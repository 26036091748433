import React, { useState } from "react";
import { boundError, formatAmount } from "../../../utils";
import moment from "moment";
import Big from "big.js";
import { ActivityDetailGeneral } from "./ActivityDetailGeneral";

const StakingActivityItemRaw = ({ item, isLast = false, isFirst = false }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const isLessThanZero = () => {
    if (Big(item?.amountChanged ?? 0).lt(0)) {
      return true;
    }

    return false;
  };

  const isEqualZero = () => {
    if (Big(item?.amountChanged ?? 0).eq(0)) {
      return true;
    }

    return false;
  };

  const onClose = () => {
    setTimeout(() => {
      setModalIsOpen(false);
    }, 10);
  };

  return (
    <div
      className="accounts-transaction-item-container cursor-pointer on-cursor-hover-blue"
      key={item.id}
      onClick={() => {
        setModalIsOpen(true);
      }}
      style={{
        borderBottom: isLast === true ? "none" : undefined,
        borderTopLeftRadius: isFirst === true ? "20px" : 0,
        borderTopRightRadius: isFirst === true ? "20px" : 0,
        borderBottomLeftRadius: isLast === true ? "20px" : 0,
        borderBottomRightRadius: isLast === true ? "20px" : 0,
        padding: "10px 20px"
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "start" }}>
        <p className="padding-none margin-none lightgrey-color" style={{ textAlign: "left" }}>
          {item && moment(item.completedAt).format("YYYY-MM-DD HH:mm")}
        </p>
        <p className="padding-none margin-none" style={{ fontSize: 18, fontWeight: "500", textAlign: "left" }}>
          {item?.title}
        </p>
        <p className="padding-none margin-none lightgrey-color" style={{ textAlign: "left" }}>
          {item?.description}
        </p>
      </div>
      {!isEqualZero() ? (
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <p
            className={isLessThanZero() ? "red-color" : "green-color"}
            style={{
              fontSize: 20,
              fontWeight: "500",
              marginTop: 5,
              marginBottom: 5
            }}
          >
            {formatAmount(item?.amountChanged, item?.currency)}
            <span style={{ fontSize: 12, fontWeight: "500", marginLeft: 5 }}>{item?.currency}</span>
          </p>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <p
            className={"red-color"}
            style={{
              fontSize: 20,
              fontWeight: "500",
              marginTop: 5,
              marginBottom: 5
            }}
          >
            {item?.description}
          </p>
        </div>
      )}
      <ActivityDetailGeneral modalIsOpen={modalIsOpen} onClose={onClose} activityId={item?.id} />
    </div>
  );
};

export const StakingActivityItem = boundError(StakingActivityItemRaw);
