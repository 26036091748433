import React from "react";
import { CopyButton } from "..";

export const KeyValue2 = ({ keyName, value, copyValue = null, style = {}, keyStyle = {}, valueStyle = {} }) => {
  return (
    <div className="key-value-2" style={{ ...style }}>
      <p
        className="margin-none"
        style={{ fontWeight: "500", fontSize: 16, minWidth: 240, textAlign: "left", ...keyStyle }}
      >
        {keyName}
      </p>
      <p className="margin-none breank-long-string" style={{ fontSize: 16, ...valueStyle }}>
        {value}
        {copyValue && <CopyButton text={copyValue} />}
      </p>
    </div>
  );
};
