import { Alert, Button, Col, Divider, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getUserKycSession } from "../../apis/kyc";
import { boundError } from "../../utils";
const { Title } = Typography;
import { BackBtn } from "../SharedComponents/Button/BackBtn";
import { useNavigate } from "react-router-dom";

const UserIdentityFormRaw = ({ userRegistrationStatus, reloadUserRegistrationStatus }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const kycStatus = userRegistrationStatus?.userRegistrationData?.kycStatus;
  const kycReady = kycStatus === "SUBMITTED" || kycStatus === "COMPLETED";

  useEffect(async () => {
    if (!userRegistrationStatus || kycReady) {
      return;
    }

    try {
      setLoading(true);
      const kycSession = await getUserKycSession();
      const { refId } = kycSession;

      const blockpass = new window.BlockpassKYCConnect(process.env.REACT_APP_BLOCKPASS_CLIENT_ID, {
        env: "prod",
        local_user_id: refId,
        mainColor: "1c8fe1",
        email: userRegistrationStatus?.email
      });
      blockpass.startKYCConnect();
      blockpass.on("KYCConnectClose", reloadUserRegistrationStatus);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [userRegistrationStatus]);

  const disableButton = !!error || kycReady;

  // hide the button using css when loading, button needs to be rendered to the page on initial page load otherwise blockpass sdk would complain
  const buttonStyle = { display: loading ? "none" : "inline-block" };

  const goBack = () => {
    const backPath = location.pathname.replace("/kyc", "");
    navigate(backPath);
  };

  return (
    <div style={{ padding: 15 }}>
      <Row justify="center">
        <Col sm={24} md={18}>
          <BackBtn onClick={goBack} />
          <Title level={3}>Verify User Identity</Title>
          {kycStatus === "SUBMITTED" && (
            <Alert message="Your kyc verification request has been submitted for review" type="info" />
          )}
          {kycStatus === "COMPLETED" && (
            <Alert message="Your kyc verification request has been completed" type="info" />
          )}
          {kycStatus === "REJECTED" && <Alert message="Your kyc verification request has been rejected" type="info" />}
          {error && <Alert message={error.response?.data?.message || error.message} type="error" />}
          <Divider />

          {loading && <Spin spinning={loading} />}
          <Button type="primary" id="blockpass-kyc-connect" disabled={disableButton} style={buttonStyle}>
            Verify with Blockpass
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export const UserIdentityForm = boundError(UserIdentityFormRaw);
