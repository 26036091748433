import { currencyImageMap } from "../resources/icons/currencies";

export const CRYPTOS = {
  BTC: { icon: currencyImageMap.BTC, name: "Bitcoin", symbol: "BTC" },
  ETH: { icon: currencyImageMap.ETH, name: "Ethereum", symbol: "ETH" },
  AXC: { icon: currencyImageMap.AXC, name: "AXIA Coin", symbol: "AXC" },
  BCH: { icon: currencyImageMap.BCH, name: "Bitcoin cash", symbol: "BCH" },
  DOGE: { icon: currencyImageMap.DOGE, name: "Dogecoin", symbol: "DOGE" },
  LTC: { icon: currencyImageMap.LTC, name: "Litecoin", symbol: "LTC" },
  USDTE: { icon: currencyImageMap.USDT, name: "Tether USD on Ethereum", symbol: "USDTE" },
  USDC: { icon: currencyImageMap.USDC, name: "USD Coin", symbol: "USDC" },
  BNB: { icon: currencyImageMap.BNB, name: "BNB on Binance Chain", symbol: "BNB" },
  USDTT: { icon: currencyImageMap.USDTT, name: "Tether USD on Tron network", symbol: "USDTT" },
  "BNB-BSC": { icon: currencyImageMap.BNB, name: "BNB on Binance Smart Chain", symbol: "BNB-BSC" },
  USAX: { icon: currencyImageMap.USAX, name: "AXIA Stable Coin", symbol: "USAX" }
};
