import React from "react";
import QRCode from "qrcode.react";
import { WarningOutlined } from "@ant-design/icons";
import { boundError } from "../../utils";
const MFAQrCodeRaw = ({ keyStr, qrCodeStr, loading }) => {
  return (
    <div>
      <p>You are about to setup time-based one-time (TOTP) MFA</p>
      <p>
        Scan the QR code below with the TOTP-generator app (e.g. Google Authenticator) on your phone.{"\n"}
        {"\n"}
        If you can't scan the code, manually enter the Secret Code string below.
      </p>
      <p style={{ overflowWrap: "break-word", fontWeight: "600", color: "gray" }}>Secret Code: {keyStr}</p>

      <div style={{ textAlign: "center", margin: "50px 0" }}>
        <QRCode value={qrCodeStr} size={200} bgColor={"#ffffff"} fgColor={"#000000"} level={"L"} renderAs={"svg"} />
      </div>
    </div>
  );
};
export const MFAQrCode = boundError(MFAQrCodeRaw);
