import React, { useState, useEffect, useContext } from "react";
import Icon, { RightOutlined, MinusOutlined, SwapOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BackBtn, SimpleButton, CancelBtn, StepMenu, ListCard, CutOffLine, AccountCard } from "../components";
import { boundError, showError, showMessage, showSuccess } from "../utils";
import { Input, Checkbox, Divider, Spin } from "antd";
import { AmountInput } from "../components/SharedComponents/AmountInput";
import { NextBtn } from "../components/SharedComponents/Button/NextBtn";
import { ShouldArriveBox } from "../components/SharedComponents/shouldArriveBox";
import { FeeRow } from "../components/SharedComponents/feeRow";
import { getTransactionFromAccountsApi } from "../apis/user";
import { CURRENCY, TRANSACTION_TYPE } from "../constants";
import { BENEFICIARY_TYPE } from "../constants/beneficiaryType";
import { custodyTransferApi } from "../apis/transfer";
import { CountrySelector } from "../components/CountrySelector";
import { ACCOUNT_STATUS } from "../constants/accountStatus";
import { encodeAddress } from "@axia-js/keyring";
import Web3 from "web3";
import UserServices from "../services/userServices";
import TransferAndDepositServices from "../services/transferAndDepositServices";

const CustodyTransferV2Raw = () => {
  const navigate = useNavigate();
  const userServices = UserServices.getInstance();
  const transferAndDepositServices = TransferAndDepositServices.getInstance();
  const [currentStep, setCurrentStep] = useState(0);
  const [accountList, setAccountList] = useState([]);
  const [selectedSource, setSelectedSource] = useState({});
  const [recipientWalletAddress, setRecipientWalletAddress] = useState("");
  const [transferAmount, setTransferAmount] = useState(0);
  const [isIndividual, setIsIndividual] = useState(true);
  const [recipient, setRecipient] = useState({});
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isValidateAxiaAddress, setIsValidateAxiaAddress] = useState(false);
  const [transferType, setTransferType] = useState();
  const [spendableAmountInAxc, setSpendableAmountInAxc] = useState();
  const [spendableAmountInUsd, setSpendableAmountInUsd] = useState();
  const [asset, setAsset] = useState("");

  useEffect(() => {
    fetchToAccount();
    fetchUserSpendable();
    fetchTransferType();
  }, []);

  useEffect(() => {
    setIsValidateAxiaAddress(validateAxiaAddress(recipientWalletAddress));
  }, [recipientWalletAddress]);

  const fetchToAccount = async () => {
    const data = await getTransactionFromAccountsApi({
      transactionType: TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_V2_WITHDRAWAL
    });
    setAccountList(data.accounts);
  };

  const fetchTransferType = async () => {
    try {
      const result = await transferAndDepositServices.getInternalTransactionTypes(
        TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_V2_WITHDRAWAL
      );

      if (result && result.length > 0) {
        setTransferType(result[0]);
      }
    } catch (err) {
      showError(err);
    }
  };

  const fetchUserSpendable = async () => {
    const data = await userServices.getUserSpendableAmount();
    setSpendableAmountInAxc(data.spendableAmountInAxc);
    setSpendableAmountInUsd(data.spendableAmountInUsd);
  };

  const validations = (steps) => {
    setValidationErrors(null);
    let allValidated = true;

    if (steps == 2) {
      if (isIndividual) {
        if (!recipient.firstname) {
          setValidationErrors((old) => ({ ...old, firstname: "First name is required!" }));
          allValidated = false;
        }

        if (!recipient.lastname) {
          setValidationErrors((old) => ({ ...old, lastname: "Last name is required!" }));
          allValidated = false;
        }
      } else {
        if (!recipient.corporateName) {
          setValidationErrors((old) => ({ ...old, corporateName: "Corporate name is required!" }));
          allValidated = false;
        }
      }

      if (!recipient.address1) {
        setValidationErrors((old) => ({ ...old, address1: "Address is required!" }));
        allValidated = false;
      }

      if (!recipient.postcode) {
        setValidationErrors((old) => ({ ...old, postcode: "Postcode is required!" }));
        allValidated = false;
      }

      if (!recipient.city) {
        setValidationErrors((old) => ({ ...old, city: "City is required!" }));
        allValidated = false;
      }

      if (!recipient.state) {
        setValidationErrors((old) => ({ ...old, state: "State is required!" }));
        allValidated = false;
      }

      if (!recipient.country) {
        setValidationErrors((old) => ({ ...old, country: "Country is required!" }));
        allValidated = false;
      }
    }

    if (!allValidated) {
      return false;
    }

    return allValidated;
  };

  const validateAxiaAddress = (address) => Web3.utils.isAddress(address);

  const submit = async () => {
    try {
      setLoading(true);
      const beneficiary = { kind: isIndividual ? "individual" : "corporate" };
      for (let key in recipient) {
        if (recipient.hasOwnProperty(key)) {
          beneficiary[key] = recipient[key].trim();
        }
      }
      const data = {
        asset,
        amount: transferAmount,
        toAddress: recipientWalletAddress,
        accountId: selectedSource?.id,
        beneficiaryType: isIndividual
          ? BENEFICIARY_TYPE.EXTERNAL_WALLET_TRANSFER_INDIVIDUAL.code
          : BENEFICIARY_TYPE.EXTERNAL_WALLET_TRANSFER_CORPORATE.code,
        beneficiary
      };
      await custodyTransferApi(data);

      showSuccess(
        "External AXIA Network (V2) wallet transfer submitted. Funds will be transferred to your wallet after admin have reviewed the transaction."
      );
      navigate("/transfer");
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const isAmountAndAddressReady = () => {
    return transferAmount >= 5 && recipientWalletAddress !== "";
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <p
                className="margin-none"
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Select source of funds to transfer
              </p>
              {accountList.map((account) => {
                const isActive = account.status === ACCOUNT_STATUS.ACTIVE;

                return (
                  <ListCard
                    style={isActive ? {} : { backgroundColor: "lightGray", cursor: "not-allowed" }}
                    key={`accountList-${account.publicId}`}
                    children={AccountCard(account)}
                    tailComponent={
                      isActive ? (
                        <RightOutlined style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }} />
                      ) : null
                    }
                    onClick={
                      isActive
                        ? () => {
                            setSelectedSource(account);
                            setAsset(account.currency === CURRENCY.USAX.code ? "usax" : "axiaV2");
                            setCurrentStep((prev) => prev + 1);
                          }
                        : () => {}
                    }
                  />
                );
              })}
            </div>
            <Divider />
            <CancelBtn toPath="/transfer" />
          </>
        );
      case 1:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <p
                className="margin-none"
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Enter the amount you want to send
              </p>
              <AmountInput
                title={"You'll send"}
                onChange={(value) => {
                  setTransferAmount(Number(value ? value : 0));
                }}
                value={transferAmount}
                currency={selectedSource.currency}
                accountId={selectedSource.publicId}
                accountAmount={selectedSource.amount}
              />
              <div
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  borderRadius: 12,
                  background: "rgb(248, 249, 250)",
                  padding: "1rem"
                }}
              >
                <div style={{ display: "flex", flexDirection: "column", marginBottom: "0.5rem" }}>
                  <p
                    className="margin-none"
                    style={{ display: "flex", fontWeight: 500, fontSize: "16px", color: "#23262F" }}
                  >
                    To AXIA Network (V2) Wallet Address
                  </p>
                  <input
                    placeholder="Wallet address..."
                    name="amount"
                    value={recipientWalletAddress}
                    onChange={(e) => {
                      setRecipientWalletAddress(e.target.value.trim());
                    }}
                    style={{
                      width: "100%",
                      border: "2px solid #e6e8ec",
                      borderRadius: "12px",
                      fontSize: 16,
                      fontWeight: "normal",
                      padding: 10
                    }}
                  />
                  {!isValidateAxiaAddress && (
                    <p
                      className="margin-none"
                      style={{
                        textAlign: "left",
                        color: "red",
                        padding: "0.5rem",
                        paddingBottom: 0
                      }}
                    >
                      Please enter a valid AXIA Network (V2) Wallet address
                    </p>
                  )}
                </div>

                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    className="margin-none"
                    style={{ textAlign: "left", fontWeight: 500, fontSize: "16px", color: "#23262F" }}
                  >
                    Transfer notice
                  </p>
                  <p className="margin-none" style={{ fontSize: "0.9rem", textAlign: "left" }}>
                    We strongly recommend that you withdraw AXC to your own custody wallet (e.g. MetaMask) instead of a
                    third-party custody wallet (e.g. Exchange Platform) in order to reduce the risk of coin
                    transfers/deposits not being recognized by the custodian provider.
                  </p>
                </div> */}
              </div>
              <FeeRow
                rows={[
                  {
                    fee: `${(transferAmount * 0.01).toFixed(5)} ${selectedSource.currency}`,
                    type: "External Network Fee"
                  },
                  {
                    fee: `${transferAmount.toFixed(5)} ${selectedSource.currency}`,
                    type: "Amount to Be Sent"
                  }
                ]}
                impactOnSpendableAmount={transferType.impactOnSpendableAmount}
                spendableData={
                  selectedSource.currency === CURRENCY.AXC.code
                    ? { spendableAmountInAxc }
                    : { spendableAmountInUsax: spendableAmountInUsd }
                }
              />
              <CutOffLine />
              <div style={{ padding: "1rem", borderRadius: 12, background: "rgb(248, 249, 250)", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    className="margin-none"
                    style={{ display: "flex", fontWeight: 500, fontSize: "1rem", color: "rgb(119, 126, 145)" }}
                  >
                    User will receive approximately
                  </p>
                  <p className="margin-none" style={{ display: "flex", fontWeight: 500, fontSize: "2rem" }}>
                    {`${(transferAmount * 0.99).toFixed(5)} ${selectedSource.currency}`}
                  </p>
                </div>
                {transferAmount < 5 ? (
                  <div style={{ color: "red", fontSize: "0.9rem" }}>
                    <p className="margin-none" style={{ display: "block" }}>
                      The minimum external transfer amount is
                    </p>
                    <p
                      className="margin-none"
                      style={{ display: "block", fontWeight: 600, textDecoration: "underline" }}
                    >
                      5.00000 {selectedSource.currency}
                    </p>
                  </div>
                ) : (
                  <ShouldArriveBox days={"1 - 2"} />
                )}
                <ul style={{ fontSize: "1rem", color: "red", textAlign: "left", marginTop: "1rem" }}>
                  <li>Please verify that the receiver is on the same network</li>
                  <li>Please verify that the receiver address is correct</li>
                  <li>Transactions cannot be canceled once sent</li>
                </ul>
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <CancelBtn toPath="/transfer" />
              <NextBtn
                enable={isAmountAndAddressReady() && isValidateAxiaAddress}
                onClick={() => setCurrentStep((prev) => prev + 1)}
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <p
                className="margin-none"
                style={{
                  display: "flex",
                  fontSize: "1.25rem",
                  lineHeight: "1.75rem",
                  color: "#606060",
                  marginBottom: "1rem",
                  fontWeight: 500
                }}
              >
                Recipient
              </p>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ fontSize: "1rem", marginBottom: "2rem" }}>
                  <p
                    className="margin-none"
                    style={{ display: "flex", fontWeight: 500, fontSize: "16px", color: "#23262F" }}
                  >
                    Legal Disclaimer
                  </p>
                  <p className="margin-none" style={{ display: "flex", textAlign: "left" }}>
                    As a regulated financial institution we are required to request the following information from you
                    in order to process transfers outside of the AXIA Network. We may be required to share any
                    information you provide to us with regulated agencies as part of our regulatory compliance
                    responsibilities. We are authorized to check any information provided to us by you. As per our
                    Accept Terms of Use , any Customer found to be providing misleading or false information may be
                    subject to the cancellation of transfers and/or accounts at the discretion of AXIA Capital Bank
                  </p>
                </div>
                <div style={{ display: "flex", borderBottom: "1px solid lightGray", marginBottom: "2rem" }}>
                  <button
                    style={{
                      marginRight: 20,
                      paddingBottom: 10,
                      border: "none",
                      color: isIndividual ? "#6366F1" : "grey",
                      fontWeight: 600,
                      borderBottom: "2px solid",
                      borderBlockColor: isIndividual ? "#6366F1" : "transparent"
                    }}
                    onClick={() => setIsIndividual(true)}
                  >
                    Individual
                  </button>
                  <button
                    style={{
                      paddingBottom: 10,
                      border: "none",
                      color: !isIndividual ? "#6366F1" : "grey",
                      fontWeight: 600,
                      borderBottom: "2px solid",
                      borderBlockColor: !isIndividual ? "#6366F1" : "transparent"
                    }}
                    onClick={() => setIsIndividual(false)}
                  >
                    Corporate
                  </button>
                </div>

                <div style={{ display: "flex", flexDirection: "column", marginBottom: "2rem", gap: "1rem" }}>
                  {isIndividual ? (
                    <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                        <p className="margin-none" style={{ textAlign: "left" }}>
                          First name *
                        </p>
                        <Input
                          name="firstname"
                          value={recipient.firstname}
                          onChange={(e) => {
                            setValidationErrors((old) => ({ ...old, firstname: "" }));
                            setRecipient({ ...recipient, firstname: e.target.value });
                          }}
                          style={{ fontSize: 16, border: "1px solid lightGray", borderRadius: 8 }}
                        />
                        {validationErrors?.firstname && (
                          <p style={{ color: "red", textAlign: "left" }}>{validationErrors?.firstname}</p>
                        )}
                      </div>
                      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                        <p className="margin-none" style={{ textAlign: "left" }}>
                          Last name *
                        </p>
                        <Input
                          name="lastname"
                          value={recipient.lastname}
                          onChange={(e) => {
                            setValidationErrors((old) => ({ ...old, lastname: "" }));
                            setRecipient({ ...recipient, lastname: e.target.value });
                          }}
                          style={{ fontSize: 16, border: "1px solid lightGray", borderRadius: 8 }}
                        />
                        {validationErrors?.lastname && (
                          <p style={{ color: "red", textAlign: "left" }}>{validationErrors?.lastname}</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                      <p className="margin-none" style={{ textAlign: "left" }}>
                        Corporate name *
                      </p>
                      <Input
                        name="corporateName"
                        value={recipient.corporateName}
                        onChange={(e) => {
                          setValidationErrors((old) => ({ ...old, corporateName: "" }));
                          setRecipient({ ...recipient, corporateName: e.target.value });
                        }}
                        style={{ fontSize: 16, border: "1px solid lightGray", borderRadius: 8 }}
                      />
                      {validationErrors?.corporateName && (
                        <p style={{ color: "red", textAlign: "left" }}>{validationErrors?.corporateName}</p>
                      )}
                    </div>
                  )}
                  <div style={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "flex-start" }}>
                    <div style={{ flex: 3, display: "flex", flexDirection: "column" }}>
                      <p className="margin-none" style={{ textAlign: "left" }}>
                        Address *
                      </p>
                      <Input
                        name="address1"
                        value={recipient.address1}
                        onChange={(e) => {
                          setValidationErrors((old) => ({ ...old, address1: "" }));
                          setRecipient({ ...recipient, address1: e.target.value });
                        }}
                        style={{ fontSize: 16, border: "1px solid lightGray", borderRadius: 8 }}
                      />
                      {validationErrors?.address1 && (
                        <p style={{ color: "red", textAlign: "left" }}>{validationErrors?.address1}</p>
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "flex-start" }}>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                      <p className="margin-none" style={{ textAlign: "left" }}>
                        Address 2
                      </p>
                      <Input
                        name="address2"
                        value={recipient.address2}
                        onChange={(e) => {
                          setRecipient({ ...recipient, address2: e.target.value });
                        }}
                        style={{ fontSize: 16, border: "1px solid lightGray", borderRadius: 8 }}
                      />
                    </div>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                      <p className="margin-none" style={{ textAlign: "left" }}>
                        Post Code / Zip Code *
                      </p>
                      <Input
                        name="postcode"
                        value={recipient.postcode}
                        onChange={(e) => {
                          setValidationErrors((old) => ({ ...old, postcode: "" }));
                          setRecipient({ ...recipient, postcode: e.target.value });
                        }}
                        style={{ fontSize: 16, border: "1px solid lightGray", borderRadius: 8 }}
                      />
                      {validationErrors?.postcode && (
                        <p style={{ color: "red", textAlign: "left" }}>{validationErrors?.postcode}</p>
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "flex-start" }}>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                      <p className="margin-none" style={{ textAlign: "left" }}>
                        City *
                      </p>
                      <Input
                        name="city"
                        value={recipient.city}
                        onChange={(e) => {
                          setValidationErrors((old) => ({ ...old, city: "" }));
                          setRecipient({ ...recipient, city: e.target.value });
                        }}
                        style={{ fontSize: 16, border: "1px solid lightGray", borderRadius: 8 }}
                      />
                      {validationErrors?.city && (
                        <p style={{ color: "red", textAlign: "left" }}>{validationErrors?.city}</p>
                      )}
                    </div>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                      <p className="margin-none" style={{ textAlign: "left" }}>
                        State/Province *
                      </p>
                      <Input
                        name="state"
                        value={recipient.state}
                        onChange={(e) => {
                          setValidationErrors((old) => ({ ...old, state: "" }));
                          setRecipient({ ...recipient, state: e.target.value });
                        }}
                        style={{ fontSize: 16, border: "1px solid lightGray", borderRadius: 8 }}
                      />
                      {validationErrors?.state && (
                        <p style={{ color: "red", textAlign: "left" }}>{validationErrors?.state}</p>
                      )}
                    </div>
                  </div>
                  <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <p className="margin-none" style={{ textAlign: "left" }}>
                      Country *
                    </p>
                    <CountrySelector
                      style={{ height: 50, alignItems: "center" }}
                      placeholder="Please select a country."
                      selectedCountry={selectedCountry}
                      onChange={(country) => {
                        setSelectedCountry(country);
                        setRecipient({ ...recipient, country: country?.name });
                        setValidationErrors((old) => ({ ...old, country: "" }));
                      }}
                    />
                    {validationErrors?.country && (
                      <p style={{ color: "red", textAlign: "left" }}>{validationErrors?.country}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <CancelBtn toPath="/transfer" />
              <SimpleButton
                style={{
                  padding: "0.5rem 0.75rem 0.5rem 0.75rem",
                  borderRadius: 12,
                  width: 300,
                  margin: "auto"
                }}
                onClick={async () => {
                  if (validations(2)) {
                    setIsConfirmed(false);
                    setCurrentStep((prev) => prev + 1);
                  }
                }}
                children={
                  <p className="margin-none" style={{ color: "white", fontSize: "1rem", fontWeight: 500 }}>
                    Next
                  </p>
                }
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <ListCard
                style={{ background: "#E9F6FF", marginBottom: "20px", cursor: "unset" }}
                children={
                  <>
                    <div
                      style={{
                        paddingLeft: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <p
                        className="margin-none"
                        style={{ display: "block", fontSize: "1rem" }}
                      >{`Send from account: ${selectedSource.publicId}`}</p>
                      <p className="margin-none" style={{ display: "block", fontSize: "2.25rem" }}>
                        {`${transferAmount.toFixed(5)} ${selectedSource.currency}`}
                      </p>
                    </div>
                  </>
                }
                onClick={() => {}}
              />
              <ListCard
                style={{ background: "#E9F6FF", cursor: "unset" }}
                children={
                  <>
                    <div
                      style={{
                        paddingLeft: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <p
                        className="margin-none"
                        style={{ display: "block", fontSize: "1rem", whiteSpace: "nowrap" }}
                      >{`Send to: ${recipientWalletAddress}`}</p>
                      <p className="margin-none" style={{ display: "block", fontSize: "2.25rem" }}>
                        {`${(transferAmount * 0.99).toFixed(5)} ${selectedSource.currency}`}
                      </p>
                    </div>
                  </>
                }
                onClick={() => {}}
              />
              <CutOffLine />
              <FeeRow
                rows={[
                  {
                    fee: `${(transferAmount * 0.01).toFixed(5)} ${selectedSource.currency}`,
                    type: "External Network Fee"
                  },
                  {
                    fee: `${transferAmount.toFixed(5)} ${selectedSource.currency}`,
                    type: "Amount to Be Sent"
                  }
                ]}
                impactOnSpendableAmount={transferType.impactOnSpendableAmount}
                spendableData={
                  selectedSource.currency === CURRENCY.AXC.code
                    ? { spendableAmountInAxc }
                    : { spendableAmountInUsax: spendableAmountInUsd }
                }
              />
              <ShouldArriveBox days={"1-2"} style={{ marginTop: 0 }} />
              <ul
                style={{
                  fontSize: "1rem",
                  color: "red",
                  textAlign: "left",
                  backgroundColor: "rgb(255, 240, 240)",
                  padding: "1rem 2rem",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0"
                }}
              >
                <li>Please verify that the receiver is on the same network</li>
                <li>Please verify that the receiver address is correct</li>
                <li>Transactions cannot be canceled once sent</li>
              </ul>
              <div
                style={{
                  backgroundColor: "#F4F5F6",
                  borderRadius: 12,
                  paddingBottom: 15,
                  padding: "10px 20px",
                  width: "100%"
                }}
              >
                <p
                  className="margin-none"
                  style={{ display: "block", textAlign: "left", fontSize: "1rem", fontWeight: 600 }}
                >
                  Recipient
                </p>
                <p className="margin-none" style={{ display: "block", textAlign: "left", fontSize: "1rem" }}>
                  {`Name: ${
                    isIndividual ? `${recipient.firstname} ${recipient.lastname}` : `${recipient.corporateName}`
                  }`}
                </p>
                <p className="margin-none" style={{ display: "block", textAlign: "left", fontSize: "1rem" }}>
                  {`Address: ${recipient.address1} ${recipient.address2 || ""} ${recipient.city} ${recipient.state} ${
                    recipient.country
                  } ${recipient.postcode}`}
                </p>
              </div>
              <div style={{ display: "flex", marginTop: "1rem", justifyContent: "start" }}>
                <Checkbox checked={isConfirmed} onChange={(e) => setIsConfirmed(e.target.checked)}>
                  I hereby acknowledge the above disclaimers
                </Checkbox>
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CancelBtn toPath="/transfer" style={{ margin: 0 }} />
              <SimpleButton
                style={{
                  backgroundColor: isConfirmed ? "#22c55e" : "#aaaaaa",
                  padding: "0.5rem 0.75rem 0.5rem 0.75rem",
                  borderRadius: 12,
                  width: 300
                }}
                disabled={!isConfirmed}
                onClick={submit}
                children={
                  <p className="margin-none" style={{ color: "white", fontSize: "1rem", fontWeight: 500 }}>
                    Send Now!
                  </p>
                }
              />
            </div>
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <Spin spinning={loading}>
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "85%", margin: "40px auto" }}
      >
        <BackBtn
          onClick={() => {
            if (currentStep > 0) {
              if (currentStep === 1) {
                setTransferAmount(0);
                setRecipientWalletAddress("");
              }
              setCurrentStep((prev) => prev - 1);
            } else {
              navigate("/transfer");
            }
          }}
        />
        <div className="row page-title" style={{ paddingTop: 0 }}>
          Transfer
        </div>
        <div className="card-apply-container" style={{ width: "100%" }}>
          <StepMenu
            steps={["Select account", "Transfer amount", "Transfer recipient", "Transaction overview"]}
            currentStep={currentStep}
          />
          <Divider />
          {renderStep()}
        </div>
      </div>
    </Spin>
  );
};

export const CustodyTransferV2 = boundError(CustodyTransferV2Raw);
