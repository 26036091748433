"use strict";

class Currency {
  constructor(code, type) {
    this.code = code;
    this.type = type;
  }
}

export const CURRENCY_TYPES = {
  FIAT: "FIAT",
  CRYPTO: "CRYPTO"
};

export const CURRENCY = {
  USD: new Currency("USD", CURRENCY_TYPES.FIAT),
  EUR: new Currency("EUR", CURRENCY_TYPES.FIAT),
  GBP: new Currency("GBP", CURRENCY_TYPES.FIAT),
  AXC: new Currency("AXC", CURRENCY_TYPES.CRYPTO),
  USAX: new Currency("USAX", CURRENCY_TYPES.CRYPTO)
};

export const VALID_CURRENCIES = new Set(Object.values(CURRENCY).map((currency) => currency.code));

export const isValidCurrency = (currency) => {
  return VALID_CURRENCIES.has(currency);
};

export const getCurrencyType = (currencyCode) => CURRENCY[currencyCode]?.type;
