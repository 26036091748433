// actions

export const mfaLoginRequest = (user) => {
  return {
    type: MFA_LOGIN_REQUEST,
    payload: { user }
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: { user }
  };
};

export const updateMFAStatus = (mfaEnabled) => {
  return {
    type: UPDATE_MFA_STATUS,
    payload: { mfaEnabled }
  };
};

export const refreshIdToken = (idToken) => {
  return {
    type: REFRESH_ID_TOKEN_SUCCESS,
    payload: idToken
  };
};

export const siteMaintenance = (isMaintaining) => {
  return {
    type: SITE_MAINTENANCE,
    payload: isMaintaining
  };
};
// types
export const MFA_LOGIN_REQUEST = "MFA_LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const UPDATE_MFA_STATUS = "UPDATE_MFA_STATUS";
export const REFRESH_ID_TOKEN_SUCCESS = "REFRESH_ID_TOKEN_SUCCESS";
export const SITE_MAINTENANCE = "SITE_MAINTENANCE";
