import React, { useState, useEffect } from "react";
import {
  boundError,
  formatAmount,
  showError,
  showMessage,
  upperCaseFirstCharEachForWord,
  removeExtraSpaceChar
} from "../../utils";
import Modal from "react-modal";
import { CloseOutlined } from "@ant-design/icons";
import { doConvertToPhysicalCard, doGetDefaultCardProvider, doGetCardTypeByCode } from "../../apis/card";
import { AccountsAndStakingSelector } from "./AccountsAndStakingSelector";
import { CURRENCY, TRANSACTION_TYPE } from "../../constants";
import { getCountryNameByCode } from "../../constants/";
import Big from "big.js";
import { isMobile } from "react-device-detect";
import UserServices from "../../services/userServices";
import { getConversionRateApi } from "../../apis";
import { Button, Divider, Spin } from "antd";

const ConvertPhysicalModalRaw = ({ showLoadModal, closeModal, card, refreshPage }) => {
  const userServices = UserServices.getInstance();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedAccountError, setSelectedAccountError] = useState("");
  const [loading, setLoading] = useState(false);
  const [spendableAmount, setSpendableAmount] = useState();
  const [cardType, setCardType] = useState();

  useEffect(() => {
    fetchCardType();
    fetchUserSpendable();
  }, []);

  const fetchCardType = async () => {
    try {
      setLoading(true);
      const result = await doGetCardTypeByCode({
        cardTypeCode: card?.cardTypeCode
      });

      setCardType(result);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const getPhysicalCardFee = () => {
    if (cardType) {
      const physicalFee = Big(cardType?.issuePhysicalFeeAmount ?? 0);

      if (physicalFee.eq(0)) {
        return "FREE";
      }

      return formatAmount(physicalFee.toNumber(), cardType?.issueFeeCurrency);
    }
  };

  const getShippingFee = () => {
    if (cardType) {
      let deliveryFee = Big(0);
      if (card?.delivery === "Courier") {
        deliveryFee = Big(cardType?.shippingHandlingAmount ?? 0);
      }

      if (deliveryFee.eq(0)) {
        return "FREE";
      }

      return formatAmount(deliveryFee.toNumber(), cardType?.issueFeeCurrency);
    }
  };

  const getTotalFee = ({ withCurrency = true } = {}) => {
    if (cardType) {
      const physicalFee = Big(cardType?.issuePhysicalFeeAmount ?? 0);
      let deliveryFee = Big(0);
      if (card?.delivery === "Courier") {
        deliveryFee = Big(cardType?.shippingHandlingAmount ?? 0);
      }
      const total = physicalFee?.plus(deliveryFee);

      if (total.eq(0)) {
        return "FREE";
      }

      return (
        formatAmount(total.toNumber(), cardType?.issueFeeCurrency) +
        (withCurrency ? " " + cardType?.issueFeeCurrency : "")
      );
    }
  };

  const renderOrderSummary = () => {
    return (
      <div
        style={{
          width: "300px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          fontSize: 15
        }}
      >
        <div>
          <h1>Order Summary</h1>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <span>Physical Card Fee:</span>
          <span>{getPhysicalCardFee()}</span>
        </div>
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <span>Virtual Card Fee:</span>
          <span>$ {virtualCardFee.toFixed()}</span>
        </div> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <span>Shipping & Handling Fee:</span>
          <span>{getShippingFee()}</span>
        </div>

        <div style={{ height: 20 }} />

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <span style={{ fontWeight: "600" }}>Order Total:</span>
          <span style={{ fontWeight: "600" }}>{getTotalFee({ withCurrency: false })}</span>
        </div>
      </div>
    );
  };

  const fetchUserSpendable = async () => {
    const data = await userServices.getUserSpendableAmount();
    const spendableAmountInUsd = data.spendableAmountInUsd;
    const fromUsdRate = await getConversionRateApi(CURRENCY.USD.code, card?.currency);
    setSpendableAmount(spendableAmountInUsd.times(fromUsdRate.conversionRate ?? 0));
  };

  const resetState = () => {
    setSelectedAccountError("");
    setSelectedAccount(null);
    setLoading(false);
  };

  const _onSubmit = async () => {
    setLoading(true);

    if (!selectedAccount) {
      setSelectedAccountError("Please select an account for amount deduction!");
      setLoading(false);
      return;
    }

    try {
      let submitData = {
        cardId: card?.id
      };

      if (selectedAccount?.from === "ACCOUNT") {
        submitData.fromAccountId = selectedAccount.id;
      } else if (selectedAccount?.from === "STAKING") {
        submitData.fromStakingId = selectedAccount.id;
      }

      await doConvertToPhysicalCard(submitData);
      resetState();
      if (card?.isFullKyc) {
        showMessage("Physical card request has been sent!");
        refreshPage();
        closeModal();
      } else {
        closeModal({ success: true });
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const onModalClose = () => {
    resetState();
  };

  return (
    <Modal
      isOpen={showLoadModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      onAfterClose={onModalClose}
      className={"popup-modal-container"}
      style={{
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          overflow: "scroll"
        },
        content: {
          borderRadius: 10
        }
      }}
    >
      <Spin
        spinning={loading}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem 0rem"
        }}
      >
        <div
          style={{
            display: "flex",
            position: "relative",
            flexDirection: "column"
            // padding: "1rem 0rem"
          }}
        >
          <Button
            style={{ border: "none", position: "absolute", right: -24, top: -16, backgroundColor: "transparent" }}
            onClick={() => closeModal()}
          >
            <CloseOutlined />
          </Button>
          <p style={{ fontWeight: "500", fontSize: 20 }}>Request Physical Card</p>

          <div
            style={{
              borderRadius: 12,
              backgroundColor: "#E9F6FF",
              padding: "10px 30px",
              alignItems: "center"
            }}
          >
            <p style={{ fontSize: "1rem", fontWeight: "500", marginBottom: 5, textAlign: "center" }}>
              Request Physical Card Fee
            </p>
            <p style={{ fontSize: "1.5rem", fontWeight: "500", margin: 0, textAlign: "center" }}>{getTotalFee()}</p>
          </div>

          {renderOrderSummary()}

          <p className="title margin-none" style={{ marginTop: 20, fontWeight: "500" }}>
            Shipping Address
          </p>
          <div className="billing-address-box">
            <p style={{ fontWeight: "600" }}>
              {upperCaseFirstCharEachForWord(removeExtraSpaceChar(card?.firstName)) +
                " " +
                upperCaseFirstCharEachForWord(removeExtraSpaceChar(card?.lastName))}
            </p>
            <p>{upperCaseFirstCharEachForWord(removeExtraSpaceChar(card?.deliveryAddress1)) || "N/A"}</p>
            <p>{upperCaseFirstCharEachForWord(removeExtraSpaceChar(card?.deliveryAddress2)) || ""}</p>
            <p>
              {(upperCaseFirstCharEachForWord(removeExtraSpaceChar(card?.deliveryCity)) || "N/A") + ", "}
              {upperCaseFirstCharEachForWord(removeExtraSpaceChar(card?.deliveryCountryState)) || "N/A"}
            </p>
            <p>
              {(getCountryNameByCode(card?.deliveryCountryCode) || "N/A") + ", "}
              {upperCaseFirstCharEachForWord(removeExtraSpaceChar(card?.deliveryPostCode?.toUpperCase())) || "N/A"}
            </p>
          </div>

          <p className="title margin-none" style={{ marginTop: 20, fontWeight: "500" }}>
            Select account to deduct amount
          </p>
          <AccountsAndStakingSelector
            currency={card?.currency}
            style={{ width: "100%", marginBottom: "20px" }}
            onAccountSelected={(account) => {
              setSelectedAccount(account);
              setSelectedAccountError("");
            }}
            selectedAccount={selectedAccount}
            placeholder={"Please select an account"}
            transactionType={TRANSACTION_TYPE.CARD_LOAD}
            displaySpendableAmount={true}
          />
          {selectedAccountError && <p style={{ color: "red" }}>{selectedAccountError}</p>}
        </div>

        <ul style={{ margin: 0 }}>
          <li>
            The card will be sent to the above address, please contact customer support if you want to ship to a
            different address.
          </li>
          <li>Please allow 2-3 weeks for delivery. Delivery time depends on your location.</li>
          <li>
            Total Spendable Amount:
            {` ${formatAmount(spendableAmount, card.currency)} ${card.currency}`}
          </li>
        </ul>

        <div
          style={{
            display: "flex",
            justifyContent: isMobile ? "space-around" : "space-between",
            padding: "0rem 0.5rem",
            marginTop: 50
          }}
        >
          <Button type="primary" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => _onSubmit()}>
            Confirm
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export const ConvertPhysicalModal = boundError(ConvertPhysicalModalRaw);
