import React, { useState, useEffect } from "react";
import { AccountsHeadCard } from "./AccountsHeadCard";
import { CURRENCY } from "../../../constants/currency";
import UserServices from "../../../services/userServices";
import { boundError, formatAmount } from "../../../utils";
import Big from "big.js";

const AccountsHeadUserSpendableRaw = ({ switchOn }) => {
  const userServices = UserServices.getInstance();

  const [spendableAmountInAxc, setSpendableAmountInAxc] = useState(Big(0));
  const [spendableAmountInUsd, setSpendableAmountInUsd] = useState(Big(0));

  useEffect(() => {
    fetchUserSpendable();
  }, []);

  const fetchUserSpendable = async () => {
    const data = await userServices.getUserSpendableAmount();
    setSpendableAmountInAxc(data?.spendableAmountInAxc ?? 0);
    setSpendableAmountInUsd(data?.spendableAmountInUsd ?? 0);
  };

  return (
    <AccountsHeadCard>
      <div
        className="row"
        style={{
          fontWeight: 500,
          fontSize: 18,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        Total Spendable Amount
      </div>
      <div
        style={{ fontWeight: 500, fontSize: 32, alignItems: "flex-end", wordBreak: "break-word", textAlign: "left" }}
      >
        <p className="padding-none margin-none">
          {switchOn
            ? formatAmount(spendableAmountInUsd, CURRENCY.USD.code)
            : formatAmount(spendableAmountInAxc, CURRENCY.AXC.code)}
        </p>
        <p className="padding-none margin-none" style={{ fontSize: 13, marginBottom: 5, width: 60 }}>
          {switchOn ? CURRENCY.USD.code : CURRENCY.AXC.code}
        </p>
      </div>
    </AccountsHeadCard>
  );
};

export const AccountsHeadUserSpendable = boundError(AccountsHeadUserSpendableRaw);
