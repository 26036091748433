import React, { useState, useContext, useEffect } from "react";
import Icon from "@ant-design/icons";
import MegaphoneIcon from "../../resources/icons/banner/megaphone.svg";
import CloseIcon from "../../resources/icons/banner/close.svg";
import { LocalStorage, StorageKeys } from "../../utils/localStorage";
import { UserContext } from "../../hooks";

import moment from "moment";
import parse from "html-react-parser";

import { boundError, showWarning } from "../../utils";
import { getBankSettings, getUserRegistrationDataApi } from "../../apis";
import Emitter, { EmitterEvents } from "../../utils/eventEmitter";

const BANNER = "BANNER";

const NotificationBarRaw = () => {
  const [notice, setNotice] = useState("");
  const [header, setHeader] = useState("");
  const [user] = useContext(UserContext);

  useEffect(() => {
    checkIsFirstCome();
    Emitter.on(EmitterEvents.REFRESH_BANNER, () => {
      checkIsFirstCome();
    });
  }, [user]);

  const getFirstComeKey = () => {
    return `${StorageKeys.FIRST_COME}/${user?.id}`;
  };

  const checkIsFirstCome = async () => {
    const registrationData = await getUserRegistrationDataApi();

    if (registrationData?.registrationCompleted) {
      const bannerSettings = await getBankSettings(BANNER);
      if (!bannerSettings[BANNER]) {
        showWarning("There was an issue fetching Banner information");
        return;
      }

      if (user?.id) {
        const key = getFirstComeKey();
        const result = LocalStorage.getItem(key, true);

        if (
          bannerSettings[BANNER]?.enabled &&
          (!result || moment(result.time) < moment(bannerSettings[BANNER]?.lastUpdatedAt) || result.value === "true")
        ) {
          setNotice(bannerSettings[BANNER]?.body ? bannerSettings[BANNER]?.body : "");
          setHeader(bannerSettings[BANNER]?.header ? bannerSettings[BANNER]?.header : "");
        }
      }
    }
  };

  const closeNotibar = () => {
    setNotice("");

    if (user?.id) {
      const key = getFirstComeKey();
      LocalStorage.setItem(key, "false");

      Emitter.emit(EmitterEvents.REFRESH_BANNER, user.id);
    }
  };

  return (
    <div className={notice ? "header-notification-container header-row" : "hide"}>
      <Icon component={() => <img src={MegaphoneIcon} />} />
      <span className="header-notification-text">
        <p className="margin-none banner-header" style={{ marginBottom: 2 }}>
          {parse(header)}
        </p>
        <p className="margin-none banner-body" style={{ fontSize: "1rem", lineHeight: "1.2rem" }}>
          {parse(notice)}
        </p>
      </span>
      <Icon component={() => <img src={CloseIcon} />} onClick={closeNotibar} />
    </div>
  );
};

export const NotificationBar = boundError(NotificationBarRaw);
