import React from "react";
import { Typography } from "antd";
const { Text } = Typography;

export const StatusText = ({ status }) => {
  if (status === "INCOMPLETE") {
    return (
      <Text type="default" strong>
        Incomplete
      </Text>
    );
  }
  if (status === "SUBMITTED" || status === "NOT_REQUIRED_SUBMITTED" || status === "PEP") {
    return (
      <Text type="warning" strong>
        Submitted
      </Text>
    );
  }
  if (status === "COMPLETED" || status === "NOT_REQUIRED") {
    return (
      <Text type="success" strong>
        Completed
      </Text>
    );
  }
  if (status === "REJECTED") {
    return (
      <Text type="danger" strong>
        Rejected
      </Text>
    );
  }
  return null;
};
