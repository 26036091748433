import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "antd";
import { GiftOutlined, DatabaseOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { boundError } from "../utils";
import CardImage from "../resources/images/card2.svg";
import { Link } from "react-router-dom";
import { getUserGiftCard } from "../apis";
import { isValidForFeature, FEATURE_NAMES } from "../utils";
import { UserContext } from "../hooks";
import { showError } from "../utils";

const GiftCardsRaw = () => {
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  const [archived, setArchived] = useState(false);
  const [giftCardList, setGiftCardList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    fetchGiftCards();
  }, [archived]);

  const fetchGiftCards = async () => {
    const res = await getUserGiftCard({ archive: archived });
    setGiftCardList(res.data);
  };

  const renderGiftCardStatus = (status) => {
    let color, backgroundColor;
    switch (status) {
      case "PROCESSED":
        color = "rgba(0, 153, 0, 1)";
        backgroundColor = "rgba(153, 255, 153, 0.8)";
        break;
      case "PENDING":
        color = "grey";
        backgroundColor = "rgba(224, 224, 224, 1)";
        break;
      case "FAILED":
        color = "red";
        backgroundColor = "rgba(255, 204, 204, 1)";
        break;
    }
    return (
      <p
        className="margin-none"
        style={{
          fontWeight: 600,
          fontSize: 14,
          padding: "2px 8px",
          borderRadius: 10,
          color,
          backgroundColor
        }}
      >
        {status}
      </p>
    );
  };

  const renderGiftCardList = () => {
    return (
      <div className="gift-card-brand-list" style={{ width: "100%" }}>
        {giftCardList.map((giftCard) => {
          if (giftCard.productName && giftCard.productName.toLowerCase().includes(searchKeyword.toLowerCase())) {
            return (
              <div
                className="gift-card-brand-card"
                key={giftCard.id}
                style={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: "white",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "1rem",
                  cursor: "pointer"
                }}
                onClick={() => navigate(`/gift-cards/${giftCard.id}`)}
              >
                <div style={{ width: "100%", padding: "1rem" }}>
                  <img
                    src={giftCard.productImage}
                    alt=""
                    style={{ maxWidth: "100%", objectFit: "contain", marginBottom: "0.5rem" }}
                  />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "0.5rem",
                      gap: "1rem"
                    }}
                  >
                    <p className="margin-none" style={{ fontWeight: 600, fontSize: "18px", textAlign: "left" }}>
                      {giftCard.productName}
                    </p>
                    {renderGiftCardStatus(giftCard.status)}
                  </div>

                  <p
                    className="margin-none"
                    style={{ fontWeight: 600, fontSize: "16px", marginBottom: "0.5rem", textAlign: "left" }}
                  >
                    {`${giftCard.faceValue} ${giftCard.faceValueCurrency}`}
                  </p>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const renderNoGiftCardView = () => {
    return (
      <div className="adjustable-layout" style={{}}>
        <img src={CardImage} width={260} style={{ marginLeft: 60, marginRight: 60 }} alt="" />
        <div className="gift-card-part2">
          <p style={{ fontSize: 24, fontWeight: "500", margin: 0, color: "#3B82F6", marginTop: 20 }}>Gift Cards</p>
          <p style={{ fontSize: 16, fontWeight: "500", margin: 0 }}>
            {`There are no ${archived ? "archived " : ""}gift cards attached to your account.`}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="row page-title">
        {archived ? (
          <>
            <DatabaseOutlined style={{ marginRight: 10 }} />
            Archived Gift Cards
          </>
        ) : (
          <>
            <GiftOutlined style={{ marginRight: 10 }} />
            Gift Cards
          </>
        )}
      </div>
      <div className="body-padding avoid-bottom-bar">
        <div className="gift-card-button-row">
          <Input
            name="search"
            placeholder="Search gift card"
            value={searchKeyword}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
            style={{ width: 320, fontSize: 16, border: "1px solid lightGray", borderRadius: 8, height: "2.5rem" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "1rem"
            }}
          >
            <Button
              type="primary"
              style={{
                borderRadius: 5,
                border: "none",
                backgroundColor: isValidForFeature({ user, featureName: FEATURE_NAMES.GIFT_CARD })
                  ? "#3B82F6"
                  : "lightgrey",
                height: "2.5rem"
              }}
              onClick={() => {
                if (!isValidForFeature({ user, featureName: FEATURE_NAMES.GIFT_CARD })) {
                  showError("This service is temporarily unavailable, please try again later.");
                }
              }}
            >
              {isValidForFeature({ user, featureName: FEATURE_NAMES.GIFT_CARD }) ? (
                <Link to={"/gift-cards/buy"}>Buy Gift Card</Link>
              ) : (
                <span>Buy Gift Card</span>
              )}
            </Button>
            <Button
              type="primary"
              style={{ borderRadius: 5, backgroundColor: "#3B82F6", height: "2.5rem" }}
              onClick={() => setArchived((prevState) => !prevState)}
            >
              {archived ? (
                <>
                  <ArrowLeftOutlined style={{ marginRight: 10 }} />
                  Back
                </>
              ) : (
                <>
                  <DatabaseOutlined style={{ marginRight: 10 }} />
                  Archived Gift Cards
                </>
              )}
            </Button>
          </div>
        </div>

        {giftCardList && giftCardList.length === 0 ? renderNoGiftCardView() : renderGiftCardList()}
      </div>
    </div>
  );
};

export const GiftCards = boundError(GiftCardsRaw);
