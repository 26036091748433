"use strict";

class StakingActivityType {
  constructor(code, description) {
    this.code = code;
    this.description = description;
  }
}

export const STAKING_ACTIVITY_TYPE = {
  CUSTOMER_CREAT: new StakingActivityType("CUSTOMER_CREAT", "Customer apply and successfully created new staking"),
  CUSTOMER_CREATE: new StakingActivityType("CUSTOMER_CREATE", "Customer apply and successfully created new staking"),
  CUSTOMER_ADD_AMOUNT: new StakingActivityType("CUSTOMER_ADD_AMOUNT", "Customer add more amount to staking"),
  CUSTOMER_ISSUE_CARD: new StakingActivityType("CUSTOMER_ISSUE_CARD", "Customer issue a new card."),
  CUSTOMER_CONVERT_CARD_TO_PHYSICAL: new StakingActivityType(
    "CUSTOMER_CONVERT_CARD_TO_PHYSICAL",
    "Customer convert a card to physical."
  ),
  CUSTOMER_LOAD_AMOUNT_TO_CARD: new StakingActivityType(
    "CUSTOMER_LOAD_AMOUNT_TO_CARD",
    "Customer load amount from staking to card"
  ),
  CUSTOMER_BUY_GIFT_CARD: new StakingActivityType("CUSTOMER_BUY_GIFT_CARD", "Customer buy gift card using staking"),
  SYSTEM_SERVICE_FEE_REFUND: new StakingActivityType("SYSTEM_SERVICE_FEE_REFUND", "Service fee refund."),
  CUSTOMER_BUY_GIFT_CARD_REFUND: new StakingActivityType(
    "CUSTOMER_BUY_GIFT_CARD_REFUND",
    "Refund to customer when gift card purchase failed"
  ),
  CUSTOMER_CLAIM: new StakingActivityType("CUSTOMER_CLAIM", "Customer claim a staking"),
  CUSTOMER_RE_ENROLL_CREATE: new StakingActivityType("CUSTOMER_RE_ENROLL_CREATE", "Customer re-enroll a staking"),
  CUSTOMER_RE_ENROLL_AMOUNT_DEDUCTION: new StakingActivityType(
    "CUSTOMER_RE_ENROLL_AMOUNT_DEDUCTION",
    "Deduct amount for customer re-enroll a staking"
  ),
  SYSTEM_UPDATE_INTEREST: new StakingActivityType(
    "SYSTEM_UPDATE_INTEREST",
    "System update interest record for staking"
  ),
  SYSTEM_PROMOTION: new StakingActivityType("SYSTEM_PROMOTION", "System give promotion to customer."),
  SYSTEM_SERVICE_FEE: new StakingActivityType("SYSTEM_SERVICE_FEE", "Service fee."),
  ADMIN_CREATE_FOR_CUSTOMER: new StakingActivityType(
    "ADMIN_CREATE_FOR_CUSTOMER",
    "Admin create a staking for customer!"
  ),
  ADMIN_ADD_AMOUNT_FOR_CUSTOMER: new StakingActivityType(
    "ADMIN_ADD_AMOUNT_FOR_CUSTOMER",
    "Admin add more amount to customer's staking!"
  ),
  ADMIN_DEDUCT_AMOUNT: new StakingActivityType("ADMIN_DEDUCT_AMOUNT", "Admin deduct amount from customer's staking!"),
  ADMIN_CLOSE: new StakingActivityType("ADMIN_CLOSE", "Admin close customer's staking!"),
  ADMIN_SUSPEND: new StakingActivityType("ADMIN_SUSPEND", "Admin suspend customer's staking!"),
  ADMIN_FREEZE: new StakingActivityType("ADMIN_FREEZE", "Admin freeze customer's staking!"),
  ADMIN_RESUME: new StakingActivityType(
    "ADMIN_RESUME",
    "Admin resume customer's staking! Staking will be set back to IN_TERM status!"
  ),
  AXIA_NETWORK_TOKEN_SPLIT: new StakingActivityType("AXIA_NETWORK_TOKEN_SPLIT", "AXIA network token split"),
  AX_PAY_DEPOSIT: new StakingActivityType("AX_PAY_DEPOSIT", "AXPay deposit"),
  AX_PAY_WITHDRAWAL: new StakingActivityType("AX_PAY_WITHDRAWAL", "AXPay withdrawal"),
  LOW_ASSET_FEE: new StakingActivityType("LOW_ASSET_FEE", "Monthly fee due to low balance")
};

export const VALID_STAKING_ACTIVITY_TYPES = new Set(
  Object.values(STAKING_ACTIVITY_TYPE).map((activity) => activity.code)
);

export const isValidStakingActivity = (stakingActivity) => VALID_STAKING_ACTIVITY_TYPES.has(stakingActivity);

export const customerSpendingActivities = [
  STAKING_ACTIVITY_TYPE.CUSTOMER_LOAD_AMOUNT_TO_CARD.code,
  STAKING_ACTIVITY_TYPE.AX_PAY_WITHDRAWAL.code,
  STAKING_ACTIVITY_TYPE.CUSTOMER_BUY_GIFT_CARD.code,
  STAKING_ACTIVITY_TYPE.CUSTOMER_CONVERT_CARD_TO_PHYSICAL.code,
  STAKING_ACTIVITY_TYPE.CUSTOMER_ISSUE_CARD.code,
  STAKING_ACTIVITY_TYPE.LOW_ASSET_FEE.code,
  STAKING_ACTIVITY_TYPE.SYSTEM_SERVICE_FEE_REFUND.code
];

export const stakingActivityTypesExcludeInterest = () => {
  return Object.values(STAKING_ACTIVITY_TYPE)
    .map((activity) => activity.code)
    .filter((item) => item !== STAKING_ACTIVITY_TYPE.SYSTEM_UPDATE_INTEREST.code);
};
