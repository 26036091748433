import { Tabs } from "antd";
import React from "react";
import { boundError } from "../../utils";
import { Select, Input, Button } from "antd";
const { TabPane } = Tabs;
const TabsMenuRaw = ({ options, onChange }) => {
  return (
    <Tabs
      defaultActiveKey="0"
      centered
      onTabClick={(e) => {
        onChange(e);
      }}
      tabBarStyle={{ color: "#02466F" }}
    >
      {options.map((each, index) => {
        return <TabPane tab={each} key={index}></TabPane>;
      })}
    </Tabs>
  );
};

export const TabsMenu = boundError(TabsMenuRaw);
