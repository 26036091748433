export const REGISTRATION_FILE_TYPE = {
  PROOF_OF_TAX_ID: "PROOF_OF_TAX_ID",
  PROOF_OF_TAX_RETURN: "PROOF_OF_TAX_RETURN",
  BUSINESS_PROOF_OF_INCORPORATE_CERTIFICATE: "BUSINESS_PROOF_OF_INCORPORATE_CERTIFICATE",
  BUSINESS_PROOF_OF_ADDRESS: "BUSINESS_PROOF_OF_ADDRESS",
  AVATAR: "AVATAR"
};

export const CARD_VERIFY_KYC_TYPE = {
  CARD_KYC: "CARD_KYC",
  CARD_KYC_ID: "CARD_KYC_ID",
  CARD_KYC_ADDRESS: "CARD_KYC_ADDRESS"
};

export const CHANGE_OF_ID_ADDRESS = {
  CHANGE_OF_ID_ADDRESS: "CHANGE_OF_ID_ADDRESS",
  CHANGE_OF_ID: "CHANGE_OF_ID",
  CHANGE_OF_ADDRESS: "CHANGE_OF_ADDRESS"
};

export const DOCUMENT_TYPE = {
  OTHER: "OTHER",
  ...REGISTRATION_FILE_TYPE,
  ...CARD_VERIFY_KYC_TYPE,
  ...CHANGE_OF_ID_ADDRESS
};