import { bankApi } from "./authenticatedAxios";
import { responseDataHandler } from "./responseHandler";
import { concatApiQuery } from "../utils/strings";

export const getInternalTransactionTypesApi = (name) =>
  bankApi.get(concatApiQuery(`/transfer/types`, { name })).then(({ data }) => responseDataHandler(data));

export const sendTransferBetweenMembersApi = ({
  fromAccountId,
  amount,
  beneficiaryEmail,
  userMemo,
  beneficiaryUserId,
  mfaCode,
  transaction2faRecordId
}) =>
  bankApi
    .post(`/transfer/between-members/send`, {
      fromAccountId,
      amount,
      beneficiaryEmail,
      userMemo,
      beneficiaryUserId,
      mfaCode,
      transaction2faRecordId
    })
    .then(({ data }) => responseDataHandler(data));

export const sendTransferBetweenAccountsApi = ({ fromAccountId, toAccountId, amount }) =>
  bankApi
    .post(`/transfer/between-accounts`, {
      fromAccountId,
      toAccountId,
      amount
    })
    .then(({ data }) => responseDataHandler(data));

export const getAllowedTransferAccountsApi = (transactionType, currency) =>
  bankApi
    .get(concatApiQuery(`/account/allow-transfer-to`, { transactionType, currency }))
    .then(({ data }) => responseDataHandler(data));

export const sendWireTransferApi = ({
  accountId,
  beneficiaryId,
  amount,
  sourceOfFund,
  userMemo,
  mfaCode,
  transaction2faRecordId
}) =>
  bankApi
    .post(`/transfer/wire-transfer`, {
      accountId,
      beneficiaryId,
      amount,
      sourceOfFund,
      userMemo,
      mfaCode,
      transaction2faRecordId
    })
    .then((response) => responseDataHandler(response));

export const externalWalletTransferApi = async ({ amount, toAddress, fromAccountId, beneficiaryType, beneficiary }) =>
  bankApi
    .post(`/transfer/externalWalletTransfer`, { amount, toAddress, fromAccountId, beneficiaryType, beneficiary })
    .then((response) => responseDataHandler(response));

export const externalAxtendWalletTransferApi = async ({
  amount,
  toAddress,
  fromAccountId,
  beneficiaryType,
  beneficiary
}) =>
  bankApi
    .post("/transfer/externalAxtendTransfer", { amount, toAddress, fromAccountId, beneficiaryType, beneficiary })
    .then((res) => responseDataHandler(res));
export const custodyTransferApi = async (data) =>
  bankApi.post(`/transfer/axcustody/withdrawal`, data).then((response) => responseDataHandler(response));
