import React, { useState, useEffect, useContext } from "react";
import { boundError, formatAmount, showError, FEATURE_NAMES, isValidForFeature } from "../../../utils";
import Modal from "react-modal";
import { KeyValue4 } from "../../SharedComponents";
import moment from "moment";
import { Button, Input, Divider, DatePicker, Dropdown, Menu, Spin } from "antd";
import { STAKING_ACTIVITY_TYPE } from "../../../constants/stakingActivityType";
import { getStakingsActivityById } from "../../../apis";
import { TRANSACTION_DIRECTION } from "../../../constants";
import Big from "big.js";
import { CURRENCY } from "../../../constants";
import { UserContext } from "../../../hooks";

const ActivityDetailGeneralRaw = ({ activityId, modalIsOpen = false, onRequestClose, onClose }) => {
  const [user] = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [activity, setActivity] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsOpen(modalIsOpen);
    if (modalIsOpen === true && activityId) {
      fetchStaking();
    }
  }, [modalIsOpen]);

  const fetchStaking = async () => {
    try {
      setLoading(true);
      const result = await getStakingsActivityById({ activityId });
      setActivity(result);
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const fromAccountDetail = () => {
    switch (activity?.stakingActivityTypeCode) {
      case STAKING_ACTIVITY_TYPE.CUSTOMER_CREATE.code:
      case STAKING_ACTIVITY_TYPE.CUSTOMER_CREAT.code:
      case STAKING_ACTIVITY_TYPE.CUSTOMER_ADD_AMOUNT.code:
        const firstName = activity?.internalTransaction?.fromUserFirstName;
        const lastName = activity?.internalTransaction?.fromUserLastName;
        const email = activity?.internalTransaction?.fromEmail;
        const publicId = activity?.internalTransaction?.fromAccountPublicId;

        return (
          <div className="account-transaction-item-modal-bottom-line" style={{ paddingTop: 10 }}>
            <p className="margin-none" style={{ fontSize: 18, fontWeight: "500" }}>
              From
            </p>
            <div>
              <KeyValue4 keyName="Name" value={`${firstName} ${lastName}`} />
              <KeyValue4 keyName="Email" value={email} />
              <KeyValue4 keyName="Account" value={publicId} />
            </div>
          </div>
        );

      default:
        return;
    }
  };

  const toAccountDetail = () => {
    switch (activity?.stakingActivityTypeCode) {
      case STAKING_ACTIVITY_TYPE.CUSTOMER_CLAIM.code:
        const firstName = activity?.internalTransaction?.toUserFirstName;
        const lastName = activity?.internalTransaction?.toUserLastName;
        const email = activity?.internalTransaction?.toEmail;
        const publicId = activity?.internalTransaction?.toAccountPublicId;

        return (
          <div className="account-transaction-item-modal-bottom-line" style={{ paddingTop: 10 }}>
            <p className="margin-none" style={{ fontSize: 18, fontWeight: "500" }}>
              To
            </p>
            <div>
              <KeyValue4 keyName="Name" value={`${firstName} ${lastName}`} />
              <KeyValue4 keyName="Email" value={email} />
              <KeyValue4 keyName="Account" value={publicId} />
            </div>
          </div>
        );

      default:
        return;
    }
  };

  const spendableAmountChangeInfo = () => {
    if (isValidForFeature({ user, featureName: FEATURE_NAMES.GLOBAL_SPENDABLE_AMOUNT })) {
      let spendableAmountChangeInAxc, spendableAmountChangeInUsd;
      if (
        activity?.metaData?.fromUserSpendableAmountChange?.amountChangedInAxc &&
        activity?.metaData?.fromUserSpendableAmountChange?.amountChangedInUsd
      ) {
        spendableAmountChangeInAxc = activity?.metaData?.fromUserSpendableAmountChange?.amountChangedInAxc;
        spendableAmountChangeInUsd = activity?.metaData?.fromUserSpendableAmountChange?.amountChangedInUsd;
      } else if (
        activity?.metaData?.toUserSpendableAmountChange?.amountChangedInAxc &&
        activity?.metaData?.toUserSpendableAmountChange?.amountChangedInUsd
      ) {
        spendableAmountChangeInAxc = activity?.metaData?.toUserSpendableAmountChange?.amountChangedInAxc;
        spendableAmountChangeInUsd = activity?.metaData?.toUserSpendableAmountChange?.amountChangedInUsd;
      } else {
        return;
      }
      spendableAmountChangeInAxc = formatAmount(spendableAmountChangeInAxc, CURRENCY.AXC.code);
      spendableAmountChangeInAxc = `${spendableAmountChangeInAxc} ${CURRENCY.AXC.code}`;
      spendableAmountChangeInUsd = formatAmount(spendableAmountChangeInUsd, CURRENCY.USD.code);
      spendableAmountChangeInUsd = `${spendableAmountChangeInUsd} ${CURRENCY.USD.code}`;
      return (
        <>
          <KeyValue4 keyName="Spendable Amount Changed in AXC" value={spendableAmountChangeInAxc} />
          <KeyValue4 keyName="Spendable Amount Changed in USD" value={spendableAmountChangeInUsd} />
        </>
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 9999,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          padding: "auto"
        },
        content: {
          margin: "auto",
          maxWidth: 1000,
          height: "100vh",
          overflow: "auto",
          padding: "auto",
          backgroundColor: "transparent",
          border: "none"
        }
      }}
    >
      <Spin spinning={loading}>
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: 10,
            padding: 30,
            marginTop: 20,
            marginBottom: 100
          }}
        >
          <div style={{ borderBottom: "1px solid lightgrey", paddingBottom: 10 }}>
            <p className="margin-none" style={{ fontWeight: "500", fontSize: 20 }}>
              {activity?.title}
            </p>
            <p className="margin-none">{activity?.description}</p>
          </div>

          <div
            className="account-transaction-item-modal-grid"
            style={{ borderBottom: "1px solid lightgrey", paddingBottom: 10 }}
          >
            {/* Amount Section */}
            <div className="account-transaction-item-modal-bottom-line" style={{ paddingTop: 10 }}>
              <p className="margin-none" style={{ fontSize: 18, fontWeight: "500" }}>
                Amount
              </p>
              <div>
                <div className="row">
                  {!Big(activity?.amountChanged ?? 0).eq(0) && (
                    <KeyValue4
                      keyName="Amount"
                      value={formatAmount(activity?.amountChanged, activity?.currency)}
                      valueTextStyle={{
                        color: Big(activity?.amountChanged ?? 0).lt(0) ? "red" : "#53E085",
                        fontWeight: "600"
                      }}
                    />
                  )}
                  {/* <KeyValue4
                    keyName={`Total ${Big(activity?.amountChanged ?? 0).lt(0) ? "Withdraw" : "Deposit"}`}
                    value={`${activity?.direction === TRANSACTION_DIRECTION.DEPOSIT ? "+" : "-"} ${formatAmount(
                      activity?.amountChanged,
                      activity?.currency
                    )} ${activity?.currency}`}
                    style={{ marginLeft: 40 }}
                    valueTextStyle={{
                      color: activity?.direction === TRANSACTION_DIRECTION.DEPOSIT ? "#53E085" : "red",
                      fontWeight: "600"
                    }}
                  /> */}
                </div>
                {!Big(activity?.amountChanged ?? 0).eq(0) && (
                  <KeyValue4 keyName="Currency" value={activity?.currency} />
                )}
                {/* <KeyValue4
                keyName="Transaction type"
                value={activity?.direction === TRANSACTION_DIRECTION.DEPOSIT ? "Received" : "Sent"}
              /> */}
                {spendableAmountChangeInfo()}
              </div>
            </div>

            {/* Details Section */}
            <div style={{ paddingTop: 10 }}>
              <p className="margin-none" style={{ fontSize: 18, fontWeight: "500" }}>
                Details
              </p>
              <div>
                <KeyValue4 keyName="Staking#" value={activity?.staking?.publicId} />
                <KeyValue4 keyName="Date" value={moment(activity?.completedAt).format("YYYY-MM-DD HH:mm:ss")} />
                <KeyValue4 keyName="Transaction status" value={"COMPLETED"} />
                <KeyValue4 keyName="Last updated" value={moment(activity?.updatedAt).format("YYYY-MM-DD HH:mm:ss")} />
              </div>
            </div>
          </div>

          <div className="account-transaction-item-modal-grid">
            {fromAccountDetail()}
            {toAccountDetail()}
          </div>

          <div className="row" style={{ marginTop: 20, alignItems: "center" }}>
            <div style={{ flex: 1 }}></div>
            <div className="row">
              <Button
                type="primary"
                style={{ borderRadius: 5, marginLeft: 20 }}
                onClick={() => {
                  // setIsOpen(false);

                  if (onClose) {
                    onClose();
                  }
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export const ActivityDetailGeneral = boundError(ActivityDetailGeneralRaw);
