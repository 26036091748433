import React from "react";
import { boundError } from "../../utils";

const ShouldArriveBoxRaw = ({ days, style = {} }) => {
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#F4F5F6",
        borderRadius: 12,
        alignItems: "center",
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 20,
        paddingRight: 40,
        marginTop: "1rem",
        justifyContent: "space-between",
        whiteSpace: "nowrap",
        width: "100%",
        ...style
      }}
    >
      <p
        className="margin-none"
        style={{
          marginLeft: "0.5rem",
          fontSize: "0.9rem",
          fontWeight: 400
        }}
      >
        Should arrive:
      </p>
      <p
        className="margin-none"
        style={{
          marginLeft: "0.5rem",
          fontSize: "0.9rem",
          fontWeight: 500
        }}
      >
        {days} days
      </p>
    </div>
  );
};

export const ShouldArriveBox = boundError(ShouldArriveBoxRaw);
