export const upperCaseFirstCharEachForWord = (sentence) => {
  if (!sentence) {
    return sentence;
  }
  const words = sentence.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1);
  }

  return words.join(" ");
};

export const removeExtraSpaceChar = (sentence) => {
  return (sentence || "").trim().replace(/\s\s+/g, " ");
};

export const concatApiQuery = (api, data) => {
  if (data) {
    const keys = Object.keys(data);

    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== undefined) {
        if (i === 0) {
          api = api + "?";
        } else {
          api = api + "&";
        }
        api = api + `${keys[i]}=${data[keys[i]]}`;
      }
    }
  }

  return api;
};
