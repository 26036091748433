import React, { useState, useEffect, useContext } from "react";
import { Button, Divider, Spin, Modal as Modal2 } from "antd";
import { KeyValue2, KeyValue4 } from "../SharedComponents";
import { boundError, showError, showSuccess } from "../../utils";
import Modal from "react-modal";
import Big from "big.js";
import StakingServices from "../../services/stakingServices";
import Emitter, { EmitterEvents } from "../../utils/eventEmitter";
import { translateStakingStatus } from "../../constants/stakingStatus";
import { AccountsSelector } from "../AccountsSelector";

const ClaimStakingModalRaw = ({ visible, onRequestClose, closeModal, staking, onClaimSuccess }) => {
  const stakingServices = StakingServices.getInstance();
  const [loading, setLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [errorAccountMsg, setErrorAccountMsg] = useState("");
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  useEffect(() => {
    setIsOpen(visible);
  }, [visible]);

  const claimStaking = async () => {
    try {
      if (!selectedAccount) {
        setErrorAccountMsg("Please select an account to deposit the staking balance!");
        return;
      }

      setConfirmLoading(true);
      await stakingServices.claimStaking(staking?.id, selectedAccount?.id);
      showSuccess("Staking has been claimed successfully!");

      setConfirmLoading(false);
      setConfirmVisible(false);
      setSelectedAccount(null);

      Emitter.emit(EmitterEvents.REFRESH_STAKING_LIST);

      closeModal();

      if (onClaimSuccess) {
        onClaimSuccess();
      }
    } catch (e) {
      setConfirmLoading(false);
      showError(e);
      closeModal();
    }
  };

  const getSelectedAccountStr = () => {
    if (selectedAccount != null) {
      let str = "";

      if (selectedAccount?.type) {
        str = selectedAccount?.type;
      }
      if (selectedAccount?.publicId) {
        str = str + (str != "" ? ":" : "") + selectedAccount?.publicId;
      }
      if (selectedAccount?.amount) {
        str = str + (str != "" ? " -- " : "") + Big(selectedAccount?.amount).toFixed(5);
      }
      if (selectedAccount?.currency) {
        str = str + (str != "" ? " " : "") + selectedAccount?.currency;
      }

      return str;
    }

    return "";
  };

  const openConfirmModal = () => {
    if (!selectedAccount) {
      setErrorAccountMsg("Please select an account to deposit the staking balance!");
      return;
    }

    if (closeModal) {
      closeModal();
    }

    setConfirmVisible(true);
  };

  const resetErrors = () => {
    setErrorAccountMsg("");
  };

  const getStakingAmount = () => {
    if (!isNaN(staking?.amount)) {
      return Big(staking?.amount).toFixed(5) + " " + staking?.currency;
    }

    return "";
  };

  const getStakingInterestAmount = () => {
    if (!isNaN(staking?.interestAmount)) {
      return Big(staking?.interestAmount).toFixed(5) + " " + staking?.currency;
    }

    return "";
  };

  const getTotalAmountToClaim = () => {
    let totalAmount = Big(0);
    if (!isNaN(staking?.amount)) {
      totalAmount = Big(staking?.amount);
    }
    if (!isNaN(staking?.interestAmount)) {
      totalAmount = totalAmount.plus(staking?.interestAmount);
    }

    return totalAmount.toFixed(5) + " " + staking?.currency;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 1999
          },
          content: {
            margin: "auto",
            maxWidth: 500,
            maxHeight: 800,
            height: "100vh",
            overflow: "auto",
            padding: 0,
            backgroundColor: "transparent",
            border: "none",
            paddingTop: 20,
            paddingBottom: 20
          }
        }}
      >
        <Spin spinning={loading}>
          <div style={{ backgroundColor: "white", borderRadius: 21 }}>
            <div className="stakings__product-modal-header">
              <h1>{staking?.name}</h1>
            </div>
            <div style={{ padding: 30, paddingBottom: 0 }}>
              <KeyValue2 keyName={"Principal Amount"} value={getStakingAmount()} />
              <KeyValue2 keyName={"Total Rewards Earned"} value={getStakingInterestAmount()} />
              <KeyValue2 keyName={"Status"} value={translateStakingStatus(staking?.status)} />

              <div style={{ height: 20 }} />
              <KeyValue2 keyName={"Total amount to claim"} value={getTotalAmountToClaim()} />
              <Divider />
              <p className="margin-none" style={{ fontWeight: "500", fontSize: 12, minWidth: 240, color: "#B1B5C3" }}>
                {"Select account to deposit amount"}
              </p>
              <AccountsSelector
                onAccountSelected={(account) => {
                  setSelectedAccount(account);
                  resetErrors();
                }}
                selectedAccount={selectedAccount}
                currency={staking?.currency}
                placeholder={"Please select an account"}
              />
              {errorAccountMsg !== "" && (
                <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorAccountMsg}</p>
              )}

              <Divider />
              <div
                className="row"
                style={{
                  paddingBottom: 20,
                  alignItems: "center",
                  justifyContent: "end"
                }}
              >
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #3b82f6",
                    borderRadius: 10,
                    color: "#3b82f6",
                    minWidth: "75px",
                    fontSize: "13px"
                  }}
                  onClick={() => {
                    setErrorAccountMsg("");
                    setSelectedAccount(null);
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  style={{ borderRadius: 12, marginLeft: 10, minWidth: "75px", fontSize: "13px" }}
                  onClick={openConfirmModal}
                >
                  Claim
                </Button>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal2
        title="Confirm information"
        visible={confirmVisible}
        onOk={claimStaking}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setConfirmVisible(false);
        }}
      >
        <KeyValue2 keyName="Staking information: " value={""} />
        <KeyValue4 keyName={"Principal Amount"} value={getStakingAmount()} />
        <KeyValue4 keyName={"Total Rewards Earned"} value={getStakingInterestAmount()} />
        <KeyValue4 keyName={"Status"} value={translateStakingStatus(staking?.status)} />
        <div style={{ height: 20 }} />
        <Divider />
        <KeyValue2 keyName="Account to: " value={""} />
        <KeyValue4 keyName={"Total amount to claim"} value={getTotalAmountToClaim()} />
        <KeyValue4 keyName="To Account" value={getSelectedAccountStr()} />
      </Modal2>
    </>
  );
};

export const ClaimStakingModal = boundError(ClaimStakingModalRaw);
