import React from "react";
import { boundError } from "../../utils";

const StepMenuRaw = ({ steps, currentStep }) => {
  return (
    <div className="step-grid">
      {steps.map((each, index) => {
        return (
          <div key={each}>
            <div className={index <= currentStep ? "reached-step" : "unreached-step"}></div>
            <p className="margin-none">{each}</p>
          </div>
        );
      })}
    </div>
  );
};

export const StepMenu = boundError(StepMenuRaw);
