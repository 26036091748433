import React, { useState, useRef, useEffect, useCallback } from "react";
import { ExclamationCircleOutlined, LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Spin, Form, Input, Row, Space, Upload, Typography, Select, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { uploadPendingDocument, downloadDocumentById, updateUserDocumentInfo, doGetCardList } from "../../apis";
import { boundError, showSuccess, showError } from "../../utils";
import { CountrySelect } from "../CountrySelect";
import { set } from "lodash";
import { DOCUMENT_TYPE } from "../../constants/documentType";
import * as pdfHelper from "../../utils/pdfHelper";
import { ConvertImgToPdfModal } from "../SharedComponents/ConvertImgToPdfModal";

const CardKYCFormRaw = ({ rawData = null }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const [id, setId] = useState(undefined);
  const [kycAddressDocId, setKycAddressDocId] = useState(null);
  const [kycIdDocId, setKycIdDocId] = useState(null);
  const [status, setStatus] = useState();
  const [initialValues, setInitialValues] = useState({});
  const [card, setCard] = useState("");
  const [cardList, setCardList] = useState([]);
  const [showUploadDocModal, setShowUploadDocModal] = useState(false);

  const [disableForm, setDisableForm] = useState(false);

  const navigate = useNavigate();

  const isMounted = useRef(true);
  const idFileList = useRef([]);

  // useEffect(() => {
  //   fetchCards();
  //   return () => {
  //     isMounted.current = false;
  //   };
  // }, []);

  useEffect(() => {
    if (rawData !== null) {
      setId(rawData?.id ?? null);
      setStatus(rawData?.status ?? "INCOMPLETE");
      setDisableForm(rawData.status !== "INCOMPLETE");
      setInitialValues({
        cardId: rawData?.info?.cardId,
        memo: rawData?.memo,
        proofOfCardId: rawData?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ID]?.documentId
          ? [
              {
                id: rawData?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ID]?.documentId,
                name: rawData?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ID]?.documentName,
                status: "done"
              }
            ]
          : [],
        proofOfCardAddress: rawData?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ADDRESS]?.documentId
          ? [
              {
                id: rawData?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ADDRESS]?.documentId,
                name: rawData?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ADDRESS]?.documentName,
                status: "done"
              }
            ]
          : []
      });
    }
    setLoading(false);
  }, [rawData]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const fetchCards = async () => {
    setLoading(true);
    try {
      const cardList = await doGetCardList();
      setCardList((cardList?.data || []).filter((card) => !card.isFullKYC));
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const StatusWarningMsg = () => {
    let msg;
    switch (status) {
      case "SUBMITTED":
        msg = "Your card identification change request has been submitted for review";
        break;
      case "COMPLETED":
        msg = "Your card identification change request has been completed";
        break;
      case "REJECTED":
        msg = "Your card identification change request has been rejected";
        break;
      case "CANCELED":
        msg = "Your card identification change request has been canceled";
        break;
    }
    if (msg) {
      return <Alert message={msg} type="info" />;
    }
  };

  const onFinish = async ({ cardId, memo }) => {
    if (cardId) cardId = cardId.trim();
    if (memo) memo = memo.trim();
    setError(null);
    setLoading(true);
    try {
      await updateUserDocumentInfo({
        id,
        documentType: "CARD_KYC",
        infoToUpdate: { cardId },
        memo,
        submit: true
      });
      showSuccess("Successfully submitted your request for review");
      goBack();
    } catch (err) {
      showError(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const onSaveAndExit = async () => {
    try {
      let { cardId, memo } = form.getFieldsValue(["cardId", "memo"]);
      if (cardId) cardId = cardId.trim();
      if (memo) memo = memo.trim();
      await updateUserDocumentInfo({
        id,
        infoToUpdate: { cardId },
        memo,
        documentType: DOCUMENT_TYPE.CARD_KYC,
        submit: false,
        multipleDocs: {}
      });
      showSuccess("Successfully saved your info");
      goBack();
    } catch (err) {
      showError(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.slice(-1);
    }
    return e?.fileList?.slice(-1);
  };

  const createCustomUploadRequestForAddress =
    (uploader) =>
    async ({ file, onError, onSuccess }) => {
      try {
        const result = await uploader({ id, documentType: DOCUMENT_TYPE.CARD_KYC_ADDRESS, file });
        if (result && result.length > 0) {
          setId(result[0].id);
        }
        onSuccess();
      } catch (err) {
        showError(err);
        onError(err);
      }
    };

  const createCustomUploadRequestForId =
    (uploader) =>
    async ({ file, onError, onSuccess }) => {
      try {
        const result = await uploader({ id, documentType: DOCUMENT_TYPE.CARD_KYC_ID, file });
        if (result && result.length > 0) {
          setId(result[0].id);
        }
        onSuccess();
      } catch (err) {
        showError(err);
        onError(err);
      }
    };

  const createCustomFileListItemRenderer = (onClickHandler) => (originalNode) => {
    return (
      <Button type="link" onClick={onClickHandler} style={{ padding: 0 }}>
        {originalNode}
      </Button>
    );
  };

  const downloadDocumentForId = async () => {
    if (initialValues.proofOfCardId && initialValues.proofOfCardId.length !== 0) {
      const { id } = initialValues.proofOfCardId[0];
      await downloadDocumentById(id);
    }
  };

  const downloadDocumentForAddress = async () => {
    if (initialValues.proofOfCardAddress && initialValues.proofOfCardAddress.length !== 0) {
      const { id } = initialValues.proofOfCardAddress[0];
      await downloadDocumentById(id);
    }
  };

  const goBack = () => {
    navigate("/user-document");
  };

  const handleUploadIdV2 = async (file) => {
    try {
      setLoading(true);
      const data = await uploadPendingDocument({ id, documentType: DOCUMENT_TYPE.CARD_KYC_ID, file });
      setId(data[0].id);
      setInitialValues({
        proofOfCardId:
          data.length > 0 && data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ID]?.documentId
            ? [
                {
                  id: data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ID]?.documentId,
                  name: data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ID]?.documentName,
                  status: "done"
                }
              ]
            : []
      });
      setLoading(false);
    } catch (err) {
      showError(err);
    }
  };

  const handleUploadId = async ({ file, onError, onSuccess }) => {
    idFileList.current.push(file);

    if (idFileList.current.length > 2) {
      showError("Please either select 1 or 2 images files");
      idFileList.current = [];
      onError();
      return;
    }

    if (idFileList.current.length == 1) {
      setTimeout(async () => {
        if (idFileList.current.length == 1) {
          try {
            const fileArray = idFileList.current ? Array.from(idFileList.current) : [];
            const fileToImagePromises = fileArray.map(pdfHelper.fileToImageURL);
            const files = await Promise.all(fileToImagePromises);
            const file = await pdfHelper.generatePdfFromImages(files, "proof_of_id_kyc");
            const data = await uploadPendingDocument({ id, documentType: DOCUMENT_TYPE.CARD_KYC_ID, file });
            setId(data[0].id);
            onSuccess();
            setInitialValues({
              proofOfCardId:
                data.length > 0 && data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ID]?.documentId
                  ? [
                      {
                        id: data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ID]?.documentId,
                        name: data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ID]?.documentName,
                        status: "done"
                      }
                    ]
                  : []
            });
            idFileList.current = [];
            return;
          } catch (err) {
            showError(err);
            onError();
            return;
          }
        }
      }, 1000);
    }

    setTimeout(async () => {
      if (idFileList.current.length == 2) {
        try {
          const fileArray = idFileList.current ? Array.from(idFileList.current) : [];
          const fileToImagePromises = fileArray.map(pdfHelper.fileToImageURL);
          const files = await Promise.all(fileToImagePromises);
          const file = await pdfHelper.generatePdfFromImages(files, "proof_of_id_kyc");
          const data = await uploadPendingDocument({ id, documentType: DOCUMENT_TYPE.CARD_KYC_ID, file });
          setId(data[0].id);
          onSuccess();
          setInitialValues({
            proofOfCardId:
              data.length > 0 && data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ID]?.documentId
                ? [
                    {
                      id: data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ID]?.documentId,
                      name: data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CARD_KYC_ID]?.documentName,
                      status: "done"
                    }
                  ]
                : []
          });
          idFileList.current = [];
          return;
        } catch (err) {
          showError(err);
          onError();
          return;
        }
      }
    }, 1000);
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Spin spinning={loading}>
        <Row justify="start">
          <Col sm={24} md={18}>
            {disableForm && (
              <div style={{ marginBottom: 20 }}>
                <StatusWarningMsg />
              </div>
            )}

            <Form
              form={form}
              layout="vertical"
              name="proof_of_card_id"
              onFinish={onFinish}
              initialValues={initialValues}
            >
              {/* <Form.Item label="Card" name="cardId" rules={[{ required: true, message: "Card is required!" }]}>
                <Select
                  size="large"
                  value={card}
                  style={{
                    display: "flex",
                    width: "100%",
                    maxWidth: 400,
                    border: "none",
                    textAlign: "left"
                  }}
                  onChange={(e) => setCard(e)}
                >
                  {Object.values(cardList).map((option, index) => {
                    return (
                      <Option value={option?.id} key={index}>
                        {option?.cardNumber}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item> */}
              <Form.Item
                label={
                  <>
                    Proof of ID (<b>front&nbsp;</b> and <b>&nbsp;back&nbsp;</b> side of an government-issued colour ID)
                  </>
                }
                name="proofOfCardId"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: "Proof of Card ID is required!" }]}
              >
                <Upload
                  // customRequest={createCustomUploadRequestForId(uploadPendingDocument)}
                  // customRequest={handleUploadId}
                  openFileDialogOnClick={false}
                  multiple={true}
                  showUploadList={{ showRemoveIcon: false }}
                  disabled={disableForm}
                  accept="image/*"
                  itemRender={createCustomFileListItemRenderer(downloadDocumentForId)}
                  restrictions={{
                    maxFileSize: 1000000
                  }}
                >
                  <Button
                    icon={<UploadOutlined />}
                    disabled={disableForm}
                    onClick={() => {
                      setShowUploadDocModal(true);
                    }}
                  >
                    Upload file(s)
                  </Button>
                  <Tooltip title="Please select up to 2 image file(s). Png, img, or jpg files are acceptable.">
                    <ExclamationCircleOutlined style={{ marginLeft: 5 }} />
                  </Tooltip>
                </Upload>
              </Form.Item>
              <Form.Item
                label="Proof of address (issued within the last 3 months)"
                name="proofOfCardAddress"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: "Proof of Card Address is required!" }]}
              >
                <Upload
                  customRequest={createCustomUploadRequestForAddress(uploadPendingDocument)}
                  multiple={false}
                  showUploadList={{ showRemoveIcon: false }}
                  disabled={disableForm}
                  accept=".pdf"
                  itemRender={createCustomFileListItemRenderer(downloadDocumentForAddress)}
                  restrictions={{
                    maxFileSize: 1000000
                  }}
                >
                  <Button icon={<UploadOutlined />} disabled={disableForm}>
                    Upload pdf file
                  </Button>
                  <Tooltip title="Please select one pdf file.">
                    <ExclamationCircleOutlined style={{ marginLeft: 5 }} />
                  </Tooltip>
                </Upload>
              </Form.Item>
              {/* <Form.Item label="Document Memo" name="memo">
                <Input type="text" disabled={disableForm} />
              </Form.Item> */}
              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={loading || disableForm}>
                    Submit
                  </Button>
                  {disableForm ? (
                    <Button type="primary" onClick={() => goBack()}>
                      Exit
                    </Button>
                  ) : (
                    <Button disabled={loading} onClick={onSaveAndExit}>
                      Save and exit
                    </Button>
                  )}
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <ConvertImgToPdfModal
          visible={showUploadDocModal}
          onCancel={() => setShowUploadDocModal(false)}
          onOk={(file) => {
            handleUploadIdV2(file);
            setShowUploadDocModal(false);
          }}
          fileName={"proof_of_id_kyc"}
        />
      </Spin>
    </div>
  );
};

export const CardKYCForm = boundError(CardKYCFormRaw);
