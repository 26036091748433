import { Auth } from "aws-amplify";
import { initAxios } from "./authenticatedAxios";
// check this documentation: https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/
const MFA_TYPES = {
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA"
};

// SESSION MANAGEMENT

export const getCurrentAuthenticatedUser = () => Auth.currentAuthenticatedUser();

export const getCurrentIdToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

// SIGN IN/OUT
export const signIn = async (email, password) => {
  const user = await Auth.signIn(email, password);
  initAxios();
  return user;
};

export const awsMfaSignIn = (user, code) => Auth.confirmSignIn(user, code, MFA_TYPES.SOFTWARE_TOKEN_MFA);

export const awsSignOut = () => Auth.signOut();

// SIGN UP
export const awsSignUp = (email, password, reCAPTCHAToken, attributes = {}) =>
  Auth.signUp({
    username: email,
    password,
    validationData: { reCAPTCHAToken: reCAPTCHAToken },
    attributes: {
      email,
      ...attributes
    }
  });

export const resendSignUpCode = (email) => Auth.resendSignUp(email);

export const awsConfirmSignUp = (email, code) => Auth.confirmSignUp(email, code);

// PASSWORD MANAGEMENT
export const updatePasswordApi = async (oldPassword, newPassword) => {
  const user = await Auth.currentAuthenticatedUser();
  return await Auth.changePassword(user, oldPassword, newPassword);
};

export const awsForgotPassword = (email) => Auth.forgotPassword(email);

export const awsForgotPasswordSubmit = (email, newPassword, verificationCode) =>
  Auth.forgotPasswordSubmit(email, verificationCode, newPassword);

// MFA
export const enableMFARequest = async () => {
  try {
    const user = await getCurrentAuthenticatedUser();
    const username = user.getUsername();
    const keyStr = await Auth.setupTOTP(user);
    const qrCodeStr = "otpauth://totp/AWSCognito:" + username + "?secret=" + keyStr + "&issuer=AXIACoin";
    return { user, keyStr, qrCodeStr };
  } catch (err) {
    throw new Error("User information not available.");
  }
};

export const enableMFASubmit = async (user, code) => {
  if (user) {
    await Auth.verifyTotpToken(user, code);
    await Auth.setPreferredMFA(user, "TOTP");
  } else {
    throw new Error("User information not available");
  }
};

export const disableMFASubmit = async (code) => {
  let user;
  try {
    user = await getCurrentAuthenticatedUser();
  } catch (err) {
    throw new Error("User information not available.");
  }
  await Auth.verifyTotpToken(user, code);
  await Auth.setPreferredMFA(user, "NOMFA");
};
