import React, { useEffect } from "react";
import { Divider, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { StepMenu, BackBtn, SimpleButton } from "../components";
import { KeyValue2 } from "../components/SharedComponents";
import { useNavigate } from "react-router-dom";
import { boundError } from "../utils";
import { useState } from "react";
import { getCustodyDepositAddress } from "../apis";
import { ConsentModal } from "../components/SharedComponents/consentModal";
import TokenSplitPDF from "../resources/consents/token_split/AXC_TOKEN_MIGRATION_TERMS_V2_202209.pdf";

const CustodyDepositRaw = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [depositAddress, setDepositAddress] = useState("");
  const [showConsentModal, setShowConsentModal] = useState(false);

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    const res = await getCustodyDepositAddress();
    setDepositAddress(res.data);
    setLoading(false);
  };

  const renderBody = () => {
    return (
      <>
        <div className="between-account step-body" style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
          <h2
            style={{
              display: "flex",
              lineHeight: "1.75rem",
              marginBottom: "1rem",
              fontWeight: 500,
              textAlign: "left",
              width: "100%",
              fontSize: "16px",
              color: "#23262F"
            }}
          >
            Fund your AXIA Network wallet balance with AXC
          </h2>
          <KeyValue2
            style={{ width: "100%" }}
            keyName="Deposit address:"
            value={depositAddress}
            copyValue={depositAddress}
            keyStyle={{ fontSize: "15px", fontWeight: 500, minWidth: 150 }}
            valueStyle={{ fontSize: "15px" }}
          />
          <p
            className="margin-none"
            style={{
              textAlign: "left",
              fontSize: "15px",
              marginTop: "0.5rem",
              fontWeight: 500,
              alignSelf: "flex-start"
            }}
          >
            Minimum required deposit amount: 1 AXC
          </p>
          <p
            className="margin-none"
            style={{
              textAlign: "left",
              fontSize: "15px",
              marginBottom: "1rem",
              marginTop: "0.5rem",
              fontWeight: 500,
              alignSelf: "flex-start"
            }}
          >
            Estimated network fee: 0.001 AXC
          </p>
          <ul
            style={{
              fontSize: "1rem",
              color: "#F12F2F",
              textAlign: "left",
              marginTop: "1rem",
              backgroundColor: "#FFF0F0",
              padding: "1rem 2rem",
              borderRadius: 12
            }}
          >
            <li>Please be advised that this deposit requires the individual to pay the network fee</li>
            <li>
              Verify the receiving address prior to transfer or funds will not make it to destination account and could
              be permanently lost
            </li>
            <li>Transactions cannot be canceled once sent</li>
          </ul>
          <p
            className="margin-none"
            style={{
              textAlign: "left",
              fontSize: "1rem",
              marginBottom: "1rem",
              marginTop: "0.5rem",
              fontWeight: 500,
              color: " rgba(180, 83, 9)"
            }}
          >
            <ExclamationCircleOutlined style={{ marginRight: "0.5rem" }} />
            IMPORTANT - Please note that the wallet address provided is only applicable to AXIA Network AXC. If you wish
            to deposit other cryptos, please use other deposit service.
          </p>
          <div style={{ display: "flex", color: "grey", width: "100%", whiteSpace: "nowrap" }}>
            *Please note that by depositing to this address, you agree to the&nbsp;
            <a
              onClick={() => {
                setShowConsentModal(true);
              }}
            >
              AXC Token Migration Terms.
            </a>
          </div>
        </div>
        <Divider />
        <SimpleButton
          style={{
            padding: "0.5rem 0.75rem 0.5rem 0.75rem",
            width: 300,
            borderRadius: 12,
            margin: "auto"
          }}
          onClick={() => {
            navigate("/deposit");
          }}
          children={
            <p className="margin-none" style={{ color: "white", fontSize: "0.875rem", fontWeight: 500 }}>
              Finish
            </p>
          }
        />
      </>
    );
  };

  return (
    <Spin spinning={loading}>
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "85%", margin: "40px auto" }}
      >
        <BackBtn />
        <div className="row page-title" style={{ paddingTop: 0 }}>
          Deposit
        </div>
        <div className="card-apply-container" style={{ width: "100%" }}>
          {/* <StepMenu steps={["Deposit Address"]} currentStep={1} />
          <Divider /> */}
          {renderBody()}
        </div>
      </div>
      <ConsentModal
        visible={showConsentModal}
        footer={null}
        onCancel={() => {
          setShowConsentModal(false);
        }}
        src={TokenSplitPDF}
      />
    </Spin>
  );
};

export const CustodyDeposit = boundError(CustodyDepositRaw);
