import React, { useState, useEffect } from "react";
import Emitter, { EmitterEvents } from "../utils/eventEmitter";
import { sleep } from "../utils";

export const UserContext = React.createContext("User");
export const UserRegistrationStatusContext = React.createContext("UserRegistrationStatus");

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [registrationStatus, setRegistrationStatus] = useState({ registrationCompleted: null });

  useEffect(() => {
    Emitter.on(EmitterEvents.SET_USER, ({ data, cb }) => {
      setUser(data);
      sleep(200).then(() => {
        cb();
      });
    });

    Emitter.on(EmitterEvents.GET_USER, (cb) => {
      cb(user);
    });

    return () => {
      Emitter.off(EmitterEvents.SET_USER);
      Emitter.off(EmitterEvents.GET_USER);
    };
  }, [user]);

  return (
    <UserContext.Provider value={[user, setUser]}>
      <UserRegistrationStatusContext.Provider value={[registrationStatus, setRegistrationStatus]}>
        {children}
      </UserRegistrationStatusContext.Provider>
    </UserContext.Provider>
  );
};

export default UserContextProvider;
