import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Spin, DatePicker, Button, Pagination } from "antd";
import { boundError, showError, formatAmount, isLocal } from "../../utils";
import { getStakingsActivities } from "../../apis";
import date from "date-and-time";
import { ListItem1 } from "../SharedComponents";
import { StakingActivityItem } from "./components/StakingActivityItem";
import moment from "moment";

const { RangePicker } = DatePicker;

export const StakingsActivities = forwardRef(({ stakingId }, ref) => {
  const [records, setRecords] = useState([]);
  const [offset, setOffset] = useState(0);
  const limit = 20;
  const [loading, setLoading] = useState(false);
  const [completedAtGte, setCompletedAtGte] = useState();
  const [completedAtLte, setCompletedAtLte] = useState();
  const [totalRecordsNumber, setTotalRecordsNumber] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getInterestRecords();
  }, [offset, completedAtGte, completedAtLte]);

  useImperativeHandle(ref, () => ({
    refresh() {
      setOffset(0);
      setTimeout(() => {
        getInterestRecords();
      }, 100);
    }
  }));

  const getInterestRecords = async () => {
    try {
      setLoading(true);

      const result = await getStakingsActivities({
        stakingId,
        offset,
        limit,
        completedAtGte,
        completedAtLte,
        includeTotalCount: true
      });

      if (result?.data) {
        setRecords(result?.data);
      }

      if (result?.totalCount) {
        setTotalRecordsNumber(result?.totalCount);
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 80 }}>
      <div className="row" style={{ width: "100%", justifyContent: "flex-start" }}>
        <p style={{ fontSize: 20, fontWeight: "800" }}>Transactions</p>
      </div>
      <div className="row" style={{ marginBottom: 10, width: "100%" }}>
        <RangePicker
          onChange={(dates, dateStrings) => {
            if (dateStrings[0] && dateStrings[0] !== "") {
              setCompletedAtGte(moment(dateStrings[0]).toISOString());
            } else {
              setCompletedAtGte();
            }
            if (dateStrings[1] && dateStrings[1] !== "") {
              // add a day so that it includes that day
              setCompletedAtLte(moment(dateStrings[1]).toISOString());
            } else {
              setCompletedAtLte();
            }
          }}
        />
        <div style={{ flex: 1 }} />
        {isLocal() && (
          <Button
            type="primary"
            // icon={<FileOutlined style={{ color: "#ffffff" }} />}
            style={{ borderRadius: 5, marginLeft: 10 }}
            // onClick={handlePrint}
          >
            Print Transactions
          </Button>
        )}
      </div>

      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 10
          }}
        >
          {!records || records.length === 0 ? (
            <p className="margin-none">No records</p>
          ) : (
            <div>
              {records.map((record, index) => {
                return (
                  <StakingActivityItem
                    item={record}
                    key={record?.id}
                    isLast={index === records.length - 1}
                    isFirst={index === 0}
                  />
                );
              })}
            </div>
          )}
        </div>
      </Spin>
      {!loading && (
        <div className="accounts-transaction-bottom" style={{ width: "100%" }}>
          <p>
            Showing {records.length} of {totalRecordsNumber} results
          </p>
          <div style={{ flex: 1 }} />
          <Pagination
            current={currentPage}
            style={{ textAlign: "right" }}
            size="small"
            pageSize={limit}
            defaultCurrent={1}
            total={totalRecordsNumber}
            showSizeChanger={false}
            onChange={(page) => {
              const newOffSet = (page - 1) * limit;
              setCurrentPage(page);
              setOffset(newOffSet);
            }}
          />
        </div>
      )}
    </div>
  );
});
