import React, { useState, useEffect } from "react";
import { boundError, formatAmount, getAmountSymbol, getDecimalScale, showError } from "../../utils";
import { Button } from "antd";
import Modal from "react-modal";
import { CheckCircleOutlined, CloseOutlined, WarningOutlined } from "@ant-design/icons";
import { Input, Spin } from "antd";
import { doLoadCardFromAccount } from "../../apis/card";
import UserServices from "../../services/userServices";
import { AccountsAndStakingSelector } from "./AccountsAndStakingSelector";
import { TRANSACTION_TYPE } from "../../constants";
import NumberFormat from "react-number-format";
import Big from "big.js";
import { CURRENCY } from "../../constants";
import { getConversionRateApi } from "../../apis";

const CardLoadModalRaw = ({ showLoadModal, closeModal, card, refreshPage }) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [loadingAmount, setLoadingAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const userServices = UserServices.getInstance();

  const [loadAmountError, setLoadAmountError] = useState("");
  const [selectedAccountError, setSelectedAccountError] = useState("");

  const [spendableAmount, setSpendableAmount] = useState();

  useEffect(() => {
    fetchUserSpendable();
  }, []);

  const fetchUserSpendable = async () => {
    const data = await userServices.getUserSpendableAmount();
    const spendableAmountInUsd = data.spendableAmountInUsd;
    const fromUsdRate = await getConversionRateApi(CURRENCY.USD.code, card?.currency);
    setSpendableAmount(spendableAmountInUsd.times(fromUsdRate.conversionRate ?? 0));
  };

  const resetState = () => {
    setLoadAmountError("");
    setSelectedAccountError("");
    setSelectedAccount(null);
    setLoadingAmount(0);
    setLoading(false);
  };

  const _onSubmit = async () => {
    setLoading(true);

    let hasError = false;
    if (!loadingAmount) {
      setLoadAmountError("Please insert a valid amount!");
      setLoading(false);
      hasError = true;
    }

    if (!selectedAccount) {
      setSelectedAccountError("Please select an account for amount deduction!");
      setLoading(false);
      hasError = true;
    }

    if (hasError) {
      return;
    }

    let submitData = {
      cardId: card?.id,
      amount: loadingAmount
    };

    if (selectedAccount?.from === "ACCOUNT") {
      submitData.fromAccountId = selectedAccount.id;
    } else if (selectedAccount?.from === "STAKING") {
      submitData.fromStakingId = selectedAccount.id;
    }

    try {
      const data = await doLoadCardFromAccount(submitData);
      if (data?.Description == "Success") {
        closeModal(
          {
            message: `Card is loaded successfully.`,
            icon: <CheckCircleOutlined style={{ color: "green" }} />
          },
          true
        );
        userServices.fetchUserAccounts();

        resetState();
        refreshPage();
      } else {
        closeModal(
          {
            message: data.toString(),
            icon: <WarningOutlined style={{ color: "red" }} />
          },
          false
        );

        resetState();
      }
    } catch (e) {
      if (e?.includes("Exceeded staking's daily spend limit")) {
        showError("Exceeded staking's daily spend limit!");
      } else if (e?.includes("Exceeded staking's weekly spend limit")) {
        showError("Exceeded staking's weekly spend limit!");
      } else if (e?.includes("Exceeded staking's monthly spend limit")) {
        showError("Exceeded staking's monthly spend limit!");
      } else if (e?.includes("Exceeded staking deduction limit")) {
        showError("Exceeded staking deduction limit!");
      } else if (e?.includes("Insufficient funds")) {
        showError("Service temporarily unavailable please try again later");
      } else {
        showError(e);
      }
    } finally {
      setLoading(false);
    }
  };

  const onModalClose = () => {
    resetState();
  };

  return (
    <Modal
      isOpen={showLoadModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      onAfterClose={onModalClose}
      className={"popup-modal-container"}
      style={{
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0, 0, 0, 0.3)"
        },
        content: {
          borderRadius: 10
        }
      }}
    >
      <Spin
        spinning={loading}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem 0rem"
        }}
      >
        <div
          style={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            padding: "1rem 0rem"
          }}
        >
          <Button
            style={{ border: "none", position: "absolute", right: -24, top: -16, backgroundColor: "transparent" }}
            onClick={() => closeModal()}
          >
            <CloseOutlined />
          </Button>
          <p style={{ fontWeight: "500" }}>Please select an account:</p>

          <AccountsAndStakingSelector
            currency={card?.currency}
            style={{ width: "100%", marginBottom: "20px" }}
            onAccountSelected={(account) => {
              setSelectedAccount(account);
              setLoadAmountError("");
              setSelectedAccountError("");
            }}
            selectedAccount={selectedAccount}
            placeholder={"Please select an account"}
            transactionType={TRANSACTION_TYPE.CARD_LOAD}
            displaySpendableAmount={true}
          />
          {selectedAccountError && <p style={{ color: "red" }}>{selectedAccountError}</p>}

          <p style={{ fontWeight: "500" }}>Please enter amount to load:</p>
          <div style={{ display: "flex" }}>
            <NumberFormat
              thousandSeparator={true}
              placeholder={"0.00"}
              decimalScale={getDecimalScale(card?.currency)}
              prefix={getAmountSymbol(card?.currency)}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setLoadingAmount(Number(value));
                setLoadAmountError("");
                setSelectedAccountError("");
              }}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: "12px",
                border: "2px solid #B1B5C4",
                padding: "10px 20px",
                maxHeight: 46,
                marginRight: 10,
                fontSize: 16
              }}
            />
            <p
              style={{
                width: "100px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: "12px",
                border: "2px solid #B1B5C4",
                padding: "10px 20px",
                maxHeight: 46,
                justifyContent: "center",
                background: "#F8F9FA",
                fontSize: 16
              }}
            >
              {card?.currency}
            </p>
          </div>
          {loadAmountError && <p style={{ color: "red" }}>{loadAmountError}</p>}

          <p style={{ margin: "20px 20px 0px 0px", fontWeight: 500 }}>
            Please note that the following fees will be deducted from the loading amount.
          </p>
          <ul style={{ margin: 0 }}>
            <li>{`Platform load fee: 1.7% of loading amount`}</li>
            <li>{`Load card fee: ${Big(card?.limit?.loadFeePercentage ?? 0).mul(100)}% of loading amount`}</li>
            <li>
              {`With a minimum charge of ${formatAmount(card?.limit?.minimumLoadFee, card?.currency)} ${
                card?.currency
              }`}
            </li>
            <li>{`Minimum load amount is ${formatAmount(5, card?.currency)} ${card?.currency}`}</li>
            <li>
              Total Spendable Amount:
              {` ${formatAmount(spendableAmount, card.currency)} ${card.currency}`}
            </li>
          </ul>
          <p style={{ margin: "10px 20px 0px 20px", fontWeight: 400, color: "red" }}>
            Please note once a card is loaded the funds cannot be returned to your account.
          </p>
          {/* <p style={{ margin: 0, fontWeight: 500 }}>Load card limits</p>
          <ul>
            <li>
              Single load limit: {formatAmount(card?.limit.dailyLoadLimitLeft, card?.currency)} {card?.currency}
            </li>
            <li>
              Daily load limit: {formatAmount(card?.limit.dailyLoadLimit, card?.currency)} {card?.currency}
            </li>
            <li>
              Monthly load limit: {formatAmount(card?.limit.monthlyLoadLimit, card?.currency)} {card?.currency}
            </li>
            <li>
              Maximum card balance: {formatAmount(card?.limit.maxCardBalance, card?.currency)} {card?.currency}
            </li>
            <li>
              Yearly load limit: {formatAmount(card?.limit.yearlyLoadLimit, card?.currency)} {card?.currency}
            </li>
          </ul> */}
          <div style={{ height: 50 }} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0rem 0.5rem"
          }}
        >
          <Button type="primary" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => _onSubmit()}>
            Load
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export const CardLoadModal = boundError(CardLoadModalRaw);
