import React, { useState, useEffect } from "react";
import { Spin, Menu, Button, Dropdown } from "antd";
import { boundError, showError } from "../../utils";
import { UserStakingItem } from "./UserStakingItem";
import StakingServices from "../../services/stakingServices";
import Emitter, { EmitterEvents } from "../../utils/eventEmitter";
import { DownOutlined } from "@ant-design/icons";
import { STAKING_STATUS } from "../../constants/stakingStatus";

const UserStakingsSectionRaw = () => {
  const [stakings, setStakings] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [filterByStatus, setFilterByStatus] = useState("");
  const limit = 90;
  const stakingServices = StakingServices.getInstance();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUserStakings();
    Emitter.on(EmitterEvents.REFRESH_STAKING_LIST, () => {
      refreshData();
    });
  }, [filterByStatus]);

  const refreshData = () => {
    setPageNum(0);
    fetchUserStakings();
  };

  const fetchUserStakings = async () => {
    try {
      setLoading(true);
      let result = await stakingServices.fetchUserStakings(pageNum * limit, limit);
      if (result && result?.length > 0) {
        if (filterByStatus) {
          result = (result || []).filter((s) => s.status == filterByStatus);
        }
        if (pageNum === 0) {
          setStakings(result);
        } else {
          setStakings((old) => [...old, ...result]);
        }
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  const handleDropdownClick = (e) => {
    setFilterByStatus(e.key);
  };

  const menu = (
    <Menu onClick={handleDropdownClick}>
      <Menu.Item key="">ALL</Menu.Item>
      {Object.keys(STAKING_STATUS)
        .filter(
          (status) =>
            status !== STAKING_STATUS.CREATED &&
            status !== STAKING_STATUS.IN_TERM &&
            status !== STAKING_STATUS.READY_TO_CLAIM
        )
        .map((m) => (
          <Menu.Item key={m}>{m}</Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Spin spinning={loading}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 50
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%"
          }}
        >
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button>
              Filter by status <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <div
          style={{
            backgroundColor: "white",
            width: "100%",
            marginTop: 10,
            borderRadius: 20
          }}
        >
          {!stakings || stakings.length === 0 ? (
            <p style={{ fontSize: 14, marginTop: 10 }}>No staking</p>
          ) : (
            stakings.map((staking, index) => (
              <UserStakingItem
                staking={staking}
                key={staking?.id}
                isFirst={index == 0}
                isLast={stakings.length - 1 === index}
              />
            ))
          )}
        </div>
      </div>
    </Spin>
  );
};

export const UserStakingsSection = boundError(UserStakingsSectionRaw);
