import React, { useState, useContext } from "react";
import {
  boundError,
  removeExtraSpaceChar,
  showError,
  sleep,
  isNumberOrLetter,
  FEATURE_NAMES,
  isValidForFeature
} from "../../utils";
import { Button, Divider, notification, Spin, Modal, Dropdown, Menu } from "antd";
import { StepMenu, AccountsSelector, BackBtn } from "..";
import { CardTypesDropDownSelector } from "./CardTypesDropDownSelector";
import { AccountsAndStakingSelector } from "./AccountsAndStakingSelector";
import { Input, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import {
  CreditCardOutlined,
  EditOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownOutlined,
  MobileOutlined
} from "@ant-design/icons";
import UserServices from "../../services/userServices";
import { useEffect } from "react";
import {
  doIssueCard,
  doGetDefaultCardProvider,
  doGetCardTypes,
  doActivateCard,
  doGetPresignedAgreementUrl,
  getConversionRateApi,
  doConvertToPhysicalCard
} from "../../apis";
import { CountrySelector } from "../CountrySelector";
import { getCountryObjByCode, getCountryNameByCode, countries } from "../../constants/country";
import { card_types } from "../../constants/cardType";
import { showSuccess } from "../../utils/messages";
import { formatAmount, upperCaseFirstCharEachForWord } from "../../utils";
import { TRANSACTION_TYPE } from "../../constants";
import { CURRENCY } from "../../constants/currency";
import Big from "big.js";
import { ConsentModal } from "../SharedComponents/consentModal";
import EUROPE_terms from "../../resources/consents/card/EUROPE_terms.pdf";
import NORTH_AMERICA_terms from "../../resources/consents/card/NORTH_AMERICA_terms.pdf";
import { UserContext } from "../../hooks";
import { isMobile } from "react-device-detect";

const CardApplyPageRaw = () => {
  const navigate = useNavigate();
  const userServices = UserServices.getInstance();
  const [user] = useContext(UserContext);
  const [contractChecked, setContractChecked] = useState(false);
  const [openExtraAgreementModal, setOpenExtraAgreementModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [requirePhysicalCard, setRequirePhysicalCard] = useState(true);
  const [useAlternativeName, setUseAlternativeName] = useState(false);
  const [sameBillingAddress, setSameBillingAddress] = useState(true);
  const [appliedSuccess, setAppliedSuccess] = useState(false);
  const [appliedSuccessText, setAppliedSuccessText] = useState("");
  const [showShippingAddressCard, setShowShippingAddressCard] = useState(true);
  const [showShippingAddressForm, setShowShippingAddressForm] = useState(false);
  const [showBillingAddressCard, setShowBillingAddressCard] = useState(true);
  const [showBillingAddressForm, setShowBillingAddressForm] = useState(false);
  const [showRedirectKycUpload, setShowRedirectKycUpload] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({ id: "", amount: 0 });
  const [userProfile, setUserProfile] = useState({
    addressCity: "",
    addressCountry: "",
    addressLine1: "",
    addressLine2: "",
    addressPostalCode: "",
    addressState: "",
    createdAt: "",
    dateOfBirth: "",
    email: "",
    firstName: "",
    id: "",
    lastName: "",
    phoneCountryCode: "",
    phoneNumber: "",
    phoneVerified: false,
    registrationCompleted: false,
    region: "",
    agreementUrl: "",
    status: "",
    updatedAt: "",
    userType: "",
    alternativeFirstName: "",
    alternativeLastName: ""
  });
  const [shippingAddress, setShippingAddress] = useState(userProfile);
  const [billingAddress, setBillingAddress] = useState(userProfile);
  const [selectedShippingCountry, setSelectedShippingCountry] = useState(null);
  const [selectedBillingCountry, setSelectedBillingCountry] = useState(null);
  const [cardTypes, setCardTypes] = useState([]);
  const [selectedCardType, setSelectedCardType] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cardProvider, setCardProvider] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const [activateFeeStr, setActivateFeeStr] = useState("");
  const [activateFee, setActivateFee] = useState();

  const [issueCardFee, setIssueCardFee] = useState();
  const [issueCardFeeCurrency, setIssueCardFeeCurrency] = useState();

  const [loadingActivationFee, setLoadingActivationFee] = useState(false);

  const [showEditBillingAddress, setShowEditBillingAddress] = useState(false);

  const [nameTooLong, setNameTooLong] = useState(false);
  const [nameHasSpecialCharacters, setNameHasSpecialCharacters] = useState(false);

  const [spendableAmountInUsd, setSpendableAmountInUsd] = useState();
  const [spendableAmountInAxc, setSpendableAmountInAxc] = useState();

  const [phone, setPhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States",
    dialCode: "+1",
    code: "US"
  });

  useEffect(() => {
    fetchUserData();
    fetchUserSpendable();
  }, []);

  useEffect(() => {
    if (sameBillingAddress) {
      setShippingAddress(billingAddress);
      setSelectedShippingCountry(selectedBillingCountry);
    } else {
      setShippingAddress({});
      setSelectedShippingCountry(null);
    }
  }, [sameBillingAddress]);

  useEffect(() => {}, [shippingAddress]);

  const fetchUserSpendable = async () => {
    const data = await userServices.getUserSpendableAmount();
    setSpendableAmountInUsd(data.spendableAmountInUsd);
    setSpendableAmountInAxc(data.spendableAmountInAxc);
  };

  const getNewActivationFee = async () => {
    try {
      setLoadingActivationFee(true);
      const displayCurrency = CURRENCY.USD.code;

      const rates = {};

      /// get Axia issue card fee info from cardType
      const axiaIssueCurrency = selectedCardType?.issueFeeCurrency;
      const axiaIssueCurrencyRateResult = await getConversionRateApi(axiaIssueCurrency, displayCurrency);
      const axiaIssueCurrencyRate = Big(axiaIssueCurrencyRateResult?.conversionRate);

      rates[axiaIssueCurrency] = axiaIssueCurrencyRate;
      let axiaIssueFee = Big(axiaIssueCurrencyRate).mul(selectedCardType?.issueFeeAmount);
      if (requirePhysicalCard === true && isValidForFeature({ user, featureName: FEATURE_NAMES.CARD_COURIER_OPTION })) {
        /// if issue physical card, add issue physical card fee
        axiaIssueFee = Big(selectedCardType?.issueFeeAmount)
          ?.plus(selectedCardType?.issuePhysicalFeeAmount)
          ?.plus(selectedCardType?.shippingHandlingAmount)
          .mul(axiaIssueCurrencyRate);
      }

      // provider fee will be charged as part of internal transaction fee configured in transaction_type, this amount is only for recording purpose
      const providerFeeCurrency = cardProvider?.issueFeeCurrency;
      let providerFeeCurrencyRate;
      if (!rates[providerFeeCurrency]) {
        const providerFeeCurrencyRateResult = await getConversionRateApi(providerFeeCurrency, displayCurrency);
        providerFeeCurrencyRate = Big(providerFeeCurrencyRateResult?.conversionRate);
      } else {
        providerFeeCurrencyRate = rates[providerFeeCurrency];
      }

      // let providerIssueFee = Big(providerFeeCurrencyRate).mul(cardProvider?.issueFeeAmount);
      let providerIssueFee = Big(0);
      // if (requirePhysicalCard === true) {
      //   providerIssueFee = Big(cardProvider?.issueFeeAmount)
      //     ?.plus(cardProvider?.issuePhysicalFeeAmount)
      //     .mul(providerFeeCurrencyRate);
      // }

      const totalIssueCardFee = axiaIssueFee?.plus(providerIssueFee);
      if (totalIssueCardFee?.gt(0)) {
        setIssueCardFee(totalIssueCardFee?.toNumber());
      } else {
        setIssueCardFee(undefined);
      }
      const feeStr = formatAmount(totalIssueCardFee?.toFixed(), displayCurrency) + " " + displayCurrency;
      setIssueCardFeeCurrency(displayCurrency);
      setActivateFeeStr(feeStr);
      setActivateFee(totalIssueCardFee);
    } catch (err) {
      showError(err);
    } finally {
      setLoadingActivationFee(false);
    }
  };

  const verifyAddress = (address) => {
    const validAddLine1 = isNumberOrLetter(removeExtraSpaceChar(address?.addressLine1));
    const validAddLine2 = isNumberOrLetter(removeExtraSpaceChar(address?.addressLine2));
    const validCity = isNumberOrLetter(removeExtraSpaceChar(address?.addressCity));
    const validState = isNumberOrLetter(removeExtraSpaceChar(address?.addressState));
    const validCountry = isNumberOrLetter(address?.addressCountry);
    const validPostalCode = isNumberOrLetter(removeExtraSpaceChar(address?.addressPostalCode));

    const addressErr = [
      validAddLine1 || address?.addressLine1,
      validAddLine2 || address?.addressLine2,
      validCity || address?.addressCity,
      validState || address?.addressState,
      validCountry || address?.addressCountry,
      validPostalCode || address?.addressPostalCode
    ].filter((err) => typeof err === "string");

    if (addressErr.length) {
      return {
        valid: false,
        data: addressErr
      };
    }
    return {
      valid: true,
      data: []
    };
  };

  const requirePhysicalCardChanged = (e) => {
    setRequirePhysicalCard(!e.target.checked);
  };
  const useAlternativeNameChanged = (e) => {
    setUseAlternativeName(e.target.checked);
  };

  const sameAsBillingAddress = (e) => {
    setSameBillingAddress(e.target.checked);
    setShowShippingAddressForm(!e.target.checked);
    setShowShippingAddressCard(e.target.checked);
  };

  const contractCheckChanged = (e) => {
    setContractChecked(e.target.checked);
  };

  const fetchUserData = async () => {
    setLoading(true);
    try {
      let user = await userServices.getUserProfile();
      if (user?.region) {
        const url = await doGetPresignedAgreementUrl(user?.region);
        user = { ...user, agreementUrl: url };
      }

      setUserProfile(user);

      if (user?.firstName?.trim()?.length + user?.lastName?.trim()?.length > 20) {
        setNameTooLong(true);
      }

      if (!isNumberOrLetter(user?.firstName?.trim()) || !isNumberOrLetter(user?.lastName?.trim())) {
        setNameHasSpecialCharacters(true);
      }

      if (
        user?.firstName?.trim()?.length + user?.lastName?.trim()?.length > 20 ||
        !isNumberOrLetter(user?.firstName?.trim()) ||
        !isNumberOrLetter(user?.lastName?.trim())
      ) {
        setUseAlternativeName(true);
      } else {
        setUseAlternativeName(false);
      }
      setShippingAddress(user);
      setBillingAddress(user);
      if (!verifyAddress(user).valid) {
        setShowEditBillingAddress(true);
      } else {
        setShowEditBillingAddress(false);
      }
      setSelectedShippingCountry(getCountryObjByCode(user?.addressCountry));
      setSelectedBillingCountry(getCountryObjByCode(user?.addressCountry));
      const availableCardTypes = await doGetCardTypes({ countryCode: user?.addressCountry, type: card_types.NORMAL });
      setCardTypes(availableCardTypes);

      const provider = await doGetDefaultCardProvider();
      setCardProvider(provider);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const _onSubmit = async () => {
    setLoading(true);
    setLoadingText("Issuing card, please wait...");
    try {
      const requestBody = {
        deliveryAddress1: (shippingAddress.addressLine1 || "").trim(),
        deliveryAddress2: (shippingAddress.addressLine2 || "").trim(),
        deliveryCity: (shippingAddress.addressCity || "").trim(),
        deliveryCountryState: (shippingAddress.addressState || "").trim(),
        deliveryCountryCode: (shippingAddress.addressCountry || "").trim(),
        deliveryPostCode: (shippingAddress.addressPostalCode || "").trim(),
        convertToPlastic: false,
        cardTypeCode: selectedCardType?.code,
        useAlternativeName,
        addressLine1: (billingAddress.addressLine1 || "").trim(),
        addressLine2: (billingAddress.addressLine2 || "").trim(),
        addressCity: (billingAddress.addressCity || "").trim(),
        addressState: (billingAddress.addressState || "").trim(),
        addressCountry: (billingAddress.addressCountry || "").trim(),
        addressPostalCode: (billingAddress.addressPostalCode || "").trim(),
        phoneNumber
      };

      if (selectedAccount?.from === "ACCOUNT") {
        requestBody.fromAccountId = selectedAccount.id;
      } else if (selectedAccount?.from === "STAKING") {
        requestBody.fromStakingId = selectedAccount.id;
      }

      if (!isValidForFeature({ user, featureName: FEATURE_NAMES.CARD_COURIER_OPTION })) {
        requestBody.delivery = "Standard";

        const result = await doIssueCard(requestBody);
        setLoadingText("Issuing card succeeded, we are activating the card for you, please wait...");

        await sleep(1000);
        await doActivateCard(result?.id)
          .then(() => {
            const text = "Congratulations! Your card is activated!";
            showSuccess(text);
            setAppliedSuccessText(text);
            setAppliedSuccess(true);
            setShowRedirectKycUpload(true);
          })
          .catch((_) => {
            const text = "Issuing card succeeded! You can go to card detail page to activate your card!";
            showSuccess(text);
            setAppliedSuccessText(text);

            setAppliedSuccess(true);
          });
      } else {
        requestBody.delivery = "Courier";

        const result = await doIssueCard(requestBody);
        setLoadingText("Issuing card succeeded, we are activating the card for you, please wait...");

        await sleep(1000);

        await doActivateCard(result?.id);

        await sleep(1000);
        const submitData = {
          cardId: result?.id,
          ...(requestBody.fromAccountId ? { fromAccountId: requestBody.fromAccountId } : null),
          ...(requestBody.fromStakingId ? { fromStakingId: requestBody.fromStakingId } : null)
        };
        await doConvertToPhysicalCard(submitData).catch((_) => {
          const text = "Issuing card succeeded! You can go to card detail page to activate your card!";
          showSuccess(text);
          setAppliedSuccessText(text);

          setAppliedSuccess(true);
        });
        const text = "Congratulations! Your card is activated!";
        showSuccess(text);
        setAppliedSuccessText(text);
        setAppliedSuccess(true);
        setShowRedirectKycUpload(true);
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const onCardTypeChanged = (data) => {
    setSelectedCardType(data);
    setSelectedAccount({ id: "", amount: 0 });
  };

  const getAlternativeName = () => {
    if (userProfile?.alternativeFirstName?.trim() && userProfile?.alternativeLastName?.trim()) {
      return `${userProfile?.alternativeFirstName} ${userProfile?.alternativeLastName}`;
    }
    return "";
  };

  const getName = () => {
    if (useAlternativeName) {
      return getAlternativeName();
    }

    return `${userProfile?.firstName} ${userProfile?.lastName}`;
  };

  const renderCardHolder = () => {
    return (
      <div className="card-holder-row">
        {getName()?.trim() && (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "start", maxWidth: 600 }}>
            <p className="title">Name on card</p>
            <Input style={{ padding: "16px 24px" }} value={getName()} disabled={true} />
          </div>
        )}

        {!getName()?.trim() && (nameTooLong || nameHasSpecialCharacters) && (
          <p className="margin-none" style={{ marginTop: 30, fontWeight: "500", color: "red" }}>
            {nameTooLong && <p>- The length of your name exceeds the maximum length allowed. </p>}
            {nameHasSpecialCharacters && <p>- The name should only contain letters. </p>}
            <p>
              {"Please contact support at "}
              <a href="mailto: support@axiacapitalbank.com">support@axiacapitalbank.com</a>
            </p>
            .
          </p>
        )}

        {getName()?.trim() && requirePhysicalCard !== true && (
          <p className="margin-none" style={{ marginTop: 30, fontWeight: "500" }}>
            Note: AXIA Physical Debit card will become available for purchase when you complete the virtual card order.
          </p>
        )}

        {loading === false && cardTypes?.length > 0 === false && (
          <p className="margin-none" style={{ color: "red", fontWeight: "700", marginTop: 20, fontSize: 18 }}>
            * Card service is not available in your country at the moment.
          </p>
        )}
      </div>
    );
  };

  const renderAddress = () => {
    return (
      <>
        <div
          style={{
            alignItems: "stretch",
            textAlign: "left",
            width: "300px",
            marginBottom: "20px"
          }}
        >
          <p className="title">Currency</p>
          <CardTypesDropDownSelector
            className="card-types-dropdown-selector"
            options={cardTypes}
            selectedCardType={selectedCardType}
            onChange={onCardTypeChanged}
            placeholder={"Select currency type for card"}
            style={{ margin: "0rem 0rem 0.5rem 0rem" }}
            settings={{ dropdownMatchSelectWidth: false }}
          />
          <p className="title">Billing Address</p>
          {showBillingAddressCard && renderShowBillingAddressCard()}
          {showBillingAddressForm && renderShowBillingAddressForm()}
          {!verifyAddress(billingAddress).valid && (
            <p style={{ color: "red" }}>
              The address should only contain space, letters or numbers, please edit it.{" "}
              {verifyAddress(billingAddress).data.map((err) => "(" + err + ")")}
            </p>
          )}
        </div>
        {<div className="separator"></div>}
        {/* ship to section */}
        {
          <div
            style={{
              width: "300px"
            }}
          >
            <div>
              {requirePhysicalCard ? (
                <p style={{ fontWeight: "500", fontSize: "15px", marginBottom: 5, textAlign: "left" }}>
                  Your physical card will be shipped to:
                </p>
              ) : (
                <p style={{ fontWeight: "500", fontSize: "15px", marginBottom: 5, textAlign: "left" }}>
                  This address will be used when you apply for a physical card later. Please enter the address you want
                  the card to be shipped. You may not be able to edit this address later.
                </p>
              )}

              <div
                style={{
                  display: "flex",
                  backgroundColor: "#F8F9FA",
                  borderRadius: 12,
                  padding: 10
                }}
              >
                <Checkbox checked={sameBillingAddress} onChange={sameAsBillingAddress}>
                  The same as billing address
                </Checkbox>
              </div>
            </div>
            {showShippingAddressCard && renderShowShippingAddressCard()}
            {showShippingAddressForm && renderShowShippingAddressForm()}
            {!verifyAddress(shippingAddress).valid && (
              <p style={{ color: "red", textAlign: "left" }}>
                The address should only contain space, letters or numbers, please edit it.{" "}
                {verifyAddress(billingAddress).data.map((err) => "(" + err + ")")}
              </p>
            )}
          </div>
        }
      </>
    );
  };

  const renderShowBillingAddressCard = () => {
    return (
      <div className="billing-address-box">
        {showEditBillingAddress && (
          <div
            style={{ float: "right", fontWeight: 500, color: "#178FE1" }}
            onClick={() => {
              setShowBillingAddressForm(true);
              setShowBillingAddressCard(false);
            }}
          >
            <EditOutlined style={{ color: "#178FE1" }} /> Edit
          </div>
        )}
        <p style={{ fontWeight: "600" }}>
          {upperCaseFirstCharEachForWord(
            removeExtraSpaceChar(useAlternativeName ? userProfile?.alternativeFirstName : userProfile?.firstName)
          ) +
            " " +
            upperCaseFirstCharEachForWord(
              removeExtraSpaceChar(useAlternativeName ? userProfile?.alternativeLastName : userProfile?.lastName)
            )}
        </p>
        <p>{upperCaseFirstCharEachForWord(removeExtraSpaceChar(billingAddress?.addressLine1)) || "N/A"}</p>
        <p>{upperCaseFirstCharEachForWord(removeExtraSpaceChar(billingAddress?.addressLine2)) || ""}</p>
        <p>
          {(upperCaseFirstCharEachForWord(removeExtraSpaceChar(billingAddress?.addressCity)) || "N/A") + ", "}
          {upperCaseFirstCharEachForWord(removeExtraSpaceChar(billingAddress?.addressState)) || "N/A"}
        </p>
        <p>
          {(getCountryNameByCode(billingAddress?.addressCountry) || "N/A") + ", "}
          {upperCaseFirstCharEachForWord(removeExtraSpaceChar(billingAddress?.addressPostalCode)) || "N/A"}
        </p>
      </div>
    );
  };
  const renderShowShippingAddressCard = () => {
    return (
      <>
        <p className="title">Physical Card Shipping Address</p>
        <div className="billing-address-box">
          {sameBillingAddress !== true && (
            <div
              style={{ float: "right", fontWeight: 500, color: "#178FE1" }}
              onClick={() => {
                setShowShippingAddressForm(true);
                setShowShippingAddressCard(false);
              }}
            >
              <EditOutlined style={{ color: "#178FE1" }} /> Edit
            </div>
          )}
          <p style={{ fontWeight: "600" }}>
            {upperCaseFirstCharEachForWord(
              removeExtraSpaceChar(useAlternativeName ? userProfile?.alternativeFirstName : userProfile?.firstName)
            ) +
              " " +
              upperCaseFirstCharEachForWord(
                removeExtraSpaceChar(useAlternativeName ? userProfile?.alternativeLastName : userProfile?.lastName)
              )}
          </p>
          <p>{upperCaseFirstCharEachForWord(removeExtraSpaceChar(shippingAddress.addressLine1)) || "N/A"}</p>
          <p>{upperCaseFirstCharEachForWord(removeExtraSpaceChar(shippingAddress.addressLine2)) || ""}</p>
          <p>
            {(upperCaseFirstCharEachForWord(removeExtraSpaceChar(shippingAddress.addressCity)) || "N/A") + ", "}{" "}
            {upperCaseFirstCharEachForWord(removeExtraSpaceChar(shippingAddress.addressState)) || "N/A"}
          </p>
          <p>
            {(getCountryNameByCode(removeExtraSpaceChar(shippingAddress.addressCountry)) || "N/A") + ", "}
            {shippingAddress?.addressPostalCode?.toUpperCase() || "N/A"}
          </p>
        </div>
        {isValidForFeature({ user, featureName: FEATURE_NAMES.CARD_COURIER_OPTION }) && (
          <>
            <p className="title">Phone Number</p>
            {/* <Input
              placeholder="1-416-888-8888"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            /> */}
            <div className="row">
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                overlayStyle={{ zIndex: 9999, overflow: "scroll", maxHeight: "100vh" }}
              >
                <Button
                  style={{
                    fontWeight: "400",
                    fontSize: 16,
                    border: "2px solid #B1B5C4",
                    borderRadius: 12,
                    padding: 10,
                    minHeight: 50,
                    marginRight: 5
                  }}
                  onClick={(e) => console.log(e)}
                >
                  {selectedCountry?.dialCode} <DownOutlined />
                </Button>
              </Dropdown>
              <Input
                placeholder="Phone number"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value.replace(/[^\d]/g, ""));
                }}
                style={{
                  width: 300,
                  fontWeight: "400",
                  fontSize: 16,
                  border: "2px solid #B1B5C4",
                  borderRadius: 12,
                  padding: 10
                }}
              />
            </div>
          </>
        )}
      </>
    );
  };

  const concatePhoneNumber = () => {
    const areaCode = selectedCountry?.dialCode.replace(/[^\d]/g, "");
    const phoneNo = phone.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    setPhoneNumber(areaCode + "-" + phoneNo);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {countries.map((country, index) => {
        return (
          <Menu.Item key={index} icon={<MobileOutlined />}>
            {country.name + " " + country.dialCode}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  function handleMenuClick(e) {
    setSelectedCountry(countries[e.key]);
  }

  const FieldTitle = ({ fieldKey, required = false }) => {
    const requiredFields = {
      firstName: "First name",
      lastName: "Last name",
      addressLine1: "Address line 1",
      addressLine2: "Address line 2",
      addressCity: "City",
      addressState: "State/Province",
      addressPostalCode: "Zip/Postal Code",
      addressCountry: "Country"
    };

    return (
      <div>
        <p className="margin-none" style={{ fontSize: 16, marginTop: 10 }}>
          {required === true && <span style={{ color: "red" }}>{"* "}</span>}
          {requiredFields[fieldKey]}
        </p>
        {errorMsgs[fieldKey] && (
          <p className="margin-none" style={{ color: "red", marginLeft: 20 }}>
            {"* " + errorMsgs[fieldKey]}
          </p>
        )}
      </div>
    );
  };

  const renderShowBillingAddressForm = () => {
    return (
      <div className="title">
        <FieldTitle fieldKey={"firstName"} required={true} />
        <Input
          placeholder="First Name"
          value={upperCaseFirstCharEachForWord(
            removeExtraSpaceChar(useAlternativeName ? userProfile?.alternativeFirstName : userProfile?.firstName)
          )}
          onChange={(e) => {
            // setBillingAddress({ ...billingAddress, firstName: e.target.value });
          }}
          disabled={true}
        />

        <FieldTitle fieldKey={"lastName"} required={true} />
        <Input
          placeholder="Last Name"
          value={upperCaseFirstCharEachForWord(
            removeExtraSpaceChar(useAlternativeName ? userProfile?.alternativeLastName : userProfile?.lastName)
          )}
          onChange={(e) => {
            // setBillingAddress({ ...billingAddress, lastName: e.target.value });
          }}
          disabled={true}
        />

        <FieldTitle fieldKey={"addressLine1"} required={true} />
        <Input
          placeholder="Address Line 1"
          value={billingAddress.addressLine1}
          onChange={(e) => {
            setBillingAddress({ ...billingAddress, addressLine1: e.target.value });
          }}
        />

        <FieldTitle fieldKey={"addressLine2"} required={false} />
        <Input
          placeholder="Address Line 2"
          value={billingAddress.addressLine2}
          onChange={(e) => {
            setBillingAddress({ ...billingAddress, addressLine2: e.target.value });
          }}
        />

        <FieldTitle fieldKey={"addressCity"} required={true} />
        <Input
          placeholder="City"
          value={billingAddress.addressCity}
          onChange={(e) => {
            setBillingAddress({ ...billingAddress, addressCity: e.target.value });
          }}
        />

        <FieldTitle fieldKey={"addressState"} required={true} />
        <Input
          placeholder="State/Province"
          value={billingAddress.addressState}
          onChange={(e) => {
            setBillingAddress({ ...billingAddress, addressState: e.target.value });
          }}
        />

        <FieldTitle fieldKey={"addressPostalCode"} required={true} />
        <Input
          placeholder="Zip/Postal Code"
          value={billingAddress.addressPostalCode}
          onChange={(e) => {
            setBillingAddress({ ...billingAddress, addressPostalCode: e.target.value });
          }}
        />

        <FieldTitle fieldKey={"addressCountry"} required={true} />
        <CountrySelector
          style={{ height: 50 }}
          // defaultOptionLabel="Change country"
          selectedCountry={selectedBillingCountry}
          onChange={(country) => {
            setBillingAddress({
              ...billingAddress,
              addressCountry: country.code
            });
            setSelectedBillingCountry(country);
          }}
        />
        <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
          <Button
            type="primary"
            style={{
              backgroundColor: "white",
              border: "1px solid #3b82f6",
              borderRadius: 10,
              color: "#3b82f6",
              width: "50%"
            }}
            onClick={() => {
              setSameBillingAddress(true);
              setShowBillingAddressForm(false);
              setShowBillingAddressCard(true);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              if (!verifyAddress(billingAddress).valid) {
                showError("Your billing address contains invalid letters.");
                return;
              }

              if (validateDeliveryInfo("Billing Address")) {
                setShowBillingAddressForm(false);
                setShowBillingAddressCard(true);
                if (sameBillingAddress) {
                  setShippingAddress(billingAddress);
                }
                showSuccess("Billing address updated successfully.");
              }
            }}
            style={{
              borderRadius: 10,
              marginLeft: 10,
              width: "50%"
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };
  const renderShowShippingAddressForm = () => {
    return (
      <div className="title">
        <FieldTitle fieldKey={"firstName"} required={true} />
        <Input
          placeholder="First Name"
          value={upperCaseFirstCharEachForWord(
            removeExtraSpaceChar(useAlternativeName ? userProfile?.alternativeFirstName : userProfile?.firstName)
          )}
          onChange={(e) => {
            // setShippingAddress({ ...shippingAddress, firstName: e.target.value });
          }}
          disabled={true}
        />

        <FieldTitle fieldKey={"lastName"} required={true} />
        <Input
          placeholder="Last Name"
          value={upperCaseFirstCharEachForWord(
            removeExtraSpaceChar(useAlternativeName ? userProfile?.alternativeLastName : userProfile?.lastName)
          )}
          onChange={(e) => {
            // setShippingAddress({ ...shippingAddress, lastName: e.target.value });
          }}
          disabled={true}
        />

        <FieldTitle fieldKey={"addressLine1"} required={true} />
        <Input
          placeholder="Address Line 1"
          value={shippingAddress.addressLine1}
          onChange={(e) => {
            setShippingAddress({ ...shippingAddress, addressLine1: e.target.value });
          }}
        />

        <FieldTitle fieldKey={"addressLine2"} required={false} />
        <Input
          placeholder="Address Line 2"
          value={shippingAddress.addressLine2}
          onChange={(e) => {
            setShippingAddress({ ...shippingAddress, addressLine2: e.target.value });
          }}
        />

        <FieldTitle fieldKey={"addressCity"} required={true} />
        <Input
          placeholder="City"
          value={shippingAddress.addressCity}
          onChange={(e) => {
            setShippingAddress({ ...shippingAddress, addressCity: e.target.value });
          }}
        />

        <FieldTitle fieldKey={"addressState"} required={true} />
        <Input
          placeholder="State/Province"
          value={shippingAddress.addressState}
          onChange={(e) => {
            setShippingAddress({ ...shippingAddress, addressState: e.target.value });
          }}
        />

        <FieldTitle fieldKey={"addressPostalCode"} required={true} />
        <Input
          placeholder="Zip/Postal Code"
          value={shippingAddress.addressPostalCode}
          onChange={(e) => {
            setShippingAddress({ ...shippingAddress, addressPostalCode: e.target.value });
          }}
        />

        <FieldTitle fieldKey={"addressCountry"} required={true} />
        <CountrySelector
          style={{ height: 50 }}
          // defaultOptionLabel="Change country"
          selectedCountry={selectedShippingCountry}
          onChange={(country) => {
            setShippingAddress({
              ...shippingAddress,
              addressCountry: country.code
            });
            setSelectedShippingCountry(country);
          }}
        />
        <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
          <Button
            type="primary"
            style={{
              backgroundColor: "white",
              border: "1px solid #3b82f6",
              borderRadius: 10,
              color: "#3b82f6",
              width: "50%"
            }}
            onClick={() => {
              setSameBillingAddress(true);
              setShowShippingAddressForm(false);
              setShowShippingAddressCard(true);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              if (!verifyAddress(shippingAddress).valid) {
                showError("Your shipping address contains invalid letters.");
                return;
              }
              if (validateDeliveryInfo("Shipping Address")) {
                setShowShippingAddressForm(false);
                setShowShippingAddressCard(true);
                showSuccess("Shipping address updated successfully.");
              }
            }}
            style={{
              borderRadius: 10,
              marginLeft: 10,
              width: "50%"
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  const renderPayment = () => {
    if (isValidForFeature({ user, featureName: FEATURE_NAMES.CARD_COURIER_OPTION })) {
      return (
        <>
          <div
            style={{
              alignItems: "stretch",
              textAlign: "left",
              width: isMobile ? "100%" : "800px",
              marginBottom: "20px"
            }}
          >
            <div
              style={{
                borderRadius: 12,
                backgroundColor: "#E9F6FF",
                padding: "10px 30px"
              }}
            >
              <p style={{ fontSize: "1rem", fontWeight: "500", marginBottom: 5, textAlign: "center" }}>
                Card Fee + Shipping & Handling
              </p>
              <p style={{ fontSize: "1rem", fontWeight: "500", marginBottom: 5, textAlign: "center" }}>
                {activateFeeStr}
              </p>
            </div>

            <br />
            <p>Please select an account to deduct amount:</p>
            <Spin spinning={loadingActivationFee}>
              {!loadingActivationFee && (
                <AccountsAndStakingSelector
                  currency={selectedCardType?.currency}
                  style={{ width: "100%", marginBottom: "20px" }}
                  onAccountSelected={(account) => {
                    setSelectedAccount(account);
                  }}
                  selectedAccount={selectedAccount}
                  placeholder={"Please select an account"}
                  transactionType={TRANSACTION_TYPE.CARD_ISSUE}
                  validSpendableAmount={issueCardFee}
                  validAmountCurrency={issueCardFeeCurrency}
                  displaySpendableAmount={true}
                />
              )}
            </Spin>
            <ul style={{ margin: 0 }}>
              {/* <li>
                User Spendable Amount In AXC:
                {` ${formatAmount(spendableAmountInAxc, CURRENCY.AXC.code)} ${CURRENCY.AXC.code}`}
              </li> */}
              <li>
                Total Spendable Amount:
                {` ${formatAmount(spendableAmountInUsd, CURRENCY.USD.code)} ${CURRENCY.USD.code}`}
              </li>
              <li>Please allow 3-8 business days for delivery. Delivery time depends on your location.</li>
            </ul>
          </div>
          {<div className="separator"></div>}
          {/* order summary */}
          {renderOrderSummary()}
        </>
      );
    } else {
      return (
        <div style={{ marginBottom: "40px" }}>
          <div
            style={{
              borderRadius: 12,
              backgroundColor: "#E9F6FF",
              padding: "10px 30px"
            }}
          >
            <p style={{ fontSize: "1rem", fontWeight: "500", marginBottom: 5 }}>New Card Activation Fee:</p>
            <p style={{ fontSize: "1.5rem", fontWeight: "500", margin: 0 }}>{activateFeeStr}</p>
          </div>

          <br />
          <p>
            Please select an account to pay for the activation fee:
            <br />
            (default charge activation fee, no additional fee)
          </p>
          <Spin spinning={loadingActivationFee}>
            {!loadingActivationFee && (
              <AccountsAndStakingSelector
                currency={selectedCardType?.currency}
                style={{ width: "100%", marginBottom: "20px" }}
                onAccountSelected={(account) => {
                  setSelectedAccount(account);
                }}
                selectedAccount={selectedAccount}
                placeholder={"Please select an account"}
                transactionType={TRANSACTION_TYPE.CARD_ISSUE}
                validSpendableAmount={issueCardFee}
                validAmountCurrency={issueCardFeeCurrency}
                displaySpendableAmount={true}
              />
            )}
          </Spin>
          <p style={{ textAlign: "left", fontSize: 14 }}>
            User Spendable Amount In AXC:
            {` ${formatAmount(spendableAmountInAxc, CURRENCY.AXC.code)} ${CURRENCY.AXC.code}`}
          </p>
          <p style={{ textAlign: "left", fontSize: 14 }}>
            User Spendable Amount In USD:
            {` ${formatAmount(spendableAmountInUsd, CURRENCY.USD.code)} ${CURRENCY.USD.code}`}
          </p>
        </div>
      );
    }
  };

  const renderOrderSummary = () => {
    const physicalCardFee = Big(selectedCardType?.issuePhysicalFeeAmount || 0);
    const virtualCardFee = Big(selectedCardType?.issueFeeAmount || 0);
    const shippingFee = Big(selectedCardType?.shippingHandlingAmount || 0);
    const total = physicalCardFee.plus(virtualCardFee).plus(shippingFee);

    return (
      <div
        style={{
          width: isMobile ? "100%" : "300px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          fontSize: 15
        }}
      >
        <div>
          <h1>Order Summary</h1>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <span>Physical Card Fee:</span>
          <span>$ {physicalCardFee.toFixed()}</span>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <span>Virtual Card Fee:</span>
          <span>$ {virtualCardFee.toFixed()}</span>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <span>Shipping & Handling Fee:</span>
          <span>$ {shippingFee.toFixed()}</span>
        </div>
        <Divider
          style={{
            marginTop: 80
          }}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <h1>Order Total:</h1>
          <h1>$ {total.toFixed()}</h1>
        </div>
      </div>
    );
  };

  const renderAgreement = () => {
    return (
      <div
        style={{ paddingTop: 20, paddingBottom: 20, alignItems: "center", display: "flex", flexDirection: "column" }}
      >
        <Button
          onClick={() => {
            setOpenExtraAgreementModal(true);
          }}
          className="cursor-pointer"
          style={{
            display: "flex",
            color: "#fff",
            borderColor: contractChecked !== true ? "#5CB1FF" : "#1890ff",
            backgroundColor: contractChecked !== true ? "#5CB1FF" : "#1890ff",
            // textShadow: "0 -1px 0 rgb(0 0 0 / 12%)",
            justifyContent: "center",
            alignItems: "center",
            padding: "14px 30px",
            borderRadius: 10,
            hover: "pointer",
            width: "40vw",
            minWidth: "240px",
            height: "auto",
            whiteSpace: "pre-wrap"
          }}
        >
          {/* <Checkbox checked={contractChecked} onChange={contractCheckChanged} style={{ margin: "0px 10px 10px 0px" }} /> */}
          <p
            className="margin-none"
            style={{
              fontSize: 22
            }}
          >
            {`${contractChecked === true ? "I've reviewed and approved" : "Review and Approve"} Terms and Conditions`}
          </p>
        </Button>
        {/* {requirePhysicalCard && (
          <p style={{ fontSize: 16, fontWeight: "500" }}>
            Please allow 5-6 weeks for delivery. Delivery time depends on your location.
          </p>
        )} */}
        {/* {!requirePhysicalCard && (
          <p style={{ fontSize: 16, fontWeight: "500" }}>
            Card will be available in a few minutes after successful application.
          </p>
        )} */}
        {appliedSuccessText && <p style={{ fontSize: 16, fontWeight: "500", marginTop: 30 }}>{appliedSuccessText}</p>}
        {showRedirectKycUpload && (
          <>
            <p style={{ fontSize: 16, fontWeight: "500" }}>
              To gain the full benefit of this program and increase your spendable limit you will need to be KYC
              verified. <br />
              Please click 'Get KYC Verified' and select 'Debit Card Identity Verification - Limit Increase Application'
              to upload your KYC documents.
            </p>
            <Button
              type="primary"
              style={{
                color: "#fff",
                borderColor: "#1890ff",
                backgroundColor: "#1890ff",
                justifyContent: "center",
                borderRadius: 10
              }}
              onClick={() => navigate("/upload-document")}
            >
              Get KYC verified
            </Button>
          </>
        )}
        {renderExtraAgreement(userProfile?.region)}
      </div>
    );
  };

  const [errorMsgs, setErrorMsgs] = useState({});
  const validateDeliveryInfo = (type) => {
    let isValidate = true;
    setErrorMsgs({});

    const requiredFields = {
      // firstName: "First name",
      // lastName: "Last name",
      addressLine1: "Address line 1",
      addressCity: "City",
      addressState: "State/Province",
      addressPostalCode: "Zip/Postal Code",
      addressCountry: "Country"
    };

    for (const k in requiredFields) {
      if (type === "Shipping Address") {
        if (!shippingAddress[k]) {
          isValidate = false;

          setErrorMsgs((prev) => ({
            ...prev,
            [k]: `${requiredFields[k]} is required!`
          }));

          setShowShippingAddressForm(true);
          setShowShippingAddressCard(false);
        }
      } else if (type === "Billing Address") {
        if (!billingAddress[k]) {
          isValidate = false;

          setErrorMsgs((prev) => ({
            ...prev,
            [k]: `${requiredFields[k]} is required!`
          }));

          setShowBillingAddressForm(true);
          setShowBillingAddressCard(false);
        }
      }
    }

    return isValidate;
  };

  const validations = (steps) => {
    if (steps == 1) {
      if (!selectedCardType) {
        showError("Please select currency to apply for your card!");
        return false;
      }
      if (isValidForFeature({ user, featureName: FEATURE_NAMES.CARD_COURIER_OPTION })) {
        if (!phone) {
          showError("Please enter phone number to apply for your card!");
          return false;
        } else {
          // const regex = /^\d{1,}-\d{1,}-\d{1,}-\d{1,}$/;
          // if (!regex.test(phoneNumber)) {
          //   showError("Please enter a valid phone number");
          //   return false;
          // }
          if (phone.length < 9) {
            showError("Please enter a valid phone number");
            return false;
          }
          concatePhoneNumber();
        }
      }

      return validateDeliveryInfo("Shipping Address") && validateDeliveryInfo("Billing Address");
    }
    if (steps == 2 && !selectedAccount.id) {
      showError("Please select an account for the amount to transfer from.");
      return false;
    }

    if (steps == 2) {
      if (activateFee && Big(selectedAccount.amount ?? 0).lt(activateFee)) {
        showError("Insufficient account balance!");
        return false;
      }
    }

    if (steps == 3 && !contractChecked) {
      showError("Please read our condition and check for continue!");
      return false;
    }
    return true;
  };

  const disabledBtn = (currentStep) => {
    if (userProfile?.firstName?.length + userProfile?.lastName?.length > 20 && !useAlternativeName) {
      return true;
    }
    if ((!userProfile?.alternativeFirstName || !userProfile?.alternativeLastName) && useAlternativeName) {
      return true;
    }
    if (currentStep === 0 && cardTypes?.length > 0 === false) {
      return true;
    }

    if (
      currentStep === 1 &&
      (!verifyAddress(billingAddress).valid ||
        !verifyAddress(shippingAddress).valid ||
        !selectedCardType ||
        (!phone && isValidForFeature({ user, featureName: FEATURE_NAMES.CARD_COURIER_OPTION })))
    ) {
      return true;
    }

    return false;
  };

  const renderBottomBtn = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row", margin: "auto" }}>
        <Button
          type="primary"
          style={{
            backgroundColor: "white",
            border: "1px solid #3b82f6",
            borderRadius: 10,
            color: "#3b82f6",
            minWidth: "75px",
            fontSize: "13px"
          }}
          onClick={() => {
            if (appliedSuccess) {
              navigate("/cards");
            } else if (currentStep == 0) {
              navigate("/cards");
            } else {
              setCurrentStep((prev) => prev - 1);
            }
          }}
        >
          {currentStep != 0 && !appliedSuccess && <ArrowLeftOutlined />}
          {appliedSuccess === true ? "Confirm" : currentStep != 0 ? "Back" : "Cancel"}
        </Button>
        {!appliedSuccess && (
          <Button
            type="primary"
            disabled={disabledBtn(currentStep)}
            onClick={async () => {
              if (validations(currentStep)) {
                if (currentStep == 3) {
                  _onSubmit();
                } else {
                  if (currentStep === 1) {
                    getNewActivationFee();
                  }
                  setCurrentStep((prev) => prev + 1);
                }
              }
            }}
            style={{
              borderRadius: 10,
              marginLeft: 10,
              minWidth: "75px",
              fontSize: "13px",
              borderColor:
                (currentStep === 3 && !contractChecked) ||
                (userProfile?.firstName?.length + userProfile?.lastName?.length > 20 && !useAlternativeName)
                  ? "lightgrey"
                  : "",
              backgroundColor:
                (currentStep === 3 && !contractChecked) ||
                (userProfile?.firstName?.length + userProfile?.lastName?.length > 20 && !useAlternativeName)
                  ? "lightgrey"
                  : ""
            }}
          >
            {currentStep != 3 ? "Next" : "Order Card"}
            {currentStep != 3 && <ArrowRightOutlined />}
          </Button>
        )}
      </div>
    );
  };

  const renderExtraAgreement = (region = "") => {
    return (
      <ConsentModal
        visible={openExtraAgreementModal && region && (region == "EUROPE" || region == "NORTH_AMERICA")}
        onOk={() => {
          setContractChecked(true);
          setOpenExtraAgreementModal(false);
        }}
        onCancel={() => {
          setContractChecked(false);
          setOpenExtraAgreementModal(false);
        }}
        src={region == "EUROPE" ? EUROPE_terms : NORTH_AMERICA_terms}
        // src={`https://docs.google.com/viewerng/viewer?url=${userProfile.agreementUrl}&embedded=true`}
      />
    );
  };

  return (
    <Spin spinning={loading} tip={loadingText} style={{ fontWeight: "500" }}>
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "85%", margin: "40px auto" }}
      >
        {!appliedSuccess && currentStep !== 0 && (
          <BackBtn
            text={"Go Back"}
            onClick={() => {
              if (currentStep > 0) {
                setCurrentStep((prev) => prev - 1);
              } else {
                navigate(-1);
              }
            }}
          />
        )}
        <div className="row page-title">Cards</div>
        <div className="card-apply-container" style={{ width: "100%" }}>
          <StepMenu steps={["Card holder", "Address", "Payment", "Agreement"]} currentStep={currentStep} />
          <Divider />
          <div className="step-body" style={{ alignItems: "unset" }}>
            {currentStep == 0 && renderCardHolder()}
            {currentStep == 1 && renderAddress()}
            {currentStep == 2 && renderPayment()}
            {currentStep == 3 && renderAgreement()}
          </div>
          <Divider />
          {renderBottomBtn()}
        </div>
      </div>
    </Spin>
  );
};

export const CardApplyPage = boundError(CardApplyPageRaw);
