import React, { useEffect, useState } from "react";
import { boundError, showError } from "../../utils";
import { Modal } from "antd";
import Avatar from "react-avatar-edit";
import noAvatarImg from "../../resources/icons/profile/no_avatar.svg";

const UploadAvatarModalRaw = ({ onOk, onCancel, ...props }) => {
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState();

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (preview) => {
    setPreview(preview);
  };

  const onFileLoad = (file) => {
    setFile(file);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 10485760) {
      alert("File is too big!");
      elem.target.value = "";
    } else {
      setFileType(elem.target.files[0].type);
    }
  };

  const urlToFile = (url, filename, mimeType) => {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const uploadAvatar = async () => {
    if (!preview) return showError("Please choose an image");
    try {
      const pic = await urlToFile(preview, file.name, fileType);
      onOk(pic, preview);
    } catch (err) {
      showError(err);
    }
  };

  return (
    <Modal
      visible={true}
      width={"100vh"}
      style={{ paddingBottom: 100 }}
      okText={"Upload"}
      cancelText={"Cancel"}
      title={"Upload Profile Picture"}
      onOk={uploadAvatar}
      onCancel={onCancel}
      {...props}
    >
      <div className="modal">
        <div className="modalContent">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly"
            }}
          >
            <Avatar
              width={390}
              height={295}
              onCrop={onCrop}
              onClose={onClose}
              onBeforeFileLoad={onBeforeFileLoad}
              onFileLoad={onFileLoad}
            />
            <img
              src={preview || noAvatarImg}
              alt="Preview"
              style={{
                height: "fit-content"
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const UploadAvatarModal = boundError(UploadAvatarModalRaw);
