import React, { useState, useEffect, useContext } from "react";
import { Menu, Dropdown, Button, Divider, Spin, Slider, Checkbox } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { boundError } from "../../utils";
import Modal from "react-modal";
import Big from "big.js";
import date from "date-and-time";
import Emitter, { EmitterEvents } from "../../utils/eventEmitter";
import { KeyValue2 } from "../SharedComponents";
import { translateStakingStatus } from "../../constants/stakingStatus";
import { formatAmount, showSuccess, showError, getDecimalScale, getAmountSymbol } from "../../utils";
import { StakingProductsContext } from "../../hooks";
import moment from "moment";
import { INTEREST_RATE_FREQUENCY } from "../../constants/interestRateFrequency";
import { createStakingAutoEnrollment, updateStakingAutoEnrollment } from "../../apis";
import { isMobile } from "react-device-detect";
import NumberFormat from "react-number-format";

const StakingReEnrollmentModalRaw = ({ visible, onRequestClose, closeModal, staking, onSuccess }) => {
  const [products] = useContext(StakingProductsContext);

  const [loading, setLoading] = useState(false);

  const [errorAmountMsg, setErrorAmountMsg] = useState("");
  const [errorProductMsg, setErrorProductMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [percentage, setPercentage] = useState(1);
  const [amount, setAmount] = useState("");

  const [selectedProduct, setSelectedProduct] = useState();
  const [agreeTerms, setAgreeTerms] = useState(false);

  useEffect(() => {
    initData();
  }, [staking]);

  useEffect(() => {
    setIsOpen(visible);
  }, [visible]);

  const getStakingAutoEnrollmentId = () => {
    if (staking?.autoEnrollments && staking?.autoEnrollments?.length > 0 && staking?.autoEnrollments[0]?.id) {
      return staking?.autoEnrollments[0]?.id;
    }
  };

  const createOrUpdateStakingReEnrollment = async () => {
    try {
      if (!selectedProduct) {
        const msg = "Please select a re-enroll product!";
        showError(msg);
        setErrorProductMsg(msg);
        return;
      }

      if (!amount || !Big(amount).gt(0)) {
        const msg = "Re-enroll amount should be greater than 0.";
        showError(msg);
        setErrorAmountMsg(msg);
        return;
      }

      const stakingTotalAmount = getStakingTotalAmount();
      if (Big(amount).gt(stakingTotalAmount)) {
        const msg = `Re-enroll amount should be lower than the staking total amount: ${stakingTotalAmount?.toFixed()}`;
        showError(msg);
        setErrorAmountMsg(msg);
        return;
      }

      setLoading(true);
      if (getStakingAutoEnrollmentId()) {
        const data = {
          id: getStakingAutoEnrollmentId(),
          autoEnrollStakingProductCode: selectedProduct?.code,
          autoEnrollAmount: amount
        };

        await updateStakingAutoEnrollment(data);
        showSuccess("Auto re-enrollment has been updated successfully!");
      } else {
        const data = {
          stakingId: staking?.id,
          autoEnrollStakingProductCode: selectedProduct?.code,
          autoEnrollAmount: amount
        };

        await createStakingAutoEnrollment(data);
        showSuccess("Auto re-enrollment has been set successfully!");
      }

      Emitter.emit(EmitterEvents.REFRESH_STAKING_LIST);

      setLoading(false);
      setIsOpen(false);
      if (closeModal) {
        closeModal();
      }
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      if (e.includes("amount must be greater than or equal to 1")) {
        showError("Staking amount must be greater or equal to 1 AXC");
      } else {
        showError(e);
      }
      setLoading(false);
      setIsOpen(false);
      if (closeModal) {
        closeModal();
      }
    }
  };

  const initData = () => {
    let productCode = staking?.productCode;
    if (
      staking?.autoEnrollments &&
      staking?.autoEnrollments?.length > 0 &&
      staking?.autoEnrollments[0]?.autoEnrollStakingProductCode
    ) {
      productCode = staking?.autoEnrollments[0]?.autoEnrollStakingProductCode;
    }

    setSelectedProductByProductCode(productCode);
    if (staking) {
      const stakingTotalAmount = Big(staking?.amount)?.plus(staking?.interestAmount);
      let stakingInitReEnrollAmount = stakingTotalAmount;
      let initPercentage = 1;
      if (
        staking?.autoEnrollments &&
        staking?.autoEnrollments?.length > 0 &&
        staking?.autoEnrollments[0]?.autoEnrollAmount &&
        Big(staking?.autoEnrollments[0]?.autoEnrollAmount).lte(stakingTotalAmount)
      ) {
        stakingInitReEnrollAmount = Big(staking?.autoEnrollments[0]?.autoEnrollAmount);
        initPercentage = Big(staking?.autoEnrollments[0]?.autoEnrollAmount).div(stakingTotalAmount).toNumber();
      }
      setAmount(stakingInitReEnrollAmount?.toFixed(5));
      setPercentage(initPercentage);
    }
  };

  const setSelectedProductByProductCode = (code) => {
    const found = products.find((p) => p.code === code);
    if (found) {
      setSelectedProduct(found);
    }
  };

  const getStakingAmountStr = () => {
    if (!isNaN(staking?.amount)) {
      return Big(staking?.amount).toFixed(5) + " " + staking?.currency;
    }

    return "";
  };

  const getStakingTotalAmountStr = () => {
    let total = Big(0);
    let hasAmount = false;
    if (staking?.amount) {
      total = total?.plus(staking?.amount);
      hasAmount = true;
    }

    if (staking?.interestAmount) {
      total = total?.plus(staking?.interestAmount);
      hasAmount = true;
    }

    return hasAmount ? total?.toFixed(5) + " " + staking?.currency : "";
  };

  const getStakingTotalAmount = () => {
    let total = Big(0);
    if (staking?.amount) {
      total = total?.plus(staking?.amount);
    }

    if (staking?.interestAmount) {
      total = total?.plus(staking?.interestAmount);
    }

    return total;
  };

  const getStakingInterestAmount = () => {
    if (!isNaN(staking?.interestAmount)) {
      return Big(staking?.interestAmount).toFixed(5) + " " + staking?.currency;
    }

    return "";
  };

  const getAnnualInterestRate = () => {
    if (!isNaN(staking?.annualInterestRate)) {
      return Big(staking?.annualInterestRate).mul(100).toFixed(2) + " %";
    }

    return "";
  };

  const getTermStartAt = () => {
    if (staking?.termStartAt) {
      return date.format(new Date(staking?.termStartAt), "YYYY-MM-DD", true);
    }

    return "";
  };

  const getTermEndAt = () => {
    if (staking?.termEndAt) {
      return date.format(new Date(staking?.termEndAt), "YYYY-MM-DD", true);
    }

    return "";
  };

  const getAllowLoadCardLimit = () => {
    /// maximumWithdrawUsdAmount / rateInUsd
    if (!isNaN(staking?.maximumWithdrawUsdAmount) && !isNaN(staking?.rateInUsd)) {
      const allowLoadLimit = Big(staking?.maximumWithdrawUsdAmount).div(staking?.rateInUsd);

      return allowLoadLimit.toFixed(5) + " " + staking?.currency;
    }

    return "";
  };

  const getAnnualInterestRateStr = (product) => {
    if (!isNaN(product?.annualInterestRate)) {
      return Big(product?.annualInterestRate).mul(100).toFixed(2) + "% APR";
    }

    return "";
  };

  const getProductStr = (product) => {
    let str = "Unknown";
    if (product?.name) {
      str = product?.name;
    }
    str = str + (str != "" ? " -- " : "") + getAnnualInterestRateStr(product);

    return str;
  };

  function handleMenuClick(e) {
    setSelectedProductByProductCode(e?.key);
    resetErrors();
  }

  const resetErrors = () => {
    setErrorAmountMsg("");
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {products.map((p, index) => {
        return (
          <Menu.Item key={p?.code} icon={<UserOutlined />}>
            {getProductStr(p)}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const getTermStr = () => {
    if (selectedProduct?.term && selectedProduct?.frequency) {
      let term = Big(selectedProduct?.term);

      if (selectedProduct?.frequency?.toUpperCase() === INTEREST_RATE_FREQUENCY.DAILY) {
        if (term.gt(0)) {
          term = term + " Days";
        } else if (term.eq(1)) {
          term = term + " Day";
        }
      } else if (selectedProduct?.frequency?.toUpperCase() === INTEREST_RATE_FREQUENCY.MONTHLY) {
        if (term.gt(0)) {
          term = term + " Months";
        } else if (term.eq(1)) {
          term = term + " Month";
        }
      } else if (selectedProduct?.frequency?.toUpperCase() === INTEREST_RATE_FREQUENCY.YEARLY) {
        if (term.gt(0)) {
          term = term + " Years";
        } else if (term.eq(1)) {
          term = term + " Year";
        }
      }

      return term;
    }

    return "";
  };

  const getInterestRateFrequencyStr = () => {
    if (selectedProduct?.frequency) {
      if (selectedProduct?.frequency?.toUpperCase() === INTEREST_RATE_FREQUENCY.DAILY) {
        return "Daily";
      } else if (selectedProduct?.frequency?.toUpperCase() === INTEREST_RATE_FREQUENCY.MONTHLY) {
        return "Monthly";
      } else if (selectedProduct?.frequency?.toUpperCase() === INTEREST_RATE_FREQUENCY.YEARLY) {
        return "Yearly";
      }
    }

    return "";
  };

  const getEndDate = () => {
    return moment(selectedProduct?.endAt).utc().format("YYYY/MM/DD");
  };

  const individualRoomLimit = () => {
    if (!isNaN(selectedProduct?.individualRoomLimit) && Big(selectedProduct?.individualRoomLimit).gt(0)) {
      return (
        formatAmount(selectedProduct?.individualRoomLimit, selectedProduct?.currency) + " " + selectedProduct?.currency
      );
    }

    return "";
  };

  const maximumApplyNumbers = () => {
    if (!isNaN(selectedProduct?.maximumApplyNumbers) && Big(selectedProduct?.maximumApplyNumbers).gt(0)) {
      return Big(selectedProduct?.maximumApplyNumbers).toFixed(0);
    }

    return "";
  };

  const userAppliedNumbers = () => {
    if (!isNaN(selectedProduct?.userAppliedNumbers) && Big(selectedProduct?.userAppliedNumbers).gt(0)) {
      return Big(selectedProduct?.userAppliedNumbers).toFixed(0);
    }

    return "";
  };

  const onChangeSlider = (value) => {
    if (isNaN(value)) {
      return;
    }

    setPercentage(value);

    if (staking) {
      const stakingTotalAmount = Big(staking?.amount)?.plus(staking?.interestAmount);
      setAmount(stakingTotalAmount?.mul(value)?.toFixed(5));
    }
  };

  const disabledSubmitBtn = () => {
    if (!selectedProduct) {
      return true;
    }

    if (!amount || !Big(amount).gt(0)) {
      return true;
    }

    const stakingTotalAmount = getStakingTotalAmount();
    if (Big(amount).gt(stakingTotalAmount)) {
      return true;
    }

    return !agreeTerms;
  };

  const getReEnrollmentStartStr = () => {
    if (staking?.autoEnrollments && staking?.autoEnrollments.length > 0) {
      return date.format(new Date(staking?.termEndAt), "YYYY-MM-DD", true);
    }
    return "";
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          zIndex: 1999
        },
        content: {
          margin: "auto",
          maxWidth: 500,
          height: "100vh",
          overflow: "auto",
          padding: 0,
          backgroundColor: "transparent",
          border: "none",
          paddingTop: 20,
          paddingBottom: 20
        }
      }}
    >
      <Spin spinning={loading}>
        <div style={{ backgroundColor: "white", borderRadius: 21 }}>
          <div className="stakings__product-modal-header">
            <h1>{staking?.name}</h1>
            <p>
              Load amount to your card using the information below. You'll need to contact your bank for specific
              instructions.
            </p>
          </div>
          <div style={{ padding: 30, paddingBottom: 0 }}>
            <KeyValue2 keyName={"Principal Amount"} value={getStakingAmountStr()} />
            <KeyValue2 keyName={"Allowed Load Card Limit"} value={getAllowLoadCardLimit()} />
            <KeyValue2 keyName={"Total Rewards Earned"} value={getStakingInterestAmount()} />
            <KeyValue2 keyName={"Annual Percentage Rewards"} value={getAnnualInterestRate()} />
            <KeyValue2 keyName={"Status"} value={translateStakingStatus(staking?.status)} />
            <KeyValue2 keyName={"Term End"} value={getTermEndAt()} />
            {getReEnrollmentStartStr() && (
              <KeyValue2 keyName={"Re-enrollment Start"} value={getReEnrollmentStartStr()} />
            )}

            <KeyValue2 keyName={"Total Amount"} value={getStakingTotalAmountStr()} style={{ marginTop: 20 }} />

            {/* <KeyValue2 keyName={"Room Remain"} value={getRoomRemain()} /> */}

            <Divider />

            <KeyValue2 keyName={"Select re-enroll staking pool"} value={""} />
            <Dropdown
              overlay={menu}
              overlayStyle={{ zIndex: 9999, overflow: "scroll", maxHeight: "100vh" }}
              trigger="click"
            >
              <Button style={{ width: "100%", border: "2px solid #CDE6F6" }}>
                {selectedProduct === null ? "Please select a re-enroll staking pool" : getProductStr(selectedProduct)}
                <DownOutlined />
              </Button>
            </Dropdown>
            {errorProductMsg !== "" && (
              <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorProductMsg}</p>
            )}
            <div style={{ height: 15 }} />
            <KeyValue2 keyName={"Annual Percentage Rewards"} value={getAnnualInterestRateStr(selectedProduct)} />
            <KeyValue2 keyName={"Term"} value={getTermStr()} />
            <KeyValue2 keyName={"Rewards Frequency"} value={getInterestRateFrequencyStr()} />
            {/* <KeyValue2 keyName={"Room Remaining"} value={getRoomRemain()} /> */}
            <KeyValue2 keyName={"Pool Closes On"} value={getEndDate()} />
            {individualRoomLimit() !== "" && <KeyValue2 keyName={"Limit per Staking"} value={individualRoomLimit()} />}
            {/* {maximumApplyNumbers() !== "" && (
              <KeyValue2 keyName={"Maximum Apply Number"} value={maximumApplyNumbers()} />
            )} */}
            {/* {userAppliedNumbers() !== "" && <KeyValue2 keyName={"Number of Holdings"} value={userAppliedNumbers()} />} */}

            {/* <p
              className="margin-none"
              style={{ fontWeight: "500", fontSize: 12, minWidth: 240, marginTop: 10, color: "#B1B5C3" }}
            >
              {"Enter amount to load"}
            </p> */}

            <Divider />

            <KeyValue2 keyName={"Enter re-enrollment amount"} value={""} />
            <Slider
              min={0}
              max={1}
              onChange={onChangeSlider}
              value={typeof percentage === "number" ? percentage : 0}
              step={0.01}
              tooltipVisible
              getTooltipPopupContainer={(node) => node.parentNode}
              tipFormatter={(value) => `${value * 100}%`}
            />

            <div style={{ display: "flex" }}>
              {/* <p
                style={{
                  border: "2px solid #CDE6F6",
                  margin: 0,
                  borderRadius: "10px",
                  width: "100px",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  marginRight: 5
                }}
              >
                {formatAmount(amount, staking?.currency)}
              </p> */}
              <NumberFormat
                thousandSeparator={true}
                value={amount}
                placeholder={"0.00"}
                decimalScale={getDecimalScale(staking?.currency)}
                prefix={getAmountSymbol(staking?.currency)}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  const stakingTotalAmount = getStakingTotalAmount();
                  setAmount(value);

                  if (Big(value).gt(stakingTotalAmount)) {
                    const msg = `Re-enroll amount should be lower than the staking total amount: ${stakingTotalAmount?.toFixed()}`;
                    showError(msg);
                    setErrorAmountMsg(msg);
                  } else if (Big(value).lte(0)) {
                    const msg = `Re-enroll amount should be greater than 0.`;
                    showError(msg);
                    setErrorAmountMsg(msg);
                  } else {
                    const p = Big(value).div(stakingTotalAmount);
                    setPercentage(p.toNumber());
                    resetErrors();
                  }
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "12px",
                  border: "2px solid #B1B5C4",
                  padding: "10px 20px",
                  maxHeight: 46,
                  marginRight: 10,
                  fontSize: 16
                }}
              />
              <p
                style={{
                  border: "2px solid #CDE6F6",
                  margin: 0,
                  borderRadius: "10px",
                  width: "100px",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {staking?.currency}
              </p>
            </div>
            {errorAmountMsg !== "" && (
              <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorAmountMsg}</p>
            )}
            <p className="margin-none" style={{ fontWeight: "500", fontSize: 12, marginTop: 5 }}>
              {"Note: Any unallocated amount will be returned to your AXC account."}
            </p>

            <Divider />
            <div
              className="row"
              style={{
                paddingBottom: 20,
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: isMobile ? "column" : null
              }}
            >
              <div style={{ whiteSpace: "nowrap" }}>
                <Checkbox checked={agreeTerms} onChange={() => setAgreeTerms(!agreeTerms)}>
                  Agree to{" "}
                  <a href="https://axiacapitalbank.com/axia-staking-program-terms-and-conditions" target="_blank">
                    terms and conditions
                  </a>
                </Checkbox>
              </div>

              <div style={{ whiteSpace: "nowrap" }}>
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #3b82f6",
                    borderRadius: 10,
                    color: "#3b82f6",
                    minWidth: "75px",
                    fontSize: "13px"
                  }}
                  onClick={() => {
                    setErrorAmountMsg("");
                    closeModal();
                  }}
                >
                  Close
                </Button>
                <Button
                  type="primary"
                  disabled={disabledSubmitBtn()}
                  style={{ borderRadius: 12, marginLeft: 10, minWidth: "75px", fontSize: "13px" }}
                  onClick={createOrUpdateStakingReEnrollment}
                >
                  {getStakingAutoEnrollmentId() ? "Update" : "Confirm"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export const StakingReEnrollmentModal = boundError(StakingReEnrollmentModalRaw);
