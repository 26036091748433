import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Spin, Form, Input, notification } from "antd";
import { awsForgotPasswordSubmit } from "../apis";
import { AuthWrapper, ButtonPrimary } from "../components";
import { getCustomErrorMessage, handleErrorMessage } from "../constants";
import { boundError } from "../utils";

const ResetPasswordRaw = () => {
  const [loading, setLoading] = useState(false);
  const [resetError, setResetError] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const email = location.state?.email;

  const resetPassword = async (values) => {
    try {
      setLoading(true);
      setResetError("");
      const { password, code } = values;
      if (code.length < 6) {
        return setResetError("Please enter the complete verification code.");
      }
      await awsForgotPasswordSubmit(email, password, code);
      notification.success({
        message: "Successfully reset password.",
        description: "You may log in now.",
        placement: "bottomRight"
      });
      navigate("/login");
    } catch (err) {
      console.log(err);
      const errMessage = handleErrorMessage(err);
      setResetError(getCustomErrorMessage(errMessage));
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthWrapper>
      <div className="sign-up-confirm__container">
        <div style={{ fontSize: 30, fontWeight: "500" }}>Confirm your email</div>
        <div className="auth-page__subtitle">{email}</div>
        <p className="auth-page__message" style={{ margin: "30px 0" }}>
          Check your spam folder and the spelling of your email address. Incorrect email?{" "}
          <span className="clickable-text" onClick={() => navigate(-1, { replace: true })}>
            Edit email address
          </span>
        </p>

        <Spin spinning={loading}>
          <Form
            onFinish={resetPassword}
            layout="vertical"
            form={form}
            name="reset-password-form"
            id="reset-password-form"
          >
            <Form.Item
              label="Six-digit token"
              name="code"
              rules={[
                {
                  required: true,
                  message: "The code field is required"
                }
              ]}
              normalize={(value, preVal) => {
                let newVal = value.replace(/\D/, "");
                if (newVal.length > 6) {
                  newVal = preVal;
                }
                return newVal;
              }}
            >
              <Input allowClear className="acb-primary-input" autoComplete="off" onChange={() => setResetError("")} />
            </Form.Item>
            {resetError && (
              <div style={{ color: "red", marginTop: -24, marginBottom: 24, fontStyle: "italic" }}>{resetError}</div>
            )}
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/g),
                  message:
                    "Passwords must be at least 8 characters, including at least one upper case letter, one lower case letter, and one numeric digit."
                }
              ]}
            >
              <Input.Password allowClear className="acb-primary-input" autoComplete="new-password" />
            </Form.Item>
            <ButtonPrimary htmlType="submit" type="primary" size={"large"} block style={{ marginBottom: 20 }}>
              Reset password
            </ButtonPrimary>
          </Form>
        </Spin>
      </div>
    </AuthWrapper>
  );
};

export const ResetPassword = boundError(ResetPasswordRaw);
