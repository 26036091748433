import React, { useState } from "react";
import { Checkbox, Form, Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { AuthWrapper, ButtonPrimary } from "../components";
import { getCustomErrorMessage, awsErrorMessages } from "../constants";
import { boundError } from "../utils";
import { awsSignUp } from "../apis";
import ReCAPTCHA from "react-google-recaptcha";

const SignUpRaw = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [signUpError, setSignUpError] = useState("");
  const [loading, setLoading] = useState(false);
  const [reCAPTCHAToken, setReCAPTCHAToken] = useState(false);
  const [reCAPTCHAError, setReCAPTCHAError] = useState("");

  const handleSignUpError = (err) => {
    console.log(err);
    let errMessage = err.message;

    if (typeof errMessage !== "string") {
      errMessage = JSON.stringify(errMessage);
    }
    switch (errMessage) {
      case awsErrorMessages.InvalidRecaptchaToken:
        setReCAPTCHAError(getCustomErrorMessage(errMessage));
        break;
      default:
        setSignUpError(getCustomErrorMessage(errMessage));
    }
  };

  const signUp = async (values) => {
    try {
      setLoading(true);
      setSignUpError("");
      const { email, password, firstName, lastName } = values;
      if (!reCAPTCHAToken) {
        return;
      }
      await awsSignUp(email, password, reCAPTCHAToken, {
        given_name: firstName,
        family_name: lastName
      });
      navigate("/sign-up-confirm", { state: { email, password } });
    } catch (err) {
      handleSignUpError(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <AuthWrapper>
      <p className="auth-page__title">Please Sign Up</p>
      <Spin spinning={loading}>
        <Form onFinish={signUp} layout="vertical" form={form} name="sign-up-form" id="sign-up-form">
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "The first name field is required"
              }
            ]}
          >
            <Input allowClear id="firstName" autoFocus className="acb-primary-input" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: "The last name field is required"
              }
            ]}
          >
            <Input allowClear id="lastName" className="acb-primary-input" />
          </Form.Item>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: "The email field is required"
              }
            ]}
            normalize={(value) => value.replace(" ", "").toLowerCase()}
          >
            <Input
              allowClear
              id="email"
              type="email"
              className="acb-primary-input"
              onChange={() => setSignUpError("")}
            />
          </Form.Item>
          {signUpError && (
            <div style={{ color: "red", marginTop: -24, marginBottom: 24, fontStyle: "italic" }}>{signUpError}</div>
          )}
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                pattern: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/g),
                message:
                  "Passwords must be at least 8 characters, including at least one upper case letter, one lower case letter, and one numeric digit."
              }
            ]}
          >
            <Input.Password allowClear className="acb-primary-input" />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "The password confirmation field is required"
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The password confirmation does not match."));
                }
              })
            ]}
          >
            <Input.Password allowClear className="acb-primary-input" />
          </Form.Item>
          <div className="form-terms-group">
            <Form.Item
              name="termsOfUse"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error("The Terms of Use must be accepted."))
                }
              ]}
            >
              <Checkbox>
                Agree to{" "}
                <a href="https://axiacapitalbank.com/terms-services/" target="_blank">
                  Terms of Use
                </a>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="axiacoinTerms"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error("The AXIA Coin Terms must be accepted."))
                }
              ]}
            >
              <Checkbox>
                Agree to{" "}
                <a href="https://axiacapitalbank.com/axia-coin-terms/" target="_blank">
                  AXIA Coin Terms
                </a>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="privacyPolicy"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error("The Privacy Policy must be accepted."))
                }
              ]}
            >
              <Checkbox>
                Agree to{" "}
                <a href="https://axiacapitalbank.com/privacy-policy/" target="_blank">
                  Privacy Policy
                </a>
              </Checkbox>
            </Form.Item>
          </div>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            style={{ marginTop: 10 }}
            onChange={(token) => {
              if (token) {
                setReCAPTCHAError("");
              }
              setReCAPTCHAToken(token);
            }}
          />
          {reCAPTCHAError && <div style={{ color: "rgb(255,77,79)" }}>{reCAPTCHAError}</div>}
          <ButtonPrimary
            htmlType="submit"
            type="primary"
            size={"large"}
            block
            style={{ marginBottom: 20 }}
            onClick={() => {
              if (!reCAPTCHAToken) {
                setReCAPTCHAError("Please check I'm not a robot");
              }
            }}
          >
            Sign Up
          </ButtonPrimary>
          <div className="row">
            <div className="grey-horizontal-line"></div>
            <span style={{ color: "rgb(115,115,115)", padding: "0 10px" }}>Already have an account?</span>
            <div className="grey-horizontal-line"></div>
          </div>
          <div
            className="clickable-text"
            style={{ textAlign: "center", marginTop: 10 }}
            onClick={() => navigate("/login")}
          >
            Log In
          </div>
        </Form>
      </Spin>
    </AuthWrapper>
  );
};

export const SignUp = boundError(SignUpRaw);
