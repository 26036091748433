import React from "react";
import { boundError } from "../utils";
import ErrorImg from "../resources/images/hasError.svg";
import LOGOImg from "../resources/images/logo.svg";
import { DoubleRightOutlined } from "@ant-design/icons";

const MaintenancePageRaw = () => {
  return (
    <div
      className="ant-modal-mask"
      style={{
        background: "rgb(231, 243, 254)"
      }}
    >
      <div
        style={{
          margin: "10%",
          background: "white",
          borderRadius: 20,
          padding: 30,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img src={LOGOImg} alt="" style={{ maxWidth: 300 }} />
        <p
          style={{
            fontWeight: 500,
            fontSize: 30
          }}
        >
          The website is undergoing maintenance
        </p>
        <p
          style={{
            fontWeight: 500,
            fontSize: 16,
            color: "#777E91"
          }}
        >
          We apologize for any inconvenience.
        </p>
        <img src={ErrorImg} alt="" style={{ maxWidth: 250 }} />
        <a href={`${process.env.REACT_APP_STATUSPAGE_VIEW_URL}`} target="_blank" rel="noopener noreferrer">
          <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>Details</span>
          <DoubleRightOutlined />
        </a>
      </div>
    </div>
  );
};

export const MaintenancePage = boundError(MaintenancePageRaw);
