import React, { useState, useEffect } from "react";
import { boundError, formatAmount } from "../../utils";
import walletFlag from "../../resources/images/flags/axia_coin_wallet.png";
import { WalletOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { CURRENCY } from "../../constants/currency";
import { getAccountOverview } from "../../apis";
import Big from "big.js";

const StakingCardRaw = ({ account }) => {
  const navigate = useNavigate();
  const color = "var(--axia-blue)";
  const [accountOverview, setAccountOverview] = useState({});

  useEffect(() => {
    fetchAccountOverview();
  }, []);

  const fetchAccountOverview = async () => {
    const data = await getAccountOverview();
    setAccountOverview(data);
  };

  const getStakingAmountPlusRewards = () => {
    const balance = accountOverview?.stakingTotalBalance?.AXC?.amount || 0;
    return Big(balance).toFixed(5);
  };

  return (
    <div
      className="account-mid-axia-wallet-card"
      onClick={() => {
        navigate(`/stakings`);
      }}
      // style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
    >
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "5px 0" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
          <img src={walletFlag} alt="" style={{ height: 30, width: 30 }} />
          <p className="account-mid-wallet-card-name" style={{ color }}>
            AXIA Staking Account
          </p>
        </div>
        <WalletOutlined className="display-mobile-unset" style={{ color, fontSize: 30 }} />
      </div>

      <div style={{ height: 18 }}></div>

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <p className="account-mid-wallet-card-amount" style={{ color }}>
          {formatAmount(getStakingAmountPlusRewards(), CURRENCY.AXC.code)}
          <span style={{ fontSize: 12, fontWeight: "600", marginLeft: "0.5rem" }}>{CURRENCY.AXC.code}</span>
        </p>
      </div>
    </div>
  );
};

export const StakingCard = boundError(StakingCardRaw);
