import React, { useState, useEffect, useContext } from "react";
import Icon, { RightOutlined, MinusOutlined, RiseOutlined, SwapOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BackBtn, SimpleButton, CancelBtn, StepMenu, ListCard, CutOffLine, AccountCard } from "../components";
import { Input, Modal, Divider, Checkbox } from "antd";
import { boundError, showError, showSuccess } from "../utils";
import UserServices from "../services/userServices";
import { AccountsContext } from "../hooks";
import { BeneficiarySelector } from "../components/BeneficiarySelector";
import Big from "big.js";
import { CURRENCY } from "../constants/currency";
import TransferAndDepositServices from "../services/transferAndDepositServices";
import { TRANSACTION_TYPE } from "../constants";
import { KeyValue2, KeyValue4 } from "../components/SharedComponents/KeyValueFields";
import { formatAmount, getDecimalScale, getAmountSymbol } from "../utils";
import { AmountInput } from "../components/SharedComponents/AmountInput";
import { NextBtn } from "../components/SharedComponents/Button/NextBtn";
import { FeeRow } from "../components/SharedComponents/feeRow";
import { ACCOUNT_STATUS } from "../constants/accountStatus";
import { Transaction2faModal } from "./Transaction2faModal";
import { useSelector } from "react-redux";
import { getConversionRateApi } from "../apis";

const { TextArea } = Input;

const BetweenMembersRaw = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const userServices = UserServices.getInstance();
  const transferAndDepositServices = TransferAndDepositServices.getInstance();
  const [currentStep, setCurrentStep] = useState(0);
  const [accountList] = useContext(AccountsContext);
  const [selectedSource, setSelectedSource] = useState({});
  const [selectedBeneficiary, setSelectedBeneficiary] = useState({});
  const [transferAmount, setTransferAmount] = useState("");
  const [memo, setMemo] = useState("");

  const [transferType, setTransferType] = useState(null);

  const [currencyRate, setCurrencyRate] = useState(1);
  const [reverseCurrencyRate, setReverseCurrencyRate] = useState(1);
  const [transferAmountInUsd, setTransferAmountInUsd] = useState(0);

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transaction2faModalVisible, setTransaction2faModalVisible] = useState(false);
  const [transaction2faRecordId, setTransaction2faRecordId] = useState();

  const [confirmChecked, setConfirmChecked] = useState(false);

  const [transferFee, setTransferFee] = useState(0);
  const [transferFeeCurrency, setTransferFeeCurrency] = useState("");
  const [transferFeeCurrencyRate, setTransferFeeCurrencyRate] = useState(null);
  const [spendableAmountInUsd, setSpendableAmountInUsd] = useState();
  const [spendableAmountInAxc, setSpendableAmountInAxc] = useState();
  const [spendableAmountInUsax, setSpendableAmountInUsax] = useState();

  useEffect(() => {
    userServices.fetchUserAccounts();
    fetchTransferType();
    fetchUserSpendable();
  }, []);

  const getCurrencyRate = async () => {
    try {
      if (selectedSource.currency === CURRENCY.USD.code) {
        setCurrencyRate(1);
        setReverseCurrencyRate(1);
      } else {
        const result = await transferAndDepositServices.getCurrencyRate(selectedSource.currency, CURRENCY.USD.code);

        if (result?.conversionRate) {
          setCurrencyRate(result?.conversionRate);
        }

        const result2 = await transferAndDepositServices.getCurrencyRate(CURRENCY.USD.code, selectedSource.currency);

        if (result2?.conversionRate) {
          setReverseCurrencyRate(result2?.conversionRate);
        }
      }
    } catch (err) {
      showError(err);
    }
  };

  const fetchTransferType = async () => {
    try {
      const result = await transferAndDepositServices.getInternalTransactionTypes(
        TRANSACTION_TYPE.USER_TRANSFER_BETWEEN_MEMBERS
      );

      if (result && result.length > 0) {
        setTransferType(result[0]);

        /// if using fee from transferType, need to know currency rate of feeCurrency type of it
        const result2 = await transferAndDepositServices.getCurrencyRate(result[0]?.feeCurrency, CURRENCY.USD.code);
        if (result2?.conversionRate) {
          setTransferFeeCurrencyRate(result2?.conversionRate);
        }
      }
    } catch (err) {
      showError(err);
    }
  };

  const fetchUserSpendable = async () => {
    const data = await userServices.getUserSpendableAmount();
    setSpendableAmountInUsd(data.spendableAmountInUsd);
    setSpendableAmountInAxc(data.spendableAmountInAxc);

    const res = await getConversionRateApi(CURRENCY.AXC.code, CURRENCY.USAX.code);
    const conversionRate = res?.conversionRate;
    if (conversionRate) {
      setSpendableAmountInUsax(new Big(data.spendableAmountInAxc).times(conversionRate).toFixed());
    }
  };

  const getTransferAmountStr = () => {
    if (transferAmount !== "") {
      return formatAmount(transferAmount, selectedSource?.currency);
    }

    return "0";
  };

  const isEnoughBalance = () => {
    const totalAmount = getTotalAmount();
    if (Big(selectedSource?.amount).lt(totalAmount)) {
      return false;
    }
    return true;
  };

  const calculations = (amount) => {
    if (amount) {
      const amountInUsd = Big(amount).mul(currencyRate);
      setTransferAmountInUsd(amountInUsd);

      if (transferType?.fee && Number(transferType?.fee) > 0 && transferType?.feeCurrency) {
        setTransferFee(transferType?.fee);
        setTransferFeeCurrency(transferType?.feeCurrency);
      } else if (transferType?.feeRate && Number(transferType?.feeRate) > 0) {
        setTransferFee(Big(amount).mul(transferType?.feeRate));
        setTransferFeeCurrency(selectedSource.currency);
      }
    } else {
      setTransferFee(0);
      setTransferAmountInUsd(0);
    }
  };

  const getCurrencyRateStr = () => {
    if (currencyRate !== undefined) {
      return Big(currencyRate).toFixed(5) + " " + CURRENCY.USD.code + "/" + selectedSource.currency;
    }

    return "";
  };

  const getAmountToBeSentStr = () => {
    if (transferAmountInUsd !== undefined) {
      return formatAmount(transferAmountInUsd, "USD") + " USD";
    }
    return "";
  };

  const getTransferFeeStr = () => {
    if (transferFee) {
      if (selectedSource?.currency === CURRENCY.USD.code) {
        if (transferFeeCurrency === CURRENCY.USD.code) {
          return formatAmount(Big(transferFee).toFixed(), transferFeeCurrency) + " " + transferFeeCurrency;
        } else if (transferFeeCurrency) {
          if (transferFeeCurrencyRate !== null) {
            return formatAmount(Big(transferFee).mul(transferFeeCurrencyRate).toFixed(), "USD") + " USD";
          } else {
            return "";
          }
        }
      } else {
        /// if fee currency is not usd, need to calculate usd value
        if (transferFeeCurrency === CURRENCY.USD.code) {
          return (
            formatAmount(Big(transferFee).mul(reverseCurrencyRate).toFixed(), selectedSource?.currency) +
            " " +
            selectedSource?.currency +
            " ≈ " +
            formatAmount(Big(transferFee).toFixed(), CURRENCY.USD.code) +
            " " +
            CURRENCY.USD.code
          );
        } else if (transferFeeCurrency) {
          return (
            formatAmount(Big(transferFee).toFixed(), transferFeeCurrency) +
            " " +
            transferFeeCurrency +
            " ≈ " +
            formatAmount(Big(transferFee).mul(currencyRate).toFixed(), CURRENCY.USD.code) +
            " " +
            CURRENCY.USD.code
          );
        }
      }
    }

    return "0.00";
  };

  const getTotalAmount = () => {
    let baseAmount = Big(0);
    if (transferAmount) {
      baseAmount = Big(transferAmount);
    }

    let baseFeeAmount = Big(0);
    if (selectedSource?.currency === CURRENCY.USD.code) {
      if (transferFeeCurrency === CURRENCY.USD.code) {
        baseFeeAmount = Big(transferFee);
      } else if (transferFeeCurrency) {
        baseFeeAmount = Big(transferFee).mul(currencyRate);

        if (transferFeeCurrencyRate !== null) {
          baseFeeAmount = Big(transferFee).mul(transferFeeCurrencyRate);
        }
      }
    } else {
      if (transferFeeCurrency === CURRENCY.USD.code) {
        baseFeeAmount = Big(transferFee).mul(reverseCurrencyRate);
      } else if (transferFeeCurrency) {
        baseFeeAmount = Big(transferFee);
      }
    }

    return baseAmount.plus(baseFeeAmount);
  };

  const getTotalDeductStr = () => {
    let baseAmount = Big(0);
    if (transferAmount) {
      baseAmount = Big(transferAmount);
    }

    let baseFeeAmount = Big(0);
    if (selectedSource?.currency === CURRENCY.USD.code) {
      if (transferFeeCurrency === CURRENCY.USD.code) {
        baseFeeAmount = Big(transferFee);
      } else if (transferFeeCurrency) {
        baseFeeAmount = Big(transferFee).mul(currencyRate);

        if (transferFeeCurrencyRate !== null) {
          baseFeeAmount = Big(transferFee).mul(transferFeeCurrencyRate);
        }
      }
    } else {
      if (transferFeeCurrency === CURRENCY.USD.code) {
        baseFeeAmount = Big(transferFee).mul(reverseCurrencyRate);
      } else if (transferFeeCurrency) {
        baseFeeAmount = Big(transferFee);
      }
    }

    const totalAmount = baseAmount.plus(baseFeeAmount);

    if (selectedSource?.currency === CURRENCY.USD.code) {
      return formatAmount(totalAmount.toFixed(), "USD") + " USD";
    } else {
      return (
        formatAmount(totalAmount.toFixed(), selectedSource?.currency) +
        " " +
        selectedSource?.currency +
        " ≈ " +
        formatAmount(totalAmount.mul(currencyRate).toFixed(), "USD") +
        " USD"
      );
    }
  };

  const getFromAccountStr = () => {
    if (selectedSource) {
      let str = "";
      if (selectedSource?.publicId) {
        str = "Account: " + selectedSource?.publicId;
      }

      if (selectedSource?.amount !== undefined && selectedSource.currency !== undefined) {
        str = str + " - ";
        if (selectedSource.currency === CURRENCY.USD.code) {
          str = str + "$" + Big(selectedSource?.amount).toFixed(2) + " " + selectedSource.currency;
        } else {
          str = str + Big(selectedSource?.amount).toFixed(5) + " " + selectedSource.currency;
        }

        return str;
      }
    }
    return "";
  };

  const sendTransferRequest = async ({ mfaCode }) => {
    if (!confirmChecked) {
      showError("Please check the checkbox above before continuing!");
      return;
    }

    try {
      setConfirmLoading(true);
      const data = {
        fromAccountId: selectedSource?.id,
        amount: transferAmount,
        currency: selectedSource?.currency,
        beneficiaryEmail: selectedBeneficiary?.beneficiaryEmail,
        userMemo: memo.trim(),
        beneficiaryUserId: selectedBeneficiary?.beneficiaryUserId,
        mfaCode,
        transaction2faRecordId
      };

      const result = await transferAndDepositServices.sendTransferBetweenMembers(data);
      if (result?.statusCode === 207) {
        setTransaction2faRecordId(result?.data?.transaction2faRecordId);
        setTransaction2faModalVisible(true);
        return;
      }
      if (result) {
        showSuccess("Transaction sent successfully!");

        navigate("/transfer");
      }

      setConfirmVisible(false);
      setConfirmLoading(false);
      setTransaction2faModalVisible(false);
    } catch (err) {
      if (err !== "Verification code is invalid") {
        setTransaction2faModalVisible(false);
      }
      setConfirmLoading(false);
      showError(err);
    }
  };

  const formatSpendableInfo = () => {
    switch (selectedSource.currency) {
      case CURRENCY.USD.code:
        return { spendableAmountInUsd };
      case CURRENCY.USAX.code:
        return spendableAmountInUsax ? { spendableAmountInUsax } : { spendableAmountInAxc };
      default:
        return { spendableAmountInAxc };
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <p
                className="margin-none"
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Select source of funds to transfer
              </p>
              {accountList.map((source) => {
                const isActive = source.status === ACCOUNT_STATUS.ACTIVE;

                return (
                  <ListCard
                    style={isActive ? {} : { backgroundColor: "lightGray", cursor: "not-allowed" }}
                    key={`sourceList-${source.publicId}`}
                    children={AccountCard(source)}
                    tailComponent={
                      isActive ? (
                        <RightOutlined style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }} />
                      ) : null
                    }
                    onClick={
                      isActive
                        ? () => {
                            setSelectedSource(source);
                            setCurrentStep((prev) => prev + 1);
                          }
                        : () => {}
                    }
                  />
                );
              })}
            </div>
            <Divider />
            <CancelBtn toPath="/transfer" />
          </>
        );
      case 1:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <BeneficiarySelector
                onBeneficiarySelect={async (beneficiary) => {
                  setSelectedBeneficiary(beneficiary);
                  await getCurrencyRate();

                  setTransferAmount("");
                  setTransferAmountInUsd(0);
                  setTransferFee(0);
                  setCurrentStep((prev) => prev + 1);
                }}
              />
            </div>

            <Divider />
            <CancelBtn toPath="/transfer" />
          </>
        );
      case 2:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <h2
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  textAlign: "left",
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Enter the amount you want to send
              </h2>
              <AmountInput
                title={"You'll send"}
                onChange={(value) => {
                  setTransferAmount(value);
                  calculations(Number(value));
                }}
                value={transferAmount}
                currency={selectedSource.currency}
                accountId={selectedSource.publicId}
                accountAmount={selectedSource.amount}
              />
              {isEnoughBalance() === false && (
                <p
                  className="margin-none"
                  style={{ marginLeft: "0.5rem", color: "#fb7185", fontSize: "0.9rem", fontWeight: 500 }}
                >
                  You do not have enough balance for this transaction
                </p>
              )}
              <FeeRow
                rows={[
                  selectedSource.currency !== CURRENCY.USD.code && {
                    fee: getCurrencyRateStr(),
                    type: "Conversion Rate"
                  },
                  selectedSource.currency !== CURRENCY.USD.code && {
                    fee: getAmountToBeSentStr(),
                    type: "Amount Value in USD"
                  },
                  getTransferFeeStr() !== "0.00" && {
                    fee: getTransferFeeStr(),
                    type: "Transfer Fee"
                  }
                ]}
                spendableData={formatSpendableInfo()}
                impactOnSpendableAmount={transferType.impactOnSpendableAmount}
              />
              <div style={{ marginTop: "1rem", width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    background: "#F8F9FA",
                    borderRadius: "12px"
                  }}
                >
                  <p
                    className="margin-none"
                    style={{ display: "flex", fontWeight: 500, fontSize: "1rem", color: "#777E91" }}
                  >
                    {selectedBeneficiary.beneficiaryName} will receive
                  </p>
                  <p className="margin-none" style={{ display: "flex", fontWeight: 500, fontSize: "2rem" }}>
                    {`${getTransferAmountStr()} ${selectedSource.currency}`}
                  </p>
                </div>
                <p className="margin-none" style={{ display: "flex", fontWeight: 600 }}>
                  Personal Memo (Optional)
                </p>
                <TextArea
                  value={memo}
                  onChange={(e) => setMemo(e.target.value)}
                  style={{ width: "100%", height: 100, border: "1px solid lightGray", borderRadius: 6 }}
                  placeholder="Optional"
                />
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <CancelBtn toPath="/transfer" />
              <NextBtn
                enable={transferAmount !== "" && isEnoughBalance() === true}
                onClick={() => setCurrentStep((prev) => prev + 1)}
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <ListCard
                style={{ boxShadow: "none", paddingLeft: 0 }}
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <p
                        className="margin-none"
                        style={{ display: "block", fontSize: "1rem" }}
                      >{`Send from ${getFromAccountStr()}`}</p>
                      <p className="margin-none" style={{ display: "block", fontSize: "2.25rem" }}>
                        {`${getTransferAmountStr()} ${selectedSource.currency}`}
                      </p>
                    </div>
                  </>
                }
                onClick={() => {}}
              />
              <FeeRow
                rows={[
                  selectedSource.currency !== CURRENCY.USD.code && {
                    fee: getCurrencyRateStr(),
                    type: "Conversion Rate"
                  },
                  selectedSource.currency !== CURRENCY.USD.code && {
                    fee: getAmountToBeSentStr(),
                    type: "Amount Value in USD"
                  },
                  getTransferFeeStr() !== "0.00" && {
                    fee: getTransferFeeStr(),
                    type: "Transfer Fee"
                  },
                  {
                    fee: getTotalDeductStr(),
                    type: "Total Deduct"
                  }
                ]}
                spendableData={
                  selectedSource.currency === CURRENCY.USD.code ? { spendableAmountInUsd } : { spendableAmountInAxc }
                }
                impactOnSpendableAmount={transferType.impactOnSpendableAmount}
              />
              <CutOffLine />
              <div style={{ marginTop: "1rem", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", marginBottom: "2rem" }}>
                  <p className="margin-none" style={{ display: "flex", fontWeight: 500, fontSize: "1rem" }}>
                    {selectedBeneficiary.beneficiaryName} will receive
                  </p>
                  <p className="margin-none" style={{ display: "flex", fontWeight: 500, fontSize: "2.25rem" }}>
                    {`${getTransferAmountStr()} ${selectedSource.currency}`}
                  </p>
                </div>

                <p className="margin-none" style={{ display: "flex", fontWeight: 600 }}>
                  Personal Memo
                </p>
                <div
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: "white",
                    borderRadius: 10,
                    marginTop: 5
                  }}
                >
                  <p style={{ textAlign: "left" }}>{memo}</p>
                </div>
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <CancelBtn toPath="/transfer" />
              <SimpleButton
                style={{
                  padding: "0.5rem 0.75rem 0.5rem 0.75rem",
                  borderRadius: 12,
                  border: "2px solid #3a82f6",
                  width: 300,
                  margin: "auto"
                }}
                onClick={async () => {
                  setConfirmVisible(true);
                }}
                children={
                  <p className="margin-none" style={{ color: "white", fontSize: "1rem", fontWeight: 500 }}>
                    Send Now!
                  </p>
                }
              />
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "85%", margin: "40px auto" }}>
      <BackBtn
        onClick={() => {
          if (currentStep > 0) {
            setCurrentStep((prev) => prev - 1);
          } else {
            navigate("/transfer");
          }
        }}
      />
      <div className="row page-title" style={{ paddingTop: 0 }}>
        Transfer
      </div>
      <div className="card-apply-container" style={{ width: "100%" }}>
        <StepMenu
          steps={["Select account", "Select recipient", "Transfer amount", "Transaction overview"]}
          currentStep={currentStep}
        />
        <Divider />
        {renderStep()}
      </div>
      <Divider />
      <Modal
        title="Confirm information"
        visible={confirmVisible}
        okText={"Confirm"}
        onOk={sendTransferRequest}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setTransaction2faRecordId(null);
          setConfirmVisible(false);
        }}
      >
        <KeyValue2 keyName="Transfer to" value={""} />
        <KeyValue4 keyName={"Name"} value={selectedBeneficiary?.beneficiaryName ?? ""} />
        <KeyValue4 keyName={"Email"} value={selectedBeneficiary?.beneficiaryEmail ?? ""} />
        <KeyValue4 keyName={"Amount"} value={getTransferAmountStr() + " " + selectedSource?.currency} />

        <Divider />

        {selectedSource.currency !== CURRENCY.USD.code ? (
          <KeyValue2 keyName="Conversion Rate and Fee" value={""} />
        ) : (
          <KeyValue2 keyName="Transaction Fee" value={""} />
        )}
        {selectedSource.currency !== CURRENCY.USD.code && (
          <KeyValue4 keyName={"Conversion Rate"} value={getCurrencyRateStr()} />
        )}
        {selectedSource.currency !== CURRENCY.USD.code && (
          <KeyValue4 keyName={"Amount Value in USD"} value={getAmountToBeSentStr()} />
        )}
        <KeyValue4 keyName={"Transfer Fee"} value={getTransferFeeStr()} />
        <KeyValue4 keyName={"Total Deduct"} value={getTotalDeductStr()} />

        <Divider />

        <KeyValue2 keyName="Memo" value={""} />
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: "white",
            borderRadius: 10,
            marginTop: 5
          }}
        >
          <p style={{ textAlign: "left" }}>{memo}</p>
        </div>

        <Divider />

        <Checkbox
          onChange={(e) => {
            setConfirmChecked(e.target.checked);
          }}
        >
          I confirm that the information is correct.
        </Checkbox>
      </Modal>
      <Transaction2faModal
        visible={transaction2faModalVisible}
        onOk={(mfaCode) => sendTransferRequest({ mfaCode })}
        onCancel={() => {
          setTransaction2faModalVisible(false);
          setConfirmLoading(false);
        }}
        email={user?.username}
      />
    </div>
  );
};

export const BetweenMembers = boundError(BetweenMembersRaw);
