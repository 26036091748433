import React, { useEffect, useState, useRef } from "react";
import { PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Row, Space, Upload, Typography, Select, DatePicker, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { updateBusinessAddress, uploadProofOfAddress, downloadProofOfAddress } from "../../apis";
import { boundError } from "../../utils";
import { CountrySelect } from "../CountrySelect";
const { Title } = Typography;
import { BackBtn } from "../SharedComponents/Button/BackBtn";
import moment from "moment";
import { REGISTRATION_DATA_STATUS } from "../../constants/registrationDataStatus";
import { showMessage, showError } from "../../utils";

const BusinessAddressFormRaw = ({ userRegistrationStatus, reloadUserRegistrationStatus }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [diffTradingAddress, setDiffTradingAddress] = useState(false);
  const businessAddressStatus = userRegistrationStatus?.userRegistrationData?.businessAddressStatus;

  const isMounted = useRef(true);

  useEffect(() => {
    isSameTradingAddress();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onFinish = async (values) => {
    setError(null);
    setLoading(true);
    values = { ...values };

    const businessAddress = {
      businessAddressLine1: values?.businessAddressLine1,
      businessAddressLine2: values?.businessAddressLine2,
      businessAddressCity: values?.businessAddressCity,
      businessAddressState: values?.businessAddressState,
      businessAddressCountryCode: values?.businessAddressCountryCode,
      businessAddressPostalCode: values?.businessAddressPostalCode
    };

    if (diffTradingAddress) {
      businessAddress.businessTradingAddressLine1 = values?.businessTradingAddressLine1;
      businessAddress.businessTradingAddressLine2 = values?.businessTradingAddressLine2;
      businessAddress.businessTradingAddressCity = values?.businessTradingAddressCity;
      businessAddress.businessTradingAddressState = values?.businessTradingAddressState;
      businessAddress.businessTradingAddressCountryCode = values?.businessTradingAddressCountryCode;
      businessAddress.businessTradingAddressPostalCode = values?.businessTradingAddressPostalCode;
    } else {
      businessAddress.businessTradingAddressLine1 = values?.businessAddressLine1;
      businessAddress.businessTradingAddressLine2 = values?.businessAddressLine2;
      businessAddress.businessTradingAddressCity = values?.businessAddressCity;
      businessAddress.businessTradingAddressState = values?.businessAddressState;
      businessAddress.businessTradingAddressCountryCode = values?.businessAddressCountryCode;
      businessAddress.businessTradingAddressPostalCode = values?.businessAddressPostalCode;
    }

    businessAddress.submit = true;

    try {
      await updateBusinessAddress(businessAddress);
      reloadUserRegistrationStatus();

      showMessage("Submitted successfully!");
      setError(null);
    } catch (err) {
      showError(err);
      setError(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const onSaveAndExit = async () => {
    setError(null);
    setLoading(true);
    try {
      const formData = form.getFieldsValue([
        "businessAddressLine1",
        "business_number",
        "businessAddressLine2",
        "businessAddressCity",
        "businessAddressState",
        "businessAddressCountryCode",
        "businessAddressPostalCode",
        "trading_number",
        "businessTradingAddressLine1",
        "businessTradingAddressLine2",
        "businessTradingAddressCity",
        "businessTradingAddressState",
        "businessTradingAddressCountryCode",
        "businessTradingAddressPostalCode"
      ]);

      const businessAddress = {
        businessAddressLine1: formData?.businessAddressLine1,
        businessAddressLine2: formData?.businessAddressLine2,
        businessAddressCity: formData?.businessAddressCity,
        businessAddressState: formData?.businessAddressState,
        businessAddressCountryCode: formData?.businessAddressCountryCode,
        businessAddressPostalCode: formData?.businessAddressPostalCode
      };

      if (diffTradingAddress) {
        businessAddress.businessTradingAddressLine1 = formData?.businessTradingAddressLine1;
        businessAddress.businessTradingAddressLine2 = formData?.businessTradingAddressLine2;
        businessAddress.businessTradingAddressCity = formData?.businessTradingAddressCity;
        businessAddress.businessTradingAddressState = formData?.businessTradingAddressState;
        businessAddress.businessTradingAddressCountryCode = formData?.businessTradingAddressCountryCode;
        businessAddress.businessTradingAddressPostalCode = formData?.businessTradingAddressPostalCode;
      } else {
        businessAddress.businessTradingAddressLine1 = formData?.businessAddressLine1;
        businessAddress.businessTradingAddressLine2 = formData?.businessAddressLine2;
        businessAddress.businessTradingAddressCity = formData?.businessAddressCity;
        businessAddress.businessTradingAddressState = formData?.businessAddressState;
        businessAddress.businessTradingAddressCountryCode = formData?.businessAddressCountryCode;
        businessAddress.businessTradingAddressPostalCode = formData?.businessAddressPostalCode;
      }

      await updateBusinessAddress(businessAddress);
      reloadUserRegistrationStatus();
      showMessage("Information saved!");
      goBack();
    } catch (err) {
      showError(err);
      setError(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.slice(-1);
    }
    return e?.fileList?.slice(-1);
  };

  const createCustomUploadRequest =
    (uploader) =>
    async ({ file, onError, onSuccess }) => {
      try {
        await uploader(file);
        onSuccess();
      } catch (err) {
        onError(err);
      }
    };

  const createCustomFileListItemRenderer = (onClickHandler) => (originalNode) => {
    return (
      <Button type="link" onClick={onClickHandler} style={{ padding: 0 }}>
        {originalNode}
      </Button>
    );
  };

  const urd = userRegistrationStatus?.userRegistrationData;
  const initialValues = {
    businessAddressLine1: urd?.businessAddressLine1,
    businessAddressLine2: urd?.businessAddressLine2,
    businessAddressCity: urd?.businessAddressCity,
    businessAddressState: urd?.businessAddressState,
    businessAddressCountryCode: urd?.businessAddressCountryCode,
    businessAddressPostalCode: urd?.businessAddressPostalCode,

    businessTradingAddressLine1: urd?.businessTradingAddressLine1,
    businessTradingAddressLine2: urd?.businessTradingAddressLine2,
    businessTradingAddressCity: urd?.businessTradingAddressCity,
    businessTradingAddressState: urd?.businessTradingAddressState,
    businessTradingAddressCountryCode: urd?.businessTradingAddressCountryCode,
    businessTradingAddressPostalCode: urd?.businessTradingAddressPostalCode,

    businessProofOfAddressFile: urd?.businessProofOfAddressFile
      ? [
          {
            uid: urd.businessProofOfAddressFile.Key,
            name: `Document uploaded at ${new Date(urd.businessProofOfAddressFile.createdAt).toLocaleString()}`,
            status: "done"
          }
        ]
      : []
  };

  const shouldDisableForm =
    businessAddressStatus === REGISTRATION_DATA_STATUS.SUBMITTED ||
    businessAddressStatus === REGISTRATION_DATA_STATUS.COMPLETED;

  const isSameTradingAddress = () => {
    if (
      urd?.businessAddressLine1 !== urd?.businessTradingAddressLine1 ||
      urd?.businessAddressLine2 !== urd?.businessTradingAddressLine2 ||
      urd?.businessAddressCity !== urd?.businessTradingAddressCity ||
      urd?.businessAddressState !== urd?.businessTradingAddressState ||
      urd?.businessAddressCountryCode !== urd?.businessTradingAddressCountryCode ||
      urd?.businessAddressPostalCode !== urd?.businessTradingAddressPostalCode
    ) {
      setDiffTradingAddress(true);
    } else {
      setDiffTradingAddress(false);
    }
  };

  const goBack = () => {
    navigate("/onboarding/business");
  };

  const renderBusinessAddress = () => {
    return (
      <>
        <Title level={3}>Enter your registered business address</Title>
        <Title level={5} type="secondary">
          The official address registered with a government body.
        </Title>
        <Divider />
        <Form.Item
          label="Address 1"
          name="businessAddressLine1"
          rules={[{ required: true, message: "Address 1 is required!" }]}
          // style={{ width: "350px", display: "inline-block", marginRight: "20px" }}
        >
          <Input type="text" disabled={shouldDisableForm} placeholder="Address 1" />
        </Form.Item>
        {/* <Form.Item
          name="business_number"
          rules={[{ required: true, message: "Can not be empty" }]}
          style={{ width: "100px", display: "inline-block" }}
        >
          <Input type="text" disabled={shouldDisableForm} placeholder="Number" />
        </Form.Item> */}
        <Form.Item label="Address 2" name="businessAddressLine2">
          <Input type="text" disabled={shouldDisableForm} placeholder="Address 2 (Optional)" />
        </Form.Item>
        <Form.Item label="City" name="businessAddressCity" rules={[{ required: true, message: "City is required!" }]}>
          <Input type="text" disabled={shouldDisableForm} placeholder="City" />
        </Form.Item>
        <Form.Item
          label="State/Province"
          name="businessAddressState"
          rules={[{ required: true, message: "State/Province is required!" }]}
        >
          <Input type="text" disabled={shouldDisableForm} placeholder="State/Province" />
        </Form.Item>
        <Form.Item
          label="Country"
          name="businessAddressCountryCode"
          rules={[{ required: true, message: "Country is required!" }]}
        >
          <CountrySelect disabled={shouldDisableForm} />
        </Form.Item>
        <Form.Item
          label="Post Code / Zip Code"
          name="businessAddressPostalCode"
          rules={[{ required: true, message: "Post Code / Zip Code is required!" }]}
        >
          <Input type="text" disabled={shouldDisableForm} placeholder="Post Code / Zip Code" />
        </Form.Item>
        {!shouldDisableForm && (
          <Checkbox checked={diffTradingAddress} onChange={() => setDiffTradingAddress(!diffTradingAddress)}>
            Different trading address
          </Checkbox>
        )}
      </>
    );
  };

  const renderTradingAddress = () => {
    return (
      <>
        <Title level={5} type="secondary">
          Current trading address
        </Title>
        <Divider />
        <Form.Item
          name="businessTradingAddressLine1"
          label="Address 1"
          rules={[{ required: diffTradingAddress, message: "Address 1 is required!" }]}
          // style={{ width: "350px", display: "inline-block", marginRight: "20px" }}
        >
          <Input type="text" disabled={shouldDisableForm} placeholder="Address 1" />
        </Form.Item>
        {/* <Form.Item
          name="trading_number"
          rules={[{ required: diffTradingAddress, message: "Can not be empty" }]}
          style={{ width: "100px", display: "inline-block" }}
        >
          <Input type="text" disabled={shouldDisableForm} placeholder="Number" />
        </Form.Item> */}
        <Form.Item name="businessTradingAddressLine2" label="Address 2">
          <Input type="text" disabled={shouldDisableForm} placeholder="Address 2 (Optional)" />
        </Form.Item>
        <Form.Item
          name="businessTradingAddressCity"
          label="City"
          rules={[{ required: diffTradingAddress, message: "City is required!" }]}
        >
          <Input type="text" disabled={shouldDisableForm} placeholder="City" />
        </Form.Item>
        <Form.Item
          name="businessTradingAddressState"
          label="State/Province"
          rules={[{ required: diffTradingAddress, message: "State/Province is required!" }]}
        >
          <Input type="text" disabled={shouldDisableForm} placeholder="State/Province" />
        </Form.Item>
        <Form.Item
          name="businessTradingAddressCountryCode"
          label="Country"
          rules={[{ required: diffTradingAddress, message: "Country is required!" }]}
        >
          {/* <Input type="text" disabled={shouldDisableForm} placeholder="Country" /> */}
          <CountrySelect disabled={shouldDisableForm} />
        </Form.Item>
        <Form.Item
          name="businessTradingAddressPostalCode"
          label="Post Code / Zip Code"
          rules={[{ required: diffTradingAddress, message: "Post Code / Zip Code is required!" }]}
        >
          <Input type="text" disabled={shouldDisableForm} placeholder="Post Code / Zip Code" />
        </Form.Item>
      </>
    );
  };

  const renderProofOfAddress = () => {
    return (
      <>
        <Title level={3}>Proof of Address</Title>
        <Title level={5} type="secondary">
          To confirm your address, please upload a digital copy of a recent Proof of Address
        </Title>
        <Divider />
        <Form.Item
          name="businessProofOfAddressFile"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: false, message: "Please upload business proof of address!" }]}
        >
          <Upload
            customRequest={createCustomUploadRequest(uploadProofOfAddress)}
            multiple={false}
            showUploadList={{ showRemoveIcon: false }}
            disabled={shouldDisableForm}
            accept=".png,.jpg,jpeg,.pdf"
            itemRender={createCustomFileListItemRenderer(downloadProofOfAddress)}
          >
            <Button icon={<UploadOutlined />} disabled={shouldDisableForm}>
              Upload
            </Button>
          </Upload>
        </Form.Item>
      </>
    );
  };

  const renderSubmitAndSaveBtn = () => {
    return (
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" disabled={loading || shouldDisableForm}>
            Submit
          </Button>
          {shouldDisableForm ? (
            <Button type="primary" onClick={() => goBack()}>
              Exit
            </Button>
          ) : (
            <Button disabled={loading} onClick={onSaveAndExit}>
              Save and exit
            </Button>
          )}
        </Space>
      </Form.Item>
    );
  };

  return (
    <div style={{ textAlign: "left", padding: 15, marginBottom: 50 }}>
      <Row justify="center">
        <Col sm={24} md={18}>
          <BackBtn onClick={goBack} />
          <Form form={form} layout="vertical" name="business-records" onFinish={onFinish} initialValues={initialValues}>
            {renderBusinessAddress()}
            <br />
            <br />
            {(diffTradingAddress || shouldDisableForm) && renderTradingAddress()}
            {/* {shouldDisableForm && renderTradingAddress()} */}
            <br />
            {renderProofOfAddress()}
            <br />
            {renderSubmitAndSaveBtn()}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export const BusinessAddressForm = boundError(BusinessAddressFormRaw);
