import React, { useState } from "react";
import { boundError } from "../utils";
import { Select } from "antd";
import { COUNTRY, getCountryObjByCode } from "../constants/country";
const { Option } = Select;

const CountrySelectorRaw = ({ onChange, style, settings, selectedCountry, placeholder = "Change Country" }) => {
  const [searchCountry, setSearchCountry] = useState("");

  const onCountrySelected = (value) => {
    setSearchCountry("");
    const countryObj = getCountryObjByCode(value);
    onChange(countryObj);
  };

  return (
    <Select
      placeholder={placeholder}
      value={selectedCountry?.code}
      style={{ ...style, display: "flex" }}
      onChange={onCountrySelected}
      autoComplete="none"
      onInputKeyDown={(e) => {
        if (e.key == "Backspace" && searchCountry.length > 0) {
          setSearchCountry(searchCountry.slice(0, -1));
        } else if (/^[a-zA-Z]/.test(e.key) && e.key.length < 2) {
          setSearchCountry(searchCountry + e.key);
        }
      }}
      {...settings}
    >
      {Object.values(COUNTRY)
        .filter((each) => each.name.toLowerCase().includes(searchCountry.toLowerCase()))
        .map((each) => {
          return (
            <Option value={each.code} key={each.code}>
              {each.name}
            </Option>
          );
        })}
    </Select>
  );
};

export const CountrySelector = boundError(CountrySelectorRaw);
