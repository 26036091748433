import { Select } from "antd";
import React from "react";
import { COUNTRY } from "../constants/country";

export const CountrySelect = (props) => {
  return (
    <Select
      {...props}
      showSearch
      onSelect={props.onSelect}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {Object.entries(COUNTRY).map(([_, country]) => {
        return (
          <Select.Option key={country.code} value={country.code}>
            {country.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};
