import React from "react";
import { boundError } from "../../../utils";

const SimpleButtonRaw = ({ children, disabled, style, ...props }) => {
  if (disabled) {
    style = {
      backgroundColor: "#aaaaaa",
      ...style
    };
  }
  return (
    <button
      className="simple-button"
      disabled={disabled}
      style={{ display: "flex", justifyContent: "center", alignItems: "center", ...style }}
      {...props}
    >
      {children}
    </button>
  );
};

export const SimpleButton = boundError(SimpleButtonRaw);
