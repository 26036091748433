import React from "react";
import { boundError } from "../utils";
import usFlag from "../resources/images/flags/usd_icon.svg";
import vaultFlag from "../resources/images/flags/axia_coin_vault.png";
import axc_icon from "../resources/images/flags/axc_icon.svg";
import { formatAmount } from "../utils";
import { ACCOUNT_STATUS } from "../constants/accountStatus";
import { Tag } from "antd";
import Big from "big.js";

const AccountCardRaw = ({
  publicId,
  type,
  amount,
  interestAmount,
  spendableAmount,
  currency,
  typePublicName,
  status,
  isStaking
}) => {
  let image;
  switch (currency) {
    case "USD":
      image = usFlag;
      break;
    // add this for future develop
    case "axiaVault":
      image = vaultFlag;
      break;
    default:
      // AXC account
      image = axc_icon;
  }

  const getAccountAmountStr = () => {
    let str = "";

    let totalAmount = Big(0);
    if (amount) {
      totalAmount = totalAmount.plus(amount);
    }

    // if (interestAmount) {
    //   totalAmount = totalAmount.plus(interestAmount);
    // }

    str = formatAmount(totalAmount?.toNumber(), currency);

    if (currency) {
      str = str + (str != "" ? " " : "") + currency;
    }

    return str;
  };

  const getAccountSpendableAmountStr = () => {
    let str = "";

    if (spendableAmount) {
      str = formatAmount(spendableAmount, currency);
      if (currency) {
        str = str + (str != "" ? " " : "") + currency;
      }
    }

    return str;
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <img src={image} alt="" style={{ height: 30, width: 30, marginRight: "1rem" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          {/* TODO: add account name? */}
          <p className="margin-none" style={{ display: "flex", flex: 1, fontSize: "1rem", fontWeight: "500" }}>
            {typePublicName}
          </p>
          <p
            className="margin-none"
            style={{ display: "flex", flex: 1, fontSize: "0.8rem", fontWeight: "500", color: "#777E91" }}
          >
            {publicId}
          </p>
          {isStaking !== true && status && status !== ACCOUNT_STATUS.ACTIVE && (
            <p
              className="margin-none"
              style={{ display: "flex", flex: 1, fontSize: "0.8rem", fontWeight: "500", color: "#ff3141" }}
            >
              <Tag color="red" style={{ borderRadius: "20px", marginTop: "1rem" }}>
                Action Required
              </Tag>
            </p>
          )}
        </div>
      </div>
      {/* <div style={{ flex: 1 }} /> */}
      <div style={{ flex: 1, marginRight: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
            fontSize: "1.2rem",
            color: "#178FE1",
            fontWeight: 500,
            marginRight: "auto",
            marginLeft: "auto"
          }}
        >
          <p className="margin-none">{getAccountAmountStr()}</p>
        </div>
        {getAccountSpendableAmountStr() !== "" && (
          <div className="row">
            <div style={{ flex: 1 }} />
            <p className="margin-none" style={{ fontWeight: "400", fontSize: 12, color: "grey" }}>
              {"Total Spendable: " + getAccountSpendableAmountStr()}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export const AccountCard = boundError(AccountCardRaw);
