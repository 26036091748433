import React from "react";
import { boundError } from "../../utils";
import { Menu, Dropdown, Button, Space } from "antd";

const DropDownMenuRaw = ({ options, onChange, style, settings, content }) => {
  const menu = (
    <Menu>
      {options.map((each, index) => {
        return (
          <Menu.Item key={index}>
            <Button
              type="text"
              onClick={() => {
                onChange(each);
              }}
            >
              {each}
            </Button>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} {...settings}>
      {content}
    </Dropdown>
  );
};

export const DropDownMenu = boundError(DropDownMenuRaw);
