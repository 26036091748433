import React, { useContext, useState } from "react";
import {
  boundError,
  showError,
  formatAmount,
  upperCaseFirstCharEachForWord,
  showMessage,
  FEATURE_NAMES,
  isValidForFeature
} from "../../utils";
import { Button, DatePicker, Divider, notification, Spin, Modal as Modal2 } from "antd";
import { CardLoadModal, TabsMenu, BackBtn } from "..";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import creditCard from "../../resources/images/card1.png";
import { CreditCardOutlined, ExclamationCircleOutlined, LoginOutlined, UnlockOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import {
  doGetCardTransHistory,
  doActivateCard,
  doSuspendCard,
  doGetCardInfoByCardId,
  doGetCardSecurityData,
  doGetCardPinNumberApi,
  doGetCardShipmentTrackingNoApi
} from "../../apis/card";
import date from "date-and-time";
import moment from "moment";
import { CARD_STATUS, getCountryNameByCode } from "../../constants/";
import { CardStatement } from "./CardStatement";
import { ConvertPhysicalModal } from "./ConvertPhysicalModal";
import { ActivatePhysicalCardModal } from "./ActivatePhysicalCardModal";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import Big from "big.js";
import { UserContext } from "../../hooks";

const CardDetailPageRaw = () => {
  const { state } = useLocation();
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const { cardId } = useParams();
  const [card, setCard] = useState(state?.card);
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [onCardDetailTab, setOnCardDetailTab] = useState("0");
  const [securityData, setSecurityData] = useState();
  const [showSecurityData, setShowSecurityData] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [showConvertPhysicalModal, setShowConvertPhysicalModal] = useState(false);
  const [showActivatePhysicalModal, setShowActivatePhysicalModal] = useState(false);
  const [showConvertSuccessModal, setShowConvertSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    fetchCardInfo();
  }, [isRefresh]);

  const fetchCardInfo = async () => {
    setLoading(true);
    try {
      const data = await doGetCardInfoByCardId(cardId);
      setCard(data);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const refreshPage = () => {
    setIsRefresh(!isRefresh);
  };

  const fetchCardSecurityData = async () => {
    setLoading(true);
    try {
      const data = await doGetCardSecurityData(cardId);
      setSecurityData(data);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = async (notif, updateCardDetail) => {
    if (notif) {
      if (updateCardDetail) {
        await fetchCardInfo();
      }
      notification.open(notif);
    }
    setShowLoadModal(false);
  };

  const closeConvertPhysicCardModal = async (notif, updateCardDetail) => {
    setShowConvertPhysicalModal(false);
  };

  const closeActivatePhysicCardModal = async (notif, updateCardDetail) => {
    setShowActivatePhysicalModal(false);
  };

  const showPinNumber = async () => {
    try {
      setLoading(true);
      const result = await doGetCardPinNumberApi({ cardId });

      Modal2.success({
        title: "PIN",
        icon: <ExclamationCircleOutlined />,
        content: `Your card PIN number is: ${result?.pin}. Please keep your pin safe.`,
        okText: "OK",
        cancelText: "Cancel"
      });
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const showTrackingNumber = async () => {
    try {
      setLoading(true);
      const result = await doGetCardShipmentTrackingNoApi({ cardId });

      const href = `https://www.dhl.com/ca-en/home/tracking.html?tracking-id=${result?.trackingNumber}&submit=1`;
      const title = result?.trackingNumber
        ? `Tracking number: ${result?.trackingNumber}`
        : "Tracking number is not available";
      const content = result?.trackingNumber ? (
        <p>
          Please track your order here <a href={href}>here</a>
        </p>
      ) : (
        "Please allow 2-5 business days to receive your tracking number."
      );
      Modal2.success({
        title,
        icon: <ExclamationCircleOutlined />,
        content,
        okText: "OK",
        cancelText: "Cancel"
      });
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  const activateCard = async () => {
    setLoading(true);
    try {
      await doActivateCard(cardId);
      showMessage("Card activated successfully!");
      await fetchCardInfo();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const suspendCard = async () => {
    setLoading(true);
    try {
      await doSuspendCard(cardId);
      showMessage("Card suspended successfully!");
      await fetchCardInfo();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const onLoadCardPress = () => {
    if (isValidForFeature({ user, featureName: FEATURE_NAMES.CARD })) {
      setShowLoadModal(true);
    } else {
      showError("This service is temporarily unavailable, please try again later.");
    }
  };

  const showCardSecurity = async () => {
    if (showSecurityData) {
      setShowSecurityData(false);
    } else {
      if (!securityData) {
        await fetchCardSecurityData();
      }

      setShowSecurityData(true);
    }
  };

  const getCardSize = () => {
    let cardWidth = 45;
    if (width < 800) {
      cardWidth = 85;
    } else if (width >= 1100) {
      cardWidth = 35;
    }
    const cardHeight = (cardWidth / 3) * 2;
    const cardNumberSize = cardHeight / 7;
    const cardNameSize = cardHeight / 8;

    const cardNumberPaddingBottom = cardHeight / 6;
    const cardNamePaddingBottom = cardHeight / 9;

    const paddingLeft = cardWidth / 15;
    const paddingRight = cardWidth / 15;

    const balanceSize = cardHeight / 10;
    const currencySize = cardHeight / 18;

    const statusSize = cardHeight / 18;
    const statusPaddingHor = cardHeight / 14;
    const statusHeight = cardHeight / 10;
    const statusBorderRadius = statusHeight / 2;
    const statusMargin = cardHeight / 30;

    return {
      cardWidth: `${cardWidth}vw`,
      cardHeight: `${cardHeight}vw`,
      cardNumberSize: `${cardNumberSize}vw`,
      cardNameSize: `${cardNameSize}vw`,
      cardNumberPaddingBottom: `${cardNumberPaddingBottom}vw`,
      cardNamePaddingBottom: `${cardNamePaddingBottom}vw`,
      paddingLeft: `${paddingLeft}vw`,
      paddingRight: `${paddingRight}vw`,
      balanceSize: `${balanceSize}vw`,
      currencySize: `${currencySize}vw`,
      statusSize: `${statusSize}vw`,
      statusPaddingHor: `${statusPaddingHor}vw`,
      statusHeight: `${statusHeight}vw`,
      statusBorderRadius: `${statusBorderRadius}vw`,
      statusMargin: `${statusMargin}vw`
    };
  };

  const getStatusColor = (status) => {
    const colors = {
      ACTIVE: "#35b994",
      SUSPENDED: "red",
      INACTIVE: "#efd915",
      LOST: "red",
      STOLEN: "red",
      EXPIRED: "#cccccc",
      UNKNOWN: "#cccccc"
    };

    return colors[status] ?? colors.UNKNOWN;
  };

  const renderCardOverview = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="cardDetail-overall-row">
          <div className="cardDetail-overall-container">
            {/** Left side */}
            <div
              className="cardDetail-cardImage"
              style={{
                position: "relative",
                width: getCardSize().cardWidth,
                height: getCardSize().cardHeight,
                borderRadius: getCardSize().statusBorderRadius,
                backgroundColor: "green"
              }}
            >
              {showSecurityData ? (
                <img
                  alt="security"
                  src={securityData?.imgUrl}
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "pink",
                    borderRadius: 10,
                    resizeMode: "contain"
                  }}
                />
              ) : (
                <img alt="credit_card" src={creditCard} />
              )}
              {showSecurityData && (
                <div className="cardDetail-expireCvv">
                  <p className="margin-none">{"CVV: " + securityData?.cvv}</p>
                  <p className="margin-none">{securityData?.expire}</p>
                </div>
              )}
            </div>

            {/** Right side */}
            <div
              className="cardDetail-cardInfoSection"
              style={{
                backgroundColor: "white",
                position: "relative",
                width: getCardSize().cardWidth,
                height: getCardSize().cardHeight,
                borderRadius: getCardSize().statusBorderRadius
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="cardDetail-balance">
                  <p className="margin-none" style={{ fontWeight: "500", fontSize: 22 }}>
                    Card Balance:
                  </p>
                  <p style={{ fontSize: "30px", color: "#35B994", fontWeight: "500" }}>
                    {card?.balance ? formatAmount(card?.balance || 0, card?.currency) : "N/A"}{" "}
                    <span style={{ fontSize: 16 }}>{card?.currency}</span>
                  </p>
                </div>
                <div className="cardDetail-cardStatus">
                  <div
                    className={`card-status card-status-${card?.status}`}
                    style={{ backgroundColor: getStatusColor(card?.status) }}
                  >
                    <p style={{ color: "white" }}>{card?.status}</p>
                  </div>
                </div>
              </div>
              <div style={{ margin: "0 auto 20px 20px" }}>
                {showAction([CARD_STATUS.ACTIVE]) && (
                  <Button icon={<LoginOutlined />} className="load-btn" onClick={onLoadCardPress}>
                    Load
                  </Button>
                )}
                {/* {showAction([CARD_STATUS.SUSPENDED]) && (
                  <Button icon={<UnlockOutlined />} className="load-btn" onClick={activateCard}>
                    Re-Activate
                  </Button>
                )} */}
                {showAction([CARD_STATUS.INACTIVE]) && (
                  <Button icon={<UnlockOutlined />} className="load-btn" onClick={activateCard}>
                    Activate
                  </Button>
                )}
                <Button
                  className="load-btn"
                  style={{ backgroundColor: "#178FE1", margin: 0, color: "#ffffff" }}
                  onClick={() => {
                    // setShowLoadModal(true);
                    showCardSecurity();
                  }}
                >
                  {showSecurityData ? "Hide Details" : "Show Details"}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="cardDetail-detail-container">
          <div style={{ paddingBottom: 60 }}>
            <TabsMenu
              options={["Transactions", "Summary", "Actions"]}
              onChange={setOnCardDetailTab}
              style={{ color: "#02466F" }}
            />
            {onCardDetailTab == "0" && <CardStatement card={card} />}
            {onCardDetailTab == "1" && renderSummaryTab()}
            {onCardDetailTab == "2" && renderActionsTab()}
          </div>
        </div>
      </div>
    );
  };

  const renderSummaryTab = () => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: width < 870 ? "column" : "row",
            justifyContent: width < 870 ? undefined : "space-between",
            alignItems: width < 870 ? undefined : "center",
            paddingRight: 50,
            paddingLeft: 50,
            marginBottom: 10
          }}
        >
          <p className="margin-none" style={{ textAlign: "left", fontWeight: 500, fontSize: 17, color: "#1890ff" }}>
            Card limits:
          </p>

          {card?.isFullKYC !== true ? (
            <p className="margin-none" style={{ textAlign: "left", fontWeight: 500, fontSize: 12, color: "red" }}>
              * KYC Not Verified (please{" "}
              <a
                onClick={() => {
                  navigate(`/upload-document`);
                }}
              >
                upload documents
              </a>
              )
            </p>
          ) : (
            <p className="margin-none" style={{ textAlign: "left", fontWeight: 500, fontSize: 12, color: "green" }}>
              * KYC Verified
            </p>
          )}
        </div>

        <div className="card-transaction-container">
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>Maximum Card Balance</p>
            <p>
              {formatAmount(card?.limit?.maxCardBalance, card?.currency)} {card?.currency}
            </p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>Daily Load Limit</p>
            <p>
              {formatAmount(card?.limit?.dailyLoadLimit, card?.currency)} {card?.currency}
            </p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>Daily Load Frequency Limit</p>
            <p>{card?.limit?.dailyLoadFrequencyLimit}</p>
          </div>
          {!Big(card?.limit?.weeklyLoadLimit ?? 999999).eq(999999) && (
            <div className="tab-rows">
              <p style={{ fontWeight: "500" }}>Weekly Load Limit</p>
              <p>
                {formatAmount(card?.limit?.weeklyLoadLimit, card?.currency)} {card?.currency}
              </p>
            </div>
          )}
          {!Big(card?.limit?.weeklyLoadFrequencyLimit ?? 999999).eq(999999) && (
            <div className="tab-rows">
              <p style={{ fontWeight: "500" }}>Weekly Load Frequency Limit</p>
              <p>{card?.limit?.weeklyLoadFrequencyLimit}</p>
            </div>
          )}
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>Monthly Load Limit</p>
            <p>
              {formatAmount(card?.limit?.monthlyLoadLimit, card?.currency)} {card?.currency}
            </p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>Monthly Load Frequency Limit</p>
            <p>{card?.limit?.monthlyLoadFrequencyLimit}</p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>Yearly Load Limit</p>
            <p>
              {formatAmount(card?.limit?.yearlyLoadLimit, card?.currency)} {card?.currency}
            </p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>Yearly Load Frequency Limit</p>
            <p>{card?.limit?.yearlyLoadFrequencyLimit}</p>
          </div>

          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>POS Daily Spending Limit</p>
            <p>
              {formatAmount(card?.limit?.dailyPosUnloadLimit, card?.currency)} {card?.currency}
            </p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>POS Daily Spending Frequency Limit</p>
            <p>{card?.limit?.dailyPosFrequencyLimit}</p>
          </div>
          {!Big(card?.limit?.weeklyPosUnloadLimit ?? 999999).eq(999999) && (
            <div className="tab-rows">
              <p style={{ fontWeight: "500" }}>POS Weekly Spending Limit</p>
              <p>
                {formatAmount(card?.limit?.weeklyPosUnloadLimit, card?.currency)} {card?.currency}
              </p>
            </div>
          )}
          {!Big(card?.limit?.weeklyPosFrequencyLimit ?? 999999).eq(999999) && (
            <div className="tab-rows">
              <p style={{ fontWeight: "500" }}>POS Weekly Spending Frequency Limit</p>
              <p>{card?.limit?.weeklyPosFrequencyLimit}</p>
            </div>
          )}
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>POS Monthly Spending Limit</p>
            <p>
              {formatAmount(card?.limit?.monthlyPosUnloadLimit, card?.currency)} {card?.currency}
            </p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>POS Monthly Spending Frequency Limit</p>
            <p>{card?.limit?.monthlyPosFrequencyLimit}</p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>POS Yearly Spending Limit</p>
            <p>
              {formatAmount(card?.limit?.yearlyPosUnloadLimit, card?.currency)} {card?.currency}
            </p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>POS Yearly Spending Frequency Limit</p>
            <p>{card?.limit?.yearlyPosFrequencyLimit}</p>
          </div>

          <div className="tab-rows" style={{ borderBottom: 0 }}>
            <p style={{ fontWeight: "500" }}>ATM Daily Withdrawal Limit</p>
            <p>
              {formatAmount(card?.limit?.dailyAtmUnloadLimit, card?.currency)} {card?.currency}
            </p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>ATM Daily Withdrawal Frequency Limit</p>
            <p>{card?.limit?.dailyAtmFrequencyLimit}</p>
          </div>
          {!Big(card?.limit?.weeklyAtmUnloadLimit ?? 999999).eq(999999) && (
            <div className="tab-rows" style={{ borderBottom: 0 }}>
              <p style={{ fontWeight: "500" }}>ATM Weekly Withdrawal Limit</p>
              <p>
                {formatAmount(card?.limit?.weeklyAtmUnloadLimit, card?.currency)} {card?.currency}
              </p>
            </div>
          )}
          {!Big(card?.limit?.weeklyAtmFrequencyLimit ?? 999999).eq(999999) && (
            <div className="tab-rows">
              <p style={{ fontWeight: "500" }}>ATM Weekly Withdrawal Frequency Limit</p>
              <p>{card?.limit?.weeklyAtmFrequencyLimit}</p>
            </div>
          )}
          <div className="tab-rows" style={{ borderBottom: 0 }}>
            <p style={{ fontWeight: "500" }}>ATM Monthly Withdrawal Limit</p>
            <p>
              {formatAmount(card?.limit?.monthlyAtmUnloadLimit, card?.currency)} {card?.currency}
            </p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>ATM Monthly Withdrawal Frequency Limit</p>
            <p>{card?.limit?.monthlyAtmFrequencyLimit}</p>
          </div>
          <div className="tab-rows" style={{ borderBottom: 0 }}>
            <p style={{ fontWeight: "500" }}>ATM Yearly Withdrawal Limit</p>
            <p>
              {formatAmount(card?.limit?.yearlyAtmUnloadLimit, card?.currency)} {card?.currency}
            </p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>ATM Yearly Withdrawal Frequency Limit</p>
            <p>{card?.limit?.yearlyAtmFrequencyLimit}</p>
          </div>

          {/* <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>ATM single withdrawal limit</p>
            <p>
              {formatAmount(card?.limit.singleAtmUnloadLimit, card?.currency)} {card?.currency}
            </p>
          </div> */}
          {/* <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>Single load limit</p>
            <p>
              {formatAmount(card?.limit.dailyLoadLimitLeft, card?.currency)} {card?.currency}
            </p>
          </div> */}
          {/* <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>POS single transaction limit</p>
            <p>
              {formatAmount(card?.limit.singlePosUnloadLimit, card?.currency)} {card?.currency}
            </p>
          </div> */}
        </div>

        <p style={{ textAlign: "left", margin: "10px 0px 5px 50px", fontWeight: 500, fontSize: 17, color: "#1890ff" }}>
          Fees:
        </p>
        <div className="card-transaction-container">
          <div className="tab-rows" style={{ borderBottom: 0 }}>
            <p style={{ fontWeight: "500" }}>Platform load fee</p>
            <p>{"1.7% of loading fee"}</p>
          </div>
          <div className="tab-rows" style={{ borderBottom: 0 }}>
            <p style={{ fontWeight: "500" }}>Load card fee</p>
            <p>{card?.limit?.loadFeeStr}</p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>Domestic ATM/Cash withdrawal</p>
            <p>{card?.limit?.atmCashWithdrawalDomesticFee}</p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>International ATM/Cash withdrawal</p>
            <p>{card?.limit?.atmCashWithdrawalInternationalFee}</p>
          </div>
          <div className="tab-rows">
            <p style={{ fontWeight: "500" }}>Domestic POS purchase</p>
            <p>{card?.limit?.posDomesticFee}</p>
          </div>
          <div className="tab-rows" style={{ borderBottom: 0 }}>
            <p style={{ fontWeight: "500" }}>International POS purchase</p>
            <p>{card?.limit?.posInternationalFee}</p>
          </div>
        </div>
      </div>
    );
  };

  const showAction = (cardInStatus) => {
    return cardInStatus.includes(card?.status);
  };

  const formatAddress = () => {
    return `${upperCaseFirstCharEachForWord(card.deliveryAddress1)}, ${upperCaseFirstCharEachForWord(
      card.deliveryCity
    )}, ${upperCaseFirstCharEachForWord(card.deliveryCountryState)}, ${getCountryNameByCode(card.addressCountry)}`;
  };

  const renderActionsTab = () => {
    return (
      <div className="card-transaction-container">
        <div className="tab-rows" style={{ borderBottom: 0, flexDirection: "row" }}>
          <p style={{ fontWeight: "500" }}>{"Show PIN number"}</p>
          <ActionBtn
            text={"Show"}
            onClick={() => {
              showPinNumber();
            }}
          />
        </div>

        {showAction([CARD_STATUS.ACTIVE]) && (
          <div className="tab-rows" style={{ borderBottom: 0, flexDirection: "row" }}>
            <p style={{ fontWeight: "500" }}>{"Load Card"}</p>
            <ActionBtn text={"Load"} onClick={onLoadCardPress} />
          </div>
        )}

        {card?.isPhysical !== true && showAction([CARD_STATUS.ACTIVE]) && card?.convertPhysicalRequested !== true && (
          <div className="tab-rows" style={{ borderBottom: 0, flexDirection: "row" }}>
            <p style={{ fontWeight: "500" }}>{"Request Physical Card"}</p>
            <ActionBtn
              text={card?.convertPhysicalRequested === true ? "Requested" : "Order Card"}
              style={{
                backgroundColor: card?.convertPhysicalRequested === true ? "lightgrey" : "rgba(53, 185, 148, 1)"
              }}
              onClick={() => {
                if (card?.convertPhysicalRequested === true) {
                  return;
                }
                if (isValidForFeature({ user, featureName: FEATURE_NAMES.CARD })) {
                  setShowConvertPhysicalModal(true);
                } else {
                  showError("This service is temporarily unavailable, please try again later.");
                }
              }}
            />
          </div>
        )}

        {showAction([CARD_STATUS.ACTIVE]) &&
          card?.convertPhysicalRequested === true &&
          card?.delivery == "Courier" &&
          isValidForFeature({ user, featureName: FEATURE_NAMES.CARD_COURIER_OPTION }) && (
            <div className="tab-rows" style={{ borderBottom: 0, flexDirection: "row" }}>
              <p style={{ fontWeight: "500" }}>{"Track Package"}</p>
              <ActionBtn
                text={"Track"}
                style={{
                  backgroundColor: "rgba(53, 185, 148, 1)"
                }}
                onClick={() => {
                  showTrackingNumber();
                }}
              />
            </div>
          )}

        {card?.isPhysical !== true && showAction([CARD_STATUS.ACTIVE]) && card?.convertPhysicalRequested === true && (
          <div className="tab-rows" style={{ borderBottom: 0, flexDirection: "row" }}>
            <p style={{ fontWeight: "500" }}>{"Activate Physical Card"}</p>
            <ActionBtn
              text={"Activate"}
              style={{
                backgroundColor: "rgba(53, 185, 148, 1)"
              }}
              onClick={() => {
                if (card?.convertPhysicalRequested === true) {
                  if (isValidForFeature({ user, featureName: FEATURE_NAMES.CARD })) {
                    setShowActivatePhysicalModal(true);
                  } else {
                    showError("This service is temporarily unavailable, please try again later.");
                  }
                  return;
                }
              }}
            />
          </div>
        )}

        {showAction([CARD_STATUS.INACTIVE]) && (
          <div className="tab-rows" style={{ borderBottom: 0, flexDirection: "row" }}>
            <p style={{ fontWeight: "500" }}>{"Activate Card"}</p>
            <ActionBtn
              text={"Activate"}
              onClick={() => {
                Modal2.confirm({
                  title: "Activate Card",
                  icon: <ExclamationCircleOutlined />,
                  content: "Please confirm to activate the card.",
                  okText: "Confirm",
                  cancelText: "Cancel",
                  onOk: () => {
                    activateCard();
                  }
                });
              }}
            />
          </div>
        )}

        {/* {showAction([CARD_STATUS.SUSPENDED]) && (
          <div className="tab-rows" style={{ borderBottom: 0, flexDirection:"row" }}>
            <p style={{ fontWeight: "500" }}>{"Card is suspended"}</p>
            <ActionBtn text={"Re-Activate"} onClick={() => activateCard()} />
          </div>
        )} */}

        {showAction([CARD_STATUS.ACTIVE]) && (
          <div className="tab-rows" style={{ borderBottom: 0, flexDirection: "row" }}>
            <p style={{ fontWeight: "500" }}>{"Suspend Card"}</p>
            <ActionBtn
              text={"Suspend"}
              style={{ backgroundColor: "red" }}
              onClick={() => {
                Modal2.confirm({
                  title: "Confirm",
                  icon: <ExclamationCircleOutlined />,
                  content: "Are you sure you want to suspend this card?",
                  okText: "Confirm",
                  cancelText: "Cancel",
                  onOk: () => {
                    suspendCard();
                  }
                });
              }}
            />
          </div>
        )}
        {[CARD_STATUS.SUSPENDED, CARD_STATUS.LOST].includes(card?.status) && (
          <div style={{ borderBottom: 0 }}>
            <p style={{ paddingTop: 10, fontWeight: "500", fontSize: 20 }}>Your card is Suspended.</p>
            <p style={{ paddingBottom: 50, fontSize: 16 }}>
              Please contact customer support(
              <a href="mailto: support@axiacapitalbank.com">support@axiacapitalbank.com</a>) to activate your card.
            </p>
          </div>
        )}
        {card?.status == CARD_STATUS.EXPIRED && (
          <div style={{ borderBottom: 0 }}>
            <p style={{ paddingTop: 10, fontWeight: "500", fontSize: 20 }}>Your card is Expired.</p>
            <p style={{ paddingBottom: 50, fontSize: 16 }}>
              Please contact customer support(
              <a href="mailto: support@axiacapitalbank.com">support@axiacapitalbank.com</a>) to issue a new card for
              you.
            </p>
          </div>
        )}
      </div>
    );
  };

  const renderConvertSuccessModal = () => {
    return (
      <Modal2
        visible={showConvertSuccessModal}
        title="Congratulations, your physical card has been ordered!"
        width={"70vh"}
        centered
        okText={"Get KYC verified"}
        cancelText={"Cancel"}
        onOk={() => navigate("/upload-document")}
        closable={false}
        onCancel={() => {
          setShowConvertSuccessModal(false);
          refreshPage();
        }}
      >
        <div className="modal">
          <div className="modalContent">
            To gain the full benefit of this program and increase your spendable limit you will need to be KYC verified.{" "}
            <br />
            Please click 'Get KYC Verified' and select 'Debit Card Identity Verification - Limit Increase Application'
            to upload your KYC documents.
          </div>
        </div>
      </Modal2>
    );
  };

  return (
    <Spin spinning={loading} tip={loadingText}>
      <div className="row page-title">
        <CreditCardOutlined style={{ marginRight: 10 }} />
        Cards
      </div>
      <div className="row" style={{ width: "100%", paddingLeft: 30 }}>
        <BackBtn text={"Go Back"} previousPath={"/cards"} />
      </div>
      {renderCardOverview()}
      <CardLoadModal showLoadModal={showLoadModal} closeModal={closeModal} card={card} refreshPage={refreshPage} />
      <ConvertPhysicalModal
        showLoadModal={showConvertPhysicalModal}
        closeModal={(e) => {
          if (e?.success) {
            setShowConvertSuccessModal(true);
          }
          closeConvertPhysicCardModal();
        }}
        card={card}
        refreshPage={refreshPage}
      />

      <ActivatePhysicalCardModal
        showLoadModal={showActivatePhysicalModal}
        closeModal={closeActivatePhysicCardModal}
        card={card}
        refreshPage={refreshPage}
      />

      <div style={{ height: 80 }} />
      {renderConvertSuccessModal()}
    </Spin>
  );
};

const ActionBtn = ({ text, onClick, style }) => {
  return (
    <Button
      type="primary"
      size="small"
      shape="round"
      style={{
        width: 100,
        height: 30,
        fontSize: 12,
        fontWeight: "600",
        backgroundColor: "rgba(53, 185, 148, 1)",
        border: "none",
        ...style
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export const CardDetailPage = boundError(CardDetailPageRaw);
