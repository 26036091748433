import React, { useState, useEffect } from "react";
import ReactCodeInput from "react-code-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Spin } from "antd";
import { agreeUserConsent, awsMfaSignIn } from "../apis";
import { ButtonPrimary } from "../components";
import { getCustomErrorMessage, handleErrorMessage } from "../constants";
import { boundError, FEATURE_NAMES, isValidForFeature, showError } from "../utils";
import { loginSuccess } from "../redux/actions";
import { WarningFilled } from "@ant-design/icons";
import UserServices from "../services/userServices";
import { ConsentModal } from "../components/SharedComponents/consentModal";
import TokenSplitPDF from "../resources/consents/token_split/AXC_TOKEN_MIGRATION_TERMS_V2_202209.pdf";
import { USER_CONSENT } from "../constants/userConsent";

const MfaLoginRaw = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [mfaLoginError, setMfaLoginError] = useState("");
  const navigate = useNavigate();
  const userServices = UserServices.getInstance();
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [showAgreePopup, setShowAgreePopup] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState(false);

  const statusCheck = async () => {
    if (!user) {
      return navigate("/login", { replace: true });
    }
  };

  useEffect(() => {
    statusCheck();
  }, [statusCheck]);

  const agreeConsent = async () => {
    try {
      await agreeUserConsent({ consentFormName: USER_CONSENT.AXIA_NETWORK_V2_TOKEN_MIGRATION });
      setShowConsentModal(false);
      dispatch(loginSuccess(authenticatedUser));
      return navigate("/", { replace: true });
    } catch (e) {
      showError(e);
      userServices.appSignOut();
      setShowConsentModal(false);
    }
  };
  const mfaLogin = async () => {
    try {
      setLoading(true);
      setMfaLoginError("");
      if (code.length < 6) {
        return setMfaLoginError("Please enter the complete verification code.");
      }
      const authenticatedUser = await awsMfaSignIn(user, code);
      setAuthenticatedUser(authenticatedUser);
      const userProfile = await userServices.getUserProfile();
      const isUserCreatedBefore = new Date(userProfile.createdAt) < new Date("2022-07-29T00:00:00");
      const isConsentEnabled = isValidForFeature({ user: userProfile, featureName: FEATURE_NAMES.TOKEN_MIGRATION_CONSENT }) === true;
      const isUserAgreed = userProfile?.consent?.AXIA_NETWORK_V2_TOKEN_MIGRATION?.agreed;

      if (isUserCreatedBefore && isConsentEnabled && !isUserAgreed) {
        return setShowAgreePopup(true);
      }
      dispatch(loginSuccess(authenticatedUser));
      return navigate("/", { replace: true });
    } catch (err) {
      console.log(err);
      const errMessage = handleErrorMessage(err);
      setMfaLoginError(getCustomErrorMessage(errMessage));
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="confirm-login-container">
      <div className="confirm-login__logout-button" onClick={userServices.appSignOut}>
        Log out
      </div>
      <div className="confirm-login-content">
        <h2>Two factor authentication</h2>
        <p>Enter 6-digit code from your authenticator application</p>
        {mfaLoginError && (
          <div className="confirm-login__error-container">
            <WarningFilled style={{ color: "red" }} />
            {mfaLoginError}
          </div>
        )}
        <Spin spinning={loading}>
          <div className="confirm-login__form">
            <ReactCodeInput type="number" fields={6} autoFocus value={code} onChange={setCode} />
            <ButtonPrimary onClick={mfaLogin}>Verify</ButtonPrimary>
          </div>
          <ConsentModal
            visible={showConsentModal}
            footer={null}
            onCancel={() => {
              setShowConsentModal(false);
            }}
            src={TokenSplitPDF}
          />
          <Modal
            visible={showAgreePopup}
            title="AXC Token Migration Terms"
            width={"50vh"}
            centered
            okText={"Agree"}
            cancelText={"Cancel"}
            onOk={() => agreeConsent()}
            onCancel={() => {
              userServices.appSignOut();
              setShowAgreePopup(false);
            }}
          >
            <div className="modal">
              <div className="modalContent">
                I have read and agree to the{" "}
                <a
                  onClick={() => {
                    setShowConsentModal(true);
                  }}
                >
                  AXC Token Migration Terms.
                </a>
              </div>
            </div>
          </Modal>
        </Spin>
      </div>
    </div>
  );
};

export const MfaLogin = boundError(MfaLoginRaw);
