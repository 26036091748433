import React from "react";
import { Button, Space, Typography } from "antd";
import { StatusText } from "./StatusText";
const { Title, Text } = Typography;

export const OnboardingButton = ({ id, href, title, subTitle, status, icon, ...props }) => {
  return (
    <Button
      block
      id={id}
      href={href}
      style={{
        height: 100,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        minWidth: 360
      }}
      {...props}
    >
      <Title level={4}>{title}</Title>
      <Space>
        <Text type="secondary">{subTitle}</Text>
        <StatusText status={status} />
      </Space>
      {icon}
    </Button>
  );
};
