import React, { useState, useEffect } from "react";
import { boundError } from "../../utils";
import Icon, { CopyOutlined } from "@ant-design/icons";
import BulletIcon from "../../resources/icons/transfer/bullet.svg";
import { formatAmount } from "../../utils";
import { CURRENCY } from "../../constants";

const FeeRowRaw = ({ rows = [], impactOnSpendableAmount = "NONE", spendableData = {} }) => {
  const renderRow = ({ fee, type }) => {
    return (
      <div className="fee-row-margin">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon component={() => <img src={BulletIcon} style={{ marginRight: 5 }} />} />
          <p className="margin-none">{fee}</p>
        </div>
        <div
          className="margin-none"
          style={{ display: "flex", alignItems: "center", fontWeight: 500, textAlign: "right" }}
        >
          {type}
        </div>
      </div>
    );
  };

  const renderSpendableInfo = () => {
    if (impactOnSpendableAmount !== "NONE") {
      return (
        <>
          {spendableData?.spendableAmountInUsd &&
            renderRow({
              fee: `${formatAmount(spendableData?.spendableAmountInUsd, CURRENCY.USD.code)} ${CURRENCY.USD.code}`,
              type: "Total Spendable Amount In USD"
            })}
          {spendableData?.spendableAmountInAxc &&
            renderRow({
              fee: `${formatAmount(spendableData?.spendableAmountInAxc, CURRENCY.AXC.code)} ${CURRENCY.AXC.code}`,
              type: "Total Spendable Amount In AXC"
            })}
          {spendableData?.spendableAmountInUsax &&
            renderRow({
              fee: `${formatAmount(spendableData?.spendableAmountInUsax, CURRENCY.USAX.code)} ${CURRENCY.USAX.code}`,
              type: "Total Spendable Amount In USAX"
            })}
        </>
      );
    }
  };

  return (
    <div className="fee-row-container">
      {rows.map((row) => {
        return (
          row && (
            <div key={row.type}>
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <Icon component={() => <img src={BulletIcon} />} />
                <p className="margin-none" style={{ marginLeft: "0.5rem" }}>
                  {row.fee}
                </p>
              </div>
              <div className="fee-row-type">{row.type}</div> */}
              {/* <div className="fee-row-margin">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon component={() => <img src={BulletIcon} style={{ marginRight: 5 }} />} />
                  <p className="margin-none">{row.fee}</p>
                </div>
                <div
                  className="margin-none"
                  style={{ display: "flex", alignItems: "center", fontWeight: 500, textAlign: "right" }}
                >
                  {row.type}
                </div>
              </div> */}
              {renderRow(row)}
            </div>
          )
        );
      })}
      {/* render spendable amount */}
      {renderSpendableInfo()}
    </div>
  );
};

export const FeeRow = boundError(FeeRowRaw);
