import React, { useState, useEffect } from "react";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BackBtn, CancelBtn, StepMenu, ListCard, AccountCard } from "../components";
import { Modal, Divider, Checkbox, Menu, Dropdown, Button, Select, Pagination } from "antd";
import { boundError, showError, showSuccess } from "../utils";
import { getTransactionFromAccountsApi, getGiftCardCountryList, getGiftCardBrands, orderGiftCard } from "../apis";
import Big from "big.js";
import TransferAndDepositServices from "../services/transferAndDepositServices";
import { TRANSACTION_TYPE } from "../constants";
import NumberFormat from "react-number-format";
import { NextBtn } from "../components/SharedComponents/Button/NextBtn";
import UserServices from "../services/userServices";
import { formatAmount } from "../utils";
import { CURRENCY } from "../constants";

const BuyGiftCardRaw = () => {
  const navigate = useNavigate();
  const userServices = UserServices.getInstance();
  const transferAndDepositServices = TransferAndDepositServices.getInstance();
  const [currentStep, setCurrentStep] = useState(0);
  const [accountList, setAccountList] = useState([]);
  const [stakingList, setStakingList] = useState([]);
  const [selectedSource, setSelectedSource] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [totalBrandCount, setTotalBrandCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [brandList, setBrandList] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState({});
  const [currencyRate, setCurrencyRate] = useState(1);
  const [giftCardValue, setGiftCardValue] = useState("");
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [spendableAmountInAxc, setSpendableAmountInAxc] = useState();

  useEffect(() => {
    fetchFromAccount();
    fetchCountryList();
    fetchUserSpendable();
  }, []);

  useEffect(() => {
    if (currentStep === 2) {
      fetchGiftCardBrands();
    }
  }, [currentPage]);

  // get conversion rate to AXC once user selected a brand
  useEffect(() => {
    getCurrencyRate();
  }, [selectedBrand]);

  // TODO: wait for API
  const fetchFromAccount = async () => {
    const data = await getTransactionFromAccountsApi({
      transactionType: TRANSACTION_TYPE.GIFT_CARD_ORDER
    });
    setAccountList(data.accounts);
    setStakingList(data.stakings);
  };

  const fetchUserSpendable = async () => {
    const data = await userServices.getUserSpendableAmount();
    setSpendableAmountInAxc(data.spendableAmountInAxc);
  };

  // TODO: wait for API
  const fetchCountryList = async () => {
    const res = await getGiftCardCountryList();
    setCountryList(res);
  };

  const fetchGiftCardBrands = async () => {
    const res = await getGiftCardBrands(selectedCountry, currentPage);
    setBrandList(res.brands);
    setTotalBrandCount(res.totalCount);
  };

  const getSpendableAmountStr = () => {
    const amountStr = `${formatAmount(spendableAmountInAxc, CURRENCY.AXC.code)} ${CURRENCY.AXC.code}`;
    return `Total Spendable Amount In AXC: ${amountStr}`;
  };

  const renderBrand = ({ productImage, brandName, currency, valueRestrictions, denominations }) => {
    let valueRange;
    if (valueRestrictions && valueRestrictions.minVal && valueRestrictions.maxVal) {
      valueRange = `Between ${valueRestrictions.minVal} ${currency} and ${valueRestrictions.maxVal} ${currency}`;
    } else if (denominations.length >= 2) {
      let minVal = denominations[0];
      let maxVal = denominations[denominations.length - 1];
      valueRange = `Between ${minVal} ${currency} and ${maxVal} ${currency}`;
    } else {
      valueRange = `${denominations[0]} ${currency}`;
    }

    return (
      <>
        <img src={productImage} alt="" style={{ maxWidth: "100%", objectFit: "contain", marginBottom: "0.5rem" }} />
        <p className="margin-none" style={{ fontWeight: 600, fontSize: "16px", marginBottom: "0.5rem" }}>
          {brandName}
        </p>
        <p className="margin-none" style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          {`Currency: ${currency}`}
        </p>
        <p className="margin-none" style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
          {valueRange}
        </p>
      </>
    );
  };

  const getCurrencyRate = async () => {
    try {
      if (selectedBrand.currency) {
        const result = await transferAndDepositServices.getCurrencyRate(
          selectedBrand.currency,
          selectedSource.currency
        );
        if (result?.conversionRate) {
          setCurrencyRate(result?.conversionRate);
        }
      }
    } catch (err) {
      showError(err);
    }
  };

  const renderValueSelector = () => {
    const { valueRestrictions, denominations, currency } = selectedBrand;
    if (valueRestrictions && valueRestrictions.minVal && valueRestrictions.maxVal) {
      return (
        <div style={{ marginBottom: "0.5rem" }}>
          <p className="margin-none" style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
            {`Between ${valueRestrictions.minVal} ${currency} and ${valueRestrictions.maxVal} ${currency}`}
          </p>
          <NumberFormat
            thousandSeparator={true}
            value={giftCardValue}
            placeholder={"0.00"}
            decimalScale={2}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setGiftCardValue(value);
            }}
            style={{ width: "100%", fontSize: 20 }}
            className={"amount-input-section"}
          />
        </div>
      );
    } else {
      const menu = (
        <Menu
          onClick={(e) => {
            setGiftCardValue(e?.key);
          }}
        >
          {denominations.map((value, index) => {
            return <Menu.Item key={value}>{value}</Menu.Item>;
          })}
        </Menu>
      );

      return (
        <div style={{ marginBottom: "0.5rem" }}>
          <p className="margin-none" style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
            Select amount
          </p>
          <Dropdown
            overlay={menu}
            overlayStyle={{
              zIndex: 9999,
              overflow: "scroll",
              maxHeight: "30vh",
              backgroundColor: "white",
              borderRadius: "12px",
              border: "2px solid #CDE6F6"
            }}
            trigger="click"
          >
            <Button
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: "12px",
                border: "2px solid #B1B5C4",
                padding: "10px 20px",
                height: "unset",
                textAlign: "left"
              }}
            >
              <div style={{ overflow: "hidden", textOverflow: "clip", flex: 1 }}>
                {giftCardValue === "" ? "Select amount" : giftCardValue}
              </div>
              <DownOutlined style={{ marginLeft: 5 }} />
            </Button>
          </Dropdown>
        </div>
      );
    }
  };

  const getConversionStr = () => {
    const { currency } = selectedBrand;
    const value = new Big(giftCardValue && giftCardValue !== "" ? giftCardValue : 0);
    const axcValue = getValueInAxc();
    return `${value.toFixed(2)} ${currency} / ${axcValue.toFixed(5)} ${selectedSource.currency}`;
  };

  const isAmountCorrect = () => {
    const { valueRestrictions, denominations } = selectedBrand;
    if (valueRestrictions && valueRestrictions.minVal && valueRestrictions.maxVal) {
      const value = new Big(giftCardValue && giftCardValue !== "" ? giftCardValue : 0);
      return value.gte(new Big(valueRestrictions.minVal)) || value.gte(new Big(valueRestrictions.maxVal));
    }
    return giftCardValue && giftCardValue !== "" && denominations.includes(Number(giftCardValue));
  };

  const renderConversionStr = () => {
    const { valueRestrictions, currency } = selectedBrand;
    const value = new Big(giftCardValue && giftCardValue !== "" ? giftCardValue : 0);
    if (
      valueRestrictions &&
      valueRestrictions.minVal &&
      valueRestrictions.maxVal &&
      (value.lt(new Big(valueRestrictions.minVal)) || value.gt(new Big(valueRestrictions.maxVal)))
    ) {
      return (
        <p className="margin-none" style={{ fontSize: "14px", marginBottom: "0.5rem", color: "red" }}>
          {`Between ${valueRestrictions.minVal} ${currency} and ${valueRestrictions.maxVal} ${currency}`}
        </p>
      );
    }
    return (
      <p className="margin-none" style={{ fontSize: "14px", marginBottom: "0.5rem", fontWeight: 600 }}>
        {getConversionStr()}
      </p>
    );
  };

  const getValueInAxc = () => {
    const value = new Big(giftCardValue && giftCardValue !== "" ? giftCardValue : 0);
    const axcValue = value.mul(currencyRate);
    return axcValue;
  };

  const isEnoughBalance = () => {
    const totalAmount = getValueInAxc();
    if (Big(selectedSource?.amount).lt(totalAmount)) {
      return false;
    }
    return true;
  };
  const placeGiftCardOrder = async () => {
    try {
      if (!confirmChecked) {
        throw new Error("Please confirm all information is correct before continuing!");
      }
      const price = new Big(giftCardValue && giftCardValue !== "" ? giftCardValue : 0).toFixed(2);
      const data = {
        productId: selectedBrand.productId,
        price,
        currency: selectedBrand.currency?.trim(),
        productImage: selectedBrand.productImage?.trim(),
        productName: selectedBrand.brandName?.trim(),
        productDescription: selectedBrand.productDescription?.trim(),
        termsAndConditions: selectedBrand.termsAndConditions?.trim(),
        howToUse: selectedBrand.howToUse?.trim(),
        expiryAndValidity: selectedBrand.expiryAndValidity?.trim()
      };
      if (selectedSource?.isStaking) {
        data.stakingId = selectedSource?.id;
      } else {
        data.accountId = selectedSource?.id;
      }

      const result = await orderGiftCard(data);
      if (result?.statusCode === 200 || result?.status === 200) {
        showSuccess("Gift card order placed successfully!");
        navigate("/gift-cards");
      } else {
        if (result?.message) throw new Error(result?.message);
        throw new Error("Unknown error!");
      }
    } catch (err) {
      setConfirmLoading(false);
      showError(err);
    }
  };

  const renderGiftCardDisplayInfo = (title, info) => {
    if (info && info !== "") {
      return (
        <>
          <p style={{ fontSize: "16px", marginBottom: "0.5rem", fontWeight: 600 }}>{title}</p>
          <div style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: info }} />
        </>
      );
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <p
                className="margin-none"
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Select an account
              </p>
              {accountList.map((source) => {
                return (
                  <ListCard
                    key={`sourceList-${source.publicId}`}
                    children={AccountCard(source)}
                    tailComponent={
                      <RightOutlined style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }} />
                    }
                    onClick={() => {
                      setSelectedSource(source);
                      setCurrentStep((prev) => prev + 1);
                    }}
                  />
                );
              })}
              {stakingList.map((source) => {
                return (
                  <ListCard
                    key={`sourceList-${source.publicId}`}
                    children={AccountCard({ ...source, isStaking: true })}
                    tailComponent={
                      <RightOutlined style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }} />
                    }
                    onClick={() => {
                      setSelectedSource({ ...source, isStaking: true });
                      setCurrentStep((prev) => prev + 1);
                    }}
                  />
                );
              })}
            </div>
            <Divider />
            <CancelBtn toPath="/gift-cards" />
          </>
        );
      case 1:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <p
                className="margin-none"
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Select country
              </p>
              <Select
                size={"large"}
                style={{ width: "100%", textAlign: "left" }}
                showSearch
                value={selectedCountry === "" ? "Select Country" : selectedCountry}
                onChange={setSelectedCountry}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {countryList.map((country) => {
                  return (
                    <Select.Option key={country} value={country}>
                      {country}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <CancelBtn toPath="/gift-cards" />
              <NextBtn
                enable={selectedCountry !== ""}
                onClick={async () => {
                  setCurrentStep((prev) => prev + 1);
                  await fetchGiftCardBrands();
                }}
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="gift-card-step-body" style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
              <p
                className="margin-none"
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Select gift card
              </p>
              <div className="gift-card-brand-list" style={{ width: "100%" }}>
                {brandList.map((brand) => {
                  return (
                    <div
                      className="gift-card-brand-card"
                      key={brand.productId}
                      style={{ width: "100%", textAlign: "left", cursor: "pointer" }}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setSelectedBrand(brand);
                        setCurrentStep((prev) => prev + 1);
                      }}
                    >
                      {renderBrand(brand)}
                    </div>
                  );
                })}
              </div>
            </div>
            <Pagination
              value={currentPage}
              style={{ textAlign: "right" }}
              size="small"
              pageSize={50}
              defaultCurrent={currentPage}
              total={totalBrandCount}
              showSizeChanger={false}
              onChange={async (page) => {
                setCurrentPage(page);
                window.scrollTo(0, 0);
              }}
            />

            <Divider />
            <CancelBtn toPath="/gift-cards" />
          </>
        );
      case 3:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", textAlign: "left", alignItems: "flex-start" }}
            >
              <h2
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Select amount
              </h2>
              <div className="gift-card-brand-info">
                <img src={selectedBrand.productImage} alt="" />
                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignContent: "flex-start" }}>
                  <p className="margin-none" style={{ fontWeight: 600, fontSize: "16px", marginBottom: "0.5rem" }}>
                    {selectedBrand.brandName}
                  </p>
                  <p className="margin-none" style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
                    {`Currency: ${selectedBrand.currency}`}
                  </p>
                  {renderValueSelector()}
                  {renderConversionStr()}
                  <p className="margin-none" style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
                    {getSpendableAmountStr()}
                  </p>
                </div>
              </div>

              {isEnoughBalance() === false && (
                <p style={{ color: "red", fontSize: "1rem", fontWeight: 500 }}>
                  You do not have enough balance for this transaction
                </p>
              )}

              {renderGiftCardDisplayInfo("Gift card description", selectedBrand.productDescription)}
              {renderGiftCardDisplayInfo("Terms and conditions", selectedBrand.termsAndConditions)}
              {renderGiftCardDisplayInfo("Expiry and validity", selectedBrand.expiryAndValidity)}
              {renderGiftCardDisplayInfo("How to use", selectedBrand.howToUse)}

              <Checkbox
                checked={agreeChecked}
                onChange={(e) => setAgreeChecked(e.target.checked)}
                style={{ fontSize: "1rem", marginTop: "1rem" }}
              >
                Agree to Terms and Conditions
              </Checkbox>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <CancelBtn toPath="/gift-cards" />
              <NextBtn enable={agreeChecked && isAmountCorrect() === true} onClick={() => setConfirmVisible(true)} />
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "85%", margin: "40px auto" }}>
      <BackBtn
        onClick={() => {
          if (currentStep > 0) {
            setCurrentStep((prev) => prev - 1);
            switch (currentStep) {
              case 1:
                setSelectedSource({});
                setSelectedCountry("");
                break;
              case 2:
                setBrandList([]);
                break;
              case 3:
                setSelectedBrand({});
                setGiftCardValue("");
                setAgreeChecked(false);
                break;
            }
          } else {
            navigate("/gift-cards");
          }
        }}
      />
      <div className="row page-title" style={{ paddingTop: 0 }}>
        Gift Card
      </div>
      <div className="card-apply-container" style={{ width: "100%" }}>
        <StepMenu
          steps={["Select account", "Select country", "Select gift card", "Select amount"]}
          currentStep={currentStep}
        />
        <Divider />
        {renderStep()}
      </div>
      <Divider />
      <Modal
        title="Confirm gift card order"
        visible={confirmVisible}
        okText={"Confirm"}
        onOk={placeGiftCardOrder}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setConfirmChecked(false);
          setConfirmVisible(false);
        }}
        okButtonProps={{
          disabled: !confirmChecked
        }}
        width={600}
      >
        <div style={{ display: "flex", flexDirection: "row", margin: "auto", gap: "1rem", marginBottom: "1rem" }}>
          <img
            src={selectedBrand.productImage}
            alt=""
            style={{ maxWidth: "50%", objectFit: "contain", marginBottom: "0.5rem" }}
          />
          <div style={{ display: "flex", flexDirection: "column", alignContent: "flex-start" }}>
            <p className="margin-none" style={{ fontWeight: 600, fontSize: "16px", marginBottom: "0.5rem" }}>
              {selectedBrand.brandName}
            </p>
            <p className="margin-none" style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
              {`Currency: ${selectedBrand.currency}`}
            </p>
            <p className="margin-none" style={{ fontSize: "16px", fontWeight: 600 }}>
              {`Amount: `}
              {getConversionStr()}
            </p>
            <p className="margin-none" style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
              {`Fee: 2.5%`}
            </p>
            <p className="margin-none" style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
              {getSpendableAmountStr()}
            </p>
          </div>
        </div>

        <Checkbox
          checked={confirmChecked}
          onChange={(e) => {
            setConfirmChecked(e.target.checked);
          }}
        >
          I confirm that the information is correct.
        </Checkbox>
      </Modal>
    </div>
  );
};

export const BuyGiftCard = boundError(BuyGiftCardRaw);
