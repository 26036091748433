import React from "react";
import { boundError } from "../../utils";
import ErrorImg from "../../resources/images/hasError.svg";
import LOGOImg from "../../resources/images/logo.svg";
import { DoubleRightOutlined } from "@ant-design/icons";
import UserServices from "../../services/userServices";

const OnboardingDisabledScreenRaw = () => {
  const userServices = UserServices.getInstance();
  return (
    <div
      className="ant-modal-mask"
      style={{
        background: "rgb(231, 243, 254)"
      }}
    >
      <div
        style={{
          margin: "10%",
          background: "white",
          borderRadius: 20,
          padding: 30,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img src={LOGOImg} alt="" style={{ maxWidth: 300 }} />
        <p
          style={{
            fontWeight: 500,
            fontSize: 30
          }}
        >
          Users onboarding is disabled
        </p>
        <p
          style={{
            fontWeight: 500,
            fontSize: 16,
            color: "#777E91"
          }}
        >
          We apologize for any inconvenience.
        </p>
        <img src={ErrorImg} alt="" style={{ maxWidth: 250 }} />
        <button
          onClick={() => {
            userServices.appSignOut();
          }}
          style={{
            borderRadius: 5,
            backgroundColor: "#188FE1",
            borderColor: "#188FE1",
            borderWidth: 0,
            color: "white",
            height: 40,
            fontWeight: "bold"
          }}
        >
          <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>Log out</span>
        </button>
      </div>
    </div>
  );
};

export const OnboardingDisabledScreen = boundError(OnboardingDisabledScreenRaw);
