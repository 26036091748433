import React, { useState, useRef } from "react";
import { Alert, Button, Col, Divider, Form, Row, Typography, Spin, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { updateSelfPepStatus } from "../../apis";
import { boundError, showSuccess } from "../../utils";
const { Title } = Typography;
import { BackBtn } from "../SharedComponents/Button/BackBtn";
import { DropDownSelector } from "../DropDownSelector";
import { QuestionCircleOutlined } from "@ant-design/icons";

const OtherInformationFormRaw = ({ userRegistrationStatus, reloadUserRegistrationStatus }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [pepStatus, setPepStatus] = useState(userRegistrationStatus?.pepStatus);

  const isMounted = useRef(true);

  const pepTypes = [
    { name: "Nothing to declare", code: "NONE", type: "NONE" },
    { name: "Politically Exposed Person", code: "PEP", type: "PEP" }
  ];

  const onFinish = async (values) => {
    //  for fields to be added later
    //    try {
    //      await updateSelfPepStatus(values);
    //      reloadUserRegistrationStatus();
    //    } catch (err) {
    //      setError(err);
    //    } finally {
    //      if (isMounted.current) {
    //        setLoading(false);
    //      }
    //    }
  };

  const urd = userRegistrationStatus;

  const initialValues = {
    pepStatus: urd?.pepStatus
  };

  const goBack = () => {
    const backPath = location.pathname.replace("/other-information", "");
    navigate(backPath);
  };

  const onSaveAndExit = async () => {
    setError(null);
    setLoading(true);
    try {
      await updateSelfPepStatus(pepStatus);

      showSuccess("Updated PEP status");

      await reloadUserRegistrationStatus();
      goBack();
    } catch (err) {
      setError(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };
  return (
    <div style={{ textAlign: "left", padding: 15, paddingBottom: 100 }}>
      <Row justify="center">
        <Col sm={24} md={18}>
          <BackBtn onClick={goBack} />
          <Title level={3}>Verify Other Information</Title>
          {error && <Alert message={error.response?.data?.message || error.message} type="error" />}
          <Divider />
          <Form
            form={form}
            layout="vertical"
            name="other-information"
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <Form.Item
              label={
                <div>
                  <span style={{ marginRight: "1rem", marginLeft: "0.25rem" }}>Politically Exposed Person Status</span>
                  <Tooltip
                    placement="right"
                    overlayStyle={{ width: "600px" }}
                    overlayInnerStyle={{ width: "600px" }}
                    title="A PEP (Politically Exposed Person) is an individual who has a high-ranking job in a government or political position, a person who possesses a certain form of political and institutional power. Typically, PEPs include: Politicians, Leaders of government or state, Judges and members of the court, High-ranking members of the Central Bank, Ambassadors, High-ranking officers in the Defence Forces, spouses and children of the people above, close business partners and connections of the people above"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
              }
              name="pepStatus"
            >
              <DropDownSelector
                options={pepTypes}
                onChange={(e) => {
                  setPepStatus(e);
                }}
                settings={{ defaultValue: initialValues.pepStatus }}
              />
            </Form.Item>
            <Button disabled={loading} onClick={onSaveAndExit}>
              Save and exit
            </Button>
            <Spin style={{ marginLeft: "1rem" }} spinning={loading} />
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export const OtherInformationForm = boundError(OtherInformationFormRaw);
