class BeneficiaryType {
  constructor(code, description) {
    this.code = code;
    this.description = description;
  }
}

export const BENEFICIARY_TYPE = {
  INTERNAL: new BeneficiaryType("INTERNAL", "Internal beneficiary"),
  WIRE_TRANSFER: new BeneficiaryType("WIRE_TRANSFER", "Wire transfer bank info"),
  EXTERNAL_WALLET_TRANSFER_INDIVIDUAL: new BeneficiaryType(
    "EXTERNAL_WALLET_TRANSFER_INDIVIDUAL",
    "Transfer to external wallet to an individual type beneficiary"
  ),
  EXTERNAL_WALLET_TRANSFER_CORPORATE: new BeneficiaryType(
    "EXTERNAL_WALLET_TRANSFER_CORPORATE",
    "Transfer to external wallet to a corporation type beneficiary"
  )
};

export const VALID_BENEFICIARIES = new Set(Object.values(BENEFICIARY_TYPE).map((beneficiary) => beneficiary.code));

export const isValidBeneficiaryType = (type) => {
  return VALID_BENEFICIARIES.has(type);
};
