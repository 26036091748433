import React, { useState, useRef, useEffect } from "react";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Spin, Form, Input, Row, Space, Upload, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  uploadPendingDocument,
  downloadDocumentById,
  updateUserDocumentInfo,
  downloadEmptyBeneficiaryAccountForm
} from "../../apis";
import { boundError, showSuccess, showError } from "../../utils";
import { CountrySelect } from "../CountrySelect";
import { set } from "lodash";

const BeneficiaryAccountFormRaw = ({ rawData = null }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const [id, setId] = useState(undefined);
  const [status, setStatus] = useState();
  const [initialValues, setInitialValues] = useState({});

  const [disableForm, setDisableForm] = useState(false);

  const navigate = useNavigate();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (rawData !== null) {
      setId(rawData?.id ?? null);
      setStatus(rawData?.status ?? "INCOMPLETE");
      setDisableForm(rawData.status !== "INCOMPLETE");
      setInitialValues({
        memo: rawData?.memo,
        beneficiaryAccountFrom: rawData?.documentId
          ? [
              {
                id: rawData?.documentId,
                name: rawData.documentName,
                // name: `Document uploaded at ${new Date(urd.beneficiaryAccountFromFile.createdAt).toLocaleString()}`,
                status: "done"
              }
            ]
          : []
      });
    }
    setLoading(false);
  }, [rawData]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const StatusWarningMsg = () => {
    let msg;
    switch (status) {
      case "SUBMITTED":
        msg = "Your Beneficiary Account Application has been submitted for review";
        break;
      case "COMPLETED":
        msg = "Your Beneficiary Account Application has been completed";
        break;
      case "REJECTED":
        msg = "Your Beneficiary Account Application has been rejected";
        break;
      case "CANCELED":
        msg = "Your Beneficiary Account Application has been canceled";
        break;
    }
    if (msg) {
      return <Alert message={msg} type="info" />;
    }
  };

  const onFinish = async ({ memo }) => {
    if (memo) memo = memo.trim();
    setError(null);
    setLoading(true);
    try {
      await updateUserDocumentInfo({
        id,
        documentType: "BENEFICIARY_ACCOUNT",
        memo,
        submit: true
      });
      showSuccess("Successfully submitted your application for review");
      goBack();
    } catch (err) {
      showError(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const onSaveAndExit = async () => {
    try {
      let { memo } = form.getFieldsValue(["memo"]);
      if (memo) memo = memo.trim();
      await updateUserDocumentInfo({
        id,
        documentType: "BENEFICIARY_ACCOUNT",
        memo,
        submit: false
      });
      showSuccess("Successfully saved your application");
      goBack();
    } catch (err) {
      showError(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.slice(-1);
    }
    return e?.fileList?.slice(-1);
  };

  const createCustomUploadRequest =
    (uploader) =>
    async ({ file, onError, onSuccess }) => {
      try {
        const result = await uploader({ id, documentType: "BENEFICIARY_ACCOUNT", file });
        if (result && result.length > 0) {
          setId(result[0].id);
        }
        onSuccess();
      } catch (err) {
        onError(err);
      }
    };

  const createCustomFileListItemRenderer = (onClickHandler) => (originalNode) => {
    return (
      <Button type="link" onClick={onClickHandler} style={{ padding: 0 }}>
        {originalNode}
      </Button>
    );
  };

  const downloadDocument = async () => {
    if (initialValues.beneficiaryAccountFrom && initialValues.beneficiaryAccountFrom.length !== 0) {
      const { id } = initialValues.beneficiaryAccountFrom[0];
      await downloadDocumentById(id);
    }
  };

  const goBack = () => {
    navigate("/user-document");
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Spin spinning={loading}>
        <Row justify="start">
          <Col sm={24} md={18}>
            {disableForm && (
              <div style={{ marginBottom: 20 }}>
                <StatusWarningMsg />
              </div>
            )}
            <div style={{ margin: "1rem 0" }}>
              <p className="margin-none" style={{ fontSize: 14, marginBottom: 5 }}>
                Please download and complete Beneficiary Account Application Form
              </p>
              <Button icon={<DownloadOutlined />} onClick={downloadEmptyBeneficiaryAccountForm}>
                Download Beneficiary Account Application Form
              </Button>
            </div>

            <Form
              form={form}
              layout="vertical"
              name="beneficiary_account"
              onFinish={onFinish}
              initialValues={initialValues}
            >
              <Form.Item
                label="Beneficiary Account Form Submission"
                name="beneficiaryAccountFrom"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: "Beneficiary Account Application From is required!" }]}
              >
                <Upload
                  customRequest={createCustomUploadRequest(uploadPendingDocument)}
                  multiple={false}
                  showUploadList={{ showRemoveIcon: false }}
                  disabled={disableForm}
                  accept=".png,.jpg,jpeg,.pdf"
                  itemRender={createCustomFileListItemRenderer(downloadDocument)}
                >
                  <Button icon={<UploadOutlined />} disabled={disableForm}>
                    Upload Beneficiary Account Application From
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item label="Document Memo" name="memo">
                <Input type="text" disabled={disableForm} />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={loading || disableForm}>
                    Submit
                  </Button>
                  {disableForm ? (
                    <Button type="primary" onClick={() => goBack()}>
                      Exit
                    </Button>
                  ) : (
                    <Button disabled={loading} onClick={onSaveAndExit}>
                      Save and exit
                    </Button>
                  )}
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export const BeneficiaryAccountForm = boundError(BeneficiaryAccountFormRaw);
