import React from "react";
import { Layout, Image } from "antd";
import Logo from "../../resources/AXIA_menubar_logo.png";
import { boundError } from "../../utils";
import { SideMenu } from "./SideMenu";

const { Sider } = Layout;

const LayoutSiderRaw = () => {
  return (
    <Sider className="menu-sider menu-sider-display">
      <div className="menubar-logo">{/* <Image src={Logo} preview={false} /> */}</div>
      <SideMenu />
    </Sider>
  );
};

export const LayoutSider = boundError(LayoutSiderRaw);
