import React, { useState, useEffect, useRef } from "react";
import { boundError } from "../../utils";
import moment from "moment";
import { PopUpNotification } from "./PopUpNotification";
import { getTotalAssetApi } from "../../apis";
import Big from "big.js";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const OnLoginNotificationRaw = () => {
  const [content, setContent] = useState([]);
  const navigate = useNavigate();
  const PopUpNotificationRef = useRef({ handleClose: null });

  const generateNotificationContent = async () => {
    if (await showLowBalanceNotification()) setContent([...content, lowBalanceNotificationContent()]);
  };

  const lowBalanceNotificationContent = () => (
    <>
      {
        "Your account is currently below the feeless minimum balance threshold of USD $100 or equivalent in AXC. (Please note your account will be closed if your balance is insufficient to pay the monthly fee)"
      }
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
        <Button
          onClick={() => {
            PopUpNotificationRef.current.handleClose();
            navigate("/deposit");
          }}
          type="primary"
        >
          Add Fund
        </Button>
      </div>
    </>
  );

  const showLowBalanceNotification = async () => {
    const result = await getTotalAssetApi();
    if (result?.threshold === undefined || result?.threshold === null) return false; //if threshold is not set -> don't show
    if (!moment().isBetween(moment().endOf("month").subtract(7, "days"), moment().endOf("month"))) return false; //if last week of the month -> don't show
    const amount = new Big(result?.totalAmount);
    const threshold = new Big(result?.threshold);
    return amount.lt(threshold); //if balance is less than threshold
  };

  const toDisplay = () => {
    let userFirstLogin = JSON.parse(localStorage.getItem("userFirstLogin"));
    if (content.length > 0 && !userFirstLogin.loginNotificationShown) {
      //show notification modal only if there is 1 or more content and is on user first login
      userFirstLogin.loginNotificationShown = true;
      localStorage.setItem("userFirstLogin", JSON.stringify(userFirstLogin)); //update user first login status
      return true;
    }
    return false;
  };

  useEffect(() => {
    generateNotificationContent();
  }, []);

  return (
    <PopUpNotification
      content={content}
      title="Notification"
      notificationId="OnLoginNotification"
      toDisplay={toDisplay}
      showCheckBox={true}
      showPagination={true}
      PopUpNotificationRef={PopUpNotificationRef}
    />
  );
};

export const OnLoginNotification = boundError(OnLoginNotificationRaw);
