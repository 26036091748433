import React from "react";
import { AccountsHeadCard } from "./AccountsHeadCard";
import { SyncOutlined } from "@ant-design/icons";
import { CURRENCY } from "../../../constants/currency";
import { boundError, formatAmount } from "../../../utils";

const AccountsHeadExchangeRateRaw = ({ axcToUsdRate, lastUpdate, enableSpin, onClick }) => {
  return (
    <AccountsHeadCard>
      <div
        className="row"
        style={{
          fontWeight: 500,
          fontSize: 18,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        AXC/USD
        <SyncOutlined spin={enableSpin} style={{ fontSize: 20, marginRight: "1rem" }} onClick={onClick} />
      </div>
      <div
        style={{ fontWeight: 500, fontSize: 32, alignItems: "flex-end", wordBreak: "break-word", textAlign: "left" }}
      >
        <p className="padding-none margin-none">{formatAmount(axcToUsdRate, CURRENCY.USD.code, "$")}</p>
        <p className="padding-none margin-none" style={{ fontSize: 13, fontWeight: 500, marginBottom: 5, width: 60 }}>
          USD
        </p>
      </div>
      <div className="row" style={{ fontWeight: 500 }}>
        Last updated: {lastUpdate} minutes ago
      </div>
    </AccountsHeadCard>
  );
};

export const AccountsHeadExchangeRate = boundError(AccountsHeadExchangeRateRaw);
