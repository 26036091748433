import React, { useState, useContext, useEffect } from "react";
import { Layout, Dropdown, Menu, Drawer, Button, Image } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { boundError, FEATURE_NAMES, isValidForFeature } from "../../utils";
import UserServices from "../../services/userServices";
import { SideMenu } from "./SideMenu";
import Logo from "../../resources/AXIA_menubar_logo.png";
import { isLocal } from "../../utils";
import { UserRegistrationStatusContext } from "../../hooks";
import { showError } from "../../utils/messages";
import { LocalStorage, StorageKeys } from "../../utils/localStorage";
import { UserContext } from "../../hooks";
import MegaphoneFilledIcon from "../../resources/icons/banner/megaphone_filled.svg";
import UserFilledIcon from "../../resources/icons/banner/user_filled.svg";
import ArrowDownIcon from "../../resources/icons/banner/arrow_down.svg";
import Emitter, { EmitterEvents } from "../../utils/eventEmitter";
import { USER_STATUS } from "../../constants/userStatus";
import { StatusPagePopover } from "../SharedComponents/StatusPagePopover";

const { Header: AntHeader } = Layout;

const HeaderRaw = () => {
  const navigate = useNavigate();
  const userServices = UserServices.getInstance();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useContext(UserRegistrationStatusContext);
  const [user] = useContext(UserContext);
  const [isRegistered, setIsRegistered] = useState(false);
  const [notificationIconVisible, setNotificationIconVisible] = useState(false);

  const menuItemStyle = { fontSize: 18, margin: 10 };

  useEffect(() => {
    if (registrationStatus?.registrationCompleted) {
      userServices.getUserProfile();
      setIsRegistered(true);
    }
    Emitter.on(EmitterEvents.REFRESH_BANNER, (userId) => {
      getNotificationDetails(userId);
    });
  }, []);

  useEffect(() => {
    getNotificationDetails();
  }, [user]);

  const getNotificationDetails = (userId) => {
    const key = getFirstComeKey(userId);
    const result = LocalStorage.getItem(key, true);
    if (result && result?.value === "false") {
      // user previously closed the notification, make visible to enable it
      setNotificationIconVisible(true);
    }
  };

  const handleClick = (e) => {
    if (!isRegistered) {
      showError("Please complete user registration.");
    }
    if (e?.key === "0" && isRegistered) {
      navigate("/profile");
    } else if (e?.key === "1" && isRegistered) {
      navigate("/messages");
    } else if (e?.key === "2") {
      userServices.appSignOut();
    }
  };

  const getFirstComeKey = (userId) => {
    return userId ? `${StorageKeys.FIRST_COME}/${userId}` : `${StorageKeys.FIRST_COME}/${user?.id}`;
  };

  const toggleNotificationVisibility = () => {
    const key = getFirstComeKey();
    const result = LocalStorage.getItem(key, true);
    if (result && result?.value === "false") {
      LocalStorage.setItem(key, "true");
      setNotificationIconVisible(false);
      Emitter.emit(EmitterEvents.REFRESH_BANNER);
    }
  };

  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="0" style={menuItemStyle}>
        Your Profile
      </Menu.Item>
      {isLocal() && (
        <Menu.Item key="1" style={menuItemStyle}>
          Messages
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key="2" style={menuItemStyle}>
        Log out
      </Menu.Item>
    </Menu>
  );

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const getUserName = () => {
    if (user?.firstName?.length > 10) {
      return user?.firstName?.substr(0, 10) + "...";
    }
    return user?.firstName ?? "";
  };

  return (
    <AntHeader className="sticky-top" style={{ zIndex: 999 }}>
      <div className="header-main header-row">
        <div className="header-list-name">
          <Button
            type="primary"
            shape="circle"
            icon={<MenuOutlined />}
            style={{ marginRight: 10 }}
            onClick={showDrawer}
            className="menu-drawer-btn"
          />
          <p className="header-title">{`Hello ${getUserName()}!`}</p>
        </div>
        <div
          className="header-status-icons"
          style={
            !isValidForFeature({ user, featureName: FEATURE_NAMES.STATUS_PAGE })
              ? { marginTop: "15px", marginRight: "10px" }
              : {}
          }
        >
          {isValidForFeature({ user, featureName: FEATURE_NAMES.STATUS_PAGE }) && <StatusPagePopover />}
          <div style={{ display: "flex" }}>
            {notificationIconVisible && (
              <Button
                className="icon-megaphone"
                style={{ backgroundColor: "transparent", border: "none", padding: 0, marginLeft: "10px" }}
              >
                <Icon onClick={toggleNotificationVisibility} component={() => <img src={MegaphoneFilledIcon} />} />
              </Button>
            )}
            {user?.status == USER_STATUS.ACTIVE && (
              <Button style={{ backgroundColor: "transparent", border: "none", padding: 0, marginLeft: "10px" }}>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <span className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    <Icon component={() => <img src={UserFilledIcon} />} />
                    <Icon component={() => <img src={ArrowDownIcon} />} />
                  </span>
                </Dropdown>
              </Button>
            )}
          </div>
        </div>
        <Drawer
          placement="left"
          closable={false}
          onClose={onClose}
          visible={drawerVisible}
          drawerStyle={{ backgroundColor: "#004373" }}
          bodyStyle={{ padding: 0, backgroundColor: "#007CBD" }}
        >
          <div className="menubar-logo" style={{ paddingLeft: 40, paddingRight: 40 }}>
            {/* <Image src={Logo} preview={false} /> */}
          </div>
          <SideMenu onClick={onClose} />
          <div className="row" style={{ justifyContent: "center" }}>
            <Button
              type="primary"
              shape="circle"
              className="mobile-sidebar-close-btn"
              icon={<CloseOutlined style={{ fontSize: 30 }} />}
              onClick={onClose}
            />
          </div>
        </Drawer>
      </div>
    </AntHeader>
  );
};

export const Header = boundError(HeaderRaw);
