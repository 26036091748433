import React, { useState } from "react";
import { Switch } from "antd";
import { AccountsHeadCard } from "./AccountsHeadCard";
import { CURRENCY } from "../../../constants/currency";
import { boundError, formatAmount } from "../../../utils";

const AccountsHeadInterestRateEarnedRaw = ({ totalInterestEarned, axcToUsdRate, switchOn }) => {
  return (
    <AccountsHeadCard>
      <div className="row" style={{ fontWeight: 500, fontSize: 18 }}>
        Rewards Earned
      </div>
      <div
        style={{ fontWeight: 500, fontSize: 32, alignItems: "flex-end", wordBreak: "break-word", textAlign: "left" }}
      >
        {switchOn
          ? formatAmount(totalInterestEarned * axcToUsdRate, CURRENCY.USD.code, "$")
          : formatAmount(totalInterestEarned, CURRENCY.AXC.code)}
      </div>
      <div className="row" style={{ fontWeight: 500 }}>
        {switchOn ? CURRENCY.USD.code : CURRENCY.AXC.code}
      </div>
    </AccountsHeadCard>
  );
};

export const AccountsHeadInterestRateEarned = boundError(AccountsHeadInterestRateEarnedRaw);
