import React, { useState, useEffect } from "react";
import { boundError, showError, showMessage } from "../../utils";
import Modal from "react-modal";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { doActivatePhysicalCardApi } from "../../apis/card";
import { Input, Button, Modal as Modal2 } from "antd";

const ActivatePhysicalCardModalRaw = ({ showLoadModal, closeModal, card, refreshPage }) => {
  const [loading, setLoading] = useState(false);
  const [cvv, setCvv] = useState("");

  const [cvvError, setCvvError] = useState("");

  const resetState = () => {
    setCvvError("");
    setLoading(false);
  };

  const onModalClose = () => {
    resetState();
  };

  const _onSubmit = async () => {
    let hasError = false;

    if (!cvv) {
      setCvvError("CVV is required!");
      hasError = true;
    }

    if (hasError) {
      return;
    }

    Modal2.confirm({
      title: "Attention!",
      icon: <ExclamationCircleOutlined />,
      content: "Please make sure your physical card has arrived before activation.",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: processActivation,
      zIndex: 10000
    });
  };

  const processActivation = async () => {
    try {
      setLoading(true);

      await doActivatePhysicalCardApi({ cardId: card?.id, cvv });
      showMessage("Your physical card is activated successfully!");
      resetState();
      refreshPage();
      closeModal();
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={showLoadModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      onAfterClose={onModalClose}
      className={"popup-modal-container"}
      style={{
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0, 0, 0, 0.3)"
        },
        content: {
          borderRadius: 10
        }
      }}
    >
      <Spin
        spinning={loading}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem 0rem"
        }}
      >
        <div
          style={{
            display: "flex",
            position: "relative",
            flexDirection: "column"
            // padding: "1rem 0rem"
          }}
        >
          <Button
            style={{ border: "none", position: "absolute", right: -24, top: -16, backgroundColor: "transparent" }}
            onClick={() => closeModal()}
          >
            <CloseOutlined />
          </Button>
          <p style={{ fontWeight: "500", fontSize: 20 }}>Activate Your Card</p>

          <p className="margin-none" style={{ fontWeight: "600" }}>
            Card
          </p>
          <p className="margin-none">{card?.cardNumber}</p>

          <p className="margin-none" style={{ marginTop: 10, fontWeight: "600" }}>
            Please enter CVV Number on your physical card
          </p>
          <Input
            placeholder="Physical Card's CVV Number"
            value={cvv}
            onChange={(e) => {
              setCvv(e.target.value);
              resetState();
            }}
            style={styles.inputField}
          />
          {cvvError && <p style={{ color: "red" }}>{cvvError}</p>}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0rem 0.5rem",
            marginTop: 50
          }}
        >
          <Button type="primary" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => _onSubmit()}>
            Activate
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

const styles = {
  inputField: {
    margin: "0.5rem 0rem 0.5rem 0rem",
    borderRadius: "5px"
  },
  popupDiv: {
    paddingRight: "20px",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column"
  }
};

export const ActivatePhysicalCardModal = boundError(ActivatePhysicalCardModalRaw);
