import React, { useEffect, useState, useRef } from "react";
import { PlusCircleOutlined, UploadOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Row, Space, Upload, Typography, Select, DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import {
  updateBusinessRecords,
  downloadProofOfIncorporateCertificate,
  uploadProofOfIncorporateCertificate,
  getAvailableBusinessEntities
} from "../../apis";
import { boundError } from "../../utils";
import { CountrySelect } from "../CountrySelect";
const { Title } = Typography;
import { BackBtn } from "../SharedComponents/Button/BackBtn";
import moment from "moment";
import { REGISTRATION_DATA_STATUS } from "../../constants/registrationDataStatus";
import { showMessage, showError } from "../../utils";

const BusinessRecordFormRaw = ({ userRegistrationStatus, reloadUserRegistrationStatus }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const businessRecordsStatus = userRegistrationStatus?.userRegistrationData?.businessRecordsStatus;
  const navigate = useNavigate();
  const [businessDirectors, setBusinessDirectors] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [entities, setEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [directorsError, setDirectorsError] = useState("");

  const isMounted = useRef(true);

  useEffect(() => {
    if (userRegistrationStatus?.userRegistrationData?.businessCountryCode) {
      setCountryCode(userRegistrationStatus?.userRegistrationData?.businessCountryCode);
    }
    if (userRegistrationStatus?.userRegistrationData?.businessEntity) {
      setSelectedEntity(userRegistrationStatus?.userRegistrationData?.businessEntity);
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(async () => {
    if (countryCode) {
      const result = await getAvailableBusinessEntities(countryCode);
      setEntities(result);
      let v = null;
      if (result?.length === 1 && result[0]?.elf_code === "NANA") {
        v = `${result[0]?.elf_code}/${result[0]?.language_code}/${result[0]?.country_code}`;
      }
      setSelectedEntity(v);
      form.setFields([
        {
          name: "businessEntity",
          value: v
        }
      ]);
    } else {
      setEntities([]);
    }
  }, [countryCode]);

  useEffect(() => {
    setBusinessDirectors(userRegistrationStatus?.userRegistrationData?.businessDirectors ?? []);
  }, [userRegistrationStatus?.userRegistrationData?.businessDirectors]);

  const onFinish = async (values) => {
    if (!businessDirectors || businessDirectors.length === 0) {
      setDirectorsError("Please add at least one director!");
      return;
    }

    setLoading(true);
    values = { ...values, businessDirectors: businessDirectors };
    values.submit = true;

    if (values?.businessDateOfIncorporation) {
      const today = new Date();
      if (new Date(values?.businessDateOfIncorporation).getDate() > today.getDate()) {
        const e = "Date of Incorporation must be before current date.";
        showError(e);
        form.setFields([
          {
            name: "businessDateOfIncorporation",
            errors: [e]
          }
        ]);
        setLoading(false);
        return;
      }

      values.businessDateOfIncorporation = moment(values?.businessDateOfIncorporation).utc().format("YYYY-MM-DD");
    }

    try {
      if (selectedEntity) {
        values.businessEntity = selectedEntity;
      }

      await updateBusinessRecords(values);
      reloadUserRegistrationStatus();

      showMessage("Submitted successfully!");
    } catch (err) {
      showRespError(err);
    } finally {
      setLoading(false);
    }
  };

  const onSaveAndExit = async () => {
    setLoading(true);
    let allValidated = true;
    try {
      let formData = form.getFieldsValue([
        "businessName",
        "businessWebsite",
        "businessRegistrationNumber",
        "businessDateOfIncorporation",
        "businessCountryCode",
        "certificateOfIncorporation",
        "businessMonthlyTransferVolume",
        "businessMonthlyPaymentNumber",
        "businessMaximumSinglePayment",
        "businessDirectors"
      ]);
      formData = { ...formData, businessDirectors };

      console.log(formData)
      if (formData?.businessDateOfIncorporation) {
        const today = new Date();
        if (new Date(formData?.businessDateOfIncorporation).getDate() > today.getDate()) {
          const e = "Date of Incorporation must be before current date.";
          showError(e);
          form.setFields([
            {
              name: "businessDateOfIncorporation",
              errors: [e]
            }
          ]);
          setLoading(false);
          return;
        }

        formData.businessDateOfIncorporation = moment(formData?.businessDateOfIncorporation).utc().format("YYYY-MM-DD");
      } else {
        formData.businessDateOfIncorporation = null;
      }

      if (formData?.businessDirectors && formData.businessDirectors.length > 0) {
        formData.businessDirectors.map(director => {
          if (!director.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            showError("Please enter a valid email for business director.");
            return allValidated = false;
          }
        })
      }

      if (selectedEntity) {
        formData.businessEntity = selectedEntity;
      }

      if (!allValidated) return;

      await updateBusinessRecords(formData);
      showMessage("Information saved!");
      goBack();
    } catch (err) {
      showRespError(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const showRespError = (err) => {
    if (err === "businessWebsite must be a valid url") {
      const e = "Please enter a valid website url!";
      showError(e);
      form.setFields([
        {
          name: "businessWebsite",
          errors: [e]
        }
      ]);
    } else {
      showError(err);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.slice(-1);
    }
    return e?.fileList?.slice(-1);
  };

  const createCustomUploadRequest =
    (uploader) =>
    async ({ file, onError, onSuccess }) => {
      try {
        await uploader(file);
        onSuccess();
      } catch (err) {
        onError(err);
      }
    };

  const handleChangeInput = (index, event) => {
    const newInputFields = businessDirectors.map((owner, i) => {
      if (index === i) {
        owner[event.target.name] = event.target.value;
      }
      return owner;
    });
    setBusinessDirectors(newInputFields);
  };

  const createCustomFileListItemRenderer = (onClickHandler) => (originalNode) => {
    return (
      <Button type="link" onClick={onClickHandler} style={{ padding: 0 }}>
        {originalNode}
      </Button>
    );
  };

  const datePickerDefaultValue = () => {
    let date = moment(new Date()).format("YYYY-MM-DD");
    if (urd?.businessDateOfIncorporation) {
      date = moment(new Date(urd?.businessDateOfIncorporation)).utc().format("YYYY-MM-DD");
    }

    return moment(date, "YYYY-MM-DD");
  };

  const urd = userRegistrationStatus?.userRegistrationData;
  const initialValues = {
    businessName: urd?.businessName,
    businessWebsite: urd?.businessWebsite,
    businessRegistrationNumber: urd?.businessRegistrationNumber,
    businessCountryCode: urd?.businessCountryCode,
    businessEntity: urd?.businessEntity,
    businessMonthlyTransferVolume: urd?.businessMonthlyTransferVolume,
    businessMonthlyPaymentNumber: urd?.businessMonthlyPaymentNumber,
    businessMaximumSinglePayment: urd?.businessMaximumSinglePayment,
    businessDirectors: urd?.businessDirectors,
    businessDateOfIncorporation: datePickerDefaultValue(),

    businessProofOfIncorporateCertificateFile: urd?.businessProofOfIncorporateCertificateFile
      ? [
          {
            uid: urd.businessProofOfIncorporateCertificateFile.Key,
            name: `Document uploaded at ${new Date(
              urd.businessProofOfIncorporateCertificateFile.createdAt
            ).toLocaleString()}`,
            status: "done"
          }
        ]
      : []
  };

  const shouldDisableForm =
    businessRecordsStatus === REGISTRATION_DATA_STATUS.SUBMITTED ||
    businessRecordsStatus === REGISTRATION_DATA_STATUS.COMPLETED;

  const goBack = () => {
    navigate("/onboarding/business");
  };

  const renderBusinessRecord = () => {
    return (
      <>
        <Title level={3}>Your business records</Title>
        <Divider />
        <Form.Item
          label="Legal Business Name"
          name="businessName"
          rules={[{ required: true, message: "Legal Business Name is required!" }]}
        >
          <Input type="text" disabled={shouldDisableForm} placeholder="Legal Business Name" />
        </Form.Item>
        <Form.Item label="Website" name="businessWebsite" rules={[{ message: "Can not be empty" }]}>
          <Input type="text" disabled={shouldDisableForm} placeholder="Website" />
        </Form.Item>

        <Form.Item
          label="Registration Number"
          name="businessRegistrationNumber"
          rules={[{ required: true, message: "Registration Number is required!" }]}
        >
          <Input type="text" disabled={shouldDisableForm} placeholder="Registration number" />
        </Form.Item>
        <Form.Item
          label="Date Of Incorporation"
          name="businessDateOfIncorporation"
          rules={[{ required: true, message: "Date Of Incorporation is required!" }]}
        >
          <DatePicker format={"YYYY-MM-DD"} disabled={shouldDisableForm} />
        </Form.Item>
        <Form.Item
          label="Country Of Incorporation"
          name="businessCountryCode"
          rules={[{ required: true, message: "Country Of Incorporation is required!" }]}
        >
          <CountrySelect
            disabled={shouldDisableForm}
            onChange={(value) => {
              setCountryCode(value);
              form.setFieldsValue({ businessEntity: "" });
            }}
          />
        </Form.Item>
        <Form.Item label="Entity" name="businessEntity" rules={[{ required: true, message: "Entity is required!" }]}>
          <Select
            disabled={shouldDisableForm}
            value={selectedEntity}
            onChange={(value) => {
              setSelectedEntity(value);
            }}
            style={{ width: "100%" }}
            placeholder={"Please select a business entity"}
          >
            {entities.map((entity, index) => {
              return (
                <Select.Option
                  key={index}
                  value={`${entity?.elf_code}/${entity?.language_code}/${entity?.country_code}`}
                >
                  {entity?.local_name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </>
    );
  };

  const renderCertificateOfIncorporation = () => {
    return (
      <>
        <Title level={3}>Certificate of Incorporation</Title>
        <Title level={5} type="secondary">
          Complete the following steps to finish your application
        </Title>
        <Divider />
        <Form.Item
          name="businessProofOfIncorporateCertificateFile"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: false }]}
        >
          <Upload
            customRequest={createCustomUploadRequest(uploadProofOfIncorporateCertificate)}
            multiple={false}
            showUploadList={{ showRemoveIcon: false }}
            disabled={shouldDisableForm}
            accept=".png,.jpg,jpeg,.pdf"
            itemRender={createCustomFileListItemRenderer(downloadProofOfIncorporateCertificate)}
          >
            <Button icon={<UploadOutlined />} disabled={shouldDisableForm}>
              Upload
            </Button>
          </Upload>
        </Form.Item>
      </>
    );
  };

  const renderTransactionInformation = () => {
    return (
      <>
        <Title level={3}>Confirm your transactional information</Title>
        <Title level={5} type="secondary">
          This is just for indicative purpose and does not need to be precise
        </Title>
        <Divider />
        <Form.Item
          label="Monthly volume of transfers"
          name="businessMonthlyTransferVolume"
          rules={[{ required: true, message: "Monthly volume of transfers is required!" }]}
        >
          <Select disabled={shouldDisableForm}>
            {["1-10", "10-100", "100-500", "500-1000", "1000+"].map((volume) => {
              return (
                <Select.Option key={volume} value={volume}>
                  {volume} transactions
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Monthly number of payments"
          name="businessMonthlyPaymentNumber"
          rules={[{ required: true, message: "Monthly number of payments is required!" }]}
        >
          <Select disabled={shouldDisableForm}>
            {["1-10", "10-100", "100-500", "500-1000", "1000+"].map((volume) => {
              return (
                <Select.Option key={volume} value={volume}>
                  {volume} payments
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Maximum single payment"
          name="businessMaximumSinglePayment"
          rules={[{ required: true, message: "Maximum single payment is required!" }]}
        >
          <Input type="text" disabled={shouldDisableForm} placeholder="Maximum single payment (USD)" />
        </Form.Item>
      </>
    );
  };

  const renderBusinessOwners = () => {
    return (
      <>
        <Title level={3}>Confirm directors</Title>
        <Title level={5} type="secondary">
          Please ensure you have included all of your business' directors
        </Title>
        <Divider />
        {directorsError && <p style={{ color: "red" }}>{directorsError}</p>}
        {businessDirectors.map((owner, i) => {
          return (
            <div key={i}>
              <Title level={5}>
                Director {i + 1}
                <MinusCircleOutlined
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    businessDirectors.splice(i, 1);
                    setBusinessDirectors([...businessDirectors]);
                  }}
                />
              </Title>
              <Form.Item label="First name" rules={[{ required: true, message: "First name is required!" }]}>
                <Input
                  type="text"
                  name="firstName"
                  value={owner.firstName}
                  disabled={shouldDisableForm}
                  placeholder="First name"
                  onChange={(event) => handleChangeInput(i, event)}
                />
              </Form.Item>
              <Form.Item label="Last name" rules={[{ required: true, message: "Last name is required!" }]}>
                <Input
                  type="text"
                  name="lastName"
                  value={owner.lastName}
                  disabled={shouldDisableForm}
                  placeholder="Last name"
                  onChange={(event) => handleChangeInput(i, event)}
                />
              </Form.Item>
              <Form.Item label="Email" rules={[{ required: true, message: "Email is required!" }]}>
                <Input
                  type="text"
                  name="email"
                  value={owner.email}
                  disabled={shouldDisableForm}
                  placeholder="Email"
                  onChange={(event) => handleChangeInput(i, event)}
                />
              </Form.Item>
            </div>
          );
        })}
        {!shouldDisableForm && (
          <Form.Item name="businessDirectors">
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                setDirectorsError("");
                setBusinessDirectors([...businessDirectors, { firstName: "", lastName: "", email: "" }]);
              }}
            >
              <PlusCircleOutlined style={{ fontSize: "30px", color: "#08c", marginRight: "10px" }} />
              <span>Add Director</span>
            </div>
          </Form.Item>
        )}
      </>
    );
  };

  const renderSubmitAndSaveBtn = () => {
    return (
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" disabled={loading || shouldDisableForm}>
            Submit
          </Button>
          {shouldDisableForm ? (
            <Button type="primary" onClick={() => goBack()}>
              Exit
            </Button>
          ) : (
            <Button disabled={loading} onClick={onSaveAndExit}>
              Save and exit
            </Button>
          )}
        </Space>
      </Form.Item>
    );
  };

  return (
    <div style={{ textAlign: "left", padding: 15, marginBottom: 50 }}>
      <Row justify="center">
        <Col sm={24} md={18}>
          <BackBtn onClick={goBack} />
          <Form form={form} layout="vertical" name="business-records" onFinish={onFinish} initialValues={initialValues}>
            {renderBusinessRecord()}
            <br />
            {renderCertificateOfIncorporation()}
            <br />
            {renderTransactionInformation()}
            <br />
            {renderBusinessOwners()}
            <br />
            {renderSubmitAndSaveBtn()}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export const BusinessRecordForm = boundError(BusinessRecordFormRaw);
