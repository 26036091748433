import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Input, Divider, DatePicker, Dropdown, Menu } from "antd";
import { FileOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import { boundError, isLocal, formatAmount, FEATURE_NAMES, isValidForFeature } from "../../utils";
import Modal from "react-modal";
import { KeyValue4, KeyValue5 } from "../SharedComponents";
import { getUserTransactionsApi } from "../../apis";
import moment from "moment";
import { AccountsStatement } from "./AccountsStatement";
import { useReactToPrint } from "react-to-print";
import { Pagination } from "antd";
import { CURRENCY, TRANSACTION_DIRECTION, TRANSACTION_TYPE } from "../../constants";
import { useQuery, UserContext } from "../../hooks";

const { RangePicker } = DatePicker;

const AccountsTransactionRaw = ({ expanded = true, account = null }) => {
  const [user] = useContext(UserContext);
  const [modalOpened, setModalOpened] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [modalData, setModalData] = useState({});
  const [typeIn, setTypeIn] = useState([]);
  const [typeDisplay, setTypeDisplay] = useState("All Transactions");
  const [createdAtGte, setCreatedAtGte] = useState();
  const [createdAtLte, setCreatedAtLte] = useState();
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [loading, setLoading] = useState(true);
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });
  const pageStyle = `@page { margin: 5rem  2rem  5rem  2rem !important; }`;

  const query = useQuery();
  const transactionId = query.get("transactionId");
  const initModalHasShown = useRef(false);
  useEffect(() => {
    autoDisplayTransaction(transactionId);
  }, [transactions]);

  const autoDisplayTransaction = async (transactionId) => {
    if (transactionId && transactions && transactions.length > 0 && !initModalHasShown.current) {
      const item = transactions.find((trx) => trx?.id === transactionId);

      if (!item) {
        return;
      }

      setModalData(item);
      setModalOpened(true);
      initModalHasShown.current = true;
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [expanded, account, typeIn, createdAtGte, createdAtLte]);

  const fetchTransactions = async ({
    transactionDirection,
    limit = expanded ? 100 : 10,
    offset = 0,
    includeTotalCount = true,
    generateTitleAndDescription = true
  } = {}) => {
    const query = { transactionDirection, limit, offset, includeTotalCount, generateTitleAndDescription };
    if (account) query.accountId = account.id;
    if (createdAtGte && createdAtGte !== "") query.createdAtGte = createdAtGte;
    if (createdAtLte && createdAtLte !== "") query.createdAtLte = createdAtLte;
    if (typeIn && typeIn.length > 0) query.typeIn = typeIn;

    const data = await getUserTransactionsApi(query);
    setTransactions(data.data);
    setTotalTransactions(data.totalCount);
    setLoading(false);
  };

  const formatServiceProvider = (raw) => {
    switch (raw) {
      case "ALPHAPO":
        return "Digital Currency Deposit";
      case "LINEARPAY":
        return "Credit/Debit Deposit";
      case "ERC20_AXC_TRANSACTION_GATEWAY":
        return "AXIA Wallet on Ethereum (ERC-20 AXC) - Deposit";
      case "BUCKZY":
        return `Wire Transfer`;
      default:
        return "Unknown";
    }
  };

  const getBeneficiaryName = (transaction) => {
    const beneficiaryName = transaction.providerMetaData?.beneficiaryName;
    const legalName = transaction.providerMetaData?.extraData?.beneficiaryName;
    if (transaction.type === "WIRE_DEPOSIT") {
      return "Wire Deposit";
    } else if (
      transaction.type === "USER_STAKING_CREATED" ||
      transaction.type === "USER_STAKING_ADD_AMOUNT" ||
      transaction.type === "USER_STAKING_LOAD_AMOUNT" ||
      transaction.type === "USER_STAKING_CLAIM"
    ) {
      return "Stakings";
    }
    if (transaction.type === "WIRE_TRANSFER") {
      return "Wire Transfer Out";
    } else {
      return beneficiaryName && beneficiaryName !== ""
        ? `${beneficiaryName}${legalName && legalName !== beneficiaryName && legalName !== "" ? ` (${legalName})` : ""}`
        : "AXIA Capital Bank";
    }
  };

  const getOriginAmount = () => {
    switch (modalData?.type) {
      case "CARD_ISSUE":
      case "CARD_CONVERT_TO_PHYSICAL":
        if (modalData?.metaData?.amountInfo?.axiaFee) {
          return (
            <KeyValue4
              keyName="Origin amount"
              value={`${formatAmount(
                modalData?.metaData?.amountInfo?.axiaFee,
                modalData?.metaData?.amountInfo?.cardCurrency
              )} ${modalData?.metaData?.amountInfo?.cardCurrency}`}
            />
          );
        }
        break;

      case "CARD_LOAD":
        if (modalData?.metaData?.amountInfo?.amountToLoad) {
          return (
            <KeyValue4
              keyName="Origin amount"
              value={`${formatAmount(
                modalData?.metaData?.amountInfo?.amountToLoad,
                modalData?.metaData?.amountInfo?.cardCurrency
              )} ${modalData?.metaData?.amountInfo?.cardCurrency}`}
            />
          );
        }
        break;

      default:
        break;
    }
  };

  function handleMenuClick(e) {
    switch (e.key) {
      case "ALL":
        setTypeIn();
        setTypeDisplay("All Transactions");
        break;
      case "WIRE_TRANSFER":
        setTypeIn(["WIRE_TRANSFER", "WIRE_TRANSFER_REFUND"]);
        setTypeDisplay("Transfer via Wire Transfer");
        break;
      case "STAKING":
        setTypeIn([
          "USER_STAKING_CREATED",
          "USER_STAKING_LOAD_AMOUNT",
          "USER_STAKING_UNLOAD_AMOUNT",
          "USER_STAKING_LOAD_TO_CARD",
          "USER_STAKING_CLAIM"
        ]);
        setTypeDisplay("Staking");
        break;
      case "EXTERNAL_WALLET_TRANSACTION":
        setTypeIn(["EXTERNAL_ERC20_WALLET_TRANSACTION"]);
        setTypeDisplay("External Wallet Transfer");
        break;
      default:
        setTypeIn([e.key]);
        switch (e.key) {
          case "USER_TRANSFER_BETWEEN_ACCOUNTS":
            setTypeDisplay("Transfer Between Accounts");
            break;
          case "USER_TRANSFER_BETWEEN_MEMBERS":
            setTypeDisplay("Transfer Between Members");
            break;
          case "EXTERNAL_TRANSACTION":
            setTypeDisplay("External Transaction");
            break;
          case "WIRE_DEPOSIT":
            setTypeDisplay("Deposit via Wire Transfer");
            break;
          case "INTEREST":
            setTypeDisplay("Rewards");
            break;
          case "SERVICE_FEE":
            setTypeDisplay("Service Fee");
            break;
        }
        break;
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="ALL">All Transactions</Menu.Item>
      <Menu.Item key="USER_TRANSFER_BETWEEN_ACCOUNTS">Transfer Between Accounts</Menu.Item>
      <Menu.Item key="USER_TRANSFER_BETWEEN_MEMBERS">Transfer Between Members</Menu.Item>
      <Menu.Item key="EXTERNAL_TRANSACTION">External Transaction</Menu.Item>
      <Menu.Item key="WIRE_TRANSFER">Transfer via Wire Transfer</Menu.Item>
      <Menu.Item key="WIRE_DEPOSIT">Deposit via Wire Transfer</Menu.Item>
      <Menu.Item key="EXTERNAL_WALLET_TRANSACTION">External Wallet Transaction</Menu.Item>
      <Menu.Item key="STAKING">Staking</Menu.Item>
      <Menu.Item key="INTEREST">Rewards</Menu.Item>
      <Menu.Item key="SERVICE_FEE">Service Fee</Menu.Item>
    </Menu>
  );

  const TransactionItem = ({ item }) => {
    return (
      <div
        className="accounts-transaction-item-container cursor-pointer"
        key={item.id}
        onClick={() => {
          setModalData(item);
          setModalOpened(true);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "start" }}>
          <p className="padding-none margin-none" style={{ color: "#A3A3A3", textAlign: "left" }}>
            {item && moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
          </p>
          <p className="padding-none margin-none" style={{ fontSize: 18, fontWeight: "500", textAlign: "left" }}>
            {item?.title}
          </p>
          <p className="padding-none margin-none" style={{ color: "#A3A3A3", textAlign: "left" }}>
            {item?.description}
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <p
            style={{
              fontSize: 20,
              fontWeight: "500",
              marginTop: 5,
              marginBottom: 5,
              color: item?.direction === TRANSACTION_DIRECTION.DEPOSIT ? "#53E085" : "red"
            }}
          >
            {`${item?.direction === TRANSACTION_DIRECTION.DEPOSIT ? "+" : "-"} ${formatAmount(
              item.amount,
              item.currency
            )}`}
            <span style={{ fontSize: 12, fontWeight: "500", marginLeft: 5 }}>{item?.currency}</span>
          </p>
        </div>
      </div>
    );
  };

  const betweenAccountInverse = (transaction) => {
    const transactionInverse = {
      ...transaction,
      direction: TRANSACTION_DIRECTION.DEPOSIT,
      amount: transaction.toAmount,
      currency: transaction.toCurrency,
      currencyType: transaction.toCurrencyType,
      amount: transaction.toAmount,

      fromAccountAlias: transaction.toAccountAlias,
      fromAccountId: transaction.toAccountId,
      fromAccountPublicId: transaction.toAccountPublicId,
      fromAccountType: transaction.toAccountType,
      fromAmount: transaction.toAmount,
      fromCurrency: transaction.toCurrency,
      fromCurrencyType: transaction.toCurrencyType,
      fromEmail: transaction.toEmail,
      fromUserFirstName: transaction.toUserFirstName,
      fromUserId: transaction.toUserId,
      fromUserLastName: transaction.toUserLastName,

      toAccountAlias: transaction.fromAccountAlias,
      toAccountId: transaction.fromAccountId,
      toAccountPublicId: transaction.fromAccountPublicId,
      toAccountType: transaction.fromAccountType,
      toAmount: transaction.fromAmount,
      toCurrency: transaction.fromCurrency,
      toCurrencyType: transaction.fromCurrencyType,
      toEmail: transaction.fromEmail,
      toUserFirstName: transaction.fromUserFirstName,
      toUserId: transaction.fromUserId,
      toUserLastName: transaction.fromUserLastName
    };
    return transactionInverse;
  };

  const accountDetail = (type) => {
    const { direction, providerMetaData } = modalData;
    if (modalData.type === "EXTERNAL_TRANSACTION") {
      if (
        (type === "FROM" && direction === TRANSACTION_DIRECTION.DEPOSIT) ||
        (type === "TO" && direction === TRANSACTION_DIRECTION.WITHDRAWAL)
      ) {
        return (
          <div>
            <KeyValue4 keyName="Service Provider" value={modalData.title} />
          </div>
        );
      }
    } else if (
      modalData.type === "EXTERNAL_ERC20_WALLET_TRANSACTION" ||
      modalData.type === "EXTERNAL_AXTEND_WALLET_TRANSACTION"
    ) {
      if (
        (type === "FROM" && direction === TRANSACTION_DIRECTION.DEPOSIT) ||
        (type === "TO" && direction === TRANSACTION_DIRECTION.WITHDRAWAL)
      ) {
        return (
          <div>
            <KeyValue4 keyName="External Wallet Address" value={`${providerMetaData?.toAddress}`} />
          </div>
        );
      }
    } else if (
      modalData.type === "WIRE_TRANSFER" ||
      modalData.type === "USER_STAKING_CREATED" ||
      modalData.type === "USER_STAKING_ADD_AMOUNT" ||
      modalData.type === "USER_STAKING_LOAD_AMOUNT"
    ) {
      if (type === "TO") {
        return (
          <div>
            <KeyValue4 keyName="Name" value={getBeneficiaryName(modalData)} />
          </div>
        );
      }
    } else if (modalData.type === "WIRE_DEPOSIT" || modalData.type === "USER_STAKING_CLAIM") {
      if (type === "FROM") {
        return (
          <div>
            <KeyValue4 keyName="Name" value={getBeneficiaryName(modalData)} />
          </div>
        );
      }
    } else if (
      modalData.type === TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_WITHDRAWAL ||
      modalData.type === TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_DEPOSIT ||
      modalData.type === TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_V2_WITHDRAWAL ||
      modalData.type === TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_V2_DEPOSIT
    ) {
      if (
        (type === "FROM" && direction === TRANSACTION_DIRECTION.DEPOSIT) ||
        (type === "TO" && direction === TRANSACTION_DIRECTION.WITHDRAWAL)
      ) {
        return (
          <div>
            {direction === TRANSACTION_DIRECTION.WITHDRAWAL && (
              <KeyValue4
                keyName="External Wallet Address"
                value={providerMetaData?.toAddress || "TBD"}
                copyValue={providerMetaData?.toAddress ?? null}
              />
            )}

            <KeyValue4
              keyName="Transaction Hash"
              value={providerMetaData?.transactionHash ?? "TBD"}
              copyValue={providerMetaData?.transactionHash ?? null}
            />
            <KeyValue4
              keyName="Confirmed Block"
              value={providerMetaData?.confirmedBlock ?? "TBD"}
              copyValue={providerMetaData?.confirmedBlock ?? null}
            />
          </div>
        );
      }
    } else if (
      !["USER_CREATED", "USER_TRANSFER_BETWEEN_MEMBERS", "USER_TRANSFER_BETWEEN_ACCOUNTS"].includes(modalData.type)
    ) {
      if (
        (type === "FROM" && direction === TRANSACTION_DIRECTION.DEPOSIT) ||
        (type === "TO" && direction === TRANSACTION_DIRECTION.WITHDRAWAL)
      ) {
        return (
          <div>
            <KeyValue4 keyName="Name" value="AXIA Capital Bank" />
          </div>
        );
      }
    }

    let firstName, lastName, email, publicId, amountChangedInAxc, amountChangedInUsd;
    if (type === "FROM") {
      firstName = modalData.fromUserFirstName;
      lastName = modalData.fromUserLastName;
      email = modalData.fromEmail;
      publicId = modalData.fromAccountPublicId;
    } else {
      firstName = modalData.toUserFirstName;
      lastName = modalData.toUserLastName;
      email = modalData.toEmail;
      publicId = modalData.toAccountPublicId;
    }
    if (
      modalData.spendableAmountChange &&
      modalData.spendableAmountChange.amountChangedInAxc &&
      modalData.spendableAmountChange.amountChangedInUsd
    ) {
      amountChangedInAxc = formatAmount(modalData.spendableAmountChange.amountChangedInAxc, CURRENCY.AXC.code);
      amountChangedInAxc = `${amountChangedInAxc} ${CURRENCY.AXC.code}`;
      amountChangedInUsd = formatAmount(modalData.spendableAmountChange.amountChangedInUsd, CURRENCY.USD.code);
      amountChangedInUsd = `${amountChangedInUsd} ${CURRENCY.USD.code}`;
    }
    return (
      <div>
        <KeyValue4 keyName="Name" value={`${firstName} ${lastName}`} />
        <KeyValue4 keyName="Email" value={email} />
        <KeyValue4 keyName="Account" value={publicId} />
        {/* <KeyValue5
          keyName="Account"
          subKey1="ADDRESS"
          subValue1="0x16826384feaea7adb39d6404b1b184eb70d4b6f6"
          subKey2="ACCOUNT NUMBER"
          subValue2={publicId}
        /> */}
        {isValidForFeature({ user, featureName: FEATURE_NAMES.GLOBAL_SPENDABLE_AMOUNT }) && amountChangedInAxc && (
          <KeyValue4 keyName="Spendable Amount Changed in AXC" value={amountChangedInAxc} />
        )}
        {isValidForFeature({ user, featureName: FEATURE_NAMES.GLOBAL_SPENDABLE_AMOUNT }) && amountChangedInUsd && (
          <KeyValue4 keyName="Spendable Amount Changed in USD" value={amountChangedInUsd} />
        )}
      </div>
    );
  };

  const closeModal = () => {
    setModalOpened(false);
    setModalData({});
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 80 }}>
      <div
        className="row"
        style={{ margin: 0, padding: 0, paddingLeft: 30, paddingRight: 20, width: "100%", marginBottom: 10 }}
      >
        <p style={{ fontSize: 20, fontWeight: "500", padding: 0, margin: "0 0 0 10px" }}>
          {expanded ? "Transactions" : "Recent Transactions"}
        </p>
      </div>
      {expanded && isLocal() && (
        <div style={{ display: "flex", margin: "0 40px 5px auto" }}>
          <Input placeholder="search..." style={{ width: 300, borderRadius: 5, height: 40 }} />
        </div>
      )}

      {expanded && (
        <div style={{ width: "100%", paddingLeft: 30, paddingRight: 20 }}>
          <Divider style={{ margin: 0 }} />
        </div>
      )}
      {expanded && (
        <div
          className="adjustable-layout"
          style={{
            margin: "10px 0",
            padding: "0 40px",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <div className="row">
            <Dropdown overlay={menu}>
              <Button style={{ width: "15rem" }}>
                {typeDisplay} <DownOutlined />
              </Button>
            </Dropdown>
            <div style={{ width: 10 }} />
            <RangePicker
              onChange={(dates, dateStrings) => {
                if (dateStrings[0] && dateStrings[0] !== "") {
                  setCreatedAtGte(moment(dateStrings[0]).toISOString());
                } else {
                  setCreatedAtGte();
                }
                if (dateStrings[1] && dateStrings[1] !== "") {
                  // add a day so that it includes that day
                  setCreatedAtLte(moment(dateStrings[1]).add(1, "days").toISOString());
                } else {
                  setCreatedAtLte();
                }
              }}
            />
          </div>
          <div style={{ height: 10 }} />
          <div style={{ marginLeft: "auto" }}>
            {/* <Button type="primary" icon={<FileOutlined />} style={{ borderRadius: 5 }}>
              Export CVS
            </Button> */}
            <Button
              type="primary"
              // icon={<FileOutlined style={{ color: "#ffffff" }} />}
              style={{ borderRadius: 5, marginLeft: 10 }}
              onClick={handlePrint}
            >
              Print Statement
            </Button>
          </div>
        </div>
      )}

      {!loading && (
        <div style={{ width: "100%" }}>
          {transactions && transactions.length > 0 ? (
            <div className="accounts-transaction-box">
              {transactions.map((item, index) => {
                if (index > 4) return;
                if (item.type === "USER_TRANSFER_BETWEEN_ACCOUNTS" && !account && !expanded) {
                  return (
                    <div key={index}>
                      <TransactionItem item={item} />
                      <TransactionItem item={betweenAccountInverse(item)} />
                    </div>
                  );
                }
                return (
                  <div key={index}>
                    <TransactionItem item={item} />
                  </div>
                );
              })}
              <div className="accounts-transaction-bottom">
                <p>
                  Showing {transactions.length < 5 ? transactions.length : 5} of {totalTransactions} results
                </p>
                <Pagination
                  style={{ textAlign: "right" }}
                  size="small"
                  pageSize={5}
                  defaultCurrent={1}
                  total={totalTransactions}
                  showSizeChanger={false}
                  onChange={(page) => {
                    fetchTransactions({
                      limit: 5,
                      offset: (page - 1) * 5
                    });
                  }}
                />
              </div>
            </div>
          ) : (
            <p style={{ fontSize: "1rem", marginTop: "1rem" }}>No transaction found</p>
          )}
        </div>
      )}
      <div style={{ display: "none" }}>
        <div style={{ width: "100%" }} ref={printRef}>
          <style> {pageStyle} </style> {/* STYLE FOR PRINTING, DO NOT DELETE */}
          {expanded && (
            <AccountsStatement account={account} transactions={transactions} start={createdAtGte} end={createdAtLte} />
          )}
        </div>
      </div>

      <div style={{ height: 20 }} />
      {modalData && (
        <Modal
          isOpen={modalOpened}
          onRequestClose={closeModal}
          ariaHideApp={false}
          style={{
            overlay: {
              zIndex: 9999,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              padding: "auto"
            },
            content: {
              margin: "auto",
              maxWidth: 1000,
              height: "100vh",
              overflow: "auto",
              padding: "auto",
              backgroundColor: "transparent",
              border: "none"
            }
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: 10,
              padding: 30,
              marginTop: 20,
              marginBottom: 100
            }}
          >
            <div style={{ borderBottom: "1px solid lightgrey", paddingBottom: 10 }}>
              <p className="margin-none" style={{ fontWeight: "500", fontSize: 20 }}>
                {modalData.title}
              </p>
              <p className="margin-none">{modalData.description}</p>
            </div>

            <div
              className="account-transaction-item-modal-grid"
              style={{ borderBottom: "1px solid lightgrey", paddingBottom: 10 }}
            >
              {/* Amount Section */}
              <div className="account-transaction-item-modal-bottom-line" style={{ paddingTop: 10 }}>
                <p className="margin-none" style={{ fontSize: 18, fontWeight: "500" }}>
                  Amount
                </p>
                <div>
                  <div className="row">
                    <KeyValue4
                      keyName="Amount"
                      value={`${formatAmount(modalData.amount, modalData.currency)} ${modalData?.currency}`}
                    />
                    <KeyValue4
                      keyName={`Total ${modalData?.direction === TRANSACTION_DIRECTION.DEPOSIT ? "Received" : "Sent"}`}
                      value={`${modalData?.direction === TRANSACTION_DIRECTION.DEPOSIT ? "+" : "-"} ${formatAmount(
                        modalData.amount,
                        modalData.currency
                      )} ${modalData?.currency}`}
                      style={{ marginLeft: 40 }}
                      valueTextStyle={{
                        color: modalData?.direction === TRANSACTION_DIRECTION.DEPOSIT ? "#53E085" : "red",
                        fontWeight: "600"
                      }}
                    />
                  </div>
                  {getOriginAmount()}
                  <KeyValue4
                    keyName="Transaction type"
                    value={modalData?.direction === TRANSACTION_DIRECTION.DEPOSIT ? "Received" : "Sent"}
                  />
                </div>
              </div>

              {/* Details Section */}
              <div style={{ paddingTop: 10 }}>
                <p className="margin-none" style={{ fontSize: 18, fontWeight: "500" }}>
                  Details
                </p>
                <div>
                  <KeyValue4 keyName="Date" value={moment(modalData.createdAt).format("YYYY-MM-DD HH:mm:ss")} />
                  <KeyValue4 keyName="Transaction status" value={modalData.status} />
                  <KeyValue4 keyName="Last updated" value={moment(modalData.updatedAt).format("YYYY-MM-DD HH:mm:ss")} />
                  <KeyValue4 keyName="Memo" value={modalData.userMemo ?? "N/A"} />
                </div>
              </div>
            </div>

            <div className="account-transaction-item-modal-grid">
              {/* From account Section */}
              <div className="account-transaction-item-modal-bottom-line" style={{ paddingTop: 10 }}>
                <p className="margin-none" style={{ fontSize: 18, fontWeight: "500" }}>
                  From
                </p>
                {accountDetail("FROM")}
              </div>

              {/* To account Section */}
              <div style={{ paddingTop: 10 }}>
                <p className="margin-none" style={{ fontSize: 18, fontWeight: "500" }}>
                  To
                </p>
                {accountDetail("TO")}
              </div>
            </div>

            <div className="row" style={{ marginTop: 20, alignItems: "center" }}>
              <div style={{ flex: 1 }}></div>
              <div className="row">
                {isLocal() && (
                  <Button type="primary" icon={<FileOutlined />} style={{ borderRadius: 5 }}>
                    Export transaction
                  </Button>
                )}

                <Button
                  type="primary"
                  style={{ borderRadius: 5, marginLeft: 20 }}
                  onClick={() => {
                    setModalOpened(false);
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export const AccountsTransaction = boundError(AccountsTransactionRaw);
