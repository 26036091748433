import Big from "big.js";
import { CURRENCY, CURRENCY_TYPES, getCurrencyType } from "../constants/currency";

// Set Big to around down mode
Big.RM = Big.roundDown;

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const isLocal = () => ["LOCAL"].includes(process.env.REACT_APP_ENV);
export const isDev = () => ["LOCAL", "DEV"].includes(process.env.REACT_APP_ENV);
export const isUat = () => ["LOCAL", "DEV", "UAT"].includes(process.env.REACT_APP_ENV);

export const FEATURE_NAMES = {
  CARD: "CARD",
  CUSTODY: "CUSTODY",
  CUSTODY_V2: "CUSTODY_V2",
  TRANSACTION2FA: "TRANSACTION2FA",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  MANDATORY_TAX_ID: "MANDATORY_TAX_ID",
  ONRAMPER: "ONRAMPER",
  TOKEN_MIGRATION_CONSENT: "TOKEN_MIGRATION_CONSENT",
  GLOBAL_SPENDABLE_AMOUNT: "GLOBAL_SPENDABLE_AMOUNT",
  GIFT_CARD: "GIFT_CARD",
  CARD_COURIER_OPTION: "CARD_COURIER_OPTION",
  STAKINGS: "STAKINGS",
  STAKINGS_RE_ENROLLMENT: "STAKINGS_RE_ENROLLMENT",
  STAKINGS_APPLY: "STAKINGS_APPLY",
  STAKINGS_ADD_AMOUNT: "STAKINGS_ADD_AMOUNT",
  STAKINGS_PRODUCTS: "STAKINGS_PRODUCTS",
  USAX_ACCOUNT: "USAX_ACCOUNT",
  STATUS_PAGE: "STATUS_PAGE",
  MENU_DEPOSIT: "MENU_DEPOSIT",
  MENU_TRANSFER: "MENU_TRANSFER",
  MENU_CARDS: "MENU_CARDS",
  MENU_STAKINGS: "MENU_STAKINGS",
  MENU_GIFT_CARDS: "MENU_GIFT_CARDS",
  FIAT_TO_CRYPTO: "FIAT_TO_CRYPTO",
  ENABLE_ONBOARDING: "ENABLE_ONBOARDING"
};

export const isValidForFeature = ({ user, featureName }) => {
  if (!featureName) {
    throw new Error("featureName is undefined!");
  }

  if (user?.features?.includes(featureName)) {
    return true;
  }

  return false;
};

export const formatAmount = (amount, currency, symbol = getAmountSymbol(currency)) => {
  if (!amount) {
    return `${symbol} 0`;
  }
  const amountArray = Big(amount)
    .toFixed(getDecimalScale(currency) + 1)
    .slice(0, -1)
    .split(".");
  const decimal = amountArray[1];
  let integerPart = amountArray[0];
  const regex = /(-?\d+)(\d{3})/;
  while (regex.test(integerPart)) {
    integerPart = integerPart.replace(regex, "$1,$2");
  }
  return `${symbol} ${integerPart}.${decimal}`;
};

export const getDecimalScale = (currency) => (getCurrencyType(currency) === CURRENCY_TYPES.FIAT ? 2 : 5);

export const getAmountSymbol = (currency) => {
  if (currency === CURRENCY.USD.code) {
    return "$ ";
  } else if (currency === CURRENCY.EUR.code) {
    return "€ ";
  } else if (currency === CURRENCY.GBP.code) {
    return "£ ";
  }

  return "";
};
