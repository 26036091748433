import React, { useContext, useState, useEffect } from "react";
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { boundError } from "../utils";
import UserServices from "../services/userServices";
import { AccountsContext } from "../hooks";
import { formatAmount } from "../utils";
import { ACCOUNT_STATUS } from "../constants/accountStatus";

const AccountsSelectorRaw = ({
  currency,
  currencyList,
  onAccountSelected,
  selectedAccount,
  placeholder = "Please select an account",
  style
}) => {
  const userServices = UserServices.getInstance();
  const [accounts] = useContext(AccountsContext);
  const [selectedAcc, setSelectedAcc] = useState(selectedAccount);

  useEffect(() => {
    userServices.fetchUserAccounts();
  }, []);

  useEffect(() => {
    setSelectedAcc(selectedAccount);
  }, [selectedAccount]);

  const getMatchCurrencyAccounts = () => {
    let temp = accounts;

    if (currency) {
      temp = temp.filter((account) => account?.currency === currency);
    }
    if (currencyList) {
      temp = temp.filter((account) => currencyList.includes(account?.currency));
    }

    return temp;
  };
  const matchCurrencyAccounts = getMatchCurrencyAccounts();

  const getAccountStr = (account) => {
    let str = placeholder;

    if (account?.type) {
      str = account?.type;
    }
    if (account?.publicId) {
      str = str + (str != "" ? ":" : "") + account?.publicId;
    }
    if (account?.amount) {
      str = str + (str != "" ? " -- " : "") + formatAmount(account?.amount, account?.currency);
    }
    if (account?.currency) {
      str = str + (str != "" ? " " : "") + account?.currency;
    }

    return str;
  };

  const handleMenuClick = (e) => {
    const account = userServices.getAccountById(e?.key, matchCurrencyAccounts);
    if (account.status !== ACCOUNT_STATUS.ACTIVE) {
      return;
    }
    setSelectedAcc(account);
    if (onAccountSelected) {
      onAccountSelected(account);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {matchCurrencyAccounts.map((account, index) => {
        const isActive = account.status === ACCOUNT_STATUS.ACTIVE;
        return (
          <Menu.Item
            style={isActive ? {} : { backgroundColor: "lightgray", cursor: "not-allowed" }}
            key={account?.id}
            icon={<UserOutlined />}
          >
            {getAccountStr(account)}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      overlayStyle={{
        zIndex: 9999,
        overflow: "scroll",
        maxHeight: "100vh",
        backgroundColor: "white",
        borderRadius: "12px",
        border: "2px solid #CDE6F6"
      }}
      trigger="click"
    >
      <Button
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: "12px",
          border: "2px solid #B1B5C4",
          padding: "10px 20px",
          height: "unset",
          ...style
        }}
      >
        <div style={{ overflow: "hidden", textOverflow: "clip", flex: 1 }}>
          {selectedAcc === null ? placeholder : getAccountStr(selectedAcc)}
        </div>
        <DownOutlined style={{ marginLeft: 5 }} />
      </Button>
    </Dropdown>
  );
};

export const AccountsSelector = boundError(AccountsSelectorRaw);
