import React from "react";

export const ListItem1 = ({ text1, text2, text3, onClick }) => {
  return (
    <div className={"row" + (onClick != null ? " cursor-pointer" : "")} onClick={onClick}>
      <div style={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "start" }}>
        <p className="padding-none margin-none" style={{ fontWeight: "500", color: "#A3A3A3", textAlign: "left" }}>
          {text1}
        </p>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <p style={{ fontSize: 16, fontWeight: "500", marginTop: 5, marginBottom: 5, color: "#53E085" }}>
          {text2}
          <span style={{ fontSize: 12, fontWeight: "500", marginLeft: 5 }}>{text3}</span>
        </p>
      </div>
    </div>
  );
};
