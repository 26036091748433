import React, { useState, useEffect } from "react";
import ReactCodeInput from "react-code-input";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { notification, Spin, Statistic } from "antd";
import { awsConfirmSignUp, resendSignUpCode, signIn } from "../apis";
import { AuthWrapper, ButtonPrimary } from "../components";
import { getCustomErrorMessage, handleErrorMessage } from "../constants";
import { boundError, showSuccess } from "../utils";
import { loginSuccess } from "../redux/actions";
import { WarningFilled } from "@ant-design/icons";

const { Countdown } = Statistic;

const SignUpConfirmRaw = ({ loginSuccess }) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifyEmailError, setVerifyError] = useState("");
  const [countdown, setCountdown] = useState(undefined);
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;
  const password = location.state?.password;
  useEffect(() => {
    if (email) {
      setCountdown(Date.now() + 60 * 1000);
      return () => {
        setCountdown(undefined);
      };
    } else {
      navigate("/login", { replace: true });
    }
  }, []);
  const requestLogin = () => {
    notification.success({
      message: "Successfully signed up",
      description: "You may log in now"
    });
    navigate("/login");
  };
  const verifyEmail = async () => {
    try {
      setLoading(true);
      setVerifyError("");
      if (code.length < 6) {
        return setVerifyError("Please enter the complete verification code.");
      }
      await awsConfirmSignUp(email, code);
      if (password) {
        try {
          const user = await signIn(email, password);
          loginSuccess(user);
          navigate("/", { replace: true });
        } catch (err) {
          requestLogin();
        }
      } else {
        requestLogin();
      }
    } catch (err) {
      console.log(err);
      const errMessage = handleErrorMessage(err);
      setVerifyError(getCustomErrorMessage(errMessage));
    } finally {
      setLoading(false);
    }
  };

  const resendCode = async () => {
    if (!countdown) {
      try {
        await resendSignUpCode(email);
        setCountdown(Date.now() + 60 * 1000);
        showSuccess("Verification code sent.");
      } catch (err) {
        console.log(err);
        setVerifyError(getCustomErrorMessage(err.message));
      }
    }
  };
  return (
    <AuthWrapper>
      <div className="sign-up-confirm__container">
        <div className="auth-page__large-title">Confirm your email</div>
        <div className="auth-page__subtitle">{email}</div>
        <p className="auth-page__message" style={{ margin: "30px 0" }}>
          Check your spam folder and the spelling of your email address. Incorrect email?{" "}
          <span className="clickable-text" onClick={() => navigate(-1, { replace: true })}>
            Edit email address
          </span>
        </p>
        {verifyEmailError && (
          <div className="confirm-login__error-container">
            <WarningFilled style={{ color: "red" }} />
            {verifyEmailError}
          </div>
        )}
        <Spin spinning={loading}>
          <div className="confirm-login__form">
            <ReactCodeInput type="number" fields={6} autoFocus value={code} onChange={setCode} />
            <ButtonPrimary onClick={verifyEmail}>Verify</ButtonPrimary>
            <div style={{ marginTop: -40 }}>
              Code hasn't arrived?{" "}
              <span className={countdown ? "disabled-clickable-text" : "clickable-text"} onClick={resendCode}>
                Re-send code
              </span>{" "}
              <Countdown
                value={countdown}
                format={countdown ? " in s[s]" : ""}
                onFinish={() => setCountdown(undefined)}
              />
            </div>
          </div>
        </Spin>
      </div>
    </AuthWrapper>
  );
};

export const SignUpConfirm = boundError(connect(null, { loginSuccess })(SignUpConfirmRaw));
