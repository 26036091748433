import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(err) {
    console.log(err);
  }
  render() {
    if (!this.props.children) {
      return null;
    }
    if (this.state.hasError) {
      return <span>Something went wrong</span>;
    }
    return this.props.children;
  }
}

export const boundError = (ChildComponent) => {
  return function EnhancedComponent(props) {
    return (
      <ErrorBoundary>
        <ChildComponent {...props} />
      </ErrorBoundary>
    );
  };
};
