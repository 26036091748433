import React from "react";
import { Link } from "react-router-dom";

export const CancelBtn = ({ style = {}, toPath = "/" }) => {
  return (
    <Link
      to={toPath}
      style={{
        padding: "0.5rem 0.75rem 0.5rem 0.75rem",
        borderRadius: 12,
        border: "2px solid #178FE1",
        width: 300,
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style
      }}
    >
      <p className="margin-none" style={{ color: "#178FE1", fontSize: "1rem", fontWeight: 500 }}>
        Cancel
      </p>
    </Link>
  );
};
