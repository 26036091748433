import {
  CheckCircleFilled,
  CloseCircleFilled,
  DoubleRightOutlined,
  ExclamationCircleFilled,
  InfoCircleFilled,
  SyncOutlined
} from "@ant-design/icons";
import { Popover, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { getAllComponentsAcb } from "../../apis/statusPage";
import { boundError, showError } from "../../utils";

const StatusPagePopoverRaw = () => {
  const [partialOutages, setPartialOutages] = useState([]);
  const [majorOutages, setMajorOutages] = useState([]);
  const [maintenanceComponents, setMaintenanceComponents] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchComponents();
  }, []);

  const fetchComponents = async () => {
    setError(false);
    try {
      const components = await getAllComponentsAcb();
      const partialoutages = [];
      const majoroutages = [];
      const maintenance = [];

      components.forEach((component) => {
        if (component.status !== "operational") {
          if (component.status === "partial_outage") {
            partialoutages.push({ name: component.name, description: component.description });
          }
          if (component.status === "major_outage") {
            majoroutages.push({ name: component.name, description: component.description });
          }
          if (component.status === "under_maintenance") {
            maintenance.push({ name: component.name, description: component.description });
          }
        }
      });

      if (partialoutages.length > 0 || majoroutages.length > 0 || maintenance.length > 0) {
        setPartialOutages(partialoutages);
        setMajorOutages(majoroutages);
        setMaintenanceComponents(maintenance);
      }
      setFetched(true);
    } catch (err) {
      showError(err);
      setError(true);
      setFetched(true);
    }
  };

  const contentOutage = () => {
    return (
      <div style={{ fontSize: "1.125rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
        {maintenanceComponents.length > 0 && (
          <div>
            The following services are undergoing <span style={{ color: "#096DD9" }}>maintenance</span>:
            <ul>
              {maintenanceComponents.map((component) => (
                <li style={{ marginTop: "1rem", maxWidth: "500px" }}>
                  {component.name} {component.description ? `- ${component.description}` : ""}
                </li>
              ))}
            </ul>
          </div>
        )}
        {majorOutages.length > 0 && (
          <div>
            The following services are undergoing <span style={{ color: "#FF4D4F" }}>major</span> outages:
            <ul>
              {majorOutages.map((component) => (
                <li style={{ marginTop: "1rem", maxWidth: "500px" }}>
                  {component.name} {component.description ? `- ${component.description}` : ""}
                </li>
              ))}
            </ul>
          </div>
        )}
        {partialOutages.length > 0 && (
          <div>
            The following services are undergoing <span style={{ color: "orange" }}>partial</span> outages:
            <ul>
              {partialOutages.map((component) => (
                <li style={{ marginTop: "1rem", maxWidth: "500px" }}>
                  {component.name} {component.description ? `- ${component.description}` : ""}
                </li>
              ))}
            </ul>
          </div>
        )}
        <a href={`${process.env.REACT_APP_STATUSPAGE_VIEW_URL}`} target="_blank" rel="noopener noreferrer">
          <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>Details</span>
          <DoubleRightOutlined />
        </a>
      </div>
    );
  };

  const contentFetching = () => {
    return (
      <div style={{ fontSize: "1.125rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
        Fetching the Service Status
        <div>
          <a href={`${process.env.REACT_APP_STATUSPAGE_VIEW_URL}`} target="_blank" rel="noopener noreferrer">
            <span>Details</span>
            <DoubleRightOutlined />
          </a>
        </div>
      </div>
    );
  };
  const contentError = () => {
    return (
      <div style={{ fontSize: "1.125rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
        There was an error fetching the Service Status
        <div>
          <a href={`${process.env.REACT_APP_STATUSPAGE_VIEW_URL}`} target="_blank" rel="noopener noreferrer">
            <span>Details</span>
            <DoubleRightOutlined />
          </a>
        </div>
      </div>
    );
  };

  const contentOperational = () => {
    return (
      <div style={{ fontSize: "1.125rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
        All services are operational
        <div>
          <a href={`${process.env.REACT_APP_STATUSPAGE_VIEW_URL}`} target="_blank" rel="noopener noreferrer">
            <span>Details</span>
            <DoubleRightOutlined />
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className="header-service-status-tag">
      <Popover
        title={<div style={{ fontSize: "1.25rem" }}>Service Status</div>}
        content={
          !fetched
            ? contentFetching
            : error
            ? contentError
            : maintenanceComponents.length > 0 || majorOutages.length > 0 || partialOutages.length > 0
            ? contentOutage
            : contentOperational
        }
        placement="bottomRight"
      >
        <div style={{ alignSelf: "center", width: "100%", display: "flex", justifyContent: "end" }}>
          <Tag
            className={"header-service-status-outer-tag"}
            color={
              !fetched
                ? "gray"
                : error
                ? "error"
                : maintenanceComponents.length > 0
                ? "blue"
                : majorOutages.length > 0
                ? "error"
                : partialOutages.length > 0
                ? "warning"
                : "success"
            }
          >
            {!fetched ? (
              <div style={{ display: "flex", alignItems: "center", padding: "0.5rem" }}>
                <SyncOutlined spin style={{ marginRight: "0.5rem" }} />
                <span>Fetching Status</span>
              </div>
            ) : error === true ? (
              "Error Fetching Status"
            ) : maintenanceComponents.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <InfoCircleFilled style={{ marginRight: "0.5rem", color: "#2db7f5", fontSize: "16px" }} />
                  <span style={{ color: "black", marginRight: "1.5rem" }}>Service Status</span>
                </div>
                <Tag
                  color={"#2db7f5"}
                  style={{
                    fontSize: "inherit",
                    backgroundColor: "#2db7f5",
                    color: "white",
                    padding: "0.5rem",
                    marginRight: 0
                  }}
                >
                  Under Maintenance
                </Tag>
              </div>
            ) : majorOutages.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CloseCircleFilled color="error" style={{ marginRight: "0.5rem", fontSize: "16px" }} />
                  <span style={{ color: "black", marginRight: "1.5rem" }}>Service Status</span>
                </div>
                <Tag
                  color="error"
                  style={{
                    fontSize: "inherit",
                    backgroundColor: "white",
                    padding: "0.5rem",
                    marginRight: 0
                  }}
                >
                  Major Outage
                </Tag>
              </div>
            ) : partialOutages.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ExclamationCircleFilled color="warning" style={{ marginRight: "0.5rem", fontSize: "16px" }} />
                  <span style={{ color: "black", marginRight: "1.5rem" }}>Service Status</span>
                </div>
                <Tag
                  color="warning"
                  style={{
                    fontSize: "inherit",
                    padding: "0.5rem",
                    backgroundColor: "white",
                    marginRight: 0,
                    color: "black"
                  }}
                >
                  Partial Outage
                </Tag>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleFilled color="success" style={{ marginRight: "0.5rem", fontSize: "16px" }} />
                  <span style={{ color: "black", marginRight: "1.5rem" }}>Service Status</span>
                </div>
                <Tag
                  color="success"
                  style={{
                    fontSize: "inherit",
                    padding: "0.5rem",
                    backgroundColor: "white",
                    marginRight: 0,
                    color: "black"
                  }}
                >
                  Fully Operational
                </Tag>
              </div>
            )}
          </Tag>
        </div>
      </Popover>
    </div>
  );
};
export const StatusPagePopover = boundError(StatusPagePopoverRaw);
