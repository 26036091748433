import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import moment from "moment";
import { boundError } from "../utils";
import Big from "big.js";
import { getUserGiftCard, archiveGiftCard } from "../apis";
import { BackBtn } from "../components";
import { formatAmount } from "../utils";

const GiftCardDetailRaw = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [giftCardInfo, setGiftCardInfo] = useState({});
  const [archiveButtonText, setArchiveButtonText] = useState("Archive Gift Card");

  useEffect(() => {
    fetchGiftCards();
  }, [id]);

  const fetchGiftCards = async () => {
    const res = await getUserGiftCard({ id });
    const info = res.data[0];
    setGiftCardInfo(info);
    setArchiveButtonText(info.archive === true ? `Unarchive Gift Card` : `Archive Gift Card`);
  };

  const renderGiftCardStatus = (status) => {
    let color, backgroundColor;
    switch (status) {
      case "PROCESSED":
        color = "rgba(0, 153, 0, 1)";
        backgroundColor = "rgba(153, 255, 153, 0.8)";
        break;
      case "PENDING":
        color = "grey";
        backgroundColor = "rgba(224, 224, 224, 1)";
        break;
      case "FAILED":
        color = "red";
        backgroundColor = "rgba(255, 204, 204, 1)";
        break;
    }
    return (
      <p
        className="margin-none"
        style={{
          fontWeight: 600,
          fontSize: 14,
          padding: "2px 8px",
          borderRadius: 10,
          color,
          backgroundColor
        }}
      >
        {status}
      </p>
    );
  };

  const renderVoucher = ({ code, pin, url, validityDate, faceValue, voucherCurrency }) => {
    return (
      <div style={{ marginBottom: "0.5rem" }}>
        {code && <p className="margin-none" style={{ fontSize: "16px" }}>{`Code: ${code}`}</p>}
        {pin && <p className="margin-none" style={{ fontSize: "16px" }}>{`PIN: ${pin}`}</p>}
        {url && <p className="margin-none" style={{ fontSize: "16px" }}>{`URL: ${url}`}</p>}
        {validityDate && <p className="margin-none" style={{ fontSize: "16px" }}>{`Validity Date: ${validityDate}`}</p>}
        {faceValue && (
          <p className="margin-none" style={{ fontSize: "16px" }}>{`Card Value: ${faceValue} ${voucherCurrency}`}</p>
        )}
      </div>
    );
  };

  const renderGiftCardDisplayInfo = (title, info) => {
    if (info && info !== "") {
      return (
        <>
          <p style={{ fontSize: "16px", marginBottom: "0.5rem", fontWeight: 600 }}>{title}</p>
          <div style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: info }} />
        </>
      );
    }
  };

  const getFaceValueText = ({ faceValue, faceValueCurrency, paidAmount, paidCurrency }) => {
    const faceValueStr = `${new Big(faceValue && faceValue !== "" ? faceValue : 0).toFixed(2)} ${faceValueCurrency}`;
    const paidValueStr = `${formatAmount(paidAmount, paidCurrency)} ${paidCurrency}`;
    return `${faceValueStr} / ${paidValueStr}`;
  };

  return (
    <div>
      <div className="body-padding avoid-bottom-bar">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2rem",
            justifyContent: "space-between",
            gap: "1rem"
          }}
        >
          <BackBtn text={"Back to overview"} onClick={() => navigate("/gift-cards")} />
          <Button
            type="primary"
            style={{ marginTop: 10, borderRadius: 5, backgroundColor: "#3B82F6", height: "2.5rem" }}
            onClick={async () => {
              await archiveGiftCard({ id, archive: !giftCardInfo.archive });
              await fetchGiftCards();
            }}
          >
            <p className="margin-none">{archiveButtonText}</p>
          </Button>
        </div>
        <div style={{ backgroundColor: "white", padding: 20, borderRadius: "1rem" }}>
          <div className="gift-card-brand-info">
            <img
              src={giftCardInfo?.productImage}
              alt=""
              // style={{ maxWidth: "50%", objectFit: "contain", marginBottom: "0.5rem" }}
            />
            <div style={{ display: "flex", flexDirection: "column", alignContent: "flex-start", textAlign: "left" }}>
              <div
                style={{
                  display: "flex",
                  flexDirectionL: "row",
                  alignItems: "center",
                  marginBottom: "0.5rem",
                  gap: "1rem"
                }}
              >
                <p className="margin-none" style={{ fontWeight: 600, fontSize: "20px" }}>
                  {giftCardInfo.productName}
                </p>
                {renderGiftCardStatus(giftCardInfo.status)}
              </div>

              <p className="margin-none" style={{ fontSize: "16px", marginBottom: "0.5rem" }}>
                {`Currency: ${giftCardInfo.faceValueCurrency}`}
              </p>
              <p className="margin-none" style={{ fontSize: "16px", marginBottom: "0.5rem" }}>
                {getFaceValueText(giftCardInfo)}
              </p>
              <p className="margin-none" style={{ fontSize: "16px", marginBottom: "0.5rem" }}>
                {`Bought on ${giftCardInfo && moment(giftCardInfo.createdAt).format("YYYY-MM-DD HH:mm:ss")}`}
              </p>
              {giftCardInfo.status === "PROCESSED" && giftCardInfo?.vouchers?.vouchers && (
                <>
                  <p className="margin-none" style={{ fontSize: "16px", marginBottom: "0.5rem", fontWeight: 600 }}>
                    Vouchers:
                  </p>
                  {giftCardInfo?.vouchers?.vouchers?.map((voucher) => renderVoucher(voucher))}
                </>
              )}
            </div>
          </div>
          {giftCardInfo.infoToDisplay && (
            <div style={{ textAlign: "left" }}>
              {renderGiftCardDisplayInfo("Gift card description", giftCardInfo.infoToDisplay?.productDescription)}
              {renderGiftCardDisplayInfo("Terms and conditions", giftCardInfo.infoToDisplay?.termsAndConditions)}
              {renderGiftCardDisplayInfo("Expiry and validity", giftCardInfo.infoToDisplay?.expiryAndValidity)}
              {renderGiftCardDisplayInfo("How to use", giftCardInfo.infoToDisplay?.howToUse)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const GiftCardDetail = boundError(GiftCardDetailRaw);
