import React, { useState, useContext } from "react";
import { boundError } from "../../utils";
import Big from "big.js";
import { formatAmount } from "../../utils";
import moment from "moment";
import { isValidForFeature, FEATURE_NAMES } from "../../utils";
import { UserContext } from "../../hooks";

import { ApplyStakingModal } from "./ApplyStakingModal";

const StakingsProductItemRaw = ({ product }) => {
  const [user] = useContext(UserContext);
  const [modalOpened, setModalOpened] = useState(false);

  const getAnnualInterestRateStr = () => {
    if (!isNaN(product?.annualInterestRate)) {
      return Big(product?.annualInterestRate).mul(100).toFixed(2) + "%";
    }

    return "";
  };

  const getRoomRemainStr = () => {
    if (!isNaN(product?.roomRemain)) {
      return formatAmount(product?.roomRemain, product?.currency) + " " + product?.currency;
    }

    return "";
  };

  const getEndDate = () => {
    return moment(product?.endAt).utc().format("YYYY/MM/DD");
  };

  const closeModal = () => {
    setModalOpened(false);
  };

  return (
    <>
      <div
        className="stakings__product-item"
        onClick={() => {
          if (isValidForFeature({ user, featureName: FEATURE_NAMES.STAKINGS_APPLY })) {
            setModalOpened(true);
          }
        }}
      >
        <div className="stakings__product-item-header">
          <p className="margin-none stakings__product-item-name">{product?.name}</p>
          <p className="margin-none">{getAnnualInterestRateStr() + " APR"}</p>
        </div>

        <div className="stakings__product-item-body">
          <div className="row" style={{ display: "flex", justifyContent: "space-between" }}>
            {/* <p className="margin-none stakings__product-item-room-limit">{"Room remain: "}</p>
            <p className="margin-none stakings__product-item-room-limit" style={{ textAlign: "right" }}>
              {getRoomRemainStr()}
            </p> */}
            {/* <p className="margin-none stakings__product-item-room-limit">{"Pool Closes On: "}</p>
            <p className="margin-none stakings__product-item-room-limit" style={{ textAlign: "right" }}>
              {getEndDate()}
            </p> */}
          </div>
          <div className="row">
            <p className="margin-none stakings__product-item-short-desc">{product?.shortDesc}</p>
          </div>
        </div>
        {isValidForFeature({ user, featureName: FEATURE_NAMES.STAKINGS_APPLY }) && (
          <p style={{ fontSize: 12, fontWeight: "700", color: "#178FE1", textAlign: "right", margin: "0 20px 10px 0" }}>
            Apply Now
          </p>
        )}
      </div>
      <ApplyStakingModal visible={modalOpened} product={product} closeModal={closeModal} />
    </>
  );
};

export const StakingsProductItem = boundError(StakingsProductItemRaw);
