import React, { useState, useEffect, useContext } from "react";
import { Button, Divider, Spin, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { BackBtn, SimpleButton } from "../components";
import { boundError, FEATURE_NAMES, isValidForFeature, showError, showSuccess, sleep } from "../utils";
import UserServices from "../services/userServices";
import TransferAndDepositServices from "../services/transferAndDepositServices";
import { KeyValue2, KeyValue4 } from "../components/SharedComponents/KeyValueFields";
import { AccountsSelector } from "../components";
import { CURRENCY } from "../constants/currency";
import { formatAmount, getDecimalScale, getAmountSymbol } from "../utils";
import NumberFormat from "react-number-format";
import { ConsentModal } from "../components/SharedComponents/consentModal";
import TokenSplitPDF from "../resources/consents/token_split/AXC_TOKEN_MIGRATION_TERMS_V2_202209.pdf";
import Big from "big.js";
import { UserContext } from "../hooks";

const TransactionGatewayRaw = () => {
  const [user] = useContext(UserContext);
  const userServices = UserServices.getInstance();
  const transferAndDepositServices = TransferAndDepositServices.getInstance();

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [depositAmount, setDepositAmount] = useState("");
  const [hotWallet, setHotWallet] = useState(null);

  const [errorAccountMsg, setErrorAccountMsg] = useState("");
  const [errorAmountMsg, setErrorAmountMsg] = useState("");

  const [loading, setLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [showAgreePopup, setShowAgreePopup] = useState(false);
  const [axiaTokenEnabled, setAxiaTokenEnabled] = useState(false);

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    userServices.fetchUserAccounts();
    fetchWallets();
    checkAXIANetworkToken();
  };

  const checkAXIANetworkToken = async () => {
    const isUserCreatedBefore = new Date(user?.createdAt || null) < new Date("2022-07-29T00:00:00");
    const isConsentEnabled = isValidForFeature({ user, featureName: FEATURE_NAMES.TOKEN_MIGRATION_CONSENT }) === true;
    if (isUserCreatedBefore && isConsentEnabled) {
      setAxiaTokenEnabled(true);
    } else {
      setAxiaTokenEnabled(false);
    }
  };

  const fetchWallets = async () => {
    try {
      setLoading(true);
      const result = await userServices.fetchUserERC20AXCWalletsApi();

      if (result?.HOT) {
        setHotWallet(result?.HOT);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  const openConfirmModal = () => {
    let hasErrors = false;
    if (!selectedAccount) {
      setErrorAccountMsg("Please select an account");
      hasErrors = true;
    }

    if (isNaN(depositAmount) || Number(depositAmount <= 0)) {
      setErrorAmountMsg("Please enter a valid amount");
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    if (axiaTokenEnabled && !showAgreePopup) {
      setShowAgreePopup(true);
      return;
    }

    setShowAgreePopup(false);
    setVisible(true);
  };

  const confirmDeposit = async () => {
    try {
      let hasErrors = false;
      if (!selectedAccount) {
        setErrorAccountMsg("Please select an account");
        hasErrors = true;
      }

      if (isNaN(depositAmount) || Number(depositAmount <= 0)) {
        setErrorAmountMsg("Please enter a valid amount");
        hasErrors = true;
      }

      if (hasErrors) {
        return;
      }
      setConfirmLoading(true);

      const result = await transferAndDepositServices.depositFromERC20(selectedAccount?.id, depositAmount);

      showSuccess("Deposit succeeded!");

      setVisible(false);

      setSelectedAccount(null);
      setDepositAmount("");
      resetErrors();
      await sleep(500);
      initData();
      setConfirmLoading(false);
    } catch (e) {
      setConfirmLoading(false);
      setVisible(false);

      showError(e);
    }
  };

  const resetErrors = () => {
    setErrorAmountMsg("");
    setErrorAccountMsg("");
  };

  const getWalletId = () => {
    if (hotWallet?.wallet_id) {
      return "****** " + hotWallet?.wallet_id?.substr(-10);
    }
    return "";
  };

  const getWalletAddress = () => {
    if (hotWallet?.eth_wallet_address) {
      return hotWallet?.eth_wallet_address;
    }

    return "";
  };

  const getWalletBalance = () => {
    if (hotWallet?.axc) {
      return formatAmount(hotWallet?.axc, "AXC") + " AXC";
    }
    return "";
  };

  const getDepositAmount = () => {
    if (depositAmount != "" && !isNaN(depositAmount)) {
      const splitRatio = axiaTokenEnabled ? 13.43 : 1;
      return formatAmount(Big(depositAmount).times(Big(splitRatio)), "AXC") + " AXC";
    }
    return "";
  };

  const getSelectedAccountStr = () => {
    if (selectedAccount != null) {
      let str = "";

      if (selectedAccount?.type) {
        str = selectedAccount?.type;
      }
      if (selectedAccount?.publicId) {
        str = str + (str != "" ? ":" : "") + selectedAccount?.publicId;
      }
      if (selectedAccount?.amount) {
        str = str + (str != "" ? " -- " : "") + formatAmount(selectedAccount?.amount, selectedAccount?.currency);
      }
      if (selectedAccount?.currency) {
        str = str + (str != "" ? " " : "") + selectedAccount?.currency;
      }

      return str;
    }

    return "";
  };

  const renderBody = () => {
    if (!hotWallet && !loading) {
      return (
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <h1>No wallet information!</h1>
        </div>
      );
    }

    return (
      <div
        className="transaction-gateway-body"
        style={{ backgroundColor: "white", padding: "20px 40px", borderRadius: 12 }}
      >
        <div className="transaction-gateway-title">
          <h1 className="margin-none" style={{ fontSize: 24, fontWeight: 500, whiteSpace: "nowrap" }}>
            AXIA Wallet on Ethereum
          </h1>
          <h1 className="margin-none" style={{ fontSize: 24, fontWeight: 500, whiteSpace: "nowrap" }}>
            (ERC-20 AXC)
          </h1>
        </div>
        <Divider />
        <KeyValue2 keyName="Email" value={hotWallet?.email ?? ""} />
        <KeyValue2
          keyName="ERC-20 AXC Token Wallet Address"
          value={getWalletAddress()}
          copyValue={getWalletAddress()}
        />
        <KeyValue2 keyName="Wallet Balance" value={getWalletBalance()} />
        <p
          className="margin-none"
          style={{
            textAlign: "left",
            fontSize: "1rem",
            marginBottom: "1rem",
            marginTop: "0.5rem",
            fontWeight: 500,
            color: " rgba(180, 83, 9)"
          }}
        >
          <ExclamationCircleOutlined style={{ marginRight: "0.5rem" }} />
          IMPORTANT - Please note: this wallet address is to be used for sending and receiving legacy ERC-20 AXC tokens
          only. DO NOT send any new AXIA Network Coins or other cryptocurrencies to this wallet as you would lose those
          funds.
        </p>
        <Divider />
        <div className="row">
          <p className="margin-none" style={{ fontWeight: 500, fontSize: 15, color: "#B1B5C3", marginBottom: 5 }}>
            {"Select account"}
          </p>
        </div>
        <div className="row">
          <AccountsSelector
            onAccountSelected={(account) => {
              setSelectedAccount(account);
              resetErrors();
            }}
            selectedAccount={selectedAccount}
            currency={CURRENCY.AXC.code}
            placeholder={"Please select account to deposit into"}
          />
        </div>
        {errorAccountMsg !== "" && (
          <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorAccountMsg}</p>
        )}

        <div className="row">
          <p className="margin-none" style={{ fontWeight: 500, fontSize: 15, color: "#B1B5C3", margin: "5px 0" }}>
            {"Enter deposit amount"}
          </p>
        </div>
        <div style={{ display: "flex" }}>
          <NumberFormat
            thousandSeparator={true}
            value={depositAmount}
            placeholder={"0.00"}
            decimalScale={getDecimalScale("AXC")}
            prefix={getAmountSymbol("AXC")}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setDepositAmount(value);

              resetErrors();
            }}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderRadius: "12px",
              border: "2px solid #B1B5C4",
              padding: "10px 20px",
              maxHeight: 46,
              marginRight: 10,
              fontSize: 16
            }}
          />
          <p
            style={{
              width: "100px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderRadius: "12px",
              border: "2px solid #B1B5C4",
              padding: "10px 20px",
              maxHeight: 46,
              justifyContent: "center",
              background: "#F8F9FA",
              fontSize: 16,
              marginBottom: 0
            }}
          >
            {"AXC"}
          </p>
        </div>
        {errorAmountMsg !== "" && (
          <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorAmountMsg}</p>
        )}
        <div></div>
        <Divider />
        {/* <div className="row">
          <p
            className="margin-none"
            style={{ fontSize: "1rem", marginBottom: "2rem", maxWidth: 600, textAlign: "left" }}
          >
            Please note that this transfer is not reversible. Once you transfer over the coins, they will be converted
            to AXIA network coins instead of ERC-20. Moreover, they cannot be redeemed or transferred to an external
            wallet before mainnet launch. Card spending and staking will work as before.
          </p>
        </div> */}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end"
          }}
        >
          <SimpleButton
            type="primary"
            style={{
              padding: "10px 20px",
              borderRadius: 12,
              border: "2px solid #3a82f6",
              background: "#178FE1"
            }}
            onClick={openConfirmModal}
            children={<p className="margin-none">Confirm</p>}
          />
        </div>
      </div>
    );
  };

  return (
    <Spin spinning={loading}>
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "85%", margin: "40px auto" }}
      >
        <BackBtn />
        <div className="row page-title" style={{ paddingTop: 0 }}>
          Deposit
        </div>
        {renderBody()}
      </div>
      <Modal
        style={{ marginBottom: 200 }}
        title="Confirm information"
        visible={visible}
        onOk={confirmDeposit}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <KeyValue2 keyName="From: " value={""} />
        <KeyValue4 keyName="Email" value={hotWallet?.email ?? ""} />
        <KeyValue4 keyName="ERC-20 AXC Token Wallet" value={getWalletAddress()} />
        <KeyValue4 keyName="Wallet Balance" value={getWalletBalance()} />
        <Divider />
        <KeyValue2 keyName="To: " value={""} />
        <KeyValue4 keyName="Account" value={getSelectedAccountStr()} />
        <KeyValue4 keyName="Amount" value={getDepositAmount()} />
      </Modal>
      <ConsentModal
        visible={showConsentModal}
        footer={null}
        onCancel={() => {
          setShowConsentModal(false);
        }}
        src={TokenSplitPDF}
        // src={`https://docs.google.com/viewerng/viewer?url=${usrConsentUrl}&embedded=true`}
      />
      <Modal
        visible={showAgreePopup}
        title="AXC Token Migration Terms"
        width={"50vh"}
        centered
        okText={"Agree"}
        cancelText={"Cancel"}
        onOk={() => openConfirmModal()}
        onCancel={() => {
          setShowAgreePopup(false);
        }}
      >
        <div className="modal">
          <div className="modalContent">
            I have read and agree to the{" "}
            <a
              onClick={() => {
                setShowConsentModal(true);
              }}
            >
              AXC Token Migration Terms.
            </a>
          </div>
        </div>
      </Modal>
    </Spin>
  );
};

export const TransactionGateway = boundError(TransactionGatewayRaw);
