import React from "react";
import { boundError } from "../../utils";
import { Select, Input, Button } from "antd";
const { Option } = Select;

const CardTypesDropDownSelectorRaw = ({ options, onChange, style, selectedCardType, ...props }) => {
  const onCardTypeChanged = (cardTypeCode) => {
    const cardType = options.find((opt) => opt.code === cardTypeCode);
    if (onChange) {
      onChange(cardType);
    }
  };

  return (
    <Select
      value={selectedCardType?.code}
      style={{ ...style, display: "flex" }}
      onChange={onCardTypeChanged}
      {...props}
    >
      {options.map((option, index) => {
        return (
          <Option value={option.code} key={index}>
            {`${option?.currency}`}
          </Option>
        );
      })}
    </Select>
  );
};

export const CardTypesDropDownSelector = boundError(CardTypesDropDownSelectorRaw);
