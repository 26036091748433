import React, { useState, useEffect } from "react";
import { Switch } from "antd";
import { AccountsHeadCard } from "./AccountsHeadCard";
import { boundError, formatAmount } from "../../../utils";
import { CURRENCY } from "../../../constants/currency";
import Big from "big.js";

const AccountsHeadBalanceRaw = ({ accounts, axcToUsdRate, stakingBalance, switchOn }) => {
  const [axcBalance, setAxcBalance] = useState(Big(0));
  const [usdBalance, setUsdBalance] = useState(Big(0));

  useEffect(() => {
    let axcAmount = new Big(0);
    let usdAmount = new Big(0);
    accounts.forEach((account) => {
      if (account.currency === CURRENCY.AXC.code) {
        axcAmount = axcAmount.plus(Big(account.amount));
      } else if (account.currency === CURRENCY.USD.code) {
        usdAmount = usdAmount.plus(Big(account.amount));
      }
      setAxcBalance(axcAmount.plus(Big(stakingBalance)));
      setUsdBalance(usdAmount);
    });
  }, [accounts, stakingBalance]);

  return (
    <AccountsHeadCard>
      <div className="row" style={{ fontWeight: 500, fontSize: 18 }}>
        AXIA Balance
      </div>
      <div className="row" style={{ fontWeight: 500, fontSize: 32, wordBreak: "break-word", textAlign: "left" }}>
        {switchOn
          ? formatAmount(axcBalance.mul(axcToUsdRate).plus(usdBalance), CURRENCY.USD.code, "$")
          : formatAmount(usdBalance.div(axcToUsdRate).plus(axcBalance), CURRENCY.AXC.code)}
      </div>
      <div className="row" style={{ fontWeight: 500 }}>
        {switchOn ? CURRENCY.USD.code : CURRENCY.AXC.code}
      </div>
    </AccountsHeadCard>
  );
};

export const AccountsHeadBalance = boundError(AccountsHeadBalanceRaw);
