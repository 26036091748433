import React, { useState, useEffect } from "react";
import Emitter, { EmitterEvents } from "../utils/eventEmitter";
import { sleep } from "../utils";

export const StakingProductsContext = React.createContext("Accounts");

const StakingProductsContextProvider = ({ children }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    Emitter.on(EmitterEvents.SET_STAKING_PRODUCTS, ({ data, cb }) => {
      setProducts(data);
      sleep(200).then(() => {
        cb();
      });
    });

    Emitter.on(EmitterEvents.GET_STAKING_PRODUCTS, (cb) => {
      cb(products);
    });

    return () => {
      Emitter.off(EmitterEvents.SET_STAKING_PRODUCTS);
      Emitter.off(EmitterEvents.GET_STAKING_PRODUCTS);
    };
  }, [products]);

  return <StakingProductsContext.Provider value={[products, setProducts]}>{children}</StakingProductsContext.Provider>;
};

export default StakingProductsContextProvider;
