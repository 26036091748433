import React, { useState, useEffect } from "react";
import { Button, Divider, Spin, Modal as Modal2, Checkbox } from "antd";
import { boundError, showError, showSuccess } from "../../utils";
import Modal from "react-modal";
import Big from "big.js";
import StakingServices from "../../services/stakingServices";
import Emitter, { EmitterEvents } from "../../utils/eventEmitter";
import { KeyValue2, KeyValue4 } from "../SharedComponents";
import { INTEREST_RATE_FREQUENCY } from "../../constants/interestRateFrequency";
import { AccountsSelector } from "../AccountsSelector";
import { formatAmount, getDecimalScale, getAmountSymbol } from "../../utils";
import NumberFormat from "react-number-format";
import moment from "moment";
import { isMobile } from "react-device-detect";

const ApplyStakingModalRaw = ({ visible, onRequestClose, closeModal, product, onApplySuccess }) => {
  const stakingServices = StakingServices.getInstance();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const [errorAccountMsg, setErrorAccountMsg] = useState("");
  const [errorAmountMsg, setErrorAmountMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(visible);
  }, [visible]);

  const getAnnualInterestRateStr = () => {
    if (!isNaN(product?.annualInterestRate)) {
      return Big(product?.annualInterestRate).mul(100).toFixed(2) + "% APR";
    }

    return "";
  };

  const getTermStr = () => {
    if (product?.term && product?.frequency) {
      let term = Big(product?.term);

      if (product?.frequency?.toUpperCase() === INTEREST_RATE_FREQUENCY.DAILY) {
        if (term.gt(0)) {
          term = term + " Days";
        } else if (term.eq(1)) {
          term = term + " Day";
        }
      } else if (product?.frequency?.toUpperCase() === INTEREST_RATE_FREQUENCY.MONTHLY) {
        if (term.gt(0)) {
          term = term + " Months";
        } else if (term.eq(1)) {
          term = term + " Month";
        }
      } else if (product?.frequency?.toUpperCase() === INTEREST_RATE_FREQUENCY.YEARLY) {
        if (term.gt(0)) {
          term = term + " Years";
        } else if (term.eq(1)) {
          term = term + " Year";
        }
      }

      return term;
    }

    return "";
  };

  const getInterestRateFrequencyStr = () => {
    if (product?.frequency) {
      if (product?.frequency?.toUpperCase() === INTEREST_RATE_FREQUENCY.DAILY) {
        return "Daily";
      } else if (product?.frequency?.toUpperCase() === INTEREST_RATE_FREQUENCY.MONTHLY) {
        return "Monthly";
      } else if (product?.frequency?.toUpperCase() === INTEREST_RATE_FREQUENCY.YEARLY) {
        return "Yearly";
      }
    }

    return "";
  };

  const getRoomRemain = () => {
    if (!isNaN(product?.roomRemain)) {
      return formatAmount(product?.roomRemain, product?.currency) + " " + product?.currency;
    }

    return "";
  };

  const maxRoomLimit = () => {
    if (!isNaN(product?.roomLimit)) {
      return formatAmount(product?.roomLimit, product?.currency) + " " + product?.currency;
    }

    return "";
  };

  const individualRoomLimit = () => {
    if (!isNaN(product?.individualRoomLimit) && Big(product?.individualRoomLimit).gt(0)) {
      return formatAmount(product?.individualRoomLimit, product?.currency) + " " + product?.currency;
    }

    return "";
  };

  const maximumApplyNumbers = () => {
    if (!isNaN(product?.maximumApplyNumbers) && Big(product?.maximumApplyNumbers).gt(0)) {
      return Big(product?.maximumApplyNumbers).toFixed(0);
    }

    return "";
  };

  const userAppliedNumbers = () => {
    if (!isNaN(product?.userAppliedNumbers) && Big(product?.userAppliedNumbers).gt(0)) {
      return Big(product?.userAppliedNumbers).toFixed(0);
    }

    return "";
  };

  const getDepositAmount = () => {
    if (amount != "" && !isNaN(amount)) {
      return formatAmount(amount, product?.currency) + " " + product?.currency;
    }
    return "";
  };

  const getSelectedAccountStr = () => {
    if (selectedAccount != null) {
      let str = "";

      if (selectedAccount?.type) {
        str = selectedAccount?.type;
      }
      if (selectedAccount?.publicId) {
        str = str + (str != "" ? ":" : "") + selectedAccount?.publicId;
      }
      if (selectedAccount?.amount) {
        str = str + (str != "" ? " -- " : "") + formatAmount(selectedAccount?.amount, selectedAccount?.currency);
      }
      if (selectedAccount?.currency) {
        str = str + (str != "" ? " " : "") + selectedAccount?.currency;
      }

      return str;
    }

    return "";
  };

  const getEndDate = () => {
    return moment(product?.endAt).utc().format("YYYY/MM/DD");
  };

  const openConfirmModal = () => {
    let hasErrors = false;
    if (!selectedAccount) {
      setErrorAccountMsg("Please select an account");
      hasErrors = true;
    }

    if (isNaN(amount) || Number(amount <= 0)) {
      setErrorAmountMsg("Please enter a valid staking amount");
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    if (closeModal) {
      closeModal();
    }

    setConfirmVisible(true);
  };

  const applyStaking = async () => {
    try {
      let hasErrors = false;
      if (!selectedAccount) {
        setErrorAccountMsg("Please select an account");
        hasErrors = true;
      }

      if (isNaN(amount) || Number(amount <= 0)) {
        setErrorAmountMsg("Please enter a valid staking amount");
        hasErrors = true;
      }

      if (hasErrors) {
        return;
      }

      setConfirmLoading(true);
      const result = await stakingServices.applyStaking(product?.code, selectedAccount?.id, amount);
      if (result?.id) {
        showSuccess("Staking has been applied successfully!");

        setConfirmLoading(false);
        setConfirmVisible(false);

        setSelectedAccount(null);
        setAmount("");

        Emitter.emit(EmitterEvents.REFRESH_STAKING_LIST);

        if (closeModal) {
          closeModal();
        }

        if (onApplySuccess) {
          onApplySuccess();
        }
      }
    } catch (e) {
      if (e.includes("amount must be greater than or equal to 1")) {
        showError("Staking amount must be greater or equal to 1 AXC");
      } else {
        showError(e);
      }

      closeModal();

      setConfirmLoading(false);
    }
  };

  const resetErrors = () => {
    setErrorAmountMsg("");
    setErrorAccountMsg("");
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 1999
          },
          content: {
            margin: "auto",
            maxWidth: 500,
            maxHeight: 800,
            height: "100vh",
            overflow: "auto",
            backgroundColor: "transparent",
            border: "none",
            padding: "20px 0px 100px 0px"
          }
        }}
      >
        <Spin spinning={loading}>
          <div style={{ backgroundColor: "white", borderRadius: 20 }}>
            <div className="stakings__product-modal-header">
              <h1>{product?.name}</h1>
              <p>{product?.longDesc}</p>
            </div>

            {/* <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 0 }}>
              <p>
                Deposit to your account using the information below. You'll need to contact your bank for specific
                instructions.
              </p>
            </div> */}

            <div style={{ padding: 30, paddingBottom: 0 }}>
              {/* <Divider /> */}
              <KeyValue2 keyName={"Annual Percentage Rewards"} value={getAnnualInterestRateStr()} />
              <KeyValue2 keyName={"Term"} value={getTermStr()} />
              <KeyValue2 keyName={"Rewards Frequency"} value={getInterestRateFrequencyStr()} />
              {/* <KeyValue2 keyName={"Room Remaining"} value={getRoomRemain()} /> */}
              <KeyValue2 keyName={"Pool Closes On"} value={getEndDate()} />
              {individualRoomLimit() !== "" && (
                <KeyValue2 keyName={"Limit per Staking"} value={individualRoomLimit()} />
              )}
              {maximumApplyNumbers() !== "" && (
                <KeyValue2 keyName={"Maximum Apply Number"} value={maximumApplyNumbers()} />
              )}
              {userAppliedNumbers() !== "" && <KeyValue2 keyName={"Number of Holdings"} value={userAppliedNumbers()} />}
              <Divider />
              <p className="margin-none" style={{ fontWeight: "500", fontSize: 12, minWidth: 240, color: "#B1B5C3" }}>
                {"Select account"}
              </p>
              <AccountsSelector
                onAccountSelected={(account) => {
                  setSelectedAccount(account);
                  resetErrors();
                }}
                selectedAccount={selectedAccount}
                currency={product?.currency}
                placeholder={"Please select account to transfer from"}
              />
              {errorAccountMsg !== "" && (
                <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorAccountMsg}</p>
              )}

              <p
                className="margin-none"
                style={{ fontWeight: "500", fontSize: 12, minWidth: 240, marginTop: 10, color: "#B1B5C3" }}
              >
                {"Enter staking amount"}
              </p>
              <div style={{ display: "flex" }}>
                <NumberFormat
                  thousandSeparator={true}
                  value={amount}
                  placeholder={"0.00"}
                  decimalScale={getDecimalScale(product?.currency)}
                  prefix={getAmountSymbol(product?.currency)}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    setAmount(value);

                    resetErrors();
                  }}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "12px",
                    border: "2px solid #B1B5C4",
                    padding: "10px 20px",
                    maxHeight: 46,
                    marginRight: 10,
                    fontSize: 16
                  }}
                />
                <p
                  style={{
                    width: "100px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "12px",
                    border: "2px solid #B1B5C4",
                    padding: "10px 20px",
                    maxHeight: 46,
                    justifyContent: "center",
                    background: "#F8F9FA",
                    fontSize: 16,
                    marginBottom: 0
                  }}
                >
                  {product?.currency}
                </p>
              </div>
              {errorAmountMsg !== "" && (
                <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorAmountMsg}</p>
              )}
              <div></div>
              <Divider />
              <div
                className="row"
                style={{
                  paddingBottom: 20,
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: isMobile ? "column" : null
                }}
              >
                <div style={{ whiteSpace: "nowrap" }}>
                  <Checkbox checked={agreeTerms} onChange={() => setAgreeTerms(!agreeTerms)}>
                    Agree to{" "}
                    <a href="https://axiacapitalbank.com/axia-staking-program-terms-and-conditions" target="_blank">
                      terms and conditions
                    </a>
                  </Checkbox>
                </div>
                <div style={{ whiteSpace: "nowrap" }}>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #3b82f6",
                      borderRadius: 10,
                      color: "#3b82f6",
                      minWidth: "75px",
                      fontSize: "13px"
                    }}
                    onClick={() => {
                      setErrorAccountMsg("");
                      setErrorAmountMsg("");
                      setAmount("");
                      setSelectedAccount(null);
                      closeModal();
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    disabled={!agreeTerms}
                    style={{
                      borderRadius: 12,
                      marginLeft: 10,
                      minWidth: "75px",
                      fontSize: "13px"
                    }}
                    onClick={openConfirmModal}
                  >
                    Confirm staking
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal2
        title="Confirm information"
        visible={confirmVisible}
        onOk={applyStaking}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setConfirmVisible(false);
        }}
      >
        <KeyValue2 keyName="Staking information: " value={""} />
        <KeyValue4 keyName={"Annual Percentage Rewards"} value={getAnnualInterestRateStr()} />
        <KeyValue4 keyName={"Term"} value={getTermStr()} />
        <KeyValue4 keyName={"Rewards Frequency"} value={getInterestRateFrequencyStr()} />
        <Divider />
        <KeyValue2 keyName="Deduct account: " value={""} />
        <KeyValue4 keyName="Account" value={getSelectedAccountStr()} />
        <KeyValue4 keyName="Amount" value={getDepositAmount()} />
      </Modal2>
    </>
  );
};

export const ApplyStakingModal = boundError(ApplyStakingModalRaw);
