import React, { useState, useEffect, useContext, useRef } from "react";
import { boundError, showError } from "../../utils";
import { StakingsProductItem } from "./StakingsProductItem";
import Emitter, { EmitterEvents } from "../../utils/eventEmitter";
import StakingServices from "../../services/stakingServices";
import { StakingProductsContext } from "../../hooks";
import Big from "big.js";
import Icon from "@ant-design/icons";
import LeftButtonIcon from "../../resources/icons/staking/left_button.svg";
import RightButtonIcon from "../../resources/icons/staking/right_button.svg";

const StakingsProductsRowRaw = () => {
  const [products] = useContext(StakingProductsContext);
  const stakingServices = StakingServices.getInstance();

  const [currency, setCurrency] = useState("AXC");
  const [pageNum, setPageNum] = useState(0);
  const ref = useRef(null);
  const limit = 24;

  useEffect(() => {
    fetchProducts();
    Emitter.on(EmitterEvents.REFRESH_STAKING_LIST, () => {
      refreshData();
    });
  }, []);

  const refreshData = () => {
    setPageNum(0);
    fetchProducts();
  };

  const fetchProducts = async () => {
    try {
      stakingServices.getAvailableStakingProducts(currency, pageNum, limit);
    } catch (e) {
      showError(e);
    }
  };

  if (products.length === 0) {
    return (
      <div style={{ width: "100%", marginTop: 10 }}>
        <p>There's no available staking.</p>
      </div>
    );
  }

  const availableProducts = () => {
    return products.filter(
      (product) =>
        Big(product?.maximumApplyNumbers).eq(-1) ||
        (Big(product?.userAppliedNumbers).lt(product?.maximumApplyNumbers) && Big(product?.roomRemain).gt(0))
    );
  };

  const scroll = (scrollOffset) => {
    ref.current.scrollTo({
      left: ref.current.scrollLeft + scrollOffset,
      behavior: "smooth"
    });
  };

  return (
    <>
      <div className="row page-title" style={{ paddingBottom: 0, paddingLeft: 20 }}>
        {`Staking Pools`}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          fontSize: "30px"
        }}
      >
        <Icon
          component={() => <img src={LeftButtonIcon} />}
          onClick={() => {
            scroll(-150);
          }}
        />
        <Icon
          component={() => <img src={RightButtonIcon} />}
          style={{
            marginLeft: "10px"
          }}
          onClick={() => {
            scroll(150);
          }}
        />
      </div>
      <div className="stakings__product-row" ref={ref}>
        {availableProducts().length === 0 && (
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", width: "100%" }}>
            <p>No available stakings!</p>
          </div>
        )}
        {availableProducts().map((product) => {
          return <StakingsProductItem product={product} key={product?.code} />;
        })}
        {/* {products.length > 1 && (
        <div
          className="stakings__product-item"
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <p style={{ fontSize: 20, fontWeight: "600" }}>More</p>
        </div>
      )} */}
      </div>
    </>
  );
};

export const StakingsProductsRow = boundError(StakingsProductsRowRaw);
