import React, { useContext, useEffect, useState } from "react";
import { boundError, FEATURE_NAMES, isValidForFeature, showError, showSuccess } from "../utils";
import { ProfilePage, ProfilePageHead, SimpleButton, MFAHandler } from "../components";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import { UserContext } from "../hooks";
import { TRANSACTION_TYPE } from "../constants/transactions";
import UserServices from "../services/userServices";
import { Transaction2faModal } from "./Transaction2faModal";

const TwoFactorRaw = () => {
  const mfaEnabled = useSelector((state) => state.auth.mfaEnabled);
  const [showModal, setShowModal] = useState(false);
  const onButtonClick = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const [transaction2faModalVisible, setTransaction2faModalVisible] = useState(false);
  const [transaction2faRecordId, setTransaction2faRecordId] = useState(false);
  const [originReq, setOriginReq] = useState({ type: null, bool: false });
  const [user] = useContext(UserContext);
  const userServices = UserServices.getInstance();
  const transferBetweenMembersMfaEnabled =
    (user?.transaction2fa && user.transaction2fa[TRANSACTION_TYPE.USER_TRANSFER_BETWEEN_MEMBERS]?.enabled) || false;
  const wireTransferMfaEnabled =
    (user?.transaction2fa && user.transaction2fa[TRANSACTION_TYPE.WIRE_TRANSFER]?.enabled) || false;

  const enableTransaction2FA = async (type, bool, mfaCode = null) => {
    try {
      setOriginReq({ type, bool });
      const result = await userServices.updateUserSettings({
        transaction2fa: {
          [type]: { enabled: bool, method: "EMAIL" }
        },
        mfaCode,
        transaction2faRecordId
      });
      if (result?.statusCode === 207) {
        setTransaction2faRecordId(result?.data?.transaction2faRecordId);
        setTransaction2faModalVisible(true);
        return;
      }
      setTransaction2faModalVisible(false);
      await userServices.getUserProfile();
      showSuccess(`Two Factor Authentication has been ${bool ? "enabled" : "disabled"}`);
    } catch (err) {
      showError("Something went wrong", err);
    }
  };
  return (
    <>
      <ProfilePage>
        <ProfilePageHead title="Two Factor Authentication" />

        <div style={{ maxWidth: 300, textAlign: "left", display: "flex", flexDirection: "column" }}>
          <div className="row" style={{ justifyContent: "space-between", paddingTop: 20 }}>
            <p style={{ margin: 0 }}>Login</p>
            <SimpleButton
              style={{
                background: mfaEnabled ? "red" : "var(--axia-blue)",
                width: 110,
                height: 31,
                whiteSpace: "nowrap"
              }}
              onClick={onButtonClick}
            >
              {mfaEnabled ? "Disable" : "Enable"} 2FA
            </SimpleButton>
          </div>
          {isValidForFeature({ user, featureName: FEATURE_NAMES.TRANSACTION2FA }) && (
            <>
              <div className="row" style={{ justifyContent: "space-between", paddingTop: 20 }}>
                <p style={{ margin: 0 }}>Transfer Between Members</p>
                <SimpleButton
                  style={{
                    background: transferBetweenMembersMfaEnabled ? "red" : "var(--axia-blue)",
                    width: 110,
                    height: 31,
                    whiteSpace: "nowrap"
                  }}
                  onClick={() =>
                    enableTransaction2FA(
                      TRANSACTION_TYPE.USER_TRANSFER_BETWEEN_MEMBERS,
                      !transferBetweenMembersMfaEnabled
                    )
                  }
                >
                  {transferBetweenMembersMfaEnabled ? "Disable" : "Enable"} 2FA
                </SimpleButton>
              </div>
              <div className="row" style={{ justifyContent: "space-between", paddingTop: 20 }}>
                <p style={{ margin: 0 }}>Wire Transfer</p>
                <SimpleButton
                  style={{
                    background: wireTransferMfaEnabled ? "red" : "var(--axia-blue)",
                    width: 110,
                    height: 31,
                    whiteSpace: "nowrap"
                  }}
                  onClick={() => enableTransaction2FA(TRANSACTION_TYPE.WIRE_TRANSFER, !wireTransferMfaEnabled)}
                >
                  {wireTransferMfaEnabled ? "Disable" : "Enable"} 2FA
                </SimpleButton>
              </div>
            </>
          )}
        </div>
      </ProfilePage>
      <Modal
        title="Two factor authentication"
        visible={showModal}
        onCancel={closeModal}
        footer={null}
        centered
        destroyOnClose
        className="mfa-modal-container"
      >
        <MFAHandler mfaEnabled={mfaEnabled} onCancel={closeModal} />
      </Modal>
      <Transaction2faModal
        visible={transaction2faModalVisible}
        onOk={(mfaCode) => enableTransaction2FA(originReq.type, originReq.bool, mfaCode)}
        onCancel={() => {
          setTransaction2faModalVisible(false);
        }}
        email={user?.username}
      />
    </>
  );
};

export const TwoFactor = boundError(TwoFactorRaw);
