import React, { useEffect, useState } from "react";
import { Col, Divider, Row, Space, Typography } from "antd";
import { UserOutlined, FileDoneOutlined, AuditOutlined } from "@ant-design/icons";
import { OnboardingButton } from "./SharedComponents";
import { getUserKycSession } from "../../apis/kyc";

const { Title, Text } = Typography;

export const UserOnboardingMenu = ({ userRegistrationStatus }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const kycStatus = userRegistrationStatus?.userRegistrationData?.kycStatus;
  const kycReady = kycStatus === "SUBMITTED" || kycStatus === "COMPLETED";

  useEffect(async () => {
    if (!userRegistrationStatus || kycReady) {
      return;
    }

    try {
      setLoading(true);
      const kycSession = await getUserKycSession();
      const { refId } = kycSession;

      const blockpass = new window.BlockpassKYCConnect(process.env.REACT_APP_BLOCKPASS_CLIENT_ID, {
        env: "prod",
        local_user_id: refId,
        mainColor: "1c8fe1",
        email: userRegistrationStatus?.email
      });
      blockpass.startKYCConnect();
      blockpass.on("KYCConnectClose", reloadUserRegistrationStatus);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [userRegistrationStatus]);

  const disableButton = !!error || kycReady;

  return (
    <div style={{ padding: 15 }}>
      <Row justify="center">
        <Col sm={24} md={18}>
          <Title level={3}>Activate your account</Title>
          <Title level={5} type="secondary">
            Complete the following steps to finish your application
          </Title>
          <Divider />
          <Space direction="vertical" style={{ width: "100%" }}>
            <OnboardingButton
              id="blockpass-kyc-connect"
              title="Verify identity"
              subTitle="Verify your personal identity"
              icon={<UserOutlined style={{ fontSize: 35, position: "absolute", right: 15 }} />}
              status={userRegistrationStatus?.userRegistrationData?.kycStatus}
            />

            <OnboardingButton
              href="/onboarding/individual/tax-id"
              title="Verify personal tax ID"
              subTitle="Verify your tax identification"
              icon={<FileDoneOutlined style={{ fontSize: 35, position: "absolute", right: 15 }} />}
              status={userRegistrationStatus?.userRegistrationData?.taxIdStatus}
            />

            <OnboardingButton
              href="/onboarding/individual/other-information"
              title="Verify other information"
              subTitle="Declare other information"
              icon={<AuditOutlined style={{ fontSize: 35, position: "absolute", right: 15 }} />}
              status={userRegistrationStatus?.pepStatus}
            />
          </Space>
        </Col>
      </Row>
    </div>
  );
};
