import React, { useContext, useEffect, useRef } from "react";
import { Checkbox, Menu, Dropdown, Button, Divider } from "antd";
import { DownOutlined, RightOutlined, PrinterOutlined } from "@ant-design/icons";
import { StepMenu, ListCard, BackBtn, CancelBtn, SimpleButton } from "../components";
import { AccountListItem } from "../components/SharedComponents";
import {
  boundError,
  getAmountSymbol,
  getDecimalScale,
  formatAmount,
  showError,
  showSuccess,
  showWarning
} from "../utils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../hooks";
import NumberFormat from "react-number-format";
import { createWireDeposit, getBankSettings, getTransactionToAccountsApi } from "../apis";
import { TRANSACTION_TYPE } from "../constants";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import usdIcon from "../resources/images/flags/usd_icon.svg";
import parse from "html-react-parser";
import { ACCOUNT_STATUS } from "../constants/accountStatus";
import { BANK_SETTINGS } from "../constants/bankSettings";

const WireDepositRaw = () => {
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  const [accountList, setAccountList] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [availableCurrencies, setAvailableCurrencies] = useState([{ name: "US Dollar", code: "USD" }]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [selectedAccount, setSelectedAccount] = useState({});
  const [sourceOfFund, setSourceOfFund] = useState("Select Source of Funds");
  const [sourceOfFundOther, setSourceOfFundOther] = useState("");
  const [sourceOfFundConfirmed, setSourceOfFundConfirmed] = useState(false);
  const [sourceOfFundFilled, setSourceOfFundFilled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [wireDepositInfo, setWireDepositInfo] = useState(null);

  const printRef = useRef();

  const btnHeight = 40;

  useEffect(() => {
    const fetchWireDepositInfo = async () => {
      const wireSettings = await getBankSettings(BANK_SETTINGS.WIRE_DEPOSIT_INFO);

      if (!Object.keys(wireSettings).length) {
        showWarning("There was an error fetching Bank Wire Deposit Information");
      }
      setWireDepositInfo(wireSettings[BANK_SETTINGS.WIRE_DEPOSIT_INFO]);

      return wireSettings;
    };

    fetchWireDepositInfo();
  }, []);

  const fetchToAccount = async (currency) => {
    const data = await getTransactionToAccountsApi(TRANSACTION_TYPE.WIRE_DEPOSIT, currency);
    setAccountList(data.accounts);
  };

  useEffect(() => {
    switch (sourceOfFund) {
      case "Select Source of Funds":
        setSourceOfFundFilled(false);
        break;
      case "Other":
        setSourceOfFundFilled(sourceOfFundOther.trim() !== "");
        break;
      default:
        setSourceOfFundFilled(true);
    }
  }, [sourceOfFund, sourceOfFundOther]);

  useEffect(() => {
    if (depositAmount === "" || depositAmount >= 10000 || depositAmount <= 0) {
      setNextDisabled(!sourceOfFundFilled || !sourceOfFundConfirmed);
    } else {
      setNextDisabled(false);
    }
  }, [depositAmount, sourceOfFundFilled, sourceOfFundConfirmed]);

  const handleDropdownClick = (e) => {
    setSourceOfFund(e.key);
  };

  const menu = (
    <Menu onClick={handleDropdownClick}>
      <Menu.Item key="Select Source of Funds">Select Source of Funds</Menu.Item>
      <Menu.Item key="Personal Investment">Personal Investment</Menu.Item>
      <Menu.Item key="Income">Income</Menu.Item>
      <Menu.Item key="Gift">Gift</Menu.Item>
      <Menu.Item key="Inheritance">Inheritance</Menu.Item>
      <Menu.Item key="Other">Other</Menu.Item>
    </Menu>
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });
  const pageStyle = `@page { margin: 5rem  4rem  5rem  4rem !important; }`;

  const TitleDescription = ({ title, desc }) => {
    return (
      <p className="margin-none">
        <span style={{ fontWeight: 600, color: "#178FE1" }}>{title}</span>
        {desc}
      </p>
    );
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div
            className="between-account step-body"
            style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
          >
            <p
              className="margin-none"
              style={{
                display: "flex",
                lineHeight: "1.75rem",
                marginBottom: "1rem",
                fontWeight: 500,
                fontSize: "16px",
                color: "#23262F",
                textAlign: "left"
              }}
            >
              Select the currency you want to deposit
            </p>

            {availableCurrencies.map((currency) => {
              return (
                <ListCard
                  key={`availableCurrencies-${currency.code}`}
                  children={
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start"
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <img src={usdIcon} alt="" style={{ height: 30, width: 30, marginRight: "1rem" }} />
                        <p className="margin-none">{`${currency.name} - ${currency.code}`}</p>
                      </div>
                    </div>
                  }
                  tailComponent={
                    <RightOutlined style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }} />
                  }
                  onClick={() => {
                    setSelectedCurrency(currency.code);
                    fetchToAccount(currency.code);
                    setCurrentStep((prev) => prev + 1);
                  }}
                />
              );
            })}
          </div>
        );
      case 1:
        return (
          <>
            <div className="between-account step-body">
              <div style={{ display: "flex", flexDirection: "column", margin: "auto", width: "100%" }}>
                <p
                  className="margin-none"
                  style={{
                    display: "flex",
                    lineHeight: "1.75rem",
                    marginBottom: "1rem",
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "#23262F",
                    textAlign: "left"
                  }}
                >
                  Select the account to deposit funds into
                </p>
                {accountList.map((account) => {
                  const isActive = account.status === ACCOUNT_STATUS.ACTIVE;

                  return (
                    <AccountListItem
                      style={isActive ? {} : { backgroundColor: "lightGray", cursor: "not-allowed" }}
                      key={`accountList-${account.publicId}`}
                      account={account}
                      onClick={
                        isActive
                          ? () => {
                              setSelectedAccount(account);
                              setCurrentStep((prev) => prev + 1);
                            }
                          : () => {}
                      }
                    />
                  );
                })}
              </div>
            </div>
            <Divider />
            <CancelBtn toPath="/deposit" style={{ height: btnHeight }} />
          </>
        );

      case 2:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <h2
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  textAlign: "left",
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Enter the amount you want to deposit
              </h2>

              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <NumberFormat
                  decimalScale={getDecimalScale(selectedCurrency)}
                  thousandSeparator={true}
                  value={depositAmount}
                  placeholder="$ 0.00"
                  prefix={getAmountSymbol(selectedCurrency)}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    setDepositAmount(value);
                  }}
                  style={{
                    width: "90%",
                    fontSize: 30,
                    fontWeight: "normal",
                    paddingLeft: "1.5rem",
                    borderRadius: "12px",
                    border: "2px solid #E6E8EC",
                    marginRight: "2px",
                    flex: 1,
                    paddingTop: 10,
                    paddingBottom: 10
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0rem 1rem 0rem 1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "12px",
                    border: "2px solid #E6E8EC",
                    marginLeft: 10,
                    width: 100
                  }}
                >
                  <p className="margin-none" style={{ fontSize: "1.25rem", fontWeight: 600 }}>
                    {selectedCurrency}
                  </p>
                </div>
              </div>

              {depositAmount >= 10000 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "2rem",
                    paddingLeft: "1rem",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    backgroundColor: "#F8F9FA",
                    borderRadius: "12px",
                    marginTop: 20,
                    padding: 30
                  }}
                >
                  <div style={{ display: "block", flexDirection: "row", width: "100%" }}>
                    <p className="margin-none" style={{ display: "flex", fontWeight: 600, fontSize: "1.1rem" }}>
                      Source of Funds Declaration
                    </p>
                    <p
                      className="margin-none"
                      style={{
                        display: "flex",
                        textAlign: "left",
                        fontSize: "1rem",
                        marginBottom: "1rem",
                        marginTop: 10
                      }}
                    >
                      As your deposit is at least $10000 USD, AXIA Capital Bank is required to request that you complete
                      this Source of Funds Declaration.
                    </p>
                  </div>

                  <div style={{ display: "block", width: "100%", marginBottom: "1.5rem" }}>
                    <p className="margin-none" style={{ display: "flex", fontWeight: 500, fontSize: "0.9rem" }}>
                      I declare that the source of funds is from
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <Button
                        style={{
                          display: "flex",
                          borderRadius: 12,
                          width: "100%",
                          height: "4rem",
                          fontSize: "1rem",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderColor: "#B1B5C4",
                          borderWidth: 2
                        }}
                      >
                        {sourceOfFund} <DownOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                  {sourceOfFund === "Other" && (
                    <div style={{ display: "block", width: "100%", marginBottom: "1.5rem" }}>
                      <p className="margin-none" style={{ display: "flex", fontWeight: 500, fontSize: "1rem" }}>
                        Other fund <span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        value={sourceOfFundOther}
                        placeholder={"Please specify the source of fund"}
                        onChange={(e) => {
                          setSourceOfFundOther(e.target.value);
                        }}
                        style={{
                          borderRadius: 8,
                          border: "1px solid #d9d9d9",
                          width: "100%",
                          paddingLeft: "1rem",
                          height: "4rem",
                          fontSize: "1rem"
                        }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: "grid",
                      gap: "1rem",
                      width: "100%",
                      margin: "1rem 0",
                      gridTemplateColumns: "repeat(2,1fr)"
                    }}
                  >
                    <div
                      style={{
                        padding: "20px",
                        border: "2px solid #dddddd",
                        boxSizing: "border-box",
                        borderRadius: "12px",
                        textAlign: "left"
                      }}
                    >
                      <p className="margin-none" style={{ fontSize: "1rem", color: "#777E91" }}>
                        Signature of Member
                      </p>
                      <p
                        className="margin-none"
                        style={{ fontSize: "1rem", fontWeight: 600 }}
                      >{`${user?.firstName} ${user?.lastName}`}</p>
                    </div>
                    <div
                      style={{
                        padding: "20px",
                        border: "2px solid #dddddd",
                        boxSizing: "border-box",
                        borderRadius: "12px",
                        textAlign: "left"
                      }}
                    >
                      <p className="margin-none" style={{ fontSize: "1rem", color: "#777E91" }}>
                        Date
                      </p>
                      <p className="margin-none" style={{ fontSize: "1rem", fontWeight: 600 }}>
                        {moment().format("YYYY/MM/DD")}
                      </p>
                    </div>
                  </div>
                  <Checkbox
                    checked={sourceOfFundConfirmed}
                    onChange={(e) => setSourceOfFundConfirmed(e.target.checked)}
                    style={{ fontSize: "1rem", marginTop: "1rem", textAlign: "left" }}
                  >
                    I hereby accept the Source of Funds requirement as a condition of processing my deposit.
                  </Checkbox>
                </div>
              )}
            </div>
            <Divider />
            <div
              class="between-account step-body"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <CancelBtn
                toPath="/deposit"
                style={{
                  height: btnHeight,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  borderRadius: 12,
                  width: 300
                }}
              />
              <SimpleButton
                style={{
                  padding: "0.5rem 0.75rem 0.5rem 0.75rem",
                  borderRadius: 12,
                  width: 300,
                  margin: "auto",
                  height: btnHeight,
                  border: `2px solid ${nextDisabled ? "#aaaaaa" : "#178FE1"}`
                }}
                disabled={nextDisabled}
                onClick={async () => {
                  setCurrentStep((prev) => prev + 1);
                }}
                children={
                  <p className="margin-none" style={{ color: "white", fontSize: "1rem", fontWeight: 500 }}>
                    Continue
                  </p>
                }
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div
              className="between-account step-body"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                textAlign: "left",
                fontSize: "1rem"
              }}
              ref={printRef}
            >
              <style> {pageStyle} </style> {/* STYLE FOR PRINTING, DO NOT DELETE */}
              <h2 className={"section-title"}>Wire deposit instructions</h2>
              <p style={{ fontSize: "1rem" }}>
                Deposit to your account using the information below. You’ll need to contact your bank for specific
                instructions.
              </p>
              <p style={{ fontSize: "1rem" }}>
                Be sure to include the “Reference” code given below - this code identifies the deposit with your
                account.
              </p>
              <p style={{ fontSize: "1rem" }}>
                Important: The name on the bank account you are depositing from must match the name entered for
                verification on the AXIA Capital Bank account you are depositing into.
              </p>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              <h2 className={"section-title"}>Banking details:</h2>
              <div className="banking-details">
                <div className="box">
                  <p className="margin-none" style={{ fontWeight: 500, color: "#777E90" }}>
                    Bank Name
                  </p>
                  <p className="margin-none">{wireDepositInfo?.bankName}</p>
                </div>
                <div className="box">
                  <p className="margin-none" style={{ fontWeight: 500, color: "#777E90" }}>
                    Account Name
                  </p>
                  <p className="margin-none">{wireDepositInfo?.bankAccountName}</p>
                </div>
                <div className="box">
                  <p className="margin-none" style={{ fontWeight: 500, color: "#777E90" }}>
                    Account Number
                  </p>
                  <p className="margin-none">{wireDepositInfo?.bankAccountNumber}</p>
                </div>
                <div className="box">
                  <p className="margin-none" style={{ fontWeight: 500, color: "#777E90" }}>
                    ABA
                  </p>
                  <p className="margin-none">{wireDepositInfo?.bankAba}</p>
                </div>
              </div>
              <Divider style={{ marginTop: 30, marginBottom: 30 }} />
              <p className="margin-none wire-info">
                {wireDepositInfo?.wireDepositInformation ? parse(wireDepositInfo?.wireDepositInformation) : null}
              </p>
              <Divider />
              <p className="margin-none" style={{ fontSize: "1.5rem" }}>
                <span style={{ fontWeight: 600 }}>Reference: </span>
                {selectedAccount.publicId}
              </p>
              <p className="margin-none">
                <span style={{ fontWeight: 600, color: "red" }}>IMPORTANT: </span>
                This code identifies your deposit with your account. If we can not identify a deposit the payment will
                be refunded ASAP.
              </p>
              <p className="margin-none" style={{ fontSize: "1.5rem", marginTop: "1.5rem" }}>
                <span style={{ fontWeight: 600 }}>Amount to send: </span>
                {`${formatAmount(depositAmount, selectedCurrency)} ${selectedCurrency}`}
              </p>
            </div>

            <Divider />

            <div
              className="between-account step-body"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem"
              }}
            >
              <CancelBtn toPath="/deposit" style={{ borderRadius: 12, height: btnHeight, width: "300px" }} />
              <SimpleButton
                style={{
                  backgroundColor: "#35B994",
                  padding: "0.5rem 0.75rem 0.5rem 0.75rem",
                  borderRadius: 12,
                  width: "300px"
                }}
                // onClick={async () => {
                //   window.print();
                // }}
                onClick={handlePrint}
                children={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <p className="margin-none" style={{ color: "white", fontSize: "1rem", fontWeight: 500 }}>
                      Print
                    </p>
                    <PrinterOutlined style={{ color: "white", paddingLeft: "5px", fontSize: "1.25rem" }} />
                  </div>
                }
              />
              <SimpleButton
                style={{
                  padding: "0.5rem 0.75rem 0.5rem 0.75rem",
                  width: "300px",
                  borderRadius: 12,
                  height: btnHeight,
                  whiteSpace: "nowrap"
                }}
                onClick={async () => {
                  try {
                    let sourceOfFundFinal = "";
                    if (sourceOfFund === "Other") {
                      sourceOfFundFinal = sourceOfFundOther.trim();
                    } else if (sourceOfFund !== "Select Source of Funds") {
                      sourceOfFundFinal = sourceOfFund;
                    }
                    const result = await createWireDeposit({
                      toAccountId: selectedAccount.id,
                      amountUsd: depositAmount,
                      sourceOfFund: sourceOfFundFinal
                    });

                    if (result?.statusCode === 200 || result?.status === 200) {
                      showSuccess("Wire deposit request sent successfully!");
                      navigate("/deposit");
                    } else {
                      if (data?.message) throw new Error(data?.message);
                      throw new Error("Unknown error!");
                    }
                  } catch (err) {
                    showError(err);
                  }
                }}
                children={
                  <p className="margin-none" style={{ color: "white", fontSize: "1rem", fontWeight: 500 }}>
                    Submit
                    <RightOutlined style={{ color: "white", paddingLeft: "5px" }} />
                  </p>
                }
              />
            </div>
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "85%", margin: "40px auto" }}>
      <BackBtn
        onClick={() => {
          if (currentStep > 0) {
            if (currentStep === 1) {
              setSelectedCurrency("");
            } else if (currentStep === 2) {
              setDepositAmount("");
              setSourceOfFund("Select Source of Funds");
              setSourceOfFundOther("");
              setSourceOfFundConfirmed(false);
            }
            setCurrentStep((prev) => prev - 1);
          } else {
            navigate("/deposit");
          }
        }}
      />
      <div className="row page-title" style={{ paddingTop: 0 }}>
        Deposit
      </div>
      <div className="card-apply-container" style={{ width: "100%" }}>
        <StepMenu steps={["Select currency", "Select account", "Select amount", "Deposit"]} currentStep={currentStep} />
        <Divider />
        {renderStep()}
      </div>
    </div>
  );
};

export const WireDeposit = boundError(WireDepositRaw);
