import React, { useState, useEffect, useRef } from "react";
import { Button } from "antd";
import { PrinterOutlined, DeleteOutlined } from "@ant-design/icons";
import { boundError, formatAmount, showError, showSuccess, showWarning } from "../../utils";
import Modal from "react-modal";
import { KeyValue1, KeyValue2, KeyValue3 } from "../SharedComponents";
import { getExternalTransactionApi, cancelWireDeposit, getBankSettings } from "../../apis";
import moment from "moment";
import { TRANSACTION_TYPE, TRANSACTION_DIRECTION } from "../../constants";
import { BANK_SETTINGS } from "../../constants/bankSettings";
import { useReactToPrint } from "react-to-print";
import parse from "html-react-parser";
import { useQuery } from "../../hooks";

const AccountsPendingTransactionRaw = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(true);
  const printRef = useRef();
  const [wireDepositInfo, setWireDepositInfo] = useState(null);

  const query = useQuery();
  const transactionId = query.get("transactionId");
  const initModalHasShown = useRef(false);
  useEffect(() => {
    autoDisplayTransaction(transactionId);
  }, [transactions]);

  useEffect(() => {
    fetchTransactions();
    fetchWireDepositInfo();
  }, []);

  const autoDisplayTransaction = async (transactionId) => {
    if (transactionId && transactions && transactions.length > 0 && !initModalHasShown.current) {
      const item = transactions.find((trx) => trx?.id === transactionId);

      if (!item) {
        return;
      }

      setModalData(item);
      setModalOpened(true);
      initModalHasShown.current = true;
    }
  };

  const fetchTransactions = async ({} = {}) => {
    const data = await getExternalTransactionApi({
      statusIn: ["PENDING_REVIEW"],
      methodIn: [
        TRANSACTION_TYPE.WIRE_DEPOSIT,
        TRANSACTION_TYPE.WIRE_TRANSFER,
        TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_WITHDRAWAL,
        TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_V2_WITHDRAWAL
      ],
      pagination: false,
      generateTitleAndDescription: true
    });
    setTransactions(data.data);
    setLoading(false);
  };

  const fetchWireDepositInfo = async () => {
    const wireSettings = await getBankSettings(BANK_SETTINGS.WIRE_DEPOSIT_INFO);

    if (!Object.keys(wireSettings).length) {
      showWarning("There was an error fetching Bank Wire Deposit Information");
    }
    setWireDepositInfo(wireSettings[BANK_SETTINGS.WIRE_DEPOSIT_INFO]);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });
  const pageStyle = `@page { margin: 5rem  4rem  5rem  4rem !important; }`;

  const TransactionItem = ({ item, lastItem }) => {
    return (
      <div
        style={lastItem ? { borderBottom: 0 } : null}
        className="accounts-transaction-item-container cursor-pointer"
        key={item.id}
        onClick={() => {
          setModalData(item);
          setModalOpened(true);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "start" }}>
          <p className="padding-none margin-none" style={{ color: "#A3A3A3", textAlign: "left" }}>
            {item && moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
          </p>
          <p className="padding-none margin-none" style={{ fontSize: 18, fontWeight: "500", textAlign: "left" }}>
            {item?.title}
          </p>
          <p className="padding-none margin-none" style={{ color: "#A3A3A3", textAlign: "left" }}>
            {item?.description}
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <p
            style={{
              fontSize: 20,
              fontWeight: "500",
              marginTop: 5,
              marginBottom: 5,
              color: item?.type === TRANSACTION_DIRECTION.DEPOSIT ? "#53E085" : "red"
            }}
          >
            {`${item?.type === TRANSACTION_DIRECTION.DEPOSIT ? "+" : "-"} ${formatAmount(
              item.providerAmount,
              item.providerCurrency
            )}`}
            <span style={{ fontSize: 12, fontWeight: "500", marginLeft: 5 }}>{item?.providerCurrency}</span>
          </p>
        </div>
      </div>
    );
  };

  const renderWireDepositModal = () => {
    return (
      <div style={{ marginTop: 20, marginBottom: 100, backgroundColor: "white", borderRadius: 10 }}>
        <div style={{ padding: 30 }} ref={printRef}>
          <style>{pageStyle}</style> {/* STYLE FOR PRINTING, DO NOT DELETE */}
          <div>
            <h1>Wire Transfer Deposit</h1>
            <p>
              Deposit to your account using the information below. You'll need to contact your bank for specific
              instructions.
            </p>
            <p>
              Be sure to include the "Reference" code given below - this code identifies the deposit with your account.
              Don't exceed your daily or monthly funding limits. If you exceed the limits, your account will be frozen
              until you get verified for higher limits, or until the funds are returned to you. Other banks used during
              the transfer may charge additional fees and are out of our control.{" "}
            </p>
            <p>
              Multiple deposits of the same amounts within a few days of each other may be delayed. If you must make
              multiple deposits, consider sending different amounts. Important: The name on the bank account you are
              depositing from must match the name entered for verification on the AXIA Capital Bank account you are
              depositing into.
            </p>
          </div>
          <div>
            <h1>Banking details:</h1>
            <div className="account-transaction-item-modal-grid">
              <KeyValue1 keyName={"Bank Name"} value={wireDepositInfo?.bankName} />
              <KeyValue1 keyName={"Account Name"} value={wireDepositInfo?.bankAccountName} />
              <KeyValue1 keyName={"Account Number"} value={wireDepositInfo?.bankAccountNumber} />
              <KeyValue1 keyName={"ABA"} value={wireDepositInfo?.bankAba} />
            </div>
          </div>
          {/* page break for printing, DO NOT DELETE */}
          <div style={{ pageBreakBefore: "always", display: "block" }} />
          <div style={{ marginTop: 20 }}>
            <p className="margin-none wire-info">
              {wireDepositInfo?.wireDepositInformation ? parse(wireDepositInfo?.wireDepositInformation) : null}
            </p>
          </div>
          {/* page break for printing, DO NOT DELETE */}
          <div style={{ pageBreakBefore: "always", display: "block" }} />
          <div style={{ marginTop: 40 }}>
            <KeyValue2 keyName="Reference" value={modalData.accountPublicId} />
            <p style={{ marginTop: 20, fontSize: "1rem" }}>
              IMPORTANT: This code identifies your deposit with your account. If we can not identify a deposit the
              payment will be refunded ASAP.
            </p>
            <KeyValue2
              keyName="Amount to send"
              value={`${formatAmount(modalData.providerAmount, modalData.providerCurrency)} ${
                modalData.providerCurrency
              }`}
            />
          </div>
        </div>

        <div
          className="row"
          style={{ marginTop: 20, alignItems: "center", padding: 20, justifyContent: "space-between" }}
        >
          <div>
            <Button
              type="primary"
              style={{
                borderRadius: 5,
                color: "white",
                backgroundColor: "red",
                border: "none",
                marginRight: 10,
                width: 115,
                display: "flex",
                justifyContent: "center"
              }}
              onClick={async () => {
                try {
                  const result = await cancelWireDeposit(modalData.id);

                  if (result?.statusCode === 200 || result?.status === 200) {
                    showSuccess("Wire deposit request canceled!");
                    fetchTransactions();
                    closeModal();
                  } else {
                    if (data?.message) throw new Error(data?.message);
                    throw new Error("Unknown error!");
                  }
                } catch (err) {
                  showError(err);
                }
              }}
            >
              Cancel deposit
            </Button>
          </div>
          <div className="row">
            <Button
              type="primary"
              // icon={<PrinterOutlined />}
              style={{ borderRadius: 5, color: "white" }}
              onClick={handlePrint}
            >
              Print
            </Button>

            <Button type="primary" style={{ borderRadius: 5, marginLeft: 10 }} onClick={closeModal}>
              Close
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderWireTransferModal = () => {
    if (!modalOpened || !modalData || modalData === {}) {
      return;
    }
    const alias = modalData?.providerMetaData?.beneficiaryName;
    const {
      currency,
      beneficiaryBankName,
      beneficiaryBankAddress,
      beneficiaryBankSwiftOrABA,
      beneficiaryName,
      beneficiaryAddress,
      beneficiaryIBAN,
      intermediaryBankName,
      intermediaryBankAddress,
      intermediaryBankSwiftOrABA,
      instructionsAndReference
    } = modalData.providerMetaData.extraData;

    return (
      <div style={{ marginTop: 20, marginBottom: 100, backgroundColor: "white", borderRadius: 10 }}>
        <div style={{ padding: 30 }}>
          <div>
            <h1>Wire Transfer</h1>
            <p style={{ fontSize: "1rem" }}>
              Wire transfer will be sent based on the information below, please contact Admin as soon as possible if you
              found any information is missing or incorrect.
            </p>
          </div>

          <h2 style={{ fontWeight: "600", marginTop: "2rem" }}>Beneficiary Details:</h2>
          <div>
            <KeyValue2 keyName="Currency" value={currency} />
            <KeyValue2 keyName="Alias" value={alias} />
          </div>
          <div style={{ marginTop: "1rem" }}>
            <KeyValue2 keyName="Bank Name" value={beneficiaryBankName} />
            <KeyValue2 keyName="Bank Address" value={beneficiaryBankAddress} />
            <KeyValue2 keyName="Bank ABA / SWIFT / BIC" value={beneficiaryBankSwiftOrABA} />
          </div>
          <div style={{ marginTop: "1rem" }}>
            <KeyValue2 keyName="Beneficiary Name" value={beneficiaryName} />
            <KeyValue2 keyName="Beneficiary Address" value={beneficiaryAddress} />
            <KeyValue2 keyName="IBAN / Account Number" value={beneficiaryIBAN} />
          </div>

          {(intermediaryBankName || intermediaryBankAddress || intermediaryBankSwiftOrABA) && (
            <div style={{ marginTop: "1rem" }}>
              {intermediaryBankName && <KeyValue2 keyName="Intermediary Bank Name" value={intermediaryBankName} />}
              {intermediaryBankAddress && (
                <KeyValue2 keyName="Intermediary Bank Address" value={intermediaryBankAddress} />
              )}
              {intermediaryBankSwiftOrABA && (
                <KeyValue2 keyName="Bank ABA / SWIFT / BIC" value={intermediaryBankSwiftOrABA} />
              )}
            </div>
          )}

          {instructionsAndReference && (
            <div style={{ marginTop: "1rem" }}>
              <KeyValue2 keyName="Reference" value={instructionsAndReference} />
            </div>
          )}

          <div style={{ marginTop: "1rem" }}>
            <KeyValue2
              keyName="User Memo"
              value={modalData.userMemo || modalData.userMemo !== "" ? modalData.userMemo : "N/A"}
            />
            <KeyValue2
              keyName="Amount sent"
              value={`${formatAmount(modalData.providerAmount, modalData.providerCurrency)} ${
                modalData.providerCurrency
              }`}
            />
            <KeyValue2
              keyName="Service Fee"
              value={`${formatAmount(modalData.providerFeeAmount, modalData.providerFeeCurrency)} ${
                modalData.providerFeeCurrency
              }`}
            />
          </div>
        </div>

        <div className="row" style={{ marginTop: 20, alignItems: "center", padding: 20 }}>
          <div style={{ flex: 1 }}>
            {/* <Button type="text" icon={<DeleteOutlined />} style={{ borderRadius: 5, color: "red" }}>
          Cancel deposit
        </Button> */}
          </div>
          <div className="row">
            {/* <Button type="primary" icon={<PrinterOutlined />} style={{ borderRadius: 5 }}>
              Print
            </Button> */}
            <Button type="primary" style={{ borderRadius: 5, marginLeft: 20 }} onClick={closeModal}>
              Close
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderAxiaNetworkTransferModal = (defaultTitle = `AXIA Network Wallet Transfer`) => {
    if (!modalOpened || !modalData || modalData === {}) {
      return;
    }
    const { toAddress } = modalData.providerMetaData;
    const title = modalData.title ?? defaultTitle;

    return (
      <div style={{ marginTop: 20, marginBottom: 100, backgroundColor: "white", borderRadius: 10 }}>
        <div style={{ padding: 30 }}>
          <div>
            <h1>{title}</h1>
            <p style={{ fontSize: "1rem" }}>
              {title} will be sent based on the information below, please contact Admin as soon as possible if you found
              any information is missing or incorrect.
            </p>
          </div>

          <div style={{ marginTop: "1rem" }}>
            <KeyValue2 keyName="Recipient Wallet Address" value={toAddress} />
            <KeyValue2
              keyName="Amount sent"
              value={`${formatAmount(modalData.providerAmount, modalData.providerCurrency)} ${
                modalData.providerCurrency
              }`}
            />
            {modalData.status && <KeyValue2 keyName=" Transaction Status" value={modalData.status.replace("_", " ")} />}{" "}
            <KeyValue2 keyName="Date Created" value={moment(modalData.createdAt).format("YYYY-MM-DD HH:mm:ss")} />
            <KeyValue2 keyName="Last updated" value={moment(modalData.updatedAt).format("YYYY-MM-DD HH:mm:ss")} />
            {modalData.userMemo && modalData.userMemo !== "" && (
              <KeyValue2 keyName="User Memo" value={modalData.userMemo} />
            )}
          </div>
        </div>

        <div className="row" style={{ marginTop: 20, alignItems: "center", padding: 20 }}>
          <div style={{ flex: 1 }}>
            {/* <Button type="text" icon={<DeleteOutlined />} style={{ borderRadius: 5, color: "red" }}>
          Cancel deposit
        </Button> */}
          </div>
          <div className="row">
            <Button type="primary" style={{ borderRadius: 5, marginLeft: 20 }} onClick={closeModal}>
              Close
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderModalByTransactionMethod = () => {
    switch (modalData?.method) {
      case TRANSACTION_TYPE.WIRE_DEPOSIT:
        return renderWireDepositModal();
      case TRANSACTION_TYPE.WIRE_TRANSFER:
        return renderWireTransferModal();
      case TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_WITHDRAWAL:
        return renderAxiaNetworkTransferModal(`AXIA Network Wallet Transfer`);
      case TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_V2_WITHDRAWAL:
        return renderAxiaNetworkTransferModal(`AXIA Network (V2) Wallet Transfer`);
    }
  };

  const closeModal = () => {
    setModalOpened(false);
    setModalData({});
  };

  if (!loading && transactions && transactions.length > 0) {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div className="row" style={{ margin: 0, padding: 0, paddingLeft: 30, width: "100%" }}>
          <p style={{ fontSize: 20, fontWeight: "500", margin: "0px 0px 10px 10px", padding: 0 }}>
            Transaction in Review
          </p>
        </div>
        <div style={{ width: "100%" }}>
          <div className="accounts-transaction-box">
            {transactions.map((item, index) => {
              return (
                <div key={index}>
                  <TransactionItem item={item} lastItem={index == transactions.length - 1} />
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ height: 20 }} />
        <Modal
          isOpen={modalOpened}
          onRequestClose={closeModal}
          ariaHideApp={false}
          style={{
            overlay: {
              zIndex: 9999,
              backgroundColor: "rgba(0, 0, 0, 0.3)"
            },
            content: {
              margin: "auto",
              maxWidth: 1000,
              height: "100vh",
              overflow: "auto",
              padding: 0,
              backgroundColor: "transparent",
              border: "none"
            }
          }}
        >
          {renderModalByTransactionMethod()}
        </Modal>
      </div>
    );
  }
  return <></>;
};

export const AccountsPendingTransaction = boundError(AccountsPendingTransactionRaw);
