import { bankApi } from "./authenticatedAxios";
import { fileDownloadResponseHandler, responseDataHandler } from "./responseHandler";
import { concatApiQuery } from "../utils/strings";

export const getBankSettings = (settingName) =>
  bankApi.get("/bankSettings", { params: { settingName } }).then(({ data }) => responseDataHandler(data));

export const getTermsConditions = () => bankApi.get("/termsConditions").then(({ data }) => responseDataHandler(data));

export const getUserProfileApi = () => bankApi.get("/user").then(({ data }) => responseDataHandler(data));

export const getUserSettingsApi = () => bankApi.get("/user/setting").then(({ data }) => responseDataHandler(data));

export const updateUserSettingsApi = (settings) =>
  bankApi.post("/user/setting", settings).then(({ data }) => responseDataHandler(data));

export const updateUserApi = ({
  requestId = null,
  email = null,
  phoneCountryCode = null,
  phoneNumber = null,
  mfaCode = null
}) =>
  bankApi
    .post("/user", { requestId, email, phoneCountryCode, phoneNumber, mfaCode })
    .then(({ data }) => responseDataHandler(data));

export const getUserAccountsApi = () => bankApi.get("/account").then(({ data }) => responseDataHandler(data));

export const getTransactionFromAccountsApi = ({
  transactionType,
  displayCurrency,
  validAmount,
  validAmountCurrency,
  validSpendableAmount,
  validSpendableAmountCurrency
}) =>
  bankApi
    .get(
      concatApiQuery("/account/allow-transfer-from", {
        transactionType,
        displayCurrency,
        validAmount,
        validAmountCurrency,
        validSpendableAmount,
        validSpendableAmountCurrency
      })
    )
    .then(({ data }) => responseDataHandler(data));

export const getTransactionToAccountsApi = (transactionType, currency) =>
  bankApi
    .get(concatApiQuery(`/account/allow-transfer-to`, { transactionType, currency }))
    .then(({ data }) => responseDataHandler(data));

export const getUserTransactionsApi = ({
  id,
  accountId,
  typeIn,
  createdAtGte,
  createdAtLte,
  transactionDirection,
  limit,
  offset,
  includeTotalCount,
  generateTitleAndDescription
}) => {
  const params = {
    id,
    accountId,
    typeIn,
    createdAtGte,
    createdAtLte,
    transactionDirection,
    limit,
    offset,
    includeTotalCount,
    generateTitleAndDescription
  };
  return bankApi.get("/account/transaction", { params }).then(({ data }) => responseDataHandler(data));
};

export const getExternalTransactionApi = ({
  id,
  accountId,
  status,
  statusIn,
  methodIn,
  limit,
  offset,
  includeTotalCount,
  pagination,

  generateTitleAndDescription
}) => {
  const params = {
    id,
    accountId,
    status,
    statusIn,
    methodIn,
    limit,
    offset,
    includeTotalCount,
    pagination,

    generateTitleAndDescription
  };
  return bankApi.get("/transfer/external", { params }).then(({ data }) => responseDataHandler(data));
};

export const getERC20AXCWalletsApi = () =>
  bankApi.get("/deposit/erc20AXC/userWalletBalance").then(({ data }) => responseDataHandler(data));

// TODO: request error, network fail, etc
export const getUserRegistrationDataApi = () =>
  bankApi.get("/user/registration").then(({ data }) => responseDataHandler(data));

export const updateSelfPepStatus = (pepStatus) =>
  bankApi.post("/user/registration", { pepStatus }).then(({ data }) => responseDataHandler(data));

export const updateSelfPepStatusAfterRegistration = (pepStatus) =>
  bankApi.post("/user/pep/registration", { pepStatus }).then(({ data }) => responseDataHandler(data));

export const updateUserType = (userType) =>
  bankApi.post("/user/registration", { userType }).then(({ data }) => responseDataHandler(data));

export const updateTaxId = (taxId, taxIdCountryCode, submit = false) =>
  bankApi
    .post("/user/registration", { taxId: { taxId, taxIdCountryCode, submit } })
    .then(({ data }) => responseDataHandler(data));

export const updateBusinessRecords = ({
  businessName,
  businessWebsite,
  businessRegistrationNumber,
  businessDateOfIncorporation,
  businessCountryCode,
  businessEntity,
  businessMonthlyTransferVolume,
  businessMonthlyPaymentNumber,
  businessMaximumSinglePayment,
  businessDirectors,
  submit = false
}) =>
  bankApi
    .post("/user/registration", {
      businessRecords: {
        businessName,
        businessWebsite,
        businessRegistrationNumber,
        businessDateOfIncorporation,
        businessCountryCode,
        businessEntity,
        businessMonthlyTransferVolume,
        businessMonthlyPaymentNumber,
        businessMaximumSinglePayment,
        businessDirectors,
        submit
      },
      userType: "BUSINESS"
    })
    .then(({ data }) => responseDataHandler(data));

export const updateBusinessAddress = ({
  businessAddressLine1,
  businessAddressLine2,
  businessAddressCity,
  businessAddressState,
  businessAddressCountryCode,
  businessAddressPostalCode,
  businessTradingAddressLine1,
  businessTradingAddressLine2,
  businessTradingAddressCity,
  businessTradingAddressState,
  businessTradingAddressCountryCode,
  businessTradingAddressPostalCode,
  submit = false
}) =>
  bankApi
    .post("/user/registration", {
      businessAddress: {
        businessAddressLine1,
        businessAddressLine2,
        businessAddressCity,
        businessAddressState,
        businessAddressCountryCode,
        businessAddressPostalCode,
        businessTradingAddressLine1,
        businessTradingAddressLine2,
        businessTradingAddressCity,
        businessTradingAddressState,
        businessTradingAddressCountryCode,
        businessTradingAddressPostalCode,
        submit
      },
      userType: "BUSINESS"
    })
    .then(({ data }) => responseDataHandler(data));

const uploadRegistrationDocumentApi = async (file, documentType) => {
  const fd = new FormData();
  fd.append("documentType", documentType);
  fd.append("file", file);
  return bankApi.post("/user/registration/document", fd).then(({ data }) => responseDataHandler(data));
};

const downloadRegistrationDocument = (documentType) =>
  bankApi
    .get(`/user/registration/document/${documentType}`, {
      responseType: "blob"
    })
    .then((response) => fileDownloadResponseHandler(response, documentType));

export const uploadProofOfTaxReturn = (file) => uploadRegistrationDocumentApi(file, "proof_of_tax_return");
export const uploadProofOfTaxId = (file) => uploadRegistrationDocumentApi(file, "proof_of_tax_id");
export const uploadProofOfIncorporateCertificate = (file) =>
  uploadRegistrationDocumentApi(file, "business_proof_of_incorporate_certificate");
export const uploadProofOfAddress = (file) => uploadRegistrationDocumentApi(file, "business_proof_of_address");

export const downloadProofOfTaxReturn = () => downloadRegistrationDocument("proof_of_tax_return");
export const downloadProofOfTaxId = () => downloadRegistrationDocument("proof_of_tax_id");
export const downloadProofOfIncorporateCertificate = () =>
  downloadRegistrationDocument("business_proof_of_incorporate_certificate");
export const downloadProofOfAddress = () => downloadRegistrationDocument("business_proof_of_address");

export const fetchBeneficiariesApi = (type) =>
  bankApi.get(concatApiQuery(`/beneficiary`, { type })).then(({ data }) => responseDataHandler(data));

export const createBeneficiariesApi = (name, email, type) =>
  bankApi
    .post("/beneficiary/add", {
      name,
      email,
      type
    })
    .then(({ data }) => responseDataHandler(data));

export const updateBeneficiariesApi = (id, beneficiaryName, beneficiaryEmail, type, updateData) =>
  bankApi
    .put("/beneficiary/update", {
      id,
      beneficiaryName,
      beneficiaryEmail,
      type,
      updateData
    })
    .then(({ data }) => responseDataHandler(data));

export const createWireTransferBeneficiaryApi = ({
  alias,
  currency,
  beneficiaryBankName,
  beneficiaryBankAddress,
  beneficiaryBankSwiftOrABA,
  beneficiaryName,
  beneficiaryAddress,
  beneficiaryIBAN,
  intermediaryBankName,
  intermediaryBankAddress,
  intermediaryBankSwiftOrABA,
  instructionsAndReference
}) =>
  bankApi
    .post("beneficiary/wire-transfer/add", {
      alias,
      currency,
      beneficiaryBankName,
      beneficiaryBankAddress,
      beneficiaryBankSwiftOrABA,
      beneficiaryName,
      beneficiaryAddress,
      beneficiaryIBAN,
      intermediaryBankName,
      intermediaryBankAddress,
      intermediaryBankSwiftOrABA,
      instructionsAndReference
    })
    .then(({ data }) => responseDataHandler(data));

export const removeBeneficiaryApi = (id) =>
  bankApi.delete(`/beneficiary/${id}`).then(({ data }) => responseDataHandler(data));

export const getAvailableBusinessEntities = (countryCode) =>
  bankApi
    .get(concatApiQuery("/user/registration/businessEntities", { countryCode }))
    .then(({ data }) => responseDataHandler(data));

export const getAccountOverview = () =>
  bankApi.get(concatApiQuery("/account/overview")).then(({ data }) => responseDataHandler(data));

export const agreeUserConsent = ({ consentFormName }) =>
  bankApi
    .post(concatApiQuery("/user/agreeUserConsent"), { consentFormName })
    .then(({ data }) => responseDataHandler(data));

export const getUserConsentUrl = () =>
  bankApi.get("/user/getUserConsent").then(({ data }) => responseDataHandler(data));

export const getUserSettings = () => bankApi.get("/user/settings").then(({ data }) => responseDataHandler(data));

export const getTaxIdExemptCountriesApi = () =>
  bankApi.get("/utils/getTaxIdExemptCountries").then(({ data }) => responseDataHandler(data));

export const getTotalAssetApi = () => bankApi.get("/user/totalAsset").then(({ data }) => responseDataHandler(data));

export const getUserSpendableApi = () => bankApi.get("/user/spendable").then(({ data }) => responseDataHandler(data));
