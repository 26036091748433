import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { boundError } from "../utils";

function PublicOutletRaw() {
  const isAuthenticated = useSelector((state) => state.auth.idToken);
  const location = useLocation();
  if (isAuthenticated) {
    let destPath = location?.state?.from?.pathname || "/";
    return <Navigate to={destPath} />;
  }
  return <Outlet />;
}

export const PublicOutlet = boundError(PublicOutletRaw);
