import React, { useEffect, useState, useContext } from "react";
import { Button, Divider } from "antd";
import { AccountsTransaction, AccountsPendingTransaction, BackBtn, CopyButton } from "../components";
import { StarOutlined, EditOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { CURRENCY } from "../constants/currency";
import { boundError, isLocal, formatAmount } from "../utils";
import UserServices from "../services/userServices";
import { AccountsContext } from "../hooks";
import Big from "big.js";

const AccountDetailRaw = (props) => {
  const userServices = UserServices.getInstance();
  const [allAccounts] = useContext(AccountsContext);
  const { id } = useParams();
  const [account, setAccount] = useState({});
  const [accountAlias, setAccountAlias] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    userServices.fetchUserAccounts();
  }, [id]);

  useEffect(() => {
    allAccounts.forEach((item) => {
      if (item.id === id) {
        setAccount(item);
      }
    });
  }, [allAccounts]);

  useEffect(() => {
    setAccountAlias(account.alias ?? account.typePublicName);
  }, [account]);

  const accountBalanceCard = () => {
    const mainColor = "black";
    return (
      <div className="account-detail-account-balance-card">
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <p style={{ color: mainColor, fontSize: 15, fontWeight: "500", marginBottom: 10, opacity: 0.7 }}>
            Account balance
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <p style={{ color: mainColor, fontSize: 22, fontWeight: "500", margin: 0, opacity: 0.7 }}>
            {`${formatAmount(account?.amount, account?.currency)} `}
            <span style={{ fontSize: 12, fontWeight: "500" }}>{account.currency}</span>
          </p>
        </div>
      </div>
    );
  };

  const accountDetailCard = () => {
    const mainColor = "black";
    return (
      <div className="account-detail-account-detail-card">
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <p style={{ color: mainColor, fontSize: 16, fontWeight: "500", marginBottom: 10, opacity: 0.7 }}>
            Account detail
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
          <p
            className="margin-none"
            style={{ color: mainColor, fontSize: 18, fontWeight: "500", margin: 0, opacity: 0.7, textAlign: "left" }}
          >
            Account Number:{" "}
            <span style={{ fontWeight: "500", margin: 0, marginRight: "0.5rem", whiteSpace: "nowrap" }}>
              {account.publicId}
            </span>
          </p>
          <CopyButton text={account.publicId} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
          <p
            className="margin-none"
            style={{ color: mainColor, fontSize: 18, fontWeight: "500", margin: 0, opacity: 0.7 }}
          >
            Rewards Rate:{" "}
            <span style={{ fontWeight: "500", margin: 0, marginRight: "0.5rem" }}>
              {`${new Big(account.interestRate ?? 0).times(new Big(100)).toFixed(2)}%`}
            </span>
          </p>
        </div>
        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
          <p
            className="margin-none"
            style={{ color: mainColor, fontSize: 16, fontWeight: "500", margin: 0, opacity: 0.7 }}
          >
            0x16826384feaea7adb39d6404b1b184eb70d4b6f6
          </p>
          <CopyButton text={account.publicId} />
        </div> */}
      </div>
    );
  };

  return (
    <div className="page">
      {/* <div className="column" style={{ width: "100%" }}> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginTop: "40px",
          padding: "0 40px"
        }}
      >
        <div
          className="row"
          style={{
            width: "100%",
            alignItems: "center",
            whiteSpace: "nowrap"
          }}
        >
          <BackBtn text={"Back to overview"} previousPath={"/accounts"} />
          <div className="row">
            {isLocal() && <StarOutlined style={{ fontSize: 24 }} />}
            {isLocal() && (
              <Button type="primary" icon={<EditOutlined />} style={{ borderRadius: 5, marginLeft: 20 }}>
                Edit Account
              </Button>
            )}
            {!isLocal() && <div style={{ minWidth: "154px" }}></div>}
          </div>
        </div>
        <p className="account-detail-account-alias-text">{accountAlias}</p>
      </div>

      <div className="accounts-mid-cards-row">
        {accountBalanceCard()}
        {accountDetailCard()}
      </div>
      <div style={{ width: "100%" }}>
        <AccountsTransaction account={account} />
      </div>
    </div>
  );
};

export const AccountDetail = boundError(AccountDetailRaw);
