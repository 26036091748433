import React, { useEffect, useRef } from "react";
import { boundError } from "../utils";
import KycVerifyImg from "../resources/images/img_kyc_verify.png";
import LOGOImg from "../resources/images/logo.svg";
import { Button, Typography } from "antd";
// import { getUserKycSession } from "../apis/kyc";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

const PublicKycVerifyPageRaw = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const trigger = searchParams.get("trigger");
  const email = searchParams.get("email");
  const refId = searchParams.get("refId");
  const inputElement = useRef();

  useEffect(async () => {
    try {
      const blockpass = new window.BlockpassKYCConnect(process.env.REACT_APP_BLOCKPASS_CLIENT_ID, {
        env: "prod",
        local_user_id: refId ?? "",
        mainColor: "1c8fe1",
        email: email ?? ""
      });
      blockpass.startKYCConnect();
      // blockpass.on("KYCConnectClose", reloadUserRegistrationStatus);
    } catch (err) {
      console.log(err);
    }

    if (trigger === "1") {
      setTimeout(() => {
        inputElement?.current?.click();
      }, 1000);
    }
  }, []);

  return (
    <div
      className="ant-modal-mask"
      style={{
        background: "rgb(231, 243, 254)"
      }}
    >
      <div
        style={{
          margin: "10%",
          background: "white",
          borderRadius: 20,
          padding: 30,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img src={LOGOImg} alt="" style={{ maxWidth: 300 }} />
        <p
          style={{
            fontWeight: 500,
            fontSize: 30
          }}
        >
          Verify User Identity
        </p>
        <img src={KycVerifyImg} alt="" style={{ maxWidth: 250 }} />
        <Button
          block
          ref={inputElement}
          id={"blockpass-kyc-connect"}
          style={{
            height: 50,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#178FE2",
            marginTop: 50,
            width: "100%",
            maxWidth: 300
          }}
        >
          <Text type="secondary" style={{ color: "white", fontSize: 18, fontWeight: "500" }}>
            {"Verify with Blockpass"}
          </Text>
        </Button>
      </div>
    </div>
  );
};

export const PublicKycVerifyPage = boundError(PublicKycVerifyPageRaw);
