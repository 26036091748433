import React, { useState } from "react";
import { HomeOutlined, StarOutlined, WalletOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import usFlag from "../../resources/images/flags/flag_us_circle.png";
import { AccountsMidCard } from "./AccountsMidCard";
import { StakingCard } from "./StakingCard";
import { boundError } from "../../utils";

const AccountsMidRaw = ({ accounts }) => {
  const navigate = useNavigate();
  const selectedBtnStyle = {
    backgroundColor: "#178FE1",
    color: "white",
    border: "none",
    borderRadius: 12
  };
  const unSelectedBtnStyle = {
    backgroundColor: "white",
    color: "#178FE1",
    border: "none",
    borderRadius: 12,
    boxShadow: "none"
  };

  //   let selectedBtn = "All";

  const [selectedBtn, setSelectedBtn] = useState("All");

  const isSelected = (btnText) => {
    return selectedBtn === btnText;
  };

  const selectBtn = (btnText) => {
    setSelectedBtn(btnText);
  };

  const renderCard = (selectedBtn) => {
    if (selectedBtn === "Favorites") {
      return (
        <div className="accounts-mid-cards-row">
          {accounts.map((account) => {
            return <AccountsMidCard account={account} key={account.publicId} />;
          })}
        </div>
      );
    } else if (selectedBtn === "Fiat") {
      return (
        <div className="accounts-mid-cards-row">
          {accounts.map((account) => {
            if (account.currencyType === "FIAT") {
              return <AccountsMidCard account={account} key={account.publicId} />;
            }
          })}
        </div>
      );
    } else if (selectedBtn === "Digital") {
      return (
        <div className="accounts-mid-cards-row">
          {accounts.map((account) => {
            if (account.currencyType === "CRYPTO") {
              return <AccountsMidCard account={account} key={account.publicId} />;
            }
          })}
          <StakingCard />
        </div>
      );
    } else {
      return (
        <div className="accounts-mid-cards-row">
          {accounts.map((account) => {
            return <AccountsMidCard account={account} key={account.publicId} />;
          })}
          <StakingCard />
        </div>
      );
    }
  };

  return (
    <div className="account-section-container">
      <div className="row page-title">Accounts</div>
      <div className="accounts-mid-buttons-row">
        {/* <Button
          type="primary"
          icon={<StarOutlined />}
          style={isSelected("Favorites") ? selectedBtnStyle : unSelectedBtnStyle}
          onClick={() => selectBtn("Favorites")}
        >
          Favorites
        </Button> */}
        <Button
          type="primary"
          style={isSelected("Fiat") ? selectedBtnStyle : unSelectedBtnStyle}
          onClick={() => selectBtn("Fiat")}
        >
          Fiat
        </Button>
        <Button
          type="primary"
          style={isSelected("Digital") ? selectedBtnStyle : unSelectedBtnStyle}
          onClick={() => selectBtn("Digital")}
        >
          Digital
        </Button>
        <Button
          type="primary"
          style={isSelected("All") ? selectedBtnStyle : unSelectedBtnStyle}
          onClick={() => selectBtn("All")}
        >
          All
        </Button>
      </div>
      {renderCard(selectedBtn)}
    </div>
  );
};

export const AccountsMid = boundError(AccountsMidRaw);
