import React, { useEffect, useState } from "react";
import { boundError } from "../utils";
import { Select, Input, Button } from "antd";
import { DropDownSelector } from ".";
import { CURRENCY_TYPES } from "../constants/currency";

const WireTransferFormRaw = ({
  inputsValue,
  setBeneficiaryCurrency,
  setNewBeneficiary,
  validationErrors,
  setNewValidationErrors
}) => {
  const supportedCurrency = [
    // { name: "Axia Coin", code: "AXC", type: CURRENCY_TYPES.CRYPTO },
    // { name: "Bitcoin", code: "BTC", type: CURRENCY_TYPES.CRYPTO },
    // { name: "Ethereum", code: "ETH", type: CURRENCY_TYPES.CRYPTO },
    // { name: "Swedish Krona", code: "SEK", type: CURRENCY_TYPES.FIAT },
    // { name: "Euro", code: "EUR", type: CURRENCY_TYPES.FIAT },
    // { name: "Indonesian Rupiah", code: "IDR", type: CURRENCY_TYPES.FIAT },
    { name: "US Dollar", code: "USD", type: CURRENCY_TYPES.FIAT }
    // { name: "UK Sterling", code: "GBP", type: CURRENCY_TYPES.FIAT }
  ];

  return (
    <div style={{ fontSize: "1rem" }}>
      <div className="beneficiary-content-container">
        <div className="content-box">
          <p>Beneficiary currency</p>
          <DropDownSelector
            options={supportedCurrency}
            onChange={(e) => {
              setNewValidationErrors((old) => ({ ...old, currency: "" }));
              setBeneficiaryCurrency(e);
            }}
            settings={{ defaultValue: inputsValue.currency || "USD" }}
          />
          {validationErrors?.currency && <p style={{ color: "red" }}>{validationErrors?.currency}</p>}
        </div>
        <div className="content-box">
          <p className="margin-none">Alias</p>
          <Input
            placeholder=""
            value={inputsValue.alias}
            onChange={(e) => {
              setNewValidationErrors((old) => ({ ...old, alias: "" }));
              setNewBeneficiary((prevState) => ({ ...prevState, alias: e.target.value }));
            }}
          />
          {validationErrors?.alias && <p style={{ color: "red" }}>{validationErrors?.alias}</p>}
        </div>
      </div>

      <div className="beneficiary-content-container">
        <div className="content-box">
          <h3 style={{ marginTop: "1rem", fontWeight: 600, fontSize: "15px" }}>Beneficiary Bank / Receiving Bank</h3>
          <p className="margin-none">Beneficiary Bank Name</p>
          <Input
            placeholder=""
            value={inputsValue.beneficiaryBankName}
            onChange={(e) => {
              setNewValidationErrors((old) => ({ ...old, beneficiaryBankName: "" }));
              setNewBeneficiary((prevState) => ({ ...prevState, beneficiaryBankName: e.target.value }));
            }}
          />
          {validationErrors?.beneficiaryBankName && (
            <p style={{ color: "red" }}>{validationErrors?.beneficiaryBankName}</p>
          )}
          <p className="margin-none">Beneficiary Bank Address</p>
          <Input
            placeholder=""
            value={inputsValue.beneficiaryBankAddress}
            onChange={(e) => {
              setNewValidationErrors((old) => ({ ...old, beneficiaryBankAddress: "" }));
              setNewBeneficiary((prevState) => ({ ...prevState, beneficiaryBankAddress: e.target.value }));
            }}
          />
          {validationErrors?.beneficiaryBankAddress && (
            <p style={{ color: "red" }}>{validationErrors?.beneficiaryBankAddress}</p>
          )}
          <p className="margin-none">Bank ABA / SWIFT / BIC</p>
          <Input
            placeholder=""
            value={inputsValue.beneficiaryBankSwiftOrABA}
            onChange={(e) => {
              setNewValidationErrors((old) => ({ ...old, beneficiaryBankSwiftOrABA: "" }));
              setNewBeneficiary((prevState) => ({ ...prevState, beneficiaryBankSwiftOrABA: e.target.value }));
            }}
          />
          {validationErrors?.beneficiaryBankSwiftOrABA && (
            <p style={{ color: "red" }}>{validationErrors?.beneficiaryBankSwiftOrABA}</p>
          )}
          <p className="margin-none">Beneficiary Name</p>
          <Input
            placeholder=""
            value={inputsValue.beneficiaryName}
            onChange={(e) => {
              setNewValidationErrors((old) => ({ ...old, beneficiaryName: "" }));
              setNewBeneficiary((prevState) => ({ ...prevState, beneficiaryName: e.target.value }));
            }}
          />
          {validationErrors?.beneficiaryName && <p style={{ color: "red" }}>{validationErrors?.beneficiaryName}</p>}
          <p className="margin-none">Beneficiary Address</p>
          <Input
            placeholder=""
            value={inputsValue.beneficiaryAddress}
            onChange={(e) => {
              setNewValidationErrors((old) => ({ ...old, beneficiaryAddress: "" }));
              setNewBeneficiary((prevState) => ({ ...prevState, beneficiaryAddress: e.target.value }));
            }}
          />
          {validationErrors?.beneficiaryAddress && (
            <p style={{ color: "red" }}>{validationErrors?.beneficiaryAddress}</p>
          )}
          <p className="margin-none">Beneficiary IBAN / Account Number</p>
          <Input
            placeholder=""
            value={inputsValue.beneficiaryIBAN}
            onChange={(e) => {
              setNewValidationErrors((old) => ({ ...old, beneficiaryIBAN: "" }));
              setNewBeneficiary((prevState) => ({ ...prevState, beneficiaryIBAN: e.target.value }));
            }}
          />
          {validationErrors?.beneficiaryIBAN && <p style={{ color: "red" }}>{validationErrors?.beneficiaryIBAN}</p>}
        </div>
        <div className="content-box">
          <h3 style={{ marginTop: "1rem", fontWeight: 600, fontSize: "15px" }}>
            Intermediary Bank / Correspondent Bank (if applicable)
          </h3>
          <p className="margin-none">Intermediary Bank Name</p>
          <Input
            placeholder=""
            value={inputsValue.intermediaryBankName}
            onChange={(e) => setNewBeneficiary((prevState) => ({ ...prevState, intermediaryBankName: e.target.value }))}
          />
          <p className="margin-none">Intermediary Bank Address</p>
          <Input
            placeholder=""
            value={inputsValue.intermediaryBankAddress}
            onChange={(e) =>
              setNewBeneficiary((prevState) => ({ ...prevState, intermediaryBankAddress: e.target.value }))
            }
          />
          <p className="margin-none">Intermediary Bank ABA / SWIFT / BIC</p>
          <Input
            placeholder=""
            value={inputsValue.intermediaryBankSwiftOrABA}
            onChange={(e) =>
              setNewBeneficiary((prevState) => ({ ...prevState, intermediaryBankSwiftOrABA: e.target.value }))
            }
          />
        </div>
      </div>

      <h3 style={{ marginTop: "1rem", fontWeight: 600, fontSize: "15px" }}>Special Instructions / Reference</h3>
      {/* <p className="margin-none">Instructions / Reference</p> */}
      <Input
        placeholder=""
        value={inputsValue.instructionsAndReference}
        onChange={(e) => setNewBeneficiary((prevState) => ({ ...prevState, instructionsAndReference: e.target.value }))}
      />
    </div>
  );
};

export const WireTransferForm = boundError(WireTransferFormRaw);
