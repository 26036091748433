import React from "react";
import { boundError } from "../../../utils";
import { AccountCard } from "../../AccountCard";
import { DownOutlined, RightOutlined, PrinterOutlined } from "@ant-design/icons";

const AccountListItemRaw = ({ onClick, account, style = {} }) => {
  return (
    <div
      className="list-card"
      style={{ ...style }}
      onClick={() => {
        onClick();
      }}
    >
      <div className="account-list-item-flex" style={{ position: "relative", width: "100%", display: "flex" }}>{AccountCard(account)}</div>
      <div style={{ position: "absolute", right: "20px" }}>
        {<RightOutlined style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }} />}
      </div>
    </div>
  );
};

export const AccountListItem = boundError(AccountListItemRaw);
