import React, { useState, useEffect, useContext } from "react";
import { Input, Modal as Modal2, Button, Checkbox, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import Icon, {
  RightOutlined,
  PlusOutlined,
  MinusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import EditPenIcon from "../resources/icons/transfer/edit_pen.svg";
import {
  StepMenu,
  ListCard,
  CutOffLine,
  BackBtn,
  SimpleButton,
  CancelBtn,
  WireTransferForm,
  AccountCard
} from "../components";
import Big from "big.js";
import { boundError, formatAmount, showError, showSuccess } from "../utils";
import UserServices from "../services/userServices";
import { BENEFICIARY_TYPE } from "../constants/beneficiaryType";
import { sendWireTransferApi, getTransactionFromAccountsApi } from "../apis";
import BulletIcon from "../resources/icons/transfer/bullet.svg";
import { TRANSACTION_TYPE } from "../constants";
const { TextArea } = Input;
import { CURRENCY } from "../constants";
import { AmountInput } from "../components/SharedComponents/AmountInput";
import { NextBtn } from "../components/SharedComponents/Button/NextBtn";
import { ShouldArriveBox } from "../components/SharedComponents/shouldArriveBox";
import { FeeRow } from "../components/SharedComponents/feeRow";
import { ACCOUNT_STATUS } from "../constants/accountStatus";
import { USER_STATUS } from "../constants/userStatus";
import { Transaction2faModal } from "./Transaction2faModal";
import { useSelector } from "react-redux";
import TransferAndDepositServices from "../services/transferAndDepositServices";

const WireTransferRaw = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const userServices = UserServices.getInstance();
  const transferAndDepositServices = TransferAndDepositServices.getInstance();
  const [accountList, setAccountList] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [newBeneficiary, setNewBeneficiary] = useState({});
  const [userMemo, setUserMemo] = useState("");
  const [beneficiaryCurrency, setBeneficiaryCurrency] = useState("USD");
  const [selectedSource, setSelectedSource] = useState({});
  const [selectedBeneficiary, setSelectedBeneficiary] = useState({});
  const [amount, setAmount] = useState("");
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [fee, setFee] = useState("0");
  const [estimateReceivedAmount, setEstimateReceivedAmount] = useState("0");
  const [isEditable, setIsEditable] = useState(false);
  const [isOnDelBeneficiary, setIsOnDelBeneficiary] = useState(false);
  const [editBeneficiary, setEditBeneficiary] = useState({});
  const [updateEditBeneficiary, setUpdateEditBeneficiary] = useState({});
  const [isOnAddBeneficiary, setIsOnAddBeneficiary] = useState(false);
  const [transaction2faModalVisible, setTransaction2faModalVisible] = useState(false);
  const [transaction2faRecordId, setTransaction2faRecordId] = useState();
  const [transferType, setTransferType] = useState();
  const [spendableAmountInUsd, setSpendableAmountInUsd] = useState();

  const minTransferAmount = new Big(100);
  const feeRow = [
    {
      fee: `${formatAmount(minTransferAmount, selectedSource.currency)} ${selectedSource.currency}`,
      type: "Minimum Transfer amount"
    },
    {
      fee: `${Number(transferType?.feeRate && transferType.feeRate !== "" ? transferType.feeRate : 0) * 100} %`,
      type: "Transfer Fee Rate"
    },
    {
      fee: `${formatAmount(transferType?.minFee, selectedSource.currency)} ${selectedSource.currency}`,
      type: "Minimum Transfer Fee"
    },
    ,
    {
      fee:
        transferType?.maxFee && transferType?.maxFee !== ""
          ? `${formatAmount(transferType?.maxFee, selectedSource.currency)} ${selectedSource.currency}`
          : "N/A",
      type: " Maximum Transfer Fee"
    },
    {
      fee: `${formatAmount(fee, selectedSource.currency)} ${selectedSource.currency}`,
      type: "Transfer Fee"
    },
    {
      fee: "1.0000",
      type: "Conversion Rate"
    }
  ];

  useEffect(() => {
    fetchToAccount();
    fetchBeneficiaries();
    fetchTransferType();
    fetchUserSpendable();
  }, []);

  const fetchTransferType = async () => {
    try {
      const result = await transferAndDepositServices.getInternalTransactionTypes(TRANSACTION_TYPE.WIRE_TRANSFER);

      if (result && result.length > 0) {
        setTransferType(result[0]);
      }
    } catch (err) {
      showError(err);
    }
  };

  const fetchToAccount = async () => {
    const data = await getTransactionFromAccountsApi({
      transactionType: TRANSACTION_TYPE.WIRE_TRANSFER,
      displayCurrency: CURRENCY.USD.code
    });
    setAccountList(data.accounts);
  };

  const fetchUserSpendable = async () => {
    const data = await userServices.getUserSpendableAmount();
    setSpendableAmountInUsd(data.spendableAmountInUsd);
  };

  const calculateFeeAmount = () => {
    const transferAmount = new Big(amount === "" ? 0 : amount);
    let feeRate = new Big(transferType?.feeRate ?? 0);
    let transactionFee = transferAmount.times(feeRate);
    if (transferType?.maxFee && transferType?.maxFee !== "") {
      const maxFee = new Big(transferType?.maxFee);
      transactionFee = transactionFee.lt(maxFee) ? transactionFee : maxFee;
    }
    if (transferType?.minFee && transferType?.minFee !== "") {
      const minFee = new Big(transferType?.minFee);
      transactionFee = transactionFee.gt(minFee) ? transactionFee : minFee;
    }

    let amountMinusFee = transferAmount.minus(transactionFee);
    amountMinusFee = amountMinusFee.lt(new Big(0)) ? "0" : amountMinusFee.toFixed();

    setEstimateReceivedAmount(amountMinusFee);
    setFee(transactionFee.toFixed());
  };

  useEffect(() => {
    calculateFeeAmount();
    setNextDisabled(
      amount === "" || !isEnoughBalance() || new Big(amount).lt(minTransferAmount) || spendableAmountInUsd.lt(amount)
    );
  }, [amount]);

  const closeModal = () => {
    setNewBeneficiary({});
    setValidationErrors(null);
    setModalOpened(false);
    setIsOnDelBeneficiary(false);
    setIsOnAddBeneficiary(false);
  };

  const fetchBeneficiaries = async () => {
    const result = await userServices.fetchBeneficiaries(BENEFICIARY_TYPE.WIRE_TRANSFER.code);
    if (result?.data) {
      setBeneficiaryList(result?.data);
    }
  };

  const isFieldValid = (data) => {
    if (!data?.currency) {
      setValidationErrors((old) => ({ ...old, currency: "The Beneficiary currency field is required" }));
    }
    if (!data?.beneficiaryBankName) {
      setValidationErrors((old) => ({ ...old, beneficiaryBankName: "The Beneficiary Bank Name field is required" }));
    }
    if (!data?.beneficiaryBankAddress) {
      setValidationErrors((old) => ({
        ...old,
        beneficiaryBankAddress: "The Beneficiary Bank Address field is required"
      }));
    }
    if (!data?.beneficiaryBankSwiftOrABA) {
      setValidationErrors((old) => ({
        ...old,
        beneficiaryBankSwiftOrABA: "The Bank ABA / SWIFT / BIC field is required"
      }));
    }
    if (!data?.beneficiaryName) {
      setValidationErrors((old) => ({ ...old, beneficiaryName: "The Beneficiary Name field is required" }));
    }
    if (!data?.beneficiaryAddress) {
      setValidationErrors((old) => ({ ...old, beneficiaryAddress: "The Beneficiary Address field is required" }));
    }
    if (!data?.beneficiaryIBAN) {
      setValidationErrors((old) => ({
        ...old,
        beneficiaryIBAN: "The Beneficiary IBAN / Account Number field is required"
      }));
    }
    if (!data?.alias) {
      setValidationErrors((old) => ({ ...old, alias: "The Alias field is required" }));
    }
    if (
      !data?.currency ||
      !data?.beneficiaryBankName ||
      !data?.beneficiaryBankAddress ||
      !data?.beneficiaryBankSwiftOrABA ||
      !data?.beneficiaryName ||
      !data?.beneficiaryAddress ||
      !data?.beneficiaryIBAN ||
      !data?.alias
    ) {
      return false;
    }
    return true;
  };

  const createBeneficiary = async (data) => {
    if (!isFieldValid(data)) {
      return;
    }
    const requestData = {};
    for (var prop in data) {
      if (data.hasOwnProperty(prop)) {
        requestData[prop] = data[prop].trim();
      }
    }
    try {
      await userServices.createWireTransferBeneficiary(requestData);
      fetchBeneficiaries();
      showSuccess("Recipient has been created.");
      closeModal();
    } catch (e) {
      showError(e);
    }
  };

  const updateBeneficiary = async (data) => {
    if (!isFieldValid(data)) {
      return;
    }
    const requestData = {};
    for (var prop in data) {
      if (data.hasOwnProperty(prop)) {
        requestData[prop] = data[prop].trim();
      }
    }
    const { alias, id, ...extraData } = requestData;
    try {
      await userServices.updateBeneficiary(
        id,
        undefined,
        requestData.beneficiaryEmail,
        BENEFICIARY_TYPE.WIRE_TRANSFER.code,
        {
          type: BENEFICIARY_TYPE.WIRE_TRANSFER.code,
          beneficiaryName: alias,
          extraData,
          name: alias
        }
      );
      fetchBeneficiaries();
      showSuccess("Recipient has been updated.");
      closeModal();
    } catch (e) {
      showError(e);
    }
  };

  const isEnoughBalance = () => {
    // const totalAmount = getTotalAmount();
    if (Big(selectedSource?.amount).lt(Big(amount === "" ? "0" : amount))) {
      return false;
    }
    return true;
  };

  const sendWireTransferRequest = async ({ mfaCode }) => {
    try {
      const data = {
        accountId: selectedSource?.id,
        beneficiaryId: selectedBeneficiary.id,
        amount,
        userMemo: userMemo.trim(),
        mfaCode,
        transaction2faRecordId
      };

      const result = await sendWireTransferApi(data);
      if (result?.statusCode === 207 || result?.status === 207) {
        setTransaction2faRecordId(result?.data?.data?.transaction2faRecordId);
        setTransaction2faModalVisible(true);
        return;
      }
      if (result?.statusCode === 200 || result?.status === 200) {
        showSuccess("Wire transfer request sent successfully!");
        navigate("/transfer");
      } else {
        if (result?.message) throw new Error(result?.message);
        throw new Error("Unknown error!");
      }
      setTransaction2faModalVisible(false);
    } catch (err) {
      if (err == "Verification code is expired") {
        setTransaction2faRecordId(null);
      }
      if (err !== "Verification code is invalid") {
        setTransaction2faModalVisible(false);
      }
      showError(err);
    }
  };

  const removeBeneficiary = async (id) => {
    try {
      await userServices.removeBeneficiary(id);

      fetchBeneficiaries();
      showSuccess("Recipient has been removed.");
    } catch (e) {
      Modal2.error({
        title: "Error",
        content: e,
        zIndex: 10000
      });
    }
  };

  const renderBeneficiaryInfo = () => {
    const {
      currency,
      beneficiaryBankName,
      beneficiaryBankAddress,
      beneficiaryBankSwiftOrABA,
      beneficiaryName,
      beneficiaryAddress,
      beneficiaryIBAN,
      intermediaryBankName,
      intermediaryBankAddress,
      intermediaryBankSwiftOrABA,
      instructionsAndReference
    } = selectedBeneficiary.extraData;
    return (
      <>
        <div style={{ marginBottom: "1.5rem", textAlign: "left", marginTop: "1.5rem" }}>
          <p className="margin-none">
            <span style={{ fontWeight: 600, color: "#178FE1" }}>Beneficiary Currency: </span>
            {currency}
          </p>
          <p className="margin-none">
            <span style={{ fontWeight: 600, color: "#178FE1" }}>Beneficiary Alias: </span>
            {selectedBeneficiary.beneficiaryName}
          </p>
        </div>

        <div style={{ marginBottom: "1.5rem", textAlign: "left" }}>
          <p className="margin-none">
            <span style={{ fontWeight: 600, color: "#178FE1" }}>Beneficiary Bank Name: </span>
            {beneficiaryBankName}
          </p>
          <p className="margin-none">
            <span style={{ fontWeight: 600, color: "#178FE1" }}>Beneficiary Bank Address: </span>
            {beneficiaryBankAddress}
          </p>
          <p className="margin-none">
            <span style={{ fontWeight: 600, color: "#178FE1" }}>Bank ABA / SWIFT / BIC: </span>
            {beneficiaryBankSwiftOrABA}
          </p>
        </div>

        <div style={{ marginBottom: "1.5rem", textAlign: "left" }}>
          <p className="margin-none">
            <span style={{ fontWeight: 600, color: "#178FE1" }}>Beneficiary Name: </span>
            {beneficiaryName}
          </p>
          <p className="margin-none">
            <span style={{ fontWeight: 600, color: "#178FE1" }}>Beneficiary Address: </span>
            {beneficiaryAddress}
          </p>
          <p className="margin-none">
            <span style={{ fontWeight: 600, color: "#178FE1" }}>Beneficiary IBAN / Account Number: </span>
            {beneficiaryIBAN}
          </p>
        </div>
        {(intermediaryBankName || intermediaryBankAddress || intermediaryBankSwiftOrABA) && (
          <div style={{ marginBottom: "1.5rem", textAlign: "left" }}>
            {intermediaryBankName && (
              <p className="margin-none">
                <span style={{ fontWeight: 600, color: "#178FE1" }}>Intermediary Bank Name: </span>
                {intermediaryBankName}
              </p>
            )}
            {intermediaryBankAddress && (
              <p className="margin-none">
                <span style={{ fontWeight: 600, color: "#178FE1" }}>Intermediary Bank Address: </span>
                {intermediaryBankAddress}
              </p>
            )}
            {intermediaryBankSwiftOrABA && (
              <p className="margin-none">
                <span style={{ fontWeight: 600, color: "#178FE1" }}>Intermediary Bank ABA / SWIFT / BIC: </span>
                {intermediaryBankSwiftOrABA}
              </p>
            )}
          </div>
        )}
        {instructionsAndReference && (
          <div style={{ marginBottom: "1.5rem", textAlign: "left" }}>
            <p className="margin-none">
              <span style={{ fontWeight: 600, color: "#178FE1" }}>Special Instructions / Reference: </span>
              {instructionsAndReference}
            </p>
          </div>
        )}
      </>
    );
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <h2
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  textAlign: "left",
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Select source of funds to transfer
              </h2>
              {accountList.map((source) => {
                // TODO: usd account only
                const isActive = source.status === ACCOUNT_STATUS.ACTIVE;

                return (
                  <ListCard
                    style={isActive ? {} : { backgroundColor: "lightGray", cursor: "not-allowed" }}
                    key={`sourceList-${source.publicId}`}
                    children={AccountCard(source)}
                    tailComponent={
                      isActive ? (
                        <RightOutlined style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }} />
                      ) : null
                    }
                    onClick={
                      isActive
                        ? () => {
                            setSelectedSource(source);
                            setCurrentStep((prev) => prev + 1);
                          }
                        : () => {}
                    }
                  />
                );
              })}
            </div>
            <Divider />
            <CancelBtn toPath="/transfer" />
          </>
        );
      case 1:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <div className="row" style={{ width: "100%", justifyContent: "space-evenly" }}>
                <p
                  className="margin-none"
                  style={{
                    display: "flex",
                    fontSize: "16px",
                    color: "#23262F",
                    lineHeight: "1.75rem",
                    marginBottom: "1rem",
                    fontWeight: 500,
                    flex: 1
                  }}
                >
                  Select recipient
                </p>
                {beneficiaryList.length > 0 && (
                  <p
                    className="cursor-pointer"
                    style={{
                      fontSize: "1rem",
                      lineHeight: "1.75rem",
                      color: "#606060",
                      marginRight: 10,
                      display: "flex"
                    }}
                    onClick={() => {
                      setIsEditable((prev) => !prev);
                    }}
                  >
                    <Icon component={() => <img src={EditPenIcon} />} />
                    <p className="margin-none">{isEditable ? "Done" : "Edit"}</p>
                  </p>
                )}
              </div>

              <ListCard
                children={<p className="margin-none">New recipient</p>}
                tailComponent={
                  <PlusOutlined style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }} />
                }
                onClick={() => {
                  setIsOnAddBeneficiary(true);
                  setModalOpened(true);
                }}
              />
              {beneficiaryList.map((each) => {
                return (
                  <ListCard
                    key={each.id}
                    children={
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                        <p className="margin-none">{each?.beneficiaryName}</p>
                      </div>
                    }
                    tailComponent={
                      isEditable ? (
                        <Button
                          type="danger"
                          shape="circle"
                          onClick={() => {
                            setIsOnDelBeneficiary(true);
                            Modal2.confirm({
                              title: "Confirm",
                              icon: <ExclamationCircleOutlined />,
                              content: "Are you sure you want to remove this recipient",
                              okText: "Confirm",
                              cancelText: "Cancel",
                              onCancel: () => {
                                setIsOnDelBeneficiary(false);
                                setModalOpened(false);
                              },
                              onOk: () => {
                                removeBeneficiary(each?.id);
                                closeModal();
                              }
                            });
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      ) : (
                        <div className="row">
                          <p className="margin-none">{each?.extraData?.currency}</p>
                          <RightOutlined
                            style={{
                              background: "#35B994",
                              color: "white",
                              padding: 2,
                              borderRadius: 3,
                              marginLeft: 15
                            }}
                          />
                        </div>
                      )
                    }
                    onClick={() => {
                      if (!isEditable) {
                        setCurrentStep((prev) => prev + 1);
                        setSelectedBeneficiary(each);
                      } else {
                        setEditBeneficiary({
                          id: each?.id,
                          alias: each?.beneficiaryName,
                          ...each?.extraData
                        });
                        setUpdateEditBeneficiary({
                          id: each?.id,
                          alias: each.beneficiaryName,
                          ...each?.extraData
                        });
                        setModalOpened(true);
                      }
                    }}
                  />
                );
              })}
            </div>
            <Divider />
            <CancelBtn toPath="/transfer" />
          </>
        );
      case 2:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <p
                className="margin-none"
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Enter the amount you want to send
              </p>
              <AmountInput
                title={"You'll send"}
                onChange={(e) => setAmount(e)}
                value={amount}
                currency={selectedSource.currency}
                accountId={selectedSource.publicId}
                accountAmount={selectedSource.amount}
              />
              <FeeRow
                rows={feeRow}
                impactOnSpendableAmount={transferType.impactOnSpendableAmount}
                spendableData={{ spendableAmountInUsd }}
              />
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    borderRadius: "12px",
                    border: "2px solid #F4F5F6"
                  }}
                >
                  <p
                    className="margin-none"
                    style={{ display: "flex", fontWeight: 500, fontSize: "0.9rem", color: "#777E91" }}
                  >
                    {selectedBeneficiary.beneficiaryName} will receive
                  </p>
                  <p className="margin-none" style={{ display: "flex", fontWeight: 500, fontSize: "2rem" }}>
                    {`${formatAmount(estimateReceivedAmount, selectedSource.currency)} ${selectedSource.currency}`}
                  </p>
                </div>
                {!isEnoughBalance() && (
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "#FFF0F0",
                      borderRadius: 12,
                      alignItems: "center",
                      paddingTop: 15,
                      paddingBottom: 15,
                      paddingLeft: 20,
                      marginTop: "1rem"
                    }}
                  >
                    <p
                      className="margin-none"
                      style={{
                        marginLeft: "0.5rem",
                        color: "#fb7185",
                        fontSize: "0.9rem",
                        fontWeight: 500
                        // marginBottom: "2rem"
                      }}
                    >
                      You do not have enough balance for this transaction
                    </p>
                  </div>
                )}
                {isEnoughBalance() && <ShouldArriveBox days={"1 - 2"} />}
                <p className="margin-none" style={{ display: "flex", color: "#777E91", margin: "10px 0" }}>
                  Reference (optional)
                </p>
                <TextArea
                  value={userMemo}
                  onChange={(e) => setUserMemo(e.target.value)}
                  style={{ width: "100%", height: 100, border: "1px solid lightGray", borderRadius: 6 }}
                  placeholder="Optional"
                />
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <CancelBtn toPath="/transfer" />
              <NextBtn enable={!nextDisabled} onClick={() => setCurrentStep((prev) => prev + 1)} />
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              {/* <h2
                style={{
                  display: "flex",
                  fontSize: "1.25rem",
                  lineHeight: "1.75rem",
                  color: "#606060",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  textAlign: "left"
                }}
              >
                Transaction Summary
              </h2> */}
              <div style={{ fontSize: "1rem", width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    background: "#E9F6FF",
                    borderRadius: "12px",
                    alignItems: "flex-start",
                    padding: 20,
                    paddingLeft: 30
                  }}
                >
                  <p className="margin-none" style={{ display: "block", fontSize: "1rem" }}>
                    {`Send from Account: ${selectedSource.publicId} - ${formatAmount(
                      selectedSource.amount,
                      selectedSource.currency
                    )} ${selectedSource.currency}`}
                  </p>
                  <p className="margin-none" style={{ display: "block", fontSize: "2.25rem" }}>
                    {`${formatAmount(amount, selectedSource.currency)} ${selectedSource.currency}`}
                  </p>
                </div>
              </div>
              <FeeRow
                rows={feeRow}
                impactOnSpendableAmount={transferType.impactOnSpendableAmount}
                spendableData={{ spendableAmountInUsd }}
              />

              <div style={{ fontSize: "1rem", width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "12px",
                    alignItems: "flex-start",
                    padding: 20,
                    paddingLeft: 30,
                    border: "2px solid #F4F5F6"
                  }}
                >
                  <p className="margin-none" style={{ textAlign: "left", fontWeight: 500, color: "#777E91" }}>
                    <span style={{ fontWeight: 600, color: "#178FE1" }}>{selectedBeneficiary.beneficiaryName} </span>
                    will receive
                  </p>
                  <p className="margin-none" style={{ display: "flex", fontWeight: 500, fontSize: "2rem" }}>
                    {`${formatAmount(estimateReceivedAmount, selectedSource.currency)} ${selectedSource.currency}`}
                  </p>
                </div>
                <ShouldArriveBox days={"1 - 2"} />
                {renderBeneficiaryInfo()}
                {userMemo && userMemo !== "" && (
                  <>
                    <p className="margin-none" style={{ display: "flex", fontWeight: 600, marginTop: "2rem" }}>
                      Reference / Memo
                    </p>
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: 10,
                        marginTop: 5
                      }}
                    >
                      <p style={{ textAlign: "left", padding: "1rem 2rem" }}>{userMemo}</p>
                    </div>
                  </>
                )}
              </div>
              <Checkbox
                checked={isConfirmed}
                onChange={(e) => setIsConfirmed(e.target.checked)}
                style={{ fontSize: "1rem", marginTop: "2rem", textAlign: "left", width: "100%" }}
              >
                I confirm that the above information is correct.
              </Checkbox>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <CancelBtn toPath="/transfer" />
              <NextBtn
                btnName="Send Now!"
                enable={isConfirmed}
                onClick={async () => {
                  sendWireTransferRequest({ mfaCode: null });
                }}
              />
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const renderCreateRecipient = () => {
    return (
      <Modal2
        title="Create wire transfer recipient"
        visible={modalOpened && !isOnDelBeneficiary}
        onCancel={closeModal}
        onOk={async () => {
          createBeneficiary({ currency: beneficiaryCurrency, ...newBeneficiary });
        }}
        okText={"Save recipient"}
        className={"add-beneficiary-modal"}
      >
        <WireTransferForm
          inputsValue={newBeneficiary}
          setNewBeneficiary={setNewBeneficiary}
          setBeneficiaryCurrency={setBeneficiaryCurrency}
          validationErrors={validationErrors}
          setNewValidationErrors={setValidationErrors}
        />
      </Modal2>
    );
  };

  const renderEditRecipient = () => {
    return (
      <Modal2
        title="Edit wire transfer recipient"
        visible={modalOpened && !isOnDelBeneficiary}
        onCancel={closeModal}
        onOk={async () => {
          updateBeneficiary({ currency: beneficiaryCurrency || editBeneficiary?.currency, ...updateEditBeneficiary });
        }}
        okText={"Update recipient"}
        className={"add-beneficiary-modal"}
      >
        <WireTransferForm
          inputsValue={updateEditBeneficiary}
          setNewBeneficiary={setUpdateEditBeneficiary}
          setBeneficiaryCurrency={setBeneficiaryCurrency}
          validationErrors={validationErrors}
          setNewValidationErrors={setValidationErrors}
        />
      </Modal2>
    );
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "85%", margin: "40px auto" }}>
      <BackBtn
        onClick={() => {
          if (currentStep > 0) {
            setCurrentStep((prev) => prev - 1);
          } else {
            navigate("/transfer");
          }
        }}
      />
      <div className="row page-title" style={{ paddingTop: 0 }}>
        Transfer
      </div>
      <div className="card-apply-container" style={{ width: "100%" }}>
        <StepMenu
          steps={["Select account", "Select recipient", "Transfer amount", "Transaction overview"]}
          currentStep={currentStep}
        />
        <Divider />
        {renderStep()}
      </div>
      {isEditable && !isOnAddBeneficiary ? renderEditRecipient() : renderCreateRecipient()}
      <Transaction2faModal
        visible={transaction2faModalVisible}
        onOk={(mfaCode) => sendWireTransferRequest({ mfaCode })}
        onCancel={() => {
          setTransaction2faModalVisible(false);
        }}
        email={user?.username}
      />
    </div>
  );
};

export const WireTransfer = boundError(WireTransferRaw);
