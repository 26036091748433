import { bankApi } from "./authenticatedAxios";
import { responseDataHandler } from "./responseHandler";
import { concatApiQuery } from "../utils";

export const getAlphapoSupportCurrencies = () =>
  bankApi.get(`/deposit/alphapo/supportCurrencies`).then(({ data }) => responseDataHandler(data));

export const getAlphapoCurrenciesRate = (exchangeInfo) =>
  bankApi.post("/deposit/alphapo/currencyRate", exchangeInfo).then(({ data }) => data);

export const getAlphapoDepositAddress = (depositInfo) =>
  bankApi.post("/deposit/alphapo/depositAddress", depositInfo).then(({ data }) => data);

export const getCreditDebitDepositAddress = ({ serviceProvider }) =>
  bankApi
    .post(`/deposit/creditDebitDeposit/depositAddress?serviceProvider=${serviceProvider}`)
    .then(({ data }) => data);

export const depositFromERC20Api = (toAccountId, amountAxc) =>
  bankApi.post("/deposit/erc20AXC", { toAccountId, amountAxc }).then(({ data }) => data);

export const createWireDeposit = ({ toAccountId, amountUsd, sourceOfFund }) =>
  bankApi.post("/transfer/wire-deposit", { toAccountId, amountUsd, sourceOfFund }).then(({ data }) => data);

export const cancelWireDeposit = (transactionId) =>
  bankApi.post(`/transfer/wire-deposit/${transactionId}/cancel`).then(({ data }) => data);

export const userConfirmDepositRequest = (info) => bankApi.post("/deposit/confirm", info).then(({ data }) => data);

export const getCustodyDepositAddress = (query) =>
  bankApi.get(concatApiQuery("transfer/axcustody/deposit-address", query)).then(({ data }) => data);
