import Big from "big.js";
import React from "react";
import NumberFormat from "react-number-format";
import { boundError, formatAmount, getAmountSymbol, getDecimalScale } from "../../utils";

const AmountInputRaw = ({ title, onChange, value, currency, accountId, accountAmount }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        margin: "10px 0"
      }}
    >
      <p className="margin-none" style={{ display: "block", fontWeight: 600 }}>
        {title}
      </p>
      <div className="amount-input-container">
        <NumberFormat
          decimalScale={getDecimalScale(currency)}
          thousandSeparator={true}
          value={value || ""}
          placeholder={getAmountSymbol(currency) + "0.00"}
          prefix={getAmountSymbol(currency)}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            onChange(value);
          }}
          className={"amount-input-section"}
        />
        <div className={"amount-account-section"}>
          <p
            className="margin-none"
            style={{ fontSize: 26, paddingRight: 10, borderRight: "2px solid #E6E8EC", margin: 10 }}
          >
            {currency}
          </p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className="margin-none" style={{ color: "#777E90" }}>
              Account id:{" "}
            </p>
            <p className="margin-none" style={{ color: "#777E90" }}>
              {accountId}
            </p>
            <p className="margin-none">{`${formatAmount(accountAmount, currency)} ${currency}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AmountInput = boundError(AmountInputRaw);
