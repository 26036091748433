export const awsErrorMessages = {
  UsernameExistsException: "An account with the given email already exists.",
  CognitoReferralCodeInvalid: "PreSignUp failed with error referral code not exist!.",
  CognitoReferralCodeInactive: "PreSignUp failed with error Inactive Referral Code.",
  ReferralCodeInvalid: "The referral code is invalid.",
  ReferralCodeInactive: "Inactive referral code.",
  IncorrectUsernameOrPassword: "Incorrect username or password.",
  UserNotFoundException: "User does not exist.",
  UserNotConfirmedException: "User is not confirmed.",
  CodeMismatchException: "Invalid code received for user",
  InvalidSignUpCode: "Invalid verification code provided, please try again.",
  PasswordNotMatch: "Passwords do not match",
  VerificationCodeMismatchException: "Invalid verification code provided, please try again.",
  NotAcceptPolicy: "The terms of service must be accepted.",
  NotBotChecked: `Please check "I'm not a robot"`,
  ExceedsAttemptException: "Password attempts exceeded",
  NotAuthorizedException: "Invalid session for the user, session is expired.",
  PasswordResetRequiredException: "Password reset required for the user",
  InvalidRecaptchaToken: "PreSignUp failed with error Invalid reCAPTCHA token."
};

export const customErrorMessage = {
  UserNotFoundException: "These credentials do not match our records.",
  ExceedsAttemptException: "Too many login attempts. Please try again in 35 seconds.",
  PasswordAgainstRules: "At least 8 characters of a-z, A-Z and 0-9 ",
  InvalidConfirmationCode: "Confirmation code is incorrect",
  UsernameExistsException: "The email has already been taken."
};

export const getCustomErrorMessage = (errMessage = "") => {
  switch (errMessage) {
    case awsErrorMessages.UserNotFoundException:
    case awsErrorMessages.IncorrectUsernameOrPassword:
      return customErrorMessage.UserNotFoundException;
    case awsErrorMessages.ExceedsAttemptException:
      return customErrorMessage.ExceedsAttemptException;
    case awsErrorMessages.UsernameExistsException:
      return customErrorMessage.UsernameExistsException;
    default:
      return errMessage;
  }
};

export const handleErrorMessage = (err) => {
  let errMessage;
  let errResMes = err.response?.data?.message || err.message;
  if (typeof errResMes === "string") {
    errMessage = errResMes;
  } else {
    errMessage = JSON.stringify(errResMes);
  }
  return errMessage;
};
