import axios from "axios";
import { getCurrentIdToken } from ".";
import { refreshIdToken, siteMaintenance } from "../redux/actions";
import store from "../redux/store";
import UserServices from "../services/userServices";

const handleRefreshError = (failedRequest) => {
  const userServices = UserServices.getInstance();
  userServices.appSignOut();
  return Promise.reject(failedRequest);
};

const decorateAxiosForApi = (apiBaseUrl, { overwriteIdToken } = {}) => {
  const decoratedAxios = axios.create({ baseURL: apiBaseUrl });
  const requestConfigProcessor = async (config) => {
    if (!config.headers?.common?.Authorization) {
      let idToken = overwriteIdToken ?? store.getState().auth.idToken;
      if (!idToken) {
        idToken = await getCurrentIdToken();
      }
      decoratedAxios.defaults.headers.common["Authorization"] = idToken;
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: idToken
        }
      };
    }
    return config;
  };

  decoratedAxios.interceptors.request.use(requestConfigProcessor, (err) => {
    return Promise.reject(err);
  });

  const refreshIdTokenAndRetry = async (failedRequest) => {
    console.log({ failedRequest });
    failedRequest.config.skipRetry = true;
    try {
      let idToken = await getCurrentIdToken();
      failedRequest.config.headers["Authorization"] = idToken;
      decoratedAxios.defaults.headers.common["Authorization"] = idToken;
      store.dispatch(refreshIdToken(idToken));
      return decoratedAxios.request(failedRequest.config);
    } catch (err) {
      return handleRefreshError(failedRequest);
    }
  };

  decoratedAxios.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err.response?.status == 503) {
        store.dispatch(siteMaintenance({ isMaintaining: true }));
        return Promise.reject(err);
      }
      const shouldRefresh = err.response?.data?.message === "CognitoTokenExpired" && !err.config.skipRetry;
      if (shouldRefresh) {
        return refreshIdTokenAndRetry(err);
      }

      if (err.response?.data?.message) {
        return Promise.reject(err.response?.data?.message);
      }

      return Promise.reject(err);
    }
  );

  return decoratedAxios;
};

let bankApi = decorateAxiosForApi(process.env.REACT_APP_BANK_API_BASE_URL);
let sharedApi = decorateAxiosForApi(process.env.REACT_APP_SHARED_API_BASE_URL);

const initAxios = ({ overwriteIdToken } = {}) => {
  bankApi = decorateAxiosForApi(process.env.REACT_APP_BANK_API_BASE_URL, { overwriteIdToken });
  sharedApi = decorateAxiosForApi(process.env.REACT_APP_SHARED_API_BASE_URL, { overwriteIdToken });
};

export { bankApi, sharedApi, initAxios };
