import React, { useState, useEffect } from "react";
import { boundError, showSuccess } from "../utils";
import { ListCard } from "./SharedComponents";
import UserServices from "../services/userServices";
import Icon, {
  RightOutlined,
  PlusOutlined,
  MinusOutlined,
  RiseOutlined,
  SwapOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import Modal from "react-modal";
import { Input, Button, message, Modal as Modal2, Spin, Tag } from "antd";
import { BENEFICIARY_TYPE } from "../constants/beneficiaryType";
import EditPenIcon from "../resources/icons/transfer/edit_pen.svg";
import { USER_STATUS } from "../constants/userStatus";

const BeneficiarySelectorRaw = ({ onBeneficiarySelect }) => {
  const userServices = UserServices.getInstance();
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOnDelBeneficiary, setIsOnDelBeneficiary] = useState(false);
  const [isOnAddBeneficiary, setIsOnAddBeneficiary] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [editBeneficiary, setEditBeneficiary] = useState({ beneficiaryName: "", beneficiaryEmail: "" });
  const [updateEditBeneficiary, setUpdateEditBeneficiary] = useState({ beneficiaryName: "", beneficiaryEmail: "" });

  const [newBeneficiary, setNewBeneficiary] = useState({ name: "", email: "" });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBeneficiaries();
  }, []);

  const fetchBeneficiaries = async () => {
    const result = await userServices.fetchBeneficiaries(BENEFICIARY_TYPE.INTERNAL.code);

    if (result?.data) {
      setBeneficiaries(result?.data);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsOnDelBeneficiary(false);
    setIsOnAddBeneficiary(false);
    setNewBeneficiary({ name: "", email: "" });
    setEditBeneficiary({ beneficiaryName: "", beneficiaryEmail: "" });
    setUpdateEditBeneficiary({ beneficiaryName: "", beneficiaryEmail: "" });
    setValidationErrors({});
  };

  const updateBeneficiary = async () => {
    setValidationErrors({});
    let hasError = false;
    if (!updateEditBeneficiary?.beneficiaryName) {
      setValidationErrors((old) => ({ ...old, name: "Please enter a valid name." }));
      hasError = true;
    }
    if (!updateEditBeneficiary?.beneficiaryEmail) {
      setValidationErrors((old) => ({ ...old, email: "Please enter a valid email." }));
      hasError = true;
    }

    if (hasError) {
      return;
    }

    try {
      setLoading(true);

      await userServices.updateBeneficiary(
        undefined,
        editBeneficiary.beneficiaryName,
        editBeneficiary.beneficiaryEmail,
        BENEFICIARY_TYPE.INTERNAL.code,
        {
          name: updateEditBeneficiary.beneficiaryName
        }
      );

      setLoading(false);
      fetchBeneficiaries();
      showSuccess("Recipient has been updated.");
      setUpdateEditBeneficiary({ beneficiaryName: "", beneficiaryEmail: "" });

      closeModal();
    } catch (e) {
      setLoading(false);

      Modal2.error({
        title: "Cannot Add Recipient",
        content: e,
        zIndex: 10000
      });
    }
  };

  const createBeneficiary = async () => {
    setValidationErrors({});
    let hasError = false;
    if (!newBeneficiary?.name) {
      setValidationErrors((old) => ({ ...old, name: "Please enter a valid name." }));
      hasError = true;
    }
    if (!newBeneficiary?.email) {
      setValidationErrors((old) => ({ ...old, email: "Please enter a valid email." }));
      hasError = true;
    }

    if (hasError) {
      return;
    }

    try {
      setLoading(true);

      await userServices.createBeneficiary(newBeneficiary.name, newBeneficiary.email, BENEFICIARY_TYPE.INTERNAL.code);

      setLoading(false);
      fetchBeneficiaries();
      showSuccess("Recipient has been created.");
      setNewBeneficiary({ name: "", email: "" });

      closeModal();
    } catch (e) {
      setLoading(false);

      Modal2.error({
        title: "Cannot Add Recipient",
        content: e,
        zIndex: 10000
      });
    }
  };

  const removeBeneficiary = async (id) => {
    try {
      setLoading(true);
      await userServices.removeBeneficiary(id);

      setLoading(false);
      fetchBeneficiaries();
    } catch (e) {
      setLoading(false);
      Modal2.error({
        title: "Error",
        content: e,
        zIndex: 10000
      });
    }
  };

  const renderCreateRecipient = () => {
    return (
      <div style={{ backgroundColor: "white", padding: 20, paddingBottom: 40, borderRadius: "20px" }}>
        <h2 style={{ fontWeight: 600 }}>Create recipient</h2>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ flex: 1, marginTop: 20 }}>
            <p className="margin-none" style={{ color: "#B1B5C4" }}>
              Name
            </p>
            <Input
              placeholder="Jane Robert"
              size="large"
              value={newBeneficiary.name}
              onChange={(e) => {
                setValidationErrors((old) => ({ ...old, name: "" }));
                setNewBeneficiary({ ...newBeneficiary, name: e.target.value });
              }}
            />
            {validationErrors?.name && <p style={{ color: "red" }}>{validationErrors?.name}</p>}
          </div>
          <div style={{ flex: 1, marginTop: 20 }}>
            <p className="margin-none" style={{ color: "#B1B5C4" }}>
              Email
            </p>
            <Input
              placeholder="Email address"
              size="large"
              value={newBeneficiary.email}
              onChange={(e) => {
                setValidationErrors((old) => ({ ...old, email: "" }));
                setNewBeneficiary({ ...newBeneficiary, email: e.target.value });
              }}
            />
            {validationErrors?.email && <p style={{ color: "red" }}>{validationErrors?.email}</p>}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flex: 1, marginTop: 20 }}>
          <Button
            type="primary"
            style={{
              backgroundColor: "white",
              border: "1px solid lightGray",
              borderRadius: 5,
              color: "black"
            }}
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={createBeneficiary}
            style={{
              border: "1px solid lightGray",
              borderRadius: 5,
              marginLeft: 10
            }}
          >
            Create recipient
          </Button>
        </div>
      </div>
    );
  };

  const renderEditRecipient = () => {
    return (
      <div style={{ backgroundColor: "white", padding: 20, paddingBottom: 40, borderRadius: "20px" }}>
        <h2 style={{ fontWeight: 600 }}>Edit recipient</h2>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ flex: 1, marginTop: 20 }}>
            <p className="margin-none" style={{ color: "#B1B5C4" }}>
              Name
            </p>
            <Input
              placeholder="Jane Robert"
              size="large"
              value={updateEditBeneficiary?.beneficiaryName}
              onChange={(e) => {
                setValidationErrors((old) => ({ ...old, name: "" }));
                setUpdateEditBeneficiary({ ...editBeneficiary, beneficiaryName: e.target.value });
              }}
            />
            {validationErrors?.name && <p style={{ color: "red" }}>{validationErrors?.name}</p>}
          </div>
          <div style={{ flex: 1, marginTop: 20 }}>
            <p className="margin-none" style={{ color: "#B1B5C4" }}>
              Email
            </p>
            <Input
              placeholder="Email address"
              size="large"
              value={updateEditBeneficiary?.beneficiaryEmail}
              disabled={true}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flex: 1, marginTop: 20 }}>
          <Button
            type="primary"
            style={{
              backgroundColor: "white",
              border: "1px solid lightGray",
              borderRadius: 5,
              color: "black"
            }}
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={updateBeneficiary}
            style={{
              border: "1px solid lightGray",
              borderRadius: 5,
              marginLeft: 10
            }}
          >
            Update recipient
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="row">
          <p
            className="margin-none"
            style={{
              display: "flex",
              fontSize: "16px",
              color: "#23262F",
              lineHeight: "1.75rem",
              marginBottom: "1rem",
              fontWeight: 500,
              flex: 1
            }}
          >
            Select recipient
          </p>
          {beneficiaries.length > 0 && (
            <p
              className="cursor-pointer"
              style={{ fontSize: "1rem", lineHeight: "1.75rem", color: "#606060", marginRight: 10, display: "flex" }}
              onClick={() => {
                setIsEditable((prev) => !prev);
              }}
            >
              <Icon component={() => <img src={EditPenIcon} />} />
              <p className="margin-none">{isEditable ? "Done" : "Edit"}</p>
            </p>
          )}
        </div>
        <ListCard
          children={<p className="margin-none">New recipient</p>}
          tailComponent={
            <PlusOutlined style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }} />
          }
          onClick={() => {
            setIsOnAddBeneficiary(true);
            setIsOpen(true);
          }}
        />
        {beneficiaries.map((beneficiary, index) => {
          const isActive = beneficiary.status === USER_STATUS.ACTIVE;
          return (
            <ListCard
              style={isActive ? {} : { backgroundColor: "lightGray", cursor: "not-allowed" }}
              key={`beneficiaries-${index}`}
              children={
                <>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start"
                    }}
                  >
                    <p
                      className="margin-none"
                      style={{ fontSize: 16, fontWeight: "500" }}
                    >{`${beneficiary.beneficiaryName}`}</p>
                    <p
                      className="margin-none"
                      style={{ fontWeight: "500", color: "grey" }}
                    >{`${beneficiary.beneficiaryEmail}`}</p>
                    {!isActive && (
                      <p
                        className="margin-none"
                        style={{ display: "flex", flex: 1, fontSize: "0.8rem", fontWeight: "500", color: "#ff3141" }}
                      >
                        <Tag color="red" style={{ borderRadius: "20px", marginTop: "1rem" }}>
                          Action Required
                        </Tag>
                      </p>
                    )}
                  </div>
                </>
              }
              tailComponent={
                isEditable ? (
                  <Button
                    type="danger"
                    shape="circle"
                    onClick={() => {
                      setIsOnDelBeneficiary(true);
                      Modal2.confirm({
                        title: "Confirm",
                        icon: <ExclamationCircleOutlined />,
                        content: "Are you sure you want to remove this recipient",
                        okText: "Confirm",
                        cancelText: "Cancel",
                        onCancel: () => {
                          setIsOnDelBeneficiary(false);
                          setIsOpen(false);
                        },
                        onOk: () => {
                          removeBeneficiary(beneficiary?.id);
                          closeModal();
                        }
                      });
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                ) : isActive ? (
                  <RightOutlined />
                ) : null
              }
              onClick={() => {
                if (isActive && !isEditable && onBeneficiarySelect) {
                  onBeneficiarySelect(beneficiary);
                } else {
                  setEditBeneficiary(beneficiary);
                  setUpdateEditBeneficiary(beneficiary);
                  setIsOpen(true);
                }
              }}
            />
          );
        })}
        <Modal
          isOpen={isOpen && !isOnDelBeneficiary}
          onRequestClose={() => closeModal()}
          ariaHideApp={false}
          style={{
            overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.3)" },
            content: {
              margin: "auto",
              maxWidth: 600,
              maxHeight: 800,
              height: "100vh",
              overflow: "auto",
              padding: 0,
              backgroundColor: "transparent",
              border: "none",
              paddingTop: 20,
              paddingBottom: 20,
              minWidth: 300
            }
          }}
        >
          <Spin spinning={loading}>
            {isEditable && !isOnAddBeneficiary ? renderEditRecipient() : renderCreateRecipient()}
          </Spin>
        </Modal>
      </Spin>
    </>
  );
};

export const BeneficiarySelector = boundError(BeneficiarySelectorRaw);
