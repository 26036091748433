import mime from "mime-types";

export const responseDataHandler = (data) => {
  if (data?.statusCode === 200 || data?.status === 200) {
    return data?.data;
  } else if (data?.statusCode === 207 || data?.status === 207) {
    return data;
  } else {
    if (data?.message) throw new Error(data?.message);

    throw new Error("Unknown error!");
  }
};

export const fileDownloadResponseHandler = (response, filename) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  const contentType = response.headers["content-type"];
  const fileExt = mime.extension(contentType); // infer file extension from MIME type
  link.setAttribute("download", `${filename}.${fileExt}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const fileDownloadResponseWithFilenameHandler = (response) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  const [, filename] = response.headers["content-disposition"].split(`"`);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
