import React, { useState, useRef } from "react";
import { boundError, showError, formatAmount, upperCaseFirstCharEachForWord } from "../../utils";
import { Button, DatePicker, Spin } from "antd";
import { useEffect } from "react";
import { doGetCardTransHistory, doGetCardSecurityData } from "../../apis/card";
import date from "date-and-time";
import moment from "moment";
import { getCountryNameByCode } from "../../constants/";
import { useReactToPrint } from "react-to-print";
import logo from "../../resources/AXIA_statement_logo.png";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { SCREEN_DIMENSIONS } from "../../constants";

const CardStatementRaw = ({ card, showPrintBtn = true } = {}) => {
  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState({
    year: moment().year(),
    month: moment().month() + 1
  });
  const printRef = useRef();
  const pageStyle = `@page { margin: 5rem  2rem  5rem  2rem !important; }`;
  const [loading, setLoading] = useState(false);
  const [securityData, setSecurityData] = useState();
  const [printTime, setPrintDate] = useState(moment().format("YYYY/MM/DD HH:mm:ss"));
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    setTimeout(() => {
      fetchCardTransHistory();
    }, 1000);
  }, [filter]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const onPrintPress = async () => {
    setPrintDate(moment().format("YYYY/MM/DD HH:mm:ss"));
    await fetchCardSecurityData();
    handlePrint();
  };

  function disabledDate(current) {
    // Can not select months after this month
    return current > moment().endOf("month");
  }

  const fetchCardSecurityData = async () => {
    setLoading(true);
    try {
      const data = await doGetCardSecurityData(card?.id);
      setSecurityData(data);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchCardTransHistory = async () => {
    setLoading(true);
    try {
      const data = await doGetCardTransHistory(card?.id, { year: filter.year, month: filter.month });
      setTransactions(data?.transactions);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const formatAddress = () => {
    let str = `${upperCaseFirstCharEachForWord(card.deliveryAddress1)}, ${upperCaseFirstCharEachForWord(
      card.deliveryCity
    )}`;

    if (card?.deliveryAddress2) {
      str = str + `, ${upperCaseFirstCharEachForWord(card.deliveryAddress2)}`;
    }

    return str;
  };

  const formatAddress2 = () => {
    return `${upperCaseFirstCharEachForWord(card?.deliveryCountryState)}, ${getCountryNameByCode(
      card?.deliveryCountryCode
    )}`;
  };

  const formatAddress3 = () => {
    return `${card.deliveryPostCode?.toUpperCase()}`;
  };

  const formatDate = (_date) => {
    if (_date.includes("/")) {
      const parts = _date?.slice(0, 10)?.split("/");
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    } else {
      return _date?.slice(0, 10);
    }
  };

  const cardInfo = () => {
    return (
      <div style={{ paddingLeft: 30, paddingRight: 30, alignItems: "center", marginBottom: 10 }}>
        <div className="row">
          <p style={{ fontSize: 20, fontWeight: "500" }}>Card Statement</p>
          <div style={{ flex: 1 }} />
          <img src={logo} alt="" style={{ height: 30 }} />
        </div>
        <div
          style={{
            width: "100%",
            display: "grid",
            gap: "1rem",
            gridTemplateColumns: "repeat(2, 1fr)",
            textAlign: "left",
            marginBottom: "1rem"
          }}
        >
          <div
            style={{
              padding: 20,
              boxSizing: "border-box",
              borderRadius: 12,
              backgroundColor: "white"
            }}
          >
            <p className="margin-none" style={{ fontWeight: 600, marginBottom: 10 }}>
              Card Holder
            </p>
            <p className="margin-none">{`${card?.firstName} ${card?.lastName}`}</p>
            <p className="margin-none">{formatAddress()}</p>
            <p className="margin-none">{formatAddress2()}</p>
            <p className="margin-none">{formatAddress3()}</p>
          </div>
          <div
            style={{
              padding: 20,
              boxSizing: "border-box",
              borderRadius: 12,
              backgroundColor: "white"
            }}
          >
            <p className="margin-none" style={{ fontWeight: 600, marginBottom: 10 }}>
              Bank Detail
            </p>
            <p className="margin-none">AXIA Capital Bank</p>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "grid",
            gap: "1rem",
            gridTemplateColumns: "repeat(2, 1fr)",
            textAlign: "left",
            marginBottom: "1rem"
          }}
        >
          <div
            style={{
              padding: 15,
              boxSizing: "border-box",
              borderRadius: 12,
              backgroundColor: "white"
            }}
          >
            <p className="margin-none" style={{ fontWeight: 600, marginBottom: 10 }}>
              Card Detail
            </p>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", flexDirection: "column", flex: 3 }}>
                <p className="margin-none">Card Type:</p>
                <p className="margin-none">Card Number:</p>
                <p className="margin-none">Expire:</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column", flex: 4, fontWeight: 600 }}>
                <p className="margin-none">{"Debit card"}</p>
                <p className="margin-none">{card?.cardNumber}</p>
                <p className="margin-none">{securityData?.expire}</p>
              </div>
            </div>
          </div>
          <div
            style={{
              padding: 15,
              boxSizing: "border-box",
              borderRadius: 12,
              backgroundColor: "white"
            }}
          >
            <p className="margin-none" style={{ fontWeight: 600, marginBottom: 10 }}>
              Statement Summary
            </p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", flexDirection: "column", flex: 3 }}>
                <p className="margin-none">Statement Period:</p>
                <p className="margin-none">Balance:</p>
                <p className="margin-none">Printed At:</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column", flex: 4, fontWeight: 600 }}>
                <p className="margin-none">{moment(filter?.year + "-" + filter?.month).format("MMM YYYY")}</p>
                <p className="margin-none">{`${formatAmount(card?.balance, card?.currency)} ${card?.currency}`}</p>
                <p className="margin-none">{printTime}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TransactionItem = ({ transaction, index, detectScreenWidth = true }) => {
    if (detectScreenWidth === true && width < SCREEN_DIMENSIONS.SCREEN_SM_WIDTH) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px 20px 10px 20px",
            borderBottom: index == Object.values(transactions).length - 1 ? "0" : "1px solid lightgrey"
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontWeight: "500" }}>{formatDate(transaction?.date)}</p>
            <div style={{ fontWeight: "500", color: Number(transaction?.amount) > 0 ? "green" : "red" }}>
              <p>
                {transaction?.amount} {transaction?.currency}
              </p>
            </div>
          </div>
          <p style={{ maxWidth: "100%", fontSize: 12, color: "grey" }}>{transaction?.description}</p>
        </div>
      );
    }

    return (
      <div
        key={index}
        className="tab-rows"
        style={{ borderBottom: index == Object.values(transactions).length - 1 ? "0" : "", flexDirection: "row" }}
      >
        <p style={{ fontWeight: "500" }}>{formatDate(transaction?.date)}</p>
        <p style={{ maxWidth: "60%", fontSize: 12, color: "grey" }}>{transaction?.description}</p>
        <div style={{ fontWeight: "500", color: Number(transaction?.amount) > 0 ? "green" : "red" }}>
          <p>
            {transaction?.amount} {transaction?.currency}
          </p>
        </div>
      </div>
    );
  };

  const mainBody = ({ detectScreenWidth = true } = {}) => {
    if (transactions) {
      return (
        <div className="card-transaction-container">
          {Object.values(transactions).length > 0 ? (
            Object.values(transactions).map((transaction, index) => {
              return (
                <TransactionItem
                  key={index}
                  transaction={transaction}
                  index={index}
                  detectScreenWidth={detectScreenWidth}
                />
              );
            })
          ) : (
            <p style={{ padding: "20px" }}>No transaction found</p>
          )}
        </div>
      );
    }

    return <p style={{ padding: "20px" }}>No transaction found</p>;
  };

  return (
    <Spin spinning={loading}>
      <div className="row" style={{ paddingLeft: 30, paddingRight: 30, alignItems: "center", marginBottom: 10 }}>
        <DatePicker
          picker="month"
          className="card-transaction-date-picker"
          disabledDate={disabledDate}
          defaultValue={moment(`${filter.year}-${filter.month}`, "YYYY-MM")}
          onChange={(date) => {
            setFilter({
              year: date?.year() || moment().year(),
              month: date?.month() + 1 || moment().month() + 1
            });
          }}
        />
        <div style={{ flex: 1 }} />
        {showPrintBtn === true && (
          <Button
            type="primary"
            // icon={<FileOutlined style={{ color: "#ffffff" }} />}
            style={{ borderRadius: 5, marginLeft: 10 }}
            onClick={() => {
              onPrintPress();
            }}
          >
            Print Statement
          </Button>
        )}
      </div>
      {mainBody()}

      <div style={{ display: "none" }}>
        <div style={{ width: "100%" }} ref={printRef}>
          <style> {pageStyle} </style> {/* STYLE FOR PRINTING, DO NOT DELETE */}
          {cardInfo()}
          {mainBody({ detectScreenWidth: false })}
        </div>
      </div>
    </Spin>
  );
};

export const CardStatement = boundError(CardStatementRaw);
