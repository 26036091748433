import React from "react";
import { boundError } from "../../utils";

const ListCardRaw = ({ children, onClick, tailComponent, style = {} }) => {
  return (
    <div
      className="list-card"
      style={{ ...style }}
      onClick={() => {
        onClick();
      }}
    >
      <div className="account-list-item-flex" style={{ position: "relative", width: "100%", display: "flex" }}>
        {children}
      </div>
      <div style={{ position: "absolute", right: "20px" }}>{tailComponent}</div>
    </div>
  );
};

export const ListCard = boundError(ListCardRaw);
