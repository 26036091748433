"use strict";

export const CARD_STATUS = {
  UNKNOWN: "UNKNOWN",
  SUSPENDED: "SUSPENDED",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  EXPIRED: "EXPIRED",
  LOST: "LOST"
};
