import React, { useEffect } from "react";
import { boundError } from "../../utils";
import { Modal } from "antd";
import PDFviewer from "./PDFviewer";

const ConsentModalRaw = ({ visible, onOk, onCancel, src, ...props }) => {
  useEffect(() => {}, [src]);
  return (
    <Modal
      visible={visible}
      width={"130vh"}
      style={{ paddingBottom: 100 }}
      okText={"Agree"}
      cancelText={"Cancel"}
      onOk={onOk}
      onCancel={onCancel}
      {...props}
    >
      <div className="modal">
        <div className="modalContent">
          <div className="all-page-container">
            <PDFviewer pdf={src} />
          </div>
          {/* <iframe
            src={src}
            style={{ width: "100%", height: "60vh", borderRadius: 20, marginTop: 15 }}
            frameBorder={0}
          ></iframe> */}
        </div>
      </div>
    </Modal>
  );
};

export const ConsentModal = boundError(ConsentModalRaw);
