import React, { useState } from "react";
import { Form, Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { AuthWrapper, ButtonPrimary } from "../components";
import { boundError } from "../utils";
import { getCustomErrorMessage } from "../constants";
import { awsForgotPassword } from "../apis";

const ForgotPasswordRaw = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [forgotPasswordError, setForgotPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleForgotPasswordError = (err) => {
    console.log(err);
    let errMessage = err.message;

    if (typeof errMessage !== "string") {
      errMessage = JSON.stringify(errMessage);
    }
    setForgotPasswordError(getCustomErrorMessage(errMessage));
  };

  const forgotPassword = async (values) => {
    try {
      setLoading(true);
      setForgotPasswordError("");
      const { email } = values;
      await awsForgotPassword(email);
      navigate("/reset-password", { state: { email } });
    } catch (err) {
      handleForgotPasswordError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthWrapper>
      <div className="auth-page__message">Password Recovery</div>
      <p className="auth-page__title">Please enter your email</p>
      <Spin spinning={loading}>
        <Form
          onFinish={forgotPassword}
          layout="vertical"
          form={form}
          name="forgot-password-form"
          id="forgot-password-form"
        >
          <Form.Item
            label="AXIA Account Email ID"
            name="email"
            rules={[
              {
                required: true,
                message: "The email field is required"
              },
              { type: "email", message: "The given email address format must be valid email address" }
            ]}
            normalize={(value) => value.replace(" ", "").toLowerCase()}
            validateTrigger="onBlur"
          >
            <Input
              allowClear
              id="email"
              type="email"
              className="acb-primary-input"
              onChange={() => setForgotPasswordError("")}
            />
          </Form.Item>
          {forgotPasswordError && (
            <div style={{ color: "red", marginTop: -24, marginBottom: 24, fontStyle: "italic" }}>
              {forgotPasswordError}
            </div>
          )}
          <ButtonPrimary htmlType="submit" type="primary" size={"large"} block style={{ marginBottom: 20 }}>
            Request new password
          </ButtonPrimary>
          <div className="row">
            <div className="grey-horizontal-line"></div>
            <span style={{ color: "rgb(115,115,115)", padding: "0 10px" }}>or</span>
            <div className="grey-horizontal-line"></div>
          </div>
          <div
            className="clickable-text"
            style={{ textAlign: "center", marginTop: 10 }}
            onClick={() => navigate("/login")}
          >
            Log In
          </div>
        </Form>
      </Spin>
    </AuthWrapper>
  );
};

export const ForgotPassword = boundError(ForgotPasswordRaw);
