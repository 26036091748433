import React, { useState, useEffect } from "react";
import { boundError } from "../../utils";
import date from "date-and-time";
import Big from "big.js";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../../utils";
import { STAKING_STATUS } from "../../constants/stakingStatus";

const UserStakingItemRaw = ({ staking, isLast, isFirst }) => {
  const navigate = useNavigate();

  const totalAmount = () => {
    let amount = Big(0);
    if (staking?.amount) {
      amount = Big(staking?.amount);
    }

    if (staking?.interestAmount) {
      amount = amount.plus(staking?.interestAmount);
    }

    return formatAmount(amount.toFixed(), staking?.currency);
  };

  const getEarned = () => {
    let earned = Big(0);
    if (staking?.interestAmount) {
      earned = Big(staking?.interestAmount);
    }

    return formatAmount(earned.toFixed(), staking?.currency);
  };

  const getTotalInUSD = () => {
    let str = "";

    if (staking?.rateInUsd) {
      let total = Big(0);
      if (staking?.amount) {
        total = Big(staking?.amount);
      }

      if (staking?.interestAmount) {
        total = total.plus(staking?.interestAmount);
      }

      return formatAmount(total.mul(staking?.rateInUsd).toFixed(), "USD", "$") + " USD";
    }

    return str;
  };

  const showStakingAmount = () => {
    if (
      staking?.status === STAKING_STATUS.CREATED ||
      staking?.status === STAKING_STATUS.IN_TERM ||
      staking?.status === STAKING_STATUS.READY_TO_CLAIM
    ) {
      return true;
    }
    return false;
  };

  return (
    <div
      className="stakings__user-staking-item"
      style={{
        borderBottom: isLast === true ? "none" : "1px solid lightgrey",
        borderTopLeftRadius: isFirst === true ? "20px" : 0,
        borderTopRightRadius: isFirst === true ? "20px" : 0,
        borderBottomLeftRadius: isLast === true ? "20px" : 0,
        borderBottomRightRadius: isLast === true ? "20px" : 0
      }}
      onClick={() => {
        navigate(`/stakings/${staking?.id}`);
      }}
    >
      <div className="column" style={{ alignItems: "flex-start" }}>
        <p className="margin-none" style={{ fontSize: 20, fontWeight: "500" }}>
          {staking?.name}
        </p>
        <p className="margin-none">
          {"Principle amount: " + formatAmount(staking?.amount, staking.currency) + " " + staking.currency}
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p
            className="margin-none"
            style={{ overflow: "hidden", textOverflow: "inherit", whiteSpace: "nowrap", textAlign: "left" }}
          >
            {"Rewards earned: " + getEarned() + " " + staking.currency}
          </p>
        </div>
        <p className="margin-none">{"End on: " + date.format(new Date(staking?.termEndAt), "YYYY-MM-DD", true)}</p>
      </div>

      {showStakingAmount() ? (
        <div
          className="column"
          style={{
            //   backgroundColor: "green",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <p className="margin-none" style={{ fontSize: 20, fontWeight: "500" }}>
            {totalAmount()}
            <span style={{ fontSize: 12, fontWeight: "500", color: "grey", marginLeft: 5 }}>{staking?.currency}</span>
          </p>
          {/* <p className="margin-none" style={{ fontSize: 15, fontWeight: "600" }}>
            {getTotalInUSD()}
          </p> */}
        </div>
      ) : (
        <div
          className="column"
          style={{
            //   backgroundColor: "green",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <p
            className="margin-none"
            style={{
              fontSize: 15,
              fontWeight: "500",
              color: "white",
              backgroundColor: "lightblue",
              padding: "8px 16px",
              borderRadius: 10,
              width: 100
            }}
          >
            {staking?.status}
          </p>
        </div>
      )}
    </div>
  );
};

export const UserStakingItem = boundError(UserStakingItemRaw);
