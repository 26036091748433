export const STAKING_STATUS = {
  CREATED: "CREATED",
  IN_TERM: "IN_TERM",
  CLOSED: "CLOSED",
  SUSPENDED: "SUSPENDED",
  FROZEN: "FROZEN",
  CLAIMED: "CLAIMED",
  READY_TO_CLAIM: "READY_TO_CLAIM"
};

export const translateStakingStatus = (status) => {
  const translations = {
    CREATED: "Created",
    IN_TERM: "In term",
    CLOSED: "Closed",
    SUSPENDED: "Suspended",
    FROZEN: "Frozen",
    CLAIMED: "Claimed",
    READY_TO_CLAIM: "Ready to claim"
  };

  if (translations[status]) {
    return translations[status];
  }

  return "";
};
