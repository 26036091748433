import StorageKeys from "./storageKeys";

/**
 * Set item to storage
 * @param key
 * @param value
 * @param exp
 * @returns
 */
const setItem = (key, value, exp = null) => {
  if (typeof window === "undefined") {
    return;
  }

  const data = {
    key,
    value,
    time: new Date(),
    exp
  };

  window.localStorage.setItem(key, JSON.stringify(data));
};

/**
 * Get item from storage
 * @param key
 * @returns Data stored in the key
 */
const getItem = (key, returnItemObj = false) => {
  if (typeof window === "undefined") {
    return;
  }

  const result = window.localStorage.getItem(key);
  if (result) {
    const json = JSON.parse(result);
    if (!json.exp) {
      if (returnItemObj) {
        return json;
      }
      return json.time;
    } else if (json.exp.getTime() > new Date().getTime()) {
      if (returnItemObj) {
        return json;
      }
      return json.time;
    } else {
      window.localStorage.removeItem(key);
      throw new Error(`DATA_EXPIRED`);
    }
  }
};

/**
 * Remove value stored in the key
 * @param key
 */
function removeItem(key) {
  if (!key) {
    throw new Error("Missing key or value");
  }

  window.localStorage.removeItem(key);
}

/**
 * Clear all data in storage
 */
function clear() {
  window.localStorage.clear();
}

const LocalStorage = {
  StorageKeys,
  setItem,
  getItem,
  removeItem,
  clear
};

export default LocalStorage;
