import React from "react";
import AccountsContextProvider from "./accountsContextProvider";
import StakingProductsContextProvider from "./stakingProductsContextProvider";
import UserContextProvider from "./userContextProvider";

const Store = ({ children }) => {
  return (
    <UserContextProvider>
      <AccountsContextProvider>
        <StakingProductsContextProvider>{children}</StakingProductsContextProvider>
      </AccountsContextProvider>
    </UserContextProvider>
  );
};

export default Store;
