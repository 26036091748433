import React, { useState, useEffect } from "react";
import { Input, Spin, Dropdown, Button, Menu } from "antd";
import { DownOutlined, MobileOutlined } from "@ant-design/icons";
import { boundError, showError, showSuccess } from "../utils";
import { ProfilePage, ProfilePageHead } from "../components";
import { SimpleButton } from "../components";
import UserServices from "../services/userServices";
import { countries } from "../constants/country";
import { updateUserApi } from "../apis";
import { SMSverifyModal } from "./SMSverifyModal";
import { v4 as uuidv4 } from "uuid";

const EmailAndPhoneRaw = () => {
  const userServices = UserServices.getInstance();
  const [loading, setLoading] = useState(false);
  // const [phoneArea, setPhoneArea] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [selectedCountry, setSelectedCountry] = useState({ dialCode: "+1" });
  const [requestId, setRequestId] = useState("");
  const [show2faPopup, setShow2faPopup] = useState(false);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    setLoading(true);
    const result = await userServices.getUserProfile();
    if (result) {
      setPhone(result?.phoneNumber);
      setEmail(result?.email ?? "");
      setSelectedCountry({
        dialCode: result?.phoneCountryCode ? "+" + result?.phoneCountryCode : "+1"
      });
    }
    setLoading(false);
  };

  function handleMenuClick(e) {
    setSelectedCountry(countries[e.key]);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      {countries.map((country, index) => {
        return (
          <Menu.Item key={index} icon={<MobileOutlined />}>
            {country.name + " " + country.dialCode}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const onSave = async ({ mfaCode }) => {
    try {
      if (phone && phone.length < 9) {
        showError("Please enter a valid phone number");
        return;
      }

      const uuid = uuidv4();
      if (!requestId) {
        setRequestId(uuid);
      }
      const phoneCountryCode = selectedCountry?.dialCode.replace("+", "");
      const result = await updateUserApi({
        requestId: requestId || uuid,
        phoneCountryCode,
        phoneNumber: phone,
        mfaCode
      });
      if (result?.statusCode == 207) {
        setShow2faPopup(true);
      } else {
        setRequestId("");
        setShow2faPopup(false);
        showSuccess("The phone number and email has been updated.");
      }
    } catch (err) {
      let error = err
      if (err == "phoneNumber must be a valid phone number.") {
        error = "Phone number must be valid."
      }
      setRequestId("");
      setShow2faPopup(false);
      showError(error);
    }
  };

  return (
    <ProfilePage>
      <Spin spinning={loading}>
        <ProfilePageHead title="Email and Phone" description="Change your security detail" />
        <div className="row" style={{ paddingTop: 20, fontWeight: "500", fontSize: 12, color: "#B1B5C3" }}>
          Email
        </div>
        <div className="row profile-info-field">{email}</div>
        <div className="row" style={{ paddingTop: 20, fontWeight: "500", fontSize: 12, color: "#B1B5C3" }}>
          Phone
        </div>
        <div className="row">
          <Dropdown overlay={menu} overlayStyle={{ zIndex: 9999, overflow: "scroll", maxHeight: "100vh" }}>
            <Button
              style={{
                fontWeight: "400",
                fontSize: 16,
                border: "2px solid #B1B5C4",
                borderRadius: 12,
                padding: 10,
                minHeight: 50,
                marginRight: 5
              }}
            >
              {selectedCountry?.dialCode} <DownOutlined />
            </Button>
          </Dropdown>
          <Input
            placeholder="Phone number"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value.replace(/[^\d]/g, ""));
            }}
            style={{
              width: 300,
              fontWeight: "400",
              fontSize: 16,
              border: "2px solid #B1B5C4",
              borderRadius: 12,
              padding: 10
            }}
          />
        </div>

        <div
          className="row"
          style={{ justifyContent: "flex-end", borderTop: "1px solid #D7D7D9", marginTop: 20, paddingTop: 20 }}
        >
          <SimpleButton onClick={onSave}>Save</SimpleButton>
        </div>
        <SMSverifyModal
          visible={show2faPopup}
          onOk={(mfaCode) => onSave({ mfaCode })}
          onCancel={() => {
            setShow2faPopup(false);
          }}
          phone={selectedCountry.dialCode + phone}
        />
      </Spin>
    </ProfilePage>
  );
};

export const EmailAndPhone = boundError(EmailAndPhoneRaw);
