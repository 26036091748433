import React from "react";
import { boundError } from "../utils";
import ErrorImg from "../resources/images/hasError.svg";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const ErrorPageRaw = () => {
  const navigate = useNavigate();
  return (
    <div className="error-page-container">
      <div
        style={{
          textAlign: "left"
        }}
      >
        <p
          style={{
            fontSize: 48,
            fontWeight: 500,
            marginBottom: 10
          }}
        >
          Oh! Error
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 400,
            margin: 0
          }}
        >
          We faced a problem ...
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 400,
            margin: 0
          }}
        >
          Don’t worry, everything will be fine.{" "}
        </p>
        <Button
            type="primary"
            onClick={() => navigate("/accounts")}
            style={{
              border: "1px solid #178FE1",
              borderRadius: 12,
              marginTop: 20,
              height: 64,
              width: 153,
              fontWeight: 500,
              fontSize: 16
            }}
          >
            Back to Home
          </Button>
      </div>
      <div>
        <img src={ErrorImg} alt="" />
      </div>
    </div>
  );
};

export const ErrorPage = boundError(ErrorPageRaw);
