import EventEmitter from "eventemitter3";
const eventEmitter = new EventEmitter();
const Emitter = {
  on: (event, fn) => eventEmitter.on(event, fn),
  once: (event, fn) => eventEmitter.once(event, fn),
  off: (event, fn) => eventEmitter.off(event, fn),
  emit: (event, payload) => eventEmitter.emit(event, payload)
};
Object.freeze(Emitter);

export const EmitterEvents = {
  SET_ACCOUNTS: "SET_ACCOUNTS",
  GET_ACCOUNTS: "GET_ACCOUNTS",
  SET_STAKING_PRODUCTS: "SET_STAKING_PRODUCTS",
  GET_STAKING_PRODUCTS: "GET_STAKING_PRODUCTS",
  SET_USER: "SET_USER",
  GET_USER: "GET_USER",
  REFRESH_STAKING_LIST: "REFRESH_STAKING_LIST",
  REFRESH_BANNER: "REFRESH_BANNER"
};

export default Emitter;
