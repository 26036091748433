import { CURRENCY } from "./currency";
class CardType {
  constructor(code, type, currency) {
    this.code = code;
    this.type = type;
    this.currency = currency;
  }
}

export const card_types = {
  NORMAL: "NORMAL",
  VIP: "VIP",
  SUPER_VIP: "SUPER_VIP"
};

export const CARD_TYPE = {
  NORMAL_USD: new CardType("NORMAL_USD", card_types.NORMAL, CURRENCY.USD.code),
  NORMAL_EUR: new CardType("NORMAL_EUR", card_types.NORMAL, CURRENCY.EUR.code),
  NORMAL_GBP: new CardType("NORMAL_GBP", card_types.NORMAL, CURRENCY.GBP.code)
};

export const VALID_CARD_TYPES = new Set(Object.values(CARD_TYPE).map((type) => type.code));

export const isValidCardType = (cardTypeCode) => VALID_CARD_TYPES.has(cardTypeCode);

export const getCardTypesByType = (type) => Object.values(CARD_TYPE).filter((cardType) => cardType.type === type);

export const getCardTypesByCode = (code) => Object.values(CARD_TYPE).find((cardType) => cardType.code === code);
