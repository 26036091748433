import React, { useState, useEffect, useContext } from "react";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BackBtn, SimpleButton, CancelBtn, StepMenu, ListCard, CutOffLine, AccountCard } from "../components";
import { boundError } from "../utils";
import UserServices from "../services/userServices";
import { AccountsContext } from "../hooks";
import { Divider, InputNumber } from "antd";

const ERC20AxiaWalletDepositRaw = () => {
  const navigate = useNavigate();
  const userServices = UserServices.getInstance();
  const [currentStep, setCurrentStep] = useState(0);
  const [accountList] = useContext(AccountsContext);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [depositAmount, setDepositAmount] = useState(0);

  useEffect(() => {
    userServices.fetchUserAccounts();
  }, []);

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <p
              className="margin-none"
              style={{
                display: "flex",
                lineHeight: "1.75rem",
                marginBottom: "1rem",
                fontWeight: 500,
                fontSize: "16px",
                color: "#23262F",
                textAlign: "left"
              }}
            >
              Select the account to deposit funds into
            </p>
            <div
              style={{
                border: "1px solid",
                borderColor: "rgba(243, 244, 246)"
              }}
            >
              {accountList.map((account) => {
                return (
                  <ListCard
                    key={`accountList-${account.publicId}`}
                    children={AccountCard(account)}
                    tailComponent={<RightOutlined />}
                    onClick={() => {
                      setSelectedAccount(account);
                      setCurrentStep((prev) => prev + 1);
                    }}
                  />
                );
              })}
            </div>
          </>
        );
      case 1:
        return (
          <>
            <h2
              style={{
                display: "flex",
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
                color: "#606060",
                marginBottom: "1rem",
                fontWeight: 500,
                textAlign: "left"
              }}
            >
              Enter the amount to be transferred
            </h2>
            <ListCard
              children={
                <>
                  <div
                    style={{
                      paddingLeft: "1rem",
                      display: "flex",
                      flex: 2,
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center"
                    }}
                  >
                    <InputNumber
                      style={{
                        width: "90%",
                        fontSize: 30,
                        fontWeight: "normal",
                        paddingLeft: "0.5rem"
                      }}
                      type="number"
                      controls={false}
                      bordered={false}
                      placeholder={0}
                      min={0}
                      step="0.00001"
                      onChange={(value) => {
                        setDepositAmount(value ? value : 0);
                      }}
                    />
                  </div>
                  <CutOffLine />
                  <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    <p className="margin-none">AXIA Wallet (Ethereum)</p>
                    <p className="margin-none" style={{ fontSize: 26 }}>
                      AXC
                    </p>
                    <p className="margin-none">{`23,275.18354 AXC`}</p>
                  </div>
                </>
              }
              onClick={() => {}}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <SimpleButton
                style={{
                  backgroundColor: "#178FE1",
                  padding: "0.5rem 0.75rem 0.5rem 0.75rem",
                  width: "300px",
                  borderRadius: 12,
                  border: "2px solid #178FE1"
                }}
                onClick={async () => {
                  setCurrentStep((prev) => prev + 1);
                }}
                children={
                  <p className="margin-none" style={{ color: "white", fontSize: "1rem", fontWeight: 500 }}>
                    Continue deposit request
                    <RightOutlined style={{ color: "white", paddingLeft: "1rem" }} />
                  </p>
                }
              />
              <CancelBtn toPath="/deposit" />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <ul
              style={{
                fontSize: "1rem",
                textAlign: "left",
                backgroundColor: "white",
                padding: "1rem 2rem"
              }}
            >
              <li>
                <b>From</b>: AXIA Wallet (Ethereum)
              </li>
              <li>
                <b>To</b>: AC102223
              </li>
              <li>
                <b>Amount</b>: 236.25000 AXC
              </li>
            </ul>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <SimpleButton
                style={{
                  padding: "0.5rem 0.75rem 0.5rem 0.75rem",
                  width: "100%",
                  marginTop: "2rem"
                }}
                onClick={() => {}}
                children={
                  <p className="margin-none" style={{ color: "white", fontSize: "0.875rem", fontWeight: 500 }}>
                    Send Now!
                  </p>
                }
              />
              <CancelBtn toPath="/deposit" />
            </div>
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "85%", margin: "40px auto" }}>
      <BackBtn
        onClick={() => {
          if (currentStep > 0) {
            setCurrentStep((prev) => prev - 1);
          } else {
            navigate("/deposit");
          }
        }}
      />
      <div className="row page-title" style={{ paddingTop: 0 }}>
        Deposit
      </div>
      <div className="card-apply-container" style={{ width: "100%" }}>
        <StepMenu steps={["Select account", "Select amount", "Overview"]} currentStep={currentStep} />
        <Divider />
        {renderStep()}
      </div>
    </div>
  );
};

export const ERC20AxiaWalletDeposit = boundError(ERC20AxiaWalletDepositRaw);
