import { loginNotificationApi } from "../apis";

export default class NotificationServices {
  static notificationServices;

  static getInstance() {
    if (!this.notificationServices) {
      this.notificationServices = new NotificationServices();
    }

    return this.notificationServices;
  }

  loginNotification = async () => {
    return await loginNotificationApi();
  };
}
