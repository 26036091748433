import React, { useState, useEffect } from "react";
import { Spin, Button } from "antd";
import { boundError, showError, showSuccess } from "../utils";
import { ProfilePage, ProfilePageHead } from "../components";
import UserServices from "../services/userServices";
import { getUserPendingDocument, cancelInfoChangeRequest, downloadDocumentById } from "../apis";
import { Link } from "react-router-dom";
import moment from "moment";
import Modal from "react-modal";
import { KeyValue2 } from "../components";

const UserDocumentRaw = () => {
  const userServices = UserServices.getInstance();
  const [loading, setLoading] = useState(false);
  const [documentRecords, setDocumentRecords] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [modalData, setModalData] = useState({});
  const [editDisabled, setEditDisabled] = useState(false);
  const [cancelDisabled, setCancelDisabled] = useState(false);

  useEffect(() => {
    fetchDocumentRecords();
  }, []);

  const fetchDocumentRecords = async () => {
    const data = await getUserPendingDocument();
    setDocumentRecords(data);
  };

  useEffect(() => {
    setEditDisabled(modalData.status !== "INCOMPLETE");
    setCancelDisabled(["COMPLETED", "REJECTED", "CANCELED"].includes(modalData.status));
  }, [modalData]);

  const LinkButton = ({ enable, width, toPath = "/", text }) => {
    return (
      <Link
        to={toPath}
        style={{
          padding: "0.5rem 1rem",
          borderRadius: 12,
          backgroundColor: `${enable ? "#3a82f6" : "lightgrey"}`,
          border: `2px solid ${enable ? "#3a82f6" : "lightgrey"}`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: 85
        }}
      >
        <p className="margin-none" style={{ color: "white", fontSize: "1rem" }}>
          {text}
        </p>
      </Link>
    );
  };

  const formatDocumentType = ({ documentType }) => {
    switch (documentType) {
      case "PROOF_OF_TAX_ID":
        return "Proof of Tax ID";
      case "PROOF_OF_TAX_RETURN":
        return "Proof of Tax Return";
      case "BUSINESS_PROOF_OF_INCORPORATE_CERTIFICATE":
        return "Proof of Business Incorporate Certificate";
      case "BUSINESS_PROOF_OF_ADDRESS":
        return `Proof of Business Address`;
      case "CARD_KYC":
        return `Debit Card Identity Verification - Limit Increase Application`;
      case "BENEFICIARY_ACCOUNT":
        return `Beneficiary Account Application`;
      case "CHANGE_OF_ID_ADDRESS":
        return `Change of Address/ID`;
      default:
        return "Other Document";
    }
  };

  const DocumentStatus = ({ status }) => {
    let color, backgroundColor;
    switch (status) {
      case "INCOMPLETE":
        color = "grey";
        backgroundColor = "rgba(224, 224, 224, 1)";
        break;
      case "SUBMITTED":
      case "COMPLETED":
        color = "rgba(0, 153, 0, 1)";
        backgroundColor = "rgba(153, 255, 153, 0.8)";
        break;
      case "REJECTED":
      case "CANCELED":
        color = "red";
        backgroundColor = "rgba(255, 204, 204, 1)";
        break;
    }
    return (
      <p
        className="margin-none"
        style={{
          minWidth: 100,
          fontWeight: 500,
          fontSize: 14,
          padding: "2px 8px",
          borderRadius: 10,
          color,
          backgroundColor
        }}
      >
        {status === "COMPLETED" ? "APPROVED" : status}
      </p>
    );
  };

  const DocumentItem = ({ item }) => {
    return (
      <div
        className="accounts-transaction-item-container cursor-pointer"
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          alignItems: "center",
          justifyContent: "space-between"
        }}
        key={item.id}
        onClick={() => {
          setModalData(item);
          setModalOpened(true);
        }}
      >
        <p className="padding-none margin-none" style={{ fontSize: 18, fontWeight: "500", textAlign: "left" }}>
          {item && moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
        </p>
        <p className="padding-none margin-none" style={{ fontSize: 18, fontWeight: "500", textAlign: "left" }}>
          {formatDocumentType(item)}
        </p>
        <DocumentStatus status={item.status} />
      </div>
    );
  };

  const closeModal = () => {
    setModalOpened(false);
    setModalData({});
  };

  const renderModalData = () => {
    const { taxId, taxIdCountryCode, cardId } = modalData.info ?? {};
    return (
      <>
        {taxId && <KeyValue2 keyName="Tax ID" value={taxId} />}
        {taxIdCountryCode && <KeyValue2 keyName="Tax ID Country Code" value={taxIdCountryCode} />}
        {cardId && <KeyValue2 keyName="Card ID" value={cardId} />}
      </>
    );
  };

  const downloadDocument = (documentId) => {
    downloadDocumentById(documentId);
  };
  const onCancel = async () => {
    await cancelInfoChangeRequest(modalData.id);
    await fetchDocumentRecords();
    showSuccess("Successfully canceled your request");
    closeModal();
  };

  return (
    <ProfilePage>
      <Spin spinning={loading}>
        <ProfilePageHead title="Documents" description="Manage your documents" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 30,
            marginBottom: 30,
            gap: 30
          }}
        >
          <div style={{ width: "100", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <LinkButton text="Upload New Document" toPath="/upload-document" enable={true} />
          </div>

          {documentRecords && documentRecords.length > 0 ? (
            <div style={{ backgroundColor: "white", borderRadius: 20, margin: "0 40" }}>
              {documentRecords.map((item, index) => {
                return (
                  <div key={index}>
                    <DocumentItem item={item} />
                  </div>
                );
              })}
            </div>
          ) : (
            <p style={{ fontSize: "1rem", marginTop: "1rem" }}>No document found</p>
          )}
        </div>
        {modalData && (
          <Modal
            isOpen={modalOpened}
            onRequestClose={closeModal}
            ariaHideApp={false}
            style={{
              overlay: {
                zIndex: 9999,
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                padding: "auto"
              },
              content: {
                margin: "auto",
                maxWidth: 800,
                height: "100vh",
                overflow: "auto",
                padding: "auto",
                backgroundColor: "transparent",
                border: "none"
              }
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: 10,
                padding: 30,
                marginTop: 20,
                marginBottom: 100
              }}
            >
              <div style={{ borderBottom: "1px solid lightgrey", paddingBottom: 10 }}>
                <p className="margin-none" style={{ fontWeight: "500", fontSize: 20 }}>
                  {formatDocumentType(modalData)}
                </p>
              </div>

              <div style={{ marginTop: 20 }}>
                {/* Info field */}
                {renderModalData()}
                {/* Document related field */}
                <div style={{ marginTop: 20 }}>
                  <div className="key-value-2" style={{ fontSize: 16 }}>
                    <p className="margin-none" style={{ fontWeight: "500", minWidth: 240 }}>
                      Document
                    </p>
                    {modalData.documentId ? (
                      <div className="back-btn" onClick={() => downloadDocument(modalData.documentId)}>
                        {modalData.documentName}
                      </div>
                    ) : modalData.multipleDocuments ? (
                      Object.values(modalData.multipleDocuments).map((doc) => {
                        return (
                          <div className="back-btn" onClick={() => downloadDocument(doc.documentId)}>
                            {doc.documentName}
                          </div>
                        );
                      })
                    ) : (
                      <p className="margin-none">Document not uploaded yet</p>
                    )}
                  </div>

                  {modalData.memo && <KeyValue2 keyName="Document Memo" value={modalData.memo} />}
                </div>
                {/* Comment field */}
                <div style={{ marginTop: 20 }}>
                  <KeyValue2 keyName="Date" value={moment(modalData.createdAt).format("YYYY-MM-DD HH:mm:ss")} />
                  <KeyValue2 keyName="Last Updated" value={moment(modalData.updatedAt).format("YYYY-MM-DD HH:mm:ss")} />
                  <KeyValue2 keyName="Status" value={modalData.status} />
                  {modalData.rejectionReason && (
                    <KeyValue2 keyName="Rejection Reason" value={modalData.rejectionReason} />
                  )}
                </div>
              </div>

              <div className="row" style={{ marginTop: 20, alignItems: "center", justifyContent: "space-between" }}>
                <div className="row">
                  <Button type="primary" style={{ border: 0, borderRadius: 5, width: 80 }} disabled={editDisabled}>
                    <Link to={`/upload-document/${modalData.id}`}>Edit</Link>
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      border: 0,
                      borderRadius: 5,
                      marginLeft: 20,
                      width: 80,
                      backgroundColor: cancelDisabled ? "rgb(245, 245, 245)" : "red"
                    }}
                    onClick={onCancel}
                    disabled={cancelDisabled}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="row">
                  <Button
                    type="primary"
                    style={{ borderRadius: 5, marginLeft: 20 }}
                    onClick={() => {
                      setModalOpened(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Spin>
    </ProfilePage>
  );
};

export const UserDocument = boundError(UserDocumentRaw);
