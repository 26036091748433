import React from "react";

export const KeyValue1 = ({ keyName, value }) => {
  return (
    <div>
      <p className="margin-none" style={{ fontWeight: "600" }}>
        {keyName}
      </p>
      <p className="margin-none">{value}</p>
    </div>
  );
};
