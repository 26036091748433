import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

export const BackBtn = ({ text, onClick, previousPath, style, ...props }) => {
  const navigate = useNavigate();

  return (
    <div
      className="back-btn"
      onClick={
        onClick ??
        (() => {
          navigate(previousPath ?? -1);
        })
      }
      {...props}
    >
      <LeftOutlined style={{ marginRight: 10 }} />
      {text ?? "Go Back"}
    </div>
  );
};
