import React, { useEffect, useContext, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { boundError } from "./utils";
import { MainLayout } from "./components/MainLayout";
import {
  Home,
  Login,
  SignUp,
  SignUpConfirm,
  ForgotPassword,
  ResetPassword,
  Accounts,
  AccountDetail,
  Transfer,
  Deposit,
  Cards,
  GiftCards,
  Profile,
  UserDocument,
  UploadDocument,
  EmailAndPhone,
  ChangePassword,
  TwoFactor,
  Notifications,
  Services,
  Messages,
  MfaLogin,
  BetweenAccounts,
  BetweenMembers,
  ExternalTransfer,
  WireTransfer,
  WireDeposit,
  Beneficiaries,
  DigitalCurrencyDeposit,
  CreditDebitDeposit,
  Stakings,
  StakingDetail,
  IndividualOnboarding,
  BusinessOnboarding,
  UserTypeSelect,
  TransactionGateway,
  PublicOutlet,
  ErrorPage,
  MaintenancePage,
  BuyGiftCard,
  GiftCardDetail,
  ExternalAxtendTransfer,
  CustodyDeposit,
  CustodyTransfer,
  PublicKycVerifyPage,
  CustodyDepositV2,
  CustodyTransferV2
} from "./routes";
import { OnboardingDisabledScreen } from "./components/Onboarding/OnboardingDisabledScreen";
import { CardApplyPage, ProfileMenuWrapper } from "./components";
import { useDispatch } from "react-redux";
import { getBankSettings, getCurrentAuthenticatedUser, initAxios } from "./apis";
import { loginSuccess } from "./redux/actions";
import { CardDetailPage } from "./components/Card/CardDetailPage";
import { UserRegistrationStatusContext, UserContext, useQuery } from "./hooks";
import UserServices from "./services/userServices";
import { showError } from "./utils/messages";
import { isValidForFeature, FEATURE_NAMES } from "./utils";
import { USER_STATUS } from "./constants/userStatus";
// check react-router-dom v6 documentation here: https://github.com/remix-run/react-router/blob/main/docs/getting-started/tutorial.md

function App() {
  const dispatch = useDispatch();
  const [registrationStatus, setRegistrationStatus] = useContext(UserRegistrationStatusContext);
  const userServices = UserServices.getInstance();
  const [isLoading, setIsLoading] = useState(true);
  const [isMaintaining, setIsMaintaining] = useState(false);
  const [user] = useContext(UserContext);

  const query = useQuery();
  const tk = query.get("tk");

  const onAppLoad = async () => {
    try {
      if (tk) {
        initAxios({ overwriteIdToken: tk });
      }
      const user = await getCurrentAuthenticatedUser();
      dispatch(loginSuccess(user));
      await checkUserRegistrationStatus();
    } catch (err) {
      console.log("No authenticated user.", err);
    }
  };

  const checkUserRegistrationStatus = async () => {
    try {
      const { registrationCompleted, userType, navigateTo } = await userServices.checkUserRegistrationStatus();
      setRegistrationStatus({ registrationCompleted, userType, navigateTo });
    } catch (e) {
      if (e?.response?.status == 503) {
        return checkMaintenanceMode(true);
      }
      showError(e);
      userServices.appSignOut();
    }
  };

  const checkMaintenanceMode = async (isMaintaining) => {
    setIsMaintaining(isMaintaining || false);
  };

  useEffect(() => {
    onAppLoad().then(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return null;
  }

  if (tk) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/accounts" element={<Accounts />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
  }

  if (isMaintaining) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MaintenancePage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
  }

  if (!registrationStatus?.registrationCompleted) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home checkMaintenanceMode={checkMaintenanceMode} />} />
          <Route path="/" element={<PublicOutlet />}>
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="/" element={<MainLayout />}>
            <Route exact path="/onboarding/user-type-select" element={<UserTypeSelect />} />
            <Route exact path="/onboarding/disabled" element={<OnboardingDisabledScreen />} />
            <Route exact path="/onboarding/individual/*" element={<IndividualOnboarding />} />
            <Route exact path="/onboarding/business/*" element={<BusinessOnboarding />} />
          </Route>

          <Route path="mfa-login" element={<MfaLogin />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="sign-up-confirm" element={<SignUpConfirm />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="kyc-verify-public" element={<PublicKycVerifyPage />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
  }

  // suspend check
  if ((user && user.status === USER_STATUS.SUSPENDED) || !user) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home checkMaintenanceMode={checkMaintenanceMode} />} />
          <Route path="/" element={<PublicOutlet />}>
            <Route path="login" element={<Login />} />
          </Route>

          <Route path="/" element={<MainLayout />}>
            <Route exact path="/accounts" element={<Accounts />} />

            {isValidForFeature({ user, featureName: FEATURE_NAMES.MENU_DEPOSIT }) && (
              <>
                <Route exact path="/deposit" element={<Deposit />} />
                {isValidForFeature({ user, featureName: FEATURE_NAMES.CUSTODY }) && (
                  <Route exact path="/deposit/axia-network-transfer" element={<CustodyTransfer />} />
                )}
                {isValidForFeature({ user, featureName: FEATURE_NAMES.CUSTODY_V2 }) && (
                  <Route exact path="/deposit/axia-network-v2-transfer" element={<CustodyTransferV2 />} />
                )}
                <Route exact path="/deposit/wire-deposit" element={<WireDeposit />} />
                <Route exact path="/deposit/digital-currency-deposit" element={<DigitalCurrencyDeposit />} />
                <Route exact path="/deposit/credit-debit-deposit" element={<CreditDebitDeposit />} />
                <Route exact path="/deposit/erc20-axia-wallet-deposit" element={<TransactionGateway />} />
                <Route exact path="/deposit/axia-network-deposit" element={<CustodyDeposit />} />
                {isValidForFeature({ user, featureName: FEATURE_NAMES.CUSTODY_V2 }) && (
                  <Route exact path="/deposit/axia-network-v2-deposit" element={<CustodyDepositV2 />} />
                )}
              </>
            )}
          </Route>
          <Route path="mfa-login" element={<MfaLogin />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="sign-up-confirm" element={<SignUpConfirm />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
  }

  // consent check
  if (
    user &&
    new Date(user?.createdAt || null) < new Date("2022-07-29T00:00:00") &&
    isValidForFeature({ user, featureName: FEATURE_NAMES.TOKEN_MIGRATION_CONSENT }) === true &&
    !user?.consent?.AXIA_NETWORK_V2_TOKEN_MIGRATION?.agreed
  ) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/" element={<PublicOutlet />}>
            <Route path="login" element={<Login />} />
          </Route>

          <Route path="mfa-login" element={<MfaLogin />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="sign-up-confirm" element={<SignUpConfirm />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="kyc-verify-public" element={<PublicKycVerifyPage />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home checkMaintenanceMode={checkMaintenanceMode} />} />
        <Route path="/" element={<PublicOutlet />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route path="/" element={<MainLayout />}>
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/maintenance" element={<MaintenancePage />} />
          <Route path="/accounts" element={<Accounts />} />

          {isValidForFeature({ user, featureName: FEATURE_NAMES.MENU_CARDS }) && (
            <>
              <Route path="/cards" element={<Cards />} />
              {isValidForFeature({ user, featureName: FEATURE_NAMES.CARD }) && (
                <Route exact path="/cards/apply" element={<CardApplyPage />} />
              )}
              <Route exact path="/cards/:cardId" element={<CardDetailPage />} />
            </>
          )}

          {isValidForFeature({ user, featureName: FEATURE_NAMES.MENU_GIFT_CARDS }) && (
            <>
              <Route path="/gift-cards" element={<GiftCards />} />
              {isValidForFeature({ user, featureName: FEATURE_NAMES.GIFT_CARD }) && (
                <Route path="/gift-cards/buy" element={<BuyGiftCard />} />
              )}
              <Route path="/gift-cards/:id" element={<GiftCardDetail />} />
            </>
          )}

          {isValidForFeature({ user, featureName: FEATURE_NAMES.MENU_STAKINGS }) && (
            <>
              <Route exact path="/stakings/:stakingId" element={<StakingDetail />} />
              <Route path="/stakings" element={<Stakings />} />
            </>
          )}

          {isValidForFeature({ user, featureName: FEATURE_NAMES.MENU_TRANSFER }) && (
            <>
              <Route path="/transfer" element={<Transfer />} />
              <Route exact path="/transfer/between-accounts" element={<BetweenAccounts />} />
              <Route exact path="/transfer/between-members" element={<BetweenMembers />} />
              <Route exact path="/transfer/wire-transfer" element={<WireTransfer />} />
              <Route exact path="/transfer/external-transfer" element={<ExternalTransfer />} />
              <Route exact path="/transfer/external-axtend-transfer" element={<ExternalAxtendTransfer />} />
              {/* <Route exact path="/transfer/external-axtend-transfer" element={<ExternalAxtendTransfer />} /> */}
              {isValidForFeature({ user, featureName: FEATURE_NAMES.CUSTODY }) && (
                <Route exact path="/transfer/axia-network-transfer" element={<CustodyTransfer />} />
              )}
              {isValidForFeature({ user, featureName: FEATURE_NAMES.CUSTODY_V2 }) && (
                <Route exact path="/transfer/axia-network-v2-transfer" element={<CustodyTransferV2 />} />
              )}
            </>
          )}

          {isValidForFeature({ user, featureName: FEATURE_NAMES.MENU_DEPOSIT }) && (
            <>
              <Route path="/deposit" element={<Deposit />} />
              {isValidForFeature({ user, featureName: FEATURE_NAMES.CUSTODY }) && (
                <Route exact path="/deposit/axia-network-transfer" element={<CustodyTransfer />} />
              )}
              {isValidForFeature({ user, featureName: FEATURE_NAMES.CUSTODY_V2 }) && (
                <Route exact path="/deposit/axia-network-v2-transfer" element={<CustodyTransferV2 />} />
              )}
              <Route exact path="/deposit/wire-deposit" element={<WireDeposit />} />
              <Route exact path="/deposit/digital-currency-deposit" element={<DigitalCurrencyDeposit />} />
              <Route exact path="/deposit/credit-debit-deposit" element={<CreditDebitDeposit />} />
              <Route exact path="/deposit/erc20-axia-wallet-deposit" element={<TransactionGateway />} />
              <Route exact path="/deposit/axia-network-deposit" element={<CustodyDeposit />} />
              {isValidForFeature({ user, featureName: FEATURE_NAMES.CUSTODY_V2 }) && (
                <Route exact path="/deposit/axia-network-v2-deposit" element={<CustodyDepositV2 />} />
              )}
            </>
          )}

          <Route exact path="/Beneficiaries" element={<Beneficiaries />} />
          <Route exact path="/onboarding/user-type-select" element={<UserTypeSelect />} />
          <Route exact path="/onboarding/individual/*" element={<IndividualOnboarding />} />
          <Route exact path="/onboarding/business/*" element={<BusinessOnboarding />} />
          <Route path="/profile" element={<ProfileMenuWrapper component={<Profile />} />} />
          {isValidForFeature({ user, featureName: FEATURE_NAMES.UPLOAD_DOCUMENT }) && (
            <Route path="/user-document" element={<ProfileMenuWrapper component={<UserDocument />} />} />
          )}
          {isValidForFeature({ user, featureName: FEATURE_NAMES.UPLOAD_DOCUMENT }) && (
            <Route path="/upload-document" element={<UploadDocument />} />
          )}
          <Route path="/upload-document/:id" element={<UploadDocument />} />
          <Route path="/email-and-phone" element={<ProfileMenuWrapper component={<EmailAndPhone />} />} />
          <Route path="/change-password" element={<ProfileMenuWrapper component={<ChangePassword />} />} />
          <Route path="/two-factor" element={<ProfileMenuWrapper component={<TwoFactor />} />} />
          <Route path="/notifications" element={<ProfileMenuWrapper component={<Notifications />} />} />
          <Route path="/services" element={<Services />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/accounts/:id" element={<AccountDetail />} />
        </Route>
        <Route path="/mfa-login" element={<MfaLogin />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-up-confirm" element={<SignUpConfirm />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default boundError(App);
