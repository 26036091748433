import { bankApi } from "./authenticatedAxios";
import {
  fileDownloadResponseHandler,
  fileDownloadResponseWithFilenameHandler,
  responseDataHandler
} from "./responseHandler";
import { concatApiQuery } from "../utils/strings";

export const getUserPendingDocument = async (documentType) => {
  let url = "/document/pending";
  if (documentType && documentType !== "") {
    url = `${url}?documentType=${documentType}`;
  }
  return bankApi.get(url).then(({ data }) => responseDataHandler(data));
};

export const getUserPendingDocumentById = async (id) =>
  bankApi.get(`/document/pending?id=${id}`).then(({ data }) => responseDataHandler(data));

export const cancelInfoChangeRequest = async (id) =>
  bankApi.post("/document/cancel", { id }).then(({ data }) => responseDataHandler(data));

export const downloadDocumentById = (id) =>
  bankApi
    .get(`/document/download/${id}`, {
      responseType: "blob"
    })
    .then((response) => fileDownloadResponseWithFilenameHandler(response));

export const downloadEmptyBeneficiaryAccountForm = (id) =>
  bankApi
    .get(`/document/download/beneficiaryForm`, {
      responseType: "blob"
    })
    .then((response) => fileDownloadResponseWithFilenameHandler(response));

export const uploadPendingDocument = async ({ id, documentType, file }) => {
  const fd = new FormData();
  if (id) {
    fd.append("pendingDocumentId", id);
  }
  fd.append("documentType", documentType);
  fd.append("file", file);
  return bankApi.post("/document/upload", fd).then(({ data }) => responseDataHandler(data));
};

export const updateUserDocumentInfo = (dateToUpdate) =>
  bankApi.post("/document/submit", dateToUpdate).then(({ data }) => responseDataHandler(data));
