import React from "react";
import { boundError } from "../utils";
import { Select, Input, Button } from "antd";
const { Option } = Select;

const DropDownSelectorRaw = ({ options, onChange, style, settings }) => {
  return (
    <Select style={{ ...style, display: "flex" }} onChange={onChange} {...settings}>
      {options.map((option, index) => {
        return (
          <Option value={option.code} key={index}>
            {`${option.name} - ${option.code}`}
          </Option>
        );
      })}
    </Select>
  );
};

export const DropDownSelector = boundError(DropDownSelectorRaw);
