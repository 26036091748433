import logo_btc from "./bitcoin-btc-logo.png";
import logo_eth from "./ethereum-eth-logo.png";
import logo_axc from "./axia-axc-logo.png";
import logo_bch from "./bitcoin-cash-bch-logo.png";
import logo_doge from "./dogecoin-doge-logo.png";
import logo_ltc from "./litecoin-ltc-logo.png";
import logo_usdt from "./tether-usdt-logo.png";
import logo_bnb from "./BNB.svg";
import logo_usdc from "./USDC.svg";
import logo_usdtt from "./USDTT.svg";
import logo_usax from "./USAX.png";

const currencyImageMap = {
  BTC: logo_btc,
  ETH: logo_eth,
  AXC: logo_axc,
  BCH: logo_bch,
  DOGE: logo_doge,
  LTC: logo_ltc,
  USDT: logo_usdt,
  BNB: logo_bnb,
  USDC: logo_usdc,
  USDTT: logo_usdtt,
  "BNB-BSC": logo_bnb,
  USAX: logo_usax
};

export {
  currencyImageMap,
  logo_btc,
  logo_eth,
  logo_axc,
  logo_bch,
  logo_doge,
  logo_ltc,
  logo_usdt,
  logo_bnb,
  logo_usdc,
  logo_usdtt,
  logo_usax
};
