import React, { useContext, useState, useEffect } from "react";
import { Menu, Dropdown, Button, Spin } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { boundError, formatAmount, showError } from "../../utils";
import { getTransactionFromAccountsApi } from "../../apis/user";
import { CURRENCY } from "../../constants";
import Big from "big.js";

const AccountsAndStakingSelectorRaw = ({
  currency,
  onAccountSelected,
  selectedAccount,
  placeholder = "Please select an account",
  style,
  transactionType,
  validAmount,
  validAmountCurrency,
  validSpendableAmount,
  displaySpendableAmount = false
}) => {
  const [selectedAcc, setSelectedAcc] = useState(selectedAccount);
  const [accountsAndStakings, setAccountsAndStakings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAccounts();
  }, [validAmount, validAmountCurrency, validSpendableAmount]);

  const fetchAccounts = async () => {
    try {
      setLoading(true);
      let result;
      if (validAmount || (validSpendableAmount && validAmountCurrency)) {
        result = await getTransactionFromAccountsApi({
          transactionType,
          displayCurrency: currency ?? CURRENCY.USD.code,
          validAmount,
          validAmountCurrency,
          validSpendableAmount
        });
      } else {
        result = await getTransactionFromAccountsApi({
          transactionType,
          displayCurrency: currency ?? CURRENCY.USD.code
        });
      }

      const data = [];
      if (result?.accounts) {
        for (let account of result?.accounts) {
          data.push({ ...account, from: "ACCOUNT" });
        }
      }
      if (result?.stakings) {
        for (let staking of result?.stakings) {
          data.push({ ...staking, from: "STAKING" });
        }
      }

      setAccountsAndStakings(data);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const getAccountStr = (account) => {
    let str = placeholder;

    if (account?.type) {
      str = account?.from;
    }
    if (account?.publicId) {
      str = str + (str != "" ? ":" : "") + account?.publicId;
    }
    // if (displaySpendableAmount === true && account?.spendableAmount) {
    //   str = str + (str != "" ? " -- " : "") + formatAmount(account?.spendableAmount, account?.currency);
    // } else

    if (account?.amount) {
      str = str + (str != "" ? " -- " : "") + formatAmount(account?.amount, account?.currency);
    }
    if (account?.currency) {
      str = str + (str != "" ? " " : "") + account?.currency;
    }

    return str;
  };

  const getAccountTitleStr = (account) => {
    let str = placeholder;

    if (account?.type) {
      str = account?.from;
    }
    if (account?.publicId) {
      str = str + (str != "" ? ": " : "") + account?.publicId;
    }

    return str;
  };

  const getAccountAmountStr = (account) => {
    let str = "";

    let totalAmount = Big(0);
    if (account?.amount) {
      totalAmount = totalAmount.plus(account?.amount);
    }

    // if (account?.interestAmount) {
    //   totalAmount = totalAmount.plus(account?.interestAmount);
    // }

    str = formatAmount(totalAmount?.toNumber(), account?.currency);

    if (account?.currency) {
      str = str + (str != "" ? " " : "") + account?.currency;
    }

    return str;
  };

  const getAccountSpendableAmountStr = (account) => {
    let str = "";

    if (account?.spendableAmount) {
      str = formatAmount(account?.spendableAmount, account?.currency);
      if (account?.currency) {
        str = str + (str != "" ? " " : "") + account?.currency;
      }
    }

    return str;
  };

  const handleMenuClick = (e) => {
    const result = accountsAndStakings.find((acc) => acc.id === e?.key);
    setSelectedAcc(result);
    if (onAccountSelected) {
      onAccountSelected(result);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {loading === true ? (
        <div
          style={{
            height: 30,
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Spin spinning={true} style={{ width: "100%" }} tip={"Loading"} />
        </div>
      ) : (
        accountsAndStakings.map((option, index) => {
          return (
            <Menu.Item key={option?.id}>
              {/* {getAccountStr(option)} */}
              <div>
                <div className="row">
                  <UserOutlined />
                  <p className="margin-none" style={{ marginLeft: 10, fontWeight: "500" }}>
                    {getAccountTitleStr(option)}
                  </p>
                  <div style={{ flex: 1 }} />
                  <p className="margin-none" style={{ fontWeight: "500" }}>
                    {getAccountAmountStr(option)}
                  </p>
                </div>

                {getAccountSpendableAmountStr(option) !== "" && (
                  <div className="row">
                    <div style={{ flex: 1 }} />
                    <p className="margin-none" style={{ fontWeight: "400", fontSize: 12, color: "grey" }}>
                      {"Total Spendable: " + getAccountSpendableAmountStr(option)}
                    </p>
                  </div>
                )}
              </div>
            </Menu.Item>
          );
        })
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      overlayStyle={{
        zIndex: 9999,
        overflow: "scroll",
        maxHeight: "60vh",
        boxShadow: "rgba(0, 0, 0, 0.28) 2px 2px 10px",
        backgroundColor: "white"
      }}
      trigger="click"
    >
      <Button
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: "12px",
          border: "2px solid #B1B5C4",
          padding: "10px 20px",
          height: "unset",
          ...style
        }}
      >
        <div style={{ overflow: "hidden", textOverflow: "clip", flex: 1 }}>
          {selectedAcc === null ? placeholder : getAccountStr(selectedAcc)}
        </div>
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export const AccountsAndStakingSelector = boundError(AccountsAndStakingSelectorRaw);
