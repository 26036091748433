import React, { useState, useEffect, useContext } from "react";
import { boundError, formatAmount } from "../../utils";
import moment from "moment";
import Big from "big.js";
import logo from "../../resources/AXIA_statement_logo.png";
import { UserContext } from "../../hooks";
import { getCountryNameByCode, TRANSACTION_DIRECTION, TRANSACTION_TYPE } from "../../constants";
import { getConversionRateApi } from "../../apis";

const AccountsStatementRaw = ({ account, transactions, ref, start, end }) => {
  const [totalWithdrawal, setTotalWithdrawal] = useState("0");
  const [totalDeposit, setTotalDeposit] = useState("0");
  const [user] = useContext(UserContext);
  const currentDate = moment().format("MMM DD/YY");
  const [axcToUsdRate, setAxcToUsdRate] = useState(0);

  useEffect(() => {
    fetchConversionRate();
    let withdrawal = new Big(0);
    let deposit = new Big(0);
    transactions.forEach((item) => {
      if (item.direction === TRANSACTION_DIRECTION.WITHDRAWAL) withdrawal = withdrawal.plus(new Big(item.amount));
      if (item.direction === TRANSACTION_DIRECTION.DEPOSIT) deposit = deposit.plus(new Big(item.amount));
    });
    setTotalWithdrawal(withdrawal.toFixed());
    setTotalDeposit(deposit.toFixed());
  }, [transactions]);

  const fetchConversionRate = async () => {
    const data = await getConversionRateApi("AXC", "USD");
    setAxcToUsdRate(data.conversionRate);
  };

  const getUserAddress = () => {
    if (!user) return "";
    const { addressLine1, addressLine2, addressCity, addressState, addressPostalCode, addressCountry } = user;
    const combinedAddress =
      `${addressLine1 && addressLine1 !== "" ? addressLine1 : ""}` +
      `${addressLine2 && addressLine2 !== "" ? ` ${addressLine2}` : ""}`.trim();
    const addressLine = combinedAddress === "" ? "" : `${combinedAddress},`;
    const city = addressCity && addressCity !== "" ? `${addressCity}, ` : "";
    const state = addressState && addressState !== "" ? addressState : "";
    const postalCode = addressPostalCode && addressPostalCode !== "" ? `${addressPostalCode},` : "";
    const country = addressCountry && addressCountry !== "" ? getCountryNameByCode(addressCountry) : "";
    return `${addressLine} ${city} ${state} ${postalCode} ${country}`;
  };

  const getStatementDateRange = () => {
    start = moment(start ?? account.createdAt).format("MMM DD/YY");
    end = end ? moment(end).format("MMM DD/YY") : currentDate;
    return `${start} - ${end}`;
  };

  const TransactionItem = ({ item }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          textAlign: "left",
          alignItems: "center",
          margin: "1rem 5px"
        }}
      >
        <p className="margin-none" style={{ flex: 1 }}>
          {moment(item.createdAt).format("MMM DD/YY")}
        </p>
        <div style={{ flex: 3 }}>
          <p className="margin-none">{item.description}</p>
          {/* <p className="margin-none"></p> */}
        </div>

        <p className="margin-none" style={{ flex: 1 }}>
          {item.direction === TRANSACTION_DIRECTION.WITHDRAWAL
            ? `${formatAmount(item.amount, item.currency)} ${item.currency}`
            : ""}
        </p>
        <p className="margin-none" style={{ flex: 1 }}>
          {item.direction === TRANSACTION_DIRECTION.DEPOSIT
            ? `${formatAmount(item.amount, item.currency)} ${item.currency}`
            : ""}
        </p>
        <p className="margin-none" style={{ flex: 1, textAlign: "right" }}>
          {`${formatAmount(item.balance, item.currency)} ${item.currency}`}
        </p>
      </div>
    );
  };

  return (
    <div ref={ref} style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: "1rem" }}>
      <div className="row" style={{ margin: 0, padding: 0, width: "100%", marginBottom: "1rem" }}>
        <p style={{ fontSize: 20, fontWeight: "500", margin: 0, padding: 0 }}>Statement of Account</p>
        <div style={{ flex: 1 }} />
        <img src={logo} alt="" style={{ height: 30 }} />
      </div>

      <div
        style={{
          width: "100%",
          display: "grid",
          gap: "1rem",
          gridTemplateColumns: "repeat(2, 1fr)",
          textAlign: "left",
          marginBottom: "1rem"
        }}
      >
        <div
          style={{
            padding: 20,
            boxSizing: "border-box",
            borderRadius: 12,
            backgroundColor: "white"
          }}
        >
          <p className="margin-none" style={{ fontWeight: 600, marginBottom: 10 }}>
            Account Holder
          </p>
          <p className="margin-none">{`${user?.firstName} ${user?.lastName}`}</p>
          <p className="margin-none">{getUserAddress()}</p>
        </div>
        <div
          style={{
            padding: 20,
            boxSizing: "border-box",
            borderRadius: 12,
            backgroundColor: "white"
          }}
        >
          <p className="margin-none" style={{ fontWeight: 600, marginBottom: 10 }}>
            Bank Detail
          </p>
          <p className="margin-none">AXIA Capital Bank</p>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "grid",
          gap: "1rem",
          gridTemplateColumns: "repeat(2, 1fr)",
          textAlign: "left",
          marginBottom: "1rem"
        }}
      >
        <div
          style={{
            padding: 15,
            boxSizing: "border-box",
            borderRadius: 12,
            backgroundColor: "white"
          }}
        >
          <p className="margin-none" style={{ fontWeight: 600, marginBottom: 10 }}>
            Account Detail
          </p>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", flexDirection: "column", flex: 3 }}>
              <p className="margin-none">Account Type:</p>
              <p className="margin-none">Currency:</p>
              <p className="margin-none">Account Number:</p>
              <p className="margin-none">Account Balance:</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 4, fontWeight: 600 }}>
              <p className="margin-none">{account.typePublicName}</p>
              <p className="margin-none">{account.currency}</p>
              <p className="margin-none">{account.publicId}</p>
              <p className="margin-none">{`${formatAmount(account.amount, account.currency)} ${account.currency}`}</p>
              {account.currency === "AXC" && (
                <p className="margin-none">{`≈ ${formatAmount(
                  new Big(account.amount).times(new Big(axcToUsdRate)),
                  "USD"
                )} USD`}</p>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            padding: 15,
            boxSizing: "border-box",
            borderRadius: 12,
            backgroundColor: "white"
          }}
        >
          <p className="margin-none" style={{ fontWeight: 600, marginBottom: 10 }}>
            Statement Summary
          </p>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", flexDirection: "column", flex: 3 }}>
              <p className="margin-none">Date From - To:</p>
              <p className="margin-none">Creation date:</p>
              <p className="margin-none">Total Withdrawals:</p>
              <p className="margin-none">Total Deposits:</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 4, fontWeight: 600 }}>
              <p className="margin-none">{getStatementDateRange()}</p>
              <p className="margin-none">{currentDate}</p>
              <p className="margin-none">{`${formatAmount(totalWithdrawal, account.currency)} ${account.currency}`}</p>
              <p className="margin-none">{`${formatAmount(totalDeposit, account.currency)} ${account.currency}`}</p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ width: "100%", fontSize: 10 }}>
        {transactions && transactions.length > 0 ? (
          <div
            style={{
              width: "100%",
              borderRadius: 12,
              backgroundColor: "white",
              padding: "10px 20px"
            }}
          >
            <div style={{ display: "block" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "left",
                  alignItems: "center",
                  fontWeight: 600,
                  margin: "1.5rem 5px",
                  fontSize: 12
                }}
              >
                <p className="margin-none" style={{ flex: 1 }}>
                  Date
                </p>
                <p className="margin-none" style={{ flex: 3 }}>
                  Counterparty
                </p>
                <p className="margin-none" style={{ flex: 1 }}>
                  Withdrawals
                </p>
                <p className="margin-none" style={{ flex: 1 }}>
                  Deposits
                </p>
                <p className="margin-none" style={{ flex: 1, textAlign: "right" }}>
                  Balance
                </p>
              </div>
            </div>
            <div style={{ height: 2, width: "100%", backgroundColor: "#CDE6F6" }} />

            {transactions.map((item, index) => {
              return (
                <div key={index} style={{ display: "block" }}>
                  <TransactionItem item={item} />
                  {index !== transactions.length - 1 && (
                    <div style={{ height: 1, width: "100%", backgroundColor: "#CDE6F6" }} />
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <p style={{ fontSize: "1rem", marginTop: "1rem" }}>No transaction record</p>
        )}
      </div>
    </div>
  );
};

export const AccountsStatement = boundError(AccountsStatementRaw);
