import { RightOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { SimpleButton } from ".";

export const NextBtn = ({ enable, onClick, btnName = "Next", style }) => {
  return (
    <SimpleButton
      style={{
        padding: "0.5rem 0.75rem 0.5rem 0.75rem",
        borderRadius: 12,
        backgroundColor: `${enable ? "#3a82f6" : "lightgrey"}`,
        border: `2px solid ${enable ? "#3a82f6" : "lightgrey"}`,
        width: 300,
        margin: "auto",
        ...style
      }}
      disabled={!enable}
      onClick={() => onClick()}
      children={
        <p className="margin-none" style={{ color: "white", fontSize: "1rem", fontWeight: 500, whiteSpace: "nowrap" }}>
          {btnName}
          {btnName == "Next" && <RightOutlined style={{ color: "white", paddingLeft: "5px" }} />}
        </p>
      }
    />
  );
};
