import jsPDF from "jspdf";
import { uploadPendingDocument } from "../apis";

const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

export class CustomImage extends Image {
  constructor(mimeType) {
    super();
    this.mimeType = mimeType;
  }

  imageType() {
    return this.mimeType.split("/")[1];
  }
}

export const imageDimensionsOnA4 = (dimensions) => {
  const isLandscapeImage = dimensions.width >= dimensions.height;

  if (isLandscapeImage) {
    return {
      width: A4_PAPER_DIMENSIONS.width,
      height: A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height)
    };
  }

  const imageRatio = dimensions.width / dimensions.height;
  if (imageRatio > A4_PAPER_RATIO) {
    const imageScaleFactor = (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

    const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

    return {
      height: scaledImageHeight,
      width: scaledImageHeight * imageRatio
    };
  }

  return {
    width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
    height: A4_PAPER_DIMENSIONS.height
  };
};

export const fileToImageURL = (file) => {
  return new Promise((resolve, reject) => {
    const image = new CustomImage(file.type);

    image.onload = () => {
      resolve(image);
    };

    image.onerror = () => {
      reject(new Error("Failed to convert File to Image"));
    };

    image.src = URL.createObjectURL(file);
  });
};

export const generatePdfFromImages = async (images, fileName) => {
  const doc = new jsPDF();
  doc.deletePage(1);

  images.forEach((image) => {
    const imageDimensions = imageDimensionsOnA4({
      width: image.width,
      height: image.height
    });

    doc.addPage();
    doc.addImage(
      image.src,
      image.imageType,
      (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
      (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
      imageDimensions.width,
      imageDimensions.height,
      "",
      "FAST"
    );
  });

  const pdfURL = doc.output("blob");
  const file = new File([pdfURL], fileName, { type: "application/pdf" });
  return file;
};
