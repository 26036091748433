import React, { useCallback, useEffect, useState } from "react";
import { boundError, showError } from "../../utils";
import { Button, Modal } from "antd";
import * as pdfHelper from "../../utils/pdfHelper";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";

const ConvertImgToPdfModalRaw = ({ visible, onOk, onCancel, fileName }) => {
  const [uploadedImages, setUploadedImages] = useState([]);

  const handleImageUpload = (event) => {
    const fileList = event.target.files;
    const fileArray = fileList ? Array.from(fileList) : [];
    const fileToImagePromises = fileArray.map(pdfHelper.fileToImageURL);

    Promise.all(fileToImagePromises).then((images) => {
      setUploadedImages([...uploadedImages, ...images]);
    });
  };

  const cleanUpUploadedImages = React.useCallback(() => {
    setUploadedImages([]);
    uploadedImages.forEach((image) => {
      URL.revokeObjectURL(image.src);
    });
  }, [setUploadedImages, uploadedImages]);

  return (
    <Modal
      visible={visible}
      okText={"Convert to pdf and upload"}
      cancelText={"Cancel"}
      centered
      onOk={async () => {
        if (uploadedImages.length > 0) {
          const file = await pdfHelper.generatePdfFromImages(uploadedImages, fileName);
          onOk(file);
          cleanUpUploadedImages();
        } else {
          showError("Please upload at least one image")
        }
      }}
      width={"50vh"}
      onCancel={onCancel}
    >
      <div className="modal">
        <div className="modalContent">
          <div>
            {uploadedImages.length > 0 ? (
              uploadedImages.map((image) => (
                <img style={{ margin: 3 }} key={image.src} src={image.src} width={60} height={60} />
              ))
            ) : (
              <p>Please upload image(s)...</p>
            )}
          </div>

          <Button type="primary" style={{ margin: 3 }} disabled={uploadedImages.length == 2}>
            <label htmlFor="file-input">
              <span className="button">
                <UploadOutlined /> Front side
              </span>
              <input
                id="file-input"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{
                  display: "none"
                }}
                disabled={uploadedImages.length == 2}
              />
            </label>
          </Button>

          <Button type="primary" style={{ margin: 3 }} disabled={uploadedImages.length == 2}>
            <label htmlFor="file-input">
              <span className="button">
                <UploadOutlined /> Back side
              </span>
              <input
                id="file-input"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{
                  display: "none"
                }}
                disabled={uploadedImages.length == 2}
              />
            </label>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const ConvertImgToPdfModal = boundError(ConvertImgToPdfModalRaw);
