import { bankApi } from "./authenticatedAxios";
import { responseDataHandler } from "./responseHandler";
import { concatApiQuery } from "../utils/strings";
import { stakingActivityTypesExcludeInterest } from "../constants/stakingActivityType";

export const getAvailableStakingsProductsApi = (currency, offset = 0, limit = 24) =>
  bankApi
    .get(concatApiQuery(`/staking/available`, { currency, offset, limit }))
    .then(({ data }) => responseDataHandler(data));

export const getUserStakingsApi = (offset = 0, limit = 90, order = "desc") =>
  bankApi.get(concatApiQuery(`/staking`, { offset, limit, order })).then(({ data }) => responseDataHandler(data));

export const applyStakingApi = (stakingProductCode, fromAccountId, amount) =>
  bankApi
    .post(`/staking/${stakingProductCode}/apply`, {
      fromAccountId,
      amount
    })
    .then(({ data }) => responseDataHandler(data));

export const getStakingByIdApi = (stakingId, num_of_records = 90) =>
  bankApi
    .get(concatApiQuery(`/staking/${stakingId}`, { num_of_records }))
    .then(({ data }) => responseDataHandler(data));

export const claimStakingApi = (stakingId, toAccountId) =>
  bankApi
    .post(`/staking/${stakingId}/claim`, {
      toAccountId
    })
    .then(({ data }) => responseDataHandler(data));

export const addAmountApi = (stakingId, fromAccountId, amount) =>
  bankApi
    .post(`/staking/${stakingId}/add-amount`, {
      fromAccountId,
      amount
    })
    .then(({ data }) => responseDataHandler(data));

export const getStakingInterestRecords = (stakingId, { offset, limit, orderBy = "date", order = "desc" }) =>
  bankApi
    .get(concatApiQuery(`/staking/${stakingId}/interestRecords`, { offset, limit, orderBy, order }))
    .then(({ data }) => responseDataHandler(data));

export const getStakingsActivities = ({
  stakingId,
  offset,
  limit,
  orderBy = "completed_at",
  order = "desc",
  orderBy2 = "created_at",
  order2 = "desc",
  completedAtGte,
  completedAtLte,
  includeTotalCount,
  activitiesIn = JSON.stringify(stakingActivityTypesExcludeInterest()).replaceAll("'", '"')
}) =>
  bankApi
    .get(
      concatApiQuery(`/staking/activities`, {
        stakingId,
        offset,
        limit,
        orderBy,
        order,
        orderBy2,
        order2,
        completedAtGte,
        completedAtLte,
        includeTotalCount,
        activitiesIn
      })
    )
    .then(({ data }) => responseDataHandler(data));

export const getStakingsActivityById = ({ activityId }) =>
  bankApi.get(`/staking/activities/${activityId}`).then(({ data }) => responseDataHandler(data));

export const createStakingAutoEnrollment = ({
  stakingId,
  autoEnrollStakingProductCode,
  autoEnrollPercentage,
  autoEnrollAmount
}) =>
  bankApi
    .post("/staking/autoEnrollments/create", {
      stakingId,
      autoEnrollStakingProductCode,
      autoEnrollPercentage,
      autoEnrollAmount
    })
    .then(({ data }) => responseDataHandler(data));

export const updateStakingAutoEnrollment = ({
  id,
  autoEnrollStakingProductCode,
  autoEnrollPercentage,
  autoEnrollAmount
}) =>
  bankApi
    .post("/staking/autoEnrollments/update", {
      id,
      autoEnrollStakingProductCode,
      autoEnrollPercentage,
      autoEnrollAmount
    })
    .then(({ data }) => responseDataHandler(data));
