import React, { useState, useEffect, useContext } from "react";
import { Checkbox, Menu, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { boundError, formatAmount, showError, showSuccess } from "../../utils";
import Modal from "react-modal";
import { KeyValue1, KeyValue2, KeyValue3 } from "../SharedComponents";
import { getExternalTransactionApi } from "../../apis";
import moment from "moment";
import { TRANSACTION_TYPE, TRANSACTION_DIRECTION } from "../../constants";
import { getConversionRateApi, userConfirmDepositRequest } from "../../apis";
import { CURRENCY } from "../../constants";
import Big from "big.js";
import { NextBtn } from "../SharedComponents/Button/NextBtn";
import { AccountsSelector } from "../AccountsSelector";
import { UserContext, useQuery } from "../../hooks";
import { useRef } from "react";

const PendingUserActionTransactionRaw = ({ route }) => {
  const [user] = useContext(UserContext);
  const [modalOpened, setModalOpened] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState();

  const [sourceOfFundRequired, setSourceOfFundRequired] = useState(false);
  const [sourceOfFund, setSourceOfFund] = useState("Select Source of Funds");
  const [sourceOfFundOther, setSourceOfFundOther] = useState("");
  const [sourceOfFundConfirmed, setSourceOfFundConfirmed] = useState(false);
  const [sourceOfFundFilled, setSourceOfFundFilled] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);

  const query = useQuery();
  const transactionId = query.get("transactionId");
  const initModalHasShown = useRef(false);
  useEffect(() => {
    autoDisplayTransaction(transactionId);
  }, [transactions]);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    const data = await getExternalTransactionApi({
      status: "PENDING_CONFIRMATION",
      methodIn: [
        TRANSACTION_TYPE.DIGITAL_CURRENCY_DEPOSIT,
        TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_DEPOSIT,
        TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_V2_DEPOSIT
      ],
      pagination: false,
      generateTitleAndDescription: true
    });
    setTransactions(data.data);
    setLoading(false);
  };

  useEffect(() => {
    switch (sourceOfFund) {
      case "Select Source of Funds":
        setSourceOfFundFilled(false);
        break;
      case "Other":
        setSourceOfFundFilled(sourceOfFundOther.trim() !== "");
        break;
      default:
        setSourceOfFundFilled(true);
    }
  }, [sourceOfFund, sourceOfFundOther]);

  useEffect(() => {
    if (sourceOfFundRequired) {
      setNextEnabled(selectedAccount && sourceOfFundFilled && sourceOfFundConfirmed && isConfirmed);
    } else {
      setNextEnabled(selectedAccount && selectedAccount !== {} && isConfirmed);
    }
  }, [selectedAccount, sourceOfFundRequired, sourceOfFundFilled, sourceOfFundConfirmed, isConfirmed]);

  const handleDropdownClick = (e) => {
    setSourceOfFund(e.key);
  };

  const menu = (
    <Menu
      onClick={handleDropdownClick}
      style={{
        zIndex: 9999,
        overlay: {
          zIndex: 9999
        }
      }}
    >
      <Menu.Item key="Select Source of Funds">Select Source of Funds</Menu.Item>
      <Menu.Item key="Personal Investment">Personal Investment</Menu.Item>
      <Menu.Item key="Income">Income</Menu.Item>
      <Menu.Item key="Gift">Gift</Menu.Item>
      <Menu.Item key="Inheritance">Inheritance</Menu.Item>
      <Menu.Item key="Other">Other</Menu.Item>
    </Menu>
  );

  const autoDisplayTransaction = async (transactionId) => {
    if (transactionId && transactions && transactions.length > 0 && !initModalHasShown.current) {
      const item = transactions.find((trx) => trx?.id === transactionId);

      console.log("aaaaA: ", item);

      if (!item) {
        return;
      }

      try {
        let amountInUsd;
        if (item.providerMetaData.amountInUsd) {
          amountInUsd = new Big(item.providerMetaData.amountInUsd);
        } else if (item.providerMetaData.amountInAxc) {
          const rate = await getConversionRateToUsd(CURRENCY.AXC.code);
          amountInUsd = new Big(item.providerMetaData.amountInAxc).times(rate);
        } else {
          amountInUsd = new Big(10000);
        }
        if (amountInUsd.gte(new Big(10000))) {
          setSourceOfFundRequired(true);
        }
      } catch (err) {
        setSourceOfFundRequired(true);
      }

      setModalData(item);
      setModalOpened(true);
    }
  };

  const generateAmountDisplay = (transaction) => {
    let amount, currency;
    switch (transaction.method) {
      case TRANSACTION_TYPE.DIGITAL_CURRENCY_DEPOSIT:
        const { original_amount, original_currency } = transaction?.providerMetaData;
        amount = new Big(original_amount && original_amount !== "" ? original_amount : 0).toFixed(5);
        currency = original_currency;
        break;
      default:
        amount = formatAmount(transaction.providerAmount, transaction.providerCurrency);
        currency = transaction.providerCurrency;
    }
    return (
      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <p
          style={{
            fontSize: 20,
            fontWeight: "500",
            marginTop: 5,
            marginBottom: 5,
            color: transaction?.type === TRANSACTION_DIRECTION.DEPOSIT ? "#53E085" : "red"
          }}
        >
          {`${transaction?.type === TRANSACTION_DIRECTION.DEPOSIT ? "+" : "-"} ${amount}`}
          <span style={{ fontSize: 12, fontWeight: "500", marginLeft: 5 }}>{currency}</span>
        </p>
      </div>
    );
  };

  const getConversionRateToUsd = async ({ providerCurrency }) => {
    if (providerCurrency === CURRENCY.USD.code) {
      return new Big(1);
    } else {
      const res = await getConversionRateApi(providerCurrency, CURRENCY.USD.code);
      return new Big(res?.conversionRate ?? 0);
    }
  };

  const confirmDepositRequest = async () => {
    try {
      let sourceOfFundFinal = "";
      if (sourceOfFund === "Other") {
        sourceOfFundFinal = sourceOfFundOther.trim();
      } else if (sourceOfFund !== "Select Source of Funds") {
        sourceOfFundFinal = sourceOfFund;
      }
      const result = await userConfirmDepositRequest({
        externalTransactionId: modalData.id,
        accountId: selectedAccount.id,
        sourceOfFund: sourceOfFundFinal
      });

      if (result?.statusCode === 200 || result?.status === 200) {
        showSuccess(
          "Funds are deposited successfully! This page will refresh automatically to show your updated balance in 5 seconds."
        );
        closeModal();
        setTimeout("window.location.reload()", 5000);
      } else {
        if (data?.message) throw new Error(data?.message);
        throw new Error("Unknown error!");
      }
    } catch (err) {
      showError(err);
    }
  };

  const TransactionItem = ({ item, lastItem }) => {
    return (
      <div
        style={lastItem ? { borderBottom: 0 } : null}
        className="accounts-transaction-item-container cursor-pointer"
        key={item.id}
        onClick={async () => {
          try {
            let amountInUsd;
            if (item.providerMetaData.amountInUsd) {
              amountInUsd = new Big(item.providerMetaData.amountInUsd);
            } else if (item.providerMetaData.amountInAxc) {
              const rate = await getConversionRateToUsd(CURRENCY.AXC.code);
              amountInUsd = new Big(item.providerMetaData.amountInAxc).times(rate);
            } else {
              amountInUsd = new Big(10000);
            }
            if (amountInUsd.gte(new Big(10000))) {
              setSourceOfFundRequired(true);
            }
          } catch (err) {
            setSourceOfFundRequired(true);
          }

          setModalData(item);
          setModalOpened(true);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "start" }}>
          <p className="padding-none margin-none" style={{ color: "#A3A3A3", textAlign: "left" }}>
            {item && moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
          </p>
          <div className="row" style={{ gap: "1rem" }}>
            <p className="padding-none margin-none" style={{ fontSize: 18, fontWeight: "500", textAlign: "left" }}>
              {item?.title}
            </p>
            <p
              className="margin-none"
              style={{
                fontWeight: 500,
                fontSize: 14,
                padding: "2px 8px",
                borderRadius: 10,
                color: "red",
                backgroundColor: "rgba(255, 204, 204, 1)"
              }}
            >
              Action Required
            </p>
          </div>
        </div>
        {generateAmountDisplay(item)}
      </div>
    );
  };

  const renderAlphapoModal = () => {
    if (!modalOpened || !modalData || modalData === {}) {
      return;
    }
    const { confirmAt } = modalData?.providerMetaData;
    const title = modalData.title;
    let fundReceived, depositFee, depositAmount;
    let allowedToCurrencyList = [CURRENCY.AXC.code];
    switch (modalData.method) {
      case TRANSACTION_TYPE.DIGITAL_CURRENCY_DEPOSIT: {
        let { original_amount, original_currency, amountInAxc, amountInUsax } = modalData?.providerMetaData;
        fundReceived = `${new Big(original_amount).toFixed(5)} ${original_currency}`;
        depositFee = `0.8% + GAS fees`;
        if (!amountInUsax) {
          // Since when USAX first introduced, the conversion rate is 1
          amountInUsax = amountInAxc;
        }
        depositAmount =
          selectedAccount?.currency === CURRENCY.USAX.code
            ? `${formatAmount(amountInUsax, CURRENCY.USAX.code)} ${CURRENCY.USAX.code}`
            : `${formatAmount(amountInAxc, CURRENCY.AXC.code)} ${CURRENCY.AXC.code}`;
        allowedToCurrencyList = [CURRENCY.AXC.code, CURRENCY.USAX.code];
        break;
      }
      case TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_DEPOSIT:
      case TRANSACTION_TYPE.EXTERNAL_AXIA_NETWORK_V2_DEPOSIT: {
        const { providerAmount, providerCurrency } = modalData;
        fundReceived = `${new Big(providerAmount).toFixed(5)} ${providerCurrency}`;
        depositAmount = `${formatAmount(providerAmount, CURRENCY.AXC.code)} ${CURRENCY.AXC.code}`;
        break;
      }
    }

    return (
      <div style={{ marginTop: 20, marginBottom: 100, backgroundColor: "white", borderRadius: 10, padding: 40 }}>
        <h1>{title}</h1>
        <p style={{ fontSize: "1rem" }}>
          {`Your ${title} request has been processed successfully. Please select the account to 
          deposit the fund${sourceOfFundRequired ? ` and declare the source of fund` : ``}.`}
        </p>

        <div>
          <h2 style={{ fontWeight: "500", marginTop: "2rem" }}>Deposit request details:</h2>
          <KeyValue2 keyName="Fund Received" value={fundReceived} />
          {depositFee && <KeyValue2 keyName="Deposit Fee" value={depositFee} />}
          <KeyValue2 keyName="Processed Date" value={moment(confirmAt).format("YYYY-MM-DD HH:mm")} />
          <KeyValue2
            keyName="Deposit Amount"
            value={selectedAccount ? depositAmount : "Please select an account to view deposit amount"}
          />
        </div>

        <div style={{ marginBottom: "2rem" }}>
          <h2 style={{ fontWeight: "500", marginTop: "2rem" }}>Please select an account to deposit your funds</h2>
          <AccountsSelector
            onAccountSelected={(account) => setSelectedAccount(account)}
            selectedAccount={selectedAccount}
            currencyList={allowedToCurrencyList}
            placeholder={"Please select an account to deposit your funds"}
          />
        </div>

        {sourceOfFundRequired && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "1rem",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              backgroundColor: "#F8F9FA",
              borderRadius: "12px",
              marginTop: 20,
              padding: 20
            }}
          >
            <div style={{ display: "block", flexDirection: "row", width: "100%" }}>
              <p className="margin-none" style={{ display: "flex", fontWeight: 600, fontSize: "1.1rem" }}>
                Source of Funds Declaration
              </p>
              <p
                className="margin-none"
                style={{
                  display: "flex",
                  textAlign: "left",
                  fontSize: "1rem",
                  marginBottom: "1rem",
                  marginTop: 10
                }}
              >
                As your deposit is at least $10000 USD, AXIA Capital Bank is required to request that you complete this
                Source of Funds Declaration.
              </p>
            </div>

            <div style={{ display: "block", width: "100%", marginBottom: "1.5rem" }}>
              <p className="margin-none" style={{ display: "flex", fontWeight: 500, fontSize: "0.9rem" }}>
                I declare that the source of funds is from
                <span style={{ color: "red" }}>*</span>
              </p>
              <Dropdown overlay={menu} overlayStyle={{ zIndex: 9999 }} trigger={["click"]}>
                <Button
                  style={{
                    display: "flex",
                    borderRadius: 12,
                    width: "100%",
                    height: "4rem",
                    fontSize: "1rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderColor: "#B1B5C4",
                    borderWidth: 2
                  }}
                >
                  {sourceOfFund} <DownOutlined />
                </Button>
              </Dropdown>
            </div>
            {sourceOfFund === "Other" && (
              <div style={{ display: "block", width: "100%", marginBottom: "1.5rem" }}>
                <p className="margin-none" style={{ display: "flex", fontWeight: 500, fontSize: "1rem" }}>
                  Other fund <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  value={sourceOfFundOther}
                  placeholder={"Please specify the source of fund"}
                  onChange={(e) => {
                    setSourceOfFundOther(e.target.value);
                  }}
                  style={{
                    borderRadius: 8,
                    border: "1px solid #d9d9d9",
                    width: "100%",
                    paddingLeft: "1rem",
                    height: "4rem",
                    fontSize: "1rem"
                  }}
                />
              </div>
            )}
            <div
              style={{
                display: "grid",
                gap: "1rem",
                width: "100%",
                margin: "1rem 0",
                gridTemplateColumns: "repeat(2,1fr)"
              }}
            >
              <div
                style={{
                  padding: "20px",
                  border: "2px solid #dddddd",
                  boxSizing: "border-box",
                  borderRadius: "12px",
                  textAlign: "left"
                }}
              >
                <p className="margin-none" style={{ fontSize: "1rem", color: "#777E91" }}>
                  Signature of Member
                </p>
                <p
                  className="margin-none"
                  style={{ fontSize: "1rem", fontWeight: 600 }}
                >{`${user?.firstName} ${user?.lastName}`}</p>
              </div>
              <div
                style={{
                  padding: "20px",
                  border: "2px solid #dddddd",
                  boxSizing: "border-box",
                  borderRadius: "12px",
                  textAlign: "left"
                }}
              >
                <p className="margin-none" style={{ fontSize: "1rem", color: "#777E91" }}>
                  Date
                </p>
                <p className="margin-none" style={{ fontSize: "1rem", fontWeight: 600 }}>
                  {moment().format("YYYY/MM/DD")}
                </p>
              </div>
            </div>
            <Checkbox
              checked={sourceOfFundConfirmed}
              onChange={(e) => setSourceOfFundConfirmed(e.target.checked)}
              style={{ fontSize: "1rem", marginTop: "1rem", textAlign: "left" }}
            >
              I hereby accept the Source of Funds requirement as a condition of processing my deposit.
            </Checkbox>
          </div>
        )}

        <Checkbox
          checked={isConfirmed}
          onChange={(e) => setIsConfirmed(e.target.checked)}
          style={{ fontSize: "1rem", marginTop: "2rem", textAlign: "left", width: "100%" }}
        >
          I confirm that the above information is correct.
        </Checkbox>

        <div
          className="row"
          style={{
            maxWidth: "60%",
            margin: "auto",
            marginTop: 20,
            padding: 20,
            gap: "1rem"
          }}
        >
          <NextBtn style={{ width: 160 }} btnName="Close" enable={true} onClick={closeModal} />
          <NextBtn style={{ width: 160 }} btnName="Confirm" enable={nextEnabled} onClick={confirmDepositRequest} />
        </div>
      </div>
    );
  };

  const closeModal = () => {
    setModalOpened(false);
    setModalData({});
    setSelectedAccount();
    setSourceOfFundRequired(false);
    setSourceOfFund("Select Source of Funds");
    setSourceOfFundOther("");
    setSourceOfFundConfirmed(false);
    setSourceOfFundFilled(false);
    setIsConfirmed(false);
  };

  if (!loading && transactions && transactions.length > 0) {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div className="row" style={{ margin: 0, padding: 0, paddingLeft: 30, width: "100%" }}>
          <p style={{ fontSize: 20, fontWeight: "500", margin: "0px 0px 10px 10px", padding: 0 }}>
            Pending Transactions
          </p>
        </div>
        <div style={{ width: "100%" }}>
          <div className="accounts-transaction-box">
            {transactions.map((item, index) => {
              return (
                <div key={index}>
                  <TransactionItem item={item} lastItem={index == transactions.length - 1} />
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ height: 20 }} />
        <Modal
          isOpen={modalOpened}
          onRequestClose={closeModal}
          ariaHideApp={false}
          style={{
            overlay: {
              zIndex: 9900,
              backgroundColor: "rgba(0, 0, 0, 0.3)"
            },
            content: {
              margin: "auto",
              maxWidth: 800,
              height: "100vh",
              overflow: "auto",
              padding: 0,
              backgroundColor: "transparent",
              border: "none"
            }
          }}
        >
          {renderAlphapoModal()}
        </Modal>
      </div>
    );
  }
  return <></>;
};

export const PendingUserActionTransaction = boundError(PendingUserActionTransactionRaw);
