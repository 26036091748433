import React, { useState, useEffect, useContext } from "react";
import { boundError } from "../utils";
import {
  AccountsHead,
  AccountsMid,
  AccountsTransaction,
  AccountsPendingTransaction,
  PendingUserActionTransaction
} from "../components";
import UserServices from "../services/userServices";
import { AccountsContext } from "../hooks";
import { OnLoginNotification } from "../components";


const AccountsRaw = () => {
  const userServices = UserServices.getInstance();
  const [accounts] = useContext(AccountsContext);
  const [axcToUsdRate, setAxcToUsdRate] = useState(0);

  useEffect(() => {
    userServices.fetchUserAccounts();
  }, []);

  return (
    <div>
      <OnLoginNotification />
      <AccountsHead accounts={accounts} axcToUsdRate={axcToUsdRate} />
      <AccountsMid accounts={accounts} />
      <PendingUserActionTransaction />
      <AccountsPendingTransaction />
      <AccountsTransaction expanded={false} />
      <div style={{ height: 100 }} />
    </div>
  );
};

export const Accounts = boundError(AccountsRaw);
