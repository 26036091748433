import React, { useState, useContext } from "react";
import {
  UserOutlined,
  MobileOutlined,
  KeyOutlined,
  PhoneOutlined,
  BellOutlined,
  FileDoneOutlined
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { isUat, isValidForFeature } from "../../utils";
import { FEATURE_NAMES } from "../../utils";
import { UserContext } from "../../hooks";
import { boundError } from "../../utils";

const ProfileMenuWrapperRaw = ({ component }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [user] = useContext(UserContext);

  const prodMenus =
    isValidForFeature({ user, featureName: FEATURE_NAMES.UPLOAD_DOCUMENT }) === true
      ? [
          { id: 0, text: "Personal", path: "/profile", icon: <UserOutlined /> },
          // { id: 1, text: "Email and Phone", path: "/email-and-phone", icon: <PhoneOutlined /> },
          { id: 2, text: "Documents", path: "/user-document", icon: <FileDoneOutlined /> },
          { id: 3, text: "Change password", path: "/change-password", icon: <KeyOutlined /> },
          { id: 4, text: "Two factor", path: "/two-factor", icon: <MobileOutlined /> }
          // { id: 5, text: "Notifications", path: "/notifications", icon: <BellOutlined /> }
        ]
      : [
          { id: 0, text: "Personal", path: "/profile", icon: <UserOutlined /> },
          // { id: 1, text: "Email and Phone", path: "/email-and-phone", icon: <PhoneOutlined /> },
          // { id: 2, text: "Documents", path: "/user-document", icon: <FileDoneOutlined /> },
          { id: 3, text: "Change password", path: "/change-password", icon: <KeyOutlined /> },
          { id: 4, text: "Two factor", path: "/two-factor", icon: <MobileOutlined /> }
          // { id: 5, text: "Notifications", path: "/notifications", icon: <BellOutlined /> }
        ];

  const menus =
    isUat() === true
      ? [
          { id: 0, text: "Personal", path: "/profile", icon: <UserOutlined /> },
          { id: 1, text: "Email and Phone", path: "/email-and-phone", icon: <PhoneOutlined /> },
          { id: 2, text: "Documents", path: "/user-document", icon: <FileDoneOutlined /> },
          { id: 3, text: "Change password", path: "/change-password", icon: <KeyOutlined /> },
          { id: 4, text: "Two factor", path: "/two-factor", icon: <MobileOutlined /> },
          { id: 5, text: "Notifications", path: "/notifications", icon: <BellOutlined /> }
        ]
      : prodMenus;

  const [selectedPath, setSelectedPath] = useState("/profile");

  const isCurrentPath = (path) => {
    return location.pathname === path;
  };

  const MenuBtn = ({ item, index }) => {
    return (
      <button
        className={isCurrentPath(item?.path) ? "profile-side-menu-btn-selected" : "profile-side-menu-btn"}
        onClick={() => {
          setSelectedPath(item?.path);
          navigation(item?.path);
        }}
      >
        {item?.icon}
        <p className="margin-none" style={{ marginLeft: 10 }}>
          {item?.text}
        </p>
      </button>
    );
  };

  return (
    <div className="profile-menu-wrapper-container">
      <div className="profile-menu-bar">
        {/* <MenuBtn text="aaa" icon={<UserOutlined />} /> */}
        {menus.map((item) => {
          return <MenuBtn item={item} key={item.id} />;
        })}
      </div>
      <div className="profile-wrapper-page-area">{component}</div>
    </div>
  );
};

export const ProfileMenuWrapper = boundError(ProfileMenuWrapperRaw);
