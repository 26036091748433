import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Button, message, Divider, Input, Spin } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { boundError } from "../../utils";
import Modal from "react-modal";
import Big from "big.js";
import date from "date-and-time";
import UserServices from "../../services/userServices";
import StakingServices from "../../services/stakingServices";
import Emitter, { EmitterEvents } from "../../utils/eventEmitter";
import { KeyValue2 } from "../SharedComponents";
import { translateStakingStatus } from "../../constants/stakingStatus";
import { formatAmount, getDecimalScale, getAmountSymbol } from "../../utils";
import NumberFormat from "react-number-format";

const LoadAmountToCardModalRaw = ({ visible, onRequestClose, closeModal, staking, onLoadCardSuccess }) => {
  const userServices = UserServices.getInstance();
  const stakingServices = StakingServices.getInstance();
  const [selectedCard, setSelectedCard] = useState(null);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const matchCurrencyCards = cards.filter((card) => card?.currency === staking?.currency);

  const [errorCardMsg, setErrorCardMsg] = useState("");
  const [errorAmountMsg, setErrorAmountMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(visible);
  }, [visible]);

  const getStakingId = () => {
    if (staking?.id) {
      return "******" + staking?.id.substr(-5);
    }
    return "";
  };

  const getStakingAmount = () => {
    if (!isNaN(staking?.amount)) {
      return Big(staking?.amount).toFixed(5) + " " + staking?.currency;
    }

    return "";
  };

  const getStakingInterestAmount = () => {
    if (!isNaN(staking?.interestAmount)) {
      return Big(staking?.interestAmount).toFixed(5) + " " + staking?.currency;
    }

    return "";
  };

  const getAnnualInterestRate = () => {
    if (!isNaN(staking?.annualInterestRate)) {
      return Big(staking?.annualInterestRate).mul(100).toFixed(2) + " %";
    }

    return "";
  };

  const getTermStartAt = () => {
    if (staking?.termStartAt) {
      return date.format(new Date(staking?.termStartAt), "YYYY-MM-DD", true);
    }

    return "";
  };

  const getTermEndAt = () => {
    if (staking?.termEndAt) {
      return date.format(new Date(staking?.termEndAt), "YYYY-MM-DD", true);
    }

    return "";
  };

  const getAllowLoadCardLimit = () => {
    /// maximumWithdrawUsdAmount / rateInUsd
    if (!isNaN(staking?.maximumWithdrawUsdAmount) && !isNaN(staking?.rateInUsd)) {
      const allowLoadLimit = Big(staking?.maximumWithdrawUsdAmount).div(staking?.rateInUsd);

      return allowLoadLimit.toFixed(5) + " " + staking?.currency;
    }

    return "";
  };

  const getCardStr = (card) => {
    let str = "Unknown";
    if (card?.type) {
      str = card?.type;
    }
    if (card?.amount) {
      str = str + (str != "" ? " -- " : "") + Big(card?.amount).toFixed(5);
    }
    if (card?.currency) {
      str = str + (str != "" ? " " : "") + card?.currency;
    }

    return str;
  };

  const confirmLoadToCard = async () => {
    alert("Coming soon!");
    return;
    try {
      let hasErrors = false;
      if (!selectedCard) {
        setErrorCardMsg("Please select an account");
        hasErrors = true;
      }

      if (isNaN(amount) || Number(amount <= 0)) {
        setErrorAmountMsg("Please enter a valid amount");
        hasErrors = true;
      }

      if (hasErrors) {
        return;
      }

      setLoading(true);
      await stakingServices.addAmount(staking?.id, selectedCard?.id, amount);
      message.success({
        content: "Amount has been added successfully!",
        style: {
          marginTop: "20vh",
          zIndex: 10000
        }
      });

      setSelectedCard(null);
      setAmount("");

      Emitter.emit(EmitterEvents.REFRESH_STAKING_LIST);

      closeModal();

      if (onLoadCardSuccess) {
        onLoadCardSuccess();
      }

      setLoading(false);
    } catch (e) {
      message.error({
        content: e.toString(),
        style: {
          marginTop: "20vh",
          zIndex: 10000
        }
      });

      closeModal();

      setLoading(false);
    }
  };

  function handleMenuClick(e) {
    const card = userServices.getCardById(e?.key, matchCurrencyCards);
    setSelectedCard(card);
    resetErrors();
  }

  const resetErrors = () => {
    setErrorAmountMsg("");
    setErrorCardMsg("");
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {matchCurrencyCards.map((card, index) => {
        return (
          <Menu.Item key={card?.id} icon={<UserOutlined />}>
            {getCardStr(card)}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          zIndex: 1999
        },
        content: {
          margin: "auto",
          maxWidth: 500,
          maxHeight: 800,
          height: "100vh",
          overflow: "auto",
          padding: 0,
          backgroundColor: "transparent",
          border: "none",
          paddingTop: 20,
          paddingBottom: 20
        }
      }}
    >
      <Spin spinning={loading}>
        <div style={{ backgroundColor: "white", borderRadius: 21 }}>
          <div className="stakings__product-modal-header">
            <h1>{staking?.name}</h1>
            <p>
              Load amount to your card using the information below. You'll need to contact your bank for specific
              instructions.
            </p>
          </div>
          <div style={{ padding: 30, paddingBottom: 0 }}>
            <KeyValue2 keyName={"Principal Amount"} value={getStakingAmount()} />
            <KeyValue2 keyName={"Allowed Load Card Limit"} value={getAllowLoadCardLimit()} />
            <KeyValue2 keyName={"Total Rewards Earned"} value={getStakingInterestAmount()} />
            <KeyValue2 keyName={"Annual Percentage Rewards"} value={getAnnualInterestRate()} />
            <KeyValue2 keyName={"Status"} value={translateStakingStatus(staking?.status)} />
            <KeyValue2 keyName={"Term end"} value={getTermEndAt()} />

            {/* <KeyValue2 keyName={"Room Remain"} value={getRoomRemain()} /> */}

            <Divider />
            <p className="margin-none" style={{ fontWeight: "500", fontSize: 12, minWidth: 240, color: "#B1B5C3" }}>
              {"Select card to load"}
            </p>
            <Dropdown
              overlay={menu}
              overlayStyle={{ zIndex: 9999, overflow: "scroll", maxHeight: "100vh" }}
              trigger="click"
            >
              <Button style={{ width: "100%", border: "2px solid #CDE6F6" }}>
                {selectedCard === null ? "Please select a card to load" : getCardStr(selectedCard)}
                <DownOutlined />
              </Button>
            </Dropdown>
            {errorCardMsg !== "" && (
              <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorCardMsg}</p>
            )}

            <p
              className="margin-none"
              style={{ fontWeight: "500", fontSize: 12, minWidth: 240, marginTop: 10, color: "#B1B5C3" }}
            >
              {"Enter amount to load"}
            </p>
            <div style={{ display: "flex" }}>
              <NumberFormat
                thousandSeparator={true}
                value={amount}
                placeholder={"0.00"}
                decimalScale={getDecimalScale(staking?.currency)}
                prefix={getAmountSymbol(staking?.currency)}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  setAmount(value);

                  resetErrors();
                }}
                style={{
                  width: "100%",
                  border: "2px solid #CDE6F6",
                  borderRadius: "10px",
                  minHeight: "32px",
                  paddingLeft: "10px"
                }}
              />
              <p
                style={{
                  border: "2px solid #CDE6F6",
                  margin: 0,
                  borderRadius: "10px",
                  width: "100px",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {staking?.currency}
              </p>
            </div>

            {errorAmountMsg !== "" && (
              <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorAmountMsg}</p>
            )}
            <div></div>
            <Divider />
            <div
              className="row"
              style={{
                paddingBottom: 20,
                alignItems: "center",
                justifyContent: "end"
              }}
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: "white",
                  border: "1px solid #3b82f6",
                  borderRadius: 10,
                  color: "#3b82f6",
                  minWidth: "75px",
                  fontSize: "13px"
                }}
                onClick={() => {
                  setErrorCardMsg("");
                  setErrorAmountMsg("");
                  setAmount("");
                  setSelectedCard(null);
                  closeModal();
                }}
              >
                Close
              </Button>
              {/* <Button
                type="primary"
                style={{ borderRadius: 12, marginLeft: 10, minWidth: "75px", fontSize: "13px" }}
                onClick={confirmLoadToCard}
              >
                Load to Card
              </Button> */}
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export const LoadAmountToCardModal = boundError(LoadAmountToCardModalRaw);
