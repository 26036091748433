import * as types from "../actions/index.js";
const INITIAL_STATE = {
  user: null,
  idToken: undefined,
  mfaEnabled: undefined,
  isMaintaining: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.MFA_LOGIN_REQUEST:
      return {
        ...state,
        user: action.payload.user
      };
    case types.LOGIN_SUCCESS:
      const user = action.payload.user;
      return {
        ...state,
        user,
        idToken: user.signInUserSession?.idToken?.jwtToken,
        mfaEnabled: !!user.challengeName || (user.preferredMFA && user.preferredMFA !== "NOMFA")
      };
    case types.REFRESH_ID_TOKEN_SUCCESS:
      return {
        ...state,
        idToken: action.payload
      };
    case types.UPDATE_MFA_STATUS:
      return {
        ...state,
        mfaEnabled: action.payload.mfaEnabled
      };
    case types.SITE_MAINTENANCE:
      return {
        ...state,
        isMaintaining: action.payload.isMaintaining
      };
    default:
      return { ...INITIAL_STATE };
  }
};

export default reducer;
