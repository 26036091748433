import React from "react";
import { showSuccess, boundError } from "../../utils";
import { Button } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
const CopyButtonRaw = ({ text, style }) => {
  return (
    <div title="copy to clipboard" style={{ display: "inline-block", marginLeft: 5 }}>
      <CopyToClipboard
        text={text}
        style={{
          borderRadius: 5,
          ...style
        }}
        onCopy={() => showSuccess("Text has been copied to clipboard.")}
      >
        <CopyOutlined />
      </CopyToClipboard>
    </div>
  );
};

export const CopyButton = boundError(CopyButtonRaw);
