import { bankApi } from "./authenticatedAxios";
import { responseDataHandler } from "./responseHandler";
import { concatApiQuery } from "../utils/strings";

export const getGiftCardCountryList = () =>
  bankApi.get(`/gift-card/countries`).then(({ data }) => responseDataHandler(data));

export const getGiftCardBrands = (country, currentPage) =>
  bankApi
    .get(concatApiQuery(`/gift-card/brand`, { country, currentPage }))
    .then(({ data }) => responseDataHandler(data));

export const orderGiftCard = ({
  accountId,
  stakingId,
  productId,
  price,
  currency,
  productImage,
  productName,
  productDescription,
  termsAndConditions,
  howToUse,
  expiryAndValidity
}) =>
  bankApi.post(`/gift-card/`, {
    accountId,
    stakingId,
    productId,
    price,
    currency,
    productImage,
    productName,
    productDescription,
    termsAndConditions,
    howToUse,
    expiryAndValidity
  });

export const getUserGiftCard = (query) =>
  bankApi.get(concatApiQuery(`/gift-card/`, query)).then(({ data }) => responseDataHandler(data));

export const archiveGiftCard = ({ id, archive }) =>
  bankApi.post(`/gift-card/archive`, { id, archive }).then(({ data }) => responseDataHandler(data));
