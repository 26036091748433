import React, { useState, useEffect } from "react";
import { boundError } from "../utils";
import { Button, Col, Divider, Row, Space, Typography, Spin } from "antd";
import { updateUserType } from "../apis";
import { useNavigate } from "react-router-dom";
import { USER_TYPE } from "../constants/userType";
import UserServices from "../services/userServices";
import { showError } from "../utils/messages";
const { Title, Text } = Typography;

const UserTypeSelectRaw = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userServices = UserServices.getInstance();
  const [userType, setUserType] = useState("");

  useEffect(() => {
    checkUserType();
  }, []);

  const checkUserType = async () => {
    setLoading(true);
    const result = await userServices.checkUserRegistrationStatus();
    setUserType(result?.userType);
    setLoading(false);
  };

  const updateUserTypeButtonClicked = async (userType) => {
    setLoading(true);
    const result = await userServices.checkUserRegistrationStatus();

    /// if userType is UNKNOWN, update userType
    if (result?.userType === USER_TYPE.UNKNOWN) {
      await updateUserType(userType);
    } else {
      /// userType is not UNKNOWN and upcoming userType not match type from db, simply show error
      if (userType !== result?.userType) {
        showError("User type mismatch!");
        setLoading(false);
        return;
      }
    }

    switch (userType) {
      case USER_TYPE.INDIVIDUAL:
        navigate("/onboarding/individual");
        break;
      case USER_TYPE.BUSINESS:
        navigate("/onboarding/business");
        break;
      default:
        break;
    }

    setLoading(false);
  };

  const disableBtn = (_userType) => {
    if (userType === USER_TYPE.UNKNOWN) {
      return false;
    } else if (userType !== _userType) {
      return true;
    }
    return false;
  };

  return (
    <div style={{ padding: 15 }}>
      <Spin spinning={loading}>
        <Row justify="center">
          <Col sm={24} md={18}>
            <Title level={3}>Choose account type</Title>
            <Divider />
            <Space direction="vertical" style={{ width: "100%" }}>
              <Button
                block
                onClick={() => updateUserTypeButtonClicked(USER_TYPE.INDIVIDUAL)}
                style={{
                  minHeight: 100,
                  height: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  paddingTop: 10,
                  paddingBottom: 10
                }}
                disabled={loading || disableBtn(USER_TYPE.INDIVIDUAL)}
              >
                <Title level={4} style={{ whiteSpace: "initial", textAlign: "left" }}>
                  I want to create a personal account
                </Title>
                <Text type="secondary" ellipsis style={{ whiteSpace: "initial", textAlign: "left" }}>
                  You are an individual who wishes to open a personal account in your own name
                </Text>
              </Button>
              <Button
                block
                onClick={() => updateUserTypeButtonClicked(USER_TYPE.BUSINESS)}
                style={{
                  minHeight: 100,
                  height: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  paddingTop: 10,
                  paddingBottom: 10
                }}
                disabled={loading || disableBtn(USER_TYPE.BUSINESS)}
              >
                <Title level={4} style={{ whiteSpace: "initial", textAlign: "left" }}>
                  I want to create a company account
                </Title>
                <Text type="secondary" style={{ whiteSpace: "initial", textAlign: "left" }}>
                  The company has a registration number and operates as a legal entity
                </Text>
              </Button>
            </Space>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export const UserTypeSelect = boundError(UserTypeSelectRaw);
