import React from "react";
import { PlusOutlined, SwapOutlined, WalletOutlined } from "@ant-design/icons";
import { boundError } from "../utils";
import { Link } from "react-router-dom";
import { CardButton } from "../components";
import imgAlphapo from "../resources/images/alphapo.png";
import Icon from "@ant-design/icons";
import WalletIcon from "../resources/icons/deposit/axia_wallet.svg";
import TransferIcon from "../resources/icons/deposit/wire_transfer.svg";
import DigitalDepositIcon from "../resources/icons/deposit/digital_currency_deposit.svg";
import FiatDepositIcon from "../resources/icons/deposit/fiat_deposit.svg";
// for testing
import { UserContext } from "../hooks";
import { useContext } from "react";
import { isValidForFeature, FEATURE_NAMES } from "../utils";

const DepositRaw = () => {
  const iconStyle = { height: 40, fontSize: 40, color: "black" };
  const [user] = useContext(UserContext);

  return (
    <div>
      <div className="row page-title">Deposit</div>
      <div className="body-padding avoid-bottom-bar">
        <div className="grid-1">
          <Link to={"/deposit/wire-deposit"}>
            <CardButton icon={<Icon component={() => <img src={TransferIcon} />} />} text="Wire Transfer" />
          </Link>
          <Link to={"/deposit/digital-currency-deposit"}>
            <CardButton
              icon={<Icon component={() => <img src={DigitalDepositIcon} />} />}
              text="Digital Currency Deposit"
            />
          </Link>
          <Link to={"/deposit/credit-debit-deposit"}>
            <CardButton icon={<Icon component={() => <img src={FiatDepositIcon} />} />} text="Credit/Debit Deposit" />
          </Link>

          <Link to={"/deposit/erc20-axia-wallet-deposit"}>
            <CardButton
              icon={<Icon component={() => <img src={WalletIcon} />} />}
              text="AXIA Wallet on Ethereum (ERC-20 AXC)"
            />
          </Link>

          <Link to={"/deposit/axia-network-deposit"}>
            <CardButton icon={<Icon component={() => <img src={WalletIcon} />} />} text="AXIA Network Deposit" />
          </Link>
          {isValidForFeature({ user, featureName: FEATURE_NAMES.CUSTODY_V2 }) && (
            <Link to={"/deposit/axia-network-v2-deposit"}>
              <CardButton icon={<Icon component={() => <img src={WalletIcon} />} />} text="AXIA Network (V2) Deposit" />
            </Link>
          )}
          {/* <Link to={"/transfer/transaction-gateway"}>
            <CardButton icon={<SwapOutlined style={{ fontSize: 40 }} />} text="Transaction Gateway" />
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export const Deposit = boundError(DepositRaw);
