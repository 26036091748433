import React, { useEffect, useState } from "react";
import { Input, Checkbox, InputNumber } from "antd";
import { boundError, showSuccess, showError } from "../utils";
import { ProfilePage, ProfilePageHead } from "../components";
import { SimpleButton } from "../components";
import UserServices from "../services/userServices";
const _ = require("lodash");

const NotificationsRaw = () => {
  const [settings, setSettings] = useState({});
  const userServices = UserServices.getInstance();

  const onSave = async () => {
    try {
      await userServices.updateUserSettings({ notifications: settings });
      showSuccess("Notification settings updated successfully!");
    } catch (err) {
      showError(`Notification settings update failed: ${err}`);
    }
  };

  const getNotificationSettings = async () => {
    const notificationSettings = await userServices.getUserSettings().then((res) => res.notifications);

    setSettings(notificationSettings);
  };

  const getTableFields = () => {
    const tableFields = [];
    for (let [key, value] of Object.entries(settings)) {
      switch (key) {
        case "ON_LOGIN":
          tableFields.push({ title: "Notify me on account login", settingKey: "ON_LOGIN" });
          break;
        // case "BALANCE_LTE":
        //   tableFields.push({
        //     title: "Notify me when my balance on my account is lower than",
        //     settingKey: "BALANCE_LTE",
        //     inputFields: [{ type: "number", key: "lte" }]
        //   });
        //   break;
        // case "TRANSACTION_STATUS_UPDATE":
        //   tableFields.push({
        //     title: "Notify me when my transaction status is updated",
        //     settingKey: "TRANSACTION_STATUS_UPDATE"
        //   });
        //   break;
        // case "ACCOUNT_APPROVED":
        //   tableFields.push({
        //     title: "Notify me when my account is approved",
        //     settingKey: "ACCOUNT_APPROVED"
        //   });
        //   break;
        // case "ALPHAPO_TRANSACTION_RECIEVED":
        //   tableFields.push({
        //     title: "Notify me when I recieve an alphapo transaction",
        //     settingKey: "ALPHAPO_TRANSACTION_RECIEVED"
        //   });
        //   break;
      }
    }
    return tableFields;
  };

  const onCheckboxChange = (enable, key) => {
    let tempSettings = _.cloneDeep(settings);
    tempSettings[key].enabledEmail = enable;
    setSettings(tempSettings);
  };
  const onNumberInputChange = (value, settingKey, key) => {
    let tempSettings = _.cloneDeep(settings);
    tempSettings[settingKey][key] = value;
    setSettings(tempSettings);
  };

  useEffect(() => {
    getNotificationSettings();
  }, []);
  return (
    <ProfilePage>
      <ProfilePageHead
        title="Notifications"
        description="Manage what notifications you want to receive in your mailbox."
      />
      {getTableFields().map((field) => (
        <Field
          title={field.title}
          placeholder="Higher than"
          settingKey={field.settingKey}
          onCheckboxChange={onCheckboxChange}
          onNumberInputChange={onNumberInputChange}
          inputFields={field.inputFields}
          setting={settings[field.settingKey]}
        />
      ))}

      <div className="row" style={{ justifyContent: "flex-end", paddingTop: 20, borderTop: "1px solid #D7D7D9" }}>
        <SimpleButton onClick={onSave}>Save</SimpleButton>
      </div>
    </ProfilePage>
  );
};

const Field = ({
  title,
  value,
  onTextChange,
  placeholder,
  onCheckboxChange,
  onNumberInputChange,
  index,
  settingKey,
  inputFields,
  setting
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginTop: 10 }}>
      <Checkbox
        checked={setting.enabledEmail}
        onChange={(e) => onCheckboxChange(e.target.checked, settingKey)}
        style={{ fontSize: 16, fontWeight: "500" }}
      >
        {title}
      </Checkbox>
      {inputFields &&
        inputFields.map((field) => {
          switch (field.type) {
            case "number":
              return (
                <InputNumber
                  controls={false}
                  value={setting[field.key]}
                  style={{ maxWidth: 140, marginTop: 5, marginBottom: 10 }}
                  onChange={(e) => {
                    onNumberInputChange(e, settingKey, field.key);
                  }}
                />
              );
          }
        })}
    </div>
  );
};

export const Notifications = boundError(NotificationsRaw);
