import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Row, Space, Typography } from "antd";
import { UserOutlined, FileDoneOutlined, FormOutlined,IdcardOutlined } from "@ant-design/icons";
import { getUserKycSession } from "../../apis/kyc";
import { useNavigate } from "react-router-dom";
import { OnboardingButton } from "./SharedComponents";
import { REGISTRATION_DATA_STATUS } from "../../constants/registrationDataStatus";

const { Title, Text } = Typography;

export const BusinessOnboardingMenu = ({ userRegistrationStatus }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const kycStatus = userRegistrationStatus?.userRegistrationData?.kycStatus;
  const kycReady = kycStatus === REGISTRATION_DATA_STATUS.SUBMITTED || kycStatus === REGISTRATION_DATA_STATUS.COMPLETED;

  useEffect(async () => {
    if (!userRegistrationStatus || kycReady) {
      return;
    }

    try {
      setLoading(true);
      const kycSession = await getUserKycSession();
      const { refId } = kycSession;

      const blockpass = new window.BlockpassKYCConnect(process.env.REACT_APP_BLOCKPASS_CLIENT_ID, {
        env: "prod",
        local_user_id: refId,
        mainColor: "1c8fe1",
        email: userRegistrationStatus?.email
      });
      blockpass.startKYCConnect();
      blockpass.on("KYCConnectClose", reloadUserRegistrationStatus);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [userRegistrationStatus]);

  const disableButton = !!error || kycReady;

  return (
    <div style={{ padding: 15 }}>
      <Row justify="center">
        <Col sm={24} md={18}>
          <Title level={3}>Activate your business account</Title>
          <Title level={5} type="secondary">
            Complete the following steps to finish your application
          </Title>
          <Divider />
          <Space direction="vertical" style={{ width: "100%" }}>
            <OnboardingButton
              id="blockpass-kyc-connect"
              // href="/onboarding/business/kyc"
              title="Verify identity"
              subTitle="Verify your personal identity"
              icon={<UserOutlined style={{ fontSize: 35, position: "absolute", right: 15 }} />}
              status={userRegistrationStatus?.userRegistrationData?.kycStatus}
            />

            <OnboardingButton
              href="/onboarding/business/tax-id"
              title="Verify personal tax ID"
              subTitle="Verify your tax identification"
              icon={<FileDoneOutlined style={{ fontSize: 35, position: "absolute", right: 15 }} />}
              status={userRegistrationStatus?.userRegistrationData?.taxIdStatus}
            />

            <OnboardingButton
              href="/onboarding/business/business-records"
              title="Business records"
              subTitle="Describe briefly what your business does"
              icon={<IdcardOutlined style={{ fontSize: 35, position: "absolute", right: 15 }} />}
              status={userRegistrationStatus?.userRegistrationData?.businessRecordsStatus}
            />

            {/* <OnboardingButton
              href="/onboarding/business/tax-id"
              title="Certificate of Incorporation"
              subTitle="Submit a copy of the Certificate of Incorporation"
              icon={<FileDoneOutlined style={{ fontSize: 35, position: "absolute", right: 15 }} />}
              status={userRegistrationStatus?.userRegistrationData?.taxIdStatus}
            /> */}

            {/* <OnboardingButton
              href="/onboarding/business/tax-id"
              title="Primary purpose"
              subTitle="Describe briefly what your business does"
              icon={<FileDoneOutlined style={{ fontSize: 35, position: "absolute", right: 15 }} />}
              status={userRegistrationStatus?.userRegistrationData?.taxIdStatus}
            /> */}

            <OnboardingButton
              href="/onboarding/business/business-address"
              title="Business address"
              subTitle="Submit your business address"
              icon={<FormOutlined style={{ fontSize: 35, position: "absolute", right: 15 }} />}
              status={userRegistrationStatus?.userRegistrationData?.businessAddressStatus}
            />

            {/* <OnboardingButton
              href="/onboarding/business/tax-id"
              title="Proof of business address"
              subTitle="Submit a copy of a valid Proof of Address"
              icon={<FileDoneOutlined style={{ fontSize: 35, position: "absolute", right: 15 }} />}
              status={userRegistrationStatus?.userRegistrationData?.taxIdStatus}
            /> */}

            {/* <OnboardingButton
              href="/onboarding/business/tax-id"
              title="Transaction information"
              subTitle="Confirm your transactional information"
              icon={<FileDoneOutlined style={{ fontSize: 35, position: "absolute", right: 15 }} />}
              status={userRegistrationStatus?.userRegistrationData?.taxIdStatus}
            /> */}

            {/* <OnboardingButton
              href="/onboarding/business/tax-id"
              title="Business owners"
              subTitle="Add information about directors and shareholders"
              icon={<FileDoneOutlined style={{ fontSize: 35, position: "absolute", right: 15 }} />}
              status={userRegistrationStatus?.userRegistrationData?.taxIdStatus}
            /> */}
          </Space>
        </Col>
      </Row>
    </div>
  );
};
