"use strict";

class Country {
  constructor(name, code, alphaThreeCode, numericCode, dialCode) {
    this.name = name;
    this.code = code;
    this.alphaThreeCode = alphaThreeCode;
    this.numericCode = numericCode;
    this.dialCode = dialCode;
  }
}

export const countries = [
  {
    name: "Afghanistan",
    code: "AF",
    alphaThreeCode: "AFG",
    numericCode: 4,
    dialCode: "+93"
  },
  {
    name: "Åland Islands",
    code: "AX",
    alphaThreeCode: "ALA",
    numericCode: 248,
    dialCode: "+358"
  },
  {
    name: "Albania",
    code: "AL",
    alphaThreeCode: "ALB",
    numericCode: 8,
    dialCode: "+355"
  },
  {
    name: "Algeria",
    code: "DZ",
    alphaThreeCode: "DZA",
    numericCode: 12,
    dialCode: "+213"
  },
  {
    name: "American Samoa",
    code: "AS",
    alphaThreeCode: "ASM",
    numericCode: 16,
    dialCode: "+1684"
  },
  {
    name: "AndorrA",
    code: "AD",
    alphaThreeCode: "AND",
    numericCode: 20,
    dialCode: "+376"
  },
  {
    name: "Angola",
    code: "AO",
    alphaThreeCode: "AGO",
    numericCode: 24,
    dialCode: "+244"
  },
  {
    name: "Anguilla",
    code: "AI",
    alphaThreeCode: "AIA",
    numericCode: 660,
    dialCode: "+1264"
  },
  {
    name: "Antarctica",
    code: "AQ",
    alphaThreeCode: "ATA",
    numericCode: 10,
    dialCode: "+672"
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    alphaThreeCode: "ATG",
    numericCode: 28,
    dialCode: "+1268"
  },
  {
    name: "Argentina",
    code: "AR",
    alphaThreeCode: "ARG",
    numericCode: 32,
    dialCode: "+54"
  },
  {
    name: "Armenia",
    code: "AM",
    alphaThreeCode: "ARM",
    numericCode: 51,
    dialCode: "+374"
  },
  {
    name: "Aruba",
    code: "AW",
    alphaThreeCode: "ABW",
    numericCode: 533,
    dialCode: "+297"
  },
  {
    name: "Australia",
    code: "AU",
    alphaThreeCode: "AUS",
    numericCode: 36,
    dialCode: "+61"
  },
  {
    name: "Austria",
    code: "AT",
    alphaThreeCode: "AUT",
    numericCode: 40,
    dialCode: "+43"
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    alphaThreeCode: "AZE",
    numericCode: 31,
    dialCode: "+994"
  },
  {
    name: "Bahamas",
    code: "BS",
    alphaThreeCode: "BHS",
    numericCode: 44,
    dialCode: "+1242"
  },
  {
    name: "Bahrain",
    code: "BH",
    alphaThreeCode: "BHR",
    numericCode: 48,
    dialCode: "+973"
  },
  {
    name: "Bangladesh",
    code: "BD",
    alphaThreeCode: "BGD",
    numericCode: 50,
    dialCode: "+880"
  },
  {
    name: "Barbados",
    code: "BB",
    alphaThreeCode: "BRB",
    numericCode: 52,
    dialCode: "+1246"
  },
  {
    name: "Belarus",
    code: "BY",
    alphaThreeCode: "BLR",
    numericCode: 112,
    dialCode: "+375"
  },
  {
    name: "Belgium",
    code: "BE",
    alphaThreeCode: "BEL",
    numericCode: 56,
    dialCode: "+32"
  },
  {
    name: "Belize",
    code: "BZ",
    alphaThreeCode: "BLZ",
    numericCode: 84,
    dialCode: "+501"
  },
  {
    name: "Benin",
    code: "BJ",
    alphaThreeCode: "BEN",
    numericCode: 204,
    dialCode: "+229"
  },
  {
    name: "Bermuda",
    code: "BM",
    alphaThreeCode: "BMU",
    numericCode: 60,
    dialCode: "+1441"
  },
  {
    name: "Bhutan",
    code: "BT",
    alphaThreeCode: "BTN",
    numericCode: 64,
    dialCode: "+975"
  },
  {
    name: "Bolivia",
    code: "BO",
    alphaThreeCode: "BOL",
    numericCode: 68,
    dialCode: "+591"
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    alphaThreeCode: "BIH",
    numericCode: 70,
    dialCode: "+387"
  },
  {
    name: "Botswana",
    code: "BW",
    alphaThreeCode: "BWA",
    numericCode: 72,
    dialCode: "+267"
  },
  { name: "Bouvet Island", code: "BV", alphaThreeCode: "BVT", numericCode: 74, dialCode: "+55" },
  { name: "Brazil", code: "BR", alphaThreeCode: "BRA", numericCode: 76, dialCode: "+55" },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    alphaThreeCode: "IOT",
    numericCode: 86,
    dialCode: "+246"
  },
  {
    name: "Brunei Darussalam",
    code: "BN",
    alphaThreeCode: "BRN",
    numericCode: 96,
    dialCode: "+673"
  },
  {
    name: "Bulgaria",
    code: "BG",
    alphaThreeCode: "BGR",
    numericCode: 100,
    dialCode: "+359"
  },
  {
    name: "Burkina Faso",
    code: "BF",
    alphaThreeCode: "BFA",
    numericCode: 854,
    dialCode: "+226"
  },
  {
    name: "Burundi",
    code: "BI",
    alphaThreeCode: "BDI",
    numericCode: 108,
    dialCode: "+257"
  },
  {
    name: "Cambodia",
    code: "KH",
    alphaThreeCode: "KHM",
    numericCode: 116,
    dialCode: "+855"
  },
  {
    name: "Cameroon",
    code: "CM",
    alphaThreeCode: "CMR",
    numericCode: 120,
    dialCode: "+237"
  },
  { name: "Canada", code: "CA", alphaThreeCode: "CAN", numericCode: 124, dialCode: "+1" },
  {
    name: "Cape Verde",
    code: "CV",
    alphaThreeCode: "CPV",
    numericCode: 132,
    dialCode: "+238"
  },
  {
    name: "Cayman Islands",
    code: "KY",
    alphaThreeCode: "CYM",
    numericCode: 136,
    dialCode: "+345"
  },
  {
    name: "Central African Republic",
    code: "CF",
    alphaThreeCode: "CAF",
    numericCode: 140,
    dialCode: "+236"
  },
  { name: "Chad", code: "TD", alphaThreeCode: "TCD", numericCode: 148, dialCode: "+235" },
  { name: "Chile", code: "CL", alphaThreeCode: "CHL", numericCode: 152, dialCode: "+56" },
  { name: "China", code: "CN", alphaThreeCode: "CHN", numericCode: 156, dialCode: "+86" },
  {
    name: "Christmas Island",
    code: "CX",
    alphaThreeCode: "CXR",
    numericCode: 162,
    dialCode: "+61"
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    alphaThreeCode: "CCK",
    numericCode: 166,
    dialCode: "+61"
  },
  {
    name: "Colombia",
    code: "CO",
    alphaThreeCode: "COL",
    numericCode: 170,
    dialCode: "+57"
  },
  {
    name: "Comoros",
    code: "KM",
    alphaThreeCode: "COM",
    numericCode: 174,
    dialCode: "+269"
  },
  {
    name: "Congo",
    code: "CG",
    alphaThreeCode: "COG",
    numericCode: 178,
    dialCode: "+242"
  },
  {
    name: "Congo, The Democratic Republic of the",
    code: "CD",
    alphaThreeCode: "COD",
    numericCode: 180,
    dialCode: "+243"
  },
  {
    name: "Cook Islands",
    code: "CK",
    alphaThreeCode: "COK",
    numericCode: 184,
    dialCode: "+682"
  },
  {
    name: "Costa Rica",
    code: "CR",
    alphaThreeCode: "CRI",
    numericCode: 188,
    dialCode: "+506"
  },
  {
    name: "Cote D'Ivoire",
    code: "CI",
    alphaThreeCode: "CIV",
    numericCode: 384,
    dialCode: "+225"
  },
  {
    name: "Croatia",
    code: "HR",
    alphaThreeCode: "HRV",
    numericCode: 191,
    dialCode: "+385"
  },
  { name: "Cuba", code: "CU", alphaThreeCode: "CUB", numericCode: 192, dialCode: "+53" },
  {
    name: "Cyprus",
    code: "CY",
    alphaThreeCode: "CYP",
    numericCode: 196,
    dialCode: "+357"
  },
  {
    name: "Czech Republic",
    code: "CZ",
    alphaThreeCode: "CZE",
    numericCode: 203,
    dialCode: "+420"
  },
  {
    name: "Denmark",
    code: "DK",
    alphaThreeCode: "DNK",
    numericCode: 208,
    dialCode: "+45"
  },
  {
    name: "Djibouti",
    code: "DJ",
    alphaThreeCode: "DJI",
    numericCode: 262,
    dialCode: "+253"
  },
  {
    name: "Dominica",
    code: "DM",
    alphaThreeCode: "DMA",
    numericCode: 212,
    dialCode: "+1767"
  },
  {
    name: "Dominican Republic",
    code: "DO",
    alphaThreeCode: "DOM",
    numericCode: 214,
    dialCode: "+1849"
  },
  {
    name: "Ecuador",
    code: "EC",
    alphaThreeCode: "ECU",
    numericCode: 218,
    dialCode: "+593"
  },
  { name: "Egypt", code: "EG", alphaThreeCode: "EGY", numericCode: 818, dialCode: "+20" },
  {
    name: "El Salvador",
    code: "SV",
    alphaThreeCode: "SLV",
    numericCode: 222,
    dialCode: "+503"
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    alphaThreeCode: "GNQ",
    numericCode: 226,
    dialCode: "+240"
  },
  {
    name: "Eritrea",
    code: "ER",
    alphaThreeCode: "ERI",
    numericCode: 232,
    dialCode: "+291"
  },
  {
    name: "Estonia",
    code: "EE",
    alphaThreeCode: "EST",
    numericCode: 233,
    dialCode: "+372"
  },
  {
    name: "Ethiopia",
    code: "ET",
    alphaThreeCode: "ETH",
    numericCode: 231,
    dialCode: "+251"
  },
  {
    name: "Falkland Islands (Malvinas)",
    code: "FK",
    alphaThreeCode: "FLK",
    numericCode: 238,
    dialCode: "+500"
  },
  {
    name: "Faroe Islands",
    code: "FO",
    alphaThreeCode: "FRO",
    numericCode: 234,
    dialCode: "+298"
  },
  { name: "Fiji", code: "FJ", alphaThreeCode: "FJI", numericCode: 242, dialCode: "+679" },
  {
    name: "Finland",
    code: "FI",
    alphaThreeCode: "FIN",
    numericCode: 246,
    dialCode: "+358"
  },
  {
    name: "France",
    code: "FR",
    alphaThreeCode: "FRA",
    numericCode: 250,
    dialCode: "+33"
  },
  {
    name: "French Guiana",
    code: "GF",
    alphaThreeCode: "GUF",
    numericCode: 254,
    dialCode: "+594"
  },
  {
    name: "French Polynesia",
    code: "PF",
    alphaThreeCode: "PYF",
    numericCode: 258,
    dialCode: "+689"
  },
  {
    name: "French Southern Territories",
    code: "TF",
    alphaThreeCode: "ATF",
    numericCode: 260,
    dialCode: "+262"
  },
  {
    name: "Gabon",
    code: "GA",
    alphaThreeCode: "GAB",
    numericCode: 266,
    dialCode: "+241"
  },
  {
    name: "Gambia",
    code: "GM",
    alphaThreeCode: "GMB",
    numericCode: 270,
    dialCode: "+220"
  },
  {
    name: "Georgia",
    code: "GE",
    alphaThreeCode: "GEO",
    numericCode: 268,
    dialCode: "+995"
  },
  {
    name: "Germany",
    code: "DE",
    alphaThreeCode: "DEU",
    numericCode: 276,
    dialCode: "+49"
  },
  {
    name: "Ghana",
    code: "GH",
    alphaThreeCode: "GHA",
    numericCode: 288,
    dialCode: "+233"
  },
  {
    name: "Gibraltar",
    code: "GI",
    alphaThreeCode: "GIB",
    numericCode: 292,
    dialCode: "+350"
  },
  {
    name: "Greece",
    code: "GR",
    alphaThreeCode: "GRC",
    numericCode: 300,
    dialCode: "+30"
  },
  {
    name: "Greenland",
    code: "GL",
    alphaThreeCode: "GRL",
    numericCode: 304,
    dialCode: "+299"
  },
  {
    name: "Grenada",
    code: "GD",
    alphaThreeCode: "GRD",
    numericCode: 308,
    dialCode: "+1473"
  },
  {
    name: "Guadeloupe",
    code: "GP",
    alphaThreeCode: "GLP",
    numericCode: 312,
    dialCode: "+590"
  },
  {
    name: "Guam",
    code: "GU",
    alphaThreeCode: "GUM",
    numericCode: 316,
    dialCode: "+1671"
  },
  {
    name: "Guatemala",
    code: "GT",
    alphaThreeCode: "GTM",
    numericCode: 320,
    dialCode: "+502"
  },
  {
    name: "Guernsey",
    code: "GG",
    alphaThreeCode: "GGY",
    numericCode: 831,
    dialCode: "+44"
  },
  {
    name: "Guinea",
    code: "GN",
    alphaThreeCode: "GIN",
    numericCode: 324,
    dialCode: "+224"
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    alphaThreeCode: "GNB",
    numericCode: 624,
    dialCode: "+245"
  },
  {
    name: "Guyana",
    code: "GY",
    alphaThreeCode: "GUY",
    numericCode: 328,
    dialCode: "+595"
  },
  {
    name: "Haiti",
    code: "HT",
    alphaThreeCode: "HTI",
    numericCode: 332,
    dialCode: "+509"
  },
  {
    name: "Heard Island and Mcdonald Islands",
    code: "HM",
    alphaThreeCode: "HMD",
    numericCode: 334,
    dialCode: "+672"
  },
  {
    name: "Holy See (Vatican City State)",
    code: "VA",
    alphaThreeCode: "VAT",
    numericCode: 336,
    dialCode: "+379"
  },
  {
    name: "Honduras",
    code: "HN",
    alphaThreeCode: "HND",
    numericCode: 340,
    dialCode: "+504"
  },
  {
    name: "Hong Kong",
    code: "HK",
    alphaThreeCode: "HKG",
    numericCode: 344,
    dialCode: "+852"
  },
  {
    name: "Hungary",
    code: "HU",
    alphaThreeCode: "HUN",
    numericCode: 348,
    dialCode: "+36"
  },
  {
    name: "Iceland",
    code: "IS",
    alphaThreeCode: "ISL",
    numericCode: 352,
    dialCode: "+354"
  },
  { name: "India", code: "IN", alphaThreeCode: "IND", numericCode: 356, dialCode: "+91" },
  {
    name: "Indonesia",
    code: "ID",
    alphaThreeCode: "IDN",
    numericCode: 360,
    dialCode: "+62"
  },
  {
    name: "Iran, Islamic Republic Of",
    code: "IR",
    alphaThreeCode: "IRN",
    numericCode: 364,
    dialCode: "+98"
  },
  { name: "Iraq", code: "IQ", alphaThreeCode: "IRQ", numericCode: 368, dialCode: "+964" },
  {
    name: "Ireland",
    code: "IE",
    alphaThreeCode: "IRL",
    numericCode: 372,
    dialCode: "+353"
  },
  {
    name: "Isle of Man",
    code: "IM",
    alphaThreeCode: "IMN",
    numericCode: 833,
    dialCode: "+44"
  },
  {
    name: "Israel",
    code: "IL",
    alphaThreeCode: "ISR",
    numericCode: 376,
    dialCode: "+972"
  },
  { name: "Italy", code: "IT", alphaThreeCode: "ITA", numericCode: 380, dialCode: "+39" },
  {
    name: "Jamaica",
    code: "JM",
    alphaThreeCode: "JAM",
    numericCode: 388,
    dialCode: "+1876"
  },
  { name: "Japan", code: "JP", alphaThreeCode: "JPN", numericCode: 392, dialCode: "+81" },
  {
    name: "Jersey",
    code: "JE",
    alphaThreeCode: "JEY",
    numericCode: 832,
    dialCode: "+44"
  },
  {
    name: "Jordan",
    code: "JO",
    alphaThreeCode: "JOR",
    numericCode: 400,
    dialCode: "+962"
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    alphaThreeCode: "KAZ",
    numericCode: 398,
    dialCode: "+77"
  },
  {
    name: "Kenya",
    code: "KE",
    alphaThreeCode: "KEN",
    numericCode: 404,
    dialCode: "+254"
  },
  {
    name: "Kiribati",
    code: "KI",
    alphaThreeCode: "KIR",
    numericCode: 296,
    dialCode: "+686"
  },
  {
    name: "Korea, Democratic People'S Republic of",
    code: "KP",
    alphaThreeCode: "PRK",
    numericCode: 408,
    dialCode: "+850"
  },
  {
    name: "Korea, Republic of",
    code: "KR",
    alphaThreeCode: "KOR",
    numericCode: 410,
    dialCode: "+82"
  },
  {
    name: "Kuwait",
    code: "KW",
    alphaThreeCode: "KWT",
    numericCode: 414,
    dialCode: "+965"
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    alphaThreeCode: "KGZ",
    numericCode: 417,
    dialCode: "+996"
  },
  {
    name: "Lao People'S Democratic Republic",
    code: "LA",
    alphaThreeCode: "LAO",
    numericCode: 418,
    dialCode: "+856"
  },
  {
    name: "Latvia",
    code: "LV",
    alphaThreeCode: "LVA",
    numericCode: 428,
    dialCode: "+371"
  },
  {
    name: "Lebanon",
    code: "LB",
    alphaThreeCode: "LBN",
    numericCode: 422,
    dialCode: "+961"
  },
  {
    name: "Lesotho",
    code: "LS",
    alphaThreeCode: "LSO",
    numericCode: 426,
    dialCode: "+266"
  },
  {
    name: "Liberia",
    code: "LR",
    alphaThreeCode: "LBR",
    numericCode: 430,
    dialCode: "+231"
  },
  {
    name: "Libyan Arab Jamahiriya",
    code: "LY",
    alphaThreeCode: "LBY",
    numericCode: 434,
    dialCode: "+218"
  },
  {
    name: "Liechtenstein",
    code: "LI",
    alphaThreeCode: "LIE",
    numericCode: 438,
    dialCode: "+423"
  },
  {
    name: "Lithuania",
    code: "LT",
    alphaThreeCode: "LTU",
    numericCode: 440,
    dialCode: "+370"
  },
  {
    name: "Luxembourg",
    code: "LU",
    alphaThreeCode: "LUX",
    numericCode: 442,
    dialCode: "+352"
  },
  {
    name: "Macao",
    code: "MO",
    alphaThreeCode: "MAC",
    numericCode: 446,
    dialCode: "+853"
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
    alphaThreeCode: "MKD",
    numericCode: 807,
    dialCode: "+389"
  },
  {
    name: "Madagascar",
    code: "MG",
    alphaThreeCode: "MDG",
    numericCode: 450,
    dialCode: "+261"
  },
  {
    name: "Malawi",
    code: "MW",
    alphaThreeCode: "MWI",
    numericCode: 454,
    dialCode: "+265"
  },
  {
    name: "Malaysia",
    code: "MY",
    alphaThreeCode: "MYS",
    numericCode: 458,
    dialCode: "+60"
  },
  {
    name: "Maldives",
    code: "MV",
    alphaThreeCode: "MDV",
    numericCode: 462,
    dialCode: "+960"
  },
  { name: "Mali", code: "ML", alphaThreeCode: "MLI", numericCode: 466, dialCode: "+223" },
  {
    name: "Malta",
    code: "MT",
    alphaThreeCode: "MLT",
    numericCode: 470,
    dialCode: "+356"
  },
  {
    name: "Marshall Islands",
    code: "MH",
    alphaThreeCode: "MHL",
    numericCode: 584,
    dialCode: "+692"
  },
  {
    name: "Martinique",
    code: "MQ",
    alphaThreeCode: "MTQ",
    numericCode: 474,
    dialCode: "+596"
  },
  {
    name: "Mauritania",
    code: "MR",
    alphaThreeCode: "MRT",
    numericCode: 478,
    dialCode: "+222"
  },
  {
    name: "Mauritius",
    code: "MU",
    alphaThreeCode: "MUS",
    numericCode: 480,
    dialCode: "+230"
  },
  {
    name: "Mayotte",
    code: "YT",
    alphaThreeCode: "MYT",
    numericCode: 175,
    dialCode: "+262"
  },
  {
    name: "Mexico",
    code: "MX",
    alphaThreeCode: "MEX",
    numericCode: 484,
    dialCode: "+52"
  },
  {
    name: "Micronesia, Federated States of",
    code: "FM",
    alphaThreeCode: "FSM",
    numericCode: 583,
    dialCode: "+691"
  },
  {
    name: "Moldova, Republic of",
    code: "MD",
    alphaThreeCode: "MDA",
    numericCode: 498,
    dialCode: "+373"
  },
  {
    name: "Monaco",
    code: "MC",
    alphaThreeCode: "MCO",
    numericCode: 492,
    dialCode: "+377"
  },
  {
    name: "Mongolia",
    code: "MN",
    alphaThreeCode: "MNG",
    numericCode: 496,
    dialCode: "+976"
  },
  {
    name: "Montserrat",
    code: "MS",
    alphaThreeCode: "MSR",
    numericCode: 500,
    dialCode: "+1664"
  },
  {
    name: "Morocco",
    code: "MA",
    alphaThreeCode: "MAR",
    numericCode: 504,
    dialCode: "+212"
  },
  {
    name: "Mozambique",
    code: "MZ",
    alphaThreeCode: "MOZ",
    numericCode: 508,
    dialCode: "+258"
  },
  {
    name: "Myanmar",
    code: "MM",
    alphaThreeCode: "MMR",
    numericCode: 104,
    dialCode: "+95"
  },
  {
    name: "Namibia",
    code: "NA",
    alphaThreeCode: "NAM",
    numericCode: 516,
    dialCode: "+264"
  },
  {
    name: "Nauru",
    code: "NR",
    alphaThreeCode: "NRU",
    numericCode: 520,
    dialCode: "+674"
  },
  {
    name: "Nepal",
    code: "NP",
    alphaThreeCode: "NPL",
    numericCode: 524,
    dialCode: "+977"
  },
  {
    name: "Netherlands",
    code: "NL",
    alphaThreeCode: "NLD",
    numericCode: 528,
    dialCode: "+31"
  },
  {
    name: "Netherlands Antilles",
    code: "AN",
    alphaThreeCode: "ANT",
    numericCode: 530,
    dialCode: "+599"
  },
  {
    name: "New Caledonia",
    code: "NC",
    alphaThreeCode: "NCL",
    numericCode: 540,
    dialCode: "+687"
  },
  {
    name: "New Zealand",
    code: "NZ",
    alphaThreeCode: "NZL",
    numericCode: 554,
    dialCode: "+64"
  },
  {
    name: "Nicaragua",
    code: "NI",
    alphaThreeCode: "NIC",
    numericCode: 558,
    dialCode: "+505"
  },
  {
    name: "Niger",
    code: "NE",
    alphaThreeCode: "NER",
    numericCode: 562,
    dialCode: "+227"
  },
  {
    name: "Nigeria",
    code: "NG",
    alphaThreeCode: "NGA",
    numericCode: 566,
    dialCode: "+234"
  },
  { name: "Niue", code: "NU", alphaThreeCode: "NIU", numericCode: 570, dialCode: "+683" },
  {
    name: "Norfolk Island",
    code: "NF",
    alphaThreeCode: "NFK",
    numericCode: 574,
    dialCode: "+672"
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    alphaThreeCode: "MNP",
    numericCode: 580,
    dialCode: "+1670"
  },
  {
    name: "Norway",
    code: "NO",
    alphaThreeCode: "NOR",
    numericCode: 578,
    dialCode: "+47"
  },
  { name: "Oman", code: "OM", alphaThreeCode: "OMN", numericCode: 512, dialCode: "+968" },
  {
    name: "Pakistan",
    code: "PK",
    alphaThreeCode: "PAK",
    numericCode: 586,
    dialCode: "+92"
  },
  {
    name: "Palau",
    code: "PW",
    alphaThreeCode: "PLW",
    numericCode: 585,
    dialCode: "+680"
  },
  {
    name: "Palestinian Territory, Occupied",
    code: "PS",
    alphaThreeCode: "PSE",
    numericCode: 275,
    dialCode: "+970"
  },
  {
    name: "Panama",
    code: "PA",
    alphaThreeCode: "PAN",
    numericCode: 591,
    dialCode: "+507"
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    alphaThreeCode: "PNG",
    numericCode: 598,
    dialCode: "+675"
  },
  {
    name: "Paraguay",
    code: "PY",
    alphaThreeCode: "PRY",
    numericCode: 600,
    dialCode: "+595"
  },
  { name: "Peru", code: "PE", alphaThreeCode: "PER", numericCode: 604, dialCode: "+51" },
  {
    name: "Philippines",
    code: "PH",
    alphaThreeCode: "PHL",
    numericCode: 608,
    dialCode: "+63"
  },
  {
    name: "Pitcairn",
    code: "PN",
    alphaThreeCode: "PCN",
    numericCode: 612,
    dialCode: "+872"
  },
  {
    name: "Poland",
    code: "PL",
    alphaThreeCode: "POL",
    numericCode: 616,
    dialCode: "+48"
  },
  {
    name: "Portugal",
    code: "PT",
    alphaThreeCode: "PRT",
    numericCode: 620,
    dialCode: "+351"
  },
  {
    name: "Puerto Rico",
    code: "PR",
    alphaThreeCode: "PRI",
    numericCode: 630,
    dialCode: "+1939"
  },
  {
    name: "Qatar",
    code: "QA",
    alphaThreeCode: "QAT",
    numericCode: 634,
    dialCode: "+974"
  },
  {
    name: "Reunion",
    code: "RE",
    alphaThreeCode: "REU",
    numericCode: 638,
    dialCode: "+262"
  },
  {
    name: "Romania",
    code: "RO",
    alphaThreeCode: "ROU",
    numericCode: 642,
    dialCode: "+40"
  },
  {
    name: "Russian Federation",
    code: "RU",
    alphaThreeCode: "RUS",
    numericCode: 643,
    dialCode: "+7"
  },
  {
    name: "RWANDA",
    code: "RW",
    alphaThreeCode: "RWA",
    numericCode: 646,
    dialCode: "+250"
  },
  {
    name: "Saint Helena",
    code: "SH",
    alphaThreeCode: "SHN",
    numericCode: 654,
    dialCode: "+290"
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    alphaThreeCode: "KNA",
    numericCode: 659,
    dialCode: "+1869"
  },
  {
    name: "Saint Lucia",
    code: "LC",
    alphaThreeCode: "LCA",
    numericCode: 662,
    dialCode: "+1758"
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    alphaThreeCode: "SPM",
    numericCode: 666,
    dialCode: "+508"
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    alphaThreeCode: "VCT",
    numericCode: 670,
    dialCode: "+1784"
  },
  {
    name: "Samoa",
    code: "WS",
    alphaThreeCode: "WSM",
    numericCode: 882,
    dialCode: "+685"
  },
  {
    name: "San Marino",
    code: "SM",
    alphaThreeCode: "SMR",
    numericCode: 674,
    dialCode: "+378"
  },
  {
    name: "Sao Tome and Principe",
    code: "ST",
    alphaThreeCode: "STP",
    numericCode: 678,
    dialCode: "+239"
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    alphaThreeCode: "SAU",
    numericCode: 682,
    dialCode: "+966"
  },
  {
    name: "Senegal",
    code: "SN",
    alphaThreeCode: "SEN",
    numericCode: 686,
    dialCode: "+221"
  },
  {
    name: "Serbia and Montenegro",
    code: "CS",
    alphaThreeCode: "SCG",
    numericCode: 891,
    dialCode: "+381"
  },
  {
    name: "Seychelles",
    code: "SC",
    alphaThreeCode: "SYC",
    numericCode: 690,
    dialCode: "+248"
  },
  {
    name: "Sierra Leone",
    code: "SL",
    alphaThreeCode: "SLE",
    numericCode: 694,
    dialCode: "+232"
  },
  {
    name: "Singapore",
    code: "SG",
    alphaThreeCode: "SGP",
    numericCode: 702,
    dialCode: "+65"
  },
  {
    name: "Slovakia",
    code: "SK",
    alphaThreeCode: "SVK",
    numericCode: 703,
    dialCode: "+421"
  },
  {
    name: "Slovenia",
    code: "SI",
    alphaThreeCode: "SVN",
    numericCode: 705,
    dialCode: "+386"
  },
  {
    name: "Solomon Islands",
    code: "SB",
    alphaThreeCode: "SLB",
    numericCode: 90,
    dialCode: "+677"
  },
  {
    name: "Somalia",
    code: "SO",
    alphaThreeCode: "SOM",
    numericCode: 706,
    dialCode: "+252"
  },
  {
    name: "South Africa",
    code: "ZA",
    alphaThreeCode: "ZAF",
    numericCode: 710,
    dialCode: "+27"
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    alphaThreeCode: "SGS",
    numericCode: 239,
    dialCode: "+500"
  },
  { name: "Spain", code: "ES", alphaThreeCode: "ESP", numericCode: 724, dialCode: "+34" },
  {
    name: "Sri Lanka",
    code: "LK",
    alphaThreeCode: "LKA",
    numericCode: 144,
    dialCode: "+94"
  },
  {
    name: "Sudan",
    code: "SD",
    alphaThreeCode: "SDN",
    numericCode: 729,
    dialCode: "+249"
  },
  {
    name: "Suriname",
    code: "SR",
    alphaThreeCode: "SUR",
    numericCode: 740,
    dialCode: "+597"
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    alphaThreeCode: "SJM",
    numericCode: 744,
    dialCode: "+47"
  },
  {
    name: "Sint Maarten",
    code: "SX",
    alphaThreeCode: "SXM",
    numericCode: 534,
    dialCode: "+721"
  },
  {
    name: "Swaziland",
    code: "SZ",
    alphaThreeCode: "SWZ",
    numericCode: 748,
    dialCode: "+268"
  },
  {
    name: "Sweden",
    code: "SE",
    alphaThreeCode: "SWE",
    numericCode: 752,
    dialCode: "+46"
  },
  {
    name: "Switzerland",
    code: "CH",
    alphaThreeCode: "CHE",
    numericCode: 756,
    dialCode: "+41"
  },
  {
    name: "Syrian Arab Republic",
    code: "SY",
    alphaThreeCode: "SYR",
    numericCode: 760,
    dialCode: "+963"
  },
  {
    name: "Taiwan, Province of China",
    code: "TW",
    alphaThreeCode: "TWN",
    numericCode: 158,
    dialCode: "+886"
  },
  {
    name: "Tajikistan",
    code: "TJ",
    alphaThreeCode: "TJK",
    numericCode: 762,
    dialCode: "+992"
  },
  {
    name: "Tanzania, United Republic of",
    code: "TZ",
    alphaThreeCode: "TZA",
    numericCode: 834,
    dialCode: "+255"
  },
  {
    name: "Thailand",
    code: "TH",
    alphaThreeCode: "THA",
    numericCode: 764,
    dialCode: "+66"
  },
  {
    name: "Timor-Leste",
    code: "TL",
    alphaThreeCode: "TLS",
    numericCode: 626,
    dialCode: "+670"
  },
  { name: "Togo", code: "TG", alphaThreeCode: "TGO", numericCode: 768, dialCode: "+228" },
  {
    name: "Tokelau",
    code: "TK",
    alphaThreeCode: "TKL",
    numericCode: 772,
    dialCode: "+690"
  },
  {
    name: "Tonga",
    code: "TO",
    alphaThreeCode: "TON",
    numericCode: 776,
    dialCode: "+676"
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    alphaThreeCode: "TTO",
    numericCode: 780,
    dialCode: "+1868"
  },
  {
    name: "Tunisia",
    code: "TN",
    alphaThreeCode: "TUN",
    numericCode: 788,
    dialCode: "+216"
  },
  {
    name: "Turkey",
    code: "TR",
    alphaThreeCode: "TUR",
    numericCode: 792,
    dialCode: "+90"
  },
  {
    name: "Turkmenistan",
    code: "TM",
    alphaThreeCode: "TKM",
    numericCode: 795,
    dialCode: "+993"
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    alphaThreeCode: "TCA",
    numericCode: 796,
    dialCode: "+1649"
  },
  {
    name: "Tuvalu",
    code: "TV",
    alphaThreeCode: "TUV",
    numericCode: 798,
    dialCode: "+688"
  },
  {
    name: "Uganda",
    code: "UG",
    alphaThreeCode: "UGA",
    numericCode: 800,
    dialCode: "+256"
  },
  {
    name: "Ukraine",
    code: "UA",
    alphaThreeCode: "UKR",
    numericCode: 804,
    dialCode: "+380"
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    alphaThreeCode: "ARE",
    numericCode: 784,
    dialCode: "+971"
  },
  {
    name: "United Kingdom",
    code: "GB",
    alphaThreeCode: "GBR",
    numericCode: 826,
    dialCode: "+44"
  },
  {
    name: "United States",
    code: "US",
    alphaThreeCode: "USA",
    numericCode: 840,
    dialCode: "+1"
  },
  {
    name: "United States Minor Outlying Islands",
    code: "UM",
    alphaThreeCode: "UMI",
    numericCode: 581,
    dialCode: "+246"
  },
  {
    name: "Uruguay",
    code: "UY",
    alphaThreeCode: "URY",
    numericCode: 858,
    dialCode: "+598"
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    alphaThreeCode: "UZB",
    numericCode: 860,
    dialCode: "+998"
  },
  {
    name: "Vanuatu",
    code: "VU",
    alphaThreeCode: "VUT",
    numericCode: 548,
    dialCode: "+678"
  },
  {
    name: "Venezuela",
    code: "VE",
    alphaThreeCode: "VEN",
    numericCode: 862,
    dialCode: "+58"
  },
  {
    name: "Viet Nam",
    code: "VN",
    alphaThreeCode: "VNM",
    numericCode: 704,
    dialCode: "+84"
  },
  {
    name: "Virgin Islands, British",
    code: "VG",
    alphaThreeCode: "VGB",
    numericCode: 92,
    dialCode: "+1284"
  },
  {
    name: "Virgin Islands, U.S.",
    code: "VI",
    alphaThreeCode: "VIR",
    numericCode: 850,
    dialCode: "+1340"
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    alphaThreeCode: "WLF",
    numericCode: 876,
    dialCode: "+681"
  },
  { name: "Western Sahara", code: "EH", alphaThreeCode: "ESH", numericCode: 732, dialCode: "+212" },
  {
    name: "Yemen",
    code: "YE",
    alphaThreeCode: "YEM",
    numericCode: 887,
    dialCode: "+967"
  },
  {
    name: "Zambia",
    code: "ZM",
    alphaThreeCode: "ZMB",
    numericCode: 894,
    dialCode: "+260"
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    alphaThreeCode: "ZWE",
    numericCode: 716,
    dialCode: "+263"
  }
];

export const COUNTRY = countries.reduce((prev, curr) => {
  prev[curr.code] = new Country(curr.name, curr.code, curr.alphaThreeCode, curr.numericCode, curr.dialCode);
  return prev;
}, {});

export const VALID_COUNTRY_CODES = new Set(Object.values(COUNTRY).map((country) => country.code));

export const isValidCountryCode = (alpha2Code) => {
  return VALID_COUNTRY_CODES.has(alpha2Code);
};

export const getCountryObjByCode = (code) => countries.find((country) => country.code === code);
export const getCountryObjByAlphaThreeCode = (alphaThreeCode) =>
  countries.find((country) => country.alphaThreeCode === alphaThreeCode);
export const getCountryObjByNumericCode = (numericCode) =>
  countries.find((country) => country.numericCode === numericCode);

export const getCountryNameByCode = (code) => countries.find((country) => country.code === code)?.name ?? code;
export const getCountryNameByAlphaThreeCode = (alphaThreeCode) =>
  countries.find((country) => country.alphaThreeCode === alphaThreeCode)?.name ?? alphaThreeCode;
