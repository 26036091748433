import { notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { WarningOutlined } from "@ant-design/icons";
import { disableMFASubmit, enableMFARequest, enableMFASubmit } from "../../apis";
import { updateMFAStatus } from "../../redux/actions";
import { boundError } from "../../utils";
import { MFASubmit, MFAQrCode } from "..";
import { handleErrorMessage } from "../../constants/messages";

function MFAHandlerRaw({ mfaEnabled, onCancel }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [keyStr, setKeyStr] = useState("");
  const [qrCodeStr, setQrCodeStr] = useState("");
  const [qrCodeError, setQrCodeError] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (mfaEnabled === false) {
      loadQRCode();
    }
  }, []);
  const loadQRCode = async () => {
    setLoading(true);
    try {
      const { user, keyStr, qrCodeStr } = await enableMFARequest();
      setKeyStr(keyStr);
      setQrCodeStr(qrCodeStr);
      setUser(user);
    } catch (err) {
      console.log(err);
      setQrCodeError(handleErrorMessage(err));
    }
    setLoading(false);
  };
  const submitMFARequest = async (code) => {
    setLoading(true);
    try {
      setSubmitError("");
      if (mfaEnabled) {
        await disableMFASubmit(code);
      } else {
        await enableMFASubmit(user, code);
      }
      notification.success({
        message: `Two-factor authentication ${mfaEnabled ? "disabled" : "enabled"}.`
      });
      onCancel();
      dispatch(updateMFAStatus(!mfaEnabled));
    } catch (err) {
      console.log(err);
      setSubmitError(handleErrorMessage(err));
    }
    setLoading(false);
  };
  if (mfaEnabled === undefined) {
    return <div>Sorry, an error occurred: 2FA status not available</div>;
  }
  if (mfaEnabled === true) {
    // disable MFA
    return (
      <Spin spinning={loading}>
        <div style={{ fontSize: 16, fontWeight: "600", marginBottom: 50 }}>
          <WarningOutlined style={{ marginRight: 5 }} />
          Disable two factor authentication
        </div>
        <MFASubmit
          onCancel={onCancel}
          onConfirm={submitMFARequest}
          okButtonText="Disable"
          okButtonBackgroundColor="red"
          error={submitError}
          setError={setSubmitError}
        />
      </Spin>
    );
  }
  // enable 2FA
  if (qrCodeError) {
    // failed to load QR code
    return <div style={{ textAlign: "center", fontSize: 20, fontWeight: "500" }}>Sorry, an error occurred</div>;
  }
  return (
    <Spin spinning={loading}>
      <MFAQrCode keyStr={keyStr} qrCodeStr={qrCodeStr} loading={loading} />
      <MFASubmit
        onCancel={onCancel}
        onConfirm={submitMFARequest}
        okButtonText="Enable"
        error={submitError}
        setError={setSubmitError}
      />
    </Spin>
  );
}

export const MFAHandler = boundError(MFAHandlerRaw);
