"use strict";

export const TRANSACTION_TYPE = {
  USER_CREATED: "USER_CREATED",
  SERVICE_FEE: "SERVICE_FEE",
  INTEREST: "INTEREST",
  SYSTEM_TRANSFER: "SYSTEM_TRANSFER",
  ADMIN_TRANSFER: "ADMIN_TRANSFER",
  USER_STAKING_CREATED: "USER_STAKING_CREATED",
  USER_STAKING_ADD_AMOUNT: "USER_STAKING_ADD_AMOUNT",
  USER_STAKING_LOAD_TO_CARD: "USER_STAKING_LOAD_TO_CARD",
  USER_STAKING_CLAIM: "USER_STAKING_CLAIM",
  USER_TRANSFER_BETWEEN_MEMBERS: "USER_TRANSFER_BETWEEN_MEMBERS",
  USER_TRANSFER_BETWEEN_ACCOUNTS: "USER_TRANSFER_BETWEEN_ACCOUNTS",
  EXTERNAL_TRANSACTION: "EXTERNAL_TRANSACTION",
  WIRE_TRANSFER: "WIRE_TRANSFER",
  WIRE_DEPOSIT: "WIRE_DEPOSIT",
  WIRE_TRANSFER_REFUND: "WIRE_TRANSFER_REFUND",
  CARD_ISSUE: "CARD_ISSUE",
  CARD_LOAD: "CARD_LOAD",
  EXTERNAL_ERC20_WALLET_TRANSACTION: "EXTERNAL_ERC20_WALLET_TRANSACTION",
  BANK_UPGRADE_MIGRATION: "BANK_UPGRADE_MIGRATION",
  GIFT_CARD_ORDER: "GIFT_CARD_ORDER",
  GIFT_CARD_ORDER_REFUND: "GIFT_CARD_ORDER_REFUND",
  DIGITAL_CURRENCY_DEPOSIT: "DIGITAL_CURRENCY_DEPOSIT",
  EXTERNAL_AXTEND_WALLET_TRANSACTION: "EXTERNAL_AXTEND_WALLET_TRANSACTION",
  EXTERNAL_AXIA_NETWORK_WITHDRAWAL: "EXTERNAL_AXIA_NETWORK_WITHDRAWAL",
  EXTERNAL_AXIA_NETWORK_DEPOSIT: "EXTERNAL_AXIA_NETWORK_DEPOSIT",
  EXTERNAL_AXIA_NETWORK_WITHDRAWAL_REFUND: "EXTERNAL_AXIA_NETWORK_WITHDRAWAL_REFUND",
  EXTERNAL_AXIA_NETWORK_V2_WITHDRAWAL: "EXTERNAL_AXIA_NETWORK_V2_WITHDRAWAL",
  EXTERNAL_AXIA_NETWORK_V2_DEPOSIT: "EXTERNAL_AXIA_NETWORK_V2_DEPOSIT",
  EXTERNAL_AXIA_NETWORK_V2_WITHDRAWAL_REFUND: "EXTERNAL_AXIA_NETWORK_V2_WITHDRAWAL_REFUND"
};

export const TRANSACTION_DIRECTION = {
  WITHDRAWAL: "WITHDRAWAL",
  DEPOSIT: "DEPOSIT"
};
