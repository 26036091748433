import React from "react";
import { boundError } from "../../../utils";

const AccountsHeadCardRaw = ({children}) => {
  return (
    <div className="account-head-card-container">
      {children}
    </div>
  );
};

export const AccountsHeadCard = boundError(AccountsHeadCardRaw);
