import React, { useEffect, useState } from "react";
import { ArrowUpOutlined, SwapOutlined, TeamOutlined, BlockOutlined } from "@ant-design/icons";
import { CardButton } from "../components";
import { Link } from "react-router-dom";
import { boundError } from "../utils";
import Icon from "@ant-design/icons";
import TransferIcon from "../resources/icons/deposit/wire_transfer.svg";
import BetweenAccountsIcon from "../resources/icons/transfer/between_accounts.svg";
import BetweenMembersIcon from "../resources/icons/transfer/between_members.svg";
// for testing
import { UserContext } from "../hooks";
import { useContext } from "react";
import { isValidForFeature, FEATURE_NAMES } from "../utils";

const TransferRaw = () => {
  const [user] = useContext(UserContext);
  // will be removed after the token split
  const [isTokenSplitOn, setIsTokenSplitOn] = useState(true);
  useEffect(() => {
    fetchTokenSplitEnable();
  }, []);

  const fetchTokenSplitEnable = async () => {
    const isUserCreatedBefore = new Date(user?.createdAt || null) < new Date("2022-07-29T00:00:00");
    const isConsentEnabled = isValidForFeature({ user, featureName: FEATURE_NAMES.TOKEN_MIGRATION_CONSENT }) === true;
    if (isUserCreatedBefore && isConsentEnabled) {
      setIsTokenSplitOn(true);
    } else {
      setIsTokenSplitOn(false);
    }
  };

  return (
    <div>
      <div className="row page-title">Transfer</div>
      <div className="body-padding avoid-bottom-bar">
        <div className="grid-1">
          <Link to={"between-accounts"}>
            <CardButton icon={<Icon component={() => <img src={BetweenAccountsIcon} />} />} text="Between Accounts" />
          </Link>
          <Link to={"between-members"}>
            <CardButton icon={<Icon component={() => <img src={BetweenMembersIcon} />} />} text="Between Members" />
          </Link>
          <Link to={"wire-transfer"}>
            <CardButton icon={<Icon component={() => <img src={TransferIcon} />} />} text="Wire Transfer" />
          </Link>
          {!isTokenSplitOn && (
            <Link to={"external-transfer"}>
              <CardButton
                icon={<Icon component={() => <img src={TransferIcon} />} />}
                text="Transfer to External Ethereum Wallet"
              />
            </Link>
          )}
          {/* <Link to={"external-axtend-transfer"}>
            <CardButton
              icon={<Icon component={() => <img src={TransferIcon} />} />}
              text="Transfer to External AXtend Wallet"
            />
          </Link> */}
          {isValidForFeature({ user, featureName: FEATURE_NAMES.CUSTODY }) && (
            <Link to={"axia-network-transfer"}>
              <CardButton
                icon={<Icon component={() => <img src={TransferIcon} />} />}
                text="Transfer to External AXIA Network Wallet"
              />
            </Link>
          )}
          {isValidForFeature({ user, featureName: FEATURE_NAMES.CUSTODY_V2 }) && (
            <Link to={"axia-network-v2-transfer"}>
              <CardButton
                icon={<Icon component={() => <img src={TransferIcon} />} />}
                text="Transfer to External AXIA Network (V2) Wallet"
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export const Transfer = boundError(TransferRaw);
