import React, { useState, useEffect, useContext } from "react";
import { AccountsHeadBalance } from "./AccountsHeadComponents/AccountsHeadBalance";
import { AccountsHeadExchangeRate } from "./AccountsHeadComponents/AccountsHeadExchangeRate";
import { AccountsHeadUserSpendable } from "./AccountsHeadComponents/AccountsHeadUserSpendable";
import { AccountsHeadInterestRateEarned } from "./AccountsHeadComponents/AccountsHeadInterestRateEarned";
import { getConversionRateApi, getAccountOverview } from "../../apis";
import { CURRENCY } from "../../constants/currency";
import Big from "big.js";

import { boundError, isValidForFeature, FEATURE_NAMES } from "../../utils";
import { AccountsHeadStakingBalance } from "./AccountsHeadComponents/AccountsHeadStakingBalance";
import { Switch } from "antd";
import { UserContext } from "../../hooks";

const AccountsHeadRaw = ({ accounts }) => {
  const [user] = useContext(UserContext);
  const [axcToUsdRate, setAxcToUsdRate] = useState(1);
  const [totalInterestEarned, setTotalInterestEarned] = useState(0);
  const [accountOverview, setAccountOverview] = useState({});
  const [lastUpdate, setLastUpdate] = useState(0);
  const [enableSpin, setEnableSpin] = useState(false);
  const [switchOn, setSwitchOn] = useState(false);

  useEffect(() => {
    fetchConversionRate();
    fetchAccountOverview();
    const id = autoUpdate();
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    calculateTotalInterestEarned();
  }, [accounts, accountOverview]);

  useEffect(() => {
    if (enableSpin) setTimeout(() => setEnableSpin(false), 1000);
  }, [enableSpin]);

  const onChange = (checked) => {
    setSwitchOn(checked);
  };

  const fetchConversionRate = async () => {
    setEnableSpin(true);
    const data = await getConversionRateApi("AXC", "USD");
    setAxcToUsdRate(data.conversionRate);
    setLastUpdate(0);
  };

  const fetchAccountOverview = async () => {
    const data = await getAccountOverview();
    setAccountOverview(data);
  };

  const autoUpdate = () => {
    const id = setInterval(() => {
      setLastUpdate((prevLastUpdate) => {
        if (prevLastUpdate >= 4) {
          fetchConversionRate();
          return 0;
        } else {
          return prevLastUpdate + 1;
        }
      });
    }, 60000);
    return id;
  };

  const calculateTotalInterestEarned = () => {
    const stakingTotalRewards = Big(accountOverview?.stakingTotalRewards?.AXC?.amount || 0);
    const accountTotalRewards = Big(accountOverview?.accountTotalRewards?.AXC?.amount || 0);
    const total = stakingTotalRewards.plus(accountTotalRewards);
    setTotalInterestEarned(total);
  };

  const getStakingAmountPlusRewards = () => {
    const balance = accountOverview?.stakingTotalBalance?.AXC?.amount || 0;
    return Big(balance).toFixed(5);
  };

  return (
    <div className="account-head-container">
      <div className="row account-head-text">Account Overview</div>
      {/* <div className="row align-items-center" style={{ justifyContent: "flex-end", marginRight: 20 }}>
        <p className="padding-none margin-none" style={{ marginRight: 10, color: "white", fontWeight: 500 }}>
          {CURRENCY.AXC.code}
        </p>
        <Switch style={{ backgroundColor: "#35B994" }} onChange={onChange} />
        <p className="padding-none margin-none" style={{ marginLeft: 10, color: "white", fontWeight: 500 }}>
          {CURRENCY.USD.code}
        </p>
      </div> */}
      <div className="account-head-cards-grid">
        <AccountsHeadBalance
          accounts={accounts}
          axcToUsdRate={axcToUsdRate}
          switchOn={switchOn}
          stakingBalance={accountOverview?.stakingTotalBalance?.AXC?.amount || 0}
        />
        {/* <AccountsHeadExchangeRate
          axcToUsdRate={axcToUsdRate}
          lastUpdate={lastUpdate}
          enableSpin={enableSpin}
          onClick={fetchConversionRate}
        /> */}
        {/* <AccountsHeadInterestRate /> */}
        <AccountsHeadInterestRateEarned
          totalInterestEarned={totalInterestEarned}
          axcToUsdRate={axcToUsdRate}
          switchOn={switchOn}
        />
        <AccountsHeadStakingBalance
          stakingBalance={getStakingAmountPlusRewards()}
          axcToUsdRate={axcToUsdRate}
          switchOn={switchOn}
        />
        {isValidForFeature({ user, featureName: FEATURE_NAMES.GLOBAL_SPENDABLE_AMOUNT }) && (
          <AccountsHeadUserSpendable axcToUsdRate={axcToUsdRate} switchOn={switchOn} />
        )}
      </div>
    </div>
  );
};

export const AccountsHead = boundError(AccountsHeadRaw);
