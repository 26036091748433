import React, { useEffect, useState, useContext } from "react";
import { CreditCardOutlined } from "@ant-design/icons";
import { boundError, showError, isUat } from "../utils";
import { Button, Spin } from "antd";
import CardImage from "../resources/images/card.png";
import { useNavigate } from "react-router-dom";
import { doGetCardList } from "../apis/card";
import { doGetCardTypes } from "../apis";
import UserServices from "../services/userServices";
import { card_types } from "../constants/cardType";
import { formatAmount } from "../utils";
import { isMobile } from "react-device-detect";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { CARD_STATUS } from "../constants";
import { isValidForFeature, FEATURE_NAMES } from "../utils";
import { UserContext } from "../hooks";

const CardsRaw = () => {
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  const [cardList, setCardList] = useState([]);
  const [loading, setLoading] = useState(true);
  const userServices = UserServices.getInstance();
  const [checkingRegion, setCheckingRegion] = useState(false);

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    fetchCards();
  }, []);

  const fetchCards = async () => {
    setLoading(true);
    try {
      const cardList = await doGetCardList();
      setCardList(cardList?.data || []);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Spin style={{ marginTop: 50 }} />;

  const onOrderACardPress = async () => {
    if (isValidForFeature({ user, featureName: FEATURE_NAMES.CARD })) {
      try {
        setCheckingRegion(true);
        let user = await userServices.getUserProfile();
        const availableCardTypes = await doGetCardTypes({ countryCode: user?.addressCountry, type: card_types.NORMAL });

        if (availableCardTypes?.length > 0) {
          navigate("/cards/apply");
        } else {
          showError("Card service is not available in your country at the moment.");
        }
      } catch (err) {
        showError(err);
      } finally {
        setCheckingRegion(false);
      }
    } else {
      showError("This service is temporarily unavailable, please try again later.");
    }
  };

  const renderNoCard = () => {
    return (
      <Spin spinning={checkingRegion}>
        <div className="no-card-container">
          <img src={CardImage} alt="" style={{ maxHeight: "250px" }} />
          <div style={{ margin: "30px 0px" }}>
            {/* <p style={{ fontSize: 16, fontWeight: "500", margin: 0 }}>You don't have any card yet</p> */}
            <Button
              type="primary"
              style={{
                marginTop: 10,
                borderRadius: 5,
                border: "none",
                backgroundColor: isValidForFeature({ user, featureName: FEATURE_NAMES.CARD }) ? "#3B82F6" : "lightgrey"
              }}
              onClick={() => {
                onOrderACardPress();
              }}
            >
              Order Card
            </Button>
          </div>
        </div>
      </Spin>
    );
  };

  const getCardSize = () => {
    let cardWidth = 45;
    if (width < 800) {
      cardWidth = 85;
    } else if (width > 1400) {
      cardWidth = 35;
    }
    const cardHeight = (cardWidth / 3) * 2;
    const cardNumberSize = cardHeight / 7;
    const cardNameSize = cardHeight / 8;

    const cardNumberPaddingBottom = cardHeight / 6;
    const cardNamePaddingBottom = cardHeight / 9;

    const paddingLeft = cardWidth / 15;
    const paddingRight = cardWidth / 15;

    const balanceSize = cardHeight / 10;
    const currencySize = cardHeight / 18;

    const statusSize = cardHeight / 18;
    const statusPaddingHor = cardHeight / 14;
    const statusHeight = cardHeight / 10;
    const statusBorderRadius = statusHeight / 2;
    const statusMargin = cardHeight / 30;

    return {
      cardWidth: `${cardWidth}vw`,
      cardHeight: `${cardHeight}vw`,
      cardNumberSize: `${cardNumberSize}vw`,
      cardNameSize: `${cardNameSize}vw`,
      cardNumberPaddingBottom: `${cardNumberPaddingBottom}vw`,
      cardNamePaddingBottom: `${cardNamePaddingBottom}vw`,
      paddingLeft: `${paddingLeft}vw`,
      paddingRight: `${paddingRight}vw`,
      balanceSize: `${balanceSize}vw`,
      currencySize: `${currencySize}vw`,
      statusSize: `${statusSize}vw`,
      statusPaddingHor: `${statusPaddingHor}vw`,
      statusHeight: `${statusHeight}vw`,
      statusBorderRadius: `${statusBorderRadius}vw`,
      statusMargin: `${statusMargin}vw`
    };
  };

  const getStatusColor = (status) => {
    const colors = {
      ACTIVE: "#35b994",
      SUSPENDED: "red",
      INACTIVE: "#efd915",
      LOST: "red",
      STOLEN: "red",
      EXPIRED: "#cccccc",
      UNKNOWN: "#cccccc"
    };

    return colors[status] ?? colors.UNKNOWN;
  };

  const activateCards = () => {
    return cardList?.filter((c) => c.status === CARD_STATUS.ACTIVE) ?? [];
  };

  const renderCardList = () => {
    return (
      <>
        <Spin spinning={loading}>
          {isUat() && (
            <div style={{ display: "flex" }}>
              <Button
                type="primary"
                onClick={() => {
                  setCardList([]);
                }}
                style={{ backgroundColor: "#02466F", borderRadius: "5px" }}
              >
                Switch to no card view
              </Button>
            </div>
          )}

          {activateCards().length === 0 && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                onClick={() => {
                  setCardList([]);
                }}
                style={{ backgroundColor: "#007CBD", borderRadius: "5px", height: 60, width: 160, fontSize: 16 }}
              >
                Order new card
              </Button>
            </div>
          )}

          {cardList.map((card) => {
            return (
              <div
                key={card?.id}
                className="card-list-container card-button-container"
                onClick={() => {
                  navigate(`/cards/${card?.id}`, { state: { card } });
                }}
                style={{
                  position: "relative",
                  width: getCardSize().cardWidth,
                  height: getCardSize().cardHeight,
                  borderRadius: getCardSize().statusBorderRadius
                }}
              >
                <div style={{ position: "absolute", bottom: "4.6vw", left: getCardSize().paddingLeft }}>
                  <p className="margin-none" style={{ fontWeight: "500", fontSize: "2.6vw", color: "white" }}>
                    {card.cardNumber}
                  </p>
                </div>

                <div style={{ position: "absolute", bottom: "1.6vw", left: getCardSize().paddingLeft }}>
                  <p className="margin-none" style={{ fontWeight: "500", fontSize: "2.0vw", color: "white" }}>
                    {card.firstName} {card.lastName}
                  </p>
                </div>

                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    top: 0,
                    right: getCardSize().paddingRight,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    display: "flex"
                  }}
                >
                  <p
                    className="margin-none"
                    style={{ fontWeight: "500", fontSize: getCardSize().balanceSize, color: "white" }}
                  >
                    {card?.balance ? formatAmount(card?.balance ?? 0, card.currency) : "N/A"}{" "}
                    <span style={{ fontSize: getCardSize().currencySize }}>{card.currency}</span>
                  </p>
                </div>

                <div
                  style={{
                    backgroundColor: getStatusColor(card.status),
                    color: "white",
                    borderRadius: getCardSize().statusBorderRadius,
                    paddingLeft: getCardSize().statusPaddingHor,
                    paddingRight: getCardSize().statusPaddingHor,
                    position: "absolute",
                    top: getCardSize().statusMargin,
                    right: getCardSize().statusMargin,
                    height: getCardSize().statusHeight,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                  }}
                >
                  <p style={{ margin: 0, fontWeight: "normal", fontSize: getCardSize().statusSize }}>{card.status}</p>
                </div>
              </div>
            );
          })}
        </Spin>
      </>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div className="row page-title">Cards</div>
      {cardList.length > 0 ? renderCardList() : renderNoCard()}
      <div style={{ height: 80 }} />
    </div>
  );
};

export const Cards = boundError(CardsRaw);
