import React from "react";
import { Layout } from "antd";
import { boundError } from "../../utils";
import { LayoutSider } from "./LayoutSider";
import { BottomNavBar } from "./BottomNavBar";
import { Header } from "./Header";
import { NotificationBar } from "./NotificationBar";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const { Content } = Layout;

const MainLayoutRaw = () => {
  const isAuthenticated = useSelector((state) => state.auth.idToken);
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <Layout>
      <LayoutSider />
      <Layout className="site-layout">
        <NotificationBar />
        <Header />
        <Content style={{ margin: "0", overflow: "initial", minHeight: "92vh", backgroundColor: "#E7F3FE" }}>
          <div className="site-layout-background" style={{ padding: 0, textAlign: "center" }}>
            <Outlet />
          </div>
        </Content>
      </Layout>
      <BottomNavBar />
    </Layout>
  );
};

export const MainLayout = boundError(MainLayoutRaw);
