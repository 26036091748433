import React from "react";
import { CopyButton } from "..";

export const KeyValue4 = ({ keyName, value, style, valueTextStyle, keyTextStyle, copyValue = null }) => {
  return (
    <div style={{ marginTop: 10, ...style }}>
      <p className="margin-none" style={{ fontSize: 12, fontWeight: "500", color: "#737373", ...keyTextStyle }}>
        {keyName}
      </p>
      <p className="margin-none" style={{ ...valueTextStyle }}>
        {value}
        {copyValue && <CopyButton text={copyValue} />}
      </p>
    </div>
  );
};
