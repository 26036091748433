import React from "react";
import { boundError } from "../../utils";

const ProfilePageHeadRaw = ({ title, description }) => {
  return (
    <div style={{ borderBottom: "1px solid #D7D7D9", paddingBottom: 10 }}>
      <div className="row" style={{ fontSize: 24, fontWeight: 500 }}>
        {title}
      </div>
      {description && (
        <div className="row" style={{ color: "#9A9A9A" }}>
          {description}
        </div>
      )}
    </div>
  );
};

export const ProfilePageHead = boundError(ProfilePageHeadRaw);
