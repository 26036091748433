import React, { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { boundError } from "../utils";
import { UserRegistrationStatusContext } from "../hooks";
import UserServices from "../services/userServices";
import { showError } from "../utils/messages";

const HomeRaw = ({ checkMaintenanceMode }) => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.idToken);
  const userServices = UserServices.getInstance();
  const [registrationStatus, setRegistrationStatus] = useContext(UserRegistrationStatusContext);

  useEffect(async () => {
    // redirect to login if user is not authenticated
    if (!isAuthenticated) {
      return navigate("/login");
    }

    // get user onboarding states
    const { registrationCompleted, userType, navigateTo } = await userServices
      .checkUserRegistrationStatus()
      .catch((e) => {
        if (e?.response?.status == 503) {
          return checkMaintenanceMode(true);
        }
        showError(e);
        userServices.appSignOut();
      });
    setRegistrationStatus({ registrationCompleted, userType, navigateTo });
    if (registrationCompleted) {
      userServices.getUserProfile();
    }
    return navigate(navigateTo);
  }, []);

  return null;
};

export const Home = boundError(HomeRaw);
