import React, { useState, useRef, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Divider, Form, Input, Row, Space, Upload, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  updateTaxId,
  uploadProofOfTaxId,
  uploadProofOfTaxReturn,
  downloadProofOfTaxId,
  downloadProofOfTaxReturn,
  getTaxIdExemptCountriesApi
} from "../../apis";
import { boundError, showError } from "../../utils";
import { CountrySelect } from "../CountrySelect";
const { Title } = Typography;
import { BackBtn } from "../SharedComponents/Button/BackBtn";

const TaxIdentificationFormRaw = ({ userRegistrationStatus, reloadUserRegistrationStatus }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const taxIdStatus = userRegistrationStatus?.userRegistrationData?.taxIdStatus;
  const navigate = useNavigate();

  const [taxIdExemptCountries, setTaxIdExemptCountries] = useState(null);
  const [isTaxIdExemptCountry, setIsTaxIdExemptCountry] = useState(false);

  const [countrySelected, setCountrySelected] = useState(null);
  const [init, setInit] = useState(false);

  const isMounted = useRef(true);

  useEffect(() => {
    const fetchTaxIdExemptCountries = async () => {
      try {
        const exemptCountries = await getTaxIdExemptCountriesApi();

        setTaxIdExemptCountries(exemptCountries);

        // in case of saved registration info
        setCountrySelected(userRegistrationStatus?.userRegistrationData?.taxIdCountryCode);

        setIsTaxIdExemptCountry(
          exemptCountries.includes(userRegistrationStatus?.userRegistrationData?.taxIdCountryCode) ? true : false
        );

        setInit(true);
      } catch (err) {
        showError(err);
      }
    };

    fetchTaxIdExemptCountries();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const onFinish = async (values) => {
    setError(null);
    setLoading(true);
    let { taxId, taxIdCountryCode } = values;

    if (isTaxIdExemptCountry) {
      taxId = "N/A";
    }

    try {
      await updateTaxId(taxId, taxIdCountryCode, true);
      reloadUserRegistrationStatus();
    } catch (err) {
      setError(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const onSaveAndExit = async () => {
    try {
      let { taxId, taxIdCountryCode } = form.getFieldsValue(["taxId", "taxIdCountryCode"]);

      await updateTaxId(taxId, taxIdCountryCode);
      goBack();
    } catch (err) {
      console.error(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.slice(-1);
    }
    return e?.fileList?.slice(-1);
  };

  const createCustomUploadRequest =
    (uploader) =>
    async ({ file, onError, onSuccess }) => {
      try {
        await uploader(file);
        onSuccess();
      } catch (err) {
        onError(err);
      }
    };

  const urd = userRegistrationStatus?.userRegistrationData;
  const initialValues = {
    taxId: urd?.taxId === "N/A" ? "" : urd?.taxId,
    taxIdCountryCode: urd?.taxIdCountryCode,
    proofOfTaxId: urd?.proofOfTaxIdFile
      ? [
          {
            uid: urd.proofOfTaxIdFile.Key,
            name: `Document uploaded at ${new Date(urd.proofOfTaxIdFile.createdAt).toLocaleString()}`,
            status: "done"
          }
        ]
      : [],
    proofOfTaxReturn: urd?.proofOfTaxReturnFile
      ? [
          {
            uid: urd.proofOfTaxReturnFile.Key,
            name: `Document uploaded at ${new Date(urd.proofOfTaxReturnFile.createdAt).toLocaleString()}`,
            status: "done"
          }
        ]
      : []
  };

  const createCustomFileListItemRenderer = (onClickHandler) => (originalNode) => {
    return (
      <Button type="link" onClick={onClickHandler} style={{ padding: 0 }}>
        {originalNode}
      </Button>
    );
  };

  const shouldDisableForm =
    taxIdStatus === "SUBMITTED" ||
    taxIdStatus === "COMPLETED" ||
    taxIdStatus === "NOT_REQUIRED_SUBMITTED" ||
    taxIdStatus === "NOT_REQUIRED";

  const goBack = () => {
    const backPath = location.pathname.replace("/tax-id", "");
    navigate(backPath);
  };

  const handleCountrySelect = (selectedCountryCode) => {
    setCountrySelected(selectedCountryCode);

    if (taxIdExemptCountries.includes(selectedCountryCode)) {
      setIsTaxIdExemptCountry(true);
    } else {
      setIsTaxIdExemptCountry(false);
    }
  };

  if (!init) {
    return null;
  }

  return (
    <div style={{ textAlign: "left", padding: 15, paddingBottom: 100 }}>
      <Row justify="center">
        <Col sm={24} md={18}>
          <BackBtn onClick={goBack} />
          <Title level={3}>Verify Personal Tax Identification</Title>
          {taxIdStatus === "SUBMITTED" && (
            <Alert message="Your tax identification has been submitted for review" type="info" />
          )}
          {taxIdStatus === "COMPLETED" && <Alert message="Your tax identification has been completed" type="info" />}
          {taxIdStatus === "REJECTED" && <Alert message="Your tax identification has been rejected" type="info" />}
          {error && <Alert message={error.response?.data?.message || error.message} type="error" />}
          <Divider />
          <Form
            form={form}
            layout="vertical"
            name="tax-identification"
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <Form.Item
              label="Tax country code"
              name="taxIdCountryCode"
              rules={[{ required: true, message: "Tax country code is required!" }]}
            >
              <CountrySelect onSelect={handleCountrySelect} disabled={shouldDisableForm} />
            </Form.Item>
            {countrySelected && (
              <>
                <Form.Item
                  label="Tax identification number"
                  name="taxId"
                  style={{ display: (urd?.taxId || countrySelected) && !isTaxIdExemptCountry ? "block" : "none" }}
                  rules={[
                    { required: isTaxIdExemptCountry ? false : true, message: "Tax identification number is required!" }
                  ]}
                  extra="&nbsp;&nbsp;&nbsp;Note: If tax ID is not available in your region, please enter any of the following: National Insurance Number, National Identity Number or Voters ID."
                >
                  <Input type="text" disabled={shouldDisableForm} />
                </Form.Item>
                <Form.Item
                  label="Proof of Tax ID"
                  name="proofOfTaxId"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  style={{ display: (urd?.taxId || countrySelected) && !isTaxIdExemptCountry ? "block" : "none" }}
                  rules={[{ required: isTaxIdExemptCountry ? false : true, message: "Proof of Tax ID is required!" }]}
                >
                  <Upload
                    customRequest={createCustomUploadRequest(uploadProofOfTaxId)}
                    multiple={false}
                    showUploadList={{ showRemoveIcon: false }}
                    disabled={shouldDisableForm}
                    accept=".png,.jpg,jpeg,.pdf"
                    itemRender={createCustomFileListItemRenderer(downloadProofOfTaxId)}
                  >
                    <Button icon={<UploadOutlined />} disabled={shouldDisableForm}>
                      Upload proof of tax ID file
                    </Button>
                  </Upload>
                </Form.Item>
              </>
            )}
            {/* <Form.Item
              label="Proof of Tax return"
              name="proofOfTaxReturn"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required: true, message: "Proof of Tax return is required!" }]}
            >
              <Upload
                customRequest={createCustomUploadRequest(uploadProofOfTaxReturn)}
                multiple={false}
                showUploadList={{ showRemoveIcon: false }}
                disabled={shouldDisableForm}
                accept=".png,.jpg,jpeg,.pdf"
                itemRender={createCustomFileListItemRenderer(downloadProofOfTaxReturn)}
              >
                <Button icon={<UploadOutlined />} disabled={shouldDisableForm}>
                  Upload proof of tax return file
                </Button>
              </Upload>
            </Form.Item> */}
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" disabled={loading || shouldDisableForm}>
                  Submit
                </Button>
                {shouldDisableForm ? (
                  <Button type="primary" onClick={() => goBack()}>
                    Exit
                  </Button>
                ) : isTaxIdExemptCountry ? null : (
                  <Button disabled={loading} onClick={onSaveAndExit}>
                    Save and exit
                  </Button>
                )}
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export const TaxIdentificationForm = boundError(TaxIdentificationFormRaw);
