import React, { useState, useEffect } from "react";
import Emitter, { EmitterEvents } from "../utils/eventEmitter";
import { sleep } from "../utils";

export const AccountsContext = React.createContext("Accounts");

const AccountsContextProvider = ({ children }) => {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    Emitter.on(EmitterEvents.SET_ACCOUNTS, ({ data, cb }) => {
      setAccounts(data);
      sleep(200).then(() => {
        cb();
      });
    });

    Emitter.on(EmitterEvents.GET_ACCOUNTS, (cb) => {
      cb(accounts);
    });

    return () => {
      Emitter.off(EmitterEvents.SET_ACCOUNTS);
      Emitter.off(EmitterEvents.GET_ACCOUNTS);
    };
  }, [accounts]);

  return <AccountsContext.Provider value={[accounts, setAccounts]}>{children}</AccountsContext.Provider>;
};

export default AccountsContextProvider;
