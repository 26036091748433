import React from "react";
import { boundError, formatAmount } from "../../utils";
import usFlag from "../../resources/images/flags/flag_us_circle.png";
import walletFlag from "../../resources/images/flags/axia_coin_wallet.png";
import vaultFlag from "../../resources/images/flags/axia_coin_vault.png";
import usaxIcon from "../../resources/icons/currencies/USAX.png";
import { WalletOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { CURRENCY } from "../../constants/currency";
import { ACCOUNT_STATUS } from "../../constants/accountStatus";
import { Tag } from "antd";

const AccountsMidCardRaw = ({ account }) => {
  const isActive = account.status === ACCOUNT_STATUS.ACTIVE;
  const navigate = useNavigate();
  let mainColor, image;
  let icon = <></>;
  switch (account.currency) {
    case "USD":
      mainColor = "#A3A3A3";
      image = usFlag;
      break;
    case "USAX":
      mainColor = "#35B994";
      image = usaxIcon;
      icon = <WalletOutlined className="display-mobile-unset" style={{ color: mainColor, fontSize: 30 }} />;
      break;
    // add this for future develop
    case "axiaVault":
      mainColor = "#077CBD";
      image = vaultFlag;
      break;
    default:
      // AXC account
      mainColor = "var(--axia-blue)";
      image = walletFlag;
      icon = <WalletOutlined className="display-mobile-unset" style={{ color: mainColor, fontSize: 30 }} />;
  }

  return (
    <div
      className="account-mid-axia-wallet-card"
      style={isActive ? {} : { backgroundColor: "lightgray" }}
      onClick={() => {
        navigate(`/accounts/${account.id}`);
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "5px 0" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
          <img src={image} alt="" style={{ height: 30, width: 30 }} />
          <p className="account-mid-wallet-card-name" style={{ color: mainColor }}>
            {account.typePublicName}
          </p>
        </div>
        {icon}
      </div>

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <p style={{ color: mainColor, fontSize: 12, padding: 0, margin: 0 }}>{account.publicId}</p>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <p className="account-mid-wallet-card-amount" style={{ color: mainColor }}>
          {formatAmount(account.amount, account.currency, account.currency === CURRENCY.USD.code ? "$" : "")}
          <span style={{ fontSize: 12, fontWeight: "600", marginLeft: "0.5rem" }}>{account.currency}</span>
        </p>
        {isActive ? null : (
          <p className="margin-none">
            <Tag color="red" style={{ borderRadius: "20px" }}>
              Action Required
            </Tag>
          </p>
        )}
      </div>
    </div>
  );
};

export const AccountsMidCard = boundError(AccountsMidCardRaw);
