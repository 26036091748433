import { bankApi } from "./authenticatedAxios";
import { responseDataHandler } from "./responseHandler";
import { concatApiQuery } from "../utils/strings";

export const doIssueCard = ({
  deliveryAddress1,
  deliveryAddress2,
  deliveryCity,
  deliveryCountryState,
  deliveryCountryCode,
  deliveryPostCode,
  convertToPlastic,
  fromAccountId,
  fromStakingId,
  cardTypeCode,
  useAlternativeName,
  addressCity,
  addressCountry,
  addressLine1,
  addressLine2,
  addressPostalCode,
  addressState,
  phoneNumber,
  delivery
}) =>
  bankApi
    .post(`/card/issueCard`, {
      deliveryAddress1,
      deliveryAddress2,
      deliveryCity,
      deliveryCountryState,
      deliveryCountryCode,
      deliveryPostCode,
      convertToPlastic,
      fromAccountId,
      fromStakingId,
      cardTypeCode,
      useAlternativeName,
      addressCity,
      addressCountry,
      addressLine1,
      addressLine2,
      addressPostalCode,
      addressState,
      phoneNumber,
      delivery
    })
    .then(({ data }) => responseDataHandler(data));

export const doGetCardList = () => bankApi.get(`/card`).then(({ data }) => responseDataHandler(data));

export const doGetCardTransHistory = (cardId, { year, month, filter = 1 }) =>
  bankApi
    .get(concatApiQuery(`/card/${cardId}/transactions`, { year, month, filter }))
    .then(({ data }) => responseDataHandler(data));

export const doActivateCard = (cardId) =>
  bankApi
    .post(`/card/activate`, {
      cardId
    })
    .then(({ data }) => responseDataHandler(data));

export const doSuspendCard = (cardId) =>
  bankApi
    .post(`/card/suspend`, {
      cardId
    })
    .then(({ data }) => responseDataHandler(data));

export const doLoadCardFromAccount = ({ cardId, fromAccountId, fromStakingId, amount }) =>
  bankApi
    .post(`/card/load`, {
      cardId,
      amount,
      fromAccountId,
      fromStakingId
    })
    .then(({ data }) => responseDataHandler(data));

export const doGetCardInfoByCardId = (cardId) =>
  bankApi.get(`/card/${cardId}`).then(({ data }) => responseDataHandler(data));

export const doGetAvailableCurrencies = (countryCode) =>
  bankApi.get("/card/currencies", { countryCode }).then(({ data }) => responseDataHandler(data));

export const doGetCardTypes = ({ countryCode, type }, { dataOnly = true, offset, limit, countTotal } = {}) =>
  bankApi
    .get(concatApiQuery(`/card/cardTypes`, { countryCode, type, dataOnly, offset, limit, countTotal }))
    .then(({ data }) => responseDataHandler(data));

export const doGetCardTypeByCode = ({ cardTypeCode }) =>
  bankApi.get(`/card/cardTypes/${cardTypeCode}`).then(({ data }) => responseDataHandler(data));

export const doGetDefaultCardProvider = () =>
  bankApi.get(`/card/cardProvider`).then(({ data }) => responseDataHandler(data));

export const doGetCardSecurityData = (cardId) =>
  bankApi.get(`/card/${cardId}/secureData`).then(({ data }) => responseDataHandler(data));

export const doGetPresignedAgreementUrl = (region) =>
  bankApi.get(`/card/getPresignedAgreementUrl/${region}`).then(({ data }) => responseDataHandler(data));

export const doConvertToPhysicalCard = ({ cardId, fromAccountId, fromStakingId }) =>
  bankApi
    .post(`/card/${cardId}/convertToPhysical`, { fromAccountId, fromStakingId })
    .then(({ data }) => responseDataHandler(data));

export const doActivatePhysicalCardApi = ({ cardId, cvv }) =>
  bankApi.post(`/card/activatePhysical`, { cardId, cvv }).then(({ data }) => responseDataHandler(data));

export const doGetCardPinNumberApi = ({ cardId }) =>
  bankApi.get(`/card/${cardId}/pin`).then(({ data }) => responseDataHandler(data));

export const doGetCardShipmentTrackingNoApi = ({ cardId }) =>
  bankApi.get(`/card/${cardId}/shipmentTrackingNo`).then(({ data }) => responseDataHandler(data));
