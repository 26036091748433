import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Input, Spin, Upload, Button, Select } from "antd";
const { Option } = Select;
import { UploadOutlined } from "@ant-design/icons";
import { boundError, showError, showSuccess } from "../utils";
import { ProfilePage, ProfilePageHead } from "../components";
import { SimpleButton } from "../components";
import { getUserPendingDocumentById } from "../apis";
import { TaxIdForm, CardKYCForm, BeneficiaryAccountForm } from "../components/Document";
import { BackBtn } from "../components";
import { ConsoleView } from "react-device-detect";
import { ChangeOfIdAddress } from "../components/Document/ChangeOfIdAddress";

const UploadDocumentRaw = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [documentType, setDocumentType] = useState("");
  const [documentRecord, setDocumentRecord] = useState();

  const typeOption = [
    "PROOF_OF_TAX_ID",
    // "PROOF_OF_TAX_RETURN",
    // "BUSINESS_PROOF_OF_INCORPORATE_CERTIFICATE",
    // "BUSINESS_PROOF_OF_ADDRESS",
    // "OTHER",
    "CARD_KYC",
    "BENEFICIARY_ACCOUNT",
    "CHANGE_OF_ID_ADDRESS"
  ];

  useEffect(() => {
    if (id && id !== "") {
      fetchDocumentRecords(id);
    }
    setLoading(false);
  }, [id]);

  const fetchDocumentRecords = async () => {
    const data = await getUserPendingDocumentById(id);
    const record = data[0];
    setDocumentType(record?.documentType);
    // console.log(record);
    setDocumentRecord(record);
  };

  const formatDocumentType = (documentType) => {
    switch (documentType) {
      case "PROOF_OF_TAX_ID":
        return "Proof of Tax ID";
      case "PROOF_OF_TAX_RETURN":
        return "Proof of Tax Return";
      case "BUSINESS_PROOF_OF_INCORPORATE_CERTIFICATE":
        return "Proof of Business Incorporate Certificate";
      case "BUSINESS_PROOF_OF_ADDRESS":
        return `Proof of Business Address`;
      case "CARD_KYC":
        return `Debit Card Identity Verification - Limit Increase Application`;
      case "BENEFICIARY_ACCOUNT":
        return `Beneficiary Account Application`;
      case "CHANGE_OF_ID_ADDRESS":
        return `Change of Address/ID`;
      default:
        return "Other Document";
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 20,
        padding: 30,
        width: "90%",
        maxWidth: 1000,
        margin: "auto",
        marginTop: 60,
        marginBottom: 60,
        alignSelf: "center"
      }}
    >
      <Spin spinning={loading}>
        <BackBtn onClick={() => navigate("/user-document")} />
        <ProfilePageHead title="Upload Document" description="Upload your documents for verification" />
        <div style={{ display: "flex", flexDirection: "column", marginTop: 30, gap: 30 }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <p className="margin-none" style={{ fontWeight: "500", fontSize: 14, marginBottom: 5 }}>
              Select Document Type
            </p>
            <Select
              size="large"
              value={documentType}
              placeholder="Please select the type of document"
              style={{
                display: "flex",
                width: "100%",
                maxWidth: 400,
                border: "none",
                textAlign: "left"
              }}
              onChange={(e) => setDocumentType(e)}
            >
              {typeOption.map((option, index) => {
                return (
                  <Option value={option} key={index}>
                    {formatDocumentType(option)}
                  </Option>
                );
              })}
            </Select>
          </div>
          {documentType === "PROOF_OF_TAX_ID" && <TaxIdForm rawData={documentRecord} />}
          {documentType === "CARD_KYC" && <CardKYCForm rawData={documentRecord} />}
          {documentType === "BENEFICIARY_ACCOUNT" && <BeneficiaryAccountForm rawData={documentRecord} />}
          {documentType === "CHANGE_OF_ID_ADDRESS" && <ChangeOfIdAddress rawData={documentRecord} />}
        </div>
      </Spin>
    </div>
  );
};

export const UploadDocument = boundError(UploadDocumentRaw);
