import { Form, Input, Modal, Spin } from "antd";
import parse from "html-react-parser";
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { agreeUserConsent, awsForgotPassword, awsSignOut, getTermsConditions, resendSignUpCode, signIn } from "../apis";
import { AuthWrapper, ButtonPrimary } from "../components";
import { ConsentModal } from "../components/SharedComponents/consentModal";
import { awsErrorMessages, getCustomErrorMessage } from "../constants";
import { USER_CONSENT } from "../constants/userConsent";
import { USER_STATUS } from "../constants/userStatus";
import { UserRegistrationStatusContext } from "../hooks";
import { loginSuccess, mfaLoginRequest } from "../redux/actions";
import TokenSplitPDF from "../resources/consents/token_split/AXC_TOKEN_MIGRATION_TERMS_V2_202209.pdf";
import NotificationServices from "../services/notificationService";
import UserServices from "../services/userServices";
import { boundError, FEATURE_NAMES, isLocal, isValidForFeature, showError, showWarning } from "../utils";

const LoginRaw = ({ mfaLoginRequest, loginSuccess }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const [consentFile, setConsentFile] = useState();
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [showAgreePopup, setShowAgreePopup] = useState(false);
  const [showUsaxPopup, setShowUsaxPopup] = useState(false);
  const [showLoginNotice, setShowLoginNotice] = useState(true);
  const [formValues, setFormValues] = useState();
  const userServices = UserServices.getInstance();
  const notificationServices = NotificationServices.getInstance();
  const [owedAmount, setOwedAmount] = useState(0);
  const [registrationStatus, setRegistrationStatus] = useContext(UserRegistrationStatusContext);
  const isMounted = useRef(true);
  const [showTaxIdPopup, setShowTaxIdPopup] = useState(false);
  const [showUserSuspendedPopup, setShowUserSuspendedPopup] = useState(false);

  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [showTermsAndConditionsPopups, setShowTermsAndConditionsPopups] = useState(false);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleLoginError = async (err, values = {}) => {
    let errMessage = err?.message || err;
    if (typeof errMessage !== "string") {
      errMessage = JSON.stringify(errMessage);
    }
    const customError = getCustomErrorMessage(errMessage);
    if (customError === awsErrorMessages.UserNotConfirmedException && values.email) {
      try {
        await resendSignUpCode(values.email);
        navigate("/sign-up-confirm", { state: { email: values.email, password: values.password } });
      } catch (err) {
        console.log(err);
        setLoginError(getCustomErrorMessage(errMessage));
      }
    } else if (customError === awsErrorMessages.PasswordResetRequiredException) {
      showWarning(customError);
      await awsForgotPassword(values.email);
      navigate("/reset-password", { state: { email: values.email } });
    } else {
      setLoginError(getCustomErrorMessage(errMessage));
    }
  };

  const agreeConsent = async ({ consentFormName }) => {
    try {
      await agreeUserConsent({ consentFormName });
      setShowConsentModal(false);
      setConsentFile();
      await login(formValues);
    } catch (e) {
      showError(e);
      userServices.appSignOut();
      setShowConsentModal(false);
      setConsentFile();
    }
  };

  const login = async (values) => {
    try {
      setLoading(true);
      setLoginError("");
      setFormValues(values);
      const { email, password } = values;
      localStorage.setItem("userFirstLogin", JSON.stringify({ loginNotificationShown: false }));
      const user = await signIn(email, password);

      try {
        const userProfile = await userServices.getUserProfile();

        setProfile(userProfile);

        const termsConditions = await getTermsConditions();

        let showPopup = false;
        termsConditions.forEach((term) => {
          if (term.enabled) {
            if (!term?.valueJson?.header || !term?.valueJson?.body) {
              throw new Error("Failed to retrieve terms and conditions, please contact customer support.");
            }
            if (!userProfile?.consent[term.name] || !userProfile?.consent[term.name]?.agreed) {
              showPopup = true;
            }
          }
        });

        if (showPopup) {
          setTermsAndConditions(termsConditions);
          return setShowTermsAndConditionsPopups(true);
        }

        // token migration consent
        const isUserCreatedBefore = new Date(userProfile.createdAt) < new Date("2022-07-29T00:00:00");
        const isConsentEnabled =
          isValidForFeature({ user: userProfile, featureName: FEATURE_NAMES.TOKEN_MIGRATION_CONSENT }) === true;
        const isUserAgreed = userProfile?.consent?.AXIA_NETWORK_V2_TOKEN_MIGRATION?.agreed;

        if (isConsentEnabled && isUserCreatedBefore && !isUserAgreed) {
          return setShowAgreePopup(true);
        }

        // token migration consent
        if (
          isValidForFeature({ user: userProfile, featureName: FEATURE_NAMES.USAX_ACCOUNT }) &&
          !userProfile?.consent?.USAX_ACCOUNT?.agreed
        ) {
          return setShowUsaxPopup(true);
        }

        // mandatory tax id
        if (
          !showTaxIdPopup &&
          userProfile?.showTaxIdNotification &&
          isValidForFeature({ user: userProfile, featureName: FEATURE_NAMES.MANDATORY_TAX_ID }) === true
        ) {
          return setShowTaxIdPopup(true);
        } else {
          setShowTaxIdPopup(false);
        }
      } catch (err) {
        if (err?.response?.status == 503) {
          navigate("/");
        }

        if (Array.isArray(err) && err.includes(USER_STATUS.DEACTIVATED)) {
          await awsSignOut();
          throw new Error(
            "User log in failed because user account is closed. For further inquiries, please contact customer support."
          );
        }
      }

      if (isLocal()) {
        console.log(user?.signInUserSession?.idToken?.jwtToken);
      }

      if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
        mfaLoginRequest(user);
        navigate("/mfa-login");
      } else {
        loginSuccess(user);

        if (registrationStatus?.registrationCompleted) {
          userServices.getUserProfile();
        }

        try {
          await notificationServices.loginNotification();
        } catch (err) {
          handleLoginError(err);
        }
      }
    } catch (err) {
      handleLoginError(err, values);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  return (
    <AuthWrapper>
      <div className="auth-page__message">Welcome back</div>
      <p className="auth-page__title">Please Log In</p>
      <Spin spinning={loading}>
        <Form onFinish={login} layout="vertical" form={form} name="login-form" id="login-form">
          <Form.Item
            label="AXIA Account Email ID"
            name="email"
            normalize={(value) => value.replace(" ", "").toLowerCase()}
            rules={[
              {
                required: true,
                message: "The email field is required"
              }
            ]}
          >
            <Input
              allowClear
              id="email"
              autoFocus
              type="email"
              className="acb-primary-input"
              onChange={() => setLoginError("")}
            />
          </Form.Item>
          {loginError && (
            <div style={{ color: "red", marginTop: -24, marginBottom: 24, fontStyle: "italic" }}>{loginError}</div>
          )}
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "The password field is required"
              }
            ]}
          >
            <Input.Password allowClear id="password" className="acb-primary-input" />
          </Form.Item>
          <div className="clickable-text login-page__forgot-password" onClick={() => navigate("/forgot-password")}>
            Forgot your password?
          </div>
          <ButtonPrimary htmlType="submit" type="primary" size={"large"} block style={{ marginBottom: 20 }}>
            Log In
          </ButtonPrimary>
          <div className="row">
            <div className="grey-horizontal-line"></div>
            <span style={{ color: "rgb(115,115,115)", padding: "0 10px" }}>No account yet?</span>
            <div className="grey-horizontal-line"></div>
          </div>
          <div
            className="clickable-text"
            style={{ textAlign: "center", marginTop: 10 }}
            onClick={() => navigate("/sign-up")}
          >
            Open an account
          </div>
        </Form>
        {showTermsAndConditionsPopups &&
          termsAndConditions.map((tc) => {
            if (!tc.enabled || profile?.consent[tc.name]?.agreed) return null;

            return (
              <Modal
                visible={true}
                title={parse(tc.valueJson.header)}
                width={"50vh"}
                centered
                okText={"OK"}
                onOk={() => agreeConsent({ consentFormName: tc.name })}
                cancelButtonProps={{ style: { display: "none" } }}
                onCancel={() => {
                  userServices.appSignOut();
                }}
              >
                <div className="modal">
                  <div className="modalContent">{parse(tc.valueJson.body)}</div>
                </div>
              </Modal>
            );
          })}
        <ConsentModal
          visible={showConsentModal}
          footer={null}
          onCancel={() => {
            setShowConsentModal(false);
            setConsentFile();
          }}
          src={consentFile}
          // src={`https://docs.google.com/viewerng/viewer?url=${usrConsentUrl}&embedded=true`}
        />
        <Modal
          visible={showLoginNotice}
          title="Notice"
          width={"50vh"}
          centered
          closable={false}
          onOk={() => {
            setShowLoginNotice(false);
          }}
          cancelButtonProps={{ style: { display: "none" } }}
          bodyStyle={{ height: "50vh", overflowY: "auto" }}
        >
          <div className="modal">
            <div className="modalContent">
              {" "}April 16, 2024: <br></br>
              <br></br>Dear Coin Holder, <br></br>
              <br></br>ANF MergeCo Ltd (In Voluntary Liquidation) (the “Company”) <br></br>
              <br></br>Pursuant to resolutions of the sole shareholder passed on 9 April 2024, the Company was 
              put into voluntary liquidation and Messrs. Alexander Lawson and Christopher Kennedy of Alvarez & 
              Marsal Cayman Islands Limited, 2nd Floor Flagship Building, P.O. Box 2507, 142 Seafarers Way, 
              George Town, Grand Cayman KY1-1104, Cayman Islands were appointed as joint voluntary 
              liquidators (“JVLs”).{" "}
              <br></br>
              <br></br>Please be advised that, on 12 April 2024, the JVLs issued correspondence to Coin Holders 
              via email. If you are a holder of AXIA Coin and you have not received the JVLs’ correspondence, 
              please contact the JVLs by way of email to axia@alvarezandmarsal.com.{" "}
              <br></br>

              <br></br> March 1, 2024: <br></br>
              <br></br>Dear Coin Holder, <br></br>
              <br></br>We write with an update on the AXIA Project. <br></br>
              <br></br>We note that as a follow-on to our last communication, additional materials have been
              published on the Ontario Capital Markets Tribunal website related to the settlement between the
              OSC, Paul Ungerman, and Nicholas Agar, which can be found here:
              https://www.capitalmarketstribunal.ca/en/notices/notice-nicholas-agar-and-paul-ungerman-file-no-2024-1-0{" "}
              <br></br>
              <br></br>In mid-January, we communicated that the AXIA Project was in the final stages of entering
              into liquidation in the Cayman Islands.  However, due to continued issues related to the final AXIA
              affiliated company being merged into Cayman (which, despite working diligently to resolve, have
              plagued us for many months), the full merger has not yet been completed, which is the last step to
              beginning Cayman liquidation.  In response to this, Calderwood and A&M have decided to develop another
              plan so that the Cayman liquidation can begin as soon as possible (with the target being the next
              two to three weeks), without regard to that final affiliated company, and to eliminate further delays.{" "}
              <br></br>
              <br></br>We continue to work to clean up multiple aspects of the AXIA Project.  One item includes the
              gift and debit cards issued by AXIA Capital Bank.  Those cards are still valid and can be used by anyone
              with a balance on their card.  We encourage coin holders with gift and debit cards to spend down those
              balances over the next 8-12 weeks. <br></br>
              <br></br>Lastly, we have received many inquiries from coin holders asking about when the liquidator will
              reach out to them and when they can expect a distribution.  To address the first question, the liquidator
              will reach out to all coin holders, via their coin account emails of record, promptly upon appointment.
              To address the second question, the Cayman liquidation process is a legal, statutory process that moves
              along a proscribed timeline. What amounts are available for distribution and the timing of such distributions
              will not be known by anyone until the liquidation proceeds via the legal process and various steps are
              completed.  We want to emphasize that we are not withholding this information from coin holders, but rather
              it is simply not known (by anyone) at this time.  The Ontario Securities Commission has been informed for
              some time about the AXIA wind-up and liquidation proceeding; contacting them will not alter the timing of
              the liquidation or any distributions.  On appointment, we understand the liquidators will set up a dedicated
              inbox for queries and most likely provide an FAQ to coin holders with the most common questions about the
              liquidation process. <br></br>
              <br></br>We hope to be back in touch in the upcoming weeks with another update related to the commencement
              of the liquidation. <br></br>

              <br></br> January 18, 2024: <br></br>
              <br></br>Dear Coin Holder, <br></br>
              <br></br>We are writing with an update on the AXIA Project wind-down. <br></br>
              <br></br>We are now down to the final steps to bring the AXIA Project into a formal liquidation process in
              the Cayman Islands and are able to provide additional detail on certain efforts made over the past six
              months. Significant time has been required to bring various companies within the AXIA Project into good
              standing such that they can be migrated to the Cayman Islands and merged to facilitate the orderly and
              centralized wind-down of the AXIA Project. While this has taken much more time than anticipated, in the
              absence of such steps, the liquidation of companies across various jurisdictions would have been a far
              more costly and inefficient process than what is currently expected. <br></br>
              <br></br>As part of this process, we were able to sell the AXIA banking license, which required
              significant interaction with and the approval of the bank‘s regulator. The sale process is largely
              completed netting USD$360,000, with final payments expected within the coming weeks. <br></br>
              <br></br>We have secured all cash and digital assets belonging to the AXIA Project. This process was, in
              part, delayed because of the inexplicable refusal of one of the AXIA Project’s operating banks to release
              AXIA’s account holding approximately US$4 million. After months of negotiation and, eventual litigation,
              AXIA was able to successfully recover all of the cash held in that account, less non-material bank costs.{" "}
              <br></br>
              <br></br>The corporate merger process is also nearing completion. AXIA Project entities in multiple
              jurisdictions have now been merged with the new Cayman entity. The Directors are completing the last step
              related to the final entity before appointing voluntary liquidators to the Cayman company. <br></br>
              <br></br>On another front, AXIA is in the process of filing a claim against certain unrelated third
              parties for losses caused to AXIA in the amount of approximately US$3 million. Investigation and
              information gathering are ongoing, including identifying viable sources of recovery for this claim, and
              updates will be provided by the liquidators when available. The Directors also continue to monitor and
              engage, as appropriate, with the Axtra litigation filed against certain specific AXIA entities. <br></br>
              <br></br>Once liquidation commences, the liquidators will attempt to monetize certain other assets of the
              Project. <br></br>
              <br></br>Lastly, we have been made aware that the Ontario Capital Markets Tribunal has issued a Notice of
              Hearing, along with a Statement of Allegations by the Ontario Securities Commission (“SOA”) related to
              Nicholas Agar (“Agar”) and Paul Ungerman (“Ungerman”). <br></br>
              <br></br>The Notice of Hearing states that the purpose of the hearing is to consider a Settlement
              Agreement dated January 10, 2024, between Staff of the Ontario Securities Commission (“OSC”) and Agar and
              Ungerman in respect of the SOA. As described in the SOA, the OSC began inquiries into matters related to
              the AXIA Project some time ago. The proposed settlement relates to OSC allegations that the distribution
              and promotion of the AXIA tokens violated Ontario securities laws following an investigation by the OSC.{" "}
              <br></br>
              <br></br>These documents can be found at
              https://www.capitalmarketstribunal.ca/en/notices/notice-nicholas-agar-and-paul-ungerman-file-no-2024-1{" "}
              <br></br>
              <br></br>Importantly, the OSC is not pursuing any remedies against any of the corporate entities
              affiliated with the AXIA Project at this time. <br></br>
              <br></br>We look forward to entering the liquidation process and communicating with coin holders more
              frequently as information becomes available.
              <br></br>
              <br></br> December 22, 2023: <br></br>
              <br></br>Dear Coin Holder, <br></br>
              <br></br>The implementation of the Cayman wind-up process is in the final stages, and incident to that
              process, over the coming months, coin holders will have access to additional information about the wind-up
              considerations and ongoing status of the Project. Currently, we can advise that steps have been taken to
              monetize assets, consolidate available funds from several jurisdictions, and commence the process of
              merging AXIA entities in the Cayman Islands for the purpose of the wind-up. Calderwood would like to share
              that, throughout this process, due to certain regulatory requirements and in reliance upon the related
              advice of counsel for certain of the AXIA entities to which Calderwood serves as an independent fiduciary,
              Calderwood has been limited in its ability to communicate more detailed information to coin holders about
              the status of the Project. We will provide an update in the first half of January and expect additional
              communications once the liquidators are formally appointed.
              <br></br>
              <br></br> October 31,2023: <br></br>
              <br></br>Dear Coin Holder, <br></br>
              <br></br>We wish to provide you with an update on the progress of the wind-down plan in respect of the
              AXIA Project. As the AXIA entities operated from a number of different jurisdictions, a solution has been
              developed that consolidates the project, allowing for a far more streamlined and equitable wind-down
              process. The development and execution of this plan has been an unusually complex and extended exercise;
              however, it is well underway.
              <br></br>
              <br></br>Subject to certain approvals, which Calderwood is seeking in several jurisdictions, all of the
              relevant AXIA entities will transfer into the jurisdiction of the Cayman Islands and merge with a
              wholly-owned subsidiary of AXIA Network Foundation, an exempted foundation company incorporated under the
              laws of the Cayman Islands. Through this process, any and all assets of the AXIA group will be held for
              the benefit of those persons/parties with approved claims in the AXIA group. The Cayman Islands, which is
              a British Overseas Territory, offers a robust infrastructure for corporate wind-downs. The Cayman Islands
              liquidation framework is designed to be equitable to stakeholders and to establish a centralized process
              for the adjudication of stakeholder claims.
              <br></br>
              <br></br>We are very close to the finalization of the consolidation process and will announce when that
              has occurred. Following this, further information will be provided in relation to next steps in the
              wind-down process. We appreciate your continued patience. <br></br>
              <br></br> June 27, 2023: <br></br>
              <br></br>We appreciate coin holders’ continued patience as we work through the wind-down process with the
              AXIA entities. Due to the AXIA Project structure, the various jurisdictions involved, and related
              requirements in such jurisdictions, coordinating the wind-down of the AXIA entities has taken longer than
              anticipated. However, we have made significant progress over the past several months and currently
              anticipate that we will soon be in a position to commence the formal wind down process, at which point we
              will contact coin holders with additional information. Should we have any developments with timing, we
              will let coin holders know. <br></br>
            </div>
          </div>
        </Modal>
        <Modal
          visible={showAgreePopup}
          title="AXC Token Migration Terms"
          width={"50vh"}
          centered
          okText={"Agree"}
          cancelText={"Cancel"}
          onOk={() => agreeConsent({ consentFormName: USER_CONSENT.AXIA_NETWORK_V2_TOKEN_MIGRATION })}
          onCancel={() => {
            userServices.appSignOut();
            setShowAgreePopup(false);
          }}
        >
          <div className="modal">
            <div className="modalContent">
              I have read and agree to the{" "}
              <a
                onClick={() => {
                  setConsentFile(TokenSplitPDF);
                  setShowConsentModal(true);
                }}
              >
                AXC Token Migration Terms.
              </a>
            </div>
          </div>
        </Modal>
        <Modal
          visible={showUsaxPopup}
          title="Terms and Conditions Update"
          width={"50vh"}
          centered
          okText={"OK"}
          onOk={() => agreeConsent({ consentFormName: USER_CONSENT.USAX_ACCOUNT })}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => {
            userServices.appSignOut();
            setShowUsaxPopup(false);
          }}
        >
          <div className="modal">
            <div className="modalContent">
              By clicking on "OK", you have read and acknowledged the{" "}
              <a
                href={process.env.REACT_APP_TERM_AND_CONDITION_LINK}
                target="_blank"
                rel="noopener noreferrer"
                style={{ flex: 3 }}
              >
                terms and conditions
              </a>
              .
            </div>
          </div>
        </Modal>
        <Modal
          visible={showTaxIdPopup}
          title="Mandatory Tax ID"
          width={"50vh"}
          centered
          okText={"Acknowledge"}
          onOk={async () => {
            await login(formValues);
          }}
          onCancel={() => {
            userServices.appSignOut();
            setShowTaxIdPopup(false);
          }}
        >
          <div className="modal">
            <div className="modalContent">
              Your proof of personal Tax ID is not provided yet. Please complete your Tax ID verification process by
              uploading Tax ID document as soon as possible. If this information is not provided by August 1st 2022,
              your access to the bank will be blocked.
            </div>
          </div>
        </Modal>
        <Modal
          visible={showUserSuspendedPopup}
          title="Your account has been closed due to negative balance"
          width={"50vh"}
          centered
          closable={false}
          okText={"Add fund"}
          onOk={async () => {
            window.location.replace("deposit");
          }}
          onCancel={() => {
            userServices.appSignOut();
            setShowTaxIdPopup(false);
          }}
        >
          <div className="modal">
            <div className="modalContent">
              Please deposit a minimum of {Math.abs(owedAmount - 100)} USD to re-activate your account. If you have any
              questions, please contact support at{" "}
              <a href="mailto:support@axiacapitalbank.com">support@axiacapitalbank.com</a>.
            </div>
          </div>
        </Modal>
      </Spin>
    </AuthWrapper>
  );
};

export const Login = boundError(connect(null, { mfaLoginRequest, loginSuccess })(LoginRaw));
