import React, { useState, useEffect, useContext } from "react";
import {
  RightOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
  LoadingOutlined,
  DollarOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BackBtn, SimpleButton, CancelBtn, StepMenu, ListCard, AccountCard, KeyValue2 } from "../components";
import {
  boundError,
  getAmountSymbol,
  getDecimalScale,
  formatAmount,
  showError,
  isValidForFeature,
  FEATURE_NAMES
} from "../utils";
import { Checkbox, Menu, Dropdown, Button, Spin, Divider, Image } from "antd";
import { getAlphapoSupportCurrencies, getAlphapoCurrenciesRate, getCreditDebitDepositAddress } from "../apis/deposit";
import NumberFormat from "react-number-format";
import Big from "big.js";
import { FeeRow } from "../components/SharedComponents/feeRow";
import { CURRENCY } from "../constants";
import { CRYPTOS } from "../constants/cryptos";
import { getConversionRateApi } from "../apis";
import { UserContext } from "../hooks";
import { WalletAddressQRCode } from "../components";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CreditDebitDepositRaw = () => {
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [serviceProviderList, setServiceProviderList] = useState([
    { name: "Linearpaye", code: "LINEARPAY" },
    { name: "Onramper", code: "ONRAMPER" }
  ]);
  const [serviceProvider, setServiceProvider] = useState({});
  const [currencyToList, setCurrencyToList] = useState([{ currency: "AXC", type: "crypto" }]);
  const [selectedCurrencyTo, setSelectedCurrencyTo] = useState("");
  const [exchangeRate, setExchangeRate] = useState("0");
  const [minDepositAmount, setMinDepositAmount] = useState("0");

  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [depositAmount, setDepositAmount] = useState("");
  const [receiveAmount, setReceiveAmount] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [depositAddress, setDepositAddress] = useState("");

  const [inputtingDepositAmount, setInputtingDepositAmount] = useState(false);
  const [inputtingReceiveAmount, setInputtingReceiveAmount] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMinDepositAmount();
    if (isValidForFeature({ user, featureName: FEATURE_NAMES.USAX_ACCOUNT })) {
      setCurrencyToList([
        { currency: "AXC", type: "crypto" },
        { currency: "USAX", type: "crypto" }
      ]);
    }
  }, []);

  const fetchMinDepositAmount = async () => {
    let supportCurrencies = await getAlphapoSupportCurrencies();
    supportCurrencies = (supportCurrencies || []).filter((currency) => currency.currency === CRYPTOS.USDTE.symbol);
    if (supportCurrencies.length === 1) {
      setMinDepositAmount(supportCurrencies[0]["minimum_amount"]);
    } else {
      showError(`This service is temporarily unavailable, please try again later.`);
    }
  };

  const onDepositAmountChanged = (value) => {
    if (inputtingReceiveAmount || isNaN(value)) {
      return;
    }
    if (value === "") {
      setDepositAmount("");
      setReceiveAmount("");
    } else {
      setDepositAmount(value);
      setReceiveAmount(convertToAxc(value));
    }
  };

  const onReceivedAmountChanged = (value) => {
    if (inputtingDepositAmount || isNaN(value)) {
      return;
    }

    if (value === "") {
      setDepositAmount("");
      setReceiveAmount("");
    } else {
      setReceiveAmount(value);
      setDepositAmount(convertFromAxc(value));
    }
  };

  const convertToAxc = (amount) => {
    if (amount === "") {
      return "";
    }
    const conversionRate = new Big(exchangeRate ?? "0");
    const feeRate = Big(0.992);
    return Big(amount ?? "0")
      .times(conversionRate)
      .times(feeRate)
      .toFixed(5);
  };

  const convertFromAxc = (amount) => {
    if (amount === "") {
      return "";
    }
    const conversionRate = new Big(exchangeRate ?? "0");
    const feeRate = Big(0.992);
    return Big(amount ?? "0")
      .div(conversionRate)
      .div(feeRate)
      .toFixed(5);
  };

  const getCurrencyImg = (currency, { logoSize = 30 } = {}) => {
    if (CRYPTOS[currency]) {
      return <Image src={CRYPTOS[currency].icon} style={{ height: logoSize, width: logoSize }} />;
    }

    return <DollarOutlined style={{ fontSize: logoSize }} />;
  };

  const getCryptoName = (currency) => {
    if (CRYPTOS[currency]) {
      return `${CRYPTOS[currency].symbol} - ${CRYPTOS[currency].name}`;
    }

    return `${currency}`;
  };

  const renderCurrencyList = ({ currency, onClick }) => {
    return (
      <ListCard
        key={`currencyList-${currency}`}
        children={
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center"
            }}
          >
            {getCurrencyImg(currency)}
            <p className="margin-none" style={{ marginLeft: 15, fontSize: "1rem", fontWeight: "500" }}>
              {getCryptoName(currency)}
            </p>
          </div>
        }
        tailComponent={<RightOutlined style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }} />}
        onClick={onClick}
        style={{ marginBottom: "0.5rem" }}
      />
    );
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <h2
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  textAlign: "left",
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Select a service provider
              </h2>
              {serviceProviderList.map((provider) => {
                console.log(user);
                return (
                  (provider.code !== "ONRAMPER" ||
                    isValidForFeature({ user, featureName: FEATURE_NAMES.ONRAMPER })) && (
                    <ListCard
                      key={`serviceProviderList-${provider.code}`}
                      children={
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center"
                          }}
                        >
                          <p className="margin-none" style={{ marginLeft: 15, fontSize: "1rem", fontWeight: "500" }}>
                            {provider.name}
                          </p>
                        </div>
                      }
                      tailComponent={
                        <RightOutlined style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }} />
                      }
                      onClick={() => {
                        setServiceProvider(provider);
                        setCurrentStep((prev) => prev + 1);
                      }}
                      style={{ marginBottom: "0.5rem" }}
                    />
                  )
                );
              })}
              <p
                className="margin-none"
                style={{
                  textAlign: "left",
                  fontSize: "1rem",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  fontWeight: 600,
                  color: " rgba(180, 83, 9)"
                }}
              >
                Please note: Linearpaye and Onramper both offer the ability to initiate a deposit using a debit/credit
                card. The services are country dependent and afford users the ability to conduct transactions subject to
                their program specific terms and conditions.
              </p>
            </div>
            <Divider />
            <CancelBtn toPath="/deposit" />
          </>
        );
      case 1:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <h2
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  textAlign: "left",
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Select the currency to deposit funds into
              </h2>
              {currencyToList.map(({ currency }) =>
                renderCurrencyList({
                  currency,
                  onClick: async () => {
                    setLoading(true);
                    const res = await getConversionRateApi(CURRENCY.USD.code, currency);
                    setExchangeRate(res.conversionRate);
                    setSelectedCurrencyTo(currency);
                    setCurrentStep((prev) => prev + 1);
                    setLoading(false);
                  }
                })
              )}
            </div>
            <Divider />
            <CancelBtn toPath="/deposit" />
          </>
        );
      case 2:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <p
                className="margin-none"
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Enter the amount to be deposited
              </p>
              <div style={{ display: "flex", width: "100%" }}>
                <NumberFormat
                  decimalScale={5}
                  thousandSeparator={true}
                  value={depositAmount}
                  placeholder={getAmountSymbol(CRYPTOS.USDTE.symbol) + minDepositAmount}
                  prefix={getAmountSymbol(CRYPTOS.USDTE.symbol)}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    setInputtingDepositAmount(true);
                    onDepositAmountChanged(value);

                    setTimeout(() => {
                      setInputtingDepositAmount(false);
                    }, 50);
                  }}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "12px",
                    border: "2px solid #B1B5C4",
                    padding: "10px 20px",
                    maxHeight: 50,
                    marginRight: 10,
                    fontSize: 16
                  }}
                />
                <p
                  style={{
                    width: "100px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "12px",
                    border: "2px solid #B1B5C4",
                    padding: "10px 20px",
                    maxHeight: 50,
                    justifyContent: "center",
                    background: "#F8F9FA",
                    fontSize: 16,
                    margin: 0
                  }}
                >
                  {CRYPTOS.USDTE.symbol}
                </p>
              </div>
              <FeeRow
                rows={[
                  {
                    fee: minDepositAmount,
                    type: "Minimum required amount"
                  },
                  {
                    fee: new Big(exchangeRate && exchangeRate !== "" ? exchangeRate : 0).toFixed(5),
                    type: "Exchange rate"
                  },
                  {
                    fee: "0.8%",
                    type: "Deposit fee percentage"
                  }
                ]}
              />
              <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                <p
                  className="margin-none"
                  style={{
                    display: "flex",
                    lineHeight: "1.75rem",
                    marginBottom: 10,
                    fontWeight: 500,
                    width: "100%",
                    fontSize: "12px",
                    color: "#777E91"
                  }}
                >
                  Estimated amount of {selectedCurrencyTo} you will receive
                </p>
                <div style={{ display: "flex", width: "100%" }}>
                  <NumberFormat
                    thousandSeparator={true}
                    value={receiveAmount}
                    placeholder={convertToAxc(minDepositAmount)}
                    decimalScale={getDecimalScale(selectedCurrencyTo)}
                    prefix={getAmountSymbol(selectedCurrencyTo)}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setInputtingReceiveAmount(true);
                      onReceivedAmountChanged(value);

                      setTimeout(() => {
                        setInputtingReceiveAmount(false);
                      }, 50);
                    }}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: "12px",
                      border: "2px solid #B1B5C4",
                      padding: "10px 20px",
                      maxHeight: 50,
                      marginRight: 10,
                      fontSize: 16
                    }}
                  />
                  <p
                    style={{
                      width: "100px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: "12px",
                      border: "2px solid #B1B5C4",
                      padding: "10px 20px",
                      maxHeight: 50,
                      justifyContent: "center",
                      background: "#F8F9FA",
                      fontSize: 16,
                      margin: 0
                    }}
                  >
                    {selectedCurrencyTo}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    padding: "1rem",
                    background: "#F8F9FA",
                    borderRadius: "12px",
                    width: "100%"
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <p
                      className="margin-none"
                      style={{
                        textAlign: "left",
                        fontSize: "1rem",
                        marginBottom: "1rem",
                        fontWeight: 600,
                        color: " rgba(180, 83, 9)"
                      }}
                    >
                      <ExclamationCircleOutlined style={{ marginRight: "0.5rem" }} />
                      Please be advised that a credit/debit deposit requires the individual to pay both the GAS fees and
                      service fees charged by the provider to conduct your deposit.
                    </p>

                    <p
                      className="margin-none"
                      style={{
                        display: "flex",
                        textAlign: "left",
                        fontSize: "14px",
                        marginBottom: "1rem",
                        color: "#777E90"
                      }}
                    >
                      You will use a service provided by {serviceProvider.name}, which offers platform users the ability
                      to transfer USDTE for conversion into AXIA Coins. Please read and agree to the Terms of Service of{" "}
                      {serviceProvider.name} before using the service. AXIA Capital Bank does not assume any
                      responsibility for any loss or damage caused by the use of the associated transfer service.
                    </p>
                    <Checkbox
                      checked={isConfirmed}
                      onChange={(e) => setIsConfirmed(e.target.checked)}
                      style={{ fontSize: "1rem" }}
                    >
                      {"Agree to "}
                      <a href="https://coinspaid.com/terms-of-use/">Terms of Service</a>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <CancelBtn toPath="/deposit" />
              <SimpleButton
                style={{
                  backgroundColor: !isConfirmed ? "#aaaaaa" : "#178FE1",
                  padding: "0.5rem 0.75rem 0.5rem 0.75rem",
                  width: 300,
                  borderRadius: 12,
                  border: `2px solid ${!isConfirmed ? "#aaaaaa" : "#178FE1"}`
                }}
                disabled={!isConfirmed}
                onClick={async () => {
                  if (depositAmount < minDepositAmount) {
                    showError(`Please enter the valid amount`, `Amount cannot be smaller than ${minDepositAmount}`);
                    return;
                  }
                  setLoading(true);

                  const res = await getCreditDebitDepositAddress({ serviceProvider: serviceProvider.code });
                  setDepositAddress(res.data);
                  setCurrentStep((prev) => prev + 1);
                  setLoading(false);
                }}
                children={
                  <p
                    className="margin-none"
                    style={{ color: "white", fontSize: "1rem", fontWeight: 500, whiteSpace: "nowrap" }}
                  >
                    Continue
                    <RightOutlined style={{ color: "white", paddingLeft: "5px" }} />
                  </p>
                }
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <h2
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  textAlign: "left",
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Fund your account balance with {CRYPTOS.USDTE.symbol}
              </h2>
              <KeyValue2
                style={{ width: "100%" }}
                keyName="Deposit address:"
                value={depositAddress}
                copyValue={depositAddress}
                keyStyle={{ fontSize: "15px", fontWeight: 500 }}
                valueStyle={{ fontSize: "15px", margin: "0 0.5rem" }}
              />
              <KeyValue2
                style={{ width: "100%" }}
                keyName="Amount:"
                value={`${formatAmount(depositAmount, CRYPTOS.USDTE.symbol)} ${CRYPTOS.USDTE.symbol}`}
                keyStyle={{ fontSize: "15px", fontWeight: 500 }}
                valueStyle={{ fontSize: "15px", margin: "0 0.5rem" }}
              />
              <WalletAddressQRCode address={depositAddress} />
              <ul
                style={{
                  fontSize: "1rem",
                  color: "#F12F2F",
                  textAlign: "left",
                  marginTop: "1rem",
                  backgroundColor: "#FFF0F0",
                  padding: "1rem 2rem",
                  borderRadius: 12
                }}
              >
                <li>
                  Verify the receiving address prior to transfer or funds will not make it to destination account and
                  could be permanently lost
                </li>
                <li>Transactions cannot be canceled once sent</li>
              </ul>
              <p
                className="margin-none"
                style={{
                  textAlign: "left",
                  fontSize: "1rem",
                  marginBottom: "1rem",
                  marginTop: "0.5rem",
                  fontWeight: 500,
                  color: " rgba(180, 83, 9)"
                }}
              >
                <ExclamationCircleOutlined style={{ marginRight: "0.5rem" }} />
                IMPORTANT - Please note that the wallet address provided is only applicable for USDTE.
              </p>
            </div>
            <Divider />
            <SimpleButton
              style={{
                padding: "0.5rem 0.75rem 0.5rem 0.75rem",
                width: 300,
                borderRadius: 12,
                margin: "auto"
              }}
              onClick={() => {
                let url;
                switch (serviceProvider.code) {
                  case "LINEARPAY":
                    url = `https://axia.linearpaye.com/?email=${user.email}&address=${depositAddress}&asset=USDT&returnUrl=${process.env.REACT_APP_BANK_WEB_BASE_URL}`;
                    break;
                  case "ONRAMPER":
                    url = `https://widget.onramper.com?apiKey=${process.env.REACT_APP_ONRAMPER_API_KEY}&defaultCrypto=USDT&onlyCryptos=USDT&isAddressEditable=false&wallets=USDT:${depositAddress}`;
                    break;
                }
                window.location.href = url;
              }}
              children={
                <p className="margin-none" style={{ color: "white", fontSize: "0.875rem", fontWeight: 500 }}>
                  Pay with {serviceProvider.name}
                </p>
              }
            />
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <Spin indicator={antIcon} spinning={loading}>
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "85%", margin: "40px auto" }}
      >
        <BackBtn
          onClick={() => {
            if (currentStep > 0) {
              if (currentStep === 1) {
                setServiceProvider({});
              } else if (currentStep === 2) {
                setDepositAmount("");
                setIsConfirmed(false);
              } else if (currentStep === 3) {
                setDepositAddress("");
                setIsConfirmed(false);
              }
              setCurrentStep((prev) => prev - 1);
            } else {
              navigate("/deposit");
            }
          }}
        />
        <div className="row page-title" style={{ paddingTop: 0 }}>
          Credit/Debit Deposit
        </div>
        <div className="card-apply-container" style={{ width: "100%" }}>
          <StepMenu steps={["Service Provider", "Currency to", "Amount estimator", "Pay"]} currentStep={currentStep} />
          <Divider />
          {renderStep()}
        </div>
      </div>
    </Spin>
  );
};

export const CreditDebitDeposit = boundError(CreditDebitDepositRaw);
