import React, { useEffect, useState, useContext } from "react";
import { Badge, TabBar } from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import { boundError } from "../../utils";
import {
  DollarOutlined,
  UserOutlined,
  HomeOutlined,
  ArrowUpOutlined,
  PlusOutlined,
  GiftOutlined
} from "@ant-design/icons";
import { UserRegistrationStatusContext } from "../../hooks";
import { showError } from "../../utils/messages";

const BottomNavBarRaw = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [registrationStatus, setRegistrationStatus] = useContext(UserRegistrationStatusContext);

  const [pathname, setPathName] = useState(location.pathname);

  const tabs = [
    {
      key: "/accounts",
      title: "Account",
      icon: <HomeOutlined />,
      badge: Badge.dot
    },
    {
      key: "/deposit",
      title: "Deposit",
      icon: <PlusOutlined />,
      badge: "5"
    },
    {
      key: "/transfer",
      title: "Transfer",
      icon: <ArrowUpOutlined />,
      badge: "99+"
    },
    {
      key: "/stakings",
      title: "Stakings",
      icon: <DollarOutlined />
    },
    {
      key: "/profile",
      title: "Profile",
      icon: <UserOutlined />
    }
  ];

  useEffect(() => {
    if (tabs.map((item) => item.key).includes(location.pathname)) {
      setPathName(location.pathname);
    }
  }, [location.pathname]);

  const setRouteActive = (value) => {
    if (registrationStatus?.registrationCompleted) {
      navigate(value);
    } else {
      showError("Please complete user registration.");
    }
  };

  return (
    <TabBar
      activeKey={pathname}
      onChange={(value) => setRouteActive(value)}
      style={{
        overflow: "auto",
        width: "100wh",
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: "#004373"
      }}
    >
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} />
      ))}
    </TabBar>
  );
};

export const BottomNavBar = boundError(BottomNavBarRaw);
