import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, Divider, Spin, Switch } from "antd";
import { boundError, showError } from "../utils";
import { useParams } from "react-router";
import StakingServices from "../services/stakingServices";
import { BackBtn, KeyValue2, TabsMenu } from "../components/SharedComponents";
import Big from "big.js";
import date from "date-and-time";
import { ApplyStakingModal } from "../components/Stakings/ApplyStakingModal";
import { AddMoreAmountStakingModal } from "../components/Stakings/AddMoreAmountStakingModal";
import { LoadAmountToCardModal } from "../components/Stakings/LoadAmountToCardModal";
import { StakingReEnrollmentModal } from "../components/Stakings/StakingReEnrollmentModal";
import { StakingProductsContext } from "../hooks";
import { useNavigate } from "react-router-dom";
import { STAKING_STATUS, translateStakingStatus } from "../constants/stakingStatus";
import { ClaimStakingModal } from "../components/Stakings/ClaimStakingModal";
import UserServices from "../services/userServices";
import { formatAmount } from "../utils";
import { StakingsActivities, StakingInterestRecords } from "../components/Stakings";
import { CURRENCY } from "../constants";
import { isValidForFeature, FEATURE_NAMES } from "../utils";
import { UserContext } from "../hooks";

const StakingDetailRaw = () => {
  const { stakingId } = useParams();
  const stakingServices = StakingServices.getInstance();

  const [user] = useContext(UserContext);

  const [staking, setStaking] = useState(null);
  const [loading, setLoading] = useState(false);
  const [applyModalOpened, setApplyModalOpened] = useState(false);
  const [addMoreAmountOpened, setAddMoreAmountOpened] = useState(false);
  const [loadCardModalOpened, setLoadCardModalOpened] = useState(false);
  const [reEnrollmentModalOpen, setReEnrollmentModalOpen] = useState(false);

  const [products] = useContext(StakingProductsContext);
  const navigate = useNavigate();
  const [claimModalOpened, setClaimModalOpened] = useState(false);
  const userServices = UserServices.getInstance();
  const [tabIdx, setTabIdx] = useState("0");
  const [switchOn, setSwitchOn] = useState(false);

  const activityModalRef = useRef();

  useEffect(() => {
    userServices.fetchUserAccounts();
    initData();
  }, []);

  const initData = async () => {
    fetchStaking();
    // fetchStakingProducts();
  };

  const fetchStakingProducts = async () => {
    stakingServices.getAvailableStakingProducts("AXC", 0, 24);
  };

  const getRelatedProduct = () => {
    if (staking?.productCode && products && products.length > 0) {
      const result = products.find((prod) => prod.code === staking?.productCode && Big(prod?.roomRemain).gt(0));

      return result;
    }
  };

  const fetchStaking = async () => {
    try {
      setLoading(true);
      const result = await stakingServices.fetchUserStakingById(stakingId);
      if (result) {
        setStaking(result);
      }
      setLoading(false);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };

  const getStakingId = () => {
    if (staking?.id) {
      return "******" + staking?.id.substr(-5);
    }
    return "";
  };

  const getStakingAmount = () => {
    if (!isNaN(staking?.amount)) {
      return switchOn
        ? formatAmount(staking?.amount * staking?.rateInUsd, CURRENCY.USD.code, "$")
        : formatAmount(staking?.amount, staking?.currency) + " " + staking?.currency;
    }

    return "";
  };

  const getStakingSpendableAmount = () => {
    if (!isNaN(staking?.spendableAmount)) {
      return switchOn
        ? formatAmount(staking?.spendableAmount * staking?.rateInUsd, CURRENCY.USD.code, "$")
        : formatAmount(staking?.spendableAmount, staking?.currency) + " " + staking?.currency;
    }

    return "";
  };

  const getStakingInterestAmount = () => {
    if (!isNaN(staking?.interestAmount)) {
      return switchOn
        ? formatAmount(staking?.interestAmount * staking?.rateInUsd, CURRENCY.USD.code, "$")
        : formatAmount(staking?.interestAmount, staking?.currency) + " " + staking?.currency;
    }

    return "";
  };

  const getAnnualInterestRate = () => {
    if (!isNaN(staking?.annualInterestRate)) {
      return Big(staking?.annualInterestRate).mul(100).toFixed(2) + " %";
    }

    return "";
  };

  const getTermStartAt = () => {
    if (staking?.termStartAt) {
      return date.format(new Date(staking?.termStartAt), "YYYY-MM-DD", true);
    }

    return "";
  };

  const getTermEndAt = () => {
    if (staking?.termEndAt) {
      return date.format(new Date(staking?.termEndAt), "YYYY-MM-DD", true);
    }

    return "";
  };

  const closeApplyModal = () => {
    setApplyModalOpened(false);
  };

  const closeAddMoreAmountModal = () => {
    setAddMoreAmountOpened(false);
  };

  const closeLoadCardModal = () => {
    setLoadCardModalOpened(false);
  };

  const closeReEnrollmentModal = () => {
    setReEnrollmentModalOpen(false);
  };

  const closeClaimModal = () => {
    setClaimModalOpened(false);
    fetchStaking();
  };

  const onApplySuccess = () => {
    navigate("/stakings");
  };

  const onClaimSuccess = () => {
    fetchStaking();
  };

  const onAddMoreAmountSuccess = async () => {
    await fetchStaking();
    activityModalRef?.current?.refresh();
  };

  const onLoadCardSuccess = () => {
    fetchStaking();
  };

  const onReEnrollmentSetupSuccess = () => {
    fetchStaking();
  };

  const showApplyMoreBtn = () => {
    /// Temporary remove apply more btn
    return false;
    if (!getRelatedProduct()) {
      return false;
    }

    if (![STAKING_STATUS.CREATED, STAKING_STATUS.IN_TERM].includes(staking?.status)) {
      return false;
    }

    if (Big(staking?.individualRoomLimit).eq(0)) {
      return false;
    } else if (
      Big(staking?.individualRoomLimit).gt(0) &&
      Big(staking?.accumulatedDepositAmount).gte(staking?.individualRoomLimit)
    ) {
      return false;
    }

    if (Big(staking?.maximumApplyNumbers).eq(0)) {
      return false;
    } else if (
      Big(staking?.maximumApplyNumbers).gt(0) &&
      Big(staking?.userAppliedNumbers).gte(staking?.maximumApplyNumbers)
    ) {
      return false;
    }

    return true;
  };

  const showAddMoreAmountBtn = () => {
    if (showAutoEnrollBtn()) {
      return false;
    }

    // individualRoomLimit === 0, not allowed to add more
    if (staking?.enableAddAmount !== true) {
      return false;
    }

    if (![STAKING_STATUS.CREATED, STAKING_STATUS.IN_TERM].includes(staking?.status)) {
      return false;
    }

    let roomRemain = Big(0);

    if (!isNaN(staking?.productRoomRemain)) {
      roomRemain = Big(staking?.productRoomRemain);
    }

    if (!isNaN(staking?.individualRoomLimit)) {
      const individualRoomLimit = Big(staking?.individualRoomLimit);
      if (individualRoomLimit.gt(0)) {
        const individualRoomRemain = individualRoomLimit.minus(staking?.amount);

        if (individualRoomRemain.lte(roomRemain)) {
          roomRemain = individualRoomRemain;
        }
      }
    }

    if (staking?.stakingProductIsPrivate) {
      return false;
    }

    if (new Date(staking?.termEndAt || 0) < new Date()) {
      return false;
    }

    if (new Date(staking?.productEndAt || 0) < new Date()) {
      return false;
    }

    return roomRemain.gt(0) && isValidForFeature({ user, featureName: FEATURE_NAMES.STAKINGS_ADD_AMOUNT });
  };

  const showLoadToCardBtn = () => {
    return staking?.enableLoadCard;
  };

  const onChange = (checked) => {
    setSwitchOn(checked);
  };

  const willExpireIn4Weeks = () => {
    const dateAfter4Weeks = new Date();
    dateAfter4Weeks.setDate(dateAfter4Weeks.getDate() + 28);

    const stakingExpireDate = new Date(staking?.termEndAt);

    return stakingExpireDate <= dateAfter4Weeks;
  };

  const showAutoEnrollBtn = () => {
    return (
      staking?.status === STAKING_STATUS.IN_TERM &&
      willExpireIn4Weeks() &&
      isValidForFeature({ user, featureName: FEATURE_NAMES.STAKINGS_RE_ENROLLMENT })
    );
  };

  const reEnrollBtnText = () => {
    if (staking?.autoEnrollments && staking?.autoEnrollments.length > 0) {
      return "Edit Re-enrollment";
    }
    return "Re-enrollment Open";
  };

  const getReEnrollmentStartStr = () => {
    if (staking?.autoEnrollments && staking?.autoEnrollments.length > 0) {
      return date.format(new Date(staking?.termEndAt), "YYYY-MM-DD", true);
    }
    return "";
  };

  return (
    <>
      <Spin spinning={loading}>
        <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20 }}>
          <div className="row">
            <BackBtn />
          </div>
          <div className="row" style={{ justifyContent: "center", marginBottom: "20px" }}>
            <div style={{ backgroundColor: "white", borderRadius: 20, width: "420px" }}>
              <div className="stakings__product-modal-header" style={{ textAlign: "left" }}>
                <h1>{staking?.name}</h1>
                <p>{staking?.shortDesc}</p>
              </div>

              <div style={{ padding: "0px 30px" }}>
                {/* <div className="row align-items-center" style={{ justifyContent: "flex-end", margin: 10 }}>
                  <p className="padding-none margin-none" style={{ marginRight: 5, fontWeight: 500 }}>
                    {CURRENCY.AXC.code}
                  </p>
                  <Switch style={{ backgroundColor: "#35B994" }} onChange={onChange} />
                  <p className="padding-none margin-none" style={{ marginLeft: 5, fontWeight: 500 }}>
                    {CURRENCY.USD.code}
                  </p>
                </div> */}
                <KeyValue2 keyName={"Staking#"} value={staking?.publicId} />
                <KeyValue2 keyName={"Principal Amount"} value={getStakingAmount()} />
                <KeyValue2 keyName={"Total Spendable Amount"} value={getStakingSpendableAmount()} />
                <KeyValue2 keyName={"Total Rewards Earned"} value={getStakingInterestAmount()} />
                <KeyValue2 keyName={"Annual Percentage Rewards"} value={getAnnualInterestRate()} />
                <KeyValue2 keyName={"Status"} value={translateStakingStatus(staking?.status)} />
                <KeyValue2 keyName={"Term End"} value={getTermEndAt()} />
                {getReEnrollmentStartStr() && (
                  <KeyValue2 keyName={"Re-enrollment Start"} value={getReEnrollmentStartStr()} />
                )}

                <Divider />
                <div
                  className="row"
                  style={{
                    paddingBottom: 20,
                    alignItems: "center",
                    justifyContent: "end"
                  }}
                >
                  {showAutoEnrollBtn() && (
                    <Button
                      type="primary"
                      style={{
                        borderRadius: 12,
                        marginLeft: 10,
                        minWidth: "75px",
                        fontSize: "13px",
                        backgroundColor: "#67D0B3",
                        borderColor: "#67D0B3"
                      }}
                      onClick={() => {
                        setReEnrollmentModalOpen(true);
                      }}
                    >
                      {reEnrollBtnText()}
                    </Button>
                  )}
                  {showApplyMoreBtn() && (
                    <Button
                      type="primary"
                      style={{ borderRadius: 12, marginLeft: 10, minWidth: "75px", fontSize: "13px" }}
                      onClick={() => {
                        setApplyModalOpened(true);
                      }}
                    >
                      Apply more
                    </Button>
                  )}
                  {showAddMoreAmountBtn() && (
                    <Button
                      type="primary"
                      style={{ borderRadius: 12, marginLeft: 10, minWidth: "75px", fontSize: "13px" }}
                      onClick={() => {
                        setAddMoreAmountOpened(true);
                      }}
                    >
                      Add Funds
                    </Button>
                  )}
                  {/* {showLoadToCardBtn() && (
                    <Button
                      type="primary"
                      style={{ borderRadius: 12, marginLeft: 10, minWidth: "75px", fontSize: "13px" }}
                      onClick={() => {
                        setLoadCardModalOpened(true);
                      }}
                    >
                      Load to Card
                    </Button>
                  )} */}
                  {staking?.status === STAKING_STATUS.READY_TO_CLAIM && (
                    <Button
                      type="primary"
                      style={{ borderRadius: 12, marginLeft: 10, minWidth: "75px", fontSize: "13px" }}
                      onClick={() => {
                        setClaimModalOpened(true);
                      }}
                    >
                      Claim
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {<TabsMenu options={["Transactions", "Rewards"]} onChange={setTabIdx} style={{ color: "#02466F" }} />}
          {tabIdx === "0" && <StakingsActivities stakingId={stakingId} ref={activityModalRef} />}
          {tabIdx === "1" && !loading && <StakingInterestRecords staking={staking} />}
          <div style={{ height: 80 }} />
        </div>
      </Spin>
      <ApplyStakingModal
        visible={applyModalOpened}
        product={getRelatedProduct()}
        closeModal={closeApplyModal}
        onApplySuccess={onApplySuccess}
      />
      <ClaimStakingModal
        visible={claimModalOpened}
        staking={staking}
        closeModal={closeClaimModal}
        onClaimSuccess={onClaimSuccess}
      />
      <AddMoreAmountStakingModal
        visible={addMoreAmountOpened}
        staking={staking}
        closeModal={closeAddMoreAmountModal}
        onAddMoreAmountSuccess={onAddMoreAmountSuccess}
      />
      <LoadAmountToCardModal
        visible={loadCardModalOpened}
        staking={staking}
        closeModal={closeLoadCardModal}
        onLoadCardSuccess={onLoadCardSuccess}
      />

      <StakingReEnrollmentModal
        visible={reEnrollmentModalOpen}
        staking={staking}
        closeModal={closeReEnrollmentModal}
        onSuccess={onReEnrollmentSetupSuccess}
      />
    </>
  );
};

export const StakingDetail = boundError(StakingDetailRaw);
