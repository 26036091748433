import React from "react";
import { Row, Col } from "antd";
import logoWhite from "../resources/AXIA_White_logo.png";
import logoBlue from "../resources/axia_blue.svg";

import { boundError } from "../utils";
const AuthWrapperRaw = ({ children }) => {
  return (
    <Row className="auth-wrapper__container">
      <Col xs={0} sm={0} md={12} style={{ background: "var(--axia-blue)" }}>
        <Row justify="center" type="flex" align="middle" style={{ height: "100%" }}>
          <img className="margin-none logo-image" alt="axia-logo" src={logoWhite} />
          {/* <span className="logo-text">Capital Bank</span> */}
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} className="auth-content__container">
        <img alt="axia-logo" src={logoBlue} className="logo-image" />
        {children}
      </Col>
    </Row>
  );
};

export const AuthWrapper = boundError(AuthWrapperRaw);
