import React, { useState, useEffect, useContext } from "react";
import Icon, { RightOutlined, MinusOutlined, RiseOutlined, SwapOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BackBtn, SimpleButton, CancelBtn, StepMenu, ListCard, CutOffLine, AccountCard } from "../components";
import {
  boundError,
  showError,
  showSuccess,
  sleep,
  formatAmount,
  getDecimalScale,
  isValidForFeature,
  FEATURE_NAMES
} from "../utils";
import UserServices from "../services/userServices";
import { AccountsContext } from "../hooks";
import { Input, Checkbox, Spin, Divider } from "antd";
import { CURRENCY } from "../constants/currency";
import TransferAndDepositServices from "../services/transferAndDepositServices";
import Big from "big.js";
import { TRANSACTION_TYPE } from "../constants";
import { AmountInput } from "../components/SharedComponents/AmountInput";
import { NextBtn } from "../components/SharedComponents/Button/NextBtn";
import { FeeRow } from "../components/SharedComponents/feeRow";
import { ACCOUNT_STATUS } from "../constants/accountStatus";
import { UserContext } from "../hooks";

const { TextArea } = Input;

// Set Big to around down mode
Big.RM = Big.roundDown;

const BetweenAccountsRaw = () => {
  const navigate = useNavigate();
  const transferAndDepositServices = TransferAndDepositServices.getInstance();
  const userServices = UserServices.getInstance();
  const [user] = useContext(UserContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [accountList] = useContext(AccountsContext);
  const [allowedAccountList, setAllowedAccountList] = useState([]);

  const [selectedFromSource, setSelectedFromSource] = useState({});
  const [selectedTargetSource, setSelectedTargetSource] = useState({});

  const [memo, setMemo] = useState("");
  const [currencyRate, setCurrencyRate] = useState(1);
  const [transferAmount, setTransferAmount] = useState("");
  const [receiveAmount, setReceiveAmount] = useState("");

  const [confirmChecked, setConfirmChecked] = useState(false);
  const [transferFee, setTransferFee] = useState(0);
  const [transferFeeCurrency, setTransferFeeCurrency] = useState("");
  const [transferFeeCurrencyRate, setTransferFeeCurrencyRate] = useState(null);
  const [transferType, setTransferType] = useState(null);

  const [transferAmountInUsd, setTransferAmountInUsd] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [inputtingTransferAmount, setInputtingTransferAmount] = useState(false);
  const [inputtingReceiveAmount, setInputtingReceiveAmount] = useState(false);

  useEffect(() => {
    userServices.fetchUserAccounts();
  }, []);

  const fetchTransferType = async () => {
    try {
      const result = await transferAndDepositServices.getInternalTransactionTypes(
        TRANSACTION_TYPE.USER_TRANSFER_BETWEEN_ACCOUNTS
      );

      if (result && result.length > 0) {
        setTransferType(result[0]);

        /// if using fee from transferType, need to know currency rate of feeCurrency type of it
        const result2 = await transferAndDepositServices.getCurrencyRate(result[0]?.feeCurrency, CURRENCY.USD.code);
        if (result2?.conversionRate) {
          setTransferFeeCurrencyRate(result2?.conversionRate);
        }
      }
    } catch (err) {
      showError(err);
    }
  };

  /// get currency rate
  const getCurrencyRate = async (targetCurrency) => {
    try {
      if (selectedFromSource.currency === targetCurrency) {
        await sleep(500);
        setCurrencyRate(1);
      } else {
        const result = await transferAndDepositServices.getCurrencyRate(selectedFromSource.currency, targetCurrency);

        if (result?.conversionRate) {
          setCurrencyRate(result?.conversionRate);
        }
      }
    } catch (err) {
      showError(err);
    }
  };

  /// get transfer fee currency rate
  const getTransferCurrencyRate = async (currency) => {
    try {
      if (currency === CURRENCY.USD.code) {
        await sleep(500);
        setTransferFeeCurrencyRate(1);
      } else {
        const result = await transferAndDepositServices.getCurrencyRate(currency, CURRENCY.USD.code);

        if (result?.conversionRate) {
          setTransferFeeCurrencyRate(result?.conversionRate);
        }
      }
    } catch (err) {
      showError(err);
    }
  };

  const getAllowedTransferAccounts = async (currency) => {
    const result = await transferAndDepositServices.getAllowedTransferAccounts(
      TRANSACTION_TYPE.USER_TRANSFER_BETWEEN_ACCOUNTS,
      currency
    );

    if (result?.accounts) {
      setAllowedAccountList(result?.accounts);
    }
  };

  const getTransferFeeStr = () => {
    if (transferFee !== undefined) {
      if (selectedFromSource?.currency === CURRENCY.USD.code) {
        if (transferFeeCurrency === CURRENCY.USD.code) {
          return formatAmount(transferFee, transferFeeCurrency) + " " + transferFeeCurrency;
        } else if (transferFeeCurrency) {
          if (transferFeeCurrencyRate !== null) {
            return formatAmount(Big(transferFee).mul(transferFeeCurrencyRate).toFixed(), "USD") + " USD";
          } else {
            return "";
          }
        }
      } else {
        /// if fee currency is not usd, need to calculate usd value
        if (transferFeeCurrency === CURRENCY.USD.code) {
          return (
            Big(transferFee).div(transferFeeCurrencyRate).toFixed(5) +
            " " +
            selectedFromSource?.currency +
            " ≈ $" +
            Big(transferFee).toFixed(2) +
            " " +
            CURRENCY.USD.code
          );
        } else if (transferFeeCurrency) {
          return (
            Big(transferFee).toFixed(5) +
            " " +
            transferFeeCurrency +
            " ≈ " +
            Big(transferFee).mul(transferFeeCurrencyRate).toFixed(2) +
            " $" +
            CURRENCY.USD.code
          );
        }
      }
    }

    return "0.00";
  };

  const calculations = (amount) => {
    if (amount) {
      const amountInUsd = Big(amount).mul(currencyRate);
      setTransferAmountInUsd(amountInUsd);

      if (transferType?.fee && Number(transferType?.fee) > 0 && transferType?.feeCurrency) {
        setTransferFee(transferType?.fee);
        setTransferFeeCurrency(transferType?.feeCurrency);
      } else if (transferType?.feeRate && Number(transferType?.feeRate) > 0) {
        setTransferFee(Big(amount).mul(transferType?.feeRate));
        setTransferFeeCurrency(selectedFromSource.currency);
      }
    } else {
      setTransferFee(0);
      setTransferAmountInUsd(0);
    }
  };

  const submitTransaction = async () => {
    if (confirmChecked === false) {
      showError("Please check the checkbox above before continuing!");
      return;
    }

    try {
      setIsLoading(true);
      const decimalScale = getDecimalScale(selectedFromSource.currency);
      const amount = new Big(transferAmount && transferAmount !== "" ? transferAmount : 0).toFixed(decimalScale);
      const data = {
        fromAccountId: selectedFromSource.id,
        toAccountId: selectedTargetSource.id,
        amount
      };

      const result = await transferAndDepositServices.sendTransferBetweenAccounts(data);

      if (result) {
        showSuccess("Transaction sent successfully!");

        navigate("/transfer");
      }
      setIsLoading(false);
    } catch (err) {
      showError(err);

      setIsLoading(false);
    }
  };

  const getCurrencyRateStr = () => {
    if (currencyRate !== undefined) {
      return (
        formatAmount(currencyRate, selectedTargetSource.currency) +
        " " +
        selectedTargetSource.currency +
        "/" +
        selectedFromSource.currency
      );
    }

    return "";
  };

  const getTransferAmountStr = () => {
    if (transferAmount !== "") {
      return formatAmount(transferAmount, selectedFromSource.currency);
    }

    return "0";
  };

  const getReceiveAmountStr = () => {
    if (receiveAmount !== "") {
      return formatAmount(receiveAmount, selectedTargetSource.currency);
    }

    return "0";
  };

  const isSameCurrency = () => {
    return selectedFromSource.currency === selectedTargetSource.currency;
  };

  const getTotalAmount = () => {
    let baseAmount = Big(0);
    if (transferAmount) {
      baseAmount = Big(transferAmount);
    }

    let baseFeeAmount = Big(0);
    if (selectedFromSource?.currency === CURRENCY.USD.code) {
      if (transferFeeCurrency === CURRENCY.USD.code) {
        baseFeeAmount = Big(transferFee);
      } else if (transferFeeCurrency) {
        baseFeeAmount = Big(transferFee).mul(currencyRate);

        if (transferFeeCurrencyRate !== null) {
          baseFeeAmount = Big(transferFee).mul(transferFeeCurrencyRate);
        }
      }
    } else {
      if (transferFeeCurrency === CURRENCY.USD.code) {
        baseFeeAmount = Big(transferFee).mul(reverseCurrencyRate);
      } else if (transferFeeCurrency) {
        baseFeeAmount = Big(transferFee);
      }
    }

    return baseAmount.plus(baseFeeAmount);
  };

  const getTotalDeductStr = () => {
    let baseAmount = Big(0);
    if (transferAmount) {
      baseAmount = Big(transferAmount);
    }

    let baseFeeAmount = Big(0);
    if (selectedFromSource?.currency === CURRENCY.USD.code) {
      if (transferFeeCurrency === CURRENCY.USD.code) {
        baseFeeAmount = Big(transferFee);
      } else if (transferFeeCurrency) {
        baseFeeAmount = Big(transferFee).mul(currencyRate);

        if (transferFeeCurrencyRate !== null) {
          baseFeeAmount = Big(transferFee).mul(transferFeeCurrencyRate);
        }
      }
    } else {
      if (transferFeeCurrency === CURRENCY.USD.code) {
        baseFeeAmount = Big(transferFee).mul(reverseCurrencyRate);
      } else if (transferFeeCurrency) {
        baseFeeAmount = Big(transferFee);
      }
    }

    const totalAmount = baseAmount.plus(baseFeeAmount);

    if (selectedFromSource?.currency === CURRENCY.USD.code) {
      return formatAmount(totalAmount.toFixed(), "USD") + " USD";
    } else {
      return formatAmount(totalAmount.toFixed(), selectedFromSource?.currency);
    }
  };

  const isEnoughBalance = () => {
    const totalAmount = getTotalAmount();
    if (Big(selectedFromSource?.amount).lt(totalAmount)) {
      return false;
    }
    return true;
  };

  const onTransferAmountChanged = (value) => {
    const decimalScale = getDecimalScale(selectedTargetSource.currency);
    if (inputtingReceiveAmount || isNaN(value)) {
      return;
    }
    if (value === "") {
      setTransferAmount("");
      setReceiveAmount("");
    } else {
      setTransferAmount(value);

      if (currencyRate !== undefined) {
        const convertedAmount = new Big(value).mul(currencyRate);
        setReceiveAmount(convertedAmount.toFixed(decimalScale));
      }
    }

    calculations(Number(value));
  };

  const onReceivedAmountChanged = (value) => {
    const decimalScale = getDecimalScale(selectedFromSource.currency);
    if (inputtingTransferAmount || isNaN(value)) {
      return;
    }

    if (value === "") {
      setTransferAmount("");
      setReceiveAmount("");
    } else {
      setReceiveAmount(value);

      if (currencyRate !== undefined) {
        const convertedAmount = new Big(value).div(currencyRate);
        setTransferAmount(convertedAmount.toFixed(decimalScale));
      }
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <p
                className="margin-none"
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Select source of funds to transfer
              </p>
              {accountList.map((source) => {
                const isActive = source.status === ACCOUNT_STATUS.ACTIVE;

                if (
                  source.currency !== CURRENCY.USD.code ||
                  isValidForFeature({ user, featureName: FEATURE_NAMES.FIAT_TO_CRYPTO })
                ) {
                  return (
                    <ListCard
                      style={isActive ? {} : { backgroundColor: "lightGray", cursor: "not-allowed" }}
                      key={`sourceList-${source.publicId}`}
                      children={AccountCard(source)}
                      tailComponent={
                        isActive ? (
                          <RightOutlined
                            style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }}
                          />
                        ) : null
                      }
                      onClick={
                        isActive
                          ? async () => {
                              setSelectedFromSource(source);
                              setTransferFeeCurrency(source.currency);
                              await getAllowedTransferAccounts(source.currency);
                              await getTransferCurrencyRate(source.currency);
                              setCurrentStep((prev) => prev + 1);
                            }
                          : () => {}
                      }
                    />
                  );
                }
              })}
            </div>
            <Divider />
            <CancelBtn toPath="/transfer" />
          </>
        );
      case 1:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <p
                className="margin-none"
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Transfer between accounts
              </p>

              {allowedAccountList.filter((account) => account.id !== selectedFromSource.id).length === 0 && (
                <p style={{ color: "#777E91", fontSize: 15 }}>There's no available account to transfer to!</p>
              )}
              {allowedAccountList.filter((account) => account.id !== selectedFromSource.id).length > 0 &&
                allowedAccountList
                  .filter((account) => account.id !== selectedFromSource.id)
                  .map((source) => {
                    const isActive = source.status === ACCOUNT_STATUS.ACTIVE;

                    return (
                      <ListCard
                        style={
                          isActive
                            ? { marginBottom: "0.5rem" }
                            : { backgroundColor: "lightGray", cursor: "not-allowed", marginBottom: "0.5rem" }
                        }
                        key={`beneficiaryList-${source.publicId}`}
                        children={AccountCard(source)}
                        tailComponent={
                          isActive ? (
                            <RightOutlined
                              style={{ background: "#35B994", color: "white", padding: 2, borderRadius: 3 }}
                            />
                          ) : null
                        }
                        onClick={
                          isActive
                            ? async () => {
                                setSelectedTargetSource(source);
                                setTransferAmount("");
                                setReceiveAmount("");

                                setCurrentStep((prev) => prev + 1);
                                await sleep(500);
                                await getCurrencyRate(source.currency);
                              }
                            : () => {}
                        }
                      />
                    );
                  })}
            </div>
            <Divider />
            <CancelBtn toPath="/transfer" />
          </>
        );
      case 2:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <h2
                style={{
                  display: "flex",
                  lineHeight: "1.75rem",
                  marginBottom: "1rem",
                  fontWeight: 500,
                  textAlign: "left",
                  width: "100%",
                  fontSize: "16px",
                  color: "#23262F"
                }}
              >
                Enter the amount you want to send
              </h2>
              <AmountInput
                title={"You'll transfer"}
                onChange={(value) => {
                  setInputtingTransferAmount(true);
                  onTransferAmountChanged(value);

                  setTimeout(() => {
                    setInputtingTransferAmount(false);
                  }, 50);
                }}
                value={transferAmount}
                currency={selectedFromSource.currency}
                accountId={selectedFromSource.publicId}
                accountAmount={selectedFromSource.amount}
              />

              {isEnoughBalance() === false && (
                <p
                  className="margin-none"
                  style={{ marginLeft: "0.5rem", color: "#fb7185", fontSize: "0.9rem", fontWeight: 500 }}
                >
                  You do not have enough balance for this transaction
                </p>
              )}
              <AmountInput
                title={"Estimated amount to receive"}
                onChange={(value) => {
                  setInputtingReceiveAmount(true);
                  onReceivedAmountChanged(value);

                  setTimeout(() => {
                    setInputtingReceiveAmount(false);
                  }, 50);
                }}
                value={receiveAmount}
                currency={selectedTargetSource.currency}
                accountId={selectedTargetSource.publicId}
                accountAmount={selectedTargetSource.amount}
              />
              <FeeRow
                rows={[
                  {
                    fee: getTransferFeeStr(),
                    type: "Transfer Fee"
                  },
                  !isSameCurrency() && {
                    fee: getCurrencyRateStr(),
                    type: "Conversion Rate"
                  },
                  {
                    fee: getTotalDeductStr(),
                    type: "Total Deduct"
                  }
                ]}
              />
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <CancelBtn toPath="/transfer" />
              <NextBtn
                enable={transferAmount !== "" && isEnoughBalance() === true}
                onClick={() => setCurrentStep((prev) => prev + 1)}
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div
              className="between-account step-body"
              style={{ display: "flex", flexDirection: "column", margin: "auto" }}
            >
              <ListCard
                style={{ background: "#E9F6FF", marginBottom: "20px" }}
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <p
                        className="margin-none"
                        style={{ display: "block", fontSize: "1rem" }}
                      >{`Send from account: ${selectedFromSource.publicId}`}</p>
                      <p className="margin-none" style={{ display: "block", fontSize: "2.25rem" }}>
                        {`${getTransferAmountStr()} ${selectedFromSource.currency}`}
                      </p>
                    </div>
                  </>
                }
                onClick={() => {}}
              />
              <ListCard
                style={{ background: "#E9F6FF" }}
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <p
                        className="margin-none"
                        style={{ display: "block", fontSize: "1rem", whiteSpace: "nowrap" }}
                      >{`Send to account: ${selectedTargetSource.publicId}`}</p>
                      <p className="margin-none" style={{ display: "block", fontSize: "2.25rem" }}>
                        {`${getReceiveAmountStr()} ${selectedTargetSource.currency}`}
                      </p>
                    </div>
                  </>
                }
                onClick={() => {}}
              />
              <FeeRow
                rows={[
                  {
                    fee: getTransferFeeStr(),
                    type: "Transfer Fee"
                  },
                  !isSameCurrency() && {
                    fee: getCurrencyRateStr(),
                    type: "Conversion Rate"
                  },
                  {
                    fee: getTotalDeductStr(),
                    type: "Total Deduct"
                  }
                ]}
              />
              <Checkbox
                style={{ width: "100%", whiteSpace: "nowrap" }}
                onChange={(e) => {
                  setConfirmChecked(e.target.checked);
                }}
              >
                I confirm that the information is correct.
              </Checkbox>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <CancelBtn toPath="/transfer" />
              <NextBtn enable={confirmChecked} onClick={submitTransaction} btnName={"Send Now!"} />
            </div>
          </>
        );
      default:
        return (
          <div
            className="between-account step-body"
            style={{ display: "flex", flexDirection: "column", margin: "auto" }}
          ></div>
        );
    }
  };
  return (
    <Spin spinning={isLoading}>
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "85%", margin: "40px auto" }}
      >
        <BackBtn
          onClick={() => {
            if (currentStep > 0) {
              setCurrentStep((prev) => prev - 1);
            } else {
              navigate("/transfer");
            }
          }}
        />
        <div className="row page-title" style={{ paddingTop: 0 }}>
          Transfer
        </div>
        <div className="card-apply-container" style={{ width: "100%" }}>
          <StepMenu steps={["Account from", "Account to", "Transfer amount", "Overview"]} currentStep={currentStep} />
          <Divider />
          {renderStep()}
        </div>
      </div>
    </Spin>
  );
};

export const BetweenAccounts = boundError(BetweenAccountsRaw);
