import React from "react";
import { AppstoreOutlined, SwapOutlined } from "@ant-design/icons";
import { CardButton } from "../components";
import { Link } from "react-router-dom";
import { boundError } from "../utils";

const ServicesRaw = () => {
  return (
    <div>
      {" "}
      <div className="row page-title">
        <AppstoreOutlined style={{ marginRight: 10 }} />
        Services
      </div>
      <div className="body-padding avoid-bottom-bar">
        <div className="grid-1">
          <Link to={"/cards"}>
            <CardButton icon={<SwapOutlined style={{ fontSize: 40 }} />} text="Your Cards" />
          </Link>
          <Link to={"/Beneficiaries"}>
            <CardButton icon={<SwapOutlined style={{ fontSize: 40 }} />} text="My Beneficiaries" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export const Services = boundError(ServicesRaw);
