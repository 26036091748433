import {
  depositFromERC20Api,
  getConversionRateApi,
  getInternalTransactionTypesApi,
  sendTransferBetweenMembersApi,
  sendTransferBetweenAccountsApi,
  getAllowedTransferAccountsApi
} from "../apis";
import Emitter, { EmitterEvents } from "../utils/eventEmitter";

export default class TransferAndDepositServices {
  static transferAndDepositServices;

  static getInstance() {
    if (!this.transferAndDepositServices) {
      this.transferAndDepositServices = new TransferAndDepositServices();
    }

    return this.transferAndDepositServices;
  }

  depositFromERC20 = async (toAccountId, amountAxc) => {
    return await depositFromERC20Api(toAccountId, amountAxc);
  };

  getCurrencyRate = async (fromCurrency, toCurrency) => {
    return await getConversionRateApi(fromCurrency, toCurrency);
  };

  getInternalTransactionTypes = async (type) => {
    return await getInternalTransactionTypesApi(type);
  };

  sendTransferBetweenMembers = async ({
    fromAccountId,
    amount,
    beneficiaryEmail,
    userMemo,
    beneficiaryUserId,
    mfaCode,
    transaction2faRecordId
  }) => {
    return await sendTransferBetweenMembersApi({
      fromAccountId,
      amount,
      beneficiaryEmail,
      userMemo,
      beneficiaryUserId,
      mfaCode,
      transaction2faRecordId
    });
  };

  sendTransferBetweenAccounts = async ({ fromAccountId, toAccountId, amount }) => {
    return await sendTransferBetweenAccountsApi({ fromAccountId, toAccountId, amount });
  };

  getAllowedTransferAccounts = async (transactionType, currency) => {
    return await getAllowedTransferAccountsApi(transactionType, currency);
  };
}
