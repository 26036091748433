import React from "react";
import { Input, Button } from "antd";
import {
  LeftOutlined,
  PlusOutlined,
  TeamOutlined,
  CloseOutlined,
  RightOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { ListCard } from "../components";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { boundError } from "../utils";
import { useState } from "react";
const BeneficiariesRaw = () => {
  const [creatOpen, setCreatOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [selectedBenef, setSelectedDenef] = useState(0);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [bendficiaryCounter, setBendficiaryCounter] = useState(0);

  const closeModal = () => {
    setNewEmail("");
    setNewName("");
    setCreatOpen(false);
    setUpdateOpen(false);
  };

  const addNewBeneficiary = () => {
    const temp = JSON.parse(JSON.stringify(beneficiaries));
    temp.push({
      id: bendficiaryCounter,
      name: newName,
      email: newEmail
    });
    setBeneficiaries(temp);
    setNewName("");
    setNewEmail("");
    setBendficiaryCounter((prev) => prev + 1);
  };

  const updateBeneficiary = () => {
    const temp = JSON.parse(JSON.stringify(beneficiaries));
    temp[selectedBenef].name = newName;
    temp[selectedBenef].email = newEmail;
    setBeneficiaries(temp);
  };

  return (
    <div className="BContainer">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Link
          to={"/services"}
          style={{
            backgroundColor: "#188fe1",
            padding: "0.5rem 0.75rem 0.5rem 0.75rem",
            borderRadius: 5,
            marginBottom: "0.5rem",
            width: "10rem"
          }}
        >
          <text style={{ color: "white", fontSize: "0.875rem", fontWeight: 500, alignItems: "center" }}>
            <LeftOutlined /> Back to services
          </text>
        </Link>
        <div style={{ display: "flex", flexDirection: "column", marginTop: "1rem" }}>
          <h2
            style={{
              display: "flex",
              color: "black",
              fontSize: "1.25rem",
              lineHeight: "2rem",
              fontWeight: 500,
              alignItems: "center"
            }}
          >
            <TeamOutlined /> My Beneficiaries
          </h2>
          <ListCard
            children={<text>New bendficiary</text>}
            tailComponent={<PlusOutlined />}
            onClick={() => {
              setCreatOpen(true);
            }}
          />
          {beneficiaries.map((each) => {
            return (
              <ListCard
                key={each.email}
                children={<text>{each.name}</text>}
                tailComponent={<RightOutlined />}
                onClick={() => {
                  setSelectedDenef(each.id);
                  setNewName(each.name);
                  setNewEmail(each.email);
                  setUpdateOpen(true);
                }}
              />
            );
          })}
        </div>
      </div>
      <Modal isOpen={creatOpen} onRequestClose={closeModal} centered={true}>
        <div style={{ display: "flex", justifyContent: "flex-end", margin: "2px" }}>
          <Button
            style={{ border: "none" }}
            onClick={() => {
              closeModal();
            }}
          >
            <CloseOutlined />
          </Button>
        </div>
        <div style={styles.popupDiv}>
          <text style={{ fontSize: "1.125rem", flex: 1, marginBottom: "10px", fontWeight: 500 }}>
            Create recipient
          </text>
          <text style={styles.inputField}>Name</text>
          <Input
            placeholder="Name"
            value={newName}
            onChange={(e) => {
              setNewName(e.target.value);
            }}
            style={styles.inputField}
          />
          <text style={styles.inputField}>Email</text>
          <Input
            placeholder="Email"
            value={newEmail}
            onChange={(e) => {
              setNewEmail(e.target.value);
            }}
            style={styles.inputField}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "20px",
            paddingLeft: "20px"
          }}
        >
          <Button
            style={{
              ...styles.button,
              color: "black"
            }}
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{
              ...styles.button,
              color: "white"
            }}
            onClick={() => {
              addNewBeneficiary();
              closeModal();
            }}
          >
            Create
          </Button>
        </div>
      </Modal>
      <Modal isOpen={updateOpen} onRequestClose={closeModal} centered={true}>
        <div style={{ display: "flex", justifyContent: "flex-end", margin: "2px" }}>
          <Button
            style={{ border: "none" }}
            onClick={() => {
              closeModal();
            }}
          >
            <CloseOutlined />
          </Button>
        </div>
        <div style={styles.popupDiv}>
          <text style={{ fontSize: "1.125rem", flex: 1, marginBottom: "10px", fontWeight: 500 }}>
            Update beneficiary({beneficiaries[selectedBenef] && beneficiaries[selectedBenef].name})
          </text>
          <text style={styles.inputField}>Name</text>
          <Input
            placeholder="Basic usage"
            value={newName}
            onChange={(e) => {
              setNewName(e.target.value);
            }}
            style={styles.inputField}
          />
          <text style={styles.inputField}>Email</text>
          <Input
            placeholder="Basic usage"
            value={newEmail}
            onChange={(e) => {
              setNewEmail(e.target.value);
            }}
            style={styles.inputField}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "20px", paddingLeft: "20px" }}>
          <Button
            style={{
              ...styles.button,
              color: "black"
            }}
            type="primary"
            danger
          >
            <DeleteOutlined style={{ color: "white" }} />
          </Button>
          <Button
            style={{
              ...styles.button,
              color: "black"
            }}
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{
              ...styles.button,
              color: "white"
            }}
            onClick={() => {
              updateBeneficiary();
              closeModal();
            }}
          >
            Update beneficiary
          </Button>
        </div>
      </Modal>
    </div>
  );
};
const styles = {
  button: {
    fontSize: "0.875rem",
    fontWeight: 500,
    borderRadius: "5px",
    margin: "1rem 0rem 0rem 1rem"
  },
  inputField: {
    margin: "0.5rem 0rem 0.5rem 0rem",
    borderRadius: "5px"
  },
  popupDiv: {
    paddingRight: "20px",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column"
  }
};
export const Beneficiaries = boundError(BeneficiariesRaw);
