import React, { useState, useEffect } from "react";
import { Button, Divider, Spin, Modal as Modal2 } from "antd";
import { boundError, showError, showSuccess } from "../../utils";
import Modal from "react-modal";
import Big from "big.js";
import date from "date-and-time";
import StakingServices from "../../services/stakingServices";
import Emitter, { EmitterEvents } from "../../utils/eventEmitter";
import { KeyValue2, KeyValue4 } from "../SharedComponents";
import { translateStakingStatus } from "../../constants/stakingStatus";
import { AccountsSelector } from "../AccountsSelector";
import { formatAmount, getDecimalScale, getAmountSymbol } from "../../utils";
import NumberFormat from "react-number-format";
import { sleep } from "../../utils";

const AddMoreAmountStakingModalRaw = ({ visible, onRequestClose, closeModal, staking, onAddMoreAmountSuccess }) => {
  const stakingServices = StakingServices.getInstance();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const [errorAccountMsg, setErrorAccountMsg] = useState("");
  const [errorAmountMsg, setErrorAmountMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  useEffect(() => {
    setIsOpen(visible);
  }, [visible]);

  const getStakingId = () => {
    if (staking?.id) {
      return "******" + staking?.id.substr(-5);
    }
    return "";
  };

  const getStakingAmount = () => {
    if (!isNaN(staking?.amount)) {
      return formatAmount(staking?.amount, staking?.currency) + " " + staking?.currency;
    }

    return "";
  };

  const getStakingInterestAmount = () => {
    if (!isNaN(staking?.interestAmount)) {
      return formatAmount(staking?.interestAmount, staking?.currency) + " " + staking?.currency;
    }

    return "";
  };

  const getAnnualInterestRate = () => {
    if (!isNaN(staking?.annualInterestRate)) {
      return Big(staking?.annualInterestRate).mul(100).toFixed(2) + " %";
    }

    return "";
  };

  const getTermStartAt = () => {
    if (staking?.termStartAt) {
      return date.format(new Date(staking?.termStartAt), "YYYY-MM-DD", true);
    }

    return "";
  };

  const getTermEndAt = () => {
    if (staking?.termEndAt) {
      return date.format(new Date(staking?.termEndAt), "YYYY-MM-DD", true);
    }

    return "";
  };

  const getRoomRemain = () => {
    let roomRemain = Big(0);

    if (!isNaN(staking?.productRoomRemain)) {
      roomRemain = Big(staking?.productRoomRemain);
    }

    if (!isNaN(staking?.individualRoomLimit)) {
      const individualRoomLimit = Big(staking?.individualRoomLimit);
      if (individualRoomLimit.gt(0)) {
        const individualRoomRemain = individualRoomLimit.minus(staking?.amount);

        if (individualRoomRemain.lte(roomRemain)) {
          roomRemain = individualRoomRemain;
        }
      }
    }

    if (roomRemain.gt(0)) {
      return formatAmount(roomRemain.toFixed(), staking?.currency) + " " + staking?.currency;
    }

    return "";
  };

  const getDepositAmount = () => {
    if (amount != "" && !isNaN(amount)) {
      return formatAmount(amount, staking?.currency) + " " + staking?.currency;
    }
    return "";
  };

  const getSelectedAccountStr = () => {
    if (selectedAccount != null) {
      let str = "";

      if (selectedAccount?.type) {
        str = selectedAccount?.type;
      }
      if (selectedAccount?.publicId) {
        str = str + (str != "" ? ":" : "") + selectedAccount?.publicId;
      }
      if (selectedAccount?.amount) {
        str = str + (str != "" ? " -- " : "") + formatAmount(selectedAccount?.amount, selectedAccount?.currency);
      }
      if (selectedAccount?.currency) {
        str = str + (str != "" ? " " : "") + selectedAccount?.currency;
      }

      return str;
    }

    return "";
  };

  const openConfirmModal = () => {
    let hasErrors = false;
    if (!selectedAccount) {
      setErrorAccountMsg("Please select an account");
      hasErrors = true;
    }

    if (isNaN(amount) || Number(amount <= 0)) {
      setErrorAmountMsg("Please enter a valid staking amount");
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    if (closeModal) {
      closeModal();
    }

    setConfirmVisible(true);
  };

  const confirmAddMore = async () => {
    try {
      let hasErrors = false;
      if (!selectedAccount) {
        setErrorAccountMsg("Please select an account");
        hasErrors = true;
      }

      if (isNaN(amount) || Number(amount <= 0)) {
        setErrorAmountMsg("Please enter a valid staking amount");
        hasErrors = true;
      }

      if (hasErrors) {
        return;
      }

      setConfirmLoading(true);
      await stakingServices.addAmount(staking?.id, selectedAccount?.id, amount);
      showSuccess("Amount has been added successfully!");

      setConfirmLoading(false);
      setConfirmVisible(false);

      setSelectedAccount(null);
      setAmount("");

      if (onAddMoreAmountSuccess) {
        onAddMoreAmountSuccess();
      }

      sleep(1000).then(() => {
        Emitter.emit(EmitterEvents.REFRESH_STAKING_LIST);
      });
      closeModal();
    } catch (e) {
      if (e.includes("amount must be greater than or equal to 1")) {
        showError("Staking amount must be greater or equal to 1 AXC");
      } else {
        showError(e);
      }

      closeModal();

      setConfirmLoading(false);
    }
  };

  const resetErrors = () => {
    setErrorAmountMsg("");
    setErrorAccountMsg("");
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 1999
          },
          content: {
            margin: "auto",
            maxWidth: 500,
            maxHeight: 800,
            height: "100vh",
            overflow: "auto",
            padding: 0,
            backgroundColor: "transparent",
            border: "none",
            paddingTop: 20,
            paddingBottom: 20
          }
        }}
      >
        <Spin spinning={loading}>
          <div style={{ backgroundColor: "white", borderRadius: 21 }}>
            <div className="stakings__product-modal-header">
              <h1>{staking?.name}</h1>
              <p>{staking?.longDesc}</p>
            </div>
            <div style={{ padding: 30, paddingBottom: 0 }}>
              <KeyValue2 keyName={"Principal Amount"} value={getStakingAmount()} />
              <KeyValue2 keyName={"Total Rewards Earned"} value={getStakingInterestAmount()} />
              <KeyValue2 keyName={"Annual Percentage Rewards"} value={getAnnualInterestRate()} />
              <KeyValue2 keyName={"Status"} value={translateStakingStatus(staking?.status)} />
              <KeyValue2 keyName={"Term End"} value={getTermEndAt()} />

              {/* <KeyValue2 keyName={"Room Remain"} value={getRoomRemain()} /> */}

              <Divider />
              <p className="margin-none" style={{ fontWeight: "500", fontSize: 12, minWidth: 240, color: "#B1B5C3" }}>
                {"Select account"}
              </p>
              <AccountsSelector
                onAccountSelected={(account) => {
                  setSelectedAccount(account);
                  resetErrors();
                }}
                selectedAccount={selectedAccount}
                currency={staking?.currency}
                placeholder={"Please select account to transfer from"}
              />
              {errorAccountMsg !== "" && (
                <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorAccountMsg}</p>
              )}

              <p
                className="margin-none"
                style={{ fontWeight: "500", fontSize: 12, minWidth: 240, marginTop: 10, color: "#B1B5C3" }}
              >
                {"Enter staking amount"}
              </p>
              <div style={{ display: "flex" }}>
                <NumberFormat
                  thousandSeparator={true}
                  value={amount}
                  placeholder={"0.00"}
                  decimalScale={getDecimalScale(staking?.currency)}
                  prefix={getAmountSymbol(staking?.currency)}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    setAmount(value);

                    resetErrors();
                  }}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "12px",
                    border: "2px solid #B1B5C4",
                    padding: "10px 20px",
                    maxHeight: 46,
                    marginRight: 10,
                    fontSize: 16
                  }}
                />
                <p
                  style={{
                    width: "100px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "12px",
                    border: "2px solid #B1B5C4",
                    padding: "10px 20px",
                    maxHeight: 46,
                    justifyContent: "center",
                    background: "#F8F9FA",
                    fontSize: 16,
                    margin: 0
                  }}
                >
                  {staking?.currency}
                </p>
              </div>

              {errorAmountMsg !== "" && (
                <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorAmountMsg}</p>
              )}
              <div></div>
              <Divider />
              <div
                className="row"
                style={{
                  paddingBottom: 20,
                  alignItems: "center",
                  justifyContent: "end"
                }}
              >
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #3b82f6",
                    borderRadius: 10,
                    color: "#3b82f6",
                    minWidth: "75px",
                    fontSize: "13px"
                  }}
                  onClick={() => {
                    setErrorAccountMsg("");
                    setErrorAmountMsg("");
                    setAmount("");
                    setSelectedAccount(null);
                    closeModal();
                  }}
                >
                  Close
                </Button>
                <Button
                  type="primary"
                  style={{ borderRadius: 12, marginLeft: 10, minWidth: "75px", fontSize: "13px" }}
                  onClick={openConfirmModal}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal2
        title="Confirm information"
        visible={confirmVisible}
        onOk={confirmAddMore}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setConfirmVisible(false);
        }}
      >
        <KeyValue2 keyName="Staking information: " value={""} />
        <KeyValue4 keyName={"Principal Amount"} value={getStakingAmount()} />
        <KeyValue4 keyName={"Total Rewards Earned"} value={getStakingInterestAmount()} />
        <KeyValue4 keyName={"Annual Percentage Rewards"} value={getAnnualInterestRate()} />
        <KeyValue4 keyName={"Status"} value={translateStakingStatus(staking?.status)} />
        <KeyValue4 keyName={"Term End"} value={getTermEndAt()} />

        {/* <KeyValue4 keyName={"Room Remain"} value={getRoomRemain()} /> */}
        <Divider />
        <KeyValue2 keyName="Deduct Account: " value={""} />
        <KeyValue4 keyName="Account" value={getSelectedAccountStr()} />
        <KeyValue4 keyName="Amount" value={getDepositAmount()} />
      </Modal2>
    </>
  );
};

export const AddMoreAmountStakingModal = boundError(AddMoreAmountStakingModalRaw);
