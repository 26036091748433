import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { isMobile } from "react-device-detect";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PDFviewer({ pdf }) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} scale={isMobile ? 0.5 : 1.5} pageNumber={index + 1} />
      ))}
    </Document>
  );
}
