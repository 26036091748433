import React, { useState } from "react";
import ReactCodeInput from "react-code-input";
import { Button, Row, Col } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { boundError } from "../../utils";

function MFASubmitRaw({ onCancel, onConfirm, okButtonText, okButtonBackgroundColor, error, setError }) {
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const onCodeChange = (code) => {
    setCodeError("");
    setError("");
    setCode(code);
  };
  const onVerify = () => {
    setCodeError("");
    if (code.length < 6) {
      return setCodeError("Please enter the complete security code.");
    }
    onConfirm(code);
  };
  return (
    <div
      style={{
        textAlign: "center",
        width: "fit-content",
        margin: "0 auto"
      }}
    >
      <div className="setup-totp-title">Please enter your 6-digit security code.</div>
      {(codeError || error) && (
        <div className="confirm-login__error-container" style={{ marginBottom: 10 }}>
          <WarningFilled style={{ color: "red" }} />
          {codeError || error}
        </div>
      )}
      <ReactCodeInput type="number" fields={6} value={code} onChange={onCodeChange} />

      <Row
        gutter={24}
        style={{
          marginTop: 30
        }}
        justify="space-between"
      >
        <Col flex={1}>
          <Button htmlType="submit" onClick={onCancel} htmlType="submit" block>
            Cancel
          </Button>
        </Col>
        <Col flex={1}>
          <Button
            htmlType="submit"
            type="primary"
            onClick={onVerify}
            block
            style={{
              background: okButtonBackgroundColor ? okButtonBackgroundColor : "var(--axia-blue)",
              borderColor: okButtonBackgroundColor ? okButtonBackgroundColor : "var(--axia-blue)"
            }}
          >
            {okButtonText ? okButtonText : "Verify"}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export const MFASubmit = boundError(MFASubmitRaw);
