import {
  getUserProfileApi,
  updatePasswordApi,
  updateUserSettingsApi,
  awsSignOut,
  getUserAccountsApi,
  getERC20AXCWalletsApi,
  fetchBeneficiariesApi,
  createBeneficiariesApi,
  createWireTransferBeneficiaryApi,
  getUserRegistrationDataApi,
  removeBeneficiaryApi,
  getUserSettingsApi,
  updateBeneficiariesApi,
  getUserSpendableApi,
  getConversionRateApi
} from "../apis";
import Emitter, { EmitterEvents } from "../utils/eventEmitter";
import { USER_TYPE } from "../constants/userType";
import Big from "big.js";
import { isValidForFeature, FEATURE_NAMES } from "../utils";

export default class UserServices {
  static userServices;

  accountsContextBridge() {
    return {
      getAccounts: async () => {
        return new Promise((res, rej) => {
          Emitter.emit(EmitterEvents.GET_ACCOUNTS, (accounts) => {
            res(accounts);
          });
        });
      },
      setAccounts: async (accounts) => {
        return new Promise((res, rej) => {
          Emitter.emit(EmitterEvents.SET_ACCOUNTS, {
            data: accounts,
            cb: () => {
              res();
            }
          });
        });
      }
    };
  }

  userContextBridge() {
    return {
      getUser: async () => {
        return new Promise((res, rej) => {
          Emitter.emit(EmitterEvents.GET_USER, (user) => {
            res(user);
          });
        });
      },
      setUser: async (user) => {
        return new Promise((res, rej) => {
          Emitter.emit(EmitterEvents.SET_USER, {
            data: user,
            cb: () => {
              res();
            }
          });
        });
      }
    };
  }

  static getInstance() {
    if (!this.userServices) {
      this.userServices = new UserServices();

      Emitter.on(EmitterEvents.REFRESH_STAKING_LIST, () => {
        this.userServices.fetchUserAccounts();
      });
    }

    return this.userServices;
  }

  checkUserRegistrationStatus = async () => {
    // get user onboarding states
    const { registrationCompleted, userType, taxIdStatus, businessRecordsStatus, businessAddressStatus, features } =
      await getUserRegistrationDataApi();

    const isValidForOnboarding = isValidForFeature({
      user: { features },
      featureName: FEATURE_NAMES.ENABLE_ONBOARDING
    });

    let navigateTo = "/";
    // if user has completed onboarding, redirect to accounts
    if (registrationCompleted) {
      navigateTo = "/accounts";
    }

    // redirect to individual onboarding page if current user type is individual
    else if (userType == USER_TYPE.INDIVIDUAL) {
      if (!isValidForOnboarding) {
        navigateTo = "/onboarding/disabled";
      } else {
        navigateTo = "/onboarding/individual";
      }
    }

    // redirect to individual onboarding page if current user type is individual
    else if (userType == USER_TYPE.BUSINESS) {
      if (!isValidForOnboarding) {
        navigateTo = "/onboarding/disabled";
      } else {
        navigateTo = "/onboarding/business";
      }
    }

    // redirect to user type select page if current user type is unknown
    else if (userType == USER_TYPE.UNKNOWN) {
      if (!isValidForOnboarding) {
        navigateTo = "/onboarding/disabled";
      } else {
        navigateTo = "/onboarding/user-type-select";
      }
    }

    return { registrationCompleted, userType, navigateTo, taxIdStatus, businessRecordsStatus, businessAddressStatus };
  };

  getUserProfile = async () => {
    try {
      const result = await getUserProfileApi();
      await this.userContextBridge().setUser(result);
      return result;
    } catch (err) {
      if (err?.user) {
        await this.userContextBridge().setUser(err.user);
      }
      throw err;
    }
  };

  updatePassword = async (oldPassword, newPassword) => {
    return await updatePasswordApi(oldPassword, newPassword);
  };

  getUserSettings = async () => {
    return await getUserSettingsApi();
  };

  updateUserSettings = async (settings) => {
    return await updateUserSettingsApi(settings);
  };

  appSignOut = async () => {
    try {
      await awsSignOut();
      window.open("/", "_self");
    } catch (err) {
      console.log("error signing out: ", err);
    }
  };

  getUserSpendableAmount = async () => {
    const conversionRate = await getConversionRateApi("AXC", "USD");
    const axcToUsdRate = new Big(conversionRate.conversionRate ?? 1);

    const userSpendable = await getUserSpendableApi();
    const spendableAmountInAxc = new Big(userSpendable?.spendableAmountInAxc ?? 0);
    const spendableAmountInUsd = new Big(userSpendable?.spendableAmountInUsd ?? 0);

    const temp = spendableAmountInAxc.times(axcToUsdRate);
    if (temp.gt(spendableAmountInUsd)) {
      return {
        spendableAmountInAxc: spendableAmountInUsd.div(axcToUsdRate),
        spendableAmountInUsd
      };
    } else {
      return {
        spendableAmountInAxc,
        spendableAmountInUsd: temp
      };
    }
  };

  fetchUserAccounts = async () => {
    const result = await getUserAccountsApi();
    let accounts = result?.accounts;
    if (accounts) {
      await this.accountsContextBridge().setAccounts(accounts);
      return result?.accounts;
    }
  };

  fetchUserERC20AXCWalletsApi = async () => {
    return getERC20AXCWalletsApi();
  };

  getAccountById = (accountId, accounts) => {
    const result = accounts.filter((account) => account.id === accountId);
    if (result.length > 0) {
      return result[0];
    }
  };

  getCardById = (cardId, cards) => {};

  fetchBeneficiaries = async (type) => {
    return await fetchBeneficiariesApi(type);
  };

  createBeneficiary = async (name, email, type) => {
    return await createBeneficiariesApi(name, email, type);
  };

  updateBeneficiary = async (id, name, email, type, updateData) => {
    return await updateBeneficiariesApi(id, name, email, type, updateData);
  };

  removeBeneficiary = async (id) => {
    return await removeBeneficiaryApi(id);
  };

  createWireTransferBeneficiary = async ({
    alias,
    currency,
    beneficiaryBankName,
    beneficiaryBankAddress,
    beneficiaryBankSwiftOrABA,
    beneficiaryName,
    beneficiaryAddress,
    beneficiaryIBAN,
    intermediaryBankName,
    intermediaryBankAddress,
    intermediaryBankSwiftOrABA,
    instructionsAndReference
  }) => {
    return await createWireTransferBeneficiaryApi({
      alias,
      currency,
      beneficiaryBankName,
      beneficiaryBankAddress,
      beneficiaryBankSwiftOrABA,
      beneficiaryName,
      beneficiaryAddress,
      beneficiaryIBAN,
      intermediaryBankName,
      intermediaryBankAddress,
      intermediaryBankSwiftOrABA,
      instructionsAndReference
    });
  };
}
