import React, { useState } from "react";
import { Input, Spin } from "antd";
import { boundError, showError, showSuccess } from "../utils";
import { ProfilePage, ProfilePageHead } from "../components";
import { SimpleButton } from "../components";
import UserServices from "../services/userServices";

const ChangePasswordRaw = () => {
  const userServices = UserServices.getInstance();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [errorOldPasswordMsg, setErrorOldPasswordMsg] = useState("");
  const [errorNewPasswordMsg, setErrorNewPasswordMsg] = useState("");
  const [errorConfirmPasswordMsg, setErrorConfirmPasswordMsg] = useState("");

  const onSave = async () => {
    const isValidPassword = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/g).test(newPassword);
    if (!oldPassword) {
      setErrorOldPasswordMsg("Please provide your old password!");
      return;
    }

    if (!newPassword) {
      setErrorNewPasswordMsg("Please enter a valid new password!");
      return;
    }

    if (!isValidPassword) {
      setErrorNewPasswordMsg(
        "Passwords must be at least 8 characters, including at least one upper case letter, one lower case letter, and one numeric digit."
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorConfirmPasswordMsg("Your confirmed password does not match the new password!");
      return;
    }

    if (errorOldPasswordMsg || errorNewPasswordMsg || errorConfirmPasswordMsg) {
      return;
    }

    try {
      setLoading(true);
      const result = await userServices.updatePassword(oldPassword, newPassword);

      if (result === "SUCCESS") {
        showSuccess("Password has been updated successfully");
        setLoading(false);
        return;
      }

      setLoading(false);
      showError("Update password failed! Please try again later!");
    } catch (e) {
      if (e?.message) {
        if (e?.message === "Incorrect username or password.") {
          showError("The old password entered does not match our records, please double-check and try again.");
        } else {
          showError(e);
        }
      } else {
        showError("Update password failed! Please try again later!");
      }

      setLoading(false);
    }
  };

  const resetErrorMsg = () => {
    setErrorOldPasswordMsg("");
    setErrorNewPasswordMsg("");
    setErrorConfirmPasswordMsg("");
  };

  return (
    <ProfilePage>
      <Spin spinning={loading}>
        <ProfilePageHead title="Change Password" description="Change your login password" />
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Field
            title="Old password"
            value={oldPassword}
            onChange={(event) => {
              setOldPassword(event.target.value);
              resetErrorMsg();
            }}
            placeholder="Enter your old password"
          />
          {errorOldPasswordMsg !== "" && (
            <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorOldPasswordMsg}</p>
          )}
          <div style={{ height: 10 }} />
          <Field
            title="New password"
            value={newPassword}
            onChange={(event) => {
              setNewPassword(event.target.value);
              resetErrorMsg();
            }}
            placeholder="Enter your new password"
          />
          {errorNewPasswordMsg !== "" && (
            <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorNewPasswordMsg}</p>
          )}
          <Field
            title="Confirm password"
            value={confirmPassword}
            onChange={(event) => {
              setConfirmPassword(event.target.value);
              resetErrorMsg();
            }}
            placeholder="Confirm your password"
          />
          {errorConfirmPasswordMsg !== "" && (
            <p style={{ color: "red", fontWeight: "600", textAlign: "left" }}>{errorConfirmPasswordMsg}</p>
          )}
        </div>

        <div className="row" style={{ justifyContent: "flex-end", borderTop: "1px solid #D7D7D9", paddingTop: 20 }}>
          <SimpleButton onClick={onSave}>Save</SimpleButton>
        </div>
      </Spin>
    </ProfilePage>
  );
};

const Field = ({ title, value, onChange, placeholder }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
      <p
        className="margin-none"
        style={{ paddingTop: 20, fontWeight: "500", fontSize: 14, color: "#B1B5C4", marginBottom: 5 }}
      >
        {title}
      </p>
      <Input.Password
        value={value}
        placeholder={placeholder}
        className="change-password-input-field"
        onChange={onChange}
      />
    </div>
  );
};

export const ChangePassword = boundError(ChangePasswordRaw);
