import React, { useState, useRef, useEffect, useCallback } from "react";
import { ExclamationCircleOutlined, LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Spin, Form, Input, Row, Space, Upload, Typography, Select, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { uploadPendingDocument, downloadDocumentById, updateUserDocumentInfo, doGetCardList } from "../../apis";
import { boundError, showSuccess, showError } from "../../utils";
import { CHANGE_OF_ID_ADDRESS, DOCUMENT_TYPE } from "../../constants/documentType";
import * as pdfHelper from "../../utils/pdfHelper";
import { ConvertImgToPdfModal } from "../SharedComponents/ConvertImgToPdfModal";

const ChangeOfIdAddressRaw = ({ rawData = null }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const [id, setId] = useState(undefined);
  const [status, setStatus] = useState();
  const [initialValues, setInitialValues] = useState({});
  const [showUploadDocModal, setShowUploadDocModal] = useState(false);

  const [disableForm, setDisableForm] = useState(false);

  const navigate = useNavigate();

  const isMounted = useRef(true);
  const idFileList = useRef([]);

  useEffect(() => {
    if (rawData !== null) {
      setId(rawData?.id ?? null);
      setStatus(rawData?.status ?? "INCOMPLETE");
      setDisableForm(rawData.status !== "INCOMPLETE");
      setInitialValues({
        changeOfId: rawData?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ID]?.documentId
          ? [
              {
                id: rawData?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ID]?.documentId,
                name: rawData?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ID]?.documentName,
                status: "done"
              }
            ]
          : [],
        changeOfAddress: rawData?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ADDRESS]?.documentId
          ? [
              {
                id: rawData?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ADDRESS]?.documentId,
                name: rawData?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ADDRESS]?.documentName,
                status: "done"
              }
            ]
          : []
      });
    }
    setLoading(false);
  }, [rawData]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const StatusWarningMsg = () => {
    let msg;
    switch (status) {
      case "SUBMITTED":
        msg = "Your change request has been submitted for review";
        break;
      case "COMPLETED":
        msg = "Your change request has been completed";
        break;
      case "REJECTED":
        msg = "Your change request has been rejected";
        break;
      case "CANCELED":
        msg = "Your change request has been canceled";
        break;
    }
    if (msg) {
      return <Alert message={msg} type="info" />;
    }
  };

  const onFinish = async ({ cardId, memo }) => {
    if (cardId) cardId = cardId.trim();
    if (memo) memo = memo.trim();
    setError(null);
    setLoading(true);
    try {
      await updateUserDocumentInfo({
        id,
        documentType: CHANGE_OF_ID_ADDRESS.CHANGE_OF_ID_ADDRESS,
        infoToUpdate: { cardId },
        memo,
        submit: true
      });
      showSuccess("Successfully submitted your request for review");
      goBack();
    } catch (err) {
      showError(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const onSaveAndExit = async () => {
    try {
      let { cardId, memo } = form.getFieldsValue(["cardId", "memo"]);
      if (cardId) cardId = cardId.trim();
      if (memo) memo = memo.trim();
      await updateUserDocumentInfo({
        id,
        infoToUpdate: { cardId },
        memo,
        documentType: CHANGE_OF_ID_ADDRESS.CHANGE_OF_ID_ADDRESS,
        submit: false,
        multipleDocs: {}
      });
      showSuccess("Successfully saved your info");
      goBack();
    } catch (err) {
      showError(err);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.slice(-1);
    }
    return e?.fileList?.slice(-1);
  };

  const createCustomUploadRequestForAddress =
    (uploader) =>
    async ({ file, onError, onSuccess }) => {
      try {
        const result = await uploader({ id, documentType: DOCUMENT_TYPE.CHANGE_OF_ADDRESS, file });
        if (result && result.length > 0) {
          setId(result[0].id);
        }
        onSuccess();
      } catch (err) {
        showError(err);
        onError(err);
      }
    };

  const createCustomFileListItemRenderer = (onClickHandler) => (originalNode) => {
    return (
      <Button type="link" onClick={onClickHandler} style={{ padding: 0 }}>
        {originalNode}
      </Button>
    );
  };

  const downloadDocumentForId = async () => {
    if (initialValues.changeOfId && initialValues.changeOfId.length !== 0) {
      const { id } = initialValues.changeOfId[0];
      await downloadDocumentById(id);
    }
  };

  const downloadDocumentForAddress = async () => {
    if (initialValues.changeOfAddress && initialValues.changeOfAddress.length !== 0) {
      const { id } = initialValues.changeOfAddress[0];
      await downloadDocumentById(id);
    }
  };

  const goBack = () => {
    navigate("/user-document");
  };

  const handleUploadIdV2 = async (file) => {
    try {
      setLoading(true);
      const data = await uploadPendingDocument({ id, documentType: DOCUMENT_TYPE.CHANGE_OF_ID, file });
      setId(data[0].id);
      setInitialValues({
        changeOfId:
          data.length > 0 && data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ID]?.documentId
            ? [
                {
                  id: data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ID]?.documentId,
                  name: data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ID]?.documentName,
                  status: "done"
                }
              ]
            : []
      });
      setLoading(false);
    } catch (err) {
      showError(err);
    }
  };

  const handleUploadId = async ({ file, onError, onSuccess }) => {
    idFileList.current.push(file);

    if (idFileList.current.length > 2) {
      showError("Please either select 1 or 2 images files");
      idFileList.current = [];
      onError();
      return;
    }

    if (idFileList.current.length == 1) {
      setTimeout(async () => {
        if (idFileList.current.length == 1) {
          const fileArray = idFileList.current ? Array.from(idFileList.current) : [];
          const fileToImagePromises = fileArray.map(pdfHelper.fileToImageURL);
          const files = await Promise.all(fileToImagePromises);
          const file = await pdfHelper.generatePdfFromImages(files, "change_of_id");
          const data = await uploadPendingDocument({ id, documentType: DOCUMENT_TYPE.CHANGE_OF_ID, file });
          setId(data[0].id);
          onSuccess();
          setInitialValues({
            changeOfId:
              data.length > 0 && data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ID]?.documentId
                ? [
                    {
                      id: data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ID]?.documentId,
                      name: data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ID]?.documentName,
                      status: "done"
                    }
                  ]
                : []
          });
          idFileList.current = [];
          return;
        }
      }, 1000);
    }

    setTimeout(async () => {
      if (idFileList.current.length == 2) {
        const fileArray = idFileList.current ? Array.from(idFileList.current) : [];
        const fileToImagePromises = fileArray.map(pdfHelper.fileToImageURL);
        const files = await Promise.all(fileToImagePromises);
        const file = await pdfHelper.generatePdfFromImages(files, "change_of_id");
        const data = await uploadPendingDocument({ id, documentType: DOCUMENT_TYPE.CHANGE_OF_ID, file });
        setId(data[0].id);
        onSuccess();
        setInitialValues({
          changeOfId:
            data.length > 0 && data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ID]?.documentId
              ? [
                  {
                    id: data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ID]?.documentId,
                    name: data[0]?.multipleDocuments?.[DOCUMENT_TYPE.CHANGE_OF_ID]?.documentName,
                    status: "done"
                  }
                ]
              : []
        });
        idFileList.current = [];
        return;
      }
    }, 1000);
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Spin spinning={loading}>
        <Row justify="start">
          <Col sm={24} md={18}>
            {disableForm && (
              <div style={{ marginBottom: 20 }}>
                <StatusWarningMsg />
              </div>
            )}

            <Form form={form} layout="vertical" name="change_of_id" onFinish={onFinish} initialValues={initialValues}>
              <Form.Item
                label={
                  <>
                    Change of ID (<b>front&nbsp;</b> and <b>&nbsp;back&nbsp;</b> side of an government-issued colour ID)
                  </>
                }
                name="changeOfId"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: "Change of ID is required!" }]}
              >
                <Upload
                  // customRequest={createCustomUploadRequestForId(uploadPendingDocument)}
                  // customRequest={handleUploadId}
                  openFileDialogOnClick={false}
                  multiple={true}
                  showUploadList={{ showRemoveIcon: false }}
                  disabled={disableForm}
                  accept="image/*"
                  itemRender={createCustomFileListItemRenderer(downloadDocumentForId)}
                  restrictions={{
                    maxFileSize: 1000000
                  }}
                >
                  <Button
                    icon={<UploadOutlined />}
                    disabled={disableForm}
                    onClick={() => {
                      setShowUploadDocModal(true);
                    }}
                  >
                    Upload file(s)
                  </Button>
                  <Tooltip title="Please select up to 2 image file(s). Png, img, or jpg files are acceptable.">
                    <ExclamationCircleOutlined style={{ marginLeft: 5 }} />
                  </Tooltip>
                </Upload>
              </Form.Item>
              <Form.Item
                label="Change of address (issued within the last 3 months)"
                name="changeOfAddress"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: "Change of Address is required!" }]}
              >
                <Upload
                  customRequest={createCustomUploadRequestForAddress(uploadPendingDocument)}
                  multiple={false}
                  showUploadList={{ showRemoveIcon: false }}
                  disabled={disableForm}
                  accept=".pdf"
                  itemRender={createCustomFileListItemRenderer(downloadDocumentForAddress)}
                  restrictions={{
                    maxFileSize: 1000000
                  }}
                >
                  <Button icon={<UploadOutlined />} disabled={disableForm}>
                    Upload pdf file
                  </Button>
                  <Tooltip title="Please select one pdf file.">
                    <ExclamationCircleOutlined style={{ marginLeft: 5 }} />
                  </Tooltip>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit" disabled={loading || disableForm}>
                    Submit
                  </Button>
                  {disableForm ? (
                    <Button type="primary" onClick={() => goBack()}>
                      Exit
                    </Button>
                  ) : (
                    <Button disabled={loading} onClick={onSaveAndExit}>
                      Save and exit
                    </Button>
                  )}
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <ConvertImgToPdfModal
          visible={showUploadDocModal}
          onCancel={() => setShowUploadDocModal(false)}
          onOk={(file) => {
            handleUploadIdV2(file);
            setShowUploadDocModal(false);
          }}
          fileName={"change_of_id"}
        />
      </Spin>
    </div>
  );
};

export const ChangeOfIdAddress = boundError(ChangeOfIdAddressRaw);
