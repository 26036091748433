import React from "react";
import { Button } from "antd";

import { boundError } from "../../utils";

function ButtonPrimaryRaw({ children, ...rest }) {
  return (
    <Button {...rest} className="acb-button-primary">
      {children}
    </Button>
  );
}
export const ButtonPrimary = boundError(ButtonPrimaryRaw);
