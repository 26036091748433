import React, { useEffect, useState, useContext } from "react";
import { Menu } from "antd";
import { boundError } from "../../utils";
import Icon from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import UserServices from "../../services/userServices";
import { isValidForFeature, FEATURE_NAMES } from "../../utils";
import { UserRegistrationStatusContext, UserContext } from "../../hooks";
import { showError } from "../../utils/messages";
import AccountIcon from "../../resources/icons/menu/account.svg";
import CardIcon from "../../resources/icons/menu/card.svg";
import DepositIcon from "../../resources/icons/menu/deposit.svg";
import ProfileIcon from "../../resources/icons/menu/profile.svg";
import LogoutIcon from "../../resources/icons/menu/logout.svg";
import StakingsIcon from "../../resources/icons/menu/stakings.svg";
import TransferIcon from "../../resources/icons/menu/transfer.svg";
import { USER_STATUS } from "../../constants/userStatus";

const SideMenuRaw = ({ onClick }) => {
  const userServices = UserServices.getInstance();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("/accounts");
  const navigate = useNavigate();
  const [registrationStatus, setRegistrationStatus] = useContext(UserRegistrationStatusContext);
  const [user] = useContext(UserContext);

  const menuStyle = { fontSize: 22, fontWeight: "400", height: 50, borderRadius: "12px" };
  const iconStyle = { fontSize: 22, marginRight: 15 };

  const menus = [
    {
      key: "/accounts",
      title: "Account",
      icon: <Icon style={iconStyle} component={() => <img src={AccountIcon} />} />
    },
    isValidForFeature({ user, featureName: FEATURE_NAMES.MENU_DEPOSIT }) &&
      [USER_STATUS.ACTIVE, USER_STATUS.SUSPENDED].includes(user?.status) && {
        key: "/deposit",
        title: "Deposit",
        icon: <Icon style={iconStyle} component={() => <img src={DepositIcon} />} />
      },
    isValidForFeature({ user, featureName: FEATURE_NAMES.MENU_TRANSFER }) &&
      user?.status == USER_STATUS.ACTIVE && {
        key: "/transfer",
        title: "Transfer",
        icon: <Icon style={iconStyle} component={() => <img src={TransferIcon} />} />
      },
    isValidForFeature({ user, featureName: FEATURE_NAMES.MENU_CARDS }) &&
      user?.status == USER_STATUS.ACTIVE && {
        key: "/cards",
        title: "Cards",
        icon: <Icon style={iconStyle} component={() => <img src={CardIcon} />} />
      },
    isValidForFeature({ user, featureName: FEATURE_NAMES.MENU_STAKINGS }) &&
      user?.status == USER_STATUS.ACTIVE && {
        key: "/stakings",
        title: "Staking",
        icon: <Icon style={iconStyle} component={() => <img src={StakingsIcon} />} />
      },
    isValidForFeature({ user, featureName: FEATURE_NAMES.MENU_GIFT_CARDS }) &&
      user?.status == USER_STATUS.ACTIVE && {
        key: "/gift-cards",
        title: "Gift cards",
        icon: <Icon style={iconStyle} component={() => <img src={CardIcon} />} />
      },
    user?.status == USER_STATUS.ACTIVE && {
      key: "/profile",
      title: "Profile",
      icon: <Icon style={iconStyle} component={() => <img src={ProfileIcon} />} />
    }
  ];

  useEffect(() => {
    if (menus.map((item) => item.key).includes(location.pathname)) {
      setSelectedKey(location.pathname);
    }
  }, [location.pathname]);

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={["1"]}
      selectedKeys={[selectedKey]}
      style={{ backgroundColor: "#007CBD", padding: "30px 20px" }}
      onClick={onClick}
    >
      {menus.map(
        (menu, index) =>
          menu && (
            <Menu.Item
              key={menu.key}
              icon={menu.icon}
              onClick={() => {
                if (registrationStatus?.registrationCompleted) {
                  navigate(menu.key);
                } else {
                  showError("Please complete user registration.");
                }
              }}
              style={menuStyle}
            >
              <span>{menu.title}</span>
            </Menu.Item>
          )
      )}

      <Menu.Divider style={{ borderColor: "rgba(255, 255, 255, 0.25)", margin: "20px 0" }} />

      <Menu.Item
        key="9"
        icon={<Icon style={iconStyle} component={() => <img src={LogoutIcon} />} />}
        onClick={userServices.appSignOut}
        style={menuStyle}
      >
        <span>Log out</span>
      </Menu.Item>
    </Menu>
  );
};

export const SideMenu = boundError(SideMenuRaw);
