import React, { useState, useEffect, useContext } from "react";
import { Button } from "antd";
import { DollarOutlined, FileTextOutlined } from "@ant-design/icons";
import { boundError } from "../utils";
import CardImage from "../resources/images/card.png";
import { StakingsProductsRow, UserStakingsSection } from "../components/Stakings";
import UserServices from "../services/userServices";
import { AccountsContext, StakingProductsContext } from "../hooks";
import { isValidForFeature, FEATURE_NAMES } from "../utils";
import { UserContext } from "../hooks";

const StakingsRaw = () => {
  const userServices = UserServices.getInstance();
  const [products] = useContext(StakingProductsContext);
  const [user] = useContext(UserContext);

  useEffect(() => {
    userServices.fetchUserAccounts();
  }, []);

  return (
    <div style={{ paddingLeft: 40, paddingRight: 40 }}>
      {isValidForFeature({ user, featureName: FEATURE_NAMES.STAKINGS_PRODUCTS }) && <StakingsProductsRow />}

      <div className="row page-title" style={{ paddingBottom: 0, paddingLeft: 20 }}>
        Staking
      </div>
      <UserStakingsSection />
      <div style={{ height: 80 }} />
    </div>
  );
};

export const Stakings = boundError(StakingsRaw);
