import React, { useState, useEffect } from "react";
import { Divider, Spin, Tooltip } from "antd";
import { ButtonPrimary, DropDownSelector, ProfilePage, ProfilePageHead } from "../components";
import {
  boundError,
  isDev,
  upperCaseFirstCharEachForWord,
  removeExtraSpaceChar,
  showError,
  showSuccess
} from "../utils";
import { updateSelfPepStatusAfterRegistration, uploadPendingDocument } from "../apis/";
import noAvatarImg from "../resources/icons/profile/no_avatar.svg";
import UserServices from "../services/userServices";
import { getCountryNameByCode } from "../constants/country";
import moment from "moment";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { UploadAvatarModal } from "../components/SharedComponents/uploadAvatarModal";
import { DOCUMENT_TYPE } from "../constants/documentType";

const ProfileRaw = () => {
  const userServices = UserServices.getInstance();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [initialPepStatus, setInitialPepStatus] = useState(null);
  const [userPepStatus, setUserPepStatus] = useState(null);
  const [updateButtonVisible, setUpdateButtonVisible] = useState(false);
  const [showUploadAvatarPopup, setShowUploadAvatarPopup] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [resync, resyncData] = useState(false);

  const pepTypes = [
    { name: "Nothing to declare", code: "NONE", type: "NONE" },
    { name: "Politically Exposed Person", code: "PEP", type: "PEP" }
  ];

  const initialValues = {
    pepStatus: initialPepStatus
  };

  useEffect(() => {
    fetchProfile();
  }, [resync]);

  useEffect(() => {}, [initialValues]);

  const fetchProfile = async () => {
    setLoading(true);
    const result = await userServices.getUserProfile();
    if (result) {
      setProfile(result);
      setUserPepStatus(result?.pepStatus);
      setInitialPepStatus(result?.pepStatus);
      setProfilePicture(result?.avatarUrl);
      initialValues.pepStatus = result?.pepStatus;
    }
    setLoading(false);
  };

  const uploadProfilePicture = async (file) => {
    setLoading(true);
    try {
      await uploadPendingDocument({
        id: "",
        documentType: DOCUMENT_TYPE.AVATAR,
        file
      });
    } catch (err) {
      showError(err);
    }
    setLoading(false);
  };

  const handleChangePepStatus = (newPepStatus) => {
    if (newPepStatus !== initialPepStatus) {
      setUpdateButtonVisible(true);
    } else {
      setUpdateButtonVisible(false);
    }
    setUserPepStatus(newPepStatus);
  };

  const onUpdatePepStatus = async () => {
    setLoading(true);
    try {
      await updateSelfPepStatusAfterRegistration(userPepStatus);

      showSuccess("Updated PEP status");

      resyncData(!resync);
    } catch (err) {
      showError(err);
    }
    setLoading(false);
    setUpdateButtonVisible(false);
  };

  return (
    <ProfilePage>
      <Spin spinning={loading}>
        <ProfilePageHead title="Personal" description="Your personal details" />

        <div
          className="row flex-direction-to-column"
          style={{ width: "100%", justifyContent: "space-between", marginTop: 5 }}
        >
          <div className="profile-picture-row">
            <img
              src={profilePicture || noAvatarImg}
              style={{ height: 160, width: 160, borderRadius: 80, cursor: "pointer" }}
              alt=""
              onClick={() => setShowUploadAvatarPopup(true)}
            />
            <p
              style={{
                margin: 0,
                fontWeight: 600,
                color: "#45A5E7",
                cursor: "pointer"
              }}
              onClick={() => setShowUploadAvatarPopup(true)}
            >
              Change profile photo
            </p>
          </div>

          <div className="profile-info-part-one">
            <div className="row flex-direction-to-column">
              <StaticField title="First Name" value={profile?.firstName ?? ""} />
              <StaticField title="Last Name" value={profile?.lastName ?? ""} />
            </div>
            <div className="row flex-direction-to-column">
              <StaticField title="Email" value={(profile?.email || "").toLowerCase() ?? ""} />
              <StaticField
                title="Date of Birth"
                value={profile?.dateOfBirth ? moment(profile?.dateOfBirth).utc().format("YYYY/MM/DD") : ""}
              />
            </div>
          </div>
        </div>

        <Divider />

        <div className="row flex-direction-to-column">
          <StaticField
            title="Address"
            value={upperCaseFirstCharEachForWord(removeExtraSpaceChar(profile?.addressLine1 ?? ""))}
          />
          <StaticField
            title="Address 2"
            value={upperCaseFirstCharEachForWord(removeExtraSpaceChar(profile?.addressLine2 ?? ""))}
          />
        </div>
        <div className="row flex-direction-to-column">
          <StaticField
            title="City"
            value={upperCaseFirstCharEachForWord(removeExtraSpaceChar(profile?.addressCity ?? ""))}
          />
          <StaticField
            title="State/Province"
            value={upperCaseFirstCharEachForWord(removeExtraSpaceChar(profile?.addressState ?? ""))}
          />
        </div>
        <div className="row flex-direction-to-column">
          <StaticField title="Post Code / Zip Code" value={(profile?.addressPostalCode || "").toUpperCase()} />
          <StaticField title="Country" value={getCountryNameByCode(profile?.addressCountry)} />
        </div>
        <DynamicField
          title={
            <div>
              Politically Exposed Person Status
              <Tooltip
                overlayInnerStyle={{ width: "600px" }}
                title="A PEP (Politically Exposed Person) is an individual who has a high-ranking job in a government or political position, a person who possesses a certain form of political and institutional power. Typically, PEPs include: Politicians, Leaders of government or state, Judges and members of the court, High-ranking members of the Central Bank, Ambassadors, High-ranking officers in the Defence Forces, spouses and children of the people above, close business partners and connections of the people above"
              >
                <QuestionCircleOutlined style={{ marginLeft: 5 }} />
              </Tooltip>
            </div>
          }
        >
          <DropDownSelector
            options={pepTypes}
            settings={{ value: userPepStatus, bordered: false, onSelect: handleChangePepStatus }}
            style={{ width: "100%", textAlign: "start" }}
          />
        </DynamicField>
        {updateButtonVisible && (
          <div style={{ textAlign: "start" }}>
            <ButtonPrimary style={{ textAlign: "start" }} disabled={loading} onClick={onUpdatePepStatus}>
              Update
            </ButtonPrimary>
            <ButtonPrimary
              type="secondary"
              style={{ textAlign: "start", marginLeft: "1rem" }}
              disabled={loading}
              onClick={() => {
                setUpdateButtonVisible(false);
                setUserPepStatus(initialValues.pepStatus);
              }}
            >
              Cancel
            </ButtonPrimary>
          </div>
        )}
        {showUploadAvatarPopup && (
          <UploadAvatarModal
            onCancel={() => setShowUploadAvatarPopup(false)}
            onOk={(pic, preview) => {
              uploadProfilePicture(pic);
              setProfilePicture(preview);
              setShowUploadAvatarPopup(false);
            }}
          />
        )}
      </Spin>
    </ProfilePage>
  );
};

const StaticField = ({ title, value }) => {
  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
      <div className="row" style={{ paddingTop: 20, fontWeight: "500", fontSize: 12, color: "#B1B5C3" }}>
        {title}
      </div>
      <div className="row profile-info-field">{value}</div>
    </div>
  );
};

const DynamicField = ({ title, children }) => {
  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
      <div className="row" style={{ paddingTop: 20, fontWeight: "500", fontSize: 12, color: "#B1B5C3" }}>
        {title}
      </div>
      <div className="row profile-info-field-dynamic">{children}</div>
    </div>
  );
};

export const Profile = boundError(ProfileRaw);
