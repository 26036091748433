import {
  applyStakingApi,
  getUserStakingsApi,
  getStakingByIdApi,
  claimStakingApi,
  getAvailableStakingsProductsApi,
  addAmountApi
} from "../apis";
import Emitter, { EmitterEvents } from "../utils/eventEmitter";

export default class StakingServices {
  static stakingServices;
  stakingProductsContextBridge() {
    return {
      getProducts: async () => {
        return new Promise((res, rej) => {
          Emitter.emit(EmitterEvents.GET_STAKING_PRODUCTS, (accounts) => {
            res(accounts);
          });
        });
      },
      setProducts: async (products) => {
        return new Promise((res, rej) => {
          Emitter.emit(EmitterEvents.SET_STAKING_PRODUCTS, {
            data: products,
            cb: () => {
              res();
            }
          });
        });
      }
    };
  }

  static getInstance() {
    if (!this.stakingServices) {
      this.stakingServices = new StakingServices();
    }

    return this.stakingServices;
  }

  async getAvailableStakingProducts(currency = "AXC", pageNum = 0, limit = 24) {
    const result = await getAvailableStakingsProductsApi(currency, pageNum * limit, limit);
    if (result?.data) {
      if (pageNum === 0) {
        this.stakingProductsContextBridge().setProducts(result?.data);
      } else {
        const prevProducts = await this.stakingProductsContextBridge().getProducts();
        this.stakingProductsContextBridge().setProducts([...prevProducts, ...result?.data]);
      }
    }
  }

  async applyStaking(stakingProductCode, fromAccountId, amount) {
    return await applyStakingApi(stakingProductCode, fromAccountId, amount);
  }

  async claimStaking(stakingId, toAccountId) {
    return await claimStakingApi(stakingId, toAccountId);
  }

  async addAmount(stakingId, fromAccountId, amount) {
    return await addAmountApi(stakingId, fromAccountId, amount);
  }

  async fetchUserStakings(offset, limit, order = "desc") {
    const result = await getUserStakingsApi(offset, limit, order);

    if (result?.data && result?.data?.length > 0) {
      return result?.data;
    }
  }

  async fetchUserStakingById(stakingId, numOfInterestRecords = 90) {
    return await getStakingByIdApi(stakingId, numOfInterestRecords);
  }
}
