import React, { useState } from "react";
import { boundError } from "../utils";
import { ButtonPrimary } from "../components";
import { Modal } from "antd";
import ReactCodeInput from "react-code-input";
import Timer from "../components/SharedComponents/Timer";

const Transaction2faModalRaw = ({ visible, onOk, onCancel, isLoading, email }) => {
  const [code, setCode] = useState("");
  const [finishedCountDown, setFinishedCountDown] = useState(false);
  const [initialMinute, setInitialMinute] = useState(5);

  const style = {
    unclickable: {
      pointerEvents: "none",
      cursor: "default",
      color: "grey",
      textDecoration: "underline"
    }
  };

  const isFinishedCountDown = (bool) => {
    setFinishedCountDown(bool);
  };

  const resentValidationCode = () => {
    onOk();
    setFinishedCountDown(false);
    setInitialMinute(5);
  };
  return (
    <Modal
      title="Two factor authentication"
      visible={visible}
      footer={null}
      centered
      destroyOnClose
      onCancel={() => {
        onCancel();
        setCode("");
      }}
      className="mfa-modal-container"
    >
      <div className="confirm-login-content">
        <h3>Enter 6-digit code from your email</h3>
        <div className="confirm-login__form">
          <ReactCodeInput type="number" fields={6} autoFocus value={code} onChange={setCode} />
          <div style={{ margin: "10px 0px" }}>
            <p style={{ margin: 0, textAlign: "center" }}>A 2FA code has been sent to your email {email}.</p>
            <p style={{ margin: 0, textAlign: "center" }}>Enter the code to complete the transaction.</p>
            {!finishedCountDown && (
              <p style={{ margin: 0, textAlign: "center" }}>
                Code will be expired in{" "}
                <Timer initialMinute={initialMinute} isFinishedCountDown={isFinishedCountDown} />
              </p>
            )}
          </div>
          <ButtonPrimary
            style={{ margin: 0 }}
            onClick={() => {
              onOk(code);
              setCode("");
            }}
          >
            Verify
          </ButtonPrimary>
          <p style={{ margin: 0, textAlign: "center", fontSize: 13 }}>
            <a
              style={finishedCountDown ? { textDecoration: "underline" } : style.unclickable}
              onClick={resentValidationCode}
            >
              Didn't get a verification code?
            </a>
          </p>
        </div>
      </div>
    </Modal>
  );
};

export const Transaction2faModal = boundError(Transaction2faModalRaw);
