import React, { useState, useEffect } from "react";
import { boundError } from "../../utils";
import { Button, Modal, Checkbox } from "antd";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const PopUpNotificationRaw = ({
  content = [<></>],
  title = "Notification",
  notificationId,
  ignoreNotificationInterval = 1,
  ignoreNotificationIntervalUnit = "days",
  ignoreNotificationCheckboxText = "Do not show this message again today",
  toDisplay = () => {
    return false;
  },
  showCheckBox = false,
  showPagination = false,
  PopUpNotificationRef
} = {}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [toggleIgnore, setToggleIgnore] = useState(false);
  const totalPages = content.length - 1;

  const handleClose = () => {
    setIsModalVisible(false);
    if (toggleIgnore) {
      localStorage.setItem(`${notificationId}LastShown`, moment().format("YYYY-MM-DD"));
    }
  };
  const pageUp = () => {
    setPage(page - 1);
  };
  const pageDown = () => {
    setPage(page + 1);
  };

  const showNotification = async () => {
    const flag = await toDisplay();
    if (!flag) return;

    const lastShown = localStorage.getItem(`${notificationId}LastShown`);
    if (moment(lastShown).add(ignoreNotificationInterval, ignoreNotificationIntervalUnit).isSameOrAfter(moment()))
      return;

    setIsModalVisible(true);
  };

  const renderContent = () => {
    if (showPagination) {
      return <>{content[page]}</>;
    } else {
      return <>{content[0]}</>;
    }
  };

  const renderFooter = () => {
    if (showPagination) {
      return [
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          {showCheckBox ? (
            <div>
              <Checkbox
                checked={toggleIgnore}
                onChange={(e) => {
                  setToggleIgnore(e.target.checked);
                }}
              >
                {ignoreNotificationCheckboxText}
              </Checkbox>
            </div>
          ) : (
            <div />
          )}
          <div>
            <Button icon={<LeftOutlined />} style={{ marginRight: "8px" }} onClick={pageUp} disabled={page === 0} />
            {page + 1}/{totalPages + 1}
            <Button icon={<RightOutlined />} onClick={pageDown} disabled={page === totalPages} />
          </div>
        </div>
      ];
    } else {
      return null;
    }
  };

  useEffect(() => {
    showNotification();
    PopUpNotificationRef.current.handleClose = handleClose;
  }, [content, toggleIgnore]);

  return (
    <Modal title={title} visible={isModalVisible} onOk={handleClose} onCancel={handleClose} footer={renderFooter()}>
      {renderContent()}
    </Modal>
  );
};

export const PopUpNotification = boundError(PopUpNotificationRaw);
