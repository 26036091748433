import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { boundError, showError, formatAmount } from "../../utils";
import { getStakingInterestRecords } from "../../apis";
import date from "date-and-time";
import { ListItem1 } from "../SharedComponents";

const StakingInterestRecordsRaw = ({ stakingId, staking }) => {
  const [records, setRecords] = useState(staking?.interestRecords ?? []);
  const [offset, setOffset] = useState(0);
  const limit = 365;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (stakingId) {
      getInterestRecords();
    }
  }, []);

  const getInterestRecords = async () => {
    try {
      setLoading(true);

      const result = await getStakingInterestRecords(stakingId, { offset, limit });

      if (result?.data) {
        setRecords(result?.data);
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 80 }}>
      <div className="row" style={{ width: "100%", justifyContent: "flex-start" }}>
        <p style={{ fontSize: 20, fontWeight: "800" }}>Rewards</p>
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 10,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 50,
            paddingTop: 10,
            paddingBottom: 10
          }}
        >
          {!records || records.length === 0 ? (
            <p className="margin-none">No records</p>
          ) : (
            <div>
              {records.map((record, index) => {
                const getRecordDate = () => {
                  if (record?.date) {
                    return date.format(new Date(record?.date), "YYYY-MM-DD", true);
                  }
                  return "";
                };

                const getRecordInterest = () => {
                  if (record?.dailyRewardAmount) {
                    return formatAmount(record?.dailyRewardAmount, record?.currency);
                  }
                  return "";
                };

                return (
                  <div
                    key={index}
                    style={{
                      borderBottom: records.length - 1 === index ? "none" : "1px solid lightgray"
                    }}
                  >
                    <ListItem1 text1={getRecordDate()} text2={getRecordInterest()} text3={record?.currency} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

export const StakingInterestRecords = boundError(StakingInterestRecordsRaw);
