import { Alert, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { getUserRegistrationDataApi } from "../apis";
import { TaxIdentificationForm } from "../components/Onboarding/TaxIdentificationForm";
import { BusinessRecordForm } from "../components/Onboarding/BusinessRecordForm";
import { BusinessAddressForm } from "../components/Onboarding/BusinessAddressForm";
import { UserIdentityForm } from "../components/Onboarding/UserIdentityForm";
import { BusinessOnboardingMenu } from "../components/Onboarding/BusinessOnboardingMenu";
import { boundError } from "../utils";

const BusinessOnboardingRaw = () => {
  const [userRegistrationStatus, setUserRegistrationStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const reloadUserRegistrationStatus = async () => {
    setError(null);
    setLoading(true);
    try {
      setUserRegistrationStatus(await getUserRegistrationDataApi());
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    await reloadUserRegistrationStatus();
  }, []);

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Alert message={error.response?.data?.message || error.message} type="error" />;
  }

  if (userRegistrationStatus?.registrationCompleted) {
    navigate("/accounts");
  }

  return (
    <Routes>
      <Route path="/" element={<BusinessOnboardingMenu userRegistrationStatus={userRegistrationStatus} />} />
      <Route
        path="/kyc"
        element={
          <UserIdentityForm
            userRegistrationStatus={userRegistrationStatus}
            reloadUserRegistrationStatus={reloadUserRegistrationStatus}
          />
        }
      />
      <Route
        path="/tax-id"
        element={
          <TaxIdentificationForm
            userRegistrationStatus={userRegistrationStatus}
            reloadUserRegistrationStatus={reloadUserRegistrationStatus}
          />
        }
      />
      <Route
        path="/business-records"
        element={
          <BusinessRecordForm
            userRegistrationStatus={userRegistrationStatus}
            reloadUserRegistrationStatus={reloadUserRegistrationStatus}
          />
        }
      />
      <Route
        path="/business-address"
        element={
          <BusinessAddressForm
            userRegistrationStatus={userRegistrationStatus}
            reloadUserRegistrationStatus={reloadUserRegistrationStatus}
          />
        }
      />
    </Routes>
  );
};

export const BusinessOnboarding = boundError(BusinessOnboardingRaw);
